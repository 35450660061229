import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from './shared/auth.service';
import { AppCustomPreloader } from './app-routing-loader';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('../app/risk/risk.module').then(m => m.RiskModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
	},
	{
		path: 'resetpassword',
		loadChildren: () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
		data: { preload: true, delay: false },
		canActivate: [AuthGuard]
	},
	{
		path: 'newsletters',
		loadChildren: () => import('./newsletters/newsletters.module').then(m => m.NewslettersModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'viewtemplate/:id',
		loadChildren:
			() => import('./newsletters/view-template/view-template.module').then(m => m.ViewTemplateModule)
	},
	{
		path: 'audittrail',
		loadChildren: () => import('./audit-trail/audit-trail.module').then(m => m.AuditTrailModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'groups',
		loadChildren:
			() => import('./kyc/manage-groups/manage-groups.module').then(m => m.ManageGroupsModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'peerfunds',
		loadChildren:
			() => import('./risk/peer-comparison-report/peer-comparison-report.module').then(m => m.PeerComparisonReportModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'managefunds',
		loadChildren:
			() => import('./admin/manage-funds/manage-funds.module').then(m => m.ManageFundsModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'pdsummary',
		loadChildren:
			() => import('./quant/premiumdiscount/premiumdiscount.module').then(m => m.PremiumdiscountModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'premiumdiscount',
		loadChildren:
			() => import('./quant/fund-summary/fund-summary.module').then(m => m.FundSummaryModule),
		canActivate: [AuthGuard]
	},
	// {
	// 	path: 'holidaycalender',
	// 	loadChildren:
	// 		() => import('./admin/holidaycalender/holidaycalender.module').then(m => m.HolidayCalenderModule),
	// 	canActivate: [AuthGuard]
	// },
	{
		path: 'users',
		loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'config-newsletters/:id',
		loadChildren:
			() => import('./newsletters/config-newsletters/config-newsletters.module').then(m => m.ConfigNewslettersModule)
	},
	{
		path: 'kycdashboard',
		loadChildren: () => import('./kyc/home/home.module').then(m => m.HomeModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'case/:id',
		loadChildren: () => import('./kyc/case/case.module').then(m => m.CaseModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'roles',
		loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'emailconfig',
		loadChildren:
			() => import('./admin/mail-config/mail-config.module').then(m => m.MailConfigModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'schedule',
		loadChildren: () => import('./admin/scheduler-config/scheduler-config.module').then(m => m.JobsConfigModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'firmliquidation',
		loadChildren:
			() => import('./risk/form-level-liquidation/form-level-liquidation.module').then(m => m.FormLevelLiquidationModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'suspendedstocks',
		loadChildren:
			() => import('./risk/suspended-stock-liquidation/suspended-stock-liquidation.module').then(m => m.SuspendedStockLiquidationModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'emailgeneration',
		loadChildren:
			() => import('./admin/generate-emails/generate-emails.module').then(m => m.GenerateEmailsModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'userstats',
		loadChildren:
			() => import('./admin/userstatistics/userstatistics.module').then(m => m.UserstatisticsModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'uploadedfileslog',
		loadChildren:
			() => import('./quant/uploaded-files-log/uploaded-files-log.module').then(m => m.UploadedFilesLogModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'jobs',
		loadChildren: () => import('./admin/jobs/jobs.module').then(m => m.JobsModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'pricemaintainance',
		loadChildren: () => import('./risk/pricemaintainance/pricemaintainance.module').then(m => m.PricemaintainanceModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'quantdashboard',
		loadChildren: () => import('./quant/transaction-summary/transaction-summary.module').then(m => m.TransactionSummaryModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'dailycrordrsmgmt',
		loadChildren: () => import('./quant/cash-flows-and-dc/cash-flows-and-dc.module').then(m => m.CashFlowsAndDcModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'finance',
		loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'requestedtemplates',
		loadChildren: () => import('./newsletters/requested-newsletters/requested-newsletters.module').then(m => m.RequestedNewslettersModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'fxrate',
		loadChildren: () => import('./fx-rate/fx-rate.module').then(m => m.FxRateModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'opsfxrate',
		loadChildren: () => import('./fx-rate/fx-rate.module').then(m => m.FxRateModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'funddetails/:id',
		loadChildren: () => import('./risk/fund-information/fund-information.module').then(m => m.FundInformationModule),
		// canActivate: [AuthGuard]
	},
	{
		path: 'portfoliocodes',
		loadChildren: () => import('./admin/portfolio-codes/portfolio-codes.module').then(m => m.PortfolioCodesModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'flexfiles',
		loadChildren: () => import('./risk/flex-system/flex-system.module').then(m => m.FlexSystemModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'flexfilesvalidation',
		loadChildren: () => import('./risk/flex-files-validation/flex-files-validation.module').then(m => m.FlexFilesValidationModule)
	},
	{
		path: 'flexfiledetails/:id',
		loadChildren: () => import('./risk/flexfile-details/flexfile-details.module').then(m => m.FlexfileDetailsModule)
	},
	{
		path: 'reconconfig',
		loadChildren: () => import('./ops/recon-configuration/recon-configuration.module').then(m => m.ReconConfigurationModule),
		canActivate: [AuthGuard]
	},
	{
		path: ':nav/opsfunddetails/:wrkflowid/:id',
		loadChildren: () => import('./ops/fund-details/fund-details.module').then(m => m.FundDetailsModule)
	},
	{
		path: ':nav/opsfunddetails/:id',
		loadChildren: () => import('./ops/fund-details/fund-details.module').then(m => m.FundDetailsModule)
	},
	{
		path: ':nav/opsfunddetails',
		loadChildren: () => import('./ops/fund-details/fund-details.module').then(m => m.FundDetailsModule)
	},
	{
		path: ':d&c/opsorderdetails/:id',
		loadChildren: () => import('./ops/order-details/order-details.module').then(m => m.OrderDetailsModule)
	},
	{
		path: ':d&c/opsorderdetails',
		loadChildren: () => import('./ops/order-details/order-details.module').then(m => m.OrderDetailsModule)
	},
	{
		path: 'reconcilereport/:id',
		loadChildren: () => import('./ops/reconcil-report/reconcil-report.module').then(m => m.ReconcilReportModule),
		// canActivate: [AuthGuard]
	},
	// {
	// 	path: 'opsdashboard',
	// 	loadChildren: () => import('./ops/ops-dashboard/ops-dashboard.module').then(m => m.OpsDashboardModule)
	// },
	{
		path: 'accountmapping',
		loadChildren: () => import('./ops/ops-account-mapping/ops-account-mapping.module').then(m => m.OpsAccountMappingModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'captureholiday',
		loadChildren: () => import('./ops/capture-holiday/capture-holiday.module').then(m => m.CaptureHolidayModule)
	},
	{
		path: 'dashboard',
		loadChildren: () => import('../app/risk/risk.module').then(m => m.RiskModule)
	},
	{
		path: 'ccass',
		loadChildren: () => import('./ccass/ccass.module').then(m => m.CcassModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'opsfundinnerdetails/:param/:id',
		loadChildren: () => import('./ops/pdcc-fund-details/pdcc-fund-details.module').then(m => m.PdccFundDetailsModule)
	},
	{
		path: 'opsfundinnerdetails/:param',
		loadChildren: () => import('./ops/pdcc-fund-details/pdcc-fund-details.module').then(m => m.PdccFundDetailsModule)
	},
	{
		path: '',
		redirectTo: '',
		pathMatch: 'full'
	},
	{
		path: 'enum-config',
		loadChildren: () => import('./e-num-config/e-num-config.module').then(m => m.ENumConfigModule),
		canActivate: [AuthGuard]
	},
	{ path: 'dc-config', loadChildren: () => import('./dc-config/dc-config.module').then(m => m.DcConfigModule), canActivate: [AuthGuard] },
	{ path: 'holidaycalender', loadChildren: () => import('./holiday-master/holiday-master.module').then(m => m.HolidayMasterModule), canActivate: [AuthGuard] },
	{ path: 'datamarketplace', loadChildren: () => import('./data-market-place/data-market-place.module').then(m => m.DataMarketPlaceModule), canActivate: [AuthGuard] },
	{ path: 'datamarketplacedetails', loadChildren: () => import('./data-market-place/data-market-place-details/data-market-place-details.module').then(m => m.DataMarketPlaceDetailsModule) },
	{ path: 'datamarketplacereports', loadChildren: () => import('./data-market-place/data-market-place-reports/data-market-place-reports.module').then(m => m.DataMarketPlaceReportsModule) },
	{ path: 'account-config', loadChildren: () => import('./account-config/account-config.module').then(m => m.AccountConfigModule), canActivate: [AuthGuard]},
	{ path: 'accounting-entries', loadChildren: () => import('./AccountingEntries/accounting-entries/accounting-entries.module').then(m => m.AccountingEntriesModule) },
	{ path: 'vieworderdetails', loadChildren: () => import('./ops/order-details/view-order-details/view-order-details.module').then(m => m.ViewOrderDetailsModule) },
	{ path: 'caconfig', loadChildren: () => import('./Riskrevamp/ca-config/ca-config.module').then(m => m.CAConfigModule) ,canActivate: [AuthGuard] },
	{ path: 'code-config', loadChildren: () => import('./Riskrevamp/code-config/code-config.module').then(m => m.CodeConfigModule), canActivate: [AuthGuard] },
	{ path: 'marketmaker', loadChildren: () => import('./Riskrevamp/marketmaker/marketmaker.module').then(m => m.MarketmakerModule) },
	{
		path: 'tdte/:id',
		loadChildren: () => import('./risk/tdte-details/tdte-details.module').then(m => m.TdteDetailsModule)
	},
	{ path: 'tdthreshold', loadChildren: () => import('./Riskrevamp/td-threshold/td-threshold.module').then(m => m.TdThresholdModule), canActivate: [AuthGuard] },
	{ path: 'transaction-cost-config', loadChildren: () => import('./Transaction-Cost-Config/transaction-cost-config/transaction-cost-config.module').then(m => m.TransactionCostConfigModule), canActivate: [AuthGuard] },
	{ path: 'jobsConfig', loadChildren: () => import('./admin/jobs-config/jobs-config.module').then(m => m.JobsConfigModule), canActivate: [AuthGuard] },
	{ path: 'asset-class-config', loadChildren: () => import('./Riskrevamp/asset-class-config/asset-class-config.module').then(m => m.AssetClassConfigModule), canActivate: [AuthGuard] },
	{ path: 'translation', loadChildren: () => import('./Riskrevamp/translation/translation.module').then(m => m.TranslationModule), canActivate: [AuthGuard] },
	{ path: 'parties', loadChildren: () => import('./Riskrevamp/parties/parties.module').then(m => m.PartiesModule), canActivate: [AuthGuard] },
	{ path: 'newslettermapping', loadChildren: () => import('./Riskrevamp/newsLetter/newsletter-mapping/newsletter-mapping.module').then(m => m.NewsletterMappingModule), canActivate: [AuthGuard] },
	{ path: 'newsletterconfig', loadChildren: () => import('./Riskrevamp/newsLetter/newsletter-config/newsletter-config.module').then(m => m.NewsletterConfigModule) },
	{ path: 'credit-rating-capture', loadChildren: () => import('./Riskrevamp/newsLetter/credit-rating-capture/credit-rating-capture.module').then(m => m.CreditRatingCaptureModule),canActivate: [AuthGuard] },
	{ path: 'newsletterreport', loadChildren: () => import('./Riskrevamp/newsLetter/newsletter-report/newsletter-report.module').then(m => m.NewsletterReportModule), canActivate: [AuthGuard] },
	{ path: 'liquidityprofilemmfthresholdconfig', loadChildren: () => import('./Riskrevamp/liquidity/liquidity-profile/mmf-config/mmf-config.module').then(m => m.MmfConfigModule) },
	{ path: 'liquidityprofilenommmfthresholdconfig', loadChildren: () => import('./Riskrevamp/liquidity/liquidity-profile/non-mmf-config/non-mmf-config.module').then(m => m.NonMmfConfigModule) },
	{ path: 'liquidationcostthresholdconfig', loadChildren: () => import('./Riskrevamp/liquidity/liquidation-cost/liquidation-cost-config/liquidation-cost-config.module').then(m => m.LiquidationCostConfigModule) },
	{ path: 'liquidationcost', loadChildren: () => import('./Riskrevamp/liquidity/liquidation-cost/liquidation-cost-dashboard/liquidation-cost-dashboard.module').then(m => m.LiquidationCostDashboardModule) },
	{ path: 'liquidityprofile', loadChildren: () => import('./Riskrevamp/liquidity/liquidity-profile/liquidity-profile-dashboard/liquidity-profile-dashboard.module').then(m => m.LiquidityProfileDashboardModule) },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: AppCustomPreloader }), BrowserModule],
	exports: [RouterModule],
	providers: [AppCustomPreloader]
})
export class AppRoutingModule { }
