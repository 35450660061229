// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: true,
	companyID: 'CSOP',

	'localhost': {
		// apiUrl: 'https://qa.tectoro.com/',
		apiUrl: 'https://kyc-qa.tectoro.com/',
		// apiUrl: 'https://portal.csopdev.tectoro.com/',
		// apiUrl: 'http://139.59.73.246/',
		wsUrl: '',
		notifUrl: 'wss://qa.tectoro.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'Fund Analytics Platform',
		client: 'CSOP',
		envType: 'QA',
		title: 'Fund Analytics Platform',
		trackerURL: '/qa.tectoro.com/analytics-collector'
	},
	'd527343a1d24.ngrok.io':
	{
		apiUrl: 'https://qa.tectoro.com/',
		wsUrl: '',
		notifUrl: 'wss://qa.tectoro.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'Fund Analytics Platform',
		client: 'CSOP',
		envType: 'QA',
		title: 'Fund Analytics Platform',
		trackerURL: '/qa.tectoro.com/analytics-collector'
	},
	'139.59.73.246': {
		apiUrl: 'http://139.59.73.246/',
		wsUrl: '',
		notifUrl: 'wss://qa.tectoro.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'CSOP PORTAL',
		client: 'CSOP',
		envType: 'QA',
		title: 'CSOP Fund Analytics Platform',
		trackerURL: '/qa.tectoro.com/analytics-collector'
	},
	'kyc-qa.tectoro.com': {
		// apiUrl: 'https://qa.tectoro.com/',
		apiUrl: 'https://kyc-qa.tectoro.com/',
		serverUrl:'http://167.71.233.143:5452/',
		dcserveUrl:'http://167.71.233.143:5452/',
		wsUrl: '',
		notifUrl: 'wss://qa.tectoro.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'CSOP PORTAL',
		client: 'CSOP',
		envType: 'QA',
		title: 'CSOP Fund Analytics Platform',
		trackerURL: '/qa.tectoro.com/analytics-collector'
	},
	'kyc-dev.tectoro.com': {
		apiUrl: 'https://dev.tectoro.com/',
		serverUrl:'http://167.71.233.143:5453/',
		dcserveUrl:'http://167.71.233.143:5453/',
		wsUrl: '',
		notifUrl: 'wss://dev.tectoro.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'CSOP PORTAL',
		client: 'CSOP',
		envType: 'DEV',
		title: 'CSOP Fund Analytics Platform',
		trackerURL: '/dev.tectoro.com/analytics-collector'
	},
	'139.59.70.53': {
		apiUrl: 'http://139.59.70.53/',
		wsUrl: '',
		notifUrl: 'wss://dev.tectoro.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		//secretKey:'6Ldi2oQjAAAAAHOqmPs1YureBIvjuVb2Phb3W-kV',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'CSOP PORTAL',
		client: 'CSOP',
		envType: 'DEV',
		title: 'CSOP Fund Analytics Platform',
		trackerURL: '/dev.tectoro.com/analytics-collector'
	},
	'portal.csopdev.tectoro.com': {
		apiUrl: 'https://portal.csopdev.tectoro.com/',
		wsUrl: '',
		notifUrl: 'wss://dev.tectoro.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		// secretKey:'6LeljYMjAAAAAKhSaQqb8vpkooooJmBRn4eg8-sf',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'CSOP PORTAL',
		client: 'CSOP',
		envType: 'DEV',
		title: 'CSOP Fund Analytics Platform',
		trackerURL: '/dev.tectoro.com/analytics-collector'
	},
	'kyc-staging.tectoro.com': {
		apiUrl: 'https://staging.tectoro.com/',
		wsUrl: '',
		notifUrl: 'wss://staging.tectoro.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'CSOP PORTAL',
		client: 'CSOP',
		envType: 'UAT',
		title: 'CSOP Fund Analytics Platform',
		trackerURL: '/staging.tectoro.com/analytics-collector'
	},
	'portal.csopasset.com': {
		apiUrl: 'https://prod.csopasset.com/',
		wsUrl: '',
		notifUrl: 'wss://prod.csopasset.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'CSOP PORTAL',
		client: 'CSOP',
		envType: '',
		title: 'CSOP Fund Analytics Platform',
		trackerURL: '/portal.csopasset.com/analytics-collector',
		header_logo_EN: 'assets/csop/csop_logo_EN.png'
	},
	'cloud.csopasset.com': {
		apiUrl: 'https://services.csopasset.com/',
		wsUrl: '',
		notifUrl: 'wss://203.86.144.128/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'CSOP PORTAL',
		client: 'CSOP',
		envType: '',
		title: 'CSOP Fund Analytics Platform',
		trackerURL: '/cloud.csopasset.com/analytics-collector'
	},
	'kyc-alpha.tectoro.com': {
		apiUrl: 'https://qa2.tectoro.com/',
		wsUrl: ''
	},
	'amc-demo.tectoro.com': {
		apiUrl: 'http://amc-demo.tectoro.com/',
		wsUrl: '',
		notifUrl: 'wss://amc-demo.tectoro.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'Fund Analytics Platform',
		client: 'Tectoro',
		envType: 'QA',
		title: 'Fund Analytics Platform',
		trackerURL: '/amc-demo.tectoro.com/api/analytics-collector'
	},
	'uat-portal.csopasset.com': {
		apiUrl: 'https://uat-api.csopasset.com/',
		wsUrl: '',
		notifUrl: 'wss://uat-api.csopasset.com/socket.io/',
		siteKey: '6LdfsMAqAAAAAPSczihGwsapOX_57xlaqGRqsAdu',
		autoLogoutInMinutes: 30,
		checkIntervalInMilliSec: 60000,
		ssnExpryCnfrmMdlTimeInMilliSec: 120000,
		defaultTitle: 'CSOP PORTAL',
		client: 'CSOP',
		envType: 'UAT',
		title: 'CSOP Fund Analytics Platform',
		trackerURL: '/uat-portal.csopasset.com/analytics-collector',
		header_logo_EN: 'assets/csop/csop_logo_EN.png'
	}

};
