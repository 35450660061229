import { Component, OnInit, ViewChild } from '@angular/core';
import { DefaultService } from '../../utils/default.service';
import * as Highcharts from 'highcharts/highstock';
import { SelectItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Calendar } from 'primeng/calendar';
import { ApiService } from '../../shared/api.service';
import { DashboardService } from '../dashboard.service';
import { PrivilegeService } from '../../shared/privilege.service';
import { ThemeingService } from '../../utils/themeing.service';
import { DatePipe } from '@angular/common';
import { NavOneCardComponent } from '../../ops/ops-dashboard/nav-one-card/nav-one-card.component';
import { DCCalculationComponent } from '../../ops/ops-dashboard/dc-calculation/dc-calculation.component';
import { json } from '@angular-devkit/core';
import { DataService } from '../../../app/data.service';

declare var $: any;


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	data: any;
	loading = false;
	reconLoader = false;
	ccIFrame = '';
	query = {};
	rangeDates: Date[];
	message: string;
	// fundTypes = [];
	assetClass = [];
	productType = [];
	noOfFunds: number;
	noOfSummaries: number;
	currencyMap = new Map<string, string>();
	flagMap = new Map<string, string>();
	totalAum: any;
	doughnutChartData: any;
	aumByAssetClass: any;
	filters = [];
	chartOptions: any;
	lineChartOptions: any;
	thresholdFilters: any;
	selectedFilters = [];
	countryList = [];
	fundList = [];
	assetFilters = [];
	byProductFilters = [];
	isTabSelected = false;
	asOfDate: any;
	doughnutOptions: any;
	selectedTab: any;
	selectedIndex: any;
	filterTabs = [];
	domicileFilters = [];
	showFundView = false;
	showConfirmationPopup = false;
	selectedFund = {};
	pieChartData: any;
	pieChartOptions: any;

	chartDataLabels = [];
	chartData: any = [];
	currentDate: Date;
	previousWorkingDay: Date;
	doughnutLegendData = [];
	pieLegendData = [];
	themeObj = '';
	maxDate: any;
	Math: any;
	chartsDetails: any;
	PDCC_ChartDetails: any;
	dcchartData: any;
	PDCC_Loader: boolean = false;
	defaultColors = [
		'#FF8C5D',
		'#4AA3E6',
		'#F6DB6E',
		'#ACD679',
		'#992600',
		'#cc00ff',
		'#00cca3',
	];
	@ViewChild(Calendar, { static: false }) calendar: Calendar;
	@ViewChild(NavOneCardComponent) NAV_OneEmitter: NavOneCardComponent;
	@ViewChild(DCCalculationComponent) DC_CalEmitter: DCCalculationComponent;

	suspStockCounts = {
		'gt_60_count': 0,
		'lt_60_count': 0
	};
	isDelistedFunds: boolean;

	canViewFLLByExposurePriv = { 'screenName': 'Dashboard', 'subModule': 'canViewFLLByExposure' };
	canViewFLLByAssetClassPriv = { 'screenName': 'Dashboard', 'subModule': 'canViewFLLByAssetClass' };
	canViewFirmLevelAUMPriv = { 'screenName': 'Dashboard', 'subModule': 'canViewFirmLevelAUM' };
	canViewFirmLevelSuspendedStocksPriv = { 'screenName': 'Dashboard', 'subModule': 'canViewFirmLevelSuspendedStocks' };
	canViewByAUMFundTypePriv = { 'screenName': 'Dashboard', 'subModule': 'canViewByAUMFundType' };
	canViewFundListPriv = { 'screenName': 'Dashboard', 'subModule': 'canViewFundList' };
	canViewCCiFramePriv = { 'screenName': 'Dashboard', 'subModule': 'canViewCCiFrame' };
	canAccessNAVOneReconWidget = { 'screenName': 'OPS', 'subModule': 'canAccessNAVOneReconWidget' };
	canAccessNAVTwoReconWidget = { 'screenName': 'OPS', 'subModule': 'canAccessNAVTwoReconWidget' };
	canAccessPDCCReconWidget = { 'screenName': 'OPS', 'subModule': 'canAccessPDCCReconWidget' };
	canAccessDcCalculation = { 'screenName': 'OPS', 'subModule': 'canAccessDcCalculation' };
	canViewTdTeChart = { 'screenName': 'RISK', 'subModule': 'canViewTdTeChart' };
	canViewLiquidityChart = { 'screenName': 'Liquidity', 'subModule': 'canViewLiquidityChart' };

	dashBoardWidgets = [
		this.canViewFLLByExposurePriv,
		this.canViewFLLByAssetClassPriv,
		this.canViewFirmLevelAUMPriv,
		this.canViewFirmLevelSuspendedStocksPriv,
		this.canViewByAUMFundTypePriv,
		this.canViewFundListPriv,
		this.canViewCCiFramePriv,
		this.canAccessNAVOneReconWidget,
		this.canAccessNAVTwoReconWidget,
		this.canAccessPDCCReconWidget
	];
	dcLoader = false
	tdteChartData: any;
	tdtdLoader: boolean;
	liquidityLoader: boolean;
	liquidityChartData: any
	suspendedStockLoader: boolean;


	constructor(
		private defaultService: DefaultService,
		private apiService: ApiService,
		public privilegeService: PrivilegeService,
		private messageService: MessageService,
		private router: Router,
		private datePipe: DatePipe,
		private themeService: ThemeingService,
		private dataService: DataService
	) {
		this.Math = Math;

	}

	ngOnInit() {
		this.currentDate = new Date();
		this.isDelistedFunds = false;
		this.selectedTab = 'TB';
		if (this.privilegeService.isAuthorized(this.canAccessNAVOneReconWidget) ||
			this.privilegeService.isAuthorized(this.canAccessNAVTwoReconWidget) ||
			this.privilegeService.isAuthorized(this.canAccessPDCCReconWidget)) {
			this.previousWorkingDay = !localStorage.getItem('asOfDate') ? this.currentDate : new Date(localStorage.getItem('asOfDate'));
			this.asOfDate = this.previousWorkingDay;
			this.maxDate = this.defaultService.getStartOfTheDay(this.currentDate);
		} else {
			this.setPreviousDate(this.currentDate, 1);
			this.setMaxDate(this.currentDate, 1);
		}

		localStorage.removeItem('financeFromDate');
		localStorage.removeItem('financeToDate');
		this.themeObj = this.themeService.getThemeObj();
		this.setCaptialMarketIFrame();
		if (this.privilegeService.isAuthorized(this.canAccessNAVOneReconWidget) ||
			this.privilegeService.isAuthorized(this.canAccessNAVTwoReconWidget)) {
			this.reconLoader = true;
			this.getDashboardDetails();
		}
		if (this.privilegeService.isAuthorized(this.canAccessPDCCReconWidget)) {
			this.PDCC_Loader = true;
			this.get_PDCC_widgetDetails();
		}
		if (this.privilegeService.isAuthorized(this.canAccessDcCalculation)) {
			this.dcLoader = true;
			this.getDcDashboardDetails()
		}
		if (this.privilegeService.isAuthorized(this.canViewTdTeChart)) {
			this.tdtdLoader = true
			this.getTdTeData()
		}
		if (this.privilegeService.isAuthorized(this.canViewLiquidityChart)) {
			this.liquidityLoader = true
			this.getLiquidityData()
		}
		if (this.privilegeService.isAuthorized(this.canViewFirmLevelSuspendedStocksPriv)) {
			this.suspendedStockLoader = true
			this.getSuspendedStockData()
		}

	}

	getSuspendedStockData() {
		const data = this.dataService.getSupendedstockData()		
		const asOfDate = this.datePipe.transform(this.asOfDate, 'yyyy-MM-dd');
		localStorage.setItem('opsAsOfDate', asOfDate);
		localStorage.setItem('asOfDate', asOfDate);
		const url = 'suspstock/report/' + asOfDate
		this.defaultService.riskfind({ }, 1, 1000, url).then(data => {
			if (data && data.status === 'success') {
				if(data['data'].length > 0) {
				 this.setSuspendStockData(data);
				} else {
					this.suspStockCounts = {
						'gt_60_count': 0,
						'lt_60_count': 0
					};
				}
			  this.suspendedStockLoader =false
			} else {
				this.suspStockCounts = {
					'gt_60_count': 0,
					'lt_60_count': 0
				};
				this.messageService.add({ severity: 'error', detail: data['status'] });
			}
			this.liquidityLoader = false;
		}).catch(err => {
			this.tdtdLoader = false;
			if(err.status !== 401) {
				if (err.status == 500) {
					this.messageService.add({ severity: 'error', detail: err['statusText'] });
				}
				else if (err.status == 502) {
					this.messageService.add({ severity: 'error', detail: err.status + 'Bad Gateway' });
				}
				else {
					this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
				}
			}			
		});
		

	}

	getLiquidityData() {
		const data = this.dataService.getLiquidityCardData()
		const asOfDate = this.datePipe.transform(this.asOfDate, 'yyyy-MM-dd');
		localStorage.setItem('opsAsOfDate', asOfDate);
		localStorage.setItem('asOfDate', asOfDate);
		const url = 'liquidity/dashboard/' + asOfDate
		this.defaultService.riskfind({ }, 1, 1000, url).then(data => {
			if (data && data.status === 'success') {
				this.liquidityChartData = data['data'];
			} else {
				this.messageService.add({ severity: 'error', detail: data['status'] });
			}
			this.liquidityLoader = false;
		}).catch(err => {
			this.tdtdLoader = false;
			if(err.status !== 401) {
				if (err.status == 500) {
					this.messageService.add({ severity: 'error', detail: err['statusText'] });
				}
				else if (err.status == 502) {
					this.messageService.add({ severity: 'error', detail: err.status + 'Bad Gateway' });
				}
				else {
					this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
				}
			}
		});
	}

	getDcDashboardDetails() {
		const asOfDate = this.datePipe.transform(this.asOfDate, 'yyyy-MM-dd');
		localStorage.setItem('opsAsOfDate', asOfDate);
		localStorage.setItem('asOfDate', asOfDate);
		const date = this.datePipe.transform(this.asOfDate, 'yyyy-MM-dd');
		const url = 'gateway/cdb/dashboard/get-dnc-chart';
		this.defaultService.get({ date }, 1, 1000, url).then(data => {
			if (data) {
				this.dcchartData = data;
			} else {
				this.messageService.add({ severity: 'error', detail: data['message'] });
			}
			this.dcLoader = false;
		}).catch((err: any) => {
			this.dcLoader = false;
			if(err.status !== 401) {
				if (err.status == 500) {
					this.messageService.add({ severity: 'error', detail: err['statusText'] });
				}
				else if (err.status == 502) {
					this.messageService.add({ severity: 'error', detail:  'Bad Gateway' });
				}
				else {
					this.messageService.add({ severity: 'error', detail: err['error'] });
				}
			}
		});
	}

	getTdTeData() {
		const data= this.dataService.getTdteData();
		const asOfDate = this.datePipe.transform(this.asOfDate, 'yyyy-MM-dd');
		localStorage.setItem('opsAsOfDate', asOfDate);
		localStorage.setItem('asOfDate', asOfDate);
		const url = 'risk/tdte/mainscreen'
		this.defaultService.find({ asOfDate }, 1, 1000, url).then(data => {
			if (data && data.status === 'success') {
				this.tdteChartData = data['data'];
			} else {
				this.messageService.add({ severity: 'error', detail: data['status'] });
			}
			this.tdtdLoader = false;
		}).catch(err => {
			this.tdtdLoader = false;
			if(err.status !== 401) {
				if (err.status == 500) {
					this.messageService.add({ severity: 'error', detail: err['statusText'] });
				}
				else if (err.status == 502) {
					this.messageService.add({ severity: 'error', detail: err.status + 'Bad Gateway' });
				}
				else {
					this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
				}
			}
		});

	}

	getDashboardDetails() {
		const asOfDate = this.datePipe.transform(this.asOfDate, 'yyyy-MM-dd');
		localStorage.setItem('opsAsOfDate', asOfDate);
		localStorage.setItem('asOfDate', asOfDate);
		const url = 'cop/cdb/recon/dashboard/charts/' + asOfDate;
		this.defaultService.get({}, 1, 1000, url).then(data => {			
			if (data && data.status === 'success') {
				this.chartsDetails = data.data;				
			} else {
				this.messageService.add({ severity: 'error', detail: data['status'] });
			}
			this.reconLoader = false;
		}).catch((err: any) => {
			this.reconLoader = false;
			if(err.status !== 401) {
			  this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
			}
		});
	}
	get_PDCC_widgetDetails() {
		const asOfDate = this.datePipe.transform(this.asOfDate, 'yyyy-MM-dd');
		localStorage.setItem('opsAsOfDate', asOfDate);
		localStorage.setItem('asOfDate', asOfDate);
		const url = 'cop/cdb/recon/dashboard/charts/' + asOfDate ;
		this.defaultService.get({}, 1, 1000, url).then(data => {
			if (data && data.status === 'success') {
				this.PDCC_ChartDetails = data.data;
			} else {
				this.messageService.add({ severity: 'error', detail: data['status'] });
			}
			this.PDCC_Loader = false;
		}).catch((err: any) => {
			this.PDCC_Loader = false;
			if(err.status !== 401) {
			this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
			}
		});
	}

	widgetsExist() {
		return this.dashBoardWidgets.filter(priv => {
			return this.privilegeService.isAuthorized(priv);
		}).length > 0;
	}

	setPreviousDate(date, days) {
		let previousDay;
		if (localStorage.getItem('asOfDate')) {
			this.previousWorkingDay = new Date(localStorage.getItem('asOfDate'));
			this.asOfDate = this.previousWorkingDay;
		} else {
			date = this.defaultService.getStartOfTheDay(date);
			let noOfBizDays = 0;
			let day = date.getDay();
			previousDay = new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000));
			day = previousDay.getDay();
			if (day !== 0 && day !== 6) {
				noOfBizDays += 1;
			}
			day = previousDay.getDay();
			while (noOfBizDays < days) {
				previousDay = new Date(previousDay.getTime() - (1 * 24 * 60 * 60 * 1000));
				day = previousDay.getDay();
				if (day !== 0 && day !== 6) {
					noOfBizDays += noOfBizDays + 1;
				}
			}
			this.previousWorkingDay = previousDay;
			this.asOfDate = this.previousWorkingDay;
			localStorage.setItem('asOfDate', this.asOfDate);
		}
		if (this.privilegeService.isAuthorized(this.canViewFundListPriv)) {
			this.loading = true;
			this.getFundList();

			this.setAumChartDateFilters();
			this.getCountries();
			this.getCurrencies();
			this.onClickFilterTabs({
				label: 'By Threshold Breach', value: 'TB'
			}, 0);
			this.getAumOfTheFunds({ 'toDate': this.defaultService.getStartDate(this.asOfDate) });
		}
	}

	setMaxDate(date, days) {
		let maxDate: any;
		date = this.defaultService.getStartOfTheDay(date);
		let noOfBizDays = 0;
		let day = date.getDay();
		maxDate = new Date(date.getTime() - (1 * 24 * 60 * 60 * 1000));
		day = maxDate.getDay();
		if (day !== 0 && day !== 6) {
			noOfBizDays += 1;
		}
		day = maxDate.getDay();
		while (noOfBizDays < days) {
			maxDate = new Date(maxDate.getTime() - (1 * 24 * 60 * 60 * 1000));
			day = maxDate.getDay();
			if (day !== 0 && day !== 6) {
				noOfBizDays += noOfBizDays + 1;
			}
		}
		this.maxDate = maxDate;
		return (this.maxDate);
	}
	setAumChartDateFilters() {
		const date = new Date(this.asOfDate);
		this.rangeDates = [];
		const toDate = this.defaultService.getStartDate(this.asOfDate);
		const fromDate = new Date(date.setFullYear(date.getFullYear() - 1));
		this.rangeDates[0] = fromDate;
		this.rangeDates[1] = new Date(toDate);
	}
	getCurrencies() {
		let currencies = [];
		const currencyList = JSON.parse(localStorage.getItem('currencies'));
		if (currencyList != null) {
			currencies = currencyList;
			this.mapCurrencyData(currencies);
		} else {
			const filter = {};
			filter['sortBy'] = {
				'code': 'asc'
			};
			this.defaultService.get(filter, 1, 1000, 'commons/currencies').then(data => {
				if (data['status'] === 'success') {
					this.mapCurrencyData(data);
				}
			}).catch(err => {
			});
		}
	}

	mapCurrencyData(currency) {
		currency.rows.forEach(element => {
			let symbol = '';
			const symVal = element['symbol'];
			if (symVal !== undefined && symVal !== '') {
				symbol = symVal;
			}
			this.currencyMap.set(element['code'], symbol);
		});
	}

	setAumByFundTypeData(data) {
		const _this = this;
		let tempVar = 0;
		this.pieChartOptions = {
			legend: {
				display: false,
				position: 'left',
				labels: {
					usePointStyle: true,
					fontSize: (window.screen.width <= 1680) ? 9 : 10,
					padding: 14,
					fontColor: '#5F6772',
					generateLabels: function (chart) {
						const d = chart.data;
						if (d.labels.length && d.datasets.length) {
							return d.labels.map(function (label, i) {
								const ds = d.datasets[0];
								return {
									text: label,
									fillStyle: _this.defaultColors[i],
								};
							});
						}
						return [];
					}
				},
			},
			responsive: false,
			maintainAspectRatio: false,
		};
		const labels = [];
		const chartData = [];
		this.pieLegendData = data['firmReports']['aumByFundType'];
		this.pieLegendData.forEach(element => {
			element['color'] = this.defaultColors[tempVar];
			tempVar++;
		});
		data['firmReports']['aumByFundType'].forEach(element => {
			labels.push(element['type']);
			chartData.push(Math.round(element['value'] / 1000000));
		});
		this.pieChartData = {
			labels: labels,
			datasets: [
				{
					data: chartData,
					backgroundColor: this.defaultColors,
					hoverBackgroundColor: this.defaultColors,
				}],
		};
	}

	setHighchartsData(data) {
		delete data['success'];
		let options = {};
		options = {
			rangeSelector: {
				selected: 5,
				enabled: false
			},
			credits: {
				enabled: false
			},
			tooltip: {
				backgroundColor: '#FFF',
				formatter: function () {
					let s = '<b>' + 'Date' + ':' + new Date(this.x).toLocaleDateString() + '</b>';
					$.each(this.points, function (i, p) {
						s += '<br/>' + '<b style=' + '"' + 'color:' + p.color + '">' + this.series.name + '</b>' + ': '
							+ '<b>' + this.y + '</b>';
					});
					return s;
				},
				shared: true
			},
			navigator: {
				enabled: false
			},
			scrollbar: {
				enabled: false
			},
			exporting: { enabled: false },
			chart: {
				height: 150
			},
			legend: {
				enabled: false,
			},
			yAxis: {
				opposite: false,
				title: {
					text: 'AUM in Billions',
					style: {
						color: this.themeObj['primaryColor'],
						fontWeight: 'bold',
						fontSize: (window.screen.width <= 1680) ? '9' : '10',
					}
				},
				labels: {
					formatter: function () {
						return this.value + ' ' + 'B';
					},
					style: {
						color: '#0B3063',
						fontWeight: 'bold',
						fontSize: (window.screen.width <= 1680) ? '9' : '10',
					}
				},
			},
			xAxis: {
				title: {
					text: 'Time',
					style: {
						color: this.themeObj['primaryColor'],
						fontWeight: 'bold',
						fontSize: (window.screen.width <= 1680) ? '9' : '10',
					}
				},
				labels: {
					style: {
						color: '#0B3063',
						fontWeight: 'bold',
						fontSize: (window.screen.width <= 1680) ? '9' : '10',
					}
				},
				gridLineWidth: 1,
			},
			series: this.getChartData(data)
		};
		Highcharts.stockChart('firmLevelAum', options);

	}
	getChartData(data) {
		const series = [];
		Object.keys(data).forEach(function (key) {
			series.push({ name: key, data: data[key] });
		});
		return series;
	}

	arrangeTabs(data) {
		this.filterTabs = [{
			label: 'By Threshold Breach', value: 'TB'
		},
		{
			label: 'By Asset Class', value: 'AS'
		},
		{
			label: 'By Domicile', value: 'DM'
		},
		{
			label: 'By Product Type', value: 'PT'
		}];
		this.filters = data['filterDetails'];
	}

	setDoughnutChartData(data) {
		const _this = this;
		let tempVar = 0;
		this.doughnutOptions = {
			legend: {
				display: false,
				position: 'left',
				labels: {
					usePointStyle: true,
					fontSize: (window.screen.width <= 1680) ? 9 : 10,
					fontWeight: 'bold',
					padding: 10,
					fontColor: '#5F6772',
					generateLabels: function (chart) {
						const d = chart.data;
						if (d.labels.length && d.datasets.length) {
							return d.labels.map(function (label, i) {
								const ds = d.datasets[0];
								return {
									text: label + ' ' + ds.data[i] + ' ' + 'M',
									fillStyle: _this.defaultColors[i],
								};
							});
						}
						return [];
					}
				},
			},
			responsive: false,
			maintainAspectRatio: false,
		};
		const labels = [];
		const chartData = [];
		this.doughnutLegendData = data['firmReports']['aumByAssetClass'];
		this.doughnutLegendData.forEach(element => {
			element['color'] = this.defaultColors[tempVar];
			tempVar++;
		});
		data['firmReports']['aumByAssetClass'].forEach(element => {
			labels.push(element['type']);
			chartData.push(Math.round(element['value'] / 1000000));
		});
		this.doughnutChartData = {
			labels: labels,
			legend: {
				enabled: false,
			},
			datasets: [
				{
					data: chartData,
					backgroundColor: this.defaultColors,
					hoverBackgroundColor: this.defaultColors,
				}]
		};
	}

	setSuspendStockData(data: any) {
		if (data) {
			this.suspStockCounts = data.data[0]
		} else {
			this.suspStockCounts = {
				'gt_60_count': 0,
				'lt_60_count': 0
			};
		}
	}

	getLiquidationByExposureData(data) {
		const labels = [];
		const daysToTrade = [];
		this.chartDataLabels = ['1', '3', '5', '7', '30'];
		this.chartData = ['0', '0', '0', '0', '0'];
		if (data['firmReports']['firmLevelLiquidity']) {
			data['firmReports']['firmLevelLiquidity'].forEach(element => {
				labels.push(element['type']);
				daysToTrade.push((Math.abs(element['value'] * 100).toFixed(2)));
			});
			this.chartDataLabels = labels;
			this.chartData = daysToTrade;
		}

		this.setLiquidationByExposureData();
	}

	setLiquidationByExposureData() {
		// Calculate Max & min Values because of overlapping
		let minVal = 0;
		let maxVal = Math.max.apply(Math, this.chartData.map(function (count) { return count; }));
		// eslint-disable-next-line radix
		maxVal = parseInt(maxVal);
		const lastIndex = 10 - (maxVal.toString().split('').pop());
		maxVal = (maxVal + lastIndex) + 10;
		let minValue = Math.min.apply(Math, this.chartData.map(function (count) { return count; }));
		if (minValue < 0) {
			// eslint-disable-next-line radix
			minValue = parseInt(minValue);
			const lastVal = 10 - (minValue.toString().split('').pop());
			minVal = (minValue - lastVal) - 10;
		}
		this.chartOptions = {
			animation: {
				onComplete: function () {
					const chartInstance = this.chart;
					const ctx = chartInstance.ctx;
					ctx.textBaseline = 'middle';
					ctx.textAlign = 'center';
					this.data.datasets.forEach(function (dataset, i) {
						const meta = chartInstance.controller.getDatasetMeta(i);
						meta.data.forEach(function (bar, index) {
							const data = dataset.data[index];
							ctx.fillText(data, bar._model.x, bar._model.y - 6);
						});
					});
				}
			},
			legend: {
				display: false,
				render: 'value',
				precision: 2
			},
			events: false,
			responsive: false,
			maintainAspectRatio: false,
			scales: {
				yAxes: [{
					showDatapoints: true,
					barPercentage: 1,
					gridLines: {
						drawOnChartArea: false,
					},
					scaleLabel: {
						display: true,
						labelString: 'Exposure',
						fontSize: (window.screen.width <= 1680) ? 9 : 10,
						fontColor: this.themeObj['primaryColor'],
					},
					ticks: {
						beginAtZero: true,
						max: Math.round(maxVal),
						min: minVal,
						// stepSize: 50,
						fontSize: (window.screen.width <= 1680) ? 9 : 11,
						fontColor: '#0C3062',
					}
				}],
				xAxes: [{
					showDatapoints: true,
					barPercentage: 1,
					gridLines: {
						drawOnChartArea: false,
					},
					scaleLabel: {
						display: true,
						labelString: 'Days to Trade',
						fontSize: (window.screen.width <= 1680) ? 9 : 10,
						fontColor: this.themeObj['primaryColor'],
					},
					ticks: {
						padding: 0,
						fontSize: (window.screen.width <= 1680) ? 9 : 10,
						beginAtZero: true,
						fontColor: '#0C3062',
					}
				}]
			},
		};

		// Bar chart data
		this.data = {
			labels: this.chartDataLabels,
			datasets: [
				{
					label: 'Days to Trade',
					data: this.chartData,
					backgroundColor: [
						'#acd679',
						'#acd679',
						'#f6db6e',
						'#f6db6e',
						'#f74646',
						'#f74646'
					],
					borderColor: [
						'#acd679',
						'#acd679',
						'#f6db6e',
						'#f6db6e',
						'#f74646',
						'#f74646'
					],
					borderWidth: 1,
				}
			],
		};
	}

	// Get country list
	getCountries() {
		let countries = [];
		const countryData = JSON.parse(localStorage.getItem('countries'));
		if (countryData != null) {
			countries = countryData;
			this.mapCountryData(countries);
		} else {
			const filter = {};
			filter['sortBy'] = {
				'countryName': 'asc'
			};
			this.defaultService.get(filter, 1, 1000, 'commons/countrystates/list').then(data => {
				countries = data;
				this.mapCountryData(countries);
			}).catch(err => {
			});
		}
	}
	mapCountryData(countries) {
		this.countryList = [];
		if (countries['rows']) {
			countries['rows'].forEach(element => {
				this.countryList.push({ label: element['countryName'], value: element['countryName'] } as SelectItem);
				const code = element['countryCode'];
				this.flagMap.set(element['countryName'], 'flag-icon flag-icon' + '-' + code.toLowerCase() + ' ' + 'float-right');
			});
		}
	}

	onClickFilterTabs(tab, index) {
		this.selectedTab = tab.value;
		this.selectedIndex = index;
	}
	downloadFundPDF() {
		this.loading = true;
		const url = 'cop/risk/fund/pdf/download?asOfDate=' + this.defaultService.getStartDate(this.asOfDate) + '&sortBy=%7B%22aum%22%3A%22desc%22%7D';
		this.apiService.pdfDownload(url, 'application/pdf', 'Fund Dashboard');
		this.messageService.add({ severity: 'success', detail: 'The file is being downloaded, Please check the downloads.' });
		this.loading = false;
	}

	getFundList() {
		const asOfDate = this.datePipe.transform(this.asOfDate, 'yyyy-MM-dd') + 'T00:00:00.000Z';
		localStorage.setItem('opsAsOfDate', asOfDate);
		localStorage.setItem('asOfDate', asOfDate);
		this.query['asOfDate'] = asOfDate
		if (!this.isDelistedFunds) {
			this.query['sortBy'] = { 'aum': 'desc' };
			this.query['active'] = 'Y';
		} else {
			this.query['sortBy'] = { 'aum': 'desc' };
			this.query['active'] = 'N';
		}
		this.defaultService.riskfind(this.query, 0, 1000, 'fundsummary').then(data => {
			this.loading = false;
			if (data['status'] && data['status'] === 'success') {
				if (data['firmReports']) {
					this.totalAum = data['firmReports']['aumTotal'];
					this.getLiquidationByExposureData(data);
					this.setDoughnutChartData(data);
					this.setAumByFundTypeData(data);
				} else {
					this.data = {};
					this.doughnutChartData = {};
					this.pieLegendData = [];
					this.pieChartData = {};
					this.doughnutLegendData = [];
					this.doughnutOptions = {};
					this.chartOptions = {};
					this.totalAum = undefined;
					this.prepareFundTpyeAndAssetClass(data);
				}
				this.arrangeTabs(data);
				this.fundList = data['summaries'];
				this.noOfFunds = data['noOfFunds'];
				this.noOfSummaries = data['noOfSummaries'];
				for (let index = 0; index < this.fundList.length; index++) {
					const element = this.fundList[index];
					element['indexReturnYTD'] = isNaN(element['indexReturnYTD']) ? undefined : element['indexReturnYTD'];
					element['fundReturnYTD'] = isNaN(element['fundReturnYTD']) ? undefined : element['fundReturnYTD'];

					const canManagePortfolioPriv = { 'screenName': 'FundDetails', 'subModule': 'canManagePortfolio' };
					if (this.privilegeService.isAuthorized(canManagePortfolioPriv)) {
						if (element.liquidationCheck === 'Fail' || element.tdCheck === 'Fail') {
							this.fundList.splice(index, 1);
							this.fundList.unshift(element);
						}
					}
				}
			} else {
				this.messageService.add({ severity: 'error', detail: data['status'] });
			}
		}).catch(err => {
			this.loading = false;
			if(err.status !== 401) {
			this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
			}
		});
	}

	prepareFundTpyeAndAssetClass(data) {
		data['firmReports'] = {};
		const aumAssetClass = [{
			type: 'Bonds', value: 0
		}, {
			type: 'Equities', value: 0
		}, {
			type: 'Derivatives', value: 0
		}, {
			type: 'Others', value: 0
		}];
		const aumByFundType = [{
			type: 'EM', value: 0
		}, {
			type: 'BM', value: 0
		}, {
			type: 'LE', value: 0
		}, {
			type: 'EE', value: 0
		}];
		data['firmReports']['aumByAssetClass'] = aumAssetClass;
		data['firmReports']['aumByFundType'] = aumByFundType;
	}

	onSelectFilter(event, filter, value) {
		this.loading = true;
		if (event.checked) {
			this.pushFiltersToArray(filter, value);
			return;
		}
		this.removeFiltersFromArray(filter, value);
	}

	pushFiltersToArray(filter, value) {
		const selectedFilters = [...this.selectedFilters];
		filter.forEach(element => {
			if (selectedFilters.indexOf(element) === -1) {
				selectedFilters.push(element);
			}
		});
		this.selectedFilters = selectedFilters;
		this.prepareDataForFilter(value);
	}

	prepareDataForFilter(filter) {
		switch (this.selectedIndex) {
			case 0:
				this.setThresholdFilters(filter);
				break;
			case 1:
				this.setAssetClassFilters(filter)
				// this.fundTypes = DashboardService.getFiltersOnSelect(filter, this.selectedFilters);
				// this.query['fundType'] = this.fundTypes;
				// this.getFundList();
				break;
			case 2:
				this.setDomicileFilters(filter);
				break;
			case 3:
				this.setProductTypeFilters(filter)
				// this.fundTypes = DashboardService.getFiltersOnSelect(filter, this.selectedFilters);
				// this.query['fundType'] = this.fundTypes;
				// this.getFundList();
				break;
		}
	}

	setThresholdFilters(value) {
		switch (value) {
			case 'TD':
				if (this.isFilterExists('TD')) {
					this.addQuery('tdCheck', 'Fail');
					break;
				}
				this.removeQuery('tdCheck');
				break;
			case 'LQ':
				if (this.isFilterExists('LQ')) {
					this.addQuery('liquidationCheck', 'Fail');
					break;
				}
				this.removeQuery('liquidationCheck');
				break;
			case 'FV':
				if (this.isFilterExists('FV')) {
					this.addQuery('noOfSuspStocks', { '$gt': 0 });
					break;
				}
				this.removeQuery('noOfSuspStocks');
				break;
		}
	}

	isFilterExists(value) {
		return this.thresholdFilters.includes(value);
	}

	addQuery(key, value) {
		this.query[key] = value;
		this.getFundList();
	}

	removeQuery(key) {
		delete this.query[key];
		this.getFundList();
	}

	setDomicileFilters(value) {
		if (this.domicileFilters.length === 0) {
			delete this.query['location'];
			this.getFundList();
		} else {
			this.query['location'] = this.domicileFilters;
			this.getFundList();
		}
	}
 
	setAssetClassFilters(value) {
		if (this.assetFilters.length === 0) {
			delete this.query['assetClass'];
			this.getFundList();
		} else {
			this.query['assetClass'] = this.assetFilters;
			this.getFundList();
		}
	}

	setProductTypeFilters(value) {
		if (this.byProductFilters.length === 0) {
			delete this.query['productType'];
			this.getFundList();
		} else {
			this.query['productType'] = this.byProductFilters;
			this.getFundList();
		}
	}

	removeFiltersFromArray(filter, value) {
		// this.fundTypes = DashboardService.getFilterAfterRemove(value, this.selectedFilters);
		const selectedFilters = [...this.selectedFilters];
		selectedFilters.splice(selectedFilters.indexOf(value), 1);
		this.selectedFilters = selectedFilters;
		if (this.selectedIndex === 3) {
			this.setProductTypeFilters(value);
			return;
		}
		if (this.selectedIndex === 2) {
			this.setDomicileFilters(value);
			return;
		}
		if (this.selectedIndex === 1) {
			this.setAssetClassFilters(value);
			return;
		}
		if (this.selectedIndex === 0) {
			this.setThresholdFilters(value);
			return;
		}

		// this.query['fundType'] = this.fundTypes;
		this.getFundList();
	}

	removeFilteredData(filter) {
		this.loading = true;
		const thresholdFilters = this.thresholdFilters ? [...this.thresholdFilters] : [];
		const assetFilters = this.assetFilters ? [...this.assetFilters] : [];
		const domicileFilters = this.domicileFilters ? [...this.domicileFilters] : [];
		const byProductFilters = this.byProductFilters ? [...this.byProductFilters] : [];
		// this.fundTypes = DashboardService.getFilterAfterRemove(filter, this.selectedFilters);
		this.selectedFilters.forEach(element => {
			if (element === filter) {
				if (thresholdFilters.indexOf(filter) !== -1) {
					thresholdFilters.splice(thresholdFilters.indexOf(filter), 1);
					this.thresholdFilters = thresholdFilters;
				}
				if (assetFilters.indexOf(filter) !== -1) {
					assetFilters.splice(assetFilters.indexOf(filter), 1);
					this.assetFilters = assetFilters;
				}
				if (domicileFilters.indexOf(filter) !== -1) {
					domicileFilters.splice(domicileFilters.indexOf(filter), 1);
					this.domicileFilters = domicileFilters;
				}
				if (byProductFilters.indexOf(filter) !== -1) {
					byProductFilters.splice(byProductFilters.indexOf(filter), 1);
					this.byProductFilters = byProductFilters;
				}
				this.selectedFilters.splice(this.selectedFilters.indexOf(filter), 1);
			}
		});
		if (filter === 'CURR' || filter === 'MM' || filter === 'EQTY' || filter === 'CMDTY' || filter === 'BOND' || filter === 'FI') {
			this.setAssetClassFilters(filter);
			// this.query['fundType'] = this.fundTypes;
			// this.getFundList();
			return;
		}
		if (filter === 'PF' || filter === 'ETF' || filter === 'LI' || filter === 'AA' || filter === 'MF') {
			this.setProductTypeFilters(filter)
			// this.query['fundType'] = this.fundTypes;
			// this.getFundList();
			return;
		}
		// if (filter === 'Equity' || filter === 'Fixed Income' || filter === 'Derivatives' || filter === 'Commodities') {
		// 	this.query['fundType'] = this.fundTypes;
		// 	this.getFundList();
		// 	return;
		// }
		// if (filter === 'ETF' || filter === 'Active Fund') {
		// 	this.query['fundType'] = this.fundTypes;
		// 	this.getFundList();
		// 	return;
		// }
		if (filter === 'TD' || filter === 'LQ' || filter === 'FV') {
			this.setThresholdFilters(filter);
			return;
		}
		this.setDomicileFilters(filter);
	}
	onClickClearAll() {
		this.loading = true;
		this.selectedFilters = [];
		this.thresholdFilters = [];
		this.assetFilters = [];
		this.domicileFilters = [];
		this.byProductFilters = [];
		this.query = {};
		this.getFundList();
		// this.fundTypes = [];
	}

	getAsOfDate(selectedDate) {
		this.asOfDate = selectedDate;
		this.loading = true;
		this.getFundList();
		const obj = { 'toDate': this.defaultService.getStartDate(selectedDate) };
		this.getAumOfTheFunds(obj);
		this.setAumChartDateFilters();
		localStorage.setItem('asOfDate', this.asOfDate);
		this.chartsDetails = undefined;
		this.PDCC_ChartDetails = undefined;
		this.tdteChartData = undefined
		this.dcchartData = undefined;
		this.liquidityChartData = undefined
		if (this.canAccessNAVOneReconWidget ||
			this.canAccessNAVTwoReconWidget) {
			this.reconLoader = true;
			this.getDashboardDetails();
		}
		if (this.canAccessPDCCReconWidget) {
			this.PDCC_Loader = true;
			this.get_PDCC_widgetDetails();
		}
		if (this.privilegeService.isAuthorized(this.canAccessDcCalculation)) {
			this.dcLoader = true;
			this.getDcDashboardDetails()
		}
		if (this.privilegeService.isAuthorized(this.canViewTdTeChart)) {
			this.tdtdLoader = true
			this.getTdTeData()
		}
		if (this.privilegeService.isAuthorized(this.canViewLiquidityChart)) {
			this.liquidityLoader = true
			this.getLiquidityData()
		}
		if (this.privilegeService.isAuthorized(this.canViewFirmLevelSuspendedStocksPriv)) {
			this.suspendedStockLoader = true
			this.getSuspendedStockData()
		}
	}

	onClickFund(data: any) {
		// if (this.isDelistedFunds || data.netAssetValue || data.noOfHoldings > 0) {
			this.showFundView = true;
			this.selectedFund = data;
			localStorage.setItem('fundInfo', JSON.stringify(data));
			localStorage.setItem('asOfDate', this.asOfDate);
			localStorage.setItem('navigationType', 'dashboard');
			this.router.navigate(['/funddetails', data.fundID]);
		// } else {
		// 	this.showConfirmationPopup = true;
		// 	this.message = 'No data available for the selected day, Please select another trading date';
		// }
	}

	getImgUrl(fund) {
		let baseURL;
		const hostName = window.location.hostname;
		if (hostName === 'amc-demo.tectoro.com') {
			baseURL = 'https://kyc-qa.tectoro.com/api/';
		} else {
			baseURL = this.apiService.getBaseAPIUrl();
		}
		const date = new Date(this.asOfDate);
		const dateVal = ('0' + date.getDate()).slice(-2) +
			('0' + (date.getMonth() + 1)).slice(-2) +
			('' + date.getFullYear()).substring(2);
		return fund['netAssetValue'] ? baseURL
			+ 'commons/stream?url=/data/images/' + fund['fundID'] +
			'hpr' + dateVal + '.png' : '';
	}

	getAumOfTheFunds(obj) {
		this.defaultService.find(obj, 0, 0, 'csoprisk/firm/reports').then(data => {
			if (data['status'] === 'success') {
				this.loading = false;
				if (this.privilegeService.isAuthorized(this.canViewFirmLevelAUMPriv)) {
					this.setHighchartsData(data);
				}
			}
		});
	}

	setCaptialMarketIFrame() {
		let url = 'http://www.csopasset.com/en/home';
		const filter = { '_id': 'DASHBOARD_CC_IFRAME_URL' };
		this.defaultService.get(filter, 1, 1000, 'commons/enums').then(data => {
			if (data['status'] === 'success' && data.rows.length > 0) {
				url = data.rows[0]?.value;
				this.ccIFrame = '<iframe id="ifrm" src=\"' + url + '\" onload=\"resizeIframe(this)\" style="width: -webkit-fill-available; border-width: 0px; height:900px"></iframe>';
			}
		}).catch(err => {
			if(err.status !== 401) {
			this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
			}
		});

	}

	onDateChange(date) {
		const obj = {};
		if (date[0] && date[1]) {
			this.loading = true;
			const startDate = this.defaultService.getStartDate(new Date(date[0]));
			const endDate = this.defaultService.getEndDate(new Date(date[1]));
			obj['fromDate'] = startDate;
			obj['toDate'] = endDate;
			this.getAumOfTheFunds(obj);
			this.calendar.overlayVisible = false;
		}
	}
	isDisable(obj) {
		if (obj.value === 0) {
			return true;
		}
		return false;
	}
	getFundStatusFlag(fund) {
		if ((fund.assetClass === 'EQTY') &&
			(fund.tdCheck === 'Fail' || fund.liquidationCheck === 'Fail')) {
			return '/assets/images/flag-error.svg';
		} else if ((fund.assetClass === 'FI' || fund.assetClass === 'BOND') && fund.tdCheck === 'Fail') {
			return '/assets/images/flag-error.svg';
		} else if ((fund.assetClass === 'FI' || fund.fundType === 'BOND') && fund.tdCheck === 'Pass') {
			return '/assets/images/flag-success.svg';
		} else {
			return '/assets/images/flag-success.svg';
		}
	}
	onClickSuspDownload() {

		const query = 'asOfDate=' + this.defaultService.getStartDate(this.asOfDate);
		const url = 'cop/risk/suspendedstocks/report';
		this.apiService.getFileDownload(url, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', query, 'SuspendedStocks'
		);
		this.messageService.add(
			{ severity: 'success', detail: 'Full stock suspended list downloaded successfully' }
		);
	}
}