import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableSchema } from '../utils/table-schema';
import { thousands_separators, thousands_separatorsd } from '../utils/utilities.function';
@Component({
	selector: 'app-expandable-table',
	templateUrl: './expandable-table.component.html',
	styleUrls: ['./expandable-table.component.scss'],
})
export class ExpandableTableComponent implements OnInit {
	@Input() tableSchema: any;
	@Input() pdccFirstInnerTable : any;
	@Input() pdccSubInnerTable : any;
	@Input() innerTableSchema: any;
	@Input() innerTableSchema1: any;
	@Input() innerTableSchema2: any;
	@Input() innerTableSchema3: any;
	@Input() hasExport: boolean;
	@Input() tableData: any[];
	@Input() isInnerTableFlex = false;
	@Input() isInnerChildTableFlex = false;
	@Input() headerSelectOptions: any;
	@Input() isLoading: any;
	@Input() id: any;
	@Input() from: any;
	@Input() source: any;
	@Input() recon_type: any
	originalList: any;
	unMachecList = [];
	@Output() changeMatchedStatus = new EventEmitter<boolean>();
	@Output() clickExport = new EventEmitter<string>();
	@Input() matchStatus: boolean;
	checked: boolean;
	secDescSchema = TableSchema['secDescInnertable1'];
	ammountDiffTotal: any;
	accruedFcDifftotal: any;
	valueFcDifftotal: any;
	copyTableData: any = [];
	@Output() editInParent = new EventEmitter();
	selectedRows: any = [];
	isSelectedRows: boolean;
	@Output() upudateInParent = new EventEmitter();
	@Input() navAccrualTotals: any = {};
	@Input() tradingTotals: any = {};
	@Input() nonTradingTotals: any = {};
	@Input() nav2CompositionTotals: any = {};
	isExpanded = true;
	expandedRows: any = {};
	@Input() isExpandAll: boolean;
	@Input() cashCompTotals: any = {};

	constructor(
		private datePipe: DatePipe
	) { }


	ngOnInit() {
		
		// if (this.from === 'pdccFund') {
		// 	this.prepare_pdcc_tableData();
			
		// 	this.tableData.forEach(data => {
		// 		this.expandedRows[data[this.id]] = true;
		// 	});
		// 	return;
		// }

		// if (this.tableData?.length > 0) {
		// 	this.updateRowGroupMetaData(this.tableData);
		//  }

		
		if(this.tableData){
			this.copyTableData = [...this.tableData];
		}
		this.checked = this.matchStatus;
		

	}

	clicked(a:any,b:any,c:any){
		console.log("clicked event ",a, b ,c)
	}
	ngOnChanges(){
		if(this.tableData && this.from === "pdccFund"){
            this.tableData.forEach((data:any)=>{
                this.expandedRows[(data?.fundID).toString()]  = true
                    data?.tickersInfo?.forEach((ticker:any)=>{
                        this.expandedRows[(ticker?.class_id).toString()] = true
                    })
            })
        }
 
	}

	prepare_pdcc_tableData() {
		this.tableData.forEach(element => {
			let columns = [
				{
					field: 'category',
					header: '',
					filterType: 'text',
					dataType: 'text',
					inlineEdit: false,
					width: '300px'
				},
			]
			for (let i = 0; i < element.ticker_count; i++) {
				columns.push(
					{
						field: `ticker${i + 1}`,
						header: `TICKER ${i + 1}`,
						filterType: 'text',
						dataType: 'pdcchasNegativeVal',
						inlineEdit: false,
						width: '200px',
					},
				)
			}
			element.columns = columns;
			if (element.children.length > 0) {
				element.workflow_id_obj = element.children[element.children.length - 1];
				element.children.splice(element.children.length - 1, 1);
			}
		});
		this.copyTableData = [...this.tableData];
		this.checked = this.matchStatus;
	}


	getMeToCcInfo(tableList: any, colField: any) {
		let e = { tableList: tableList, colField: colField }
		this.editInParent.emit(e);
	}

	expandAll() {
		if (!this.isExpanded) {
			this.tableData.forEach(data => {
				this.expandedRows[data[this.id]] = true;
			});
		} else {
			this.expandedRows = {};
		}				
		this.isExpanded = !this.isExpanded;
	}

	thousandSeparators(value) {
		return thousands_separators(value);
	}

	thousandSeparatorsDecimal(value) {
		return thousands_separatorsd(value);
	}

	onSort(e: any) {
		this.calculateTotal(e.filteredValue);
	}

	calculateTotal(data: any) {
		if (this.from == 'GAV Trading') {
			this.tradingTotals['tradingQuantityDiffTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.quantity_diff); }, 0);
			this.tradingTotals['tradingPriceDiffTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.price_in_local_ccy_diff); }, 0);
			this.tradingTotals['tradingLocalValTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.local_value); }, 0);
			this.tradingTotals['tradingFundValTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.fund_value); }, 0);
			this.tradingTotals['tradingAccrLcTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.accr_lc); }, 0);
			this.tradingTotals['tradingAccrFcTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.accr_fc); }, 0);
		}
		if (this.from == 'GAV Non Trading') {
			this.nonTradingTotals['nonTradingLocalValTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.local_value_variance); }, 0);
			this.nonTradingTotals['nonTradingFundValTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.fund_value_variance); }, 0);
		}
		if (this.from == 'NAV Accrual') {
			this.navAccrualTotals['navAccLocalValTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.local_value_variance); }, 0);
			this.navAccrualTotals['navAccFundValTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.fund_value_variance); }, 0);
		}
		if (this.from == 'Nav2 composition') {
			this.nav2CompositionTotals['ammountDiffTotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.quantity_diff); }, 0);
			this.nav2CompositionTotals['accruedFcDifftotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.accrued_fc_diff); }, 0);
			this.nav2CompositionTotals['valueFcDifftotal'] = data.reduce(function (acc, val) { return acc + parseFloat(val.value_fc_diff); }, 0);
		}
		if (this.from === 'pdcc') {
			this.cashCompTotals['fundValTotal'] = data.reduce(function (acc, val) { return acc + val.FUND_VALUE; }, 0);
		}
	}

	onChangeStatus() {
		this.changeMatchedStatus.emit(this.checked);
	}

	checkForNaN(value: any) {
		return isNaN(value);
	}

	onSelectDate(date) {
		let filteredDt = [];
		if (date && filteredDt.length == 0) {
			const dt = this.datePipe.transform(date, 'dd MMM YYYY');
			filteredDt = this.copyTableData.filter((d: any) => {
				const signOffDate = this.datePipe.transform(d.sign_off_date, 'dd MMM YYYY');
				if (signOffDate == dt) {
					return d;
				}
			});
			this.tableData = filteredDt;
		} else {
			this.tableData = [...this.copyTableData];
		}
	}

	onClickClose() {
		this.tableData = [...this.copyTableData];
	}

	setFundCodeDatatype() {
		if (JSON.parse(localStorage.getItem('selectedRows'))?.length > 0) {
			this.isSelectedRows = true;
		} else {
			this.isSelectedRows = false;
		}
	}
	onSelectRow() {
		this.upudateInParent.emit(this.selectedRows);
		localStorage.setItem('selectedRows', JSON.stringify(this.selectedRows));
		this.setFundCodeDatatype();
	}
	onRowUnselect(row) {
		const list = this.tableData.filter(obj => row.data.tradar_code !== obj.tradar_code);
		this.selectedRows = [...list];
		localStorage.setItem('selectedRows', JSON.stringify(this.selectedRows));
		this.upudateInParent.emit(this.selectedRows);
		this.setFundCodeDatatype();
	}
	selectAllRows() {
		const filterdData = this.tableData.filter((d: any) => d.recon_status == 'SUCCESSFUL');
		localStorage.setItem('selectedRows', JSON.stringify(filterdData));
		this.upudateInParent.emit(filterdData);
		this.setFundCodeDatatype();
	}

	onExpandClick(e: any) {		

		// const pmaLength = e.pma?.length;
		
		// const faLength = e.fa?.length;

		// const varianceLength = e.variance?.length;
		// const maxLength = Math.max(pmaLength, faLength, varianceLength);
		// if (maxLength !== faLength) {
		// 	if (faLength == 0) {
		// 		for (let i = 0; i < maxLength; i++) {
		// 			e['fa'][i] = {
		// 				'field1': '',
		// 				'field2': '',
		// 				'fund_value': '',
		// 				'local_value': ''
		// 			};
		// 		}
		// 	} else {
		// 		for (let i = faLength; i < maxLength; i++) {
		// 			e['fa'][i] = {
		// 				'field1': '',
		// 				'field2': '',
		// 				'fund_value': '',
		// 				'local_value': ''
		// 			};
		// 		}
		// 	}
		// }
		// if (maxLength !== pmaLength) {
		// 	if (pmaLength == 0) {
		// 		for (let i = 0; i < maxLength; i++) {
		// 			e['pma'][i] = {
		// 				'field1': '',
		// 				'field2': '',
		// 				'fund_value': '',
		// 				'local_value': ''
		// 			};
		// 		}
		// 	} else {
		// 		for (let i = pmaLength; i < maxLength; i++) {
		// 			e['pma'][i] = {
		// 				'field1': '',
		// 				'field2': '',
		// 				'fund_value': '',
		// 				'local_value': ''
		// 			};
		// 		}
		// 	}
		// }
		// if (maxLength !== varianceLength) {
		// 	if (varianceLength == 0) {
		// 		for (let i = 0; i < maxLength; i++) {
		// 			e['variance'][i] = {
		// 				'fund_value_variance': '',
		// 				'local_value_variance': ''
		// 			};
		// 		}
		// 	} else {
		// 		for (let i = varianceLength; i < maxLength; i++) {
		// 			e['variance'][i] = {
		// 				'fund_value_variance': '',
		// 				'local_value_variance': ''
		// 			};
		// 		}
		// 	}
		// }

	}

	exportPdf() {
		this.clickExport.emit('pdf');
	}

	exportExcel() {
		this.clickExport.emit('xlsx');
	}

	onInnerExpandClick(e){
		console.log(e,'onInnerExpandClick');
		
	}
}
