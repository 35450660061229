import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-confirmation-popup',
	templateUrl: './confirmation-popup.component.html',
	styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {
	@Input() isDisplay = false;
	@Output() isDisplayChange = new EventEmitter<boolean>();
	@Output() onClickYes = new EventEmitter();
	@Output() onClickNo = new EventEmitter();
	@Input() header = '';
	@Input() customHeader: any;
	@Input() check = '';
	@Input() message = '';
	@Input() icon: any;
	@Input() yes: any;
	@Input() ok: any;
	@Input() no: any;
	constructor() { }

	ngOnInit() {		
	}

	// On Click Yes
	clickYes() {
		this.close();
		this.onClickYes.emit(true);
	}

	// On click No
	clickNo() {
		this.close();
		this.onClickNo.emit(true);
	}

	// To close model.
	close() {
		this.isDisplay = false;
		this.onClickNo.emit(true);
		this.isDisplayChange.emit(this.isDisplay);
	}

	clickYesForFlexFilePopUp() {
		this.onClickYes.emit(true);
	}

}
