import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor(
    private http: HttpClient
  ) { }

  getInfo() {
    // return this.http.get(this.sdata, { responseType: 'text' });
  }

  getFiles() {
    return this.http.get<any>('assets/tectoro/dmpdata.json')
      .toPromise()
      .then(res => <TreeNode[]>res.data);
  }


  get_sub_inner_data(){
    return {
      "data" : [
        {
          "field":"NAV/Share ",
          "calculated" : 10,
          "fa" : 10,
        },
        {
          "field":"NAV per Creation unit (FC) ",
          "calculated" : 10,
          "fa" : 10,
        },
        {
          "field":"Actual Cash per creation Unit ",
          "calculated" : 10,
          "fa" : 10,
        },
        {
          "field":"Total Units",
          "calculated" : 10,
          "fa" : 10,
        },
        {
          "field":"AUM ",
          "calculated" : 10,
          "fa" : 10,
        },
        {
          "field":"Premium Discount ",
          "calculated" : 10,
          "fa" : 10,
        },
      ],
      "status" : "success"
    }
  }


  getUnderliers() {
    return {
      "data": [
        {
          "category": "UNDERLIER_STOCK_FILL_DATE",
          "category_desc": "Underlier Stock Fill Date",
          "code": "T0",
          "code_desc": "T0",
          "id": "2FD9B7DD-F482-40B4-917C-55DC54EB86B8",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-27T10:25:23.7016932Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "UNDERLIER_STOCK_FILL_DATE",
          "category_desc": "Underlier Stock Fill Date",
          "code": "T2",
          "code_desc": "T2",
          "id": "57413A0E-A9B9-4236-A38A-B3EEC04AAB0E",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-27T10:25:47.8284104Z",
          "version_user": "OPSUSER"
        }
      ],
      "status": "success"
    }
  }

  getJobDEF() {
    return {
      "data": [
        //  {
        //     "ackQueue":"csop_cdb_mrd_job_req_qa",
        //     "active":true,
        //     "command":"test_command",
        //     "description":"Index Information Request",
        //     "executionType":"",
        //     "jobDefId":1,
        //     "jobReference":"INDEX_INFORMATION_REQ",
        //     "params":{
        //        "asOfDate":"2022-12-14T00:00:00Z",
        //       //  "fromDate":"2020-06-01T00:00:00Z",
        //        "jobType":"INDEX_INFORMATION_REQ",
        //       //  "toDate":"2022-12-14T00:00:00Z"
        //     },
        //     "reqQueue":"csop_cdb_mrd_job_req_dev",
        //     "respQueue":"csop_cdb_mrd_job_req_qa",
        //     "type":"JOB"
        //  },
        {
          "ackQueue": "csop_cdb_mrd_job_req_qa",
          "active": true,
          "command": "test_command",
          "description": "Index Information Request",
          "executionType": "",
          "jobDefId": 1,
          "jobReference": "INDEX_INFORMATION_REQ",
          "params": {
            //    "asOfDate":"2022-12-14T00:00:00Z",
            "fromDate": "2020-06-01T00:00:00Z",
            "jobType": "INDEX_INFORMATION_REQ",
            "toDate": "2022-12-14T00:00:00Z"
          },
          "reqQueue": "csop_cdb_mrd_job_req_dev",
          "respQueue": "csop_cdb_mrd_job_req_qa",
          "type": "JOB"
        }
      ],
      "status": "success"
    }
  }

  getFiles1() {
    return this.http.get<any>('assets/tectoro/dmpdata.json')
      .toPromise()
      .then(res => <TreeNode[]>res.bata);
  }
  getProcessedJobsNew() {
    return {
      "count": 2,
      "data": [
        {
          "endTime": "2023-01-01T00:00:00Z",
          "errorDetails": [{
            "errorMsg": "QWDYQBWYDB",
            "fundId": "127376",
            "id": 7,
            "shareClassId": "QWDWYBD"
          }],
          "errorMsg": "asdinn",
          "jobProcessID": 272,
          "belongsTo": "JOB",
          "jobReference": "INDEX_CHANGE_RESP",
          "jobStatus": "SUCCESS",
          "reRun": true,
          "params": {
            "asofDate": "2020-06-01T00:00:00Z",
            "fundIds": "INDEX_INFORMATION_REQ",
          },
          "startTime": "0001-01-01T00:00:00Z"
        },
        {
          "endTime": "2023-01-04T00:00:00Z",
          "errorDetails": [{
            "errorMsg": "When You Find Something So they Can Make Anything Up to THe Mark By The Tommarow Land for today Land",
            "fundId": "12735789",
            "id": 8,
            "shareClassId": "SFGWGGA"
          }],
          "errorMsg": "asdinn",
          "jobProcessID": 273,
          "jobReference": "CA_PROCESSING",
          "jobStatus": "FAILED",
          "reQueue": true,
          "params": {
            "fromDate": "2020-06-01T00:00:00Z",
            "jobType": "INDEX_INFORMATION_REQ",
            "toDate": "2022-12-14T00:00:00Z",
            "asofDate": "",
            "slaType": "T0",
          },
          "startTime": "0001-01-01T00:00:00Z"
        },
        {
          "endTime": "2023-01-04T00:00:00Z",
          "errorDetails": [{
            "errorMsg": "ASDAQ12EEEEEDFSDF",
            "fundId": "12735789",
            "id": 8,
            "shareClassId": "SFGWGGA"
          }],
          "errorMsg": "asdinn",
          "jobProcessID": 274,
          "jobReference": "CA_PROCESSING",
          "jobStatus": "FAILED",
          "reRun": true,
          "reQueue": true,
          "params": {
            "fromDate": "2020-06-01T00:00:00Z",
            "jobType": "INDEX_INFORMATION_REQ",
            "toDate": "2022-12-14T00:00:00Z"
          },
          "startTime": "0001-01-01T00:00:00Z"
        },
        {
          "endTime": "2023-01-04T00:00:00Z",
          "errorDetails": [{
            "errorMsg": "ASDAQ12EEEEEDFSDF",
            "fundId": "12735789",
            "id": 8,
            "shareClassId": "SFGWGGA"
          }],
          "errorMsg": "asdinn",
          "jobProcessID": 274,
          "jobReference": "CA_PROCESSING",
          "jobStatus": "FAILED",
          "params": {
            "fromDate": "2020-06-01T00:00:00Z",
            "jobType": "INDEX_INFORMATION_REQ",
            "toDate": "2022-12-14T00:00:00Z"
          },
          "startTime": "0001-01-01T00:00:00Z"
        }
      ],
      "status": "success"
    }
  }
  getPds() {
    return [
      {
        "PartyCategory": "PD",
        "PartyID": "PD433",
        "PartyNameInChinese": "ABN AMRO Clearing Bank N.V. Singapore Branch,",
        "PartyNameInEnglish": "ABN AMRO Clearing Bank N.V. Singapore Branch"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD434",
        "PartyNameInChinese": "ABN AMRO Clearing Hong Kong Limited,",
        "PartyNameInEnglish": "ABN AMRO Clearing Hong Kong Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD435",
        "PartyNameInChinese": "Barclays Bank PLC,",
        "PartyNameInEnglish": "Barclays Bank PLC"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD436",
        "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司,",
        "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD437",
        "PartyNameInChinese": "法國巴黎證券服務公司,",
        "PartyNameInEnglish": "BNP Paribas Securities Services"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD438",
        "PartyNameInChinese": "中銀國際證券有限公司,",
        "PartyNameInEnglish": "BOCI Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD439",
        "PartyNameInChinese": "交銀國際證券有限公司,",
        "PartyNameInEnglish": "BOCOM International Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD440",
        "PartyNameInChinese": "國泰證券(香港)有限公司,",
        "PartyNameInEnglish": "Cathay Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD441",
        "PartyNameInChinese": "CGS-CIMB Securities (Singapore) Pte Ltd,",
        "PartyNameInEnglish": "CGS-CIMB Securities (Singapore) Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD442",
        "PartyNameInChinese": "中國銀河國際證券(香港)有限公司,",
        "PartyNameInEnglish": "China Galaxy International Securities (Hong Kong) Co., Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD443",
        "PartyNameInChinese": "興證國際證券有限公司,",
        "PartyNameInEnglish": "China Industrial Securities International Brokerage Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD444",
        "PartyNameInChinese": "中國國際金融香港證券有限公司,",
        "PartyNameInEnglish": "China International Capital Corporation Hong Kong Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD445",
        "PartyNameInChinese": "招商證券(香港)有限公司,",
        "PartyNameInEnglish": "China Merchant Securities (HK) Co., Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD446",
        "PartyNameInChinese": "中信建投(國際)証券有限公司,",
        "PartyNameInEnglish": "China Securities (International) Brokerage Company Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD447",
        "PartyNameInChinese": "聯昌證券有限公司,",
        "PartyNameInEnglish": "CIMB Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD448",
        "PartyNameInChinese": "信達國際證券有限公司,",
        "PartyNameInEnglish": "Cinda International Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD449",
        "PartyNameInChinese": "中信証券經紀(香港)有限公司,",
        "PartyNameInEnglish": "CITIC Securities Brokerage (HK) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD450",
        "PartyNameInChinese": "花旗環球金融亞洲有限公司,",
        "PartyNameInEnglish": "Citigroup Global Markets Asia Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD451",
        "PartyNameInChinese": "中信里昂證券有限公司,",
        "PartyNameInEnglish": "CLSA LIMITED"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD452",
        "PartyNameInChinese": "招銀國際證券有限公司,",
        "PartyNameInEnglish": "CMB International Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD453",
        "PartyNameInChinese": "民銀證券有限公司,",
        "PartyNameInEnglish": "CMBC Securities Company Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD454",
        "PartyNameInChinese": "瑞士信貸證券(香港)有限公司,",
        "PartyNameInEnglish": "Credit Suisse Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD455",
        "PartyNameInChinese": "DBS Vickers Securities (Singapore) Pte Ltd,",
        "PartyNameInEnglish": "DBS Vickers Securities (Singapore) Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD456",
        "PartyNameInChinese": "德意志證券亞洲有限公司,",
        "PartyNameInEnglish": "Deutsche Securities Asia Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD457",
        "PartyNameInChinese": "安信國際證券(香港)有限公司,",
        "PartyNameInEnglish": "Essence International Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD458",
        "PartyNameInChinese": "Flow Traders Asia Pte. Ltd,",
        "PartyNameInEnglish": "Flow Traders Asia Pte. Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD459",
        "PartyNameInChinese": "Futu Singapore Pte Ltd,",
        "PartyNameInEnglish": "Futu Singapore Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD460",
        "PartyNameInChinese": "廣發証券(香港)經紀有限公司,",
        "PartyNameInEnglish": "GF Securities (Hong Kong) Brokerage Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD461",
        "PartyNameInChinese": "高盛(亞洲)證券有限公司",
        "PartyNameInEnglish": "Goldman Sachs (Asia) Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD462",
        "PartyNameInChinese": "國泰君安證券(香港)有限公司",
        "PartyNameInEnglish": "Guotai Junan Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD463",
        "PartyNameInChinese": "國元証券經紀(香港)有限公司",
        "PartyNameInEnglish": "Guoyuan Securities Brokerage (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD464",
        "PartyNameInChinese": "海通國際證券有限公司",
        "PartyNameInEnglish": "Haitong International Securities Company Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD465",
        "PartyNameInChinese": "聯合證券有限公司",
        "PartyNameInEnglish": "Head \u0026 Shoulders Securities Limited "
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD466",
        "PartyNameInChinese": "華泰金融控股(香港)有限公司,",
        "PartyNameInEnglish": "Huatai Financial Holdings (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD467",
        "PartyNameInChinese": "工銀國際證券有限公司",
        "PartyNameInEnglish": "ICBC International Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD468",
        "PartyNameInChinese": "iFAST Financial Pte Ltd",
        "PartyNameInEnglish": "iFAST Financial Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD469",
        "PartyNameInChinese": "J.P. Morgan Broking (Hong Kong) Limited",
        "PartyNameInEnglish": "J.P. Morgan Broking (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD470",
        "PartyNameInChinese": "Jane Street Financial Limited",
        "PartyNameInEnglish": "Jane Street Financial Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD471",
        "PartyNameInChinese": "凱基證券亞洲有限公司",
        "PartyNameInEnglish": "KGI Asia Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD472",
        "PartyNameInChinese": "韓國投資證券亞洲有限公司",
        "PartyNameInEnglish": "Korea Investment \u0026 Securities Asia Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD473",
        "PartyNameInChinese": "長橋證券(香港)有限公司 ",
        "PartyNameInEnglish": "Long Bridge HK Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD474",
        "PartyNameInChinese": "陸國際(香港)有限公司",
        "PartyNameInEnglish": "Lu International (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD475",
        "PartyNameInChinese": "元富證券(香港)有限公司",
        "PartyNameInEnglish": "Masterlink Securities (Hong Kong) Corporation Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD476",
        "PartyNameInChinese": "Merrill Lynch (Singapore) Pte Ltd",
        "PartyNameInEnglish": "Merrill Lynch (Singapore) Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD477",
        "PartyNameInChinese": "Merrill Lynch Far East Limited",
        "PartyNameInEnglish": "Merrill Lynch Far East Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD478",
        "PartyNameInChinese": "未來資產證券(香港)有限公司",
        "PartyNameInEnglish": "Mirae Asset Securities (HK) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD479",
        "PartyNameInChinese": "Morgan Stanley Hong Kong Securities Limited",
        "PartyNameInEnglish": "Morgan Stanley Hong Kong Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD480",
        "PartyNameInChinese": "野村國際(香港)有限公司",
        "PartyNameInEnglish": "Nomura International (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD481",
        "PartyNameInChinese": "東英亞洲證券有限公司",
        "PartyNameInEnglish": "Oriental Patron Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD482",
        "PartyNameInChinese": "輝立證券(香港)有限公司",
        "PartyNameInEnglish": "Phillip Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD483",
        "PartyNameInChinese": "Phillip Securities Pte Ltd",
        "PartyNameInEnglish": "Phillip Securities Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD484",
        "PartyNameInChinese": "法國興業證券(香港)有限公司",
        "PartyNameInEnglish": "SG Securities (HK) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD485",
        "PartyNameInChinese": "山證國際證券有限公司,",
        "PartyNameInEnglish": "Shanxi Securities International Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD486",
        "PartyNameInChinese": "太平証券(香港)有限公司",
        "PartyNameInEnglish": "Taiping Securities (HK) Co Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD487",
        "PartyNameInChinese": "香港上海滙豐銀行有限公司",
        "PartyNameInEnglish": "The Hong Kong and Shanghai Banking Corporation Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD488",
        "PartyNameInChinese": "Tiger Brokers (Singapore) Pte Ltd",
        "PartyNameInEnglish": "Tiger Brokers (Singapore) Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD489",
        "PartyNameInChinese": "UBS Securities Hong Kong Limited",
        "PartyNameInEnglish": "UBS Securities Hong Kong Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD490",
        "PartyNameInChinese": "UBS Securities Pte Ltd",
        "PartyNameInEnglish": "UBS Securities Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD491",
        "PartyNameInChinese": "UOB Kay Hian Private Limited",
        "PartyNameInEnglish": "UOB Kay Hian Private Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD492",
        "PartyNameInChinese": "華盛資本証券有限公司",
        "PartyNameInEnglish": "Valuable Capital Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD493",
        "PartyNameInChinese": "元大證券(香港)有限公司",
        "PartyNameInEnglish": "Yuanta Securities (Hong Kong) Company Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD494",
        "PartyNameInChinese": "中泰國際證券有限公司",
        "PartyNameInEnglish": "Zhongtai International Securities Limited"
      }
    ]
  }
  getCusts() {
    return [
      {
        "PartyCategory": "CUST",
        "PartyID": "CU528",
        "PartyNameInChinese": "中銀國際英國保誠信託有限公司",
        "PartyNameInEnglish": "BOCI-Prudential Trustee Limited"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU527",
        "PartyNameInChinese": "花旗银行(中国)有限公司",
        "PartyNameInEnglish": "CITIBANK (CHINA) CO., LTD"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU529",
        "PartyNameInChinese": "花旗銀行香港分行",
        "PartyNameInEnglish": "Citibank, N.A., Hong Kong Branch"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU530",
        "PartyNameInChinese": "花旗信托有限公司",
        "PartyNameInEnglish": "Cititrust Limited"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU525",
        "PartyNameInChinese": "匯豐銀行(中國)有限公司",
        "PartyNameInEnglish": "HSBC Bank (China) Company Limited"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU526",
        "PartyNameInChinese": "匯豐機構信託服務(亞洲)有限公司",
        "PartyNameInEnglish": "HSBC Institutional Trust Services (Asia) Limited"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU531",
        "PartyNameInChinese": "HSBC Institutional Trust Services (Singapore)  Limited",
        "PartyNameInEnglish": "HSBC Institutional Trust Services (Singapore)  Limited"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU532",
        "PartyNameInChinese": "香港上海滙豐銀行有限公司",
        "PartyNameInEnglish": "The Hongkong and Shanghai Banking Corporation Limited"
      }
    ]
  }
  getPrcs() {
    return [
      {
        "PartyCategory": "PRC_CUST",
        "PartyID": "711",
        "PartyNameInChinese": "匯豐銀行(中國)有限公司",
        "PartyNameInEnglish": "HSBC Bank (China) Company Limited"
      }
    ]
  }
  getDCDashBoardData() {
    return {
      "chartData": [
        {
          "timeline": "T0",
          "unsignedOff": 101,
          "signedOff": 1
        }
      ],
      "fundData": {
        "L&I ETF": 11,
        "MMF ETF": 7,
        "EQUITY ETF": 5,
        "BOND ETF": 1
      }
    }
  }
  getThreshold() {
    return {
      "data": {
        "li_funds_count": 0,
        "non_li_funds_count": 1,
        "threshold_config_data": [
          {
            "effective_from": "2021-11-23T00:00:00Z",
            "effective_to": "9999-12-31T00:00:00Z",
            "fund_id": "1000023",
            "fund_type": "non_li_fund",
            "id": 8,
            "share_class_id": "CLS00069",
            "threshold_value": 2,
            "ticker": "82822 HK Equity",
            "tradar_code": "CO-A50F",
            "upper_bound": 23,
            "lower_bound": 45,
            share_class_name: "RMB Share Class A"
          },
          {
            "effective_from": "2021-11-23T00:00:00Z",
            "effective_to": "9999-12-31T00:00:00Z",
            "fund_id": "1000023",
            "fund_type": "li_fund",
            "id": 8,
            "share_class_id": "CLS00069",
            "threshold_value": -2,
            "ticker": "82822 HK Equity",
            "tradar_code": "HK-HSI",
            "upper_bound": 23,
            "lower_bound": 45,
            share_class_name: "RMB SHARE CLASS A"
          },
          {
            "effective_from": "2021-11-23T00:00:00Z",
            "effective_to": "9999-12-31T00:00:00Z",
            "fund_id": "10000299",
            "fund_type": "non_li_fund",
            "id": 8,
            "share_class_id": "CLS00069",
            "threshold_value": 2,
            "ticker": "82822 HK Equity",
            "tradar_code": "CO-A50F",
            "upper_bound": 23,
            "lower_bound": 45,
            share_class_name: "RMB Share Class A"
          },
          {
            "effective_from": "2021-11-23T00:00:00Z",
            "effective_to": "9999-12-31T00:00:00Z",
            "fund_id": "1000",
            "fund_type": "li_fund",
            "id": 8,
            "share_class_id": "CLS00069",
            "threshold_value": 2,
            "ticker": "82822 HK Equity",
            "tradar_code": "CO-A50F",
            "upper_bound": 23,
            "lower_bound": 45,
            share_class_name: "RMB Share Class A"
          },
        ]
      },
      "status": "success"
    }
  }
  getFundInfo() {
    return {
      "data": [
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000018",
          "fund_name": "CSOP MSCI China A International Hedged ETF",
          "tradar_code": "US-MSCIH"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "USD",
              "class_id": "CLS00195",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000138",
          "fund_name": "CSOP WTI Crude Oil Futures Daily (-1x) Inverse Product",
          "tradar_code": "HK-OIL-I"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 500000,
              "ccy": "CNY",
              "class_id": "CLS00196",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000139",
          "fund_name": "CSOP MSCI China A 50 Connect ETF",
          "tradar_code": "HK-MSCIA50"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00239",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000168",
          "fund_name": "CSOP Global Quant Allocation Fund I SP",
          "tradar_code": "CO-CGQA"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 500000,
              "ccy": "CNY",
              "class_id": "CLS00069",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000023",
          "fund_name": "CSOP FTSE China A50 ETF",
          "tradar_code": "CO-A50F"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000034",
          "fund_name": "ICBC Advisory Account",
          "tradar_code": "CO-ICBC"
        },
        {
          "asset_class": "LEVE",
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "CNY",
              "class_id": "CLS00176",
              "class_id_name": "CNY",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000121",
          "fund_name": "CSOP CSI 300 Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-CS3-L"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "CNY",
              "class_id": "CLS00189",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000133",
          "fund_name": "CSOP Huatai-PineBridge CSI Photovoltaic Industry ETF",
          "tradar_code": "HK-PTVT"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000165",
          "fund_name": "CSOP US Large Oil \u0026 Gas Companies Daily (2x) Leveraged Product",
          "tradar_code": "HK-OLG-L"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 0,
              "ccy": "CNY",
              "class_id": "CLS00203",
              "class_id_name": "Unlisted Class P",
              "share_class_name": "RMB Share Class P unlisted"
            },
            {
              "basket_size": 30000,
              "ccy": "CNY",
              "class_id": "CLS00002",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            },
            {
              "basket_size": 0,
              "ccy": "CNY",
              "class_id": "CLS00202",
              "class_id_name": "Unlisted Class A",
              "share_class_name": "RMB Share Class unlisted A"
            }
          ],
          "fund_id": "1000002",
          "fund_name": "CSOP Bloomberg Barclays China Treasury + Policy Bank Bond Index ETF",
          "tradar_code": "CO-B5"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "HKD",
              "class_id": "CLS00072",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000025",
          "fund_name": "CSOP Hang Seng China Enterprises Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-HCE-L"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000033",
          "fund_name": "CSOP Dragon Dynamic China Select Balanced Fund",
          "tradar_code": "CO-CSBF"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 1000,
              "ccy": "HKD",
              "class_id": "CLS00173",
              "class_id_name": "Unlisted share class A",
              "share_class_name": "HKD Share Class unlisted A"
            },
            {
              "basket_size": 1000,
              "ccy": "HKD",
              "class_id": "CLS00197",
              "class_id_name": "Unlisted share class P",
              "share_class_name": "HKD Share Class P unlisted"
            },
            {
              "basket_size": 1000,
              "ccy": "HKD",
              "class_id": "CLS00077",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000029",
          "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
          "tradar_code": "CO-MMF"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "USD",
              "class_id": "CLS00175",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000120",
          "fund_name": "CSOP Gold Futures Daily (2x) Leveraged Product",
          "tradar_code": "HK-GLD-L"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "USD",
              "class_id": "CLS00192",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000136",
          "fund_name": "CSOP Gold Futures Daily (-1x) Inverse Product",
          "tradar_code": "HK-GLD-I"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 200000,
              "ccy": "USD",
              "class_id": "CLS00231",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000162",
          "fund_name": "CSOP CGS-CIMB FTSE Asia Pacific Low Carbon Index ETF",
          "tradar_code": "SG-ALC"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 500000,
              "ccy": "CNY",
              "class_id": "CLS00048",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000012",
          "fund_name": "CSOP MSCI China A Inclusion Index ETF",
          "tradar_code": "HK-MSCIA"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "HKD",
              "class_id": "CLS00190",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000134",
          "fund_name": "CSOP China Healthcare Disruption Index ETF",
          "tradar_code": "HK-HEALTH"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "VND",
              "class_id": "CLS00230",
              "class_id_name": "VND",
              "share_class_name": "VND Share Class A"
            }
          ],
          "fund_id": "1000161",
          "fund_name": "CSOP FTSE Vietnam 30 ETF",
          "tradar_code": "HK-VN30"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000007",
          "fund_name": "CSOP China Intelligence A-Share Fund",
          "tradar_code": "CO-HERM"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000036",
          "fund_name": "China Access Bond Fund SP7",
          "tradar_code": "CO-CABF"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000039",
          "fund_name": "ABS Direct Eq Fund (S4)",
          "tradar_code": "CO-ABS"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "USD",
              "class_id": "CLS00184",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000128",
          "fund_name": "CSOP FTSE China A50 Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-A50-L"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "USD",
              "class_id": "CLS00185",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000129",
          "fund_name": "CSOP FTSE China A50 Index Daily (-1x) Inverse Product",
          "tradar_code": "HK-A50-I"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 10000,
              "ccy": "CNY",
              "class_id": "CLS00040",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            },
            {
              "basket_size": 0,
              "ccy": "CNY",
              "class_id": "CLS00198",
              "class_id_name": "Unlisted Share Class A",
              "share_class_name": "RMB Share Class unlisted A"
            }
          ],
          "fund_id": "1000008",
          "fund_name": "CSOP RMB Money Market ETF",
          "tradar_code": "CO-USTB"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "HKD",
              "class_id": "CLS00071",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000024",
          "fund_name": "CSOP Hang Seng China Enterprises Index Daily (-2x) Inverse Product",
          "tradar_code": "HK-HCE-2I"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "CNY",
              "class_id": "CLS00171",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000118",
          "fund_name": "CSOP CSI 500 ETF",
          "tradar_code": "HK-CSI5"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "USD",
              "class_id": "CLS00188",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000132",
          "fund_name": "CSOP Global Cloud Computing Technology Index ETF",
          "tradar_code": "HK-CLOUD"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00241",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000170",
          "fund_name": "CSOP Global Quant Allocation Fund – Bond Plus SP",
          "tradar_code": "CO-CQBP"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000009",
          "fund_name": "CSOP FTSE China A50 ETF (US)",
          "tradar_code": "CO-AFTY"
        },
        {
          "asset_class": "EQTY",
          "classes": [
            {
              "basket_size": 500000,
              "ccy": "HKD",
              "class_id": "CLS00178",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            },
            {
              "basket_size": 0,
              "ccy": "HKD",
              "class_id": "CLS00199",
              "class_id_name": "Unlisted class",
              "share_class_name": "HKD Share Class unlisted A"
            }
          ],
          "fund_id": "1000123",
          "fund_name": "CSOP Hang Seng TECH Index ETF",
          "tradar_code": "CO-CHST"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 50000,
              "ccy": "SGD",
              "class_id": "CLS00193",
              "class_id_name": "SGD",
              "share_class_name": "SGD Share Class A"
            }
          ],
          "fund_id": "1000137",
          "fund_name": "CSOP iEdge S-REIT Leaders Index ETF",
          "tradar_code": "SG-REIT"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "CNY",
              "class_id": "CLS00044",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000010",
          "fund_name": "CSOP SZSE ChiNext ETF",
          "tradar_code": "CO-CNXT"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00242",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000171",
          "fund_name": "CSOP Global Quant Allocation Fund - BOCOM Bond Plus II Greater Bay SP",
          "tradar_code": "CO-CQB2"
        },
        {
          "asset_class": "EQTY",
          "classes": [
            {
              "basket_size": 100000,
              "ccy": "HKD",
              "class_id": "CLS00066",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000022",
          "fund_name": "ICBC CSOP S\u0026P NEW CHINA SECTORS ETF",
          "tradar_code": "HK-SPNC"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000040",
          "fund_name": "HKU HK and CN Equity Fund",
          "tradar_code": "CO-HKU"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 1000000,
              "ccy": "HKD",
              "class_id": "CLS00187",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000131",
          "fund_name": "CSOP Hang Seng Index ETF",
          "tradar_code": "HK-HSI"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 1,
              "ccy": "USD",
              "class_id": "CLS00227",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class Q"
            },
            {
              "basket_size": 0,
              "ccy": "HKD",
              "class_id": "CLS00228",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class Q"
            },
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00229",
              "class_id_name": "Unlisted Share Class Q",
              "share_class_name": "USD Share Class R"
            }
          ],
          "fund_id": "1000160",
          "fund_name": "CSOP Changjiang Absolute Return Fund SP",
          "tradar_code": "CO-CCJA"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00235",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000164",
          "fund_name": "CSOP Dragon Dynamic Multi Access Fund of Funds SP",
          "tradar_code": "CO-MAF"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000166",
          "fund_name": "CSOP US Large Oil \u0026 Gas Companies Daily (-2x) Inverse Product",
          "tradar_code": "HK-OLG-I"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000016",
          "fund_name": "CSOP Select USD Bond Fund",
          "tradar_code": "CO-USDB"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "HKD",
              "class_id": "CLS00182",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000126",
          "fund_name": "CSOP Hang Seng TECH Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-HST-L"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "HKD",
              "class_id": "CLS00183",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000127",
          "fund_name": "CSOP Hang Seng TECH Index Daily (-2x) Inverse Product",
          "tradar_code": "HK-HST-2I"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00238",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000167",
          "fund_name": "CSOP Finetree Fund SP",
          "tradar_code": "CO-CSTF"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000003",
          "fund_name": "China New Balance Opportunity Fund",
          "tradar_code": "CNBO"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000035",
          "fund_name": "Multi Access Fund of Funds SP",
          "tradar_code": "CO-MFOF"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "HKD",
              "class_id": "CLS00092",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000043",
          "fund_name": "CSOP Hang Seng Index Daily (-2x) Inverse Product",
          "tradar_code": "HK-HSI-2I"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "USD",
              "class_id": "CLS00174",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000119",
          "fund_name": "CSOP NASDAQ-100 Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-NDX-L"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "CNY",
              "class_id": "CLS00177",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000122",
          "fund_name": "CSOP CSI 300 Index Daily (-1x) Inverse Product",
          "tradar_code": "HK-CS3-I"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "CNY",
              "class_id": "CLS00186",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000130",
          "fund_name": "CSOP STAR 50 Index ETF",
          "tradar_code": "CO-STAR50"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000005",
          "fund_name": "CSOP Shen Zhou RMB Fund",
          "tradar_code": "RQFII-SZRMB"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "HKD",
              "class_id": "CLS00073",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000026",
          "fund_name": "CSOP Hang Seng Index Daily (-1x) Inverse Product",
          "tradar_code": "HK-HSI-I"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 1000,
              "ccy": "USD",
              "class_id": "CLS00091",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            },
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00172",
              "class_id_name": "Unlisted Share Class A",
              "share_class_name": "USD Share Class unlisted A"
            }
          ],
          "fund_id": "1000042",
          "fund_name": "CSOP US Dollar Money Market ETF",
          "tradar_code": "CO-CUSF"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "USD",
              "class_id": "CLS00201",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000141",
          "fund_name": "CSOP Metaverse Concept ETF",
          "tradar_code": "HK-METAV"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00240",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000169",
          "fund_name": "CSOP Global Quant Allocation Growth Fund I SP",
          "tradar_code": "CO-CQGF"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000006",
          "fund_name": "CSOP Dragon Dynamic China Select Equity SP",
          "tradar_code": "RQFII-DDCS"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 50000,
              "ccy": "CNY",
              "class_id": "CLS00180",
              "class_id_name": "SGD",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000124",
          "fund_name": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
          "tradar_code": "SG-WGBI"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000163",
          "fund_name": "CSOP CSI Brokerage Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-IBB-L"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "HKD",
              "class_id": "CLS00074",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000027",
          "fund_name": "CSOP Hang Seng Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-HSI-L"
        },
        {
          "asset_class": "EQTY",
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "USD",
              "class_id": "CLS00162",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000113",
          "fund_name": "CSOP NASDAQ-100 Index Daily (-2x) Inverse Product",
          "tradar_code": "HK-NDX-2I"
        },
        {
          "asset_class": null,
          "classes": [],
          "fund_id": "1000038",
          "fund_name": "Rock Creek Advisory account",
          "tradar_code": "CO-RC"
        },
        {
          "asset_class": null,
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "USD",
              "class_id": "CLS00191",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000135",
          "fund_name": "CSOP Global Smart Driving Index ETF",
          "tradar_code": "HK-SDRIVE"
        }
      ],
      "status": "success"
    }
  }
  getExFxData() {
    return {
      executionVOList: [
        {
          "fxDate": "Fri Oct 28 00:00:00 IST 2022",
          "pmaBasketId": "CSOP20221028B00301R",
          "ccyPair": "HKDUSD",
          "fxRate": null,
          "versionUser": "ORDERCALCJOB",
          "versionDate": "2022-10-31T12:39:38.718+00:00",
          "dcCalcType": null,
          "fundCode": "CO-CHST",
          "revCcyPair": "USD-CNY",
          "revFxRate": null
        },
        {
          "fxDate": "Fri Oct 28 00:00:00 IST 2022",
          "pmaBasketId": "CSOP20221028B00301R",
          "ccyPair": "USDHKD",
          "fxRate": "7.8500000000",
          "versionUser": "ORDERCALCJOB",
          "versionDate": "2022-10-31T12:39:38.718+00:00",
          "dcCalcType": null,
          "fundCode": "CO-CHST",
          "revCcyPair": "USD-CNY",
          "revFxRate": null
        },
      ],
      "editEnabled": true
    }
  }
  getTdteData() {
    return {
      data: [
        {
          "account_summary_id": 9526,
          "daily_change_td": -0.0616108913,
          "daily_change_te": 0.0008487971,
          "fund_type": "li_fund",
          "lower_bound": -0.2,
          "rolling_1y_td": -0.0001758209,
          "rolling_1y_te": 0.0021817681,
          "sign_off_date": "2022-11-18T05:19:10.8537457Z",
          "sign_off_status": "Y",
          "since_inception_td": -0.0001772625,
          "since_inception_te": 0.0021745738,
          "threshold_breach_comments": "Hellow World This is My First Program I have alert and am non li",
          "ticker": "7300 HK Equity",
          "tradar_code": "HK-HSI-I",
          "upper_bound": 0.1,
          alert: true,
          type: "nonLi"
        },
        {
          "account_summary_id": 9527,
          "daily_change_td": -0.0616108913,
          "daily_change_te": 0.0008487971,
          "fund_type": "li_fund",
          "lower_bound": -0.2,
          "rolling_1y_td": -0.0001758209,
          "rolling_1y_te": 0.0021817681,
          "sign_off_date": "2022-11-18T05:19:10.8537457Z",
          "sign_off_status": "Y",
          "since_inception_td": -0.0001772625,
          "since_inception_te": 0.0021745738,
          "threshold_breach_comments": "Hellow World This is My First Program",
          "ticker": "7300 HK Equity",
          "tradar_code": "HK-HSI-II",
          "upper_bound": 0.1,
          type: "nonLi"
        },
        {
          "account_summary_id": 9528,
          "daily_change_td": -0.0616108913,
          "daily_change_te": 0.0008487971,
          "fund_type": "non_li_fund",
          "lower_bound": -0.2,
          "rolling_1y_td": -0.0001758209,
          "rolling_1y_te": 0.0021817681,
          "sign_off_date": "2022-11-18T05:19:10.8537457Z",
          "sign_off_status": "Y",
          "since_inception_td": -0.0001772625,
          "since_inception_te": 0.0021745738,
          "threshold_breach_comments": "Hellow World This is My First Program i have alert and am li",
          "ticker": "7300 HK Equity",
          "tradar_code": "HK-HSI-III",
          "upper_bound": 0.1,
          alert: true,
          type: "Li"
        },
        {
          "account_summary_id": 9529,
          "daily_change_td": -0.0616108913,
          "daily_change_te": 0.0008487971,
          "fund_type": "li_fund",
          "lower_bound": -0.2,
          "rolling_1y_td": -0.0001758209,
          "rolling_1y_te": 0.0021817681,
          "sign_off_date": "2022-11-18T05:19:10.8537457Z",
          "sign_off_status": "Y",
          "since_inception_td": -0.0001772625,
          "since_inception_te": 0.0021745738,
          "threshold_breach_comments": "Hellow World This is My First Program",
          "ticker": "7300 HK Equity",
          "tradar_code": "HK-HSI-IV",
          "upper_bound": 0.1,
          type: "Li"
        },
        {
          "account_summary_id": 9530,
          "daily_change_td": -0.0616108913,
          "daily_change_te": 0.0008487971,
          "fund_type": "non_li_fund",
          "lower_bound": -0.2,
          "rolling_1y_td": -0.0001758209,
          "rolling_1y_te": 0.0021817681,
          "sign_off_date": "2022-11-18T05:19:10.8537457Z",
          "sign_off_status": "Y",
          "since_inception_td": -0.0001772625,
          "since_inception_te": 0.0021745738,
          "threshold_breach_comments": "Hellow World This is My First Program",
          "ticker": "7300 HK Equity",
          "tradar_code": "HK-HSI-IIV",
          "upper_bound": 0.1,
          type: "nonLi"
        }


      ],
      status: "success"
    }
  }
  getDrop() {
    return {
      "data": [
        {
          "class_desc": null,
          "class_type": "PRODUCT_TYPE",
          "code": "AF",
          "code_desc": "Active Fund",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-15T10:12:08.2993397Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "BOND",
          "code_desc": "Bond",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-28T10:12:23.670361Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "DERV",
          "code_desc": "Derivative",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-15T10:55:22.2751747Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "EQTY",
          "code_desc": "Equities",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.707686Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": null,
          "class_type": "PRODUCT_TYPE",
          "code": "ETF",
          "code_desc": "ETF",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-15T10:11:30.8815889Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "INDX",
          "code_desc": "Index",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076866Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": null,
          "class_type": "PRODUCT_TYPE",
          "code": "LI",
          "code_desc": "L\u0026I",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-11T05:45:15.0119491Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PRODUCT_TYPE",
          "code": "MF",
          "code_desc": "Mutual Fund",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-15T10:11:50.5474059Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "MM",
          "code_desc": "Money Market",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076863Z",
          "version_user": "SYSTEM"
        }
      ],
      "status": "success"
    }
  }
  getTdTeDashBoardData() {
    return {
      "data": {
        "li_funds": {
          "sythetic_equity": {
            "breach": 9,
            "un_breach": 2323
          }
        },
        "non_li_funds": {
          "equity": {
            "breach": 0,
            "un_breach": 2
          },
          "fixed_income": {
            "breach": 0,
            "un_breach": 3688
          }
        }
      },
      "status": "success"
    }
  }
  getCapitalEntries() {
    return [
      {
        "orderId": "CSOP20221013H00102R",
        "operation": "Redemption",
        "operaionDescription": "Redemption",
        "postingDriver": "CAP_ENTRY",
        "postingDriverDescription": "Capital Entry",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "fund": "CO-MMF",
        "shareClassAccount": "HKDSHAARECLASSCODE",
        "transactionType": "TransAcct",
        "subscriptionAmount": null,
        "redemptionAmount": 103411.8100000000,
        "redemptionPrice": 1034.1181000000,
        "redemptionDate": "2022-10-14T00:00:00.000+00:00",
        "navEndingDate": "2022-10-13T00:00:00.000+00:00",
        "trader": "Trader1",
        "pbAccount": "DueFromPD",
        "strategy": "Strat1",
        "fxType": "",
        "notes": "Redemption as of DD Thu Oct 13 00:00:00 UTC 2022: CSOP20221013H00102R Haitong",
        "reportDateExclusion": "1",
        "signOffStatus": "T0FS",
        "entryDate": "2022-12-01T00:00:00.000+00:00",
        "entryStatus": "A",
        "orderSummaryId": 2357
      },
      {
        "orderId": "CSOP20221013EM0101R",
        "operation": "Redemption",
        "operaionDescription": "Redemption",
        "postingDriver": "CAP_ENTRY",
        "postingDriverDescription": "Capital Entry",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "fund": "CO-MMF",
        "shareClassAccount": "MMF_UNLISTED_CODE",
        "transactionType": "TransAcct",
        "subscriptionAmount": null,
        "redemptionAmount": 3670326.1100000000,
        "redemptionPrice": 10.3412000000,
        "redemptionDate": "2022-10-14T00:00:00.000+00:00",
        "navEndingDate": "2022-10-13T00:00:00.000+00:00",
        "trader": "Trader1",
        "pbAccount": "DueFromPD",
        "strategy": "Strat1",
        "fxType": "",
        "notes": "Redemption as of DD Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101R Eastmoney",
        "reportDateExclusion": "1",
        "signOffStatus": "T0FS",
        "entryDate": "2022-11-29T00:00:00.000+00:00",
        "entryStatus": "A",
        "orderSummaryId": 2353
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operaionDescription": "Creation",
        "postingDriver": "CAP_ENTRY",
        "postingDriverDescription": "Capital Entry",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "fund": "CO-MMF",
        "shareClassAccount": "MMF_UNLISTED_CODE",
        "transactionType": "TransAcctin",
        "subscriptionAmount": 874904.0800000000,
        "redemptionAmount": null,
        "redemptionPrice": 10.3412000000,
        "redemptionDate": "2022-10-14T00:00:00.000+00:00",
        "navEndingDate": "2022-10-13T00:00:00.000+00:00",
        "trader": "Trader1",
        "pbAccount": "DueFromPD",
        "strategy": "Strat1",
        "fxType": "",
        "notes": "Creation as of DD Thu Oct 13 00:00:00 IST 2022: CSOP20221013EM0101C Eastmoney",
        "reportDateExclusion": "1",
        "signOffStatus": "T0FS",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "A",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013H00101R",
        "operation": "Redemption",
        "operaionDescription": "Redemption",
        "postingDriver": "CAP_ENTRY",
        "postingDriverDescription": "Capital Entry",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "fund": "CO-MMF",
        "shareClassAccount": "HKDSHAARECLASSCODE",
        "transactionType": "TransAcct",
        "subscriptionAmount": null,
        "redemptionAmount": 12409417.2000000000,
        "redemptionPrice": 1034.1181000000,
        "redemptionDate": "2022-10-14T00:00:00.000+00:00",
        "navEndingDate": "2022-10-13T00:00:00.000+00:00",
        "trader": "Trader1",
        "pbAccount": "DueFromPD",
        "strategy": "Strat1",
        "fxType": "",
        "notes": "Redemption as of DD Thu Oct 13 00:00:00 UTC 2022: CSOP20221013H00101R Haitong",
        "reportDateExclusion": "1",
        "signOffStatus": "T0FS",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "A",
        "orderSummaryId": 2356
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operaionDescription": "Creation",
        "postingDriver": "CAP_ENTRY",
        "postingDriverDescription": "Capital Entry",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "fund": "CO-MMF",
        "shareClassAccount": "MMF_UNLISTED_CODE",
        "transactionType": "TransAcctin",
        "subscriptionAmount": 874904.0800000000,
        "redemptionAmount": null,
        "redemptionPrice": 10.3412000000,
        "redemptionDate": "2022-10-14T00:00:00.000+00:00",
        "navEndingDate": "2022-10-13T00:00:00.000+00:00",
        "trader": "Trader1",
        "pbAccount": "DueFromPD",
        "strategy": "Strat1",
        "fxType": "",
        "notes": "Creation as of DD Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101C Eastmoney",
        "reportDateExclusion": "1",
        "signOffStatus": "T0FS",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "X",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101R",
        "operation": "Redemption",
        "operaionDescription": "Redemption",
        "postingDriver": "CAP_ENTRY",
        "postingDriverDescription": "Capital Entry",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "fund": "CO-MMF",
        "shareClassAccount": "MMF_UNLISTED_CODE",
        "transactionType": "TransAcct",
        "subscriptionAmount": null,
        "redemptionAmount": 3670326.1100000000,
        "redemptionPrice": 10.3412000000,
        "redemptionDate": "2022-10-14T00:00:00.000+00:00",
        "navEndingDate": "2022-10-13T00:00:00.000+00:00",
        "trader": "Trader1",
        "pbAccount": "DueFromPD",
        "strategy": "Strat1",
        "fxType": "",
        "notes": "Redemption as of DD Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101R Eastmoney",
        "reportDateExclusion": "1",
        "signOffStatus": "T0FS",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "X",
        "orderSummaryId": 2353
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operaionDescription": "Creation",
        "postingDriver": "CAP_ENTRY",
        "postingDriverDescription": "Capital Entry",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "fund": "CO-MMF",
        "shareClassAccount": "MMF_UNLISTED_CODE",
        "transactionType": "TransAcctin",
        "subscriptionAmount": 874904.0800000000,
        "redemptionAmount": null,
        "redemptionPrice": 10.3400000000,
        "redemptionDate": "2022-10-14T00:00:00.000+00:00",
        "navEndingDate": "2022-10-13T00:00:00.000+00:00",
        "trader": "Trader1",
        "pbAccount": "DueFromPD",
        "strategy": "Strat1",
        "fxType": "",
        "notes": "Creation as of DD Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101C Eastmoney",
        "reportDateExclusion": "1",
        "signOffStatus": "T0FS",
        "entryDate": "2022-11-25T00:00:00.000+00:00",
        "entryStatus": "X",
        "orderSummaryId": 2276
      }
    ]
  }
  getDcEntries() {
    return [
      {
        "orderId": "CSOP20221013H00102R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "MGR",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "PD_FEE",
        "postingDriverDescription": "PD Fee",
        "postingDiscription": "PD Fee Payment from MGR",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "rawPostingAmt": "600.0000000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-14T00:00:00.000+00:00",
        "tradeType": "Tranxfee",
        "ccy": "HKD",
        "account": "MMF_Custodian_Code",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "600.0000000000",
        "price": null,
        "toAccount": "MMF_Custodian_Code",
        "notes": "PD Fee for DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013H00102R Haitong",
        "security": "HKD",
        "settlementDate": "2022-10-14T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-12-01T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2357
      },
      {
        "orderId": "CSOP20221013H00102R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "MGR",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "RD_PAY_1",
        "postingDriverDescription": "Redemption Pay First",
        "postingDiscription": "CR_1stPayment_CRCCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "-103355.9700000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcct",
        "ccy": "HKD",
        "account": "MMF_Custodian_Code",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "103355.9700000000",
        "price": null,
        "toAccount": "DueToPD",
        "notes": "Redemption Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013H00102R Haitong",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-12-01T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2357
      },
      {
        "orderId": "CSOP20221013H00102R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "MGR",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "PD_FEE",
        "postingDriverDescription": "PD Fee",
        "postingDiscription": "PD Fee Payment from MGR",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "rawPostingAmt": "600.0000000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-14T00:00:00.000+00:00",
        "tradeType": "MgmtFeeReversal",
        "ccy": "HKD",
        "account": "zMgmtN-1",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "600.0000000000",
        "price": null,
        "toAccount": "zMgmtN-1",
        "notes": "PD Fee for DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013H00102R Haitong",
        "security": "HKD",
        "settlementDate": "2022-10-14T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-12-01T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2357
      },
      {
        "orderId": "CSOP20221013H00102R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "MGR",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "RD_PAY_1",
        "postingDriverDescription": "Redemption Pay First",
        "postingDiscription": "CR_1stPayment_CRCCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "-103355.9700000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcct",
        "ccy": "HKD",
        "account": "MMF_Custodian_Code",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "103355.9700000000",
        "price": null,
        "toAccount": "DueToPD",
        "notes": "Redemption Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013H00102R Haitong",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-12-01T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2357
      },
      {
        "orderId": "CSOP20221013EM0101R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "RD_PAY_1",
        "postingDriverDescription": "Redemption Pay First",
        "postingDiscription": "CR_1stPayment_CRCCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "-3669874.6600000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcctin",
        "ccy": "HKD",
        "account": "MMF_Custodian_Code",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "3669874.6600000000",
        "price": null,
        "toAccount": "DueToPD",
        "notes": "Redemption Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101R Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-29T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2353
      },
      {
        "orderId": "CSOP20221013EM0101R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "RD_PAY_1",
        "postingDriverDescription": "Redemption Pay First",
        "postingDiscription": "CR_1stPayment_CRCCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "-3669874.6600000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcctin",
        "ccy": "HKD",
        "account": "MMF_Custodian_Code",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "3669874.6600000000",
        "price": null,
        "toAccount": "DueToPD",
        "notes": "Redemption Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101R Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-29T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2353
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "TRANX_FEE",
        "postingDriverDescription": "Transaction Fee",
        "postingDiscription": "TA OrderFee",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "rawPostingAmt": "120.0000000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-14T00:00:00.000+00:00",
        "tradeType": "Tranxfee",
        "ccy": "HKD",
        "account": "zREG&TRE FEE-LIA",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "120.0000000000",
        "price": "1.0000000000",
        "toAccount": "zREG&TRE FEE-LIA",
        "notes": "TA Order Fee for DD Thu Oct 13 00:00:00 IST 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-14T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "CR_PAY_1",
        "postingDriverDescription": "Creation Pay First",
        "postingDiscription": "CR_1stPayment_CR CCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "875010.9900000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcct",
        "ccy": "HKD",
        "account": "DueFromPD",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "875010.9900000000",
        "price": null,
        "toAccount": "MMF_Custodian_Code",
        "notes": "Creation Payment HKD DD: Thu Oct 13 00:00:00 IST 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "TRANX_FEE",
        "postingDriverDescription": "Transaction Fee",
        "postingDiscription": "TA OrderFee",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "rawPostingAmt": "120.0000000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-14T00:00:00.000+00:00",
        "tradeType": "Tranxfee",
        "ccy": "HKD",
        "account": "zREG&TRE FEE-LIA",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "120.0000000000",
        "price": null,
        "toAccount": "zREG&TRE FEE-LIA",
        "notes": "TA Order Fee for DD Thu Oct 13 00:00:00 IST 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-14T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "CR_PAY_1",
        "postingDriverDescription": "Creation Pay First",
        "postingDiscription": "CR_1stPayment_CR CCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "875010.9900000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcct",
        "ccy": "HKD",
        "account": "DueFromPD",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "875010.9900000000",
        "price": null,
        "toAccount": "MMF_Custodian_Code",
        "notes": "Creation Payment HKD DD: Thu Oct 13 00:00:00 IST 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013H00101R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "MGR",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "PD_FEE",
        "postingDriverDescription": "PD Fee",
        "postingDiscription": "PD Fee Payment from MGR",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "rawPostingAmt": "600.0000000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-14T00:00:00.000+00:00",
        "tradeType": "Tranxfee",
        "ccy": "HKD",
        "account": "MMF_Custodian_Code",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "600.0000000000",
        "price": null,
        "toAccount": "MMF_Custodian_Code",
        "notes": "PD Fee for DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013H00101R Haitong",
        "security": "HKD",
        "settlementDate": "2022-10-14T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2356
      },
      {
        "orderId": "CSOP20221013H00101R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "MGR",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "RD_PAY_1",
        "postingDriverDescription": "Redemption Pay First",
        "postingDiscription": "CR_1stPayment_CRCCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "-12407900.5200000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcctin",
        "ccy": "HKD",
        "account": "MMF_Custodian_Code",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "12407900.5200000000",
        "price": null,
        "toAccount": "DueToPD",
        "notes": "Redemption Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013H00101R Haitong",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2356
      },
      {
        "orderId": "CSOP20221013H00101R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "MGR",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "PD_FEE",
        "postingDriverDescription": "PD Fee",
        "postingDiscription": "PD Fee Payment from MGR",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "rawPostingAmt": "600.0000000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-14T00:00:00.000+00:00",
        "tradeType": "MgmtFeeReversal",
        "ccy": "HKD",
        "account": "zMgmtN-1",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "600.0000000000",
        "price": null,
        "toAccount": "zMgmtN-1",
        "notes": "PD Fee for DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013H00101R Haitong",
        "security": "HKD",
        "settlementDate": "2022-10-14T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2356
      },
      {
        "orderId": "CSOP20221013H00101R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "MGR",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "RD_PAY_1",
        "postingDriverDescription": "Redemption Pay First",
        "postingDiscription": "CR_1stPayment_CRCCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "-12407900.5200000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcctin",
        "ccy": "HKD",
        "account": "MMF_Custodian_Code",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "12407900.5200000000",
        "price": null,
        "toAccount": "DueToPD",
        "notes": "Redemption Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013H00101R Haitong",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "A",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2356
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "TRANX_FEE",
        "postingDriverDescription": "Transaction Fee",
        "postingDiscription": "TA OrderFee",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "rawPostingAmt": "120.0000000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-14T00:00:00.000+00:00",
        "tradeType": "Tranxfee",
        "ccy": "HKD",
        "account": "zREG&TRE FEE-LIA",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "120.0000000000",
        "price": "1.0000000000",
        "toAccount": "zREG&TRE FEE-LIA",
        "notes": "TA Order Fee for DD Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-14T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "X",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "CR_PAY_1",
        "postingDriverDescription": "Creation Pay First",
        "postingDiscription": "CR_1stPayment_CR CCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "875010.9900000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcct",
        "ccy": "HKD",
        "account": "DueFromPD",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "875010.9900000000",
        "price": null,
        "toAccount": "MMF_Custodian_Code",
        "notes": "Creation Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "X",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "TRANX_FEE",
        "postingDriverDescription": "Transaction Fee",
        "postingDiscription": "TA OrderFee",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "rawPostingAmt": "120.0000000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-14T00:00:00.000+00:00",
        "tradeType": "Tranxfee",
        "ccy": "HKD",
        "account": "zREG&TRE FEE-LIA",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "120.0000000000",
        "price": null,
        "toAccount": "zREG&TRE FEE-LIA",
        "notes": "TA Order Fee for DD Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-14T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "X",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "CR_PAY_1",
        "postingDriverDescription": "Creation Pay First",
        "postingDiscription": "CR_1stPayment_CR CCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "875010.9900000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcct",
        "ccy": "HKD",
        "account": "DueFromPD",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "875010.9900000000",
        "price": null,
        "toAccount": "MMF_Custodian_Code",
        "notes": "Creation Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "X",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "RD_PAY_1",
        "postingDriverDescription": "Redemption Pay First",
        "postingDiscription": "CR_1stPayment_CRCCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "-3669874.6600000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcctin",
        "ccy": "HKD",
        "account": "MMF_Custodian_Code",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "3669874.6600000000",
        "price": null,
        "toAccount": "DueToPD",
        "notes": "Redemption Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101R Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "X",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2353
      },
      {
        "orderId": "CSOP20221013EM0101R",
        "operation": "Redemption",
        "operationDescription": "Redemption",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "RD_PAY_1",
        "postingDriverDescription": "Redemption Pay First",
        "postingDiscription": "CR_1stPayment_CRCCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "-3669874.6600000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcctin",
        "ccy": "HKD",
        "account": "MMF_Custodian_Code",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "3669874.6600000000",
        "price": null,
        "toAccount": "DueToPD",
        "notes": "Redemption Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101R Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-28T00:00:00.000+00:00",
        "entryStatus": "X",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2353
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "TRANX_FEE",
        "postingDriverDescription": "Transaction Fee",
        "postingDiscription": "TA OrderFee",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "rawPostingAmt": "120.0000000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-14T00:00:00.000+00:00",
        "tradeType": "Tranxfee",
        "ccy": "HKD",
        "account": "zREG&TRE FEE-LIA",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "120.0000000000",
        "price": "1.0000000000",
        "toAccount": "zREG&TRE FEE-LIA",
        "notes": "TA Order Fee for DD Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-14T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-25T00:00:00.000+00:00",
        "entryStatus": "X",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "CR_PAY_1",
        "postingDriverDescription": "Creation Pay First",
        "postingDiscription": "CR_1stPayment_CR CCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "875010.9900000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcct",
        "ccy": "HKD",
        "account": "DueFromPD",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "875010.9900000000",
        "price": null,
        "toAccount": "MMF_Custodian_Code",
        "notes": "Creation Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-25T00:00:00.000+00:00",
        "entryStatus": "X",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "TRANX_FEE",
        "postingDriverDescription": "Transaction Fee",
        "postingDiscription": "TA OrderFee",
        "settlementCycle": "DD+1",
        "holidayDateAdjustment": "N",
        "rawPostingAmt": "120.0000000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-14T00:00:00.000+00:00",
        "tradeType": "Tranxfee",
        "ccy": "HKD",
        "account": "zREG&TRE FEE-LIA",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "120.0000000000",
        "price": null,
        "toAccount": "zREG&TRE FEE-LIA",
        "notes": "TA Order Fee for DD Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-14T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-25T00:00:00.000+00:00",
        "entryStatus": "X",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      },
      {
        "orderId": "CSOP20221013EM0101C",
        "operation": "Creation",
        "operationDescription": "Creation",
        "crFeeFrom": "NA",
        "taOrderFeeFrom": "MGR",
        "executionFxDate": "T0",
        "preFund": "N",
        "postingDriver": "CR_PAY_1",
        "postingDriverDescription": "Creation Pay First",
        "postingDiscription": "CR_1stPayment_CR CCY",
        "settlementCycle": "DD",
        "holidayDateAdjustment": "Y",
        "rawPostingAmt": "875010.9900000000",
        "fund": "CO-MMF",
        "paymentDate": "2022-10-13T00:00:00.000+00:00",
        "tradeType": "TransAcct",
        "ccy": "HKD",
        "account": "DueFromPD",
        "strategy": "Strat1",
        "trader": "Trader1",
        "amount": "875010.9900000000",
        "price": null,
        "toAccount": "MMF_Custodian_Code",
        "notes": "Creation Payment HKD DD: Thu Oct 13 00:00:00 UTC 2022: CSOP20221013EM0101C Eastmoney",
        "security": "HKD",
        "settlementDate": "2022-10-13T00:00:00.000+00:00",
        "counterParty": null,
        "fxType": "",
        "reportDateExclution": "1",
        "entryDate": "2022-11-25T00:00:00.000+00:00",
        "entryStatus": "X",
        "signOffStatus": "T0FS",
        "orderSummaryId": 2276
      }
    ]
  }

  getOrderSummaryData() {
    return [
      {
        "orderSummaryId": 2276,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2215,
        "basketId": "NAV1CSOP20221013EM0101C",
        "exceptions": "Order calculations done successfully",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-19T19:17:15.307+00:00",
        "mergeOrderId": 2718,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013EM0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "84508.1200000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": 875010.99,
        "t0PayAmtFund": 875010.99,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 106.9100000000,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": "0.1222000000",
        "tfileId": null,
        "calculationTime": "2022-11-22T05:27:28.583+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "874904.0800000000"
      },
      {
        "orderSummaryId": 2353,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2216,
        "basketId": "NAV1CSOP20221013EM0101R",
        "exceptions": "Order calculations done successfully",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-22T04:44:12.521+00:00",
        "mergeOrderId": 3345,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013EM0101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "354922.6500000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0.1500000000",
        "t0PayAmtPd": -3669874.66,
        "t0PayAmtFund": -3669874.66,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 451.4500000000,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": "0.1230000000",
        "tfileId": null,
        "calculationTime": "2022-11-22T04:43:05.847+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "354.9226500000",
        "amount": "3674478.7300000000"
      },
      {
        "orderSummaryId": 2354,
        "classId": "CLS00069",
        "fundId": "1000023",
        "fundCode": "CO-A50F",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "CNY",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2217,
        "basketId": "CSOP20221013B00301C_CO-A50F_BARCLAYS",
        "exceptions": "Order calculations done successfully",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-19T19:17:15.324+00:00",
        "mergeOrderId": 3747,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013B00301C",
        "orderType": "Creation",
        "stockCode": " ",
        "product": null,
        "pd": "BARCLAYS",
        "units": "9000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "dilip.chandra91@gmail.com",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": 87368410.06,
        "t0PayAmtFund": 87368410.06,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": -62256589.9400000000,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:26:47.290+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "18.0000000000",
        "amount": "116006340.0000000000"
      },
      {
        "orderSummaryId": 2355,
        "classId": "CLS00069",
        "fundId": "1000023",
        "fundCode": "CO-A50F",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2218,
        "basketId": "CSOP20221013H00101C_CO-A50F_Haitong",
        "exceptions": "Order calculations done successfully",
        "signOffStatus": "T0S",
        "versionDate": "2022-11-22T05:22:00.358+00:00",
        "mergeOrderId": 3028,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013H00101C",
        "orderType": "Creation",
        "stockCode": "(Stock code: RMB 82822, HKD 2822)",
        "product": null,
        "pd": "Haitong",
        "units": "8000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": 29764165.69,
        "t0PayAmtFund": 29764165.69,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": -19930712.3200000000,
        "reFundPayAmt": null,
        "signOffUser": "OPSUSER",
        "signOffDate": "2022-10-13T00:00:00.000+00:00",
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": "0.2300000000",
        "tfileId": null,
        "calculationTime": "2022-11-22T00:00:00.000+00:00",
        "signOffStatusIndicator": "SIGNED-OFF",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "16.0000000000",
        "amount": "103117080.0000000000"
      },
      {
        "orderSummaryId": 2356,
        "classId": "CLS00077",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2219,
        "basketId": "NAV1CSOP20221013H00101R",
        "exceptions": "Order calculations done successfully",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T06:01:26.574+00:00",
        "mergeOrderId": 2691,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013H00101R",
        "orderType": "Redemption",
        "stockCode": "3053.HK",
        "product": null,
        "pd": "Haitong",
        "units": "12000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": -12407900.52,
        "t0PayAmtFund": -12407900.52,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 1516.6800000000,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": "0.1222200000",
        "tfileId": null,
        "calculationTime": "2022-11-21T08:55:08.953+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "12.0000000000",
        "amount": "0.0000000000"
      },
      {
        "orderSummaryId": 2357,
        "classId": "CLS00077",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2220,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-16T12:52:40.068+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013H00102R",
        "orderType": "Redemption",
        "stockCode": " ",
        "product": null,
        "pd": "Haitong",
        "units": "100.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "dilip.chandra91@gmail.com",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": -1033991.73,
        "t0PayAmtFund": -1033991.73,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 126.3692318200,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T05:58:37.570+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "0.1000000000",
        "amount": "0.0000000000"
      },
      {
        "orderSummaryId": 2359,
        "classId": "CLS00044",
        "fundId": "1000010",
        "fundCode": "CO-CNXT",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "CNY",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2222,
        "basketId": "",
        "exceptions": "Missing Previous Day NAV",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-12T13:47:05.678+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013BB0101R",
        "orderType": "RD",
        "stockCode": "(Stock code: RMB 83147, HKD 3147)",
        "product": null,
        "pd": "BARCLAYS Bank",
        "units": "3000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.289+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "10.0000000000",
        "amount": "-21062940.0000000000"
      },
      {
        "orderSummaryId": 2362,
        "classId": "CLS00182",
        "fundId": "1000126",
        "fundCode": "HK-HST-L",
        "custodianReconParty": "HSBC",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "CNY",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2225,
        "basketId": null,
        "exceptions": "Missing Trade ExecutionsCSOP20221013A00101C",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-12T13:47:05.659+00:00",
        "mergeOrderId": 3191,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00101C",
        "orderType": "Creation",
        "stockCode": "7226.HK",
        "product": null,
        "pd": "ABN",
        "units": "22000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 13915175969.02,
        "t0PayAmtFund": 13915173469.02,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 5361802473.5414000000,
        "reFundPayAmt": 0.00,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-07T06:13:13.300+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "55.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2363,
        "classId": "CLS00073",
        "fundId": "1000026",
        "fundCode": "HK-HSI-I",
        "custodianReconParty": "HSBC",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2226,
        "basketId": "CSOP20221013A00102C_HK-HSI-I_ABN",
        "exceptions": "Order calculations done successfully",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T06:13:09.394+00:00",
        "mergeOrderId": 2934,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00102C",
        "orderType": "Creation",
        "stockCode": "7300.HK",
        "product": null,
        "pd": "ABN",
        "units": "1600000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0.1000000000",
        "t0PayAmtPd": -2594167.43,
        "t0PayAmtFund": -2596667.43,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": -9816187.4300000000,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": "12.5600000000",
        "tfileId": null,
        "calculationTime": "2022-11-22T05:28:50.927+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "2.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2364,
        "classId": "CLS00073",
        "fundId": "1000026",
        "fundCode": "HK-HSI-I",
        "custodianReconParty": "HSBC",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2227,
        "basketId": "CSOP20221013A00103C_HK-HSI-I_ABN",
        "exceptions": "Order calculations done successfully",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T06:01:26.605+00:00",
        "mergeOrderId": 2936,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00103C",
        "orderType": "Creation",
        "stockCode": "7300.HK",
        "product": null,
        "pd": "ABN",
        "units": "4000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -14584018.18,
        "t0PayAmtFund": -14586518.18,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": -32635318.1800000000,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:28:51.013+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "5.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2365,
        "classId": "CLS00002",
        "fundId": "1000002",
        "fundCode": "CO-B5",
        "custodianReconParty": "HSBC",
        "fundGroup": "BOND ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-11T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "HK",
        "tradeFillDay": "T0",
        "shareClassCcy": null,
        "recordId": 2228,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T12:51:26.052+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-11 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00101R",
        "orderType": "Creation",
        "stockCode": " ",
        "product": null,
        "pd": "known",
        "units": "3.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-11T00:00:00.000+00:00",
        "dealingDate1": "2022-10-12T00:00:00.000+00:00",
        "dealingDate2": "2022-10-14T00:00:00.000+00:00",
        "versionUser": "dummy@dummy.com",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.298+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "145.0000000000",
        "amount": "60.5000000000"
      },
      {
        "orderSummaryId": 2366,
        "classId": "CLS00162",
        "fundId": "1000113",
        "fundCode": "HK-NDX-2I",
        "custodianReconParty": "HSBC",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "USD",
        "recordId": 2229,
        "basketId": "CSOP20221013A00102R_HK-NDX-2I_ABN",
        "exceptions": "Missing DC Config",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T07:03:57.410+00:00",
        "mergeOrderId": 2451,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00102R",
        "orderType": "Redemption",
        "stockCode": "7568.HK",
        "product": null,
        "pd": "ABN",
        "units": "2400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "USD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.300+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "3.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2367,
        "classId": "CLS00074",
        "fundId": "1000027",
        "fundCode": "HK-HSI-L",
        "custodianReconParty": "HSBC",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2230,
        "basketId": "",
        "exceptions": "Missing DC Config",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T07:03:57.416+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00103R",
        "orderType": "Redemption",
        "stockCode": "7200.HK",
        "product": null,
        "pd": "ABN",
        "units": "44800000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.303+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "56.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2368,
        "classId": "CLS00092",
        "fundId": "1000043",
        "fundCode": "HK-HSI-2I",
        "custodianReconParty": "HSBC",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2231,
        "basketId": "",
        "exceptions": "Missing trade executions",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T07:03:57.421+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00104R",
        "orderType": "Redemption",
        "stockCode": "7500.HK",
        "product": null,
        "pd": "ABN",
        "units": "20000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.305+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "25.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2369,
        "classId": "CLS00072",
        "fundId": "1000025",
        "fundCode": "HK-HCE-L",
        "custodianReconParty": "HSBC",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2232,
        "basketId": "",
        "exceptions": "NAV1 not signed off",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T07:03:57.426+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00104C",
        "orderType": "Creation",
        "stockCode": "7288.HK",
        "product": null,
        "pd": "ABN",
        "units": "8000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.307+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "10.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2370,
        "classId": "CLS00092",
        "fundId": "1000043",
        "fundCode": "HK-HSI-2I",
        "custodianReconParty": "HSBC",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2233,
        "basketId": "",
        "exceptions": "Missing trade executions",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T07:03:57.431+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00105R",
        "orderType": "Redemption",
        "stockCode": "7500.HK",
        "product": null,
        "pd": "ABN",
        "units": "32000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.309+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "40.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2371,
        "classId": "CLS00162",
        "fundId": "1000113",
        "fundCode": "HK-NDX-2I",
        "custodianReconParty": "HSBC",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "USD",
        "recordId": 2234,
        "basketId": null,
        "exceptions": "SIGNED OFF",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T07:03:57.437+00:00",
        "mergeOrderId": 2980,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00106R",
        "orderType": "Redemption",
        "stockCode": "",
        "product": null,
        "pd": "ABN",
        "units": "4800000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "MANUAL",
        "fileId": "0",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "dilip.chandra91@gmail.com",
        "shareClassName": "USD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.311+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "6.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2372,
        "classId": "CLS00178",
        "fundId": "1000123",
        "fundCode": "CO-CHST",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2235,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T05:47:25.953+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013B00101R",
        "orderType": "Redemption",
        "stockCode": "3033.HK",
        "product": null,
        "pd": "BNP Paribas",
        "units": "30000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.314+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "60.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2373,
        "classId": "CLS00183",
        "fundId": "1000127",
        "fundCode": "HK-HST-2I",
        "custodianReconParty": "HSBC",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2236,
        "basketId": "",
        "exceptions": "NAV1 not signed off",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T07:03:57.444+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00107R",
        "orderType": "Redemption",
        "stockCode": "7552.HK",
        "product": null,
        "pd": "ABN",
        "units": "6000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.316+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "15.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2374,
        "classId": "CLS00175",
        "fundId": "1000120",
        "fundCode": "HK-GLD-L",
        "custodianReconParty": "CITI",
        "fundGroup": "L&I ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "USD",
        "recordId": 2237,
        "basketId": "",
        "exceptions": "NAV1 not signed off",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T07:03:57.451+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A00105C",
        "orderType": "Creation",
        "stockCode": "7299.HK",
        "product": null,
        "pd": "ABN",
        "units": "1600000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "2214",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "USD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.318+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "2.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 2697,
        "classId": "CLS00180",
        "fundId": "1000124",
        "fundCode": "SG-WGBI",
        "custodianReconParty": "HSBC",
        "fundGroup": "BOND ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-13T00:00:00.000+00:00",
        "dcCalcType": "TRAD",
        "crCcy": "CNY",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2419,
        "basketId": "CSOP20221013A0190C ",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T06:01:26.625+00:00",
        "mergeOrderId": 3574,
        "status": "A",
        "tradeDate": "2022-10-13 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221013A0190C",
        "orderType": "Creation",
        "stockCode": "",
        "product": null,
        "pd": "test5",
        "units": "150000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "MANUAL",
        "fileId": "0",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "dealingDate1": "2022-10-14T00:00:00.000+00:00",
        "dealingDate2": "2022-10-17T00:00:00.000+00:00",
        "versionUser": "dilip.chandra91@gmail.com",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 282511596.98,
        "t0PayAmtFund": 282508596.98,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 171638921.4912000000,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "Y",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-15T07:29:31.410+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [
          {
            "label": "NAV1",
            "value": "NAV1"
          },
          {
            "label": "TRAD",
            "value": "TRAD"
          }
        ],
        "latest": true,
        "holiday": false,
        "basket": "3.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3220,
        "classId": "CLS00002",
        "fundId": "1000002",
        "fundCode": "CO-B5",
        "custodianReconParty": "HSBC",
        "fundGroup": "BOND ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-10-11T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "HK",
        "tradeFillDay": "T0",
        "shareClassCcy": null,
        "recordId": 2645,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-17T11:02:02.780+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-10-11 00:00:00.0000000 +00:00",
        "orderId": "CSOP20221101H00101C",
        "orderType": "Creation",
        "stockCode": " ",
        "product": null,
        "pd": "known",
        "units": "3.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5001",
        "dealingDate": "2022-10-11T00:00:00.000+00:00",
        "dealingDate1": "2022-10-12T00:00:00.000+00:00",
        "dealingDate2": "2022-10-14T00:00:00.000+00:00",
        "versionUser": "dummy@dummy.com",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": 108428477.03,
        "t0PayAmtFund": 108425477.03,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": -23085297.6100000000,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-02T00:00:00.000+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "145.0000000000",
        "amount": "60.5000000000"
      },
      {
        "orderSummaryId": 3428,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": null,
        "crCcy": "CNY",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2714,
        "basketId": "",
        "exceptions": "Missing DC Config",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-14T09:06:47.463+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP2022034357XF0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "XTransfer",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.325+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "12.5700000000"
      },
      {
        "orderSummaryId": 3429,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": null,
        "crCcy": "CNY",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2715,
        "basketId": "",
        "exceptions": "Missing DC Config",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-14T09:06:47.468+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20223454XF0101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "XTransfer",
        "units": "8.2270000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.327+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "0.0082270000",
        "amount": "87.4300000000"
      },
      {
        "orderSummaryId": 3430,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2716,
        "basketId": "NAV1CSOP20_erere0th11yegl",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.585+00:00",
        "mergeOrderId": 3603,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_erere0th11yegl",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:17.283+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "228572.6500000000"
      },
      {
        "orderSummaryId": 3431,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2717,
        "basketId": "NAV1CSOP20_54354h23brvigm",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.601+00:00",
        "mergeOrderId": 3604,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_54354h23brvigm",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": "230433.9378000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:18.127+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "230.4339378000",
        "amount": null
      },
      {
        "orderSummaryId": 3432,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2718,
        "basketId": "NAV1CSOP20wreer07EM0101C",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.609+00:00",
        "mergeOrderId": 3605,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20wreer07EM0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "194714.4095000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 2022070.20,
        "t0PayAmtFund": 2022070.20,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:19.043+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "194.7144095000",
        "amount": "2022070.2000000000"
      },
      {
        "orderSummaryId": 3433,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2719,
        "basketId": "NAV1CSOP2022090YTERT7EM0101R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.618+00:00",
        "mergeOrderId": 3606,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP2022090YTERT7EM0101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "50810.1960000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -525438.40,
        "t0PayAmtFund": -525438.40,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:20.037+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "50.8101960000",
        "amount": "527653.7200000000"
      },
      {
        "orderSummaryId": 3434,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2720,
        "basketId": "NAV1CSOP20254507SYFE0101C",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.649+00:00",
        "mergeOrderId": 3607,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20254507SYFE0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "SYFE",
        "units": "96.8242000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:20.857+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "0.0968242000",
        "amount": "100.0000000000"
      },
      {
        "orderSummaryId": 3435,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2721,
        "basketId": "NAV1CSOP20220GVFD907M00501R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.656+00:00",
        "mergeOrderId": 3608,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220GVFD907M00501R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Mirae",
        "units": "200000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -2068240.00,
        "t0PayAmtFund": -2068240.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:21.920+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "2.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3436,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2722,
        "basketId": "NAV1CSOP202207BB0101C",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.661+00:00",
        "mergeOrderId": 3609,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP202207BB0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "BARCLAYS Bank",
        "units": "500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 5170600.00,
        "t0PayAmtFund": 5170600.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:22.837+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3437,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2723,
        "basketId": "NAV1CSOP2022093407B00101C",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.667+00:00",
        "mergeOrderId": 3610,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP2022093407B00101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "BNP Paribas",
        "units": "70000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 723884000.00,
        "t0PayAmtFund": 723884000.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:23.730+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "140.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3438,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2724,
        "basketId": "NAV1CSOP20220907A0010145R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.674+00:00",
        "mergeOrderId": 3611,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907A0010145R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "2400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -24818880.00,
        "t0PayAmtFund": -24818880.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:24.587+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "3.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3439,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2725,
        "basketId": "NAV1CSOP20220907A00145402R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.681+00:00",
        "mergeOrderId": 3612,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907A00145402R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "2400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -24818880.00,
        "t0PayAmtFund": -24818880.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:25.547+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "3.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3440,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2726,
        "basketId": "NAV1CSOP20220907A0003R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.692+00:00",
        "mergeOrderId": 3613,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907A0003R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "4800000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -49637760.00,
        "t0PayAmtFund": -49637760.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:26.393+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "12.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3441,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2727,
        "basketId": "NAV1CSOP20220907ERE01C",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.697+00:00",
        "mergeOrderId": 3614,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907ERE01C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "6400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 66183680.00,
        "t0PayAmtFund": 66183680.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:27.203+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "8.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3442,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2728,
        "basketId": "NAV1CSOP202209WERF00104R",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.707+00:00",
        "mergeOrderId": 3615,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP202209WERF00104R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "1600000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:28.357+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "2.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3443,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2729,
        "basketId": "NAV1CSOP20220SRFD00105R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.718+00:00",
        "mergeOrderId": 3616,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220SRFD00105R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "6400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -66183680.00,
        "t0PayAmtFund": -66183680.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:29.197+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "8.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3444,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2730,
        "basketId": "NAV1CSOP2022DFVV7H00101R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.725+00:00",
        "mergeOrderId": 3617,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP2022DFVV7H00101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Haitong",
        "units": "7200000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -74456640.00,
        "t0PayAmtFund": -74456640.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:30.077+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "9.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3445,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2731,
        "basketId": "NAV1CSOP2022094RR00102R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.732+00:00",
        "mergeOrderId": 3618,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP2022094RR00102R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Haitong",
        "units": "3600000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -37228320.00,
        "t0PayAmtFund": -37228320.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:30.947+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "9.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3446,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2732,
        "basketId": "NAV1CSOP20220WRFDM00502R",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.741+00:00",
        "mergeOrderId": 3619,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220WRFDM00502R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Mirae",
        "units": "2400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:31.820+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "3.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3447,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2733,
        "basketId": "NAV1CSOP202209045450301C",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.749+00:00",
        "mergeOrderId": 3620,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP202209045450301C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "BARCLAYS",
        "units": "2500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 25853000.00,
        "t0PayAmtFund": 25853000.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:32.983+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "5.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3448,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2734,
        "basketId": "NAV1CSOP202209WERF00301R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.755+00:00",
        "mergeOrderId": 3621,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP202209WERF00301R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "BARCLAYS",
        "units": "2500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -25853000.00,
        "t0PayAmtFund": -25853000.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:33.873+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "5.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3449,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2735,
        "basketId": "NAV1CSOP2022090WERT0102C",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.762+00:00",
        "mergeOrderId": 3622,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP2022090WERT0102C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "800000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:34.923+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3450,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2736,
        "basketId": "NAV1R016360884545",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.771+00:00",
        "mergeOrderId": 3623,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "R016360884545",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "CSOP o.b.o ABN",
        "units": "500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5101",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 5170600.00,
        "t0PayAmtFund": 5170600.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:35.973+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3451,
        "classId": "CLS00178",
        "fundId": "1000123",
        "fundCode": "CO-CHST",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": null,
        "crCcy": "CNY",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2737,
        "basketId": "",
        "exceptions": "Missing DC Config",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-14T09:06:47.475+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP2009887601R",
        "orderType": "Creation",
        "stockCode": "3033.HK",
        "product": null,
        "pd": "XTransfer",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.376+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "12.5700000000"
      },
      {
        "orderSummaryId": 3452,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": null,
        "crCcy": "CNY",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2738,
        "basketId": "",
        "exceptions": "Missing DC Config",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-14T09:06:47.479+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP276767860101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "XTransfer",
        "units": "8.2270000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.378+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "0.0082270000",
        "amount": "87.4300000000"
      },
      {
        "orderSummaryId": 3453,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2739,
        "basketId": "NAV1CSOP2657667th11yegl",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.777+00:00",
        "mergeOrderId": 3624,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP2657667th11yegl",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:36.967+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "228572.6500000000"
      },
      {
        "orderSummaryId": 3454,
        "classId": "CLS00178",
        "fundId": "1000123",
        "fundCode": "CO-CHST",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": null,
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2740,
        "basketId": "",
        "exceptions": "Missing DC Config",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-14T09:06:47.484+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP287687876h23brvigm",
        "orderType": "Redemption",
        "stockCode": "3033.HK",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": "230433.9378000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.382+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "0.4608678756",
        "amount": null
      },
      {
        "orderSummaryId": 3455,
        "classId": "CLS00178",
        "fundId": "1000123",
        "fundCode": "CO-CHST",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2741,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-14T09:06:47.496+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP2027676567M0101C",
        "orderType": "Creation",
        "stockCode": "3033.HK",
        "product": null,
        "pd": "Eastmoney",
        "units": "194714.4095000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.383+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "0.3894288190",
        "amount": "2022070.2000000000"
      },
      {
        "orderSummaryId": 3456,
        "classId": "CLS00178",
        "fundId": "1000123",
        "fundCode": "CO-CHST",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2742,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-14T09:06:47.502+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP202656576M0101R",
        "orderType": "Redemption",
        "stockCode": "3033.HK",
        "product": null,
        "pd": "Eastmoney",
        "units": "50810.1960000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.385+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "0.1016203920",
        "amount": "527653.7200000000"
      },
      {
        "orderSummaryId": 3457,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2743,
        "basketId": "NAV1CSOP5454547SYFE0101C",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.786+00:00",
        "mergeOrderId": 3625,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP5454547SYFE0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "SYFE",
        "units": "96.8242000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:37.950+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "0.0968242000",
        "amount": "100.0000000000"
      },
      {
        "orderSummaryId": 3458,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2744,
        "basketId": "NAV1CS88767807M00501R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.792+00:00",
        "mergeOrderId": 3626,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CS88767807M00501R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Mirae",
        "units": "200000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -2068240.00,
        "t0PayAmtFund": -2068240.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:38.877+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "2.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3459,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2745,
        "basketId": "NAV1CSOP28787907BB0101C",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.802+00:00",
        "mergeOrderId": 3627,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP28787907BB0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "BARCLAYS Bank",
        "units": "500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 5170600.00,
        "t0PayAmtFund": 5170600.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:39.830+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3460,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2746,
        "basketId": "NAV1CSOP20220907B00101C",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.808+00:00",
        "mergeOrderId": 3628,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907B00101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "BNP Paribas",
        "units": "70000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 723884000.00,
        "t0PayAmtFund": 723884000.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:40.770+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "140.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3461,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2747,
        "basketId": "NAV1CSOP20220907A00101R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.815+00:00",
        "mergeOrderId": 3629,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907A00101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "2400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -24818880.00,
        "t0PayAmtFund": -24818880.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:41.620+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "3.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3462,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2748,
        "basketId": "NAV1CSOP20220907A00102R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.822+00:00",
        "mergeOrderId": 3630,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907A00102R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "2400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -24818880.00,
        "t0PayAmtFund": -24818880.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:42.837+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "3.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3463,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2749,
        "basketId": "NAV1CSOP20220907A00103R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.828+00:00",
        "mergeOrderId": 3631,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907A00103R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "4800000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -49637760.00,
        "t0PayAmtFund": -49637760.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:43.757+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "12.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3464,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2750,
        "basketId": "NAV1CSOP20220907A00101C",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.834+00:00",
        "mergeOrderId": 3632,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907A00101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "6400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 66183680.00,
        "t0PayAmtFund": 66183680.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:44.857+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "8.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3465,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2751,
        "basketId": "NAV1CSOP20220907A00104R",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.841+00:00",
        "mergeOrderId": 3633,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907A00104R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "1600000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:45.780+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "2.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3466,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2752,
        "basketId": "NAV1CSOP20220907A00105R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.848+00:00",
        "mergeOrderId": 3634,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907A00105R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "6400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -66183680.00,
        "t0PayAmtFund": -66183680.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:46.603+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "8.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3467,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2753,
        "basketId": "NAV1CSOP20220907H00101R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.854+00:00",
        "mergeOrderId": 3635,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907H00101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Haitong",
        "units": "7200000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -74456640.00,
        "t0PayAmtFund": -74456640.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:47.490+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "9.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3468,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2754,
        "basketId": "NAV1CSOP20220907H00102R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.861+00:00",
        "mergeOrderId": 3636,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907H00102R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Haitong",
        "units": "3600000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -37228320.00,
        "t0PayAmtFund": -37228320.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:48.770+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "9.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3469,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2755,
        "basketId": "NAV1CSOP20220907M00502R",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.868+00:00",
        "mergeOrderId": 3637,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907M00502R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Mirae",
        "units": "2400000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:49.647+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "3.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3470,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2756,
        "basketId": "NAV1CSOP20220907B00301C",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.894+00:00",
        "mergeOrderId": 3638,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907B00301C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "BARCLAYS",
        "units": "2500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 25853000.00,
        "t0PayAmtFund": 25853000.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:50.543+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "5.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3471,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2757,
        "basketId": "NAV1CSOP20220907B00301R",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.909+00:00",
        "mergeOrderId": 3639,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907B00301R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "BARCLAYS",
        "units": "2500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": -25853000.00,
        "t0PayAmtFund": -25853000.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:51.420+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "5.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3472,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2758,
        "basketId": "NAV1CSOP20220907A00102C",
        "exceptions": "Missing Execution FX Rates",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.922+00:00",
        "mergeOrderId": 3640,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220907A00102C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "ABN",
        "units": "800000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:52.733+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3473,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-09-07T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2759,
        "basketId": "NAV1R01636088",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-10T12:53:10.929+00:00",
        "mergeOrderId": 3641,
        "status": "A",
        "tradeDate": "2022-09-07 00:00:00.0000000 +00:00",
        "orderId": "R01636088",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "CSOP o.b.o ABN",
        "units": "500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5102",
        "dealingDate": "2022-09-07T00:00:00.000+00:00",
        "dealingDate1": "2022-09-08T00:00:00.000+00:00",
        "dealingDate2": "2022-09-09T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0",
        "t0PayAmtPd": 5170600.00,
        "t0PayAmtFund": 5170600.00,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": 0E-10,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-14T14:37:53.787+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3676,
        "classId": "CLS00069",
        "fundId": "1000023",
        "fundCode": "CO-A50F",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-01T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2827,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T15:37:39.354+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-01 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_3frgqiv0avj2ue",
        "orderType": "Redemption",
        "stockCode": "CSOPUMM",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": "418447.9310000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5149",
        "dealingDate": "2022-01-01T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.423+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "0.8368958620",
        "amount": null
      },
      {
        "orderSummaryId": 3677,
        "classId": "CLS00069",
        "fundId": "1000023",
        "fundCode": "CO-A50F",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-01T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2828,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T15:38:26.112+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-01 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_3frh5u0fka62vr",
        "orderType": "Creation",
        "stockCode": "CSOPUMM",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5149",
        "dealingDate": "2022-01-01T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.426+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "513905.2600000000"
      },
      {
        "orderSummaryId": 3678,
        "classId": "CLS00172",
        "fundId": "1000042",
        "fundCode": "CO-CUSF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD-1",
        "orderDate": "2022-01-03T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "USD",
        "recordId": 2829,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T15:38:26.274+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-01 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220101H00301C",
        "orderType": "Creation",
        "stockCode": "3096UA",
        "product": null,
        "pd": "Huatai",
        "units": "6450991.7196000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5149",
        "dealingDate": "2022-01-03T00:00:00.000+00:00",
        "dealingDate1": "2022-01-04T00:00:00.000+00:00",
        "dealingDate2": "2022-01-05T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "USD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.428+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "6700000.0000000000"
      },
      {
        "orderSummaryId": 3679,
        "classId": "CLS00078",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-01T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2830,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T15:38:26.388+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-01 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220101FS0101C",
        "orderType": "Creation",
        "stockCode": "3053UP",
        "product": null,
        "pd": "Futu Securities",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5149",
        "dealingDate": "2022-01-01T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class P unlisted",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.430+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "4328.7200000000"
      },
      {
        "orderSummaryId": 3680,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-01T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2831,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T15:38:26.495+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-01 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220101EM0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "205002.2075000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5149",
        "dealingDate": "2022-01-01T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.431+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "205.0022075000",
        "amount": "2136840.5100000000"
      },
      {
        "orderSummaryId": 3681,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-01T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2832,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T15:38:26.624+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-01 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220101EM0101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "294507.6222000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5149",
        "dealingDate": "2022-01-01T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.433+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "294.5076222000",
        "amount": "3069800.2000000000"
      },
      {
        "orderSummaryId": 3682,
        "classId": "CLS00172",
        "fundId": "1000042",
        "fundCode": "CO-CUSF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD-1",
        "orderDate": "2022-01-03T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "USD",
        "recordId": 2833,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T15:38:26.785+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-01 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220101SYFE0101C",
        "orderType": "Creation",
        "stockCode": "3096UA",
        "product": null,
        "pd": "SYFE",
        "units": "1616.6859000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5149",
        "dealingDate": "2022-01-03T00:00:00.000+00:00",
        "dealingDate1": "2022-01-04T00:00:00.000+00:00",
        "dealingDate2": "2022-01-05T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "USD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.435+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "1679.0900000000"
      },
      {
        "orderSummaryId": 3683,
        "classId": "CLS00178",
        "fundId": "1000123",
        "fundCode": "CO-CHST",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-01T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2834,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T15:38:26.893+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-01 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220101BB0101R",
        "orderType": "Redemption",
        "stockCode": "3033.HK",
        "product": null,
        "pd": "BARCLAYS Bank",
        "units": "500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5149",
        "dealingDate": "2022-01-01T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.437+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3684,
        "classId": "CLS00069",
        "fundId": "1000023",
        "fundCode": "CO-A50F",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-02T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2835,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T16:02:11.487+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-02 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_3frgqiv0avj2uj",
        "orderType": "Redemption",
        "stockCode": "CSOPUMM",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": "418447.9310000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5150",
        "dealingDate": "2022-01-02T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.439+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "0.8368958620",
        "amount": null
      },
      {
        "orderSummaryId": 3685,
        "classId": "CLS00069",
        "fundId": "1000023",
        "fundCode": "CO-A50F",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-02T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2836,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T16:02:18.348+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-02 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_3frh5u0fka62t",
        "orderType": "Creation",
        "stockCode": "CSOPUMM",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5150",
        "dealingDate": "2022-01-02T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.441+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "513905.2600000000"
      },
      {
        "orderSummaryId": 3686,
        "classId": "CLS00172",
        "fundId": "1000042",
        "fundCode": "CO-CUSF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD-1",
        "orderDate": "2022-01-03T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "USD",
        "recordId": 2837,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T16:02:27.404+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-02 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220102H00301C",
        "orderType": "Creation",
        "stockCode": "3096UA",
        "product": null,
        "pd": "Huatai",
        "units": "6450991.7196000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5150",
        "dealingDate": "2022-01-03T00:00:00.000+00:00",
        "dealingDate1": "2022-01-04T00:00:00.000+00:00",
        "dealingDate2": "2022-01-05T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "USD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.443+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "6700000.0000000000"
      },
      {
        "orderSummaryId": 3687,
        "classId": "CLS00078",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-02T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2838,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T16:02:30.266+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-02 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220102FS0101C",
        "orderType": "Creation",
        "stockCode": "3053UP",
        "product": null,
        "pd": "Futu Securities",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5150",
        "dealingDate": "2022-01-02T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class P unlisted",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.445+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "4328.7200000000"
      },
      {
        "orderSummaryId": 3688,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-02T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2839,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T16:02:32.815+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-02 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220102EM0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "205002.2075000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5150",
        "dealingDate": "2022-01-02T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.447+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "205.0022075000",
        "amount": "2136840.5100000000"
      },
      {
        "orderSummaryId": 3689,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-02T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2840,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T16:02:35.203+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-02 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220102EM0101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "294507.6222000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5150",
        "dealingDate": "2022-01-02T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.449+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "294.5076222000",
        "amount": "3069800.2000000000"
      },
      {
        "orderSummaryId": 3690,
        "classId": "CLS00178",
        "fundId": "1000123",
        "fundCode": "CO-CHST",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-02T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2841,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T16:02:38.943+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-02 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220102BB0101R",
        "orderType": "Redemption",
        "stockCode": "3033.HK",
        "product": null,
        "pd": "BARCLAYS Bank",
        "units": "500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5150",
        "dealingDate": "2022-01-02T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.451+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3691,
        "classId": "CLS00178",
        "fundId": "1000123",
        "fundCode": "CO-CHST",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-02T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2842,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T16:02:41.663+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-02 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220102B00101R",
        "orderType": "Redemption",
        "stockCode": "3033.HK",
        "product": null,
        "pd": "BNP Paribas",
        "units": "140000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5150",
        "dealingDate": "2022-01-02T00:00:00.000+00:00",
        "dealingDate1": "2022-01-03T00:00:00.000+00:00",
        "dealingDate2": "2022-01-04T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.453+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "280.0000000000",
        "amount": null
      },
      {
        "orderSummaryId": 3692,
        "classId": "CLS00069",
        "fundId": "1000023",
        "fundCode": "CO-A50F",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-03T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": null,
        "recordId": 2843,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T16:05:52.493+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-03 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_3frgqiv0avj2ujik",
        "orderType": "Redemption",
        "stockCode": "csopumm",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": "418447.9310000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5151",
        "dealingDate": "2022-01-03T00:00:00.000+00:00",
        "dealingDate1": "2022-01-04T00:00:00.000+00:00",
        "dealingDate2": "2022-01-05T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.455+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": null
      },
      {
        "orderSummaryId": 3693,
        "classId": "CLS00069",
        "fundId": "1000023",
        "fundCode": "CO-A50F",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-03T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": null,
        "recordId": 2844,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-15T16:05:52.697+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-03 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_3frh5u0fka62tlm",
        "orderType": "Creation",
        "stockCode": "csopumm",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5151",
        "dealingDate": "2022-01-03T00:00:00.000+00:00",
        "dealingDate1": "2022-01-04T00:00:00.000+00:00",
        "dealingDate2": "2022-01-05T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.457+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "513905.2600000000"
      },
      {
        "orderSummaryId": 3719,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-08-10T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2869,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-16T15:28:21.039+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-08-10 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220910EM0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "84508.1200000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5162",
        "dealingDate": "2022-08-10T00:00:00.000+00:00",
        "dealingDate1": "2022-08-11T00:00:00.000+00:00",
        "dealingDate2": "2022-08-12T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.459+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "84.5081200000",
        "amount": "874904.0800000000"
      },
      {
        "orderSummaryId": 3720,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-08-10T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2870,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-16T15:28:21.231+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-08-10 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220810EM0101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "354922.6500000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5162",
        "dealingDate": "2022-08-10T00:00:00.000+00:00",
        "dealingDate1": "2022-08-11T00:00:00.000+00:00",
        "dealingDate2": "2022-08-12T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.463+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "354.9226500000",
        "amount": "3674478.7300000000"
      },
      {
        "orderSummaryId": 3721,
        "classId": "CLS00077",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-08-10T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2871,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-16T15:28:21.506+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-08-10 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220810H00101R",
        "orderType": "Redemption",
        "stockCode": "3053.HK",
        "product": null,
        "pd": "Haitong",
        "units": "12000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5162",
        "dealingDate": "2022-08-10T00:00:00.000+00:00",
        "dealingDate1": "2022-08-11T00:00:00.000+00:00",
        "dealingDate2": "2022-08-12T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.465+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "12.0000000000",
        "amount": "0.0000000000"
      },
      {
        "orderSummaryId": 3722,
        "classId": "CLS00077",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-08-10T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2872,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-16T15:28:21.710+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-08-10 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220810H00102R",
        "orderType": "Redemption",
        "stockCode": "3053.HK",
        "product": null,
        "pd": "Haitong",
        "units": "1000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5162",
        "dealingDate": "2022-08-10T00:00:00.000+00:00",
        "dealingDate1": "2022-08-11T00:00:00.000+00:00",
        "dealingDate2": "2022-08-12T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.467+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": "0.0000000000"
      },
      {
        "orderSummaryId": 3723,
        "classId": "CLS00091",
        "fundId": "1000042",
        "fundCode": "CO-CUSF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD-1",
        "orderDate": "2022-08-11T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "USD",
        "recordId": 2873,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-16T15:28:21.865+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-08-10 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220810H00103R",
        "orderType": "Redemption",
        "stockCode": "9096.HK",
        "product": null,
        "pd": "Haitong",
        "units": "1000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5162",
        "dealingDate": "2022-08-11T00:00:00.000+00:00",
        "dealingDate1": "2022-08-12T00:00:00.000+00:00",
        "dealingDate2": "2022-08-15T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "USD Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.470+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": "0.0000000000"
      },
      {
        "orderSummaryId": 3724,
        "classId": "CLS00044",
        "fundId": "1000010",
        "fundCode": "CO-CNXT",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-08-10T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "CNY",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2874,
        "basketId": "",
        "exceptions": "Missing Previous Day NAV",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-16T15:28:22.009+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-08-10 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220810BB0101R",
        "orderType": "Redemption",
        "stockCode": "(Stock code: RMB 83147, HKD 3147)",
        "product": null,
        "pd": "BARCLAYS Bank",
        "units": "3000000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5162",
        "dealingDate": "2022-08-10T00:00:00.000+00:00",
        "dealingDate1": "2022-08-11T00:00:00.000+00:00",
        "dealingDate2": "2022-08-12T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.473+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "10.0000000000",
        "amount": "-21062940.0000000000"
      },
      {
        "orderSummaryId": 3725,
        "classId": "CLS00040",
        "fundId": "1000008",
        "fundCode": "CO-USTB",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD-1",
        "orderDate": "2022-08-11T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "CNY",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2875,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-16T15:28:22.201+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-08-10 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220810H00104R",
        "orderType": "Redemption",
        "stockCode": "(Stock code: RMB 83122, HKD 3122)",
        "product": null,
        "pd": "Haitong",
        "units": "60000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5162",
        "dealingDate": "2022-08-11T00:00:00.000+00:00",
        "dealingDate1": "2022-08-12T00:00:00.000+00:00",
        "dealingDate2": "2022-08-15T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.475+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "6.0000000000",
        "amount": "0.0000000000"
      },
      {
        "orderSummaryId": 3748,
        "classId": "CLS00069",
        "fundId": "1000023",
        "fundCode": "CO-A50F",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-12T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2897,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-21T11:55:16.784+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-12 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_3frgqiv0avj2u2",
        "orderType": "Redemption",
        "stockCode": "csopumm",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": "418447.9310000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5165",
        "dealingDate": "2022-01-12T00:00:00.000+00:00",
        "dealingDate1": "2022-01-13T00:00:00.000+00:00",
        "dealingDate2": "2022-01-14T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.477+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": null
      },
      {
        "orderSummaryId": 3749,
        "classId": "CLS00069",
        "fundId": "1000023",
        "fundCode": "CO-A50F",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-12T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2898,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-21T11:55:16.909+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-12 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_3frh5u0fka62v3",
        "orderType": "Creation",
        "stockCode": "csopumm",
        "product": null,
        "pd": "CSOP o.b.o Moomoo Singapore",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5165",
        "dealingDate": "2022-01-12T00:00:00.000+00:00",
        "dealingDate1": "2022-01-13T00:00:00.000+00:00",
        "dealingDate2": "2022-01-14T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "RMB Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.479+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "513905.2600000000"
      },
      {
        "orderSummaryId": 3750,
        "classId": "CLS00172",
        "fundId": "1000042",
        "fundCode": "CO-CUSF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD-1",
        "orderDate": "2022-01-13T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "USD",
        "recordId": 2899,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-21T11:55:17.022+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-12 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220112H00301C",
        "orderType": "Creation",
        "stockCode": "3096UA",
        "product": null,
        "pd": "Huatai",
        "units": "6450991.7196000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5165",
        "dealingDate": "2022-01-13T00:00:00.000+00:00",
        "dealingDate1": "2022-01-14T00:00:00.000+00:00",
        "dealingDate2": "2022-01-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "USD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.481+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "6700000.0000000000"
      },
      {
        "orderSummaryId": 3751,
        "classId": "CLS00078",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-12T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "CNY",
        "recordId": 2900,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-21T11:55:17.145+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-12 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220112FS0101C",
        "orderType": "Creation",
        "stockCode": "3053UP",
        "product": null,
        "pd": "Futu Securities",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5165",
        "dealingDate": "2022-01-12T00:00:00.000+00:00",
        "dealingDate1": "2022-01-13T00:00:00.000+00:00",
        "dealingDate2": "2022-01-14T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class P unlisted",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.482+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "4328.7200000000"
      },
      {
        "orderSummaryId": 3752,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-12T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2901,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-21T11:55:17.263+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-12 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220112EM0101C",
        "orderType": "Creation",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "205002.2075000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5165",
        "dealingDate": "2022-01-12T00:00:00.000+00:00",
        "dealingDate1": "2022-01-13T00:00:00.000+00:00",
        "dealingDate2": "2022-01-14T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.484+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "205.0022000000",
        "amount": "2136840.5100000000"
      },
      {
        "orderSummaryId": 3753,
        "classId": "CLS00173",
        "fundId": "1000029",
        "fundCode": "CO-MMF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-12T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2902,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-21T11:55:17.398+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-12 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220112EM0101R",
        "orderType": "Redemption",
        "stockCode": "3053UA",
        "product": null,
        "pd": "Eastmoney",
        "units": "294507.6222000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5165",
        "dealingDate": "2022-01-12T00:00:00.000+00:00",
        "dealingDate1": "2022-01-13T00:00:00.000+00:00",
        "dealingDate2": "2022-01-14T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.486+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "294.5076000000",
        "amount": "3069800.2000000000"
      },
      {
        "orderSummaryId": 3754,
        "classId": "CLS00172",
        "fundId": "1000042",
        "fundCode": "CO-CUSF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD-1",
        "orderDate": "2022-01-13T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "USD",
        "recordId": 2903,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-21T11:55:17.515+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-12 00:00:00.0000000 +00:00",
        "orderId": "CSOP20_3fsgovmlj6y2we",
        "orderType": "Creation",
        "stockCode": "3096UA",
        "product": null,
        "pd": "CSOP o.b.o CICC",
        "units": null,
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "input",
        "fileId": "5165",
        "dealingDate": "2022-01-13T00:00:00.000+00:00",
        "dealingDate1": "2022-01-14T00:00:00.000+00:00",
        "dealingDate2": "2022-01-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "USD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.488+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "0.0000000000"
      },
      {
        "orderSummaryId": 3755,
        "classId": "CLS00172",
        "fundId": "1000042",
        "fundCode": "CO-CUSF",
        "custodianReconParty": "HSBC",
        "fundGroup": "MMF ETF",
        "dealingDateIndicator": "DD-1",
        "orderDate": "2022-01-13T00:00:00.000+00:00",
        "dcCalcType": "NAV1",
        "crCcy": "USD",
        "tradeFillDay": "T0",
        "shareClassCcy": "USD",
        "recordId": 2904,
        "basketId": "",
        "exceptions": "",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-21T11:55:17.619+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-12 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220112SYFE0101C",
        "orderType": "Creation",
        "stockCode": "3096UA",
        "product": null,
        "pd": "SYFE",
        "units": "1616.6859000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5165",
        "dealingDate": "2022-01-13T00:00:00.000+00:00",
        "dealingDate1": "2022-01-14T00:00:00.000+00:00",
        "dealingDate2": "2022-01-17T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "USD Share Class unlisted A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.490+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": null,
        "amount": "1679.0900000000"
      },
      {
        "orderSummaryId": 3756,
        "classId": "CLS00178",
        "fundId": "1000123",
        "fundCode": "CO-CHST",
        "custodianReconParty": "HSBC",
        "fundGroup": "EQUITY ETF",
        "dealingDateIndicator": "DD",
        "orderDate": "2022-01-12T00:00:00.000+00:00",
        "dcCalcType": "PCF",
        "crCcy": "HKD",
        "tradeFillDay": "T0",
        "shareClassCcy": "HKD",
        "recordId": 2905,
        "basketId": "",
        "exceptions": "Missing PCF Cash Component",
        "signOffStatus": "PEND",
        "versionDate": "2022-11-21T11:55:17.751+00:00",
        "mergeOrderId": 0,
        "status": "A",
        "tradeDate": "2022-01-12 00:00:00.0000000 +00:00",
        "orderId": "CSOP20220112BB0101R",
        "orderType": "Redemption",
        "stockCode": "3033.HK",
        "product": null,
        "pd": "BARCLAYS Bank",
        "units": "500000.0000000000",
        "pdComments": null,
        "contractsExecuted": null,
        "futureContract": null,
        "executionTime": null,
        "comments": null,
        "orderSource": "8ight",
        "fileId": "5165",
        "dealingDate": "2022-01-12T00:00:00.000+00:00",
        "dealingDate1": "2022-01-13T00:00:00.000+00:00",
        "dealingDate2": "2022-01-14T00:00:00.000+00:00",
        "versionUser": "cdb-dc-api",
        "shareClassName": "HKD Share Class A",
        "adjustmentFactor": "0.0000000000",
        "t0PayAmtPd": null,
        "t0PayAmtFund": null,
        "t0Red2ndPayAmt": 0.00,
        "dcT0Fccy": null,
        "reFundPayAmt": null,
        "signOffUser": null,
        "signOffDate": null,
        "signOffComments": null,
        "isMultiDCCalcType": "N",
        "crCharges": null,
        "tfileId": null,
        "calculationTime": "2022-11-22T05:42:26.492+00:00",
        "signOffStatusIndicator": "PENDING",
        "dcCalcTypeArray": [],
        "latest": true,
        "holiday": false,
        "basket": "1.0000000000",
        "amount": null
      }
    ]
  }
  getOrderDetails() {
    return [
      {
        "signOffDate": null,
        "adjustmentFactor": null,
        "versionDate": "2022-10-26T12:42:06.230+00:00",
        "signOffStatus": "PEND",
        "signOffUser": null,
        "order_id": "CSOP20221013A00101C",
        "dealingDate": "2022-10-13T00:00:00.000+00:00",
        "tradeFillDate": "T2",
        "orderDate": "2022-10-13 00:00:00.0",
        "orderType": "Creation",
        "shareClassCcy": "HKD",
        "dcCalType": "TRAD",
        "pmaBasketID": "CSOP20221013A00101C_HK-HST-L_ABN",
        "basketId": null,
        "pd": "ABN",
        "totalBaskets": 55.0000,
        "units": 22000000.00,
        "amount": null,
        "crCcy": "CNY",
        "crCharges": null,
        "cashComponentPcf": null,
        "ratio": 1.00,
        "navPerShare": 8.02,
        "crCapitalFccy": 353003200.00,
        "preFundNav": "0.00",
        "preFundFxRate": 1.0000000000,
        "preFundCashPayAmt": "0.00",
        "pdFee": 2500.00,
        "pdFeeForm": "PD",
        "crPayFxrate": 1.0800000000,
        "crChargesPayAmt": "0.00",
        "t0PayAmtPd": 13915175969.02,
        "t0PayAmtFund": 13915173469.02,
        "t0Red2PayAmt": 0.00,
        "dcT0Fccy": 5361802473.54,
        "t0FundAmt": 5714805673.54,
        "securityFundAmtT1Fx": "0.00",
        "dcT1FxT0": 0.00,
        "refundPayAmt": "0.00",
        "estimatedFill": null,
        "dcChangeT1Fccy": null,
        "dcChangeT2Fccy": null,
        "finalPayAmtT2": null,
        "t0BlendedPayFundFxrate": 0.4106887842,
        "t1BlendedPayFundFxrate": null,
        "t2BlendedPayFundFxrate": null,
        "mergeOrderId": 3191,
        "fundCode": "HK-HST-L",
        "baskets": 55.0000,
        "execFxDate": "NA",
        "execFxCashCompInclude": false,
        "preFundRate": "0.00",
        "preFundBase": "",
        "prefunding": false,
        "taOrderFeeFccy": 120.00,
        "crCharges1": null,
        "pmaShareClass": "HKD Share Class A",
        "taOrderFeeFrom": "MGR",
        "jsonHeaders": [
          "orderDate",
          "dealingDate",
          "tradeFillDate",
          "order_id",
          "orderType",
          "fundCode",
          "pmaShareClass",
          "shareClassCcy",
          "pd",
          "baskets",
          "units",
          "amount",
          "crCcy",
          "dcCalType",
          "crCharges",
          "cashComponentPcf",
          "adjustmentFactor",
          "totalBaskets",
          "ratio",
          "pmaBasketID",
          "execFxDate",
          "execFxCashCompInclude",
          "navPerShare",
          "crCapitalFccy",
          "prefunding",
          "preFundRate",
          "preFundBase",
          "preFundNav",
          "preFundFxRate",
          "preFundCashPayAmt",
          "pdFee",
          "pdFeeForm",
          "crCharges1",
          "crPayFxrate",
          "crChargesPayAmt",
          "t0PayAmtPd",
          "t0PayAmtFund",
          "t0Red2PayAmt",
          "dcT0Fccy",
          "t0FundAmt",
          "securityFundAmtT1Fx",
          "dcT1FxT0",
          "refundPayAmt",
          "estimatedFill",
          "SecurityPayAmtChangeT1",
          "dcChangeT1Fccy",
          "SecurityPayAmtChangeT2",
          "dcChangeT2Fccy",
          "finalPayAmtT2",
          "t0BlendedPayFundFxrate",
          "t1BlendedPayFundFxrate",
          "t2BlendedPayFundFxrate",
          "taOrderFeeFccy",
          "taOrderFeeFrom",
          "signOffStatus",
          "signOffUser",
          "signOffDate"
        ],
        "securityPayAmtChangeT1": null,
        "securityPayAmtChangeT2": null,
        "8fileId": 2214,
        "TfileId": 2241
      }
    ]
  }
  getCardData() {
    return [
      {
        cardType: "AUM",
        cardTitle: "AUM Q2 2022",
        key: "AUM_Q2_2022",
        cardDescription: "Daily AUM time series across all ETFs for one quarter.",
        cardUsage: "mainly for distribution to index company",
        cardData_Quality: "signed-off",
        cardTags: "AUM, NAV1, Units",
        cardAs_of_Date: "6/30/2022",
        cardAuthorized_User: "Quant, Product",
        cardData_Owner: "Operations",
        cardUrl: "https://qa.tectoro.com/commons/downloadfile?url=/data/uploads//data/uploads/commons/csop/datamarketplace/AUM Q2 -9OYiGrTap.csv"
      },
      {
        cardType: "MONTHLY",
        cardTitle: "MONTHLY AUM JUL 2022",
        key: "MONTHLY_AUM_JUL_2022",
        cardDescription: "Month End AUM for all ETFs.",
        cardUsage: "mainly for Finance team on month end AUM distribution",
        cardData_Quality: "signed-off",
        cardTags: "AUM, NAV1",
        cardAs_of_Date: "7/29/2022",
        cardAuthorized_User: "Finance",
        cardData_Owner: "Operations",
        cardUrl: "https://qa.tectoro.com/commons/downloadfile?url=/data/uploads//data/uploads/commons/csop/datamarketplace/Monthly AUM -4cWwJrTap.csv"
      },
      // {
      //   cardType:"MONTHLY",
      //   cardTitle: "MONTHLY AUM AUG 2022",
      //   key:"MONTHLY_AUM_AUG_2022", 
      //   cardDescription: "Month End AUM for all ETFs.",
      //   cardUsage: "mainly for Finance team on month end AUM distribution",
      //   cardData_Quality: "signed-off",
      //   cardTags: "AUM, NAV1",
      //   cardAs_of_Date: "8/31/2022",
      //   cardAuthorized_User: "Finance",
      //   cardData_Owner: "Operations"
      // },
      // {
      //   cardType:"MONTHLY",
      //   cardTitle: "MONTHLY AUM SEP 2022",
      //   key:"MONTHLY_AUM_SEP_2022",
      //   cardDescription: "Month End AUM for all ETFs.",
      //   cardUsage: "mainly for Finance team on month end AUM distribution",
      //   cardData_Quality: "signed-off",
      //   cardTags: "AUM, NAV1",
      //   cardAs_of_Date: "9/30/2022",
      //   cardAuthorized_User: "Finance",
      //   cardData_Owner: "Operations"
      // },
      // {
      //   cardType:"AUM",
      //   cardTitle: "AUM Q3 2022 ", 
      //   key:"AUM_Q3_2022",
      //   cardDescription: "Daily AUM time series across all ETFs for one quarter.",
      //   cardUsage: "mainly for distribution to index company.",
      //   cardData_Quality: "signed-off",
      //   cardTags: "AUM, NAV1, Units",
      //   cardAs_of_Date: "9/30/2022",
      //   cardAuthorized_User: "Quant, Product",
      //   cardData_Owner: "Operations"
      // }
    ]
  }
  getFUDDD() {
    return {
      "data": [
        {
          "adjust_nav_unit": 129.0422831482,
          "ccy": "CNY",
          "class_id": "CLS00002",
          "class_type": "LISTED",
          "estAnnualTD": null,
          "fund_dtd_return": 0.0002703177,
          "fund_id": "1000002",
          "fund_inception": 0.2595696724,
          "fund_mtd_return": 0.0213574136,
          "fund_name": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
          "fund_returns_1M": -0.0006888421,
          "fund_returns_1M_rolling": -0.0006888421,
          "fund_returns_1Y": 0.0134270831,
          "fund_returns_1Y_rolling": 0.0134270831,
          "fund_returns_2M": 0.0090290764,
          "fund_returns_2M_rolling": 0.0090290764,
          "fund_returns_2Y": 0.0416205885,
          "fund_returns_2Y_rolling": 0.0416205885,
          "fund_returns_3M": 0.0112166695,
          "fund_returns_3M_rolling": 0.0112166695,
          "fund_returns_3Y": 0.1131524061,
          "fund_returns_3Y_rolling": 0.1131524061,
          "fund_returns_5Y": 0.0998271571,
          "fund_returns_5Y_rolling": 0.0998271571,
          "fund_returns_6M": 0.0078772261,
          "fund_returns_6M_rolling": 0.0078772261,
          "fund_type": "N",
          "fund_ytd_return": -0.0006888421,
          "index_inception": 0.7297547099,
          "index_mtd_return": 0.0306834046,
          "index_name": null,
          "index_returns_1M": 0.0003948156,
          "index_returns_1M_rolling": 0.0003948156,
          "index_returns_1Y": 0.022446719,
          "index_returns_1Y_rolling": 0.022446719,
          "index_returns_2M": 0.0099153572,
          "index_returns_2M_rolling": 0.0099153572,
          "index_returns_2Y": 0.3530317469,
          "index_returns_2Y_rolling": 0.3530317469,
          "index_returns_3M": 0.0123699576,
          "index_returns_3M_rolling": 0.0123699576,
          "index_returns_3Y": 0.4645684647,
          "index_returns_3Y_rolling": 0.4645684647,
          "index_returns_5Y": 0.4882881294,
          "index_returns_5Y_rolling": 0.4882881294,
          "index_returns_6M": 0.0114112189,
          "index_returns_6M_rolling": 0.0114112189,
          "index_ytd_return": 0.0003948156,
          "leverage_type": "non_li_fund",
          "performance_annual_return": [
            {
              "calendar_year": "2015",
              "fund_return": 0.0687019552,
              "index_return": 0.0777420124
            },
            {
              "calendar_year": "2016",
              "fund_return": 0.0050252997,
              "index_return": 0.0231348299
            },
            {
              "calendar_year": "2017",
              "fund_return": -0.0220060326,
              "index_return": -0.0104111672
            },
            {
              "calendar_year": "2018",
              "fund_return": 0.0644407469,
              "index_return": 0.0772375493
            },
            {
              "calendar_year": "2019",
              "fund_return": 0.0264471245,
              "index_return": 0.323323019
            },
            {
              "calendar_year": "2020",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2021",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2022",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2023",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2024",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2025",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2026",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2027",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2028",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2029",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2030",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2031",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            },
            {
              "calendar_year": "2032",
              "fund_return": 0.0220614525,
              "index_return": 0.0302766353
            }
          ],
          "share_class_name": "RMB Share Class A",
          "since_scheme_change": false,
          "summary_id": 144841,
          "tdteDetails": {
            "TD_1Y": -0.0090196359,
            "TD_DTD": "NA",
            "TD_MTD": -0.009325991,
            "TD_Since_Inception": -0.4701850375,
            "TD_YTD": -0.0010836577,
            "TE_MTD": 0.0041391876,
            "TE_Rolling_1Y": 0.0067158632,
            "TE_Since_Inception": 0.1053664928,
            "TE_YTD": 0.0041391876
          },
          "tdte_annual_return": [
            {
              "calendar_year": "2020",
              "td_annual": -0.0082151828,
              "te_annual": 0.0066880539
            },
            {
              "calendar_year": "2019",
              "td_annual": -0.2968758945,
              "te_annual": 0.2697769017
            },
            {
              "calendar_year": "2018",
              "td_annual": -0.0127968024,
              "te_annual": 0.0048538093
            },
            {
              "calendar_year": "2017",
              "td_annual": -0.0115948654,
              "te_annual": 0.0053667299
            },
            {
              "calendar_year": "2016",
              "td_annual": -0.0181095302,
              "te_annual": 0.0081069333
            },
            {
              "calendar_year": "2015",
              "td_annual": -0.0090400572,
              "te_annual": 0.0064375991
            }
          ]
        },
        {
          "adjust_nav_unit": 0.7997,
          "as_of_date": "2023-10-25T00:00:00Z",
          "ccy": "USD",
          "class_id": "CLS00201",
          "class_type": "LISTED",
          "dtd_diff": 0,
          "estAnnualTD": null,
          "fund_dtd_return": -0.031371124,
          "fund_id": "1000141",
          "fund_inception": -0.2111856382,
          "fund_mtd_return": -0.0226106087,
          "fund_name": "CSOP Metaverse Concept ETF",
          "fund_returns_1M": -0.015390298,
          "fund_returns_1M_rolling": -0.015390298,
          "fund_returns_1Y": 0.2136894825,
          "fund_returns_1Y_rolling": 0.2136894825,
          "fund_returns_2M": -0.0400912255,
          "fund_returns_2M_rolling": -0.0400912255,
          "fund_returns_2Y": "-",
          "fund_returns_2Y_rolling": "-",
          "fund_returns_3M": -0.1015616223,
          "fund_returns_3M_rolling": -0.1015616223,
          "fund_returns_3Y": "-",
          "fund_returns_3Y_rolling": "-",
          "fund_returns_5Y": "-",
          "fund_returns_5Y_rolling": "-",
          "fund_returns_6M": 0.0953294069,
          "fund_returns_6M_rolling": 0.0953294069,
          "fund_since_scheme_chg": -0.031371124,
          "fund_type": "P",
          "fund_ytd_return": 0.27952,
          "inception_date": "2022-03-28T00:00:00.000Z",
          "index_inception": -0.0246991526,
          "index_mtd_return": "-",
          "index_name": "NDX Index",
          "index_returns_1M": "-",
          "index_returns_1M_rolling": "-",
          "index_returns_1Y": "-",
          "index_returns_1Y_rolling": "-",
          "index_returns_2M": "-",
          "index_returns_2M_rolling": "-",
          "index_returns_2Y": "-",
          "index_returns_2Y_rolling": "-",
          "index_returns_3M": "-",
          "index_returns_3M_rolling": "-",
          "index_returns_3Y": "-",
          "index_returns_3Y_rolling": "-",
          "index_returns_5Y": "-",
          "index_returns_5Y_rolling": "-",
          "index_returns_6M": "-",
          "index_returns_6M_rolling": "-",
          "index_since_scheme_chg": -0.0246991526,
          "index_ytd_return": "-",
          "leverage_type": "non_li_fund",
          "performance_annual_return": [],
          "share_class_name": "USD Share Class A",
          "since_scheme_change": true,
          "summary_id": 268035,
          "tdteDetails": {
            "TD_1Y": "NA",
            "TD_DTD": "NA",
            "TD_MTD": "NA",
            "TD_Since_Inception": -0.1864864856,
            "TD_YTD": "NA",
            "TE_MTD": "NA",
            "TE_Rolling_1Y": "NA",
            "TE_Since_Inception": "NA",
            "TE_YTD": "NA"
          },
          "tdte_annual_return": []
        }
      ],
      "status": "success"
    }
  }
  getFUNDDATA() {
    return {
      "fundInformation": {
        "Domicile": "Hong Kong",
        "NoOfHoldings": 0,
        "assetClass": "BOND",
        "baseCcy": "CNY",
        "fundInceptionDate": "2014-02-18T00:00:00Z",
        "investmentExposure": 0,
        "totalNav": 150056582.44
      },
      "shareClassInformation": [
        {
          "ClassName": "CO-B5",
          "TER": "N.A",
          "baseCurrency": "CNY",
          "classID": "CLS00002",
          "classType": "LISTED",
          "indexCcy": "CNY",
          "indexName": "Bloomberg Barclays China Treasury + Policy Bank Index",
          "indexTicker": "I32561CN Index",
          "lotSize": 20,
          "managementFee": "0.49",
          "navPerShare": 101.3896,
          "shareClassName": "RMB Share Class A",
          "ticker": [
            "3199 HK EQUITY",
            "83199 HK EQUITY"
          ],
          "totalNav": 150056582.44,
          "tradingCurrency": [
            "HKD",
            "CNY"
          ]
        },
        {
          "ClassName": "CO-B5",
          "TER": "N.A",
          "baseCurrency": "HKD",
          "classID": "CLS00003",
          "classType": "",
          "indexCcy": "CNY",
          "indexName": "Bloomberg Barclays China Treasury + Policy Bank Index",
          "indexTicker": "I32561CN Index",
          "lotSize": 20,
          "managementFee": "0.49",
          "navPerShare": "N.A",
          "shareClassName": "",
          "ticker": [

          ],
          "totalNav": "N.A",
          "tradingCurrency": [

          ]
        },
        {
          "ClassName": "CO-B5",
          "TER": "N.A",
          "baseCurrency": "CNY",
          "classID": "CLS00003",
          "classType": "",
          "indexCcy": "CNY",
          "indexName": "Bloomberg Barclays China Treasury + Policy Bank Index",
          "indexTicker": "I32561CN Index",
          "lotSize": 20,
          "managementFee": "0.49",
          "navPerShare": 101.3896,
          "shareClassName": "RMB Share Class C",
          "ticker": [
            "3199 HK EQUITY",
            "83199 HK EQUITY"
          ],
          "totalNav": 150056582.44,
          "tradingCurrency": [
            "HKD",
            "CNY"
          ]
        },
        {
          "ClassName": "CO-B5",
          "TER": "N.A",
          "baseCurrency": "CNY",
          "classID": "CLS00004",
          "classType": "",
          "indexCcy": "CNY",
          "indexName": "Bloomberg Barclays China Treasury + Policy Bank Index",
          "indexTicker": "I32561CN Index",
          "lotSize": 20,
          "managementFee": "0.49",
          "navPerShare": 101.3896,
          "shareClassName": "RMB Share Class D",
          "ticker": [
            "3199 HK EQUITY",
            "83199 HK EQUITY"
          ],
          "totalNav": 150056582.44,
          "tradingCurrency": [
            "HKD",
            "CNY"
          ]
        },
        //  {
        //     "ClassName":"CO-B5",
        //     "TER":"N.A",
        //     "baseCurrency":"CNY",
        //     "classID":"CLS00005",
        //     "classType":"",
        //     "indexCcy":"CNY",
        //     "indexName":"Bloomberg Barclays China Treasury + Policy Bank Index",
        //     "indexTicker":"I32561CN Index",
        //     "lotSize":20,
        //     "managementFee":"0.49",
        //     "navPerShare":101.3896,
        //     "shareClassName":"RMB Share Class E",
        //     "ticker":[
        //        "3199 HK EQUITY",
        //        "83199 HK EQUITY"
        //     ],
        //     "totalNav":150056582.44,
        //     "tradingCurrency":[
        //        "HKD",
        //        "CNY"
        //     ]
        //  },


      ],
      "status": "success"
    }
  }
  getCurrencies() {
    return {
      "records": 154,
      "rows": [
        {
          "_id": "9iwFkMSTM",
          "code": "AED",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "UAE Dirham",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "د.إ",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9iwFkMSHpp",
          "code": "AFN",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Afghani",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Af",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "ri6FkpSHpH",
          "code": "ALL",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Lek",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "L",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "riwFkMSTMj",
          "code": "AMD",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Armenian Dram",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Դ",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "riw3kMSHpY",
          "code": "AOA",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Kwanza",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Kz",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "rWw3kMSTMf",
          "code": "ARS",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Argentine Peso",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9i6FCpSHpt",
          "code": "AUD",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Australian Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "rWw3CpZTp-",
          "code": "AWG",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Aruban Guilder/Florin",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ƒ",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9Ww3CpSTp9",
          "code": "AZN",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Azerbaijanian Manat",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ман",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9iwFkMSTMn",
          "code": "BAM",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Konvertibilna Marka",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "КМ",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9i6FCMZHMZ",
          "code": "BBD",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Barbados Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "rW63kMSHMP",
          "code": "BDT",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Taka",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "৳",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9WwFCMSHpx",
          "code": "BGN",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Bulgarian Lev",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "лв",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "ri63kpZTpi",
          "code": "BHD",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Bahraini Dinar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ب.د",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "rWwFCMZHpF",
          "code": "BIF",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Burundi Franc",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₣",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "ri63CpSTph",
          "code": "BMD",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Bermudian Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9i63CpZTps",
          "code": "BND",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Brunei Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9WwFkpSHp2",
          "code": "BOB",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Boliviano",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Bs.",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "rW6FCpZTM8",
          "code": "BRL",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Brazilian Real",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "R$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9iwFkMZHMN",
          "code": "BSD",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Bahamian Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9W6FkMZHMu",
          "code": "BTN",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Ngultrum",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "rW6FCMSTpv",
          "code": "BWP",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Pula",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "P",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9W6FCMZHMK",
          "code": "BYR",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Belarussian Ruble",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Br",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "ri6FCpSHMe",
          "code": "BZD",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Belize Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "rW6FCMSTMI",
          "code": "CAD",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Canadian Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "ri63CpZHpG",
          "code": "CDF",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Congolese Franc",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₣",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9i63CpSTpV",
          "code": "CHF",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Swiss Franc",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₣",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9W6FkMSTp6",
          "code": "CLP",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Chilean Peso",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "ri6FCMZHM1",
          "code": "CNY",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Yuan",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "¥",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9W6FCMSHpR",
          "code": "COP",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Colombian Peso",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9Ww3kMSHpm",
          "code": "CRC",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Costa Rican Colon",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₡",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9i6FCpSHMk",
          "code": "CUP",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Cuban Peso",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9iwFkMZHpo",
          "code": "CVE",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Cape Verde Escudo",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9iw3kpZTMM",
          "code": "CZK",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Czech Koruna",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Kč",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9W63CpSTMT",
          "code": "DJF",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Djibouti Franc",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₣",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "ri63CMZHp0",
          "code": "DKK",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Danish Krone",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "kr",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "rWw3CpSTMD",
          "code": "DOP",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Dominican Peso",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "ri6FkpSHp4",
          "code": "DZD",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Algerian Dinar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "د.ج",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "riw3kMZHpa",
          "code": "EGP",
          "createdAt": "2018-07-09T09:50:42.088Z",
          "currency": "Egyptian Pound",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "£",
          "updatedAt": "2018-07-09T09:50:42.088Z"
        },
        {
          "_id": "9iw3CMZTpz",
          "code": "ERN",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Nakfa",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Nfk",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "cW63CMZTM",
          "code": "ETB",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Ethiopian Birr",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ciw3kMSTpp",
          "code": "EUR",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Euro",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "€",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "5b45fd84825c0246418ef0af",
          "code": "EURO",
          "companyID": "CV",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "European Dollar",
          "type": "CV",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "ci63kMZHpH",
          "code": "FJD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Fiji Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ni63kpSTpj",
          "code": "FKP",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Falkland Islands Pound",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "£",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "nW63kMSHMY",
          "code": "GBP",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Pound Sterling",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "£",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "5c5149a7815bc90c55c22102",
          "code": "GBP",
          "companyID": "CV",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Great Britain Pound",
          "type": "CV",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "nWw3CMZHMf",
          "code": "GEL",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Lari",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ლ",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ciw3CpZHpt",
          "code": "GHS",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Cedi",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₵",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "niw3kMZHp-",
          "code": "GIP",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Gibraltar Pound",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "£",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ci6FCMSTp9",
          "code": "GMD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Dalasi",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "D",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "niwFkMSTMn",
          "code": "GNF",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Guinea Franc",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₣",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "nWwFkpZTMZ",
          "code": "GTQ",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Quetzal",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Q",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "niw3kMSTpP",
          "code": "GYD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Guyana Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "nWw3CMZTpx",
          "code": "HKD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Hong Kong Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ci63CMZHpi",
          "code": "HNL",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Lempira",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "L",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ciwFkMZTpF",
          "code": "HRK",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Croatian Kuna",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Kn",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ci6FkMSHph",
          "code": "HTG",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Gourde",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "G",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ciwFCpZHps",
          "code": "HUF",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Forint",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Ft",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "nWw3kMSTp2",
          "code": "IDR",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Rupiah",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Rp",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ci6FkpZHp8",
          "code": "ILS",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "New Israeli Shekel",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₪",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "niwFkpSHpN",
          "code": "INR",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Indian Rupee",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₨",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "cW63CpSTMu",
          "code": "IQD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Iraqi Dinar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ع.د",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ni63CMSTMv",
          "code": "IRR",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Iranian Rial",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "﷼",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "nWw3CMSTMK",
          "code": "ISK",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Iceland Krona",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Kr",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ni6FkMSHMe",
          "code": "JMD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Jamaican Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ciw3kMSTpI",
          "code": "JOD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Jordanian Dinar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "د.ا",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ni6FkpZHpG",
          "code": "JPY",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Yen",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "¥",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ciw3kMZTMV",
          "code": "KES",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Kenyan Shilling",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Sh",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ni6FCMZHp6",
          "code": "KGS",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Som",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "cW6FCpZHp1",
          "code": "KHR",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Riel",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "៛",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "cW63CMZHMR",
          "code": "KPW",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "North Korean Won",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₩",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "cWw3CpZTpm",
          "code": "KRW",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "South Korean Won",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₩",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ciw3kMSTMk",
          "code": "KWD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Kuwaiti Dinar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "د.ك",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "nW63CpZHpo",
          "code": "KYD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Cayman Islands Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ciw3CMSTpM",
          "code": "KZT",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Tenge",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "〒",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "niwFkMZHpT",
          "code": "LAK",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Kip",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₭",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ciw3kpZHM0",
          "code": "LBP",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Lebanese Pound",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ل.ل",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "cW6FkpSTpD",
          "code": "LKR",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Sri Lanka Rupee",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Rs",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ci63kpSTp4",
          "code": "LRD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Liberian Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "nW6FkpSHMa",
          "code": "LSL",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Loti",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "L",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "nWwFkMSTpz",
          "code": "LYD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Libyan Dinar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ل.د",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "nWwFkMSHpr",
          "code": "MAD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Moroccan Dirham",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "د.م.",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "cW63CpSTpc",
          "code": "MDL",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Moldavian Leu",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "L",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "nWwFkpZTpS",
          "code": "MGA",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Malagasy Ariary",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ci63kpSHpg",
          "code": "MKD",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Denar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ден",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ci6FCpZTME",
          "code": "MMK",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Kyat",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "K",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ci63kpZHpW",
          "code": "MNT",
          "createdAt": "2018-07-09T09:50:42.089Z",
          "currency": "Tugrik",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₮",
          "updatedAt": "2018-07-09T09:50:42.089Z"
        },
        {
          "_id": "ciw3CMZTp3",
          "code": "MOP",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Pataca",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "P",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "Ziw3kMZTM",
          "code": "MRO",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Ouguiya",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "UM",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "Si6FkpSTMp",
          "code": "MUR",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Mauritius Rupee",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₨",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "SiwFkMSTMH",
          "code": "MVR",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Rufiyaa",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ރ.",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "Si6FkpZHpj",
          "code": "MWK",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Kwacha",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "MK",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "SW63CpZTMY",
          "code": "MXN",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Mexican Peso",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "SW6FCMSHMf",
          "code": "MYR",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Malaysian Ringgit",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "RM",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "Si6FCMSHMt",
          "code": "MZN",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Metical",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "MTn",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "Si6FCMZHp-",
          "code": "NAD",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Namibia Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "ZWwFCpZTp9",
          "code": "NGN",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Naira",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₦",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "SiwFkpSHpn",
          "code": "NIO",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Cordoba Oro",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "C$",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "Siw3kMZTMZ",
          "code": "NOK",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Norwegian Krone",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "kr",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "SiwFCMZTpP",
          "code": "NPR",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Nepalese Rupee",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₨",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "ZW63kpZTpx",
          "code": "NZD",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "New Zealand Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "Zi63CpSHMi",
          "code": "OMR",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Rial Omani",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ر.ع.",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "ZWw3CpZHpF",
          "code": "PAB",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Balboa",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "B/.",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "Zi63kpSHph",
          "code": "PEN",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Nuevo Sol",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "S/.",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "ZWwFkpSTMs",
          "code": "PGK",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Kina",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "K",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "SiwFkMSTp2",
          "code": "PHP",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Philippine Peso",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₱",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "ZiwFCMZTM8",
          "code": "PKR",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Pakistan Rupee",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₨",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "Zi6FkMSTpN",
          "code": "PLN",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "PZloty",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "zł",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "SWwFCpSHMu",
          "code": "PYG",
          "createdAt": "2018-07-09T09:50:42.09Z",
          "currency": "Guarani",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₲",
          "updatedAt": "2018-07-09T09:50:42.09Z"
        },
        {
          "_id": "SW6FkpSTpv",
          "code": "QAR",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Qatari Rial",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ر.ق",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "giw3CpSTp",
          "code": "RON",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Leu",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "L",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW63kMZTpp",
          "code": "RSD",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Serbian Dinar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "din",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW63CpZHMH",
          "code": "RUB",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Russian Ruble",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "р.",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW6FCMZHpj",
          "code": "RWF",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Rwanda Franc",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₣",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "Pi6FkMZHpY",
          "code": "SAR",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Saudi Riyal",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ر.س",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "giw3CpSHMf",
          "code": "SBD",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Solomon Islands Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "gi6FCMSTMt",
          "code": "SCR",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Seychelles Rupee",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₨",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW6FCMSTp-",
          "code": "SDG",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Sudanese Pound",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "£",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PiwFCMSHp9",
          "code": "SEK",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Swedish Krona",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "kr",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "Pi6FkMSHpn",
          "code": "SGD",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Singapore Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW6FkpSHMZ",
          "code": "SHP",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Saint Helena Pound",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "£",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "Pi63CpZTMP",
          "code": "SLL",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Leone",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Le",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PiwFCMZTpx",
          "code": "SOS",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Somali Shilling",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Sh",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PWwFCMSTpi",
          "code": "SRD",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Suriname Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PiwFkMSTMF",
          "code": "STD",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Dobra",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Db",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "gWw3CpSHph",
          "code": "SYP",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Syrian Pound",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ل.س",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "giw3CpZTMs",
          "code": "SZL",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Lilangeni",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "L",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "giwFkMZTM2",
          "code": "THB",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Baht",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "฿",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "gW63CMSHp8",
          "code": "TJS",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Somoni",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ЅМ",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "gWw3CpSTMN",
          "code": "TMT",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Manat",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "m",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "gi63CMZHMu",
          "code": "TND",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Tunisian Dinar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "د.ت",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW6FCMZTpv",
          "code": "TOP",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Pa’anga",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "T$",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "gi63CMSHMK",
          "code": "TRY",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Turkish Lira",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₤",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "Piw3CpZHMe",
          "code": "TTD",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Trinidad and Tobago Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "Piw3kMZTpI",
          "code": "TWD",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Taiwan Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PiwFkMSTMG",
          "code": "TZS",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Tanzanian Shilling",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Sh",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PWwFkpZHpV",
          "code": "UAH",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Hryvnia",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₴",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "gi63CMSHp6",
          "code": "UGX",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Uganda Shilling",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Sh",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "5b45fd84825c0246418ef0aa",
          "code": "US$",
          "companyID": "CV",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "US Dollar",
          "type": "CV",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "Pi63CpSTM1",
          "code": "USD",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "US Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW63kpSHMR",
          "code": "UYU",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Peso Uruguayo",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW63kpZHpm",
          "code": "UZS",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Uzbekistan Sum",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW63kMSHpk",
          "code": "VEF",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Bolivar Fuerte",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Bs F",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PWw3kMZTMo",
          "code": "VND",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Dong",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₫",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW63kMSHMM",
          "code": "VUV",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Vatu",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "Vt",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "gWw3kMZHMT",
          "code": "WST",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Tala",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "T",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW63CMZHM0",
          "code": "XAF",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "CFA Franc BCEAO",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₣",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW6FkMSHMD",
          "code": "XCD",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "East Caribbean Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "gi63kMSHp4",
          "code": "XPF",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "CFP Franc",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "₣",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PWwFCpZTMa",
          "code": "YER",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Yemeni Rial",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "﷼",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PWwFkMZTpz",
          "code": "ZAR",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Rand",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "R",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "PW6FkMSHpr",
          "code": "ZMW",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Zambian Kwacha",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "ZK",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        },
        {
          "_id": "gWw3CpSHMc",
          "code": "ZWL",
          "createdAt": "2018-07-09T09:50:42.091Z",
          "currency": "Zimbabwe Dollar",
          "locations": "TECTORO",
          "num": 0,
          "symbol": "$",
          "updatedAt": "2018-07-09T09:50:42.091Z"
        }
      ],
      "status": "success",
      "total": 1
    }
  }
  getCountries() {
    return {
      "rows": [
        {
          "_id": "Vk-s7rjoBe",
          "areaInSqKm": "647500.0",
          "capital": "Kabul",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "AF",
          "countryName": "Afghanistan",
          "createdAt": "2015-12-17T13:18:02.477Z",
          "currencyCode": "AFN",
          "east": 74.879448,
          "fipsCode": "AF",
          "geonameId": "1149361",
          "id": "Vk-s7rjoBe",
          "isoAlpha3": "AFG",
          "isoNumeric": "004",
          "languages": "fa-AF,ps,uz-AF,tk",
          "name": "Afghanistan",
          "north": 38.483418,
          "population": "29121286",
          "south": 29.377472,
          "updatedAt": "2015-12-17T13:18:02.477Z",
          "west": 60.478443
        },
        {
          "_id": "NyVsmHsoHx",
          "areaInSqKm": "28748.0",
          "capital": "Tirana",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "AL",
          "countryName": "Albania",
          "createdAt": "2015-12-17T13:18:02.479Z",
          "currencyCode": "ALL",
          "east": 21.068472,
          "fipsCode": "AL",
          "geonameId": "783754",
          "id": "NyVsmHsoHx",
          "isoAlpha3": "ALB",
          "isoNumeric": "008",
          "languages": "sq,el",
          "name": "Albania",
          "north": 42.665611,
          "population": "2986952",
          "south": 39.648361,
          "updatedAt": "2015-12-17T13:18:02.479Z",
          "west": 19.293972
        },
        {
          "_id": "EJ3zj7SjoSg",
          "areaInSqKm": "2381740.0",
          "capital": "Algiers",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "DZ",
          "countryName": "Algeria",
          "createdAt": "2015-12-17T13:18:02.514Z",
          "currencyCode": "DZD",
          "east": 11.979548,
          "fipsCode": "AG",
          "geonameId": "2589581",
          "id": "EJ3zj7SjoSg",
          "isoAlpha3": "DZA",
          "isoNumeric": "012",
          "languages": "ar-DZ",
          "name": "Algeria",
          "north": 37.093723,
          "population": "34586184",
          "south": 18.960028,
          "updatedAt": "2015-12-17T13:18:02.514Z",
          "west": -8.673868
        },
        {
          "_id": "NyFi7HsjHx",
          "areaInSqKm": "199.0",
          "capital": "Pago Pago",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "AS",
          "countryName": "American Samoa",
          "createdAt": "2015-12-17T13:18:02.484Z",
          "currencyCode": "USD",
          "east": -169.416077,
          "fipsCode": "AQ",
          "geonameId": "5880801",
          "id": "NyFi7HsjHx",
          "isoAlpha3": "ASM",
          "isoNumeric": "016",
          "languages": "en-AS,sm,to",
          "name": "American Samoa",
          "north": -11.0497,
          "population": "57881",
          "south": -14.382478,
          "updatedAt": "2015-12-17T13:18:02.484Z",
          "west": -171.091888
        },
        {
          "_id": "Nyo7Sjsrl",
          "areaInSqKm": "468.0",
          "capital": "Andorra la Vella",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "AD",
          "countryName": "Andorra",
          "createdAt": "2015-12-17T13:18:02.473Z",
          "currencyCode": "EUR",
          "east": 1.786542777831983,
          "fipsCode": "AN",
          "geonameId": "3041565",
          "id": "Nyo7Sjsrl",
          "isoAlpha3": "AND",
          "isoNumeric": "020",
          "languages": "ca",
          "name": "Andorra",
          "north": 42.65604389629997,
          "population": "84000",
          "south": 42.42849259876837,
          "updatedAt": "2015-12-17T13:18:02.473Z",
          "west": 1.407186714111276
        },
        {
          "_id": "4yUs7Ssorx",
          "areaInSqKm": "1246700.0",
          "capital": "Luanda",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "AO",
          "countryName": "Angola",
          "createdAt": "2015-12-17T13:18:02.481Z",
          "currencyCode": "AOA",
          "east": 24.082119,
          "fipsCode": "AO",
          "geonameId": "3351879",
          "id": "4yUs7Ssorx",
          "isoAlpha3": "AGO",
          "isoNumeric": "024",
          "languages": "pt-AO",
          "name": "Angola",
          "north": -4.376826,
          "population": "13068161",
          "south": -18.042076,
          "updatedAt": "2015-12-17T13:18:02.481Z",
          "west": 11.679219
        },
        {
          "_id": "EkXsmBooHx",
          "areaInSqKm": "102.0",
          "capital": "The Valley",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "AI",
          "countryName": "Anguilla",
          "createdAt": "2015-12-17T13:18:02.479Z",
          "currencyCode": "XCD",
          "east": -62.971359,
          "fipsCode": "AV",
          "geonameId": "3573511",
          "id": "EkXsmBooHx",
          "isoAlpha3": "AIA",
          "isoNumeric": "660",
          "languages": "en-AI",
          "name": "Anguilla",
          "north": 18.283424,
          "population": "13254",
          "south": 18.166815,
          "updatedAt": "2015-12-17T13:18:02.479Z",
          "west": -63.172901
        },
        {
          "_id": "EJPoXrisHx",
          "areaInSqKm": "1.4E7",
          "capital": "",
          "companyID": "TECTORO",
          "continent": "AN",
          "continentName": "Antarctica",
          "countryCode": "AQ",
          "countryName": "Antarctica",
          "createdAt": "2015-12-17T13:18:02.481Z",
          "currencyCode": "",
          "east": 179.9999,
          "fipsCode": "AY",
          "geonameId": "6697173",
          "id": "EJPoXrisHx",
          "isoAlpha3": "ATA",
          "isoNumeric": "010",
          "languages": "",
          "name": "Antarctica",
          "north": -60.515533,
          "population": "0",
          "south": -89.9999,
          "updatedAt": "2015-12-17T13:18:02.481Z",
          "west": -179.9999
        },
        {
          "_id": "E1Gs7rjorx",
          "areaInSqKm": "443.0",
          "capital": "St. John's",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "AG",
          "countryName": "Antigua and Barbuda",
          "createdAt": "2015-12-17T13:18:02.478Z",
          "currencyCode": "XCD",
          "east": -61.672421,
          "fipsCode": "AC",
          "geonameId": "3576396",
          "id": "E1Gs7rjorx",
          "isoAlpha3": "ATG",
          "isoNumeric": "028",
          "languages": "en-AG",
          "name": "Antigua and Barbuda",
          "north": 17.729387,
          "population": "86754",
          "south": 16.996979,
          "updatedAt": "2015-12-17T13:18:02.478Z",
          "west": -61.906425
        },
        {
          "_id": "VydsQSojHg",
          "areaInSqKm": "2766890.0",
          "capital": "Buenos Aires",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "AR",
          "countryName": "Argentina",
          "createdAt": "2015-12-17T13:18:02.483Z",
          "currencyCode": "ARS",
          "east": -53.591835,
          "fipsCode": "AR",
          "geonameId": "3865483",
          "id": "VydsQSojHg",
          "isoAlpha3": "ARG",
          "isoNumeric": "032",
          "languages": "es-AR,en,it,de,fr,gn",
          "name": "Argentina",
          "north": -21.781277,
          "population": "41343201",
          "south": -55.061314,
          "updatedAt": "2015-12-17T13:18:02.483Z",
          "west": -73.58297
        },
        {
          "_id": "4kBj7HsjSx",
          "areaInSqKm": "29800.0",
          "capital": "Yerevan",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "AM",
          "countryName": "Armenia",
          "createdAt": "2015-12-17T13:18:02.48Z",
          "currencyCode": "AMD",
          "east": 46.77243504515999,
          "fipsCode": "AM",
          "geonameId": "174982",
          "id": "4kBj7HsjSx",
          "isoAlpha3": "ARM",
          "isoNumeric": "051",
          "languages": "hy",
          "name": "Armenia",
          "north": 41.301834,
          "population": "2968000",
          "south": 38.830528,
          "updatedAt": "2015-12-17T13:18:02.48Z",
          "west": 43.44978
        },
        {
          "_id": "4Jni7Bjorx",
          "areaInSqKm": "193.0",
          "capital": "Oranjestad",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "AW",
          "countryName": "Aruba",
          "createdAt": "2015-12-17T13:18:02.488Z",
          "currencyCode": "AWG",
          "east": -69.86575120104982,
          "fipsCode": "AA",
          "geonameId": "3577279",
          "id": "4Jni7Bjorx",
          "isoAlpha3": "ABW",
          "isoNumeric": "533",
          "languages": "nl-AW,es,en",
          "name": "Aruba",
          "north": 12.62371812715292,
          "population": "71566",
          "south": 12.41170770619072,
          "updatedAt": "2015-12-17T13:18:02.488Z",
          "west": -70.0644737196045
        },
        {
          "_id": "N1jimSooSe",
          "areaInSqKm": "7686850.0",
          "capital": "Canberra",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "AU",
          "countryName": "Australia",
          "createdAt": "2015-12-17T13:18:02.485Z",
          "currencyCode": "AUD",
          "east": 153.639252,
          "fipsCode": "AS",
          "geonameId": "2077456",
          "id": "N1jimSooSe",
          "isoAlpha3": "AUS",
          "isoNumeric": "036",
          "languages": "en-AU",
          "name": "Australia",
          "north": -10.062805,
          "population": "21515754",
          "south": -43.64397,
          "updatedAt": "2015-12-17T13:18:02.485Z",
          "west": 112.911057
        },
        {
          "_id": "4kqjmSsjrg",
          "areaInSqKm": "83858.0",
          "capital": "Vienna",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "AT",
          "countryName": "Austria",
          "createdAt": "2015-12-17T13:18:02.484Z",
          "currencyCode": "EUR",
          "east": 17.1620685652599,
          "fipsCode": "AU",
          "geonameId": "2782113",
          "id": "4kqjmSsjrg",
          "isoAlpha3": "AUT",
          "isoNumeric": "040",
          "languages": "de-AT,hr,hu,sl",
          "name": "Austria",
          "north": 49.0211627691393,
          "population": "8205000",
          "south": 46.3726520216244,
          "updatedAt": "2015-12-17T13:18:02.484Z",
          "west": 9.53095237240833
        },
        {
          "_id": "41RoQBssSx",
          "areaInSqKm": "86600.0",
          "capital": "Baku",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "AZ",
          "countryName": "Azerbaijan",
          "createdAt": "2015-12-17T13:18:02.489Z",
          "currencyCode": "AZN",
          "east": 50.370083,
          "fipsCode": "AJ",
          "geonameId": "587116",
          "id": "41RoQBssSx",
          "isoAlpha3": "AZE",
          "isoNumeric": "031",
          "languages": "az,ru,hy",
          "name": "Azerbaijan",
          "north": 41.90564,
          "population": "8303512",
          "south": 38.38915252685547,
          "updatedAt": "2015-12-17T13:18:02.489Z",
          "west": 44.774113
        },
        {
          "_id": "VkRxiXBjsBl",
          "areaInSqKm": "13940.0",
          "capital": "Nassau",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "BS",
          "countryName": "Bahamas",
          "createdAt": "2015-12-17T13:18:02.499Z",
          "currencyCode": "BSD",
          "east": -74.423874,
          "fipsCode": "BF",
          "geonameId": "3572887",
          "id": "VkRxiXBjsBl",
          "isoAlpha3": "BHS",
          "isoNumeric": "044",
          "languages": "en-BS",
          "name": "Bahamas",
          "north": 26.919243,
          "population": "301790",
          "south": 22.852743,
          "updatedAt": "2015-12-17T13:18:02.499Z",
          "west": -78.995911
        },
        {
          "_id": "VkBeoQBojre",
          "areaInSqKm": "665.0",
          "capital": "Manama",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "BH",
          "countryName": "Bahrain",
          "createdAt": "2015-12-17T13:18:02.493Z",
          "currencyCode": "BHD",
          "east": 50.664471,
          "fipsCode": "BA",
          "geonameId": "290291",
          "id": "VkBeoQBojre",
          "isoAlpha3": "BHR",
          "isoNumeric": "048",
          "languages": "ar-BH,en,fa,ur",
          "name": "Bahrain",
          "north": 26.282583,
          "population": "738004",
          "south": 25.796862,
          "updatedAt": "2015-12-17T13:18:02.493Z",
          "west": 50.45414
        },
        {
          "_id": "NybgsXBssSe",
          "areaInSqKm": "144000.0",
          "capital": "Dhaka",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "BD",
          "countryName": "Bangladesh",
          "createdAt": "2015-12-17T13:18:02.491Z",
          "currencyCode": "BDT",
          "east": 92.673668,
          "fipsCode": "BG",
          "geonameId": "1210997",
          "id": "NybgsXBssSe",
          "isoAlpha3": "BGD",
          "isoNumeric": "050",
          "languages": "bn-BD,en",
          "name": "Bangladesh",
          "north": 26.631945,
          "population": "156118464",
          "south": 20.743334,
          "updatedAt": "2015-12-17T13:18:02.491Z",
          "west": 88.028336
        },
        {
          "_id": "N1xlimHiiBg",
          "areaInSqKm": "431.0",
          "capital": "Bridgetown",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "BB",
          "countryName": "Barbados",
          "createdAt": "2015-12-17T13:18:02.491Z",
          "currencyCode": "BBD",
          "east": -59.420376,
          "fipsCode": "BB",
          "geonameId": "3374084",
          "id": "N1xlimHiiBg",
          "isoAlpha3": "BRB",
          "isoNumeric": "052",
          "languages": "en-BB",
          "name": "Barbados",
          "north": 13.327257,
          "population": "285653",
          "south": 13.039844,
          "updatedAt": "2015-12-17T13:18:02.491Z",
          "west": -59.648922
        },
        {
          "_id": "41zWjXHosrx",
          "areaInSqKm": "207600.0",
          "capital": "Minsk",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "BY",
          "countryName": "Belarus",
          "createdAt": "2015-12-17T13:18:02.501Z",
          "currencyCode": "BYR",
          "east": 32.770805,
          "fipsCode": "BO",
          "geonameId": "630336",
          "id": "41zWjXHosrx",
          "isoAlpha3": "BLR",
          "isoNumeric": "112",
          "languages": "be,ru",
          "name": "Belarus",
          "north": 56.165806,
          "population": "9685000",
          "south": 51.256416,
          "updatedAt": "2015-12-17T13:18:02.501Z",
          "west": 23.176889
        },
        {
          "_id": "N1fgomrssSe",
          "areaInSqKm": "30510.0",
          "capital": "Brussels",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "BE",
          "countryName": "Belgium",
          "createdAt": "2015-12-17T13:18:02.492Z",
          "currencyCode": "EUR",
          "east": 6.403861,
          "fipsCode": "BE",
          "geonameId": "2802361",
          "id": "N1fgomrssSe",
          "isoAlpha3": "BEL",
          "isoNumeric": "056",
          "languages": "nl-BE,fr-BE,de-BE",
          "name": "Belgium",
          "north": 51.505444,
          "population": "10403000",
          "south": 49.49361,
          "updatedAt": "2015-12-17T13:18:02.492Z",
          "west": 2.546944
        },
        {
          "_id": "V1QZimBjoHe",
          "areaInSqKm": "22966.0",
          "capital": "Belmopan",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "BZ",
          "countryName": "Belize",
          "createdAt": "2015-12-17T13:18:02.502Z",
          "currencyCode": "BZD",
          "east": -87.776985,
          "fipsCode": "BH",
          "geonameId": "3582678",
          "id": "V1QZimBjoHe",
          "isoAlpha3": "BLZ",
          "isoNumeric": "084",
          "languages": "en-BZ,es",
          "name": "Belize",
          "north": 18.496557,
          "population": "314522",
          "south": 15.8893,
          "updatedAt": "2015-12-17T13:18:02.502Z",
          "west": -89.224815
        },
        {
          "_id": "EyvljQSoirx",
          "areaInSqKm": "112620.0",
          "capital": "Porto-Novo",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "BJ",
          "countryName": "Benin",
          "createdAt": "2015-12-17T13:18:02.494Z",
          "currencyCode": "XOF",
          "east": 3.851701,
          "fipsCode": "BN",
          "geonameId": "2395170",
          "id": "EyvljQSoirx",
          "isoAlpha3": "BEN",
          "isoNumeric": "204",
          "languages": "fr-BJ",
          "name": "Benin",
          "north": 12.418347,
          "population": "9056010",
          "south": 6.225748,
          "updatedAt": "2015-12-17T13:18:02.494Z",
          "west": 0.774575
        },
        {
          "_id": "4JKxomSisBg",
          "areaInSqKm": "53.0",
          "capital": "Hamilton",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "BM",
          "countryName": "Bermuda",
          "createdAt": "2015-12-17T13:18:02.496Z",
          "currencyCode": "BMD",
          "east": -64.651993,
          "fipsCode": "BD",
          "geonameId": "3573345",
          "id": "4JKxomSisBg",
          "isoAlpha3": "BMU",
          "isoNumeric": "060",
          "languages": "en-BM,pt",
          "name": "Bermuda",
          "north": 32.393833,
          "population": "65365",
          "south": 32.246639,
          "updatedAt": "2015-12-17T13:18:02.496Z",
          "west": -64.89605
        },
        {
          "_id": "4kkWomHjiSl",
          "areaInSqKm": "47000.0",
          "capital": "Thimphu",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "BT",
          "countryName": "Bhutan",
          "createdAt": "2015-12-17T13:18:02.5Z",
          "currencyCode": "BTN",
          "east": 92.125191,
          "fipsCode": "BT",
          "geonameId": "1252634",
          "id": "4kkWomHjiSl",
          "isoAlpha3": "BTN",
          "isoNumeric": "064",
          "languages": "dz",
          "name": "Bhutan",
          "north": 28.323778,
          "population": "699847",
          "south": 26.70764,
          "updatedAt": "2015-12-17T13:18:02.5Z",
          "west": 88.75972
        },
        {
          "_id": "VkjesXrssBe",
          "areaInSqKm": "1098580.0",
          "capital": "Sucre",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "BO",
          "countryName": "Bolivia",
          "createdAt": "2015-12-17T13:18:02.497Z",
          "currencyCode": "BOB",
          "east": -57.45809600000001,
          "fipsCode": "BL",
          "geonameId": "3923057",
          "id": "VkjesXrssBe",
          "isoAlpha3": "BOL",
          "isoNumeric": "068",
          "languages": "es-BO,qu,ay",
          "name": "Bolivia",
          "north": -9.680567,
          "population": "9947418",
          "south": -22.896133,
          "updatedAt": "2015-12-17T13:18:02.497Z",
          "west": -69.640762
        },
        {
          "_id": "Nkhgo7HoiSg",
          "areaInSqKm": "328.0",
          "capital": "Kralendijk",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "BQ",
          "countryName": "Bonaire",
          "createdAt": "2015-12-17T13:18:02.498Z",
          "currencyCode": "USD",
          "east": -68.192307,
          "fipsCode": "",
          "geonameId": "7626844",
          "id": "Nkhgo7HoiSg",
          "isoAlpha3": "BES",
          "isoNumeric": "535",
          "languages": "nl,pap,en",
          "name": "Bonaire",
          "north": 12.304535,
          "population": "18012",
          "south": 12.017149,
          "updatedAt": "2015-12-17T13:18:02.498Z",
          "west": -68.416458
        },
        {
          "_id": "EyJxo7HjiHe",
          "areaInSqKm": "51129.0",
          "capital": "Sarajevo",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "BA",
          "countryName": "Bosnia and Herzegovina",
          "createdAt": "2015-12-17T13:18:02.49Z",
          "currencyCode": "BAM",
          "east": 19.622223,
          "fipsCode": "BK",
          "geonameId": "3277605",
          "id": "EyJxo7HjiHe",
          "isoAlpha3": "BIH",
          "isoNumeric": "070",
          "languages": "bs,hr-BA,sr-BA",
          "name": "Bosnia and Herzegovina",
          "north": 45.239193,
          "population": "4590000",
          "south": 42.546112,
          "updatedAt": "2015-12-17T13:18:02.49Z",
          "west": 15.718945
        },
        {
          "_id": "V1bboXSsjSe",
          "areaInSqKm": "600370.0",
          "capital": "Gaborone",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "BW",
          "countryName": "Botswana",
          "createdAt": "2015-12-17T13:18:02.501Z",
          "currencyCode": "BWP",
          "east": 29.360781,
          "fipsCode": "BC",
          "geonameId": "933860",
          "id": "V1bboXSsjSe",
          "isoAlpha3": "BWA",
          "isoNumeric": "072",
          "languages": "en-BW,tn-BW",
          "name": "Botswana",
          "north": -17.780813,
          "population": "2029307",
          "south": -26.907246,
          "updatedAt": "2015-12-17T13:18:02.501Z",
          "west": 19.999535
        },
        {
          "_id": "NyebiXBoiSg",
          "areaInSqKm": "49.0",
          "capital": "",
          "companyID": "TECTORO",
          "continent": "AN",
          "continentName": "Antarctica",
          "countryCode": "BV",
          "countryName": "Bouvet Island",
          "createdAt": "2015-12-17T13:18:02.5Z",
          "currencyCode": "NOK",
          "east": 3.487976,
          "fipsCode": "BV",
          "geonameId": "3371123",
          "id": "NyebiXBoiSg",
          "isoAlpha3": "BVT",
          "isoNumeric": "074",
          "languages": "",
          "name": "Bouvet Island",
          "north": -54.400322,
          "population": "0",
          "south": -54.462383,
          "updatedAt": "2015-12-17T13:18:02.5Z",
          "west": 3.335499
        },
        {
          "_id": "N1TesmrojSe",
          "areaInSqKm": "8511965.0",
          "capital": "Brasília",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "BR",
          "countryName": "Brazil",
          "createdAt": "2015-12-17T13:18:02.498Z",
          "currencyCode": "BRL",
          "east": -32.392998,
          "fipsCode": "BR",
          "geonameId": "3469034",
          "id": "N1TesmrojSe",
          "isoAlpha3": "BRA",
          "isoNumeric": "076",
          "languages": "pt-BR,es,en,fr",
          "name": "Brazil",
          "north": 5.264877,
          "population": "201103330",
          "south": -33.750706,
          "updatedAt": "2015-12-17T13:18:02.498Z",
          "west": -73.985535
        },
        {
          "_id": "4kuSoQrssrx",
          "areaInSqKm": "60.0",
          "capital": "",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "IO",
          "countryName": "British Indian Ocean Territory",
          "createdAt": "2015-12-17T13:18:02.534Z",
          "currencyCode": "USD",
          "east": 72.493164,
          "fipsCode": "IO",
          "geonameId": "1282588",
          "id": "4kuSoQrssrx",
          "isoAlpha3": "IOT",
          "isoNumeric": "086",
          "languages": "en-IO",
          "name": "British Indian Ocean Territory",
          "north": -5.268333,
          "population": "4000",
          "south": -7.438028,
          "updatedAt": "2015-12-17T13:18:02.534Z",
          "west": 71.259972
        },
        {
          "_id": "EkTTjXrsiBx",
          "areaInSqKm": "153.0",
          "capital": "Road Town",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "VG",
          "countryName": "British Virgin Islands",
          "createdAt": "2015-12-17T13:18:02.599Z",
          "currencyCode": "USD",
          "east": -64.268768,
          "fipsCode": "VI",
          "geonameId": "3577718",
          "id": "EkTTjXrsiBx",
          "isoAlpha3": "VGB",
          "isoNumeric": "092",
          "languages": "en-VG",
          "name": "British Virgin Islands",
          "north": 18.757221,
          "population": "21730",
          "south": 18.38371089821131,
          "updatedAt": "2015-12-17T13:18:02.599Z",
          "west": -64.71312752730364
        },
        {
          "_id": "Ny5xi7roorx",
          "areaInSqKm": "5770.0",
          "capital": "Bandar Seri Begawan",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "BN",
          "countryName": "Brunei",
          "createdAt": "2015-12-17T13:18:02.497Z",
          "currencyCode": "BND",
          "east": 115.359444,
          "fipsCode": "BX",
          "geonameId": "1820814",
          "id": "Ny5xi7roorx",
          "isoAlpha3": "BRN",
          "isoNumeric": "096",
          "languages": "ms-BN,en-BN",
          "name": "Brunei",
          "north": 5.047167,
          "population": "395027",
          "south": 4.003083,
          "updatedAt": "2015-12-17T13:18:02.497Z",
          "west": 114.071442
        },
        {
          "_id": "VJVgsmBsiHx",
          "areaInSqKm": "110910.0",
          "capital": "Sofia",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "BG",
          "countryName": "Bulgaria",
          "createdAt": "2015-12-17T13:18:02.492Z",
          "currencyCode": "BGN",
          "east": 28.612167,
          "fipsCode": "BU",
          "geonameId": "732800",
          "id": "VJVgsmBsiHx",
          "isoAlpha3": "BGR",
          "isoNumeric": "100",
          "languages": "bg,tr-BG,rom",
          "name": "Bulgaria",
          "north": 44.21764,
          "population": "7148785",
          "south": 41.242084,
          "updatedAt": "2015-12-17T13:18:02.492Z",
          "west": 22.371166
        },
        {
          "_id": "Nk7limBjirg",
          "areaInSqKm": "274200.0",
          "capital": "Ouagadougou",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "BF",
          "countryName": "Burkina Faso",
          "createdAt": "2015-12-17T13:18:02.492Z",
          "currencyCode": "XOF",
          "east": 2.405395,
          "fipsCode": "UV",
          "geonameId": "2361809",
          "id": "Nk7limBjirg",
          "isoAlpha3": "BFA",
          "isoNumeric": "854",
          "languages": "fr-BF",
          "name": "Burkina Faso",
          "north": 15.082593,
          "population": "16241811",
          "south": 9.401108,
          "updatedAt": "2015-12-17T13:18:02.492Z",
          "west": -5.518916
        },
        {
          "_id": "VJ8xj7HjiSg",
          "areaInSqKm": "27830.0",
          "capital": "Bujumbura",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "BI",
          "countryName": "Burundi",
          "createdAt": "2015-12-17T13:18:02.493Z",
          "currencyCode": "BIF",
          "east": 30.847729,
          "fipsCode": "BY",
          "geonameId": "433561",
          "id": "VJ8xj7HjiSg",
          "isoAlpha3": "BDI",
          "isoNumeric": "108",
          "languages": "fr-BI,rn",
          "name": "Burundi",
          "north": -2.310123,
          "population": "9863117",
          "south": -4.465713,
          "updatedAt": "2015-12-17T13:18:02.493Z",
          "west": 28.993061
        },
        {
          "_id": "VJQLo7BjsHe",
          "areaInSqKm": "181040.0",
          "capital": "Phnom Penh",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "KH",
          "countryName": "Cambodia",
          "createdAt": "2015-12-17T13:18:02.54Z",
          "currencyCode": "KHR",
          "east": 107.627724,
          "fipsCode": "CB",
          "geonameId": "1831722",
          "id": "VJQLo7BjsHe",
          "isoAlpha3": "KHM",
          "isoNumeric": "116",
          "languages": "km,fr,en",
          "name": "Cambodia",
          "north": 14.686417,
          "population": "14453680",
          "south": 10.409083,
          "updatedAt": "2015-12-17T13:18:02.54Z",
          "west": 102.339996
        },
        {
          "_id": "EkpZj7rioHe",
          "areaInSqKm": "475440.0",
          "capital": "Yaoundé",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "CM",
          "countryName": "Cameroon",
          "createdAt": "2015-12-17T13:18:02.506Z",
          "currencyCode": "XAF",
          "east": 16.192116,
          "fipsCode": "CM",
          "geonameId": "2233387",
          "id": "EkpZj7rioHe",
          "isoAlpha3": "CMR",
          "isoNumeric": "120",
          "languages": "en-CM,fr-CM",
          "name": "Cameroon",
          "north": 13.078056,
          "population": "19294149",
          "south": 1.652548,
          "updatedAt": "2015-12-17T13:18:02.506Z",
          "west": 8.494763
        },
        {
          "_id": "E14WsXBjoSx",
          "areaInSqKm": "9984670.0",
          "capital": "Ottawa",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "CA",
          "countryName": "Canada",
          "createdAt": "2015-12-17T13:18:02.502Z",
          "currencyCode": "CAD",
          "east": -52.636291,
          "fipsCode": "CA",
          "geonameId": "6251999",
          "id": "E14WsXBjoSx",
          "isoAlpha3": "CAN",
          "isoNumeric": "124",
          "languages": "en-CA,fr-CA,iu",
          "name": "Canada",
          "north": 83.110626,
          "population": "33679000",
          "south": 41.67598,
          "updatedAt": "2015-12-17T13:18:02.502Z",
          "west": -141
        },
        {
          "_id": "41GfoXroiHl",
          "areaInSqKm": "4033.0",
          "capital": "Praia",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "CV",
          "countryName": "Cape Verde",
          "createdAt": "2015-12-17T13:18:02.508Z",
          "currencyCode": "CVE",
          "east": -22.669443,
          "fipsCode": "CV",
          "geonameId": "3374766",
          "id": "41GfoXroiHl",
          "isoAlpha3": "CPV",
          "isoNumeric": "132",
          "languages": "pt-CV",
          "name": "Cape Verde",
          "north": 17.197178,
          "population": "508659",
          "south": 14.808022,
          "updatedAt": "2015-12-17T13:18:02.508Z",
          "west": -25.358747
        },
        {
          "_id": "Ek58jmHijrx",
          "areaInSqKm": "262.0",
          "capital": "George Town",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "KY",
          "countryName": "Cayman Islands",
          "createdAt": "2015-12-17T13:18:02.544Z",
          "currencyCode": "KYD",
          "east": -79.727272,
          "fipsCode": "CJ",
          "geonameId": "3580718",
          "id": "Ek58jmHijrx",
          "isoAlpha3": "CYM",
          "isoNumeric": "136",
          "languages": "en-KY",
          "name": "Cayman Islands",
          "north": 19.7617,
          "population": "44270",
          "south": 19.263029,
          "updatedAt": "2015-12-17T13:18:02.544Z",
          "west": -81.432777
        },
        {
          "_id": "EyDbsmBjsBe",
          "areaInSqKm": "622984.0",
          "capital": "Bangui",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "CF",
          "countryName": "Central African Republic",
          "createdAt": "2015-12-17T13:18:02.503Z",
          "currencyCode": "XAF",
          "east": 27.463421,
          "fipsCode": "CT",
          "geonameId": "239880",
          "id": "EyDbsmBjsBe",
          "isoAlpha3": "CAF",
          "isoNumeric": "140",
          "languages": "fr-CF,sg,ln,kg",
          "name": "Central African Republic",
          "north": 11.007569,
          "population": "4844927",
          "south": 2.220514,
          "updatedAt": "2015-12-17T13:18:02.503Z",
          "west": 14.420097
        },
        {
          "_id": "N1rno7BssSl",
          "areaInSqKm": "1284000.0",
          "capital": "N'Djamena",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "TD",
          "countryName": "Chad",
          "createdAt": "2015-12-17T13:18:02.59Z",
          "currencyCode": "XAF",
          "east": 24.002661,
          "fipsCode": "CD",
          "geonameId": "2434508",
          "id": "N1rno7BssSl",
          "isoAlpha3": "TCD",
          "isoNumeric": "148",
          "languages": "fr-TD,ar-TD,sre",
          "name": "Chad",
          "north": 23.450369,
          "population": "10543464",
          "south": 7.441068,
          "updatedAt": "2015-12-17T13:18:02.59Z",
          "west": 13.473475
        },
        {
          "_id": "5d380394cc9d6488e9fc5c3d",
          "areaInSqKm": "78.0",
          "capital": "St. Peter Port",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "GGY",
          "countryName": "Channel Islands",
          "createdAt": "2015-12-17T13:18:02.534Z",
          "currencyCode": "GBP",
          "id": "5d380394cc9d6488e9fc5c3d",
          "isoAlpha3": "GGY",
          "isoNumeric": "831",
          "languages": "eng,fra",
          "name": "Channel Islands",
          "population": "62999",
          "updatedAt": "2015-12-17T13:18:02.534Z"
        },
        {
          "_id": "Ek3bsQrisHl",
          "areaInSqKm": "756950.0",
          "capital": "Santiago",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "CL",
          "countryName": "Chile",
          "createdAt": "2015-12-17T13:18:02.505Z",
          "currencyCode": "CLP",
          "east": -66.417557,
          "fipsCode": "CI",
          "geonameId": "3895114",
          "id": "Ek3bsQrisHl",
          "isoAlpha3": "CHL",
          "isoNumeric": "152",
          "languages": "es-CL",
          "name": "Chile",
          "north": -17.507553,
          "population": "16746491",
          "south": -55.9256225109217,
          "updatedAt": "2015-12-17T13:18:02.505Z",
          "west": -80.785851
        },
        {
          "_id": "E10Zj7rosSx",
          "areaInSqKm": "9596960.0",
          "capital": "Beijing",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "CN",
          "countryName": "China",
          "createdAt": "2015-12-17T13:18:02.506Z",
          "currencyCode": "CNY",
          "east": 134.773911,
          "fipsCode": "CH",
          "geonameId": "1814991",
          "id": "E10Zj7rosSx",
          "isoAlpha3": "CHN",
          "isoNumeric": "156",
          "languages": "zh-CN,yue,wuu,dta,ug,za",
          "name": "China",
          "north": 53.56086,
          "population": "1330044000",
          "south": 15.775416,
          "updatedAt": "2015-12-17T13:18:02.506Z",
          "west": 73.557693
        },
        {
          "_id": "414MsXrjiSl",
          "areaInSqKm": "135.0",
          "capital": "Flying Fish Cove",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "CX",
          "countryName": "Christmas Island",
          "createdAt": "2015-12-17T13:18:02.509Z",
          "currencyCode": "AUD",
          "east": 105.712596992,
          "fipsCode": "KT",
          "geonameId": "2078138",
          "id": "414MsXrjiSl",
          "isoAlpha3": "CXR",
          "isoNumeric": "162",
          "languages": "en,zh,ms-CC",
          "name": "Christmas Island",
          "north": -10.412356007,
          "population": "1500",
          "south": -10.5704829995,
          "updatedAt": "2015-12-17T13:18:02.509Z",
          "west": 105.533276992
        },
        {
          "_id": "EJBZimHisBg",
          "areaInSqKm": "14.0",
          "capital": "West Island",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "CC",
          "countryName": "Cocos [Keeling] Islands",
          "createdAt": "2015-12-17T13:18:02.502Z",
          "currencyCode": "AUD",
          "east": 96.929489344,
          "fipsCode": "CK",
          "geonameId": "1547376",
          "id": "EJBZimHisBg",
          "isoAlpha3": "CCK",
          "isoNumeric": "166",
          "languages": "ms-CC,en",
          "name": "Cocos [Keeling] Islands",
          "north": -12.072459094,
          "population": "628",
          "south": -12.208725839,
          "updatedAt": "2015-12-17T13:18:02.502Z",
          "west": 96.816941408
        },
        {
          "_id": "NJ1GiQBisBe",
          "areaInSqKm": "1138910.0",
          "capital": "Bogotá",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "CO",
          "countryName": "Colombia",
          "createdAt": "2015-12-17T13:18:02.506Z",
          "currencyCode": "COP",
          "east": -66.869835,
          "fipsCode": "CO",
          "geonameId": "3686110",
          "id": "NJ1GiQBisBe",
          "isoAlpha3": "COL",
          "isoNumeric": "170",
          "languages": "es-CO",
          "name": "Colombia",
          "north": 13.380502,
          "population": "47790000",
          "south": -4.225869,
          "updatedAt": "2015-12-17T13:18:02.507Z",
          "west": -81.728111
        },
        {
          "_id": "V1HIiQrsjSg",
          "areaInSqKm": "2170.0",
          "capital": "Moroni",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "KM",
          "countryName": "Comoros",
          "createdAt": "2015-12-17T13:18:02.541Z",
          "currencyCode": "KMF",
          "east": 44.538223,
          "fipsCode": "CN",
          "geonameId": "921929",
          "id": "V1HIiQrsjSg",
          "isoAlpha3": "COM",
          "isoNumeric": "174",
          "languages": "ar,fr-KM",
          "name": "Comoros",
          "north": -11.362381,
          "population": "773407",
          "south": -12.387857,
          "updatedAt": "2015-12-17T13:18:02.541Z",
          "west": 43.21579
        },
        {
          "_id": "NyjZsQSisBe",
          "areaInSqKm": "240.0",
          "capital": "Avarua",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "CK",
          "countryName": "Cook Islands",
          "createdAt": "2015-12-17T13:18:02.505Z",
          "currencyCode": "NZD",
          "east": -157.312134,
          "fipsCode": "CW",
          "geonameId": "1899402",
          "id": "NyjZsQSisBe",
          "isoAlpha3": "COK",
          "isoNumeric": "184",
          "languages": "en-CK,mi",
          "name": "Cook Islands",
          "north": -10.023114,
          "population": "21388",
          "south": -21.944164,
          "updatedAt": "2015-12-17T13:18:02.505Z",
          "west": -161.093658
        },
        {
          "_id": "EygfoQBjjSe",
          "areaInSqKm": "51100.0",
          "capital": "San José",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "CR",
          "countryName": "Costa Rica",
          "createdAt": "2015-12-17T13:18:02.507Z",
          "currencyCode": "CRC",
          "east": -82.555992,
          "fipsCode": "CS",
          "geonameId": "3624060",
          "id": "EygfoQBjjSe",
          "isoAlpha3": "CRI",
          "isoNumeric": "188",
          "languages": "es-CR,en",
          "name": "Costa Rica",
          "north": 11.216819,
          "population": "4516220",
          "south": 8.032975,
          "updatedAt": "2015-12-17T13:18:02.507Z",
          "west": -85.950623
        },
        {
          "_id": "V1lHjQBosSg",
          "areaInSqKm": "56542.0",
          "capital": "Zagreb",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "HR",
          "countryName": "Croatia",
          "createdAt": "2015-12-17T13:18:02.53Z",
          "currencyCode": "HRK",
          "east": 19.427389,
          "fipsCode": "HR",
          "geonameId": "3202326",
          "id": "V1lHjQBosSg",
          "isoAlpha3": "HRV",
          "isoNumeric": "191",
          "languages": "hr-HR,sr",
          "name": "Croatia",
          "north": 46.53875,
          "population": "4491000",
          "south": 42.43589,
          "updatedAt": "2015-12-17T13:18:02.53Z",
          "west": 13.493222
        },
        {
          "_id": "4y-MsXBjoHl",
          "areaInSqKm": "110860.0",
          "capital": "Havana",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "CU",
          "countryName": "Cuba",
          "createdAt": "2015-12-17T13:18:02.507Z",
          "currencyCode": "CUP",
          "east": -74.131775,
          "fipsCode": "CU",
          "geonameId": "3562981",
          "id": "4y-MsXBjoHl",
          "isoAlpha3": "CUB",
          "isoNumeric": "192",
          "languages": "es-CU",
          "name": "Cuba",
          "north": 23.226042,
          "population": "11423000",
          "south": 19.828083,
          "updatedAt": "2015-12-17T13:18:02.507Z",
          "west": -84.957428
        },
        {
          "_id": "4k7MomrjjSg",
          "areaInSqKm": "444.0",
          "capital": "Willemstad",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "CW",
          "countryName": "Curacao",
          "createdAt": "2015-12-17T13:18:02.508Z",
          "currencyCode": "ANG",
          "east": -68.733948,
          "fipsCode": "UC",
          "geonameId": "7626836",
          "id": "4k7MomrjjSg",
          "isoAlpha3": "CUW",
          "isoNumeric": "531",
          "languages": "nl,pap",
          "name": "Curacao",
          "north": 12.385672,
          "population": "141766",
          "south": 12.032745,
          "updatedAt": "2015-12-17T13:18:02.508Z",
          "west": -69.157204
        },
        {
          "_id": "EkSfi7BooBx",
          "areaInSqKm": "9250.0",
          "capital": "Nicosia",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "CY",
          "countryName": "Cyprus",
          "createdAt": "2015-12-17T13:18:02.509Z",
          "currencyCode": "EUR",
          "east": 34.59791599999994,
          "fipsCode": "CY",
          "geonameId": "146669",
          "id": "EkSfi7BooBx",
          "isoAlpha3": "CYP",
          "isoNumeric": "196",
          "languages": "el-CY,tr-CY,en",
          "name": "Cyprus",
          "north": 35.701527,
          "population": "1102677",
          "south": 34.6332846722908,
          "updatedAt": "2015-12-17T13:18:02.509Z",
          "west": 32.27308300000004
        },
        {
          "_id": "NyIGomHisrl",
          "areaInSqKm": "78866.0",
          "capital": "Prague",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "CZ",
          "countryName": "Czech Republic",
          "createdAt": "2015-12-17T13:18:02.51Z",
          "currencyCode": "CZK",
          "east": 18.860111,
          "fipsCode": "EZ",
          "geonameId": "3077311",
          "id": "NyIGomHisrl",
          "isoAlpha3": "CZE",
          "isoNumeric": "203",
          "languages": "cs,sk",
          "name": "Czech Republic",
          "north": 51.058887,
          "population": "10476000",
          "south": 48.542915,
          "updatedAt": "2015-12-17T13:18:02.51Z",
          "west": 12.096194
        },
        {
          "_id": "5d3802f0cc9d6488e9fc3854",
          "areaInSqKm": "322463.0",
          "capital": "Yamoussoukro",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "CIV",
          "countryName": "Côte d'Ivoire",
          "createdAt": "2015-12-17T13:18:02.534Z",
          "currencyCode": "XOF",
          "id": "5d3802f0cc9d6488e9fc3854",
          "isoAlpha3": "CIV",
          "isoNumeric": "384",
          "languages": "fr",
          "name": "Côte d'Ivoire",
          "population": "22671331",
          "updatedAt": "2015-12-17T13:18:02.534Z"
        },
        {
          "_id": "VkIWj7rjsrg",
          "areaInSqKm": "2345410.0",
          "capital": "Kinshasa",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "CD",
          "countryName": "Democratic Republic of the Congo",
          "createdAt": "2015-12-17T13:18:02.503Z",
          "currencyCode": "CDF",
          "east": 31.305912,
          "fipsCode": "CG",
          "geonameId": "203312",
          "id": "VkIWj7rjsrg",
          "isoAlpha3": "COD",
          "isoNumeric": "180",
          "languages": "fr-CD,ln,kg",
          "name": "Democratic Republic of the Congo",
          "north": 5.386098,
          "population": "70916439",
          "south": -13.455675,
          "updatedAt": "2015-12-17T13:18:02.503Z",
          "west": 12.204144
        },
        {
          "_id": "4JYfjmBjjre",
          "areaInSqKm": "43094.0",
          "capital": "Copenhagen",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "DK",
          "countryName": "Denmark",
          "createdAt": "2015-12-17T13:18:02.512Z",
          "currencyCode": "DKK",
          "east": 15.158834,
          "fipsCode": "DA",
          "geonameId": "2623032",
          "id": "4JYfjmBjjre",
          "isoAlpha3": "DNK",
          "isoNumeric": "208",
          "languages": "da-DK,en,fo,de-DK",
          "name": "Denmark",
          "north": 57.748417,
          "population": "5484000",
          "south": 54.562389,
          "updatedAt": "2015-12-17T13:18:02.512Z",
          "west": 8.075611
        },
        {
          "_id": "EkdMsmBjsSe",
          "areaInSqKm": "23000.0",
          "capital": "Djibouti",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "DJ",
          "countryName": "Djibouti",
          "createdAt": "2015-12-17T13:18:02.51Z",
          "currencyCode": "DJF",
          "east": 43.416973,
          "fipsCode": "DJ",
          "geonameId": "223816",
          "id": "EkdMsmBjsSe",
          "isoAlpha3": "DJI",
          "isoNumeric": "262",
          "languages": "fr-DJ,ar,so-DJ,aa",
          "name": "Djibouti",
          "north": 12.706833,
          "population": "740528",
          "south": 10.909917,
          "updatedAt": "2015-12-17T13:18:02.51Z",
          "west": 41.773472
        },
        {
          "_id": "Eyqfi7BoiBx",
          "areaInSqKm": "754.0",
          "capital": "Roseau",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "DM",
          "countryName": "Dominica",
          "createdAt": "2015-12-17T13:18:02.513Z",
          "currencyCode": "XCD",
          "east": -61.244152,
          "fipsCode": "DO",
          "geonameId": "3575830",
          "id": "Eyqfi7BoiBx",
          "isoAlpha3": "DMA",
          "isoNumeric": "212",
          "languages": "en-DM",
          "name": "Dominica",
          "north": 15.631809,
          "population": "72813",
          "south": 15.20169,
          "updatedAt": "2015-12-17T13:18:02.513Z",
          "west": -61.484108
        },
        {
          "_id": "VJifomBijHe",
          "areaInSqKm": "48730.0",
          "capital": "Santo Domingo",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "DO",
          "countryName": "Dominican Republic",
          "createdAt": "2015-12-17T13:18:02.513Z",
          "currencyCode": "DOP",
          "east": -68.32,
          "fipsCode": "DR",
          "geonameId": "3508796",
          "id": "VJifomBijHe",
          "isoAlpha3": "DOM",
          "isoNumeric": "214",
          "languages": "es-DO",
          "name": "Dominican Republic",
          "north": 19.929859,
          "population": "9823821",
          "south": 17.543159,
          "updatedAt": "2015-12-17T13:18:02.513Z",
          "west": -72.003487
        },
        {
          "_id": "5d665937b233ee87e1c5c397",
          "areaInSqKm": "999.0",
          "capital": "Willemstad",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "BES",
          "countryName": "Dutch Caribbean",
          "currencyCode": "XCD",
          "id": "5d665937b233ee87e1c5c397",
          "isoNumeric": "599",
          "languages": "da",
          "name": "Dutch Caribbean",
          "population": "315,339"
        },
        {
          "_id": "5d665938b233ee87e1c5c3a4",
          "areaInSqKm": "2709.0",
          "capital": "",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "OECS",
          "countryName": "East Caribbean",
          "currencyCode": "XCD",
          "id": "5d665938b233ee87e1c5c3a4",
          "isoNumeric": "809",
          "languages": "en",
          "name": "East Caribbean",
          "population": "615724"
        },
        {
          "_id": "4JsnjQrsiBx",
          "areaInSqKm": "15007.0",
          "capital": "Dili",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "TL",
          "countryName": "East Timor",
          "createdAt": "2015-12-17T13:18:02.592Z",
          "currencyCode": "USD",
          "east": 127.30859375,
          "fipsCode": "TT",
          "geonameId": "1966436",
          "id": "4JsnjQrsiBx",
          "isoAlpha3": "TLS",
          "isoNumeric": "626",
          "languages": "tet,pt-TL,id,en",
          "name": "East Timor",
          "north": -8.135833740234375,
          "population": "1154625",
          "south": -9.463626861572266,
          "updatedAt": "2015-12-17T13:18:02.592Z",
          "west": 124.0460968017578
        },
        {
          "_id": "E16MiQHooSg",
          "areaInSqKm": "283560.0",
          "capital": "Quito",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "EC",
          "countryName": "Ecuador",
          "createdAt": "2015-12-17T13:18:02.514Z",
          "currencyCode": "USD",
          "east": -75.184586,
          "fipsCode": "EC",
          "geonameId": "3658394",
          "id": "E16MiQHooSg",
          "isoAlpha3": "ECU",
          "isoNumeric": "218",
          "languages": "es-EC",
          "name": "Ecuador",
          "north": 1.43902,
          "population": "14790608",
          "south": -4.998823,
          "updatedAt": "2015-12-17T13:18:02.514Z",
          "west": -81.078598
        },
        {
          "_id": "NJy7oXSsjSg",
          "areaInSqKm": "1001450.0",
          "capital": "Cairo",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "EG",
          "countryName": "Egypt",
          "createdAt": "2015-12-17T13:18:02.515Z",
          "currencyCode": "EGP",
          "east": 36.89833068847656,
          "fipsCode": "EG",
          "geonameId": "357994",
          "id": "NJy7oXSsjSg",
          "isoAlpha3": "EGY",
          "isoNumeric": "818",
          "languages": "ar-EG,en,fr",
          "name": "Egypt",
          "north": 31.667334,
          "population": "80471869",
          "south": 21.725389,
          "updatedAt": "2015-12-17T13:18:02.515Z",
          "west": 24.698111
        },
        {
          "_id": "VylhjQrijBg",
          "areaInSqKm": "21040.0",
          "capital": "San Salvador",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "SV",
          "countryName": "El Salvador",
          "createdAt": "2015-12-17T13:18:02.588Z",
          "currencyCode": "USD",
          "east": -87.692162,
          "fipsCode": "ES",
          "geonameId": "3585968",
          "id": "VylhjQrijBg",
          "isoAlpha3": "SLV",
          "isoNumeric": "222",
          "languages": "es-SV",
          "name": "El Salvador",
          "north": 14.445067,
          "population": "6052064",
          "south": 13.148679,
          "updatedAt": "2015-12-17T13:18:02.588Z",
          "west": -90.128662
        },
        {
          "_id": "41LEs7HijHl",
          "areaInSqKm": "28051.0",
          "capital": "Malabo",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "GQ",
          "countryName": "Equatorial Guinea",
          "createdAt": "2015-12-17T13:18:02.526Z",
          "currencyCode": "XAF",
          "east": 11.335724,
          "fipsCode": "EK",
          "geonameId": "2309096",
          "id": "41LEs7HijHl",
          "isoAlpha3": "GNQ",
          "isoNumeric": "226",
          "languages": "es-GQ,fr",
          "name": "Equatorial Guinea",
          "north": 2.346989,
          "population": "1014999",
          "south": 0.92086,
          "updatedAt": "2015-12-17T13:18:02.526Z",
          "west": 9.346865
        },
        {
          "_id": "VJWmimrsoBx",
          "areaInSqKm": "121320.0",
          "capital": "Asmara",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "ER",
          "countryName": "Eritrea",
          "createdAt": "2015-12-17T13:18:02.518Z",
          "currencyCode": "ERN",
          "east": 43.13464,
          "fipsCode": "ER",
          "geonameId": "338010",
          "id": "VJWmimrsoBx",
          "isoAlpha3": "ERI",
          "isoNumeric": "232",
          "languages": "aa-ER,ar,tig,kun,ti-ER",
          "name": "Eritrea",
          "north": 18.003084,
          "population": "5792984",
          "south": 12.359555,
          "updatedAt": "2015-12-17T13:18:02.518Z",
          "west": 36.438778
        },
        {
          "_id": "EkRzs7HijHl",
          "areaInSqKm": "45226.0",
          "capital": "Tallinn",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "EE",
          "countryName": "Estonia",
          "createdAt": "2015-12-17T13:18:02.515Z",
          "currencyCode": "EUR",
          "east": 28.209972,
          "fipsCode": "EN",
          "geonameId": "453733",
          "id": "EkRzs7HijHl",
          "isoAlpha3": "EST",
          "isoNumeric": "233",
          "languages": "et,ru",
          "name": "Estonia",
          "north": 59.676224,
          "population": "1291170",
          "south": 57.516193,
          "updatedAt": "2015-12-17T13:18:02.515Z",
          "west": 21.837584
        },
        {
          "_id": "Vkm7oXrioHl",
          "areaInSqKm": "1127127.0",
          "capital": "Addis Ababa",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "ET",
          "countryName": "Ethiopia",
          "createdAt": "2015-12-17T13:18:02.518Z",
          "currencyCode": "ETB",
          "east": 47.986179,
          "fipsCode": "ET",
          "geonameId": "337996",
          "id": "Vkm7oXrioHl",
          "isoAlpha3": "ETH",
          "isoNumeric": "231",
          "languages": "am,en-ET,om-ET,ti-ET,so-ET,sid",
          "name": "Ethiopia",
          "north": 14.89375,
          "population": "88013491",
          "south": 3.402422,
          "updatedAt": "2015-12-17T13:18:02.518Z",
          "west": 32.999939
        },
        {
          "_id": "Nk87smBssSe",
          "areaInSqKm": "12173.0",
          "capital": "Stanley",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "FK",
          "countryName": "Falkland Islands",
          "createdAt": "2015-12-17T13:18:02.52Z",
          "currencyCode": "FKP",
          "east": -57.712486,
          "fipsCode": "FK",
          "geonameId": "3474414",
          "id": "Nk87smBssSe",
          "isoAlpha3": "FLK",
          "isoNumeric": "238",
          "languages": "en-FK",
          "name": "Falkland Islands",
          "north": -51.24065,
          "population": "2638",
          "south": -52.360512,
          "updatedAt": "2015-12-17T13:18:02.52Z",
          "west": -61.345192
        },
        {
          "_id": "NJOmomrjjSg",
          "areaInSqKm": "1399.0",
          "capital": "Tórshavn",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "FO",
          "countryName": "Faroe Islands",
          "createdAt": "2015-12-17T13:18:02.521Z",
          "currencyCode": "DKK",
          "east": -6.399583,
          "fipsCode": "FO",
          "geonameId": "2622320",
          "id": "NJOmomrjjSg",
          "isoAlpha3": "FRO",
          "isoNumeric": "234",
          "languages": "fo,da-FO",
          "name": "Faroe Islands",
          "north": 62.400749,
          "population": "48228",
          "south": 61.394943,
          "updatedAt": "2015-12-17T13:18:02.521Z",
          "west": -7.458
        },
        {
          "_id": "VJS7j7HjoHg",
          "areaInSqKm": "18270.0",
          "capital": "Suva",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "FJ",
          "countryName": "Fiji",
          "createdAt": "2015-12-17T13:18:02.519Z",
          "currencyCode": "FJD",
          "east": -178.424438,
          "fipsCode": "FJ",
          "geonameId": "2205218",
          "id": "VJS7j7HjoHg",
          "isoAlpha3": "FJI",
          "isoNumeric": "242",
          "languages": "en-FJ,fj",
          "name": "Fiji",
          "north": -12.480111,
          "population": "875983",
          "south": -20.67597,
          "updatedAt": "2015-12-17T13:18:02.519Z",
          "west": 177.129334
        },
        {
          "_id": "NyEXjXBjoSx",
          "areaInSqKm": "337030.0",
          "capital": "Helsinki",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "FI",
          "countryName": "Finland",
          "createdAt": "2015-12-17T13:18:02.519Z",
          "currencyCode": "EUR",
          "east": 31.580944,
          "fipsCode": "FI",
          "geonameId": "660013",
          "id": "NyEXjXBjoSx",
          "isoAlpha3": "FIN",
          "isoNumeric": "246",
          "languages": "fi-FI,sv-FI,smn",
          "name": "Finland",
          "north": 70.096054,
          "population": "5244000",
          "south": 59.808777,
          "updatedAt": "2015-12-17T13:18:02.519Z",
          "west": 20.556944
        },
        {
          "_id": "VkKQsmHjjHx",
          "areaInSqKm": "547030.0",
          "capital": "Paris",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "FR",
          "countryName": "France",
          "createdAt": "2015-12-17T13:18:02.522Z",
          "currencyCode": "EUR",
          "east": 9.561556,
          "fipsCode": "FR",
          "geonameId": "3017382",
          "id": "VkKQsmHjjHx",
          "isoAlpha3": "FRA",
          "isoNumeric": "250",
          "languages": "fr-FR,frp,br,co,ca,eu,oc",
          "name": "France",
          "north": 51.092804,
          "population": "64768389",
          "south": 41.371582,
          "updatedAt": "2015-12-17T13:18:02.522Z",
          "west": -5.142222
        },
        {
          "_id": "EJAQiQrjiBg",
          "areaInSqKm": "91000.0",
          "capital": "Cayenne",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "GF",
          "countryName": "French Guiana",
          "createdAt": "2015-12-17T13:18:02.524Z",
          "currencyCode": "EUR",
          "east": -51.613949,
          "fipsCode": "FG",
          "geonameId": "3381670",
          "id": "EJAQiQrjiBg",
          "isoAlpha3": "GUF",
          "isoNumeric": "254",
          "languages": "fr-GF",
          "name": "French Guiana",
          "north": 5.776496,
          "population": "195506",
          "south": 2.127094,
          "updatedAt": "2015-12-17T13:18:02.524Z",
          "west": -54.542511
        },
        {
          "_id": "4JUhjmBjsBg",
          "areaInSqKm": "7829.0",
          "capital": "Port-aux-Français",
          "companyID": "TECTORO",
          "continent": "AN",
          "continentName": "Antarctica",
          "countryCode": "TF",
          "countryName": "French Southern Territories",
          "createdAt": "2015-12-17T13:18:02.59Z",
          "currencyCode": "EUR",
          "east": 77.598808,
          "fipsCode": "FS",
          "geonameId": "1546748",
          "id": "4JUhjmBjsBg",
          "isoAlpha3": "ATF",
          "isoNumeric": "260",
          "languages": "fr",
          "name": "French Southern Territories",
          "north": -37.790722,
          "population": "140",
          "south": -49.735184,
          "updatedAt": "2015-12-17T13:18:02.59Z",
          "west": 50.170258
        },
        {
          "_id": "N197sQBsore",
          "areaInSqKm": "267667.0",
          "capital": "Libreville",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "GA",
          "countryName": "Gabon",
          "createdAt": "2015-12-17T13:18:02.522Z",
          "currencyCode": "XAF",
          "east": 14.502347,
          "fipsCode": "GB",
          "geonameId": "2400553",
          "id": "N197sQBsore",
          "isoAlpha3": "GAB",
          "isoNumeric": "266",
          "languages": "fr-GA",
          "name": "Gabon",
          "north": 2.322612,
          "population": "1545255",
          "south": -3.978806,
          "updatedAt": "2015-12-17T13:18:02.522Z",
          "west": 8.695471
        },
        {
          "_id": "EkXEs7Hsirl",
          "areaInSqKm": "11300.0",
          "capital": "Bathurst",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "GM",
          "countryName": "Gambia",
          "createdAt": "2015-12-17T13:18:02.525Z",
          "currencyCode": "GMD",
          "east": -13.797793,
          "fipsCode": "GA",
          "geonameId": "2413451",
          "id": "EkXEs7Hsirl",
          "isoAlpha3": "GMB",
          "isoNumeric": "270",
          "languages": "en-GM,mnk,wof,wo,ff",
          "name": "Gambia",
          "north": 13.826571,
          "population": "1593256",
          "south": 13.064252,
          "updatedAt": "2015-12-17T13:18:02.525Z",
          "west": -16.825079
        },
        {
          "_id": "VypQiQrosrl",
          "areaInSqKm": "69700.0",
          "capital": "Tbilisi",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "GE",
          "countryName": "Georgia",
          "createdAt": "2015-12-17T13:18:02.523Z",
          "currencyCode": "GEL",
          "east": 46.725971,
          "fipsCode": "GG",
          "geonameId": "614540",
          "id": "VypQiQrosrl",
          "isoAlpha3": "GEO",
          "isoNumeric": "268",
          "languages": "ka,ru,hy,az",
          "name": "Georgia",
          "north": 43.586498,
          "population": "4630000",
          "south": 41.053196,
          "updatedAt": "2015-12-17T13:18:02.523Z",
          "west": 40.010139
        },
        {
          "_id": "NJPzj7BisHe",
          "areaInSqKm": "357021.0",
          "capital": "Berlin",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "DE",
          "countryName": "Germany",
          "createdAt": "2015-12-17T13:18:02.51Z",
          "currencyCode": "EUR",
          "east": 15.039889,
          "fipsCode": "GM",
          "geonameId": "2921044",
          "id": "NJPzj7BisHe",
          "isoAlpha3": "DEU",
          "isoNumeric": "276",
          "languages": "de",
          "name": "Germany",
          "north": 55.055637,
          "population": "81802257",
          "south": 47.275776,
          "updatedAt": "2015-12-17T13:18:02.51Z",
          "west": 5.865639
        },
        {
          "_id": "V1l4jXrisre",
          "areaInSqKm": "239460.0",
          "capital": "Accra",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "GH",
          "countryName": "Ghana",
          "createdAt": "2015-12-17T13:18:02.524Z",
          "currencyCode": "GHS",
          "east": 1.191781,
          "fipsCode": "GH",
          "geonameId": "2300660",
          "id": "V1l4jXrisre",
          "isoAlpha3": "GHA",
          "isoNumeric": "288",
          "languages": "en-GH,ak,ee,tw",
          "name": "Ghana",
          "north": 11.173301,
          "population": "24339838",
          "south": 4.736723,
          "updatedAt": "2015-12-17T13:18:02.524Z",
          "west": -3.25542
        },
        {
          "_id": "4kWVi7rosHg",
          "areaInSqKm": "6.5",
          "capital": "Gibraltar",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "GI",
          "countryName": "Gibraltar",
          "createdAt": "2015-12-17T13:18:02.525Z",
          "currencyCode": "GIP",
          "east": -5.338285164001491,
          "fipsCode": "GI",
          "geonameId": "2411586",
          "id": "4kWVi7rosHg",
          "isoAlpha3": "GIB",
          "isoNumeric": "292",
          "languages": "en-GI,es,it,pt",
          "name": "Gibraltar",
          "north": 36.15543913567073,
          "population": "27884",
          "south": 36.10903070140248,
          "updatedAt": "2015-12-17T13:18:02.525Z",
          "west": -5.36626149743654
        },
        {
          "_id": "EyPVj7rioBl",
          "areaInSqKm": "131940.0",
          "capital": "Athens",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "GR",
          "countryName": "Greece",
          "createdAt": "2015-12-17T13:18:02.527Z",
          "currencyCode": "EUR",
          "east": 28.2470831714347,
          "fipsCode": "GR",
          "geonameId": "390903",
          "id": "EyPVj7rioBl",
          "isoAlpha3": "GRC",
          "isoNumeric": "300",
          "languages": "el-GR,en,fr",
          "name": "Greece",
          "north": 41.7484999849641,
          "population": "11000000",
          "south": 34.8020663391466,
          "updatedAt": "2015-12-17T13:18:02.527Z",
          "west": 19.3736035624134
        },
        {
          "_id": "EJzVsmBioBx",
          "areaInSqKm": "2166086.0",
          "capital": "Nuuk",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "GL",
          "countryName": "Greenland",
          "createdAt": "2015-12-17T13:18:02.525Z",
          "currencyCode": "DKK",
          "east": -11.312319,
          "fipsCode": "GL",
          "geonameId": "3425505",
          "id": "EJzVsmBioBx",
          "isoAlpha3": "GRL",
          "isoNumeric": "304",
          "languages": "kl,da-GL,en",
          "name": "Greenland",
          "north": 83.627357,
          "population": "56375",
          "south": 59.777401,
          "updatedAt": "2015-12-17T13:18:02.525Z",
          "west": -73.04203
        },
        {
          "_id": "4k27o7HssBl",
          "areaInSqKm": "344.0",
          "capital": "St. George's",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "GD",
          "countryName": "Grenada",
          "createdAt": "2015-12-17T13:18:02.523Z",
          "currencyCode": "XCD",
          "east": -61.57676970108031,
          "fipsCode": "GJ",
          "geonameId": "3580239",
          "id": "4k27o7HssBl",
          "isoAlpha3": "GRD",
          "isoNumeric": "308",
          "languages": "en-GD",
          "name": "Grenada",
          "north": 12.3182839281713,
          "population": "107818",
          "south": 11.986893,
          "updatedAt": "2015-12-17T13:18:02.523Z",
          "west": -61.802344
        },
        {
          "_id": "NySVj7BijSg",
          "areaInSqKm": "1780.0",
          "capital": "Basse-Terre",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "GP",
          "countryName": "Guadeloupe",
          "createdAt": "2015-12-17T13:18:02.526Z",
          "currencyCode": "EUR",
          "east": -61,
          "fipsCode": "GP",
          "geonameId": "3579143",
          "id": "NySVj7BijSg",
          "isoAlpha3": "GLP",
          "isoNumeric": "312",
          "languages": "fr-GP",
          "name": "Guadeloupe",
          "north": 16.516848,
          "population": "443000",
          "south": 15.867565,
          "updatedAt": "2015-12-17T13:18:02.526Z",
          "west": -61.544765
        },
        {
          "_id": "Ey54j7SioBx",
          "areaInSqKm": "549.0",
          "capital": "Hagåtña",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "GU",
          "countryName": "Guam",
          "createdAt": "2015-12-17T13:18:02.528Z",
          "currencyCode": "USD",
          "east": 144.956894,
          "fipsCode": "GQ",
          "geonameId": "4043988",
          "id": "Ey54j7SioBx",
          "isoAlpha3": "GUM",
          "isoNumeric": "316",
          "languages": "en-GU,ch-GU",
          "name": "Guam",
          "north": 13.654402,
          "population": "159358",
          "south": 13.23376,
          "updatedAt": "2015-12-17T13:18:02.528Z",
          "west": 144.61806
        },
        {
          "_id": "EyYVi7rjiHg",
          "areaInSqKm": "108890.0",
          "capital": "Guatemala City",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "GT",
          "countryName": "Guatemala",
          "createdAt": "2015-12-17T13:18:02.527Z",
          "currencyCode": "GTQ",
          "east": -88.223198,
          "fipsCode": "GT",
          "geonameId": "3595528",
          "id": "EyYVi7rjiHg",
          "isoAlpha3": "GTM",
          "isoNumeric": "320",
          "languages": "es-GT",
          "name": "Guatemala",
          "north": 17.81522,
          "population": "13550440",
          "south": 13.737302,
          "updatedAt": "2015-12-17T13:18:02.528Z",
          "west": -92.23629
        },
        {
          "_id": "4y14s7rojSe",
          "areaInSqKm": "78.0",
          "capital": "St Peter Port",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "GG",
          "countryName": "Guernsey",
          "createdAt": "2015-12-17T13:18:02.524Z",
          "currencyCode": "GBP",
          "east": -2.157715211224627,
          "fipsCode": "GK",
          "geonameId": "3042362",
          "id": "4y14s7rojSe",
          "isoAlpha3": "GGY",
          "isoNumeric": "831",
          "languages": "en,fr",
          "name": "Guernsey",
          "north": 49.73172781670542,
          "population": "65228",
          "south": 49.40764156876899,
          "updatedAt": "2015-12-17T13:18:02.524Z",
          "west": -2.673194593476069
        },
        {
          "_id": "4yV4o7Bijrg",
          "areaInSqKm": "245857.0",
          "capital": "Conakry",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "GN",
          "countryName": "Guinea",
          "createdAt": "2015-12-17T13:18:02.526Z",
          "currencyCode": "GNF",
          "east": -7.641071,
          "fipsCode": "GV",
          "geonameId": "2420477",
          "id": "4yV4o7Bijrg",
          "isoAlpha3": "GIN",
          "isoNumeric": "324",
          "languages": "fr-GN",
          "name": "Guinea",
          "north": 12.67622,
          "population": "10324025",
          "south": 7.193553,
          "updatedAt": "2015-12-17T13:18:02.526Z",
          "west": -14.926619
        },
        {
          "_id": "EkhNiXrssre",
          "areaInSqKm": "214970.0",
          "capital": "Georgetown",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "GY",
          "countryName": "Guyana",
          "createdAt": "2015-12-17T13:18:02.529Z",
          "currencyCode": "GYD",
          "east": -56.480251,
          "fipsCode": "GY",
          "geonameId": "3378535",
          "id": "EkhNiXrssre",
          "isoAlpha3": "GUY",
          "isoNumeric": "328",
          "languages": "en-GY",
          "name": "Guyana",
          "north": 8.557567,
          "population": "748486",
          "south": 1.17508,
          "updatedAt": "2015-12-17T13:18:02.529Z",
          "west": -61.384762
        },
        {
          "_id": "NkWriQHiiHx",
          "areaInSqKm": "27750.0",
          "capital": "Port-au-Prince",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "HT",
          "countryName": "Haiti",
          "createdAt": "2015-12-17T13:18:02.531Z",
          "currencyCode": "HTG",
          "east": -71.613358,
          "fipsCode": "HA",
          "geonameId": "3723988",
          "id": "NkWriQHiiHx",
          "isoAlpha3": "HTI",
          "isoNumeric": "332",
          "languages": "ht,fr-HT",
          "name": "Haiti",
          "north": 20.08782,
          "population": "9648924",
          "south": 18.021032,
          "updatedAt": "2015-12-17T13:18:02.531Z",
          "west": -74.478584
        },
        {
          "_id": "EkREo7roirx",
          "areaInSqKm": "412.0",
          "capital": "",
          "companyID": "TECTORO",
          "continent": "AN",
          "continentName": "Antarctica",
          "countryCode": "HM",
          "countryName": "Heard Island and McDonald Islands",
          "createdAt": "2015-12-17T13:18:02.529Z",
          "currencyCode": "AUD",
          "east": 73.859146,
          "fipsCode": "HM",
          "geonameId": "1547314",
          "id": "EkREo7roirx",
          "isoAlpha3": "HMD",
          "isoNumeric": "334",
          "languages": "",
          "name": "Heard Island and McDonald Islands",
          "north": -52.909416,
          "population": "0",
          "south": -53.192001,
          "updatedAt": "2015-12-17T13:18:02.529Z",
          "west": 72.596535
        },
        {
          "_id": "4yJromHjjBe",
          "areaInSqKm": "112090.0",
          "capital": "Tegucigalpa",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "HN",
          "countryName": "Honduras",
          "createdAt": "2015-12-17T13:18:02.53Z",
          "currencyCode": "HNL",
          "east": -83.155403,
          "fipsCode": "HO",
          "geonameId": "3608932",
          "id": "4yJromHjjBe",
          "isoAlpha3": "HND",
          "isoNumeric": "340",
          "languages": "es-HN",
          "name": "Honduras",
          "north": 16.510256,
          "population": "7989415",
          "south": 12.982411,
          "updatedAt": "2015-12-17T13:18:02.53Z",
          "west": -89.350792
        },
        {
          "_id": "V1aNiXHjiBl",
          "areaInSqKm": "1092.0",
          "capital": "Hong Kong",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "HK",
          "countryName": "Hong Kong",
          "createdAt": "2015-12-17T13:18:02.529Z",
          "currencyCode": "HKD",
          "east": 114.434753,
          "fipsCode": "HK",
          "geonameId": "1819730",
          "id": "V1aNiXHjiBl",
          "isoAlpha3": "HKG",
          "isoNumeric": "344",
          "languages": "zh-HK,yue,zh,en",
          "name": "Hong Kong",
          "north": 22.559778,
          "population": "6898686",
          "south": 22.15325,
          "updatedAt": "2015-12-17T13:18:02.529Z",
          "west": 113.837753
        },
        {
          "_id": "VJzSsmBjiHe",
          "areaInSqKm": "93030.0",
          "capital": "Budapest",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "HU",
          "countryName": "Hungary",
          "createdAt": "2015-12-17T13:18:02.531Z",
          "currencyCode": "HUF",
          "east": 22.906,
          "fipsCode": "HU",
          "geonameId": "719819",
          "id": "VJzSsmBjiHe",
          "isoAlpha3": "HUN",
          "isoNumeric": "348",
          "languages": "hu-HU",
          "name": "Hungary",
          "north": 48.585667,
          "population": "9982000",
          "south": 45.74361,
          "updatedAt": "2015-12-17T13:18:02.531Z",
          "west": 16.111889
        },
        {
          "_id": "EysBiQHsjrl",
          "areaInSqKm": "103000.0",
          "capital": "Reykjavik",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "IS",
          "countryName": "Iceland",
          "createdAt": "2015-12-17T13:18:02.537Z",
          "currencyCode": "ISK",
          "east": -13.495815,
          "fipsCode": "IC",
          "geonameId": "2629691",
          "id": "EysBiQHsjrl",
          "isoAlpha3": "ISL",
          "isoNumeric": "352",
          "languages": "is,en,de,da,sv,no",
          "name": "Iceland",
          "north": 66.53463,
          "population": "308910",
          "south": 63.393253,
          "updatedAt": "2015-12-17T13:18:02.537Z",
          "west": -24.546524
        },
        {
          "_id": "NyvroXBiiSg",
          "areaInSqKm": "3287590.0",
          "capital": "New Delhi",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "IN",
          "countryName": "India",
          "createdAt": "2015-12-17T13:18:02.534Z",
          "currencyCode": "INR",
          "east": 97.403305,
          "fipsCode": "IN",
          "geonameId": "1269750",
          "id": "NyvroXBiiSg",
          "isoAlpha3": "IND",
          "isoNumeric": "356",
          "languages": "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
          "name": "India",
          "north": 35.504223,
          "population": "1173108018",
          "south": 6.747139,
          "updatedAt": "2015-12-17T13:18:02.534Z",
          "west": 68.186691
        },
        {
          "_id": "417Si7SsjSe",
          "areaInSqKm": "1919440.0",
          "capital": "Jakarta",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "ID",
          "countryName": "Indonesia",
          "createdAt": "2015-12-17T13:18:02.532Z",
          "currencyCode": "IDR",
          "east": 141.021805,
          "fipsCode": "ID",
          "geonameId": "1643084",
          "id": "417Si7SsjSe",
          "isoAlpha3": "IDN",
          "isoNumeric": "360",
          "languages": "id,en,nl,jv",
          "name": "Indonesia",
          "north": 5.904417,
          "population": "242968342",
          "south": -10.941861,
          "updatedAt": "2015-12-17T13:18:02.532Z",
          "west": 95.009331
        },
        {
          "_id": "Ek9BomHssBx",
          "areaInSqKm": "1648000.0",
          "capital": "Tehran",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "IR",
          "countryName": "Iran",
          "createdAt": "2015-12-17T13:18:02.536Z",
          "currencyCode": "IRR",
          "east": 63.317471,
          "fipsCode": "IR",
          "geonameId": "130758",
          "id": "Ek9BomHssBx",
          "isoAlpha3": "IRN",
          "isoNumeric": "364",
          "languages": "fa-IR,ku",
          "name": "Iran",
          "north": 39.777222,
          "population": "76923300",
          "south": 25.064083,
          "updatedAt": "2015-12-17T13:18:02.536Z",
          "west": 44.047279
        },
        {
          "_id": "41tromroiBe",
          "areaInSqKm": "437072.0",
          "capital": "Baghdad",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "IQ",
          "countryName": "Iraq",
          "createdAt": "2015-12-17T13:18:02.535Z",
          "currencyCode": "IQD",
          "east": 48.575916,
          "fipsCode": "IZ",
          "geonameId": "99237",
          "id": "41tromroiBe",
          "isoAlpha3": "IRQ",
          "isoNumeric": "368",
          "languages": "ar-IQ,ku,hy",
          "name": "Iraq",
          "north": 37.378029,
          "population": "29671605",
          "south": 29.069445,
          "updatedAt": "2015-12-17T13:18:02.535Z",
          "west": 38.795887
        },
        {
          "_id": "V1EBimHjjHe",
          "areaInSqKm": "70280.0",
          "capital": "Dublin",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "IE",
          "countryName": "Ireland",
          "createdAt": "2015-12-17T13:18:02.532Z",
          "currencyCode": "EUR",
          "east": -6.002389,
          "fipsCode": "EI",
          "geonameId": "2963597",
          "id": "V1EBimHjjHe",
          "isoAlpha3": "IRL",
          "isoNumeric": "372",
          "languages": "en-IE,ga-IE",
          "name": "Ireland",
          "north": 55.387917,
          "population": "4622917",
          "south": 51.451584,
          "updatedAt": "2015-12-17T13:18:02.532Z",
          "west": -10.478556
        },
        {
          "_id": "EJUrsXSosrx",
          "areaInSqKm": "572.0",
          "capital": "Douglas",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "IM",
          "countryName": "Isle of Man",
          "createdAt": "2015-12-17T13:18:02.533Z",
          "currencyCode": "GBP",
          "east": -4.3115,
          "fipsCode": "IM",
          "geonameId": "3042225",
          "id": "EJUrsXSosrx",
          "isoAlpha3": "IMN",
          "isoNumeric": "833",
          "languages": "en,gv",
          "name": "Isle of Man",
          "north": 54.419724,
          "population": "75049",
          "south": 54.055916,
          "updatedAt": "2015-12-17T13:18:02.533Z",
          "west": -4.798722
        },
        {
          "_id": "NySriQHsiSe",
          "areaInSqKm": "20770.0",
          "capital": "",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "IL",
          "countryName": "Israel",
          "createdAt": "2015-12-17T13:18:02.533Z",
          "currencyCode": "ILS",
          "east": 35.876804,
          "fipsCode": "IS",
          "geonameId": "294640",
          "id": "NySriQHsiSe",
          "isoAlpha3": "ISR",
          "isoNumeric": "376",
          "languages": "he,ar-IL,en-IL,",
          "name": "Israel",
          "north": 33.340137,
          "population": "7353985",
          "south": 29.496639,
          "updatedAt": "2015-12-17T13:18:02.533Z",
          "west": 34.27027875441915
        },
        {
          "_id": "41hSomBojBl",
          "areaInSqKm": "301230.0",
          "capital": "Rome",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "IT",
          "countryName": "Italy",
          "createdAt": "2015-12-17T13:18:02.537Z",
          "currencyCode": "EUR",
          "east": 18.513445,
          "fipsCode": "IT",
          "geonameId": "3175395",
          "id": "41hSomBojBl",
          "isoAlpha3": "ITA",
          "isoNumeric": "380",
          "languages": "it-IT,de-IT,fr-IT,sc,ca,co,sl",
          "name": "Italy",
          "north": 47.095196,
          "population": "60340328",
          "south": 36.652779,
          "updatedAt": "2015-12-17T13:18:02.537Z",
          "west": 6.614889
        },
        {
          "_id": "NJ9boQBsiHg",
          "areaInSqKm": "322460.0",
          "capital": "Yamoussoukro",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "CI",
          "countryName": "Ivory Coast",
          "createdAt": "2015-12-17T13:18:02.505Z",
          "currencyCode": "XOF",
          "east": -2.494897,
          "fipsCode": "IV",
          "geonameId": "2287781",
          "id": "NJ9boQBsiHg",
          "isoAlpha3": "CIV",
          "isoNumeric": "384",
          "languages": "fr-CI",
          "name": "Ivory Coast",
          "north": 10.736642,
          "population": "21058798",
          "south": 4.357067,
          "updatedAt": "2015-12-17T13:18:02.505Z",
          "west": -8.599302
        },
        {
          "_id": "Ek0SjmBisSl",
          "areaInSqKm": "10991.0",
          "capital": "Kingston",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "JM",
          "countryName": "Jamaica",
          "createdAt": "2015-12-17T13:18:02.538Z",
          "currencyCode": "JMD",
          "east": -76.180321,
          "fipsCode": "JM",
          "geonameId": "3489940",
          "id": "Ek0SjmBisSl",
          "isoAlpha3": "JAM",
          "isoNumeric": "388",
          "languages": "en-JM",
          "name": "Jamaica",
          "north": 18.526976,
          "population": "2847232",
          "south": 17.703554,
          "updatedAt": "2015-12-17T13:18:02.538Z",
          "west": -78.366638
        },
        {
          "_id": "4JgLsQBooBg",
          "areaInSqKm": "377835.0",
          "capital": "Tokyo",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "JP",
          "countryName": "Japan",
          "createdAt": "2015-12-17T13:18:02.539Z",
          "currencyCode": "JPY",
          "east": 145.820892,
          "fipsCode": "JA",
          "geonameId": "1861060",
          "id": "4JgLsQBooBg",
          "isoAlpha3": "JPN",
          "isoNumeric": "392",
          "languages": "ja",
          "name": "Japan",
          "north": 45.52314,
          "population": "127288000",
          "south": 24.249472,
          "updatedAt": "2015-12-17T13:18:02.539Z",
          "west": 122.93853
        },
        {
          "_id": "Ey6SjmSsjHe",
          "areaInSqKm": "116.0",
          "capital": "Saint Helier",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "JE",
          "countryName": "Jersey",
          "createdAt": "2015-12-17T13:18:02.538Z",
          "currencyCode": "GBP",
          "east": -2.022083,
          "fipsCode": "JE",
          "geonameId": "3042142",
          "id": "Ey6SjmSsjHe",
          "isoAlpha3": "JEY",
          "isoNumeric": "832",
          "languages": "en,pt",
          "name": "Jersey",
          "north": 49.265057,
          "population": "90812",
          "south": 49.169834,
          "updatedAt": "2015-12-17T13:18:02.538Z",
          "west": -2.260028
        },
        {
          "_id": "NJ18sXSsjHg",
          "areaInSqKm": "92300.0",
          "capital": "Amman",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "JO",
          "countryName": "Jordan",
          "createdAt": "2015-12-17T13:18:02.538Z",
          "currencyCode": "JOD",
          "east": 39.301167,
          "fipsCode": "JO",
          "geonameId": "248816",
          "id": "NJ18sXSsjHg",
          "isoAlpha3": "JOR",
          "isoNumeric": "400",
          "languages": "ar-JO,en",
          "name": "Jordan",
          "north": 33.367668,
          "population": "6407085",
          "south": 29.185888,
          "updatedAt": "2015-12-17T13:18:02.538Z",
          "west": 34.959999
        },
        {
          "_id": "4yi8iXBjjBg",
          "areaInSqKm": "2717300.0",
          "capital": "Astana",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "KZ",
          "countryName": "Kazakhstan",
          "createdAt": "2015-12-17T13:18:02.544Z",
          "currencyCode": "KZT",
          "east": 87.312668,
          "fipsCode": "KZ",
          "geonameId": "1522867",
          "id": "4yi8iXBjjBg",
          "isoAlpha3": "KAZ",
          "isoNumeric": "398",
          "languages": "kk,ru",
          "name": "Kazakhstan",
          "north": 55.451195,
          "population": "15340000",
          "south": 40.936333,
          "updatedAt": "2015-12-17T13:18:02.544Z",
          "west": 46.491859
        },
        {
          "_id": "VJZIi7roorx",
          "areaInSqKm": "582650.0",
          "capital": "Nairobi",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "KE",
          "countryName": "Kenya",
          "createdAt": "2015-12-17T13:18:02.539Z",
          "currencyCode": "KES",
          "east": 41.899078,
          "fipsCode": "KE",
          "geonameId": "192950",
          "id": "VJZIi7roorx",
          "isoAlpha3": "KEN",
          "isoNumeric": "404",
          "languages": "en-KE,sw-KE",
          "name": "Kenya",
          "north": 5.019938,
          "population": "40046566",
          "south": -4.678047,
          "updatedAt": "2015-12-17T13:18:02.539Z",
          "west": 33.908859
        },
        {
          "_id": "EyNLs7HjoBl",
          "areaInSqKm": "811.0",
          "capital": "Tarawa",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "KI",
          "countryName": "Kiribati",
          "createdAt": "2015-12-17T13:18:02.541Z",
          "currencyCode": "AUD",
          "east": -150.215347,
          "fipsCode": "KR",
          "geonameId": "4030945",
          "id": "EyNLs7HjoBl",
          "isoAlpha3": "KIR",
          "isoNumeric": "296",
          "languages": "en-KI,gil",
          "name": "Kiribati",
          "north": 4.71957,
          "population": "92533",
          "south": -11.44688115018686,
          "updatedAt": "2015-12-17T13:18:02.541Z",
          "west": 169.556137
        },
        {
          "_id": "VJm0smBoiHx",
          "areaInSqKm": "10908.0",
          "capital": "Pristina",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "XK",
          "countryName": "Kosovo",
          "createdAt": "2015-12-17T13:18:02.601Z",
          "currencyCode": "EUR",
          "east": 21.80335088694943,
          "fipsCode": "KV",
          "geonameId": "831053",
          "id": "VJm0smBoiHx",
          "isoAlpha3": "XKX",
          "isoNumeric": "0",
          "languages": "sq,sr",
          "name": "Kosovo",
          "north": 43.2682495807952,
          "population": "1800000",
          "south": 41.85636960185992,
          "updatedAt": "2015-12-17T13:18:02.601Z",
          "west": 19.97748150449291
        },
        {
          "_id": "V1KLjmSiirg",
          "areaInSqKm": "17820.0",
          "capital": "Kuwait City",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "KW",
          "countryName": "Kuwait",
          "createdAt": "2015-12-17T13:18:02.543Z",
          "currencyCode": "KWD",
          "east": 48.431473,
          "fipsCode": "KU",
          "geonameId": "285570",
          "id": "V1KLjmSiirg",
          "isoAlpha3": "KWT",
          "isoNumeric": "414",
          "languages": "ar-KW,en",
          "name": "Kuwait",
          "north": 30.095945,
          "population": "2789132",
          "south": 28.524611,
          "updatedAt": "2015-12-17T13:18:02.543Z",
          "west": 46.555557
        },
        {
          "_id": "NyG8jXrojBe",
          "areaInSqKm": "198500.0",
          "capital": "Bishkek",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "KG",
          "countryName": "Kyrgyzstan",
          "createdAt": "2015-12-17T13:18:02.54Z",
          "currencyCode": "KGS",
          "east": 80.283165,
          "fipsCode": "KG",
          "geonameId": "1527747",
          "id": "NyG8jXrojBe",
          "isoAlpha3": "KGZ",
          "isoNumeric": "417",
          "languages": "ky,uz,ru",
          "name": "Kyrgyzstan",
          "north": 43.238224,
          "population": "5776500",
          "south": 39.172832,
          "updatedAt": "2015-12-17T13:18:02.54Z",
          "west": 69.276611
        },
        {
          "_id": "4k2Li7rjjBg",
          "areaInSqKm": "236800.0",
          "capital": "Vientiane",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "LA",
          "countryName": "Laos",
          "createdAt": "2015-12-17T13:18:02.544Z",
          "currencyCode": "LAK",
          "east": 107.697029,
          "fipsCode": "LA",
          "geonameId": "1655842",
          "id": "4k2Li7rjjBg",
          "isoAlpha3": "LAO",
          "isoNumeric": "418",
          "languages": "lo,fr,en",
          "name": "Laos",
          "north": 22.500389,
          "population": "6368162",
          "south": 13.910027,
          "updatedAt": "2015-12-17T13:18:02.544Z",
          "west": 100.093056
        },
        {
          "_id": "NkrwsmHjoSg",
          "areaInSqKm": "64589.0",
          "capital": "Riga",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "LV",
          "countryName": "Latvia",
          "createdAt": "2015-12-17T13:18:02.551Z",
          "currencyCode": "EUR",
          "east": 28.241167,
          "fipsCode": "LG",
          "geonameId": "458258",
          "id": "NkrwsmHjoSg",
          "isoAlpha3": "LVA",
          "isoNumeric": "428",
          "languages": "lv,ru,lt",
          "name": "Latvia",
          "north": 58.082306,
          "population": "2217969",
          "south": 55.668861,
          "updatedAt": "2015-12-17T13:18:02.551Z",
          "west": 20.974277
        },
        {
          "_id": "Ny6LoQHooSe",
          "areaInSqKm": "10400.0",
          "capital": "Beirut",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "LB",
          "countryName": "Lebanon",
          "createdAt": "2015-12-17T13:18:02.545Z",
          "currencyCode": "LBP",
          "east": 36.639194,
          "fipsCode": "LE",
          "geonameId": "272103",
          "id": "Ny6LoQHooSe",
          "isoAlpha3": "LBN",
          "isoNumeric": "422",
          "languages": "ar-LB,fr-LB,en,hy",
          "name": "Lebanon",
          "north": 34.691418,
          "population": "4125247",
          "south": 33.05386,
          "updatedAt": "2015-12-17T13:18:02.545Z",
          "west": 35.114277
        },
        {
          "_id": "4yfvsmrssrl",
          "areaInSqKm": "30355.0",
          "capital": "Maseru",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "LS",
          "countryName": "Lesotho",
          "createdAt": "2015-12-17T13:18:02.548Z",
          "currencyCode": "LSL",
          "east": 29.465761,
          "fipsCode": "LT",
          "geonameId": "932692",
          "id": "4yfvsmrssrl",
          "isoAlpha3": "LSO",
          "isoNumeric": "426",
          "languages": "en-LS,st,zu,xh",
          "name": "Lesotho",
          "north": -28.572058,
          "population": "1919552",
          "south": -30.668964,
          "updatedAt": "2015-12-17T13:18:02.548Z",
          "west": 27.029068
        },
        {
          "_id": "4y-DoXHioBl",
          "areaInSqKm": "111370.0",
          "capital": "Monrovia",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "LR",
          "countryName": "Liberia",
          "createdAt": "2015-12-17T13:18:02.546Z",
          "currencyCode": "LRD",
          "east": -7.365113,
          "fipsCode": "LI",
          "geonameId": "2275384",
          "id": "4y-DoXHioBl",
          "isoAlpha3": "LBR",
          "isoNumeric": "430",
          "languages": "en-LR",
          "name": "Liberia",
          "north": 8.551791,
          "population": "3685076",
          "south": 4.353057,
          "updatedAt": "2015-12-17T13:18:02.546Z",
          "west": -11.492083
        },
        {
          "_id": "41IDjmSijre",
          "areaInSqKm": "1759540.0",
          "capital": "Tripoli",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "LY",
          "countryName": "Libya",
          "createdAt": "2015-12-17T13:18:02.552Z",
          "currencyCode": "LYD",
          "east": 25.150612,
          "fipsCode": "LY",
          "geonameId": "2215636",
          "id": "41IDjmSijre",
          "isoAlpha3": "LBY",
          "isoNumeric": "434",
          "languages": "ar-LY,it,en",
          "name": "Libya",
          "north": 33.168999,
          "population": "6461454",
          "south": 19.508045,
          "updatedAt": "2015-12-17T13:18:02.552Z",
          "west": 9.38702
        },
        {
          "_id": "4J1Do7rjsBg",
          "areaInSqKm": "160.0",
          "capital": "Vaduz",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "LI",
          "countryName": "Liechtenstein",
          "createdAt": "2015-12-17T13:18:02.546Z",
          "currencyCode": "CHF",
          "east": 9.63564281136796,
          "fipsCode": "LS",
          "geonameId": "3042058",
          "id": "4J1Do7rjsBg",
          "isoAlpha3": "LIE",
          "isoNumeric": "438",
          "languages": "de-LI",
          "name": "Liechtenstein",
          "north": 47.2706251386959,
          "population": "35000",
          "south": 47.0484284123471,
          "updatedAt": "2015-12-17T13:18:02.546Z",
          "west": 9.47167359782014
        },
        {
          "_id": "N1QDj7Hoorx",
          "areaInSqKm": "65200.0",
          "capital": "Vilnius",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "LT",
          "countryName": "Lithuania",
          "createdAt": "2015-12-17T13:18:02.55Z",
          "currencyCode": "EUR",
          "east": 26.871944,
          "fipsCode": "LH",
          "geonameId": "597427",
          "id": "N1QDj7Hoorx",
          "isoAlpha3": "LTU",
          "isoNumeric": "440",
          "languages": "lt,ru,pl",
          "name": "Lithuania",
          "north": 56.446918,
          "population": "2944459",
          "south": 53.901306,
          "updatedAt": "2015-12-17T13:18:02.55Z",
          "west": 20.941528
        },
        {
          "_id": "NyVwiQHoiHg",
          "areaInSqKm": "2586.0",
          "capital": "Luxembourg",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "LU",
          "countryName": "Luxembourg",
          "createdAt": "2015-12-17T13:18:02.551Z",
          "currencyCode": "EUR",
          "east": 6.528472,
          "fipsCode": "LU",
          "geonameId": "2960313",
          "id": "NyVwiQHoiHg",
          "isoAlpha3": "LUX",
          "isoNumeric": "442",
          "languages": "lb,de-LU,fr-LU",
          "name": "Luxembourg",
          "north": 50.184944,
          "population": "497538",
          "south": 49.446583,
          "updatedAt": "2015-12-17T13:18:02.551Z",
          "west": 5.734556
        },
        {
          "_id": "E1MdjXrjiHe",
          "areaInSqKm": "254.0",
          "capital": "Macao",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "MO",
          "countryName": "Macao",
          "createdAt": "2015-12-17T13:18:02.558Z",
          "currencyCode": "MOP",
          "east": 113.565834,
          "fipsCode": "MC",
          "geonameId": "1821275",
          "id": "E1MdjXrjiHe",
          "isoAlpha3": "MAC",
          "isoNumeric": "446",
          "languages": "zh,zh-MO,pt",
          "name": "Macao",
          "north": 22.222334,
          "population": "449198",
          "south": 22.180389,
          "updatedAt": "2015-12-17T13:18:02.558Z",
          "west": 113.528946
        },
        {
          "_id": "NyAvj7BisHl",
          "areaInSqKm": "25333.0",
          "capital": "Skopje",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "MK",
          "countryName": "Macedonia",
          "createdAt": "2015-12-17T13:18:02.557Z",
          "currencyCode": "MKD",
          "east": 23.038139,
          "fipsCode": "MK",
          "geonameId": "718075",
          "id": "NyAvj7BisHl",
          "isoAlpha3": "MKD",
          "isoNumeric": "807",
          "languages": "mk,sq,tr,rmm,sr",
          "name": "Macedonia",
          "north": 42.361805,
          "population": "2062294",
          "south": 40.860195,
          "updatedAt": "2015-12-17T13:18:02.557Z",
          "west": 20.464695
        },
        {
          "_id": "E1hwjmHsoBe",
          "areaInSqKm": "587040.0",
          "capital": "Antananarivo",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "MG",
          "countryName": "Madagascar",
          "createdAt": "2015-12-17T13:18:02.556Z",
          "currencyCode": "MGA",
          "east": 50.48378,
          "fipsCode": "MA",
          "geonameId": "1062947",
          "id": "E1hwjmHsoBe",
          "isoAlpha3": "MDG",
          "isoNumeric": "450",
          "languages": "fr-MG,mg",
          "name": "Madagascar",
          "north": -11.945433,
          "population": "21281844",
          "south": -25.608952,
          "updatedAt": "2015-12-17T13:18:02.556Z",
          "west": 43.224876
        },
        {
          "_id": "Ek9_oQHisre",
          "areaInSqKm": "118480.0",
          "capital": "Lilongwe",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "MW",
          "countryName": "Malawi",
          "createdAt": "2015-12-17T13:18:02.561Z",
          "currencyCode": "MWK",
          "east": 35.916821,
          "fipsCode": "MI",
          "geonameId": "927384",
          "id": "Ek9_oQHisre",
          "isoAlpha3": "MWI",
          "isoNumeric": "454",
          "languages": "ny,yao,tum,swk",
          "name": "Malawi",
          "north": -9.367541,
          "population": "15447500",
          "south": -17.125,
          "updatedAt": "2015-12-17T13:18:02.561Z",
          "west": 32.67395
        },
        {
          "_id": "4J3ujXrisrg",
          "areaInSqKm": "329750.0",
          "capital": "Kuala Lumpur",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "MY",
          "countryName": "Malaysia",
          "createdAt": "2015-12-17T13:18:02.561Z",
          "currencyCode": "MYR",
          "east": 119.267502,
          "fipsCode": "MY",
          "geonameId": "1733045",
          "id": "4J3ujXrisrg",
          "isoAlpha3": "MYS",
          "isoNumeric": "458",
          "languages": "ms-MY,en,zh,ta,te,ml,pa,th",
          "name": "Malaysia",
          "north": 7.363417,
          "population": "28274729",
          "south": 0.855222,
          "updatedAt": "2015-12-17T13:18:02.561Z",
          "west": 99.643448
        },
        {
          "_id": "VJK_jXrooBg",
          "areaInSqKm": "300.0",
          "capital": "Malé",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "MV",
          "countryName": "Maldives",
          "createdAt": "2015-12-17T13:18:02.56Z",
          "currencyCode": "MVR",
          "east": 73.637276,
          "fipsCode": "MV",
          "geonameId": "1282028",
          "id": "VJK_jXrooBg",
          "isoAlpha3": "MDV",
          "isoNumeric": "462",
          "languages": "dv,en",
          "name": "Maldives",
          "north": 7.091587495414767,
          "population": "395650",
          "south": -0.692694,
          "updatedAt": "2015-12-17T13:18:02.56Z",
          "west": 72.693222
        },
        {
          "_id": "Ey1OjmSioHg",
          "areaInSqKm": "1240000.0",
          "capital": "Bamako",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "ML",
          "countryName": "Mali",
          "createdAt": "2015-12-17T13:18:02.557Z",
          "currencyCode": "XOF",
          "east": 4.244968,
          "fipsCode": "ML",
          "geonameId": "2453866",
          "id": "Ey1OjmSioHg",
          "isoAlpha3": "MLI",
          "isoNumeric": "466",
          "languages": "fr-ML,bm",
          "name": "Mali",
          "north": 25.000002,
          "population": "13796354",
          "south": 10.159513,
          "updatedAt": "2015-12-17T13:18:02.557Z",
          "west": -12.242614
        },
        {
          "_id": "4yvuimBjiSx",
          "areaInSqKm": "316.0",
          "capital": "Valletta",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "MT",
          "countryName": "Malta",
          "createdAt": "2015-12-17T13:18:02.56Z",
          "currencyCode": "EUR",
          "east": 14.5764915000002,
          "fipsCode": "MT",
          "geonameId": "2562770",
          "id": "4yvuimBjiSx",
          "isoAlpha3": "MLT",
          "isoNumeric": "470",
          "languages": "mt,en-MT",
          "name": "Malta",
          "north": 36.0821530995456,
          "population": "403000",
          "south": 35.8061835000002,
          "updatedAt": "2015-12-17T13:18:02.56Z",
          "west": 14.1834251000001
        },
        {
          "_id": "EkTvs7Hosrx",
          "areaInSqKm": "181.3",
          "capital": "Majuro",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "MH",
          "countryName": "Marshall Islands",
          "createdAt": "2015-12-17T13:18:02.556Z",
          "currencyCode": "USD",
          "east": 171.931808,
          "fipsCode": "RM",
          "geonameId": "2080185",
          "id": "EkTvs7Hosrx",
          "isoAlpha3": "MHL",
          "isoNumeric": "584",
          "languages": "mh,en-MH",
          "name": "Marshall Islands",
          "north": 14.62,
          "population": "65859",
          "south": 5.587639,
          "updatedAt": "2015-12-17T13:18:02.556Z",
          "west": 165.524918
        },
        {
          "_id": "EJEuoXHjoHg",
          "areaInSqKm": "1100.0",
          "capital": "Fort-de-France",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "MQ",
          "countryName": "Martinique",
          "createdAt": "2015-12-17T13:18:02.559Z",
          "currencyCode": "EUR",
          "east": -60.81551,
          "fipsCode": "MB",
          "geonameId": "3570311",
          "id": "EJEuoXHjoHg",
          "isoAlpha3": "MTQ",
          "isoNumeric": "474",
          "languages": "fr-MQ",
          "name": "Martinique",
          "north": 14.878819,
          "population": "432900",
          "south": 14.392262,
          "updatedAt": "2015-12-17T13:18:02.559Z",
          "west": -61.230118
        },
        {
          "_id": "4yB_iQBjjrx",
          "areaInSqKm": "1030700.0",
          "capital": "Nouakchott",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "MR",
          "countryName": "Mauritania",
          "createdAt": "2015-12-17T13:18:02.559Z",
          "currencyCode": "MRO",
          "east": -4.827674,
          "fipsCode": "MR",
          "geonameId": "2378080",
          "id": "4yB_iQBjjrx",
          "isoAlpha3": "MRT",
          "isoNumeric": "478",
          "languages": "ar-MR,fuc,snk,fr,mey,wo",
          "name": "Mauritania",
          "north": 27.298073,
          "population": "3205060",
          "south": 14.715547,
          "updatedAt": "2015-12-17T13:18:02.559Z",
          "west": -17.066521
        },
        {
          "_id": "N1_domBioHg",
          "areaInSqKm": "2040.0",
          "capital": "Port Louis",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "MU",
          "countryName": "Mauritius",
          "createdAt": "2015-12-17T13:18:02.56Z",
          "currencyCode": "MUR",
          "east": 63.500179,
          "fipsCode": "MP",
          "geonameId": "934292",
          "id": "N1_domBioHg",
          "isoAlpha3": "MUS",
          "isoNumeric": "480",
          "languages": "en-MU,bho,fr",
          "name": "Mauritius",
          "north": -10.319255,
          "population": "1294104",
          "south": -20.525717,
          "updatedAt": "2015-12-17T13:18:02.56Z",
          "west": 56.512718
        },
        {
          "_id": "NJBAs7Hiirx",
          "areaInSqKm": "374.0",
          "capital": "Mamoudzou",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "YT",
          "countryName": "Mayotte",
          "createdAt": "2015-12-17T13:18:02.602Z",
          "currencyCode": "EUR",
          "east": 45.29295,
          "fipsCode": "MF",
          "geonameId": "1024031",
          "id": "NJBAs7Hiirx",
          "isoAlpha3": "MYT",
          "isoNumeric": "175",
          "languages": "fr-YT",
          "name": "Mayotte",
          "north": -12.648891,
          "population": "159042",
          "south": -13.000132,
          "updatedAt": "2015-12-17T13:18:02.602Z",
          "west": 45.03796
        },
        {
          "_id": "4JiOj7HsiBx",
          "areaInSqKm": "1972550.0",
          "capital": "Mexico City",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "MX",
          "countryName": "Mexico",
          "createdAt": "2015-12-17T13:18:02.561Z",
          "currencyCode": "MXN",
          "east": -86.703392,
          "fipsCode": "MX",
          "geonameId": "3996063",
          "id": "4JiOj7HsiBx",
          "isoAlpha3": "MEX",
          "isoNumeric": "484",
          "languages": "es-MX",
          "name": "Mexico",
          "north": 32.716759,
          "population": "112468855",
          "south": 14.532866,
          "updatedAt": "2015-12-17T13:18:02.561Z",
          "west": -118.453949
        },
        {
          "_id": "NJvXo7riiHx",
          "areaInSqKm": "702.0",
          "capital": "Palikir",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "FM",
          "countryName": "Micronesia",
          "createdAt": "2015-12-17T13:18:02.52Z",
          "currencyCode": "USD",
          "east": 163.03717,
          "fipsCode": "FM",
          "geonameId": "2081918",
          "id": "NJvXo7riiHx",
          "isoAlpha3": "FSM",
          "isoNumeric": "583",
          "languages": "en-FM,chk,pon,yap,kos,uli,woe,nkr,kpg",
          "name": "Micronesia",
          "north": 10.08904,
          "population": "107708",
          "south": 1.02629,
          "updatedAt": "2015-12-17T13:18:02.52Z",
          "west": 137.33648
        },
        {
          "_id": "EyYPoQrsore",
          "areaInSqKm": "33843.0",
          "capital": "Chişinău",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "MD",
          "countryName": "Moldova",
          "createdAt": "2015-12-17T13:18:02.554Z",
          "currencyCode": "MDL",
          "east": 30.135445,
          "fipsCode": "MD",
          "geonameId": "617790",
          "id": "EyYPoQrsore",
          "isoAlpha3": "MDA",
          "isoNumeric": "498",
          "languages": "ro,ru,gag,tr",
          "name": "Moldova",
          "north": 48.490166,
          "population": "4324000",
          "south": 45.468887,
          "updatedAt": "2015-12-17T13:18:02.554Z",
          "west": 26.618944
        },
        {
          "_id": "VyuDjXrsoHg",
          "areaInSqKm": "1.95",
          "capital": "Monaco",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "MC",
          "countryName": "Monaco",
          "createdAt": "2015-12-17T13:18:02.554Z",
          "currencyCode": "EUR",
          "east": 7.439939260482788,
          "fipsCode": "MN",
          "geonameId": "2993457",
          "id": "VyuDjXrsoHg",
          "isoAlpha3": "MCO",
          "isoNumeric": "492",
          "languages": "fr-MC,en,it",
          "name": "Monaco",
          "north": 43.75196717037228,
          "population": "32965",
          "south": 43.72472839869377,
          "updatedAt": "2015-12-17T13:18:02.554Z",
          "west": 7.408962249755859
        },
        {
          "_id": "41bdj7SssHl",
          "areaInSqKm": "1565000.0",
          "capital": "Ulan Bator",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "MN",
          "countryName": "Mongolia",
          "createdAt": "2015-12-17T13:18:02.558Z",
          "currencyCode": "MNT",
          "east": 119.924309,
          "fipsCode": "MG",
          "geonameId": "2029969",
          "id": "41bdj7SssHl",
          "isoAlpha3": "MNG",
          "isoNumeric": "496",
          "languages": "mn,ru",
          "name": "Mongolia",
          "north": 52.154251,
          "population": "3086918",
          "south": 41.567638,
          "updatedAt": "2015-12-17T13:18:02.558Z",
          "west": 87.749664
        },
        {
          "_id": "EkqPiXHosrl",
          "areaInSqKm": "14026.0",
          "capital": "Podgorica",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "ME",
          "countryName": "Montenegro",
          "createdAt": "2015-12-17T13:18:02.554Z",
          "currencyCode": "EUR",
          "east": 20.358833,
          "fipsCode": "MJ",
          "geonameId": "3194884",
          "id": "EkqPiXHosrl",
          "isoAlpha3": "MNE",
          "isoNumeric": "499",
          "languages": "sr,hu,bs,sq,hr,rom",
          "name": "Montenegro",
          "north": 43.570137,
          "population": "666730",
          "south": 41.850166,
          "updatedAt": "2015-12-17T13:18:02.554Z",
          "west": 18.461306
        },
        {
          "_id": "4k8dsmSisBe",
          "areaInSqKm": "102.0",
          "capital": "Plymouth",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "MS",
          "countryName": "Montserrat",
          "createdAt": "2015-12-17T13:18:02.559Z",
          "currencyCode": "XCD",
          "east": -62.1441001296082,
          "fipsCode": "MH",
          "geonameId": "3578097",
          "id": "4k8dsmSisBe",
          "isoAlpha3": "MSR",
          "isoNumeric": "500",
          "languages": "en-MS",
          "name": "Montserrat",
          "north": 16.82406020531318,
          "population": "9341",
          "south": 16.67476893544156,
          "updatedAt": "2015-12-17T13:18:02.559Z",
          "west": -62.24138237036129
        },
        {
          "_id": "V1wPi7SoiBe",
          "areaInSqKm": "446550.0",
          "capital": "Rabat",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "MA",
          "countryName": "Morocco",
          "createdAt": "2015-12-17T13:18:02.553Z",
          "currencyCode": "MAD",
          "east": -0.991750000000025,
          "fipsCode": "MO",
          "geonameId": "2542007",
          "id": "V1wPi7SoiBe",
          "isoAlpha3": "MAR",
          "isoNumeric": "504",
          "languages": "ar-MA,fr",
          "name": "Morocco",
          "north": 35.9224966985384,
          "population": "31627428",
          "south": 27.662115,
          "updatedAt": "2015-12-17T13:18:02.553Z",
          "west": -13.168586
        },
        {
          "_id": "41p_oXHsjBe",
          "areaInSqKm": "801590.0",
          "capital": "Maputo",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "MZ",
          "countryName": "Mozambique",
          "createdAt": "2015-12-17T13:18:02.562Z",
          "currencyCode": "MZN",
          "east": 40.842995,
          "fipsCode": "MZ",
          "geonameId": "1036973",
          "id": "41p_oXHsjBe",
          "isoAlpha3": "MOZ",
          "isoNumeric": "508",
          "languages": "pt-MZ,vmw",
          "name": "Mozambique",
          "north": -10.471883,
          "population": "22061451",
          "south": -26.868685,
          "updatedAt": "2015-12-17T13:18:02.562Z",
          "west": 30.217319
        },
        {
          "_id": "4yxuj7SjoSg",
          "areaInSqKm": "678500.0",
          "capital": "Naypyitaw",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "MM",
          "countryName": "Myanmar [Burma]",
          "createdAt": "2015-12-17T13:18:02.557Z",
          "currencyCode": "MMK",
          "east": 101.176781,
          "fipsCode": "BM",
          "geonameId": "1327865",
          "id": "4yxuj7SjoSg",
          "isoAlpha3": "MMR",
          "isoNumeric": "104",
          "languages": "my",
          "name": "Myanmar [Burma]",
          "north": 28.543249,
          "population": "53414374",
          "south": 9.784583,
          "updatedAt": "2015-12-17T13:18:02.557Z",
          "west": 92.189278
        },
        {
          "_id": "Nk0di7SooSg",
          "areaInSqKm": "825418.0",
          "capital": "Windhoek",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "NA",
          "countryName": "Namibia",
          "createdAt": "2015-12-17T13:18:02.562Z",
          "currencyCode": "NAD",
          "east": 25.256701,
          "fipsCode": "WA",
          "geonameId": "3355338",
          "id": "Nk0di7SooSg",
          "isoAlpha3": "NAM",
          "isoNumeric": "516",
          "languages": "en-NA,af,de,hz,naq",
          "name": "Namibia",
          "north": -16.959894,
          "population": "2128471",
          "south": -28.97143,
          "updatedAt": "2015-12-17T13:18:02.562Z",
          "west": 11.71563
        },
        {
          "_id": "EywtoQBosBg",
          "areaInSqKm": "21.0",
          "capital": "Yaren",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "NR",
          "countryName": "Nauru",
          "createdAt": "2015-12-17T13:18:02.566Z",
          "currencyCode": "AUD",
          "east": 166.945282,
          "fipsCode": "NR",
          "geonameId": "2110425",
          "id": "EywtoQBosBg",
          "isoAlpha3": "NRU",
          "isoNumeric": "520",
          "languages": "na,en-NR",
          "name": "Nauru",
          "north": -0.504306,
          "population": "10065",
          "south": -0.552333,
          "updatedAt": "2015-12-17T13:18:02.566Z",
          "west": 166.899033
        },
        {
          "_id": "NJ8tiQrjjBl",
          "areaInSqKm": "140800.0",
          "capital": "Kathmandu",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "NP",
          "countryName": "Nepal",
          "createdAt": "2015-12-17T13:18:02.565Z",
          "currencyCode": "NPR",
          "east": 88.199333,
          "fipsCode": "NP",
          "geonameId": "1282988",
          "id": "NJ8tiQrjjBl",
          "isoAlpha3": "NPL",
          "isoNumeric": "524",
          "languages": "ne,en",
          "name": "Nepal",
          "north": 30.43339,
          "population": "28951852",
          "south": 26.356722,
          "updatedAt": "2015-12-17T13:18:02.565Z",
          "west": 80.056274
        },
        {
          "_id": "E1VFi7SoiSx",
          "areaInSqKm": "41526.0",
          "capital": "Amsterdam",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "NL",
          "countryName": "Netherlands",
          "createdAt": "2015-12-17T13:18:02.565Z",
          "currencyCode": "EUR",
          "east": 7.227944,
          "fipsCode": "NL",
          "geonameId": "2750405",
          "id": "E1VFi7SoiSx",
          "isoAlpha3": "NLD",
          "isoNumeric": "528",
          "languages": "nl-NL,fy-NL",
          "name": "Netherlands",
          "north": 53.512196,
          "population": "16645000",
          "south": 50.753918,
          "updatedAt": "2015-12-17T13:18:02.565Z",
          "west": 3.362556
        },
        {
          "_id": "EyyKiXSoiSe",
          "areaInSqKm": "19060.0",
          "capital": "Noumea",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "NC",
          "countryName": "New Caledonia",
          "createdAt": "2015-12-17T13:18:02.562Z",
          "currencyCode": "XPF",
          "east": 168.129135,
          "fipsCode": "NC",
          "geonameId": "2139685",
          "id": "EyyKiXSoiSe",
          "isoAlpha3": "NCL",
          "isoNumeric": "540",
          "languages": "fr-NC",
          "name": "New Caledonia",
          "north": -19.549778,
          "population": "216494",
          "south": -22.698,
          "updatedAt": "2015-12-17T13:18:02.562Z",
          "west": 163.564667
        },
        {
          "_id": "EJKFomBijSe",
          "areaInSqKm": "268680.0",
          "capital": "Wellington",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "NZ",
          "countryName": "New Zealand",
          "createdAt": "2015-12-17T13:18:02.567Z",
          "currencyCode": "NZD",
          "east": -180,
          "fipsCode": "NZ",
          "geonameId": "2186224",
          "id": "EJKFomBijSe",
          "isoAlpha3": "NZL",
          "isoNumeric": "554",
          "languages": "en-NZ,mi",
          "name": "New Zealand",
          "north": -34.389668,
          "population": "4252277",
          "south": -47.286026,
          "updatedAt": "2015-12-17T13:18:02.567Z",
          "west": 166.7155
        },
        {
          "_id": "4JXKiQSssBl",
          "areaInSqKm": "129494.0",
          "capital": "Managua",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "NI",
          "countryName": "Nicaragua",
          "createdAt": "2015-12-17T13:18:02.564Z",
          "currencyCode": "NIO",
          "east": -82.738289,
          "fipsCode": "NU",
          "geonameId": "3617476",
          "id": "4JXKiQSssBl",
          "isoAlpha3": "NIC",
          "isoNumeric": "558",
          "languages": "es-NI,en",
          "name": "Nicaragua",
          "north": 15.025909,
          "population": "5995928",
          "south": 10.707543,
          "updatedAt": "2015-12-17T13:18:02.564Z",
          "west": -87.690308
        },
        {
          "_id": "E1gFsXBooBl",
          "areaInSqKm": "1267000.0",
          "capital": "Niamey",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "NE",
          "countryName": "Niger",
          "createdAt": "2015-12-17T13:18:02.563Z",
          "currencyCode": "XOF",
          "east": 15.995643,
          "fipsCode": "NG",
          "geonameId": "2440476",
          "id": "E1gFsXBooBl",
          "isoAlpha3": "NER",
          "isoNumeric": "562",
          "languages": "fr-NE,ha,kr,dje",
          "name": "Niger",
          "north": 23.525026,
          "population": "15878271",
          "south": 11.696975,
          "updatedAt": "2015-12-17T13:18:02.563Z",
          "west": 0.16625
        },
        {
          "_id": "VJMYj7BoiBx",
          "areaInSqKm": "923768.0",
          "capital": "Abuja",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "NG",
          "countryName": "Nigeria",
          "createdAt": "2015-12-17T13:18:02.563Z",
          "currencyCode": "NGN",
          "east": 14.680073,
          "fipsCode": "NI",
          "geonameId": "2328926",
          "id": "VJMYj7BoiBx",
          "isoAlpha3": "NGA",
          "isoNumeric": "566",
          "languages": "en-NG,ha,yo,ig,ff",
          "name": "Nigeria",
          "north": 13.892007,
          "population": "154000000",
          "south": 4.277144,
          "updatedAt": "2015-12-17T13:18:02.563Z",
          "west": 2.668432
        },
        {
          "_id": "N1_KjQHisSe",
          "areaInSqKm": "260.0",
          "capital": "Alofi",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "NU",
          "countryName": "Niue",
          "createdAt": "2015-12-17T13:18:02.567Z",
          "currencyCode": "NZD",
          "east": -169.775177,
          "fipsCode": "NE",
          "geonameId": "4036232",
          "id": "N1_KjQHisSe",
          "isoAlpha3": "NIU",
          "isoNumeric": "570",
          "languages": "niu,en-NU",
          "name": "Niue",
          "north": -18.951069,
          "population": "2166",
          "south": -19.152193,
          "updatedAt": "2015-12-17T13:18:02.567Z",
          "west": -169.951004
        },
        {
          "_id": "4kWtj7SiiSx",
          "areaInSqKm": "34.6",
          "capital": "Kingston",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "NF",
          "countryName": "Norfolk Island",
          "createdAt": "2015-12-17T13:18:02.563Z",
          "currencyCode": "AUD",
          "east": 167.9977374020996,
          "fipsCode": "NF",
          "geonameId": "2155115",
          "id": "4kWtj7SiiSx",
          "isoAlpha3": "NFK",
          "isoNumeric": "574",
          "languages": "en-NF",
          "name": "Norfolk Island",
          "north": -28.99517068694843,
          "population": "1828",
          "south": -29.06307674295473,
          "updatedAt": "2015-12-17T13:18:02.563Z",
          "west": 167.9154323015136
        },
        {
          "_id": "VJwLimBijSx",
          "areaInSqKm": "120540.0",
          "capital": "Pyongyang",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "KP",
          "countryName": "North Korea",
          "createdAt": "2015-12-17T13:18:02.542Z",
          "currencyCode": "KPW",
          "east": 130.674866,
          "fipsCode": "KN",
          "geonameId": "1873107",
          "id": "VJwLimBijSx",
          "isoAlpha3": "PRK",
          "isoNumeric": "408",
          "languages": "ko-KP",
          "name": "North Korea",
          "north": 43.006054,
          "population": "22912177",
          "south": 37.673332,
          "updatedAt": "2015-12-17T13:18:02.542Z",
          "west": 124.315887
        },
        {
          "_id": "EJXOjQrsorx",
          "areaInSqKm": "477.0",
          "capital": "Saipan",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "MP",
          "countryName": "Northern Mariana Islands",
          "createdAt": "2015-12-17T13:18:02.558Z",
          "currencyCode": "USD",
          "east": 146.06528,
          "fipsCode": "CQ",
          "geonameId": "4041468",
          "id": "EJXOjQrsorx",
          "isoAlpha3": "MNP",
          "isoNumeric": "580",
          "languages": "fil,tl,zh,ch-MP,en-MP",
          "name": "Northern Mariana Islands",
          "north": 20.55344,
          "population": "53883",
          "south": 14.11023,
          "updatedAt": "2015-12-17T13:18:02.558Z",
          "west": 144.88626
        },
        {
          "_id": "EJrFjQSsjHl",
          "areaInSqKm": "324220.0",
          "capital": "Oslo",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "NO",
          "countryName": "Norway",
          "createdAt": "2015-12-17T13:18:02.565Z",
          "currencyCode": "NOK",
          "east": 31.07805252075195,
          "fipsCode": "NO",
          "geonameId": "3144096",
          "id": "EJrFjQSsjHl",
          "isoAlpha3": "NOR",
          "isoNumeric": "578",
          "languages": "no,nb,nn,se,fi",
          "name": "Norway",
          "north": 71.18811,
          "population": "5009150",
          "south": 57.977917,
          "updatedAt": "2015-12-17T13:18:02.565Z",
          "west": 4.650167
        },
        {
          "_id": "NkcFoXSojrg",
          "areaInSqKm": "212460.0",
          "capital": "Muscat",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "OM",
          "countryName": "Oman",
          "createdAt": "2015-12-17T13:18:02.567Z",
          "currencyCode": "OMR",
          "east": 59.836582,
          "fipsCode": "MU",
          "geonameId": "286963",
          "id": "NkcFoXSojrg",
          "isoAlpha3": "OMN",
          "isoNumeric": "512",
          "languages": "ar-OM,en,bal,ur",
          "name": "Oman",
          "north": 26.387972,
          "population": "2967717",
          "south": 16.64575,
          "updatedAt": "2015-12-17T13:18:02.567Z",
          "west": 51.882
        },
        {
          "_id": "4yecimSooBl",
          "areaInSqKm": "803940.0",
          "capital": "Islamabad",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "PK",
          "countryName": "Pakistan",
          "createdAt": "2015-12-17T13:18:02.57Z",
          "currencyCode": "PKR",
          "east": 77.840919,
          "fipsCode": "PK",
          "geonameId": "1168579",
          "id": "4yecimSooBl",
          "isoAlpha3": "PAK",
          "isoNumeric": "586",
          "languages": "ur-PK,en-PK,pa,sd,ps,brh",
          "name": "Pakistan",
          "north": 37.097,
          "population": "184404791",
          "south": 23.786722,
          "updatedAt": "2015-12-17T13:18:02.57Z",
          "west": 60.878613
        },
        {
          "_id": "4yDciQSsiHg",
          "areaInSqKm": "458.0",
          "capital": "Melekeok",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "PW",
          "countryName": "Palau",
          "createdAt": "2015-12-17T13:18:02.572Z",
          "currencyCode": "USD",
          "east": 134.72307,
          "fipsCode": "PS",
          "geonameId": "1559582",
          "id": "4yDciQSsiHg",
          "isoAlpha3": "PLW",
          "isoNumeric": "585",
          "languages": "pau,sov,en-PW,tox,ja,fil,zh",
          "name": "Palau",
          "north": 8.46966,
          "population": "19907",
          "south": 2.8036,
          "updatedAt": "2015-12-17T13:18:02.572Z",
          "west": 131.11788
        },
        {
          "_id": "VySqjmSjirx",
          "areaInSqKm": "5970.0",
          "capital": "",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "PS",
          "countryName": "Palestine",
          "createdAt": "2015-12-17T13:18:02.571Z",
          "currencyCode": "ILS",
          "east": 35.5732955932617,
          "fipsCode": "WE",
          "geonameId": "6254930",
          "id": "VySqjmSjirx",
          "isoAlpha3": "PSE",
          "isoNumeric": "275",
          "languages": "ar-PS",
          "name": "Palestine",
          "north": 32.54638671875,
          "population": "3800000",
          "south": 31.2165412902832,
          "updatedAt": "2015-12-17T13:18:02.571Z",
          "west": 34.21665954589844
        },
        {
          "_id": "4JoYi7BojBx",
          "areaInSqKm": "78200.0",
          "capital": "Panama City",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "PA",
          "countryName": "Panama",
          "createdAt": "2015-12-17T13:18:02.568Z",
          "currencyCode": "PAB",
          "east": -77.17411,
          "fipsCode": "PM",
          "geonameId": "3703430",
          "id": "4JoYi7BojBx",
          "isoAlpha3": "PAN",
          "isoNumeric": "591",
          "languages": "es-PA,en",
          "name": "Panama",
          "north": 9.637514,
          "population": "3410676",
          "south": 7.197906,
          "updatedAt": "2015-12-17T13:18:02.568Z",
          "west": -83.051445
        },
        {
          "_id": "N1RtoQSjoHe",
          "areaInSqKm": "462840.0",
          "capital": "Port Moresby",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "PG",
          "countryName": "Papua New Guinea",
          "createdAt": "2015-12-17T13:18:02.569Z",
          "currencyCode": "PGK",
          "east": 155.96344,
          "fipsCode": "PP",
          "geonameId": "2088628",
          "id": "N1RtoQSjoHe",
          "isoAlpha3": "PNG",
          "isoNumeric": "598",
          "languages": "en-PG,ho,meu,tpi",
          "name": "Papua New Guinea",
          "north": -1.318639,
          "population": "6064515",
          "south": -11.657861,
          "updatedAt": "2015-12-17T13:18:02.569Z",
          "west": 140.842865
        },
        {
          "_id": "N1uqs7Hoore",
          "areaInSqKm": "406750.0",
          "capital": "Asunción",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "PY",
          "countryName": "Paraguay",
          "createdAt": "2015-12-17T13:18:02.572Z",
          "currencyCode": "PYG",
          "east": -54.259354,
          "fipsCode": "PA",
          "geonameId": "3437598",
          "id": "N1uqs7Hoore",
          "isoAlpha3": "PRY",
          "isoNumeric": "600",
          "languages": "es-PY,gn",
          "name": "Paraguay",
          "north": -19.294041,
          "population": "6375830",
          "south": -27.608738,
          "updatedAt": "2015-12-17T13:18:02.572Z",
          "west": -62.647076
        },
        {
          "_id": "V13Kimroirg",
          "areaInSqKm": "1285220.0",
          "capital": "Lima",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "PE",
          "countryName": "Peru",
          "createdAt": "2015-12-17T13:18:02.568Z",
          "currencyCode": "PEN",
          "east": -68.677986,
          "fipsCode": "PE",
          "geonameId": "3932488",
          "id": "V13Kimroirg",
          "isoAlpha3": "PER",
          "isoNumeric": "604",
          "languages": "es-PE,qu,ay",
          "name": "Peru",
          "north": -0.012977,
          "population": "29907003",
          "south": -18.349728,
          "updatedAt": "2015-12-17T13:18:02.568Z",
          "west": -81.326744
        },
        {
          "_id": "Vky5imHsjrl",
          "areaInSqKm": "300000.0",
          "capital": "Manila",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "PH",
          "countryName": "Philippines",
          "createdAt": "2015-12-17T13:18:02.569Z",
          "currencyCode": "PHP",
          "east": 126.601524,
          "fipsCode": "RP",
          "geonameId": "1694008",
          "id": "Vky5imHsjrl",
          "isoAlpha3": "PHL",
          "isoNumeric": "608",
          "languages": "tl,en-PH,fil",
          "name": "Philippines",
          "north": 21.120611,
          "population": "99900177",
          "south": 4.643306,
          "updatedAt": "2015-12-17T13:18:02.569Z",
          "west": 116.931557
        },
        {
          "_id": "V1QcjmrooHl",
          "areaInSqKm": "47.0",
          "capital": "Adamstown",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "PN",
          "countryName": "Pitcairn Islands",
          "createdAt": "2015-12-17T13:18:02.571Z",
          "currencyCode": "NZD",
          "east": -124.77285,
          "fipsCode": "PC",
          "geonameId": "4030699",
          "id": "V1QcjmrooHl",
          "isoAlpha3": "PCN",
          "isoNumeric": "612",
          "languages": "en-PN",
          "name": "Pitcairn Islands",
          "north": -24.315865,
          "population": "46",
          "south": -24.672565,
          "updatedAt": "2015-12-17T13:18:02.571Z",
          "west": -128.346436
        },
        {
          "_id": "4Jb5iQHsorg",
          "areaInSqKm": "312685.0",
          "capital": "Warsaw",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "PL",
          "countryName": "Poland",
          "createdAt": "2015-12-17T13:18:02.57Z",
          "currencyCode": "PLN",
          "east": 24.150749,
          "fipsCode": "PL",
          "geonameId": "798544",
          "id": "4Jb5iQHsorg",
          "isoAlpha3": "POL",
          "isoNumeric": "616",
          "languages": "pl",
          "name": "Poland",
          "north": 54.839138,
          "population": "38500000",
          "south": 49.006363,
          "updatedAt": "2015-12-17T13:18:02.57Z",
          "west": 14.123
        },
        {
          "_id": "4kU5sXSjoBx",
          "areaInSqKm": "92391.0",
          "capital": "Lisbon",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "PT",
          "countryName": "Portugal",
          "createdAt": "2015-12-17T13:18:02.572Z",
          "currencyCode": "EUR",
          "east": -6.18915930748288,
          "fipsCode": "PO",
          "geonameId": "2264397",
          "id": "4kU5sXSjoBx",
          "isoAlpha3": "PRT",
          "isoNumeric": "620",
          "languages": "pt-PT,mwl",
          "name": "Portugal",
          "north": 42.154311127408,
          "population": "10676000",
          "south": 36.96125,
          "updatedAt": "2015-12-17T13:18:02.572Z",
          "west": -9.50052660716588
        },
        {
          "_id": "V1EqjQSsjBl",
          "areaInSqKm": "9104.0",
          "capital": "San Juan",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "PR",
          "countryName": "Puerto Rico",
          "createdAt": "2015-12-17T13:18:02.571Z",
          "currencyCode": "USD",
          "east": -65.242737,
          "fipsCode": "RQ",
          "geonameId": "4566966",
          "id": "V1EqjQSsjBl",
          "isoAlpha3": "PRI",
          "isoNumeric": "630",
          "languages": "en-PR,es-PR",
          "name": "Puerto Rico",
          "north": 18.520166,
          "population": "3916632",
          "south": 17.926405,
          "updatedAt": "2015-12-17T13:18:02.571Z",
          "west": -67.942726
        },
        {
          "_id": "4JtcjXBjjSe",
          "areaInSqKm": "11437.0",
          "capital": "Doha",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "QA",
          "countryName": "Qatar",
          "createdAt": "2015-12-17T13:18:02.573Z",
          "currencyCode": "QAR",
          "east": 51.636639,
          "fipsCode": "QA",
          "geonameId": "289688",
          "id": "4JtcjXBjjSe",
          "isoAlpha3": "QAT",
          "isoNumeric": "634",
          "languages": "ar-QA,es",
          "name": "Qatar",
          "north": 26.154722,
          "population": "840926",
          "south": 24.482944,
          "updatedAt": "2015-12-17T13:18:02.573Z",
          "west": 50.757221
        },
        {
          "_id": "4Jo4oXBisrr",
          "areaInSqKm": "36120.0",
          "capital": "Bissau",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "GW",
          "countryName": "Republic of Guinea",
          "createdAt": "2015-12-17T13:18:02.528Z",
          "currencyCode": "XOF",
          "east": -13.636522,
          "fipsCode": "PU",
          "geonameId": "2372248",
          "id": "4Jo4oXBisrr",
          "isoAlpha3": "GNB",
          "isoNumeric": "624",
          "languages": "pt-GW,pov",
          "name": "Republic of Guinea",
          "north": 12.680789,
          "population": "1565126",
          "south": 10.924265,
          "updatedAt": "2015-12-17T13:18:02.528Z",
          "west": -16.717535
        },
        {
          "_id": "4Jo4oXBisre",
          "areaInSqKm": "36120.0",
          "capital": "Bissau",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "GW",
          "countryName": "Republic of Guinea-Bissau",
          "createdAt": "2015-12-17T13:18:02.528Z",
          "currencyCode": "XOF",
          "east": -13.636522,
          "fipsCode": "PU",
          "geonameId": "2372248",
          "id": "4Jo4oXBisre",
          "isoAlpha3": "GNB",
          "isoNumeric": "624",
          "languages": "pt-GW,pov",
          "name": "Republic of Guinea-Bissau",
          "north": 12.680789,
          "population": "1565126",
          "south": 10.924265,
          "updatedAt": "2015-12-17T13:18:02.528Z",
          "west": -16.717535
        },
        {
          "_id": "EkuZsQBsiHl",
          "areaInSqKm": "342000.0",
          "capital": "Brazzaville",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "CG",
          "countryName": "Republic of the Congo",
          "createdAt": "2015-12-17T13:18:02.504Z",
          "currencyCode": "XAF",
          "east": 18.649839,
          "fipsCode": "CF",
          "geonameId": "2260494",
          "id": "EkuZsQBsiHl",
          "isoAlpha3": "COG",
          "isoNumeric": "178",
          "languages": "fr-CG,kg,ln-CG",
          "name": "Republic of the Congo",
          "north": 3.703082,
          "population": "3039126",
          "south": -5.027223,
          "updatedAt": "2015-12-17T13:18:02.504Z",
          "west": 11.205009
        },
        {
          "_id": "Vys5sQriirg",
          "areaInSqKm": "237500.0",
          "capital": "Bucharest",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "RO",
          "countryName": "Romania",
          "createdAt": "2015-12-17T13:18:02.575Z",
          "currencyCode": "RON",
          "east": 29.691055,
          "fipsCode": "RO",
          "geonameId": "798549",
          "id": "Vys5sQriirg",
          "isoAlpha3": "ROU",
          "isoNumeric": "642",
          "languages": "ro,hu,rom",
          "name": "Romania",
          "north": 48.266945,
          "population": "21959278",
          "south": 43.627304,
          "updatedAt": "2015-12-17T13:18:02.575Z",
          "west": 20.269972
        },
        {
          "_id": "Vk6qj7Hsirg",
          "areaInSqKm": "1.71E7",
          "capital": "Moscow",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "RU",
          "countryName": "Russia",
          "createdAt": "2015-12-17T13:18:02.577Z",
          "currencyCode": "RUB",
          "east": -169.05,
          "fipsCode": "RS",
          "geonameId": "2017370",
          "id": "Vk6qj7Hsirg",
          "isoAlpha3": "RUS",
          "isoNumeric": "643",
          "languages": "ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,tut,kbd,krc,ava,sah,nog",
          "name": "Russia",
          "north": 81.857361,
          "population": "140702000",
          "south": 41.188862,
          "updatedAt": "2015-12-17T13:18:02.577Z",
          "west": 19.25
        },
        {
          "_id": "5eec74cc5917e917fb0debe7",
          "areaInSqKm": "1.71E7",
          "capital": "Moscow",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "RUS",
          "countryName": "Russian Federation",
          "currencyCode": "RUB",
          "east": -169.05,
          "fipsCode": "RS",
          "geonameId": "2017370",
          "id": "5eec74cc5917e917fb0debe7",
          "isoAlpha3": "RUS",
          "isoNumeric": "643",
          "languages": "ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,tut,kbd,krc,ava,sah,nog",
          "name": "Russian Federation",
          "north": 81.857361,
          "population": "140702000",
          "south": 41.188862,
          "west": 19.25
        },
        {
          "_id": "Vy0csmBisHg",
          "areaInSqKm": "26338.0",
          "capital": "Kigali",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "RW",
          "countryName": "Rwanda",
          "createdAt": "2015-12-17T13:18:02.578Z",
          "currencyCode": "RWF",
          "east": 30.895958,
          "fipsCode": "RW",
          "geonameId": "49518",
          "id": "Vy0csmBisHg",
          "isoAlpha3": "RWA",
          "isoNumeric": "646",
          "languages": "rw,en-RW,fr-RW,sw",
          "name": "Rwanda",
          "north": -1.053481,
          "population": "11055976",
          "south": -2.840679,
          "updatedAt": "2015-12-17T13:18:02.578Z",
          "west": 28.856794
        },
        {
          "_id": "41c5sQHjoHl",
          "areaInSqKm": "2517.0",
          "capital": "Saint-Denis",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "RE",
          "countryName": "Réunion",
          "createdAt": "2015-12-17T13:18:02.574Z",
          "currencyCode": "EUR",
          "east": 55.83819390193003,
          "fipsCode": "RE",
          "geonameId": "935317",
          "id": "41c5sQHjoHl",
          "isoAlpha3": "REU",
          "isoNumeric": "638",
          "languages": "fr-RE",
          "name": "Réunion",
          "north": -20.86839132457694,
          "population": "776948",
          "south": -21.38374730146911,
          "updatedAt": "2015-12-17T13:18:02.574Z",
          "west": 55.21219224792685
        },
        {
          "_id": "5d665b77b233ee87e1c660a0",
          "areaInSqKm": "13.0",
          "capital": "The Bottom",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "BQ",
          "countryName": "Saba",
          "currencyCode": "USD",
          "id": "5d665b77b233ee87e1c660a0",
          "isoNumeric": "599",
          "languages": "da",
          "name": "Saba",
          "population": "1991"
        },
        {
          "_id": "E1dls7BosHe",
          "areaInSqKm": "21.0",
          "capital": "Gustavia",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "BL",
          "countryName": "Saint Barthélemy",
          "createdAt": "2015-12-17T13:18:02.494Z",
          "currencyCode": "EUR",
          "east": -62.78898337298585,
          "fipsCode": "TB",
          "geonameId": "3578476",
          "id": "E1dls7BosHe",
          "isoAlpha3": "BLM",
          "isoNumeric": "652",
          "languages": "fr",
          "name": "Saint Barthélemy",
          "north": 17.92880879194928,
          "population": "8450",
          "south": 17.87818322740558,
          "updatedAt": "2015-12-17T13:18:02.494Z",
          "west": -62.8739118253784
        },
        {
          "_id": "E1Hss7HjiBg",
          "areaInSqKm": "410.0",
          "capital": "Jamestown",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "SH",
          "countryName": "Saint Helena",
          "createdAt": "2015-12-17T13:18:02.583Z",
          "currencyCode": "SHP",
          "east": -5.638753,
          "fipsCode": "SH",
          "geonameId": "3370751",
          "id": "E1Hss7HjiBg",
          "isoAlpha3": "SHN",
          "isoNumeric": "654",
          "languages": "en-SH",
          "name": "Saint Helena",
          "north": -7.887815,
          "population": "7460",
          "south": -16.019543,
          "updatedAt": "2015-12-17T13:18:02.583Z",
          "west": -14.42123
        },
        {
          "_id": "VkULsQriire",
          "areaInSqKm": "261.0",
          "capital": "Basseterre",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "KN",
          "countryName": "Saint Kitts and Nevis",
          "createdAt": "2015-12-17T13:18:02.542Z",
          "currencyCode": "XCD",
          "east": -62.543266,
          "fipsCode": "SC",
          "geonameId": "3575174",
          "id": "VkULsQriire",
          "isoAlpha3": "KNA",
          "isoNumeric": "659",
          "languages": "en-KN",
          "name": "Saint Kitts and Nevis",
          "north": 17.420118,
          "population": "51134",
          "south": 17.095343,
          "updatedAt": "2015-12-17T13:18:02.542Z",
          "west": -62.86956
        },
        {
          "_id": "4JRLiXSsire",
          "areaInSqKm": "616.0",
          "capital": "Castries",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "LC",
          "countryName": "Saint Lucia",
          "createdAt": "2015-12-17T13:18:02.545Z",
          "currencyCode": "XCD",
          "east": -60.874203,
          "fipsCode": "ST",
          "geonameId": "3576468",
          "id": "4JRLiXSsire",
          "isoAlpha3": "LCA",
          "isoNumeric": "662",
          "languages": "en-LC",
          "name": "Saint Lucia",
          "north": 14.103245,
          "population": "160922",
          "south": 13.704778,
          "updatedAt": "2015-12-17T13:18:02.545Z",
          "west": -61.07415
        },
        {
          "_id": "4yoDsmHssBx",
          "areaInSqKm": "53.0",
          "capital": "Marigot",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "MF",
          "countryName": "Saint Martin",
          "createdAt": "2015-12-17T13:18:02.555Z",
          "currencyCode": "EUR",
          "east": -63.012993,
          "fipsCode": "RN",
          "geonameId": "3578421",
          "id": "4yoDsmHssBx",
          "isoAlpha3": "MAF",
          "isoNumeric": "663",
          "languages": "fr",
          "name": "Saint Martin",
          "north": 18.130354,
          "population": "35925",
          "south": 18.052231,
          "updatedAt": "2015-12-17T13:18:02.555Z",
          "west": -63.152767
        },
        {
          "_id": "VyMqi7Sijrl",
          "areaInSqKm": "242.0",
          "capital": "Saint-Pierre",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "PM",
          "countryName": "Saint Pierre and Miquelon",
          "createdAt": "2015-12-17T13:18:02.57Z",
          "currencyCode": "EUR",
          "east": -56.252991,
          "fipsCode": "SB",
          "geonameId": "3424932",
          "id": "VyMqi7Sijrl",
          "isoAlpha3": "SPM",
          "isoNumeric": "666",
          "languages": "fr-PM",
          "name": "Saint Pierre and Miquelon",
          "north": 47.146286,
          "population": "7012",
          "south": 46.786041,
          "updatedAt": "2015-12-17T13:18:02.57Z",
          "west": -56.420658
        },
        {
          "_id": "E1sTi7Bsjrg",
          "areaInSqKm": "389.0",
          "capital": "Kingstown",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "VC",
          "countryName": "Saint Vincent and the Grenadines",
          "createdAt": "2015-12-17T13:18:02.598Z",
          "currencyCode": "XCD",
          "east": -61.11388,
          "fipsCode": "VC",
          "geonameId": "3577815",
          "id": "E1sTi7Bsjrg",
          "isoAlpha3": "VCT",
          "isoNumeric": "670",
          "languages": "en-VC,fr",
          "name": "Saint Vincent and the Grenadines",
          "north": 13.377834,
          "population": "104217",
          "south": 12.58398481096904,
          "updatedAt": "2015-12-17T13:18:02.598Z",
          "west": -61.46090317727658
        },
        {
          "_id": "V1MCo7HjjHx",
          "areaInSqKm": "2944.0",
          "capital": "Apia",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "WS",
          "countryName": "Samoa",
          "createdAt": "2015-12-17T13:18:02.601Z",
          "currencyCode": "WST",
          "east": -171.415741,
          "fipsCode": "WS",
          "geonameId": "4034894",
          "id": "V1MCo7HjjHx",
          "isoAlpha3": "WSM",
          "isoNumeric": "882",
          "languages": "sm,en-WS",
          "name": "Samoa",
          "north": -13.432207,
          "population": "192001",
          "south": -14.040939,
          "updatedAt": "2015-12-17T13:18:02.601Z",
          "west": -172.798599
        },
        {
          "_id": "4JcsoQHooBl",
          "areaInSqKm": "61.2",
          "capital": "San Marino",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "SM",
          "countryName": "San Marino",
          "createdAt": "2015-12-17T13:18:02.586Z",
          "currencyCode": "EUR",
          "east": 12.51653186779788,
          "fipsCode": "SM",
          "geonameId": "3168068",
          "id": "4JcsoQHooBl",
          "isoAlpha3": "SMR",
          "isoNumeric": "674",
          "languages": "it-SM",
          "name": "San Marino",
          "north": 43.99223730851663,
          "population": "31477",
          "south": 43.8937092171425,
          "updatedAt": "2015-12-17T13:18:02.586Z",
          "west": 12.40353897882073
        },
        {
          "_id": "4ykjjmrsiSg",
          "areaInSqKm": "1960582.0",
          "capital": "Riyadh",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "SA",
          "countryName": "Saudi Arabia",
          "createdAt": "2015-12-17T13:18:02.578Z",
          "currencyCode": "SAR",
          "east": 55.666584,
          "fipsCode": "SA",
          "geonameId": "102358",
          "id": "4ykjjmrsiSg",
          "isoAlpha3": "SAU",
          "isoNumeric": "682",
          "languages": "ar-SA",
          "name": "Saudi Arabia",
          "north": 32.158333,
          "population": "25731776",
          "south": 15.61425,
          "updatedAt": "2015-12-17T13:18:02.578Z",
          "west": 34.495693
        },
        {
          "_id": "NJioiQBojSl",
          "areaInSqKm": "196190.0",
          "capital": "Dakar",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "SN",
          "countryName": "Senegal",
          "createdAt": "2015-12-17T13:18:02.586Z",
          "currencyCode": "XOF",
          "east": -11.355887,
          "fipsCode": "SG",
          "geonameId": "2245662",
          "id": "NJioiQBojSl",
          "isoAlpha3": "SEN",
          "isoNumeric": "686",
          "languages": "fr-SN,wo,fuc,mnk",
          "name": "Senegal",
          "north": 16.691633,
          "population": "12323252",
          "south": 12.307275,
          "updatedAt": "2015-12-17T13:18:02.586Z",
          "west": -17.535236
        },
        {
          "_id": "EJ2co7BosSl",
          "areaInSqKm": "88361.0",
          "capital": "Belgrade",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "RS",
          "countryName": "Serbia",
          "createdAt": "2015-12-17T13:18:02.577Z",
          "currencyCode": "RSD",
          "east": 23.00499725341797,
          "fipsCode": "RI",
          "geonameId": "6290252",
          "id": "EJ2co7BosSl",
          "isoAlpha3": "SRB",
          "isoNumeric": "688",
          "languages": "sr,hu,bs,rom",
          "name": "Serbia",
          "north": 46.18138885498047,
          "population": "7344847",
          "south": 42.23221588134766,
          "updatedAt": "2015-12-17T13:18:02.577Z",
          "west": 18.81702041625977
        },
        {
          "_id": "N1Zos7BjoBg",
          "areaInSqKm": "455.0",
          "capital": "Victoria",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "SC",
          "countryName": "Seychelles",
          "createdAt": "2015-12-17T13:18:02.579Z",
          "currencyCode": "SCR",
          "east": 56.29770287937299,
          "fipsCode": "SE",
          "geonameId": "241170",
          "id": "N1Zos7BjoBg",
          "isoAlpha3": "SYC",
          "isoNumeric": "690",
          "languages": "en-SC,fr-SC",
          "name": "Seychelles",
          "north": -4.283717,
          "population": "88340",
          "south": -9.753867,
          "updatedAt": "2015-12-17T13:18:02.579Z",
          "west": 46.204769
        },
        {
          "_id": "41FojXBosHe",
          "areaInSqKm": "71740.0",
          "capital": "Freetown",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "SL",
          "countryName": "Sierra Leone",
          "createdAt": "2015-12-17T13:18:02.586Z",
          "currencyCode": "SLL",
          "east": -10.284238,
          "fipsCode": "SL",
          "geonameId": "2403846",
          "id": "41FojXBosHe",
          "isoAlpha3": "SLE",
          "isoNumeric": "694",
          "languages": "en-SL,men,tem",
          "name": "Sierra Leone",
          "north": 10,
          "population": "5245695",
          "south": 6.929611,
          "updatedAt": "2015-12-17T13:18:02.586Z",
          "west": -13.307631
        },
        {
          "_id": "EJEis7HisSx",
          "areaInSqKm": "692.7",
          "capital": "Singapore",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "SG",
          "countryName": "Singapore",
          "createdAt": "2015-12-17T13:18:02.582Z",
          "currencyCode": "SGD",
          "east": 104.007469,
          "fipsCode": "SN",
          "geonameId": "1880251",
          "id": "EJEis7HisSx",
          "isoAlpha3": "SGP",
          "isoNumeric": "702",
          "languages": "cmn,en-SG,ms-SG,ta-SG,zh-SG",
          "name": "Singapore",
          "north": 1.471278,
          "population": "4701069",
          "south": 1.258556,
          "updatedAt": "2015-12-17T13:18:02.582Z",
          "west": 103.638275
        },
        {
          "_id": "4y-nsXrisBl",
          "areaInSqKm": "21.0",
          "capital": "Philipsburg",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "SX",
          "countryName": "Sint Maarten",
          "createdAt": "2015-12-17T13:18:02.589Z",
          "currencyCode": "ANG",
          "east": -63.012993,
          "fipsCode": "NN",
          "geonameId": "7609695",
          "id": "4y-nsXrisBl",
          "isoAlpha3": "SXM",
          "isoNumeric": "534",
          "languages": "nl,en",
          "name": "Sint Maarten",
          "north": 18.070248,
          "population": "37429",
          "south": 18.011692,
          "updatedAt": "2015-12-17T13:18:02.589Z",
          "west": -63.144039
        },
        {
          "_id": "4ydosXHsiSl",
          "areaInSqKm": "48845.0",
          "capital": "Bratislava",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "SK",
          "countryName": "Slovakia",
          "createdAt": "2015-12-17T13:18:02.585Z",
          "currencyCode": "EUR",
          "east": 22.570444,
          "fipsCode": "LO",
          "geonameId": "3057568",
          "id": "4ydosXHsiSl",
          "isoAlpha3": "SVK",
          "isoNumeric": "703",
          "languages": "sk,hu",
          "name": "Slovakia",
          "north": 49.603168,
          "population": "5455000",
          "south": 47.728111,
          "updatedAt": "2015-12-17T13:18:02.585Z",
          "west": 16.84775
        },
        {
          "_id": "4k8iiQSooSl",
          "areaInSqKm": "20273.0",
          "capital": "Ljubljana",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "SI",
          "countryName": "Slovenia",
          "createdAt": "2015-12-17T13:18:02.584Z",
          "currencyCode": "EUR",
          "east": 16.6106311807,
          "fipsCode": "SI",
          "geonameId": "3190538",
          "id": "4k8iiQSooSl",
          "isoAlpha3": "SVN",
          "isoNumeric": "705",
          "languages": "sl,sh",
          "name": "Slovenia",
          "north": 46.8766275518195,
          "population": "2007000",
          "south": 45.421812998164,
          "updatedAt": "2015-12-17T13:18:02.584Z",
          "west": 13.3753342064709
        },
        {
          "_id": "4yxsj7Siorg",
          "areaInSqKm": "28450.0",
          "capital": "Honiara",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "SB",
          "countryName": "Solomon Islands",
          "createdAt": "2015-12-17T13:18:02.579Z",
          "currencyCode": "SBD",
          "east": 166.980865,
          "fipsCode": "BP",
          "geonameId": "2103350",
          "id": "4yxsj7Siorg",
          "isoAlpha3": "SLB",
          "isoNumeric": "090",
          "languages": "en-SB,tpi",
          "name": "Solomon Islands",
          "north": -6.589611,
          "population": "559198",
          "south": -11.850555,
          "updatedAt": "2015-12-17T13:18:02.579Z",
          "west": 155.508606
        },
        {
          "_id": "EyhoiXSoire",
          "areaInSqKm": "637657.0",
          "capital": "Mogadishu",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "SO",
          "countryName": "Somalia",
          "createdAt": "2015-12-17T13:18:02.587Z",
          "currencyCode": "SOS",
          "east": 51.412636,
          "fipsCode": "SO",
          "geonameId": "51537",
          "id": "EyhoiXSoire",
          "isoAlpha3": "SOM",
          "isoNumeric": "706",
          "languages": "so-SO,ar-SO,it,en-SO",
          "name": "Somalia",
          "north": 11.979166,
          "population": "10112453",
          "south": -1.674868,
          "updatedAt": "2015-12-17T13:18:02.587Z",
          "west": 40.986595
        },
        {
          "_id": "EJ8As7HisBe",
          "areaInSqKm": "1219912.0",
          "capital": "Pretoria",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "ZA",
          "countryName": "South Africa",
          "createdAt": "2015-12-17T13:18:02.602Z",
          "currencyCode": "ZAR",
          "east": 32.895973,
          "fipsCode": "SF",
          "geonameId": "953987",
          "id": "EJ8As7HisBe",
          "isoAlpha3": "ZAF",
          "isoNumeric": "710",
          "languages": "zu,xh,af,nso,en-ZA,tn,st,ts,ss,ve,nr",
          "name": "South Africa",
          "north": -22.126612,
          "population": "49000000",
          "south": -34.839828,
          "updatedAt": "2015-12-17T13:18:02.602Z",
          "west": 16.458021
        },
        {
          "_id": "VJOEjXHisBl",
          "areaInSqKm": "3903.0",
          "capital": "Grytviken",
          "companyID": "TECTORO",
          "continent": "AN",
          "continentName": "Antarctica",
          "countryCode": "GS",
          "countryName": "South Georgia and the South Sandwich Islands",
          "createdAt": "2015-12-17T13:18:02.527Z",
          "currencyCode": "GBP",
          "east": -26.229326,
          "fipsCode": "SX",
          "geonameId": "3474415",
          "id": "VJOEjXHisBl",
          "isoAlpha3": "SGS",
          "isoNumeric": "239",
          "languages": "en",
          "name": "South Georgia and the South Sandwich Islands",
          "north": -53.970467,
          "population": "30",
          "south": -59.479259,
          "updatedAt": "2015-12-17T13:18:02.527Z",
          "west": -38.021175
        },
        {
          "_id": "EJ_IjXrisBg",
          "areaInSqKm": "98480.0",
          "capital": "Seoul",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "KR",
          "countryName": "South Korea",
          "createdAt": "2015-12-17T13:18:02.543Z",
          "currencyCode": "KRW",
          "east": 129.584671,
          "fipsCode": "KS",
          "geonameId": "1835841",
          "id": "EJ_IjXrisBg",
          "isoAlpha3": "KOR",
          "isoNumeric": "410",
          "languages": "ko-KR,en",
          "name": "South Korea",
          "north": 38.612446,
          "population": "48422644",
          "south": 33.190945,
          "updatedAt": "2015-12-17T13:18:02.543Z",
          "west": 125.887108
        },
        {
          "_id": "N10soXroiBe",
          "areaInSqKm": "644329.0",
          "capital": "Juba",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "SS",
          "countryName": "South Sudan",
          "createdAt": "2015-12-17T13:18:02.588Z",
          "currencyCode": "SSP",
          "east": 35.9405517578125,
          "fipsCode": "OD",
          "geonameId": "7909807",
          "id": "N10soXroiBe",
          "isoAlpha3": "SSD",
          "isoNumeric": "728",
          "languages": "en",
          "name": "South Sudan",
          "north": 12.21914863586426,
          "population": "8260490",
          "south": 3.493394374847412,
          "updatedAt": "2015-12-17T13:18:02.588Z",
          "west": 24.14027404785156
        },
        {
          "_id": "E1MmomHssrx",
          "areaInSqKm": "504782.0",
          "capital": "Madrid",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "ES",
          "countryName": "Spain",
          "createdAt": "2015-12-17T13:18:02.518Z",
          "currencyCode": "EUR",
          "east": 4.32778473043961,
          "fipsCode": "SP",
          "geonameId": "2510769",
          "id": "E1MmomHssrx",
          "isoAlpha3": "ESP",
          "isoNumeric": "724",
          "languages": "es-ES,ca,gl,eu,oc",
          "name": "Spain",
          "north": 43.7913565913767,
          "population": "46505963",
          "south": 36.0001044260548,
          "updatedAt": "2015-12-17T13:18:02.518Z",
          "west": -9.30151567231899
        },
        {
          "_id": "EJgvoQrsiBg",
          "areaInSqKm": "65610.0",
          "capital": "Colombo",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "LK",
          "countryName": "Sri Lanka",
          "createdAt": "2015-12-17T13:18:02.546Z",
          "currencyCode": "LKR",
          "east": 81.881279,
          "fipsCode": "CE",
          "geonameId": "1227603",
          "id": "EJgvoQrsiBg",
          "isoAlpha3": "LKA",
          "isoNumeric": "144",
          "languages": "si,ta,en",
          "name": "Sri Lanka",
          "north": 9.831361,
          "population": "21513990",
          "south": 5.916833,
          "updatedAt": "2015-12-17T13:18:02.546Z",
          "west": 79.652916
        },
        {
          "_id": "5d665937b233ee87e1c5c3a0",
          "areaInSqKm": "21.0",
          "capital": "Oranjestad",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "AN",
          "countryName": "St Eustatius",
          "currencyCode": "USD",
          "id": "5d665937b233ee87e1c5c3a0",
          "isoNumeric": "599",
          "languages": "da",
          "name": "St Eustatius",
          "population": "3,193"
        },
        {
          "_id": "4JMio7rssrx",
          "areaInSqKm": "1861484.0",
          "capital": "Khartoum",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "SD",
          "countryName": "Sudan",
          "createdAt": "2015-12-17T13:18:02.58Z",
          "currencyCode": "SDG",
          "east": 38.60749816894531,
          "fipsCode": "SU",
          "geonameId": "366755",
          "id": "4JMio7rssrx",
          "isoAlpha3": "SDN",
          "isoNumeric": "729",
          "languages": "ar-SD,en,fia",
          "name": "Sudan",
          "north": 22.23221969604492,
          "population": "35000000",
          "south": 8.684720993041992,
          "updatedAt": "2015-12-17T13:18:02.58Z",
          "west": 21.82777404785156
        },
        {
          "_id": "4yToo7BsjSe",
          "areaInSqKm": "163270.0",
          "capital": "Paramaribo",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "SR",
          "countryName": "Suriname",
          "createdAt": "2015-12-17T13:18:02.587Z",
          "currencyCode": "SRD",
          "east": -53.977493,
          "fipsCode": "NS",
          "geonameId": "3382998",
          "id": "4yToo7BsjSe",
          "isoAlpha3": "SUR",
          "isoNumeric": "740",
          "languages": "nl-SR,en,srn,hns,jv",
          "name": "Suriname",
          "north": 6.004546,
          "population": "492829",
          "south": 1.831145,
          "updatedAt": "2015-12-17T13:18:02.587Z",
          "west": -58.086563
        },
        {
          "_id": "VkDjsmHssrl",
          "areaInSqKm": "62049.0",
          "capital": "Longyearbyen",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "SJ",
          "countryName": "Svalbard and Jan Mayen",
          "createdAt": "2015-12-17T13:18:02.584Z",
          "currencyCode": "NOK",
          "east": 33.287334,
          "fipsCode": "SV",
          "geonameId": "607072",
          "id": "VkDjsmHssrl",
          "isoAlpha3": "SJM",
          "isoNumeric": "744",
          "languages": "no,ru",
          "name": "Svalbard and Jan Mayen",
          "north": 80.762085,
          "population": "2550",
          "south": 79.220306,
          "updatedAt": "2015-12-17T13:18:02.584Z",
          "west": 17.699389
        },
        {
          "_id": "EkmnoQBojSg",
          "areaInSqKm": "17363.0",
          "capital": "Mbabane",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "SZ",
          "countryName": "Swaziland",
          "createdAt": "2015-12-17T13:18:02.589Z",
          "currencyCode": "SZL",
          "east": 32.13726,
          "fipsCode": "WZ",
          "geonameId": "934841",
          "id": "EkmnoQBojSg",
          "isoAlpha3": "SWZ",
          "isoNumeric": "748",
          "languages": "en-SZ,ss-SZ",
          "name": "Swaziland",
          "north": -25.719648,
          "population": "1354051",
          "south": -27.317101,
          "updatedAt": "2015-12-17T13:18:02.589Z",
          "west": 30.794107
        },
        {
          "_id": "4JXjsmroiHg",
          "areaInSqKm": "449964.0",
          "capital": "Stockholm",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "SE",
          "countryName": "Sweden",
          "createdAt": "2015-12-17T13:18:02.58Z",
          "currencyCode": "SEK",
          "east": 24.1562924839185,
          "fipsCode": "SW",
          "geonameId": "2661886",
          "id": "4JXjsmroiHg",
          "isoAlpha3": "SWE",
          "isoNumeric": "752",
          "languages": "sv-SE,se,sma,fi-SE",
          "name": "Sweden",
          "north": 69.0625,
          "population": "9828655",
          "south": 55.337112,
          "updatedAt": "2015-12-17T13:18:02.58Z",
          "west": 11.118694
        },
        {
          "_id": "NJFZjQSojre",
          "areaInSqKm": "41290.0",
          "capital": "Bern",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "CH",
          "countryName": "Switzerland",
          "createdAt": "2015-12-17T13:18:02.504Z",
          "currencyCode": "CHF",
          "east": 10.491472,
          "fipsCode": "SZ",
          "geonameId": "2658434",
          "id": "NJFZjQSojre",
          "isoAlpha3": "CHE",
          "isoNumeric": "756",
          "languages": "de-CH,fr-CH,it-CH,rm",
          "name": "Switzerland",
          "north": 47.805332,
          "population": "7581000",
          "south": 45.825695,
          "updatedAt": "2015-12-17T13:18:02.504Z",
          "west": 5.957472
        },
        {
          "_id": "N1fhjQSiiBg",
          "areaInSqKm": "185180.0",
          "capital": "Damascus",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "SY",
          "countryName": "Syria",
          "createdAt": "2015-12-17T13:18:02.589Z",
          "currencyCode": "SYP",
          "east": 42.385029,
          "fipsCode": "SY",
          "geonameId": "163843",
          "id": "N1fhjQSiiBg",
          "isoAlpha3": "SYR",
          "isoNumeric": "760",
          "languages": "ar-SY,ku,hy,arc,fr,en",
          "name": "Syria",
          "north": 37.319138,
          "population": "22198110",
          "south": 32.310665,
          "updatedAt": "2015-12-17T13:18:02.589Z",
          "west": 35.727222
        },
        {
          "_id": "NyJnjXBsoSg",
          "areaInSqKm": "1001.0",
          "capital": "São Tomé",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "ST",
          "countryName": "São Tomé and Príncipe",
          "createdAt": "2015-12-17T13:18:02.588Z",
          "currencyCode": "STD",
          "east": 7.466374,
          "fipsCode": "TP",
          "geonameId": "2410758",
          "id": "NyJnjXBsoSg",
          "isoAlpha3": "STP",
          "isoNumeric": "678",
          "languages": "pt-ST",
          "name": "São Tomé and Príncipe",
          "north": 1.701323,
          "population": "175808",
          "south": 0.024766,
          "updatedAt": "2015-12-17T13:18:02.588Z",
          "west": 6.47017
        },
        {
          "_id": "NkaFsQBiiSg",
          "areaInSqKm": "4167.0",
          "capital": "Papeete",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "PF",
          "countryName": "Tahiti (French Polynesia)",
          "createdAt": "2015-12-17T13:18:02.569Z",
          "currencyCode": "XPF",
          "east": -134.929825,
          "fipsCode": "FP",
          "geonameId": "4030656",
          "id": "NkaFsQBiiSg",
          "isoAlpha3": "PYF",
          "isoNumeric": "258",
          "languages": "fr-PF,ty",
          "name": "Tahiti (French Polynesia)",
          "north": -7.903573,
          "population": "270485",
          "south": -27.653572,
          "updatedAt": "2015-12-17T13:18:02.569Z",
          "west": -152.877167
        },
        {
          "_id": "VkzpsQBssBe",
          "areaInSqKm": "35980.0",
          "capital": "Taipei",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "TW",
          "countryName": "Taiwan",
          "createdAt": "2015-12-17T13:18:02.594Z",
          "currencyCode": "TWD",
          "east": 122.006739823315,
          "fipsCode": "TW",
          "geonameId": "1668284",
          "id": "VkzpsQBssBe",
          "isoAlpha3": "TWN",
          "isoNumeric": "158",
          "languages": "zh-TW,zh,nan,hak",
          "name": "Taiwan",
          "north": 25.3002899036181,
          "population": "22894384",
          "south": 21.896606934717,
          "updatedAt": "2015-12-17T13:18:02.594Z",
          "west": 119.534691
        },
        {
          "_id": "N1Yni7BojHe",
          "areaInSqKm": "143100.0",
          "capital": "Dushanbe",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "TJ",
          "countryName": "Tajikistan",
          "createdAt": "2015-12-17T13:18:02.591Z",
          "currencyCode": "TJS",
          "east": 75.137222,
          "fipsCode": "TI",
          "geonameId": "1220409",
          "id": "N1Yni7BojHe",
          "isoAlpha3": "TJK",
          "isoNumeric": "762",
          "languages": "tg,ru",
          "name": "Tajikistan",
          "north": 41.042252,
          "population": "7487489",
          "south": 36.674137,
          "updatedAt": "2015-12-17T13:18:02.591Z",
          "west": 67.387138
        },
        {
          "_id": "VyQas7HjsBl",
          "areaInSqKm": "945087.0",
          "capital": "Dodoma",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "TZ",
          "countryName": "Tanzania",
          "createdAt": "2015-12-17T13:18:02.595Z",
          "currencyCode": "TZS",
          "east": 40.443222,
          "fipsCode": "TZ",
          "geonameId": "149590",
          "id": "VyQas7HjsBl",
          "isoAlpha3": "TZA",
          "isoNumeric": "834",
          "languages": "sw-TZ,en,ar",
          "name": "Tanzania",
          "north": -0.990736,
          "population": "41892895",
          "south": -11.745696,
          "updatedAt": "2015-12-17T13:18:02.595Z",
          "west": 29.327168
        },
        {
          "_id": "41dhoXrisHl",
          "areaInSqKm": "514000.0",
          "capital": "Bangkok",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "TH",
          "countryName": "Thailand",
          "createdAt": "2015-12-17T13:18:02.591Z",
          "currencyCode": "THB",
          "east": 105.639389,
          "fipsCode": "TH",
          "geonameId": "1605651",
          "id": "41dhoXrisHl",
          "isoAlpha3": "THA",
          "isoNumeric": "764",
          "languages": "th,en",
          "name": "Thailand",
          "north": 20.463194,
          "population": "67089500",
          "south": 5.61,
          "updatedAt": "2015-12-17T13:18:02.591Z",
          "west": 97.345642
        },
        {
          "_id": "5d665938b233ee87e1c5c3a8",
          "areaInSqKm": "15007.0",
          "capital": "Dili",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "TLS",
          "countryName": "Timor Leste",
          "currencyCode": "USD",
          "id": "5d665938b233ee87e1c5c3a8",
          "isoNumeric": "670",
          "languages": "pt",
          "name": "Timor Leste",
          "population": "1167242"
        },
        {
          "_id": "EkP3jQSojSx",
          "areaInSqKm": "56785.0",
          "capital": "Lomé",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "TG",
          "countryName": "Togo",
          "createdAt": "2015-12-17T13:18:02.591Z",
          "currencyCode": "XOF",
          "east": 1.806693,
          "fipsCode": "TO",
          "geonameId": "2363686",
          "id": "EkP3jQSojSx",
          "isoAlpha3": "TGO",
          "isoNumeric": "768",
          "languages": "fr-TG,ee,hna,kbp,dag,ha",
          "name": "Togo",
          "north": 11.138977,
          "population": "6587239",
          "south": 6.104417,
          "updatedAt": "2015-12-17T13:18:02.591Z",
          "west": -0.147324
        },
        {
          "_id": "V1qns7HisSe",
          "areaInSqKm": "10.0",
          "capital": "",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "TK",
          "countryName": "Tokelau",
          "createdAt": "2015-12-17T13:18:02.592Z",
          "currencyCode": "NZD",
          "east": -171.21142578125,
          "fipsCode": "TL",
          "geonameId": "4031074",
          "id": "V1qns7HisSe",
          "isoAlpha3": "TKL",
          "isoNumeric": "772",
          "languages": "tkl,en-TK",
          "name": "Tokelau",
          "north": -8.553613662719727,
          "population": "1466",
          "south": -9.381111145019531,
          "updatedAt": "2015-12-17T13:18:02.592Z",
          "west": -172.5003356933594
        },
        {
          "_id": "NJChjmrisHx",
          "areaInSqKm": "748.0",
          "capital": "Nuku'alofa",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "TO",
          "countryName": "Tonga",
          "createdAt": "2015-12-17T13:18:02.593Z",
          "currencyCode": "TOP",
          "east": -173.907578,
          "fipsCode": "TN",
          "geonameId": "4032283",
          "id": "NJChjmrisHx",
          "isoAlpha3": "TON",
          "isoNumeric": "776",
          "languages": "to,en-TO",
          "name": "Tonga",
          "north": -15.562988,
          "population": "122580",
          "south": -21.455057,
          "updatedAt": "2015-12-17T13:18:02.593Z",
          "west": -175.682266
        },
        {
          "_id": "VJgasmBsire",
          "areaInSqKm": "5128.0",
          "capital": "Port of Spain",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "TT",
          "countryName": "Trinidad and Tobago",
          "createdAt": "2015-12-17T13:18:02.594Z",
          "currencyCode": "TTD",
          "east": -60.517933,
          "fipsCode": "TD",
          "geonameId": "3573591",
          "id": "VJgasmBsire",
          "isoAlpha3": "TTO",
          "isoNumeric": "780",
          "languages": "en-TT,hns,fr,es,zh",
          "name": "Trinidad and Tobago",
          "north": 11.338342,
          "population": "1228691",
          "south": 10.036105,
          "updatedAt": "2015-12-17T13:18:02.594Z",
          "west": -61.923771
        },
        {
          "_id": "41animBiirx",
          "areaInSqKm": "163610.0",
          "capital": "Tunis",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "TN",
          "countryName": "Tunisia",
          "createdAt": "2015-12-17T13:18:02.593Z",
          "currencyCode": "TND",
          "east": 11.598278,
          "fipsCode": "TS",
          "geonameId": "2464461",
          "id": "41animBiirx",
          "isoAlpha3": "TUN",
          "isoNumeric": "788",
          "languages": "ar-TN,fr",
          "name": "Tunisia",
          "north": 37.543915,
          "population": "10589025",
          "south": 30.240417,
          "updatedAt": "2015-12-17T13:18:02.593Z",
          "west": 7.524833
        },
        {
          "_id": "4kkpiQSsire",
          "areaInSqKm": "780580.0",
          "capital": "Ankara",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "TR",
          "countryName": "Turkey",
          "createdAt": "2015-12-17T13:18:02.593Z",
          "currencyCode": "TRY",
          "east": 44.834999,
          "fipsCode": "TU",
          "geonameId": "298795",
          "id": "4kkpiQSsire",
          "isoAlpha3": "TUR",
          "isoNumeric": "792",
          "languages": "tr-TR,ku,diq,az,av",
          "name": "Turkey",
          "north": 42.107613,
          "population": "77804122",
          "south": 35.815418,
          "updatedAt": "2015-12-17T13:18:02.593Z",
          "west": 25.668501
        },
        {
          "_id": "413hi7SojSl",
          "areaInSqKm": "488100.0",
          "capital": "Ashgabat",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "TM",
          "countryName": "Turkmenistan",
          "createdAt": "2015-12-17T13:18:02.592Z",
          "currencyCode": "TMT",
          "east": 66.684303,
          "fipsCode": "TX",
          "geonameId": "1218197",
          "id": "413hi7SojSl",
          "isoAlpha3": "TKM",
          "isoNumeric": "795",
          "languages": "tk,ru,uz",
          "name": "Turkmenistan",
          "north": 42.795555,
          "population": "4940916",
          "south": 35.141083,
          "updatedAt": "2015-12-17T13:18:02.592Z",
          "west": 52.441444
        },
        {
          "_id": "NJE2jQriirl",
          "areaInSqKm": "430.0",
          "capital": "Cockburn Town",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "TC",
          "countryName": "Turks and Caicos Islands",
          "createdAt": "2015-12-17T13:18:02.59Z",
          "currencyCode": "USD",
          "east": -71.123642,
          "fipsCode": "TK",
          "geonameId": "3576916",
          "id": "NJE2jQriirl",
          "isoAlpha3": "TCA",
          "isoNumeric": "796",
          "languages": "en-TC",
          "name": "Turks and Caicos Islands",
          "north": 21.961878,
          "population": "20556",
          "south": 21.422626,
          "updatedAt": "2015-12-17T13:18:02.59Z",
          "west": -72.483871
        },
        {
          "_id": "EkWpoQHjsSe",
          "areaInSqKm": "26.0",
          "capital": "Funafuti",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "TV",
          "countryName": "Tuvalu",
          "createdAt": "2015-12-17T13:18:02.594Z",
          "currencyCode": "AUD",
          "east": 179.863281,
          "fipsCode": "TV",
          "geonameId": "2110297",
          "id": "EkWpoQHjsSe",
          "isoAlpha3": "TUV",
          "isoNumeric": "798",
          "languages": "tvl,en,sm,gil",
          "name": "Tuvalu",
          "north": -5.641972,
          "population": "10472",
          "south": -10.801169,
          "updatedAt": "2015-12-17T13:18:02.594Z",
          "west": 176.064865
        },
        {
          "_id": "NJLTsXrisrg",
          "areaInSqKm": "0.0",
          "capital": "",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "UM",
          "countryName": "U.S. Minor Outlying Islands",
          "createdAt": "2015-12-17T13:18:02.596Z",
          "currencyCode": "USD",
          "east": 166.654526,
          "fipsCode": "",
          "geonameId": "5854968",
          "id": "NJLTsXrisrg",
          "isoAlpha3": "UMI",
          "isoNumeric": "581",
          "languages": "en-UM",
          "name": "U.S. Minor Outlying Islands",
          "north": 28.219814,
          "population": "0",
          "south": -0.389006,
          "updatedAt": "2015-12-17T13:18:02.596Z",
          "west": -177.392029
        },
        {
          "_id": "41Apo7SoiSg",
          "areaInSqKm": "352.0",
          "capital": "Charlotte Amalie",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "VI",
          "countryName": "U.S. Virgin Islands",
          "createdAt": "2015-12-17T13:18:02.599Z",
          "currencyCode": "USD",
          "east": -64.565193,
          "fipsCode": "VQ",
          "geonameId": "4796775",
          "id": "41Apo7SoiSg",
          "isoAlpha3": "VIR",
          "isoNumeric": "850",
          "languages": "en-VI",
          "name": "U.S. Virgin Islands",
          "north": 18.415382,
          "population": "108708",
          "south": 17.673931,
          "updatedAt": "2015-12-17T13:18:02.599Z",
          "west": -65.101333
        },
        {
          "_id": "41r6oQBiiSl",
          "areaInSqKm": "236040.0",
          "capital": "Kampala",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "UG",
          "countryName": "Uganda",
          "createdAt": "2015-12-17T13:18:02.595Z",
          "currencyCode": "UGX",
          "east": 35.036049,
          "fipsCode": "UG",
          "geonameId": "226074",
          "id": "41r6oQBiiSl",
          "isoAlpha3": "UGA",
          "isoNumeric": "800",
          "languages": "en-UG,lg,sw,ar",
          "name": "Uganda",
          "north": 4.214427,
          "population": "33398682",
          "south": -1.48405,
          "updatedAt": "2015-12-17T13:18:02.595Z",
          "west": 29.573252
        },
        {
          "_id": "NkVas7SioSe",
          "areaInSqKm": "603700.0",
          "capital": "Kiev",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "UA",
          "countryName": "Ukraine",
          "createdAt": "2015-12-17T13:18:02.595Z",
          "currencyCode": "UAH",
          "east": 40.20739,
          "fipsCode": "UP",
          "geonameId": "690791",
          "id": "NkVas7SioSe",
          "isoAlpha3": "UKR",
          "isoNumeric": "804",
          "languages": "uk,ru-UA,rom,pl,hu",
          "name": "Ukraine",
          "north": 52.369362,
          "population": "45415596",
          "south": 44.390415,
          "updatedAt": "2015-12-17T13:18:02.595Z",
          "west": 22.128889
        },
        {
          "_id": "4ygjmHjiSe",
          "areaInSqKm": "82880.0",
          "capital": "Abu Dhabi",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "AE",
          "countryName": "United Arab Emirates",
          "createdAt": "2015-12-17T13:18:02.476Z",
          "currencyCode": "AED",
          "east": 56.38166046142578,
          "fipsCode": "AE",
          "geonameId": "290557",
          "id": "4ygjmHjiSe",
          "isoAlpha3": "ARE",
          "isoNumeric": "784",
          "languages": "ar-AE,fa,en,hi,ur",
          "name": "United Arab Emirates",
          "north": 26.08415985107422,
          "population": "4975593",
          "south": 22.63332939147949,
          "updatedAt": "2015-12-17T13:18:02.476Z",
          "west": 51.58332824707031
        },
        {
          "_id": "Nyo7oQSjsHe",
          "areaInSqKm": "244820.0",
          "capital": "London",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "GB",
          "countryName": "United Kingdom",
          "createdAt": "2015-12-17T13:18:02.523Z",
          "currencyCode": "GBP",
          "east": 1.759,
          "fipsCode": "UK",
          "geonameId": "2635167",
          "id": "Nyo7oQSjsHe",
          "isoAlpha3": "GBR",
          "isoNumeric": "826",
          "languages": "en-GB,cy-GB,gd",
          "name": "United Kingdom",
          "north": 59.360249,
          "population": "62348447",
          "south": 49.906193,
          "updatedAt": "2015-12-17T13:18:02.523Z",
          "west": -8.623555
        },
        {
          "_id": "4yD6sQSoore",
          "areaInSqKm": "9629091.0",
          "capital": "Washington",
          "companyID": "TECTORO",
          "continent": "NA",
          "continentName": "North America",
          "countryCode": "US",
          "countryName": "United States",
          "createdAt": "2015-12-17T13:18:02.596Z",
          "currencyCode": "USD",
          "east": -66.954811,
          "fipsCode": "US",
          "geonameId": "6252001",
          "id": "4yD6sQSoore",
          "isoAlpha3": "USA",
          "isoNumeric": "840",
          "languages": "en-US,es-US,haw,fr",
          "name": "United States",
          "north": 49.388611,
          "population": "310232863",
          "south": 24.544245,
          "updatedAt": "2015-12-17T13:18:02.596Z",
          "west": -124.733253
        },
        {
          "_id": "41dTiXrsjHg",
          "areaInSqKm": "176220.0",
          "capital": "Montevideo",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "UY",
          "countryName": "Uruguay",
          "createdAt": "2015-12-17T13:18:02.596Z",
          "currencyCode": "UYU",
          "east": -53.073933,
          "fipsCode": "UY",
          "geonameId": "3439705",
          "id": "41dTiXrsjHg",
          "isoAlpha3": "URY",
          "isoNumeric": "858",
          "languages": "es-UY",
          "name": "Uruguay",
          "north": -30.082224,
          "population": "3477000",
          "south": -34.980816,
          "updatedAt": "2015-12-17T13:18:02.596Z",
          "west": -58.442722
        },
        {
          "_id": "V1FpiXBisBg",
          "areaInSqKm": "447400.0",
          "capital": "Tashkent",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "UZ",
          "countryName": "Uzbekistan",
          "createdAt": "2015-12-17T13:18:02.597Z",
          "currencyCode": "UZS",
          "east": 73.132278,
          "fipsCode": "UZ",
          "geonameId": "1512440",
          "id": "V1FpiXBisBg",
          "isoAlpha3": "UZB",
          "isoNumeric": "860",
          "languages": "uz,ru,tg",
          "name": "Uzbekistan",
          "north": 45.575001,
          "population": "27865738",
          "south": 37.184444,
          "updatedAt": "2015-12-17T13:18:02.597Z",
          "west": 55.996639
        },
        {
          "_id": "Vkg0s7BooSl",
          "areaInSqKm": "12200.0",
          "capital": "Port Vila",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "VU",
          "countryName": "Vanuatu",
          "createdAt": "2015-12-17T13:18:02.6Z",
          "currencyCode": "VUV",
          "east": 169.904785,
          "fipsCode": "NH",
          "geonameId": "2134431",
          "id": "Vkg0s7BooSl",
          "isoAlpha3": "VUT",
          "isoNumeric": "548",
          "languages": "bi,en-VU,fr-VU",
          "name": "Vanuatu",
          "north": -13.073444,
          "population": "221552",
          "south": -20.248945,
          "updatedAt": "2015-12-17T13:18:02.6Z",
          "west": 166.524979
        },
        {
          "_id": "N15aimHojBe",
          "areaInSqKm": "0.44",
          "capital": "Vatican City",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "VA",
          "countryName": "Vatican City",
          "createdAt": "2015-12-17T13:18:02.597Z",
          "currencyCode": "EUR",
          "east": 12.45837546629481,
          "fipsCode": "VT",
          "geonameId": "3164670",
          "id": "N15aimHojBe",
          "isoAlpha3": "VAT",
          "isoNumeric": "336",
          "languages": "la,it,fr",
          "name": "Vatican City",
          "north": 41.90743830885576,
          "population": "921",
          "south": 41.90027960306854,
          "updatedAt": "2015-12-17T13:18:02.597Z",
          "west": 12.44570678169205
        },
        {
          "_id": "VynTi7BsjBx",
          "areaInSqKm": "912050.0",
          "capital": "Caracas",
          "companyID": "TECTORO",
          "continent": "SA",
          "continentName": "South America",
          "countryCode": "VE",
          "countryName": "Venezuela",
          "createdAt": "2015-12-17T13:18:02.598Z",
          "currencyCode": "VEF",
          "east": -59.80378,
          "fipsCode": "VE",
          "geonameId": "3625428",
          "id": "VynTi7BsjBx",
          "isoAlpha3": "VEN",
          "isoNumeric": "862",
          "languages": "es-VE",
          "name": "Venezuela",
          "north": 12.201903,
          "population": "27223228",
          "south": 0.626311,
          "updatedAt": "2015-12-17T13:18:02.598Z",
          "west": -73.354073
        },
        {
          "_id": "41JCi7rjjSx",
          "areaInSqKm": "329560.0",
          "capital": "Hanoi",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "VN",
          "countryName": "Vietnam",
          "createdAt": "2015-12-17T13:18:02.6Z",
          "currencyCode": "VND",
          "east": 109.464638,
          "fipsCode": "VM",
          "geonameId": "1562822",
          "id": "41JCi7rjjSx",
          "isoAlpha3": "VNM",
          "isoNumeric": "704",
          "languages": "vi,en,fr,zh,km",
          "name": "Vietnam",
          "north": 23.388834,
          "population": "89571130",
          "south": 8.559611,
          "updatedAt": "2015-12-17T13:18:02.6Z",
          "west": 102.148224
        },
        {
          "_id": "VyZ0s7Ssirl",
          "areaInSqKm": "274.0",
          "capital": "Mata-Utu",
          "companyID": "TECTORO",
          "continent": "OC",
          "continentName": "Oceania",
          "countryCode": "WF",
          "countryName": "Wallis and Futuna",
          "createdAt": "2015-12-17T13:18:02.601Z",
          "currencyCode": "XPF",
          "east": -176.1617431771825,
          "fipsCode": "WF",
          "geonameId": "4034749",
          "id": "VyZ0s7Ssirl",
          "isoAlpha3": "WLF",
          "isoNumeric": "876",
          "languages": "wls,fud,fr-WF",
          "name": "Wallis and Futuna",
          "north": -13.21675818106144,
          "population": "16025",
          "south": -14.31455998982084,
          "updatedAt": "2015-12-17T13:18:02.601Z",
          "west": -178.1848112896414
        },
        {
          "_id": "5d3803a1cc9d6488e9fc6286",
          "areaInSqKm": "",
          "capital": "Ramallah",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "PLE",
          "countryName": "West Bank \u0026 Gaza ",
          "createdAt": "2015-12-17T13:18:02.534Z",
          "currencyCode": "ILS",
          "id": "5d3803a1cc9d6488e9fc6286",
          "isoAlpha3": "GGY",
          "isoNumeric": "275",
          "languages": "ara",
          "name": "West Bank \u0026 Gaza ",
          "population": "4682467",
          "updatedAt": "2015-12-17T13:18:02.534Z"
        },
        {
          "_id": "Nygmo7Sisrl",
          "areaInSqKm": "266000.0",
          "capital": "Laâyoune / El Aaiún",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "EH",
          "countryName": "Western Sahara",
          "createdAt": "2015-12-17T13:18:02.516Z",
          "currencyCode": "MAD",
          "east": -8.670276,
          "fipsCode": "WI",
          "geonameId": "2461445",
          "id": "Nygmo7Sisrl",
          "isoAlpha3": "ESH",
          "isoNumeric": "732",
          "languages": "ar,mey",
          "name": "Western Sahara",
          "north": 27.669674,
          "population": "273008",
          "south": 20.774158,
          "updatedAt": "2015-12-17T13:18:02.516Z",
          "west": -17.103182
        },
        {
          "_id": "Ey4AjmBjoSx",
          "areaInSqKm": "527970.0",
          "capital": "Sanaa",
          "companyID": "TECTORO",
          "continent": "AS",
          "continentName": "Asia",
          "countryCode": "YE",
          "countryName": "Yemen",
          "createdAt": "2015-12-17T13:18:02.602Z",
          "currencyCode": "YER",
          "east": 54.5305388163283,
          "fipsCode": "YM",
          "geonameId": "69543",
          "id": "Ey4AjmBjoSx",
          "isoAlpha3": "YEM",
          "isoNumeric": "887",
          "languages": "ar-YE",
          "name": "Yemen",
          "north": 18.9999989031009,
          "population": "23495361",
          "south": 12.1110910264462,
          "updatedAt": "2015-12-17T13:18:02.602Z",
          "west": 42.5325394314234
        },
        {
          "_id": "VkvCo7SoiSx",
          "areaInSqKm": "752614.0",
          "capital": "Lusaka",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "ZM",
          "countryName": "Zambia",
          "createdAt": "2015-12-17T13:18:02.603Z",
          "currencyCode": "ZMW",
          "east": 33.705704,
          "fipsCode": "ZA",
          "geonameId": "895949",
          "id": "VkvCo7SoiSx",
          "isoAlpha3": "ZMB",
          "isoNumeric": "894",
          "languages": "en-ZM,bem,loz,lun,lue,ny,toi",
          "name": "Zambia",
          "north": -8.22436,
          "population": "13460305",
          "south": -18.079473,
          "updatedAt": "2015-12-17T13:18:02.603Z",
          "west": 21.999371
        },
        {
          "_id": "EydRiXBjjSl",
          "areaInSqKm": "390580.0",
          "capital": "Harare",
          "companyID": "TECTORO",
          "continent": "AF",
          "continentName": "Africa",
          "countryCode": "ZW",
          "countryName": "Zimbabwe",
          "createdAt": "2015-12-17T13:18:02.603Z",
          "currencyCode": "ZWL",
          "east": 33.056305,
          "fipsCode": "ZI",
          "geonameId": "878675",
          "id": "EydRiXBjjSl",
          "isoAlpha3": "ZWE",
          "isoNumeric": "716",
          "languages": "en-ZW,sn,nr,nd",
          "name": "Zimbabwe",
          "north": -15.608835,
          "population": "11651858",
          "south": -22.417738,
          "updatedAt": "2015-12-17T13:18:02.603Z",
          "west": 25.237028
        },
        {
          "_id": "N16sQSojrx",
          "areaInSqKm": "1580.0",
          "capital": "Mariehamn",
          "companyID": "TECTORO",
          "continent": "EU",
          "continentName": "Europe",
          "countryCode": "AX",
          "countryName": "Åland",
          "createdAt": "2015-12-17T13:18:02.489Z",
          "currencyCode": "EUR",
          "east": 21.011862,
          "fipsCode": "",
          "geonameId": "661882",
          "id": "N16sQSojrx",
          "isoAlpha3": "ALA",
          "isoNumeric": "248",
          "languages": "sv-AX",
          "name": "Åland",
          "north": 60.488861,
          "population": "26711",
          "south": 59.90675,
          "updatedAt": "2015-12-17T13:18:02.489Z",
          "west": 19.317694
        }
      ],
      "status": "success",
      "total": 1,
      "totalRecords": 260
    }
  }
  getEnums() {
    return {
      "data": [
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "CYM",
          "code_desc": "Cayman Islands(CYM)",
          "id": "716A8167-AFD4-4B61-A62B-3E0EF147E85B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T06:26:03.8323832Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "HK",
          "code_desc": "Hong Kong(HK)",
          "id": "417A29DC-6AAC-479C-A02A-23DE2B2BDA27",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T10:21:48.1781189Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "HKD",
          "code_desc": "HKD",
          "id": "6738CC78-17E9-4F06-94E6-A69BC4DDFA93",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-08-22T10:48:00.5376193Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "LUX",
          "code_desc": "Luxembourg(LUX)",
          "id": "AF210980-0D0B-4DBA-A9A7-F5BB64E89A9B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T10:27:38.4648984Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "SG",
          "code_desc": "Singapore(SG)",
          "id": "D4E8F539-11C7-4104-8128-2259B78193E3",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T10:23:03.5620517Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "US",
          "code_desc": "US",
          "id": "B5D9F72C-074B-415A-895D-ED19A35A5D2D",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:18:51.7153098Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "OPS_CAL_CODES",
          "category_desc": "Ops Calendar Codes",
          "code": "HKO",
          "code_desc": "HK Ops Team Calendar",
          "id": "980692D3-7DC4-4B4E-A95A-79E01750B3B1",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-01-04T07:51:59.8086499Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "CN",
          "code_desc": "CN",
          "id": "F7506029-0FC7-4701-95F0-3209AC2D8A04",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:54:22.3417065Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "HK",
          "code_desc": "HK",
          "id": "192B99F5-AF57-4564-957D-2F7385A47A23",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T08:52:10.8241609Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "HKSC",
          "code_desc": "Hong Kong Stock Connect Market",
          "id": "1294D328-3A7C-4D39-8A57-710116B6446C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T08:51:58.2468583Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "SG",
          "code_desc": "SG",
          "id": "7562DC71-581D-4BEA-B924-AAB6A12631A5",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-10T09:26:27.1846152Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "US",
          "code_desc": "US",
          "id": "6948EF94-4C2D-41E4-8D8A-FBFBD2A18FA1",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:20:47.796745Z",
          "version_user": "OPSMANAGER"
        }
      ],
      "status": "success"
    }
  }
  getTickerReferences() {
    return {
      "data": [
        {
          "class_desc": null,
          "class_type": "TICKER_REF",
          "code": "8IGHT",
          "code_desc": "8ight Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:54:26.2423052Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "TICKER_REF",
          "code": "EXCH",
          "code_desc": "Exchange Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-03-30T11:10:51.9865382Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "TICKER_REF",
          "code": "ISIN",
          "code_desc": "ISIN Ref",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:52:43.4845078Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "TICKER_REF",
          "code": "REUT",
          "code_desc": "Reuter Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:53:44.6619172Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "TICKER_REF",
          "code": "SEDOL",
          "code_desc": "Sedol Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:53:01.1827425Z",
          "version_user": "RISK"
        }
      ],
      "status": "success"
    }
  }

  getCodes() {
    return {
      "data": [
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "BOND",
          "code_desc": "Bond",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-04-04T06:46:02.5190544Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "CMDTY",
          "code_desc": "Commodity",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-04-03T09:45:35.0997997Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "DERV",
          "code_desc": "Derivative",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-15T10:55:22.2751747Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "EQTY",
          "code_desc": "Equity",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:57:13.2579399Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "INDX",
          "code_desc": "Index",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076866Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": null,
          "class_type": "ASSET_CLASS",
          "code": "MM",
          "code_desc": "Money Market",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076863Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": null,
          "class_type": "DIVI_FREQ",
          "code": "HALF_YEAR",
          "code_desc": "Half Yearly",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:26:11.6940656Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "DIVI_FREQ",
          "code": "QUARTER",
          "code_desc": "Quarterly",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:25:38.8697405Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "DIVI_FREQ",
          "code": "YEAR",
          "code_desc": "Yearly",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:25:56.0199658Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "INVST_STRATEGY",
          "code": "INVST_STRATEGY\t",
          "code_desc": "Passive",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-04-03T09:43:03.836763Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "INVST_STRATEGY",
          "code": "INVST_STRATEGY",
          "code_desc": "Replication Strategy",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-03-17T08:52:34.9192048Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "INVST_STRATEGY",
          "code": "RI",
          "code_desc": "Replica Index",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-03-17T11:18:28.7239029Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "INVST_STRATEGY",
          "code": "RSS",
          "code_desc": "Representative Sampling Strategy",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-03-17T11:18:06.7011204Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "NAV1_SC_SLA",
          "code": "09:02",
          "code_desc": "09:02",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-04-14T09:09:05.8806944Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "NAV2_SC_SLA",
          "code": "16:29",
          "code_desc": "16:29",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-04-14T09:10:48.6839181Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "BBGTICKER",
          "code_desc": "BBG Ticker",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-17T07:21:31.6803584Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "BIC",
          "code_desc": "BIC Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:50:35.7376096Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "CDSREF",
          "code_desc": "CDS BBG Ticker",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-23T06:13:25.2570038Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "CREDIT_RATING_ISIN",
          "code_desc": "Credit Rating ISIN",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-23T06:16:01.5848682Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "CUST",
          "code_desc": "Custodian Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T06:00:56.3656017Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "FLEX",
          "code_desc": "Flex System Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:56:52.0301714Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "INT",
          "code_desc": "Internal Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:50:06.5343032Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "PMA_ACCT_ID",
          "code_desc": "PMA Account ID",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-23T06:07:23.7734862Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "PMA_CUST",
          "code_desc": "PMA Custodian Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T11:50:24.1658652Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "TRADAR_CODE",
          "code_desc": "Tradar Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T06:02:25.8486552Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PRODUCT_TYPE",
          "code": "ADVA",
          "code_desc": "Advisory Account",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-13T06:45:47.4303956Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PRODUCT_TYPE",
          "code": "ETF",
          "code_desc": "ETF",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-15T10:11:30.8815889Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PRODUCT_TYPE",
          "code": "LI",
          "code_desc": "L\u0026I",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-11T05:45:15.0119491Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PRODUCT_TYPE",
          "code": "MF",
          "code_desc": "Mutual Fund",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-15T10:11:50.5474059Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PRODUCT_TYPE",
          "code": "PF",
          "code_desc": "Private Fund",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-13T06:45:27.6743179Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "SLA",
          "code": "T0",
          "code_desc": "T0",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T11:17:23.7786573Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "SLA",
          "code": "T1",
          "code_desc": "T1",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T11:17:33.1802068Z",
          "version_user": "RISK"
        }
      ],
      "status": "success"
    }
  }
  getMMs() {
    return [
      {
        "PartyCategory": "MM",
        "PartyID": "MM904",
        "PartyNameInChinese": "安联资本投资有限公司",
        "PartyNameInEnglish": "AP Capital Investment Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM905",
        "PartyNameInChinese": "安联资本管理（香港）有限公司",
        "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM497",
        "PartyNameInChinese": "Bluefin HK Limited,",
        "PartyNameInEnglish": "Bluefin HK Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM498",
        "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
        "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM499",
        "PartyNameInChinese": "中國國際金融香港證券有限公司,",
        "PartyNameInEnglish": "China International Capital Corporation Hong Kong Securities Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM500",
        "PartyNameInChinese": "Citadel Securities (Hong Kong) Limited",
        "PartyNameInEnglish": "Citadel Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM501",
        "PartyNameInChinese": "Citadel Securities Arbitrage Trading Limited",
        "PartyNameInEnglish": "Citadel Securities Arbitrage Trading Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM502",
        "PartyNameInChinese": "CLSA Singapore Pte Ltd",
        "PartyNameInEnglish": "CLSA Singapore Pte Ltd"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM503",
        "PartyNameInChinese": "DRW Singapore Pte. Ltd",
        "PartyNameInEnglish": "DRW Singapore Pte. Ltd"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM504",
        "PartyNameInChinese": "Eclipse Options (HK) Limited",
        "PartyNameInEnglish": "Eclipse Options (HK) Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM505",
        "PartyNameInChinese": "Flow Traders Asia Pte Ltd",
        "PartyNameInEnglish": "Flow Traders Asia Pte Ltd"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM506",
        "PartyNameInChinese": "Flow Traders Hong Kong Limited",
        "PartyNameInEnglish": "Flow Traders Hong Kong Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM507",
        "PartyNameInChinese": "Guotai Junan Investments (Hong Kong) Limited",
        "PartyNameInEnglish": "Guotai Junan Investments (Hong Kong) Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM508",
        "PartyNameInChinese": "海通國際證券有限公司",
        "PartyNameInEnglish": "Haitong International Securities Company Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM509",
        "PartyNameInChinese": "聯合證券有限公司",
        "PartyNameInEnglish": "Head \u0026 Shoulders Securities Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM510",
        "PartyNameInChinese": "滙豐證券經紀(亞洲)有限公司",
        "PartyNameInEnglish": "HSBC Securities Brokers (Asia) Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM511",
        "PartyNameInChinese": "IMC Asia Pacific Limited",
        "PartyNameInEnglish": "IMC Asia Pacific Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM512",
        "PartyNameInChinese": "盈透證券香港有限公司",
        "PartyNameInEnglish": "Interactive Brokers Hong Kong Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM513",
        "PartyNameInChinese": "Jane Street Financial Limited",
        "PartyNameInEnglish": "Jane Street Financial Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM514",
        "PartyNameInChinese": "Jump Trading Pacific Pte. Ltd",
        "PartyNameInEnglish": "Jump Trading Pacific Pte. Ltd"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM515",
        "PartyNameInChinese": "凱基證券亞洲有限公司",
        "PartyNameInEnglish": "KGI Asia Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM516",
        "PartyNameInChinese": "韓國投資證券亞洲有限公司",
        "PartyNameInEnglish": "Korea Investment \u0026 Securities Asia Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM517",
        "PartyNameInChinese": "未來資產證券(香港)有限公司",
        "PartyNameInEnglish": "Mirae Asset Securities (HK) Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM518",
        "PartyNameInChinese": "Optiver Trading Hong Kong Limited",
        "PartyNameInEnglish": "Optiver Trading Hong Kong Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM519",
        "PartyNameInChinese": "Phillip Securities Pte Ltd",
        "PartyNameInEnglish": "Phillip Securities Pte Ltd"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM520",
        "PartyNameInChinese": "法國興業證券(香港)有限公司",
        "PartyNameInEnglish": "SG Securities (HK) Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM521",
        "PartyNameInChinese": "華盛資本証券有限公司",
        "PartyNameInEnglish": "Valuable Capital Limited"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM522",
        "PartyNameInChinese": "Virtu Financial Singapore PTE. Ltd",
        "PartyNameInEnglish": "Virtu Financial Singapore PTE. Ltd"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM523",
        "PartyNameInChinese": "Vivienne Court Trading Pty. Ltd",
        "PartyNameInEnglish": "Vivienne Court Trading Pty. Ltd"
      },
      {
        "PartyCategory": "MM",
        "PartyID": "MM524",
        "PartyNameInChinese": "躍鯤研發有限公司",
        "PartyNameInEnglish": "Yue Kun Research Limited"
      }
    ]
  }
  getManager() {
    return [
      {
        "PartyCategory": "FMGR",
        "PartyID": "756",
        "PartyNameInChinese": "南方光电",
        "PartyNameInEnglish": "CSOP"
      },
      {
        "PartyCategory": "FMGR",
        "PartyID": "746",
        "PartyNameInChinese": "南方東英資産管理有限公司",
        "PartyNameInEnglish": "CSOP Asset Management Limited"
      },
      {
        "PartyCategory": "FMGR",
        "PartyID": "FM890",
        "PartyNameInChinese": "滙豐證券經紀(亞洲)有限公司",
        "PartyNameInEnglish": "Fund Manager"
      },
      {
        "PartyCategory": "FMGR",
        "PartyID": "769",
        "PartyNameInChinese": "匯豐銀行(中國)有限公司",
        "PartyNameInEnglish": "Hong Kong Unit Trust"
      },
      {
        "PartyCategory": "FMGR",
        "PartyID": "763",
        "PartyNameInChinese": "csc_TEST",
        "PartyNameInEnglish": "Manager1"
      }
    ]
  }
  getPortfolioManager() {
    return [
      {
        "PartyCategory": "PMGR",
        "PartyID": "757",
        "PartyNameInChinese": "科特斯瓦拉朗",
        "PartyNameInEnglish": "Koteswararao "
      },
      {
        "PartyCategory": "PMGR",
        "PartyID": "PM805",
        "PartyNameInChinese": "科特斯瓦拉朗佩鲁穆拉",
        "PartyNameInEnglish": "Koteswararao Perumulla"
      },
      {
        "PartyCategory": "PMGR",
        "PartyID": "784",
        "PartyNameInChinese": "南方東英資産管理有限公司",
        "PartyNameInEnglish": "Portfolio Manager"
      }
    ]
  }
  getInvestmentAdvisor() {
    return [
      {
        "PartyCategory": "INVA",
        "PartyID": "747",
        "PartyNameInChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
        "PartyNameInEnglish": "CGS-CIMB Securities (Singapore) Pte. Ltd."
      },
      {
        "PartyCategory": "INVA",
        "PartyID": "758",
        "PartyNameInChinese": "南方光电",
        "PartyNameInEnglish": "CSOP"
      },
      {
        "PartyCategory": "INVA",
        "PartyID": "IA891",
        "PartyNameInChinese": "華盛資本証券有限公司",
        "PartyNameInEnglish": "Investment Advisor"
      },
      {
        "PartyCategory": "INVA",
        "PartyID": "765",
        "PartyNameInChinese": "fss",
        "PartyNameInEnglish": "InvestmentAdviser11"
      },
      {
        "PartyCategory": "INVA",
        "PartyID": "771",
        "PartyNameInChinese": "中銀國際英國保誠信託有限公司",
        "PartyNameInEnglish": "SEHK - Main Board"
      }
    ]
  }
  getExchangeListing() {
    return [
      {
        "PartyCategory": "MKT",
        "PartyID": "EL804",
        "PartyNameInChinese": "香港聯交所 ─ 主板",
        "PartyNameInEnglish": "SEHK - Main Board"
      },
      {
        "PartyCategory": "MKT",
        "PartyID": "EL863",
        "PartyNameInChinese": "SGX-ST- 主板",
        "PartyNameInEnglish": "SGX-ST- Main Board"
      },
      {
        "PartyCategory": "MKT",
        "PartyID": "EL919",
        "PartyNameInChinese": "SGX-ST-主板",
        "PartyNameInEnglish": "SGX-ST-Main Board"
      }
    ]
  }
  getLegalStructure() {
    return [
      {
        "PartyCategory": "LEGAL",
        "PartyID": "750",
        "PartyNameInChinese": "香港单位信托",
        "PartyNameInEnglish": "Hong Kong Unit Trust"
      },
      {
        "PartyCategory": "LEGAL",
        "PartyID": "766",
        "PartyNameInChinese": "dsd",
        "PartyNameInEnglish": "LegalStructure"
      },
      {
        "PartyCategory": "LEGAL",
        "PartyID": "759",
        "PartyNameInChinese": "香港联交所 – 主板",
        "PartyNameInEnglish": "SEHK – Main Board"
      },
      {
        "PartyCategory": "LEGAL",
        "PartyID": "772",
        "PartyNameInChinese": "匯豐銀行(中國)有限公司",
        "PartyNameInEnglish": "SGX-ST – Main Board"
      }
    ]
  }
  getTrustee() {
    return [
      {
        "PartyCategory": "TRUS",
        "PartyID": "TS909",
        "PartyNameInChinese": "花旗信托有限公司",
        "PartyNameInEnglish": "cititrust Limited"
      },
      {
        "PartyCategory": "TRUS",
        "PartyID": "TS900",
        "PartyNameInChinese": "汇丰托管人\n",
        "PartyNameInEnglish": "HSBC CUSTODIAN\t\n"
      },
      {
        "PartyCategory": "TRUS",
        "PartyID": "TS901",
        "PartyNameInChinese": "汇丰机构信托服务（亚洲）有限公司",
        "PartyNameInEnglish": "HSBC Institutional Trust Services (Asia) Limited"
      },
      {
        "PartyCategory": "TRUS",
        "PartyID": "TS925",
        "PartyNameInChinese": "卓佳证券投资有限公司",
        "PartyNameInEnglish": "Tricor Investor Services Limited"
      }
    ]
  }
  getRegistarar() {
    return [
      {
        "PartyCategory": "RGTR",
        "PartyID": "RG902",
        "PartyNameInChinese": "中银国际英国保诚信托有限公司",
        "PartyNameInEnglish": "BOCI-Prudential Trustee Limited"
      },
      {
        "PartyCategory": "RGTR",
        "PartyID": "RG910",
        "PartyNameInChinese": "花旗信托有限公司",
        "PartyNameInEnglish": "Cititrust Limited"
      },
      {
        "PartyCategory": "RGTR",
        "PartyID": "RG923",
        "PartyNameInChinese": "汇丰设计信托服务（Azowyo）有限公司",
        "PartyNameInEnglish": "HSBC Institutional Trust Services (Asia) Limited"
      },
      {
        "PartyCategory": "RGTR",
        "PartyID": "RG903",
        "PartyNameInChinese": "汇丰信托（开曼）有限公司",
        "PartyNameInEnglish": "HSBC Trustee (Cayman) Limited"
      },
      {
        "PartyCategory": "RGTR",
        "PartyID": "RG892",
        "PartyNameInChinese": "海通國際證券有限公司",
        "PartyNameInEnglish": "Registrar"
      },
      {
        "PartyCategory": "RGTR",
        "PartyID": "RG926",
        "PartyNameInChinese": "卓佳证券投资有限公司",
        "PartyNameInEnglish": "Tricor Investor Services Limited"
      }
    ]
  }
  getParticipateDealers() {
    return [
      {
        "PartyCategory": "PD",
        "PartyID": "PD433",
        "PartyNameInChinese": "ABN AMRO Clearing Bank N.V. Singapore Branch,",
        "PartyNameInEnglish": "ABN AMRO Clearing Bank N.V. Singapore Branch"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD434",
        "PartyNameInChinese": "ABN AMRO Clearing Hong Kong Limited,",
        "PartyNameInEnglish": "ABN AMRO Clearing Hong Kong Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD435",
        "PartyNameInChinese": "Barclays Bank PLC,",
        "PartyNameInEnglish": "Barclays Bank PLC"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD436",
        "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司,",
        "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD437",
        "PartyNameInChinese": "法國巴黎證券服務公司,",
        "PartyNameInEnglish": "BNP Paribas Securities Services"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD438",
        "PartyNameInChinese": "中銀國際證券有限公司,",
        "PartyNameInEnglish": "BOCI Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD439",
        "PartyNameInChinese": "交銀國際證券有限公司,",
        "PartyNameInEnglish": "BOCOM International Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD440",
        "PartyNameInChinese": "國泰證券(香港)有限公司,",
        "PartyNameInEnglish": "Cathay Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD441",
        "PartyNameInChinese": "CGS-CIMB Securities (Singapore) Pte Ltd,",
        "PartyNameInEnglish": "CGS-CIMB Securities (Singapore) Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD442",
        "PartyNameInChinese": "中國銀河國際證券(香港)有限公司,",
        "PartyNameInEnglish": "China Galaxy International Securities (Hong Kong) Co., Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD443",
        "PartyNameInChinese": "興證國際證券有限公司,",
        "PartyNameInEnglish": "China Industrial Securities International Brokerage Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD444",
        "PartyNameInChinese": "中國國際金融香港證券有限公司,",
        "PartyNameInEnglish": "China International Capital Corporation Hong Kong Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD445",
        "PartyNameInChinese": "招商證券(香港)有限公司,",
        "PartyNameInEnglish": "China Merchant Securities (HK) Co., Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD446",
        "PartyNameInChinese": "中信建投(國際)証券有限公司,",
        "PartyNameInEnglish": "China Securities (International) Brokerage Company Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD447",
        "PartyNameInChinese": "聯昌證券有限公司,",
        "PartyNameInEnglish": "CIMB Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD448",
        "PartyNameInChinese": "信達國際證券有限公司,",
        "PartyNameInEnglish": "Cinda International Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD449",
        "PartyNameInChinese": "中信証券經紀(香港)有限公司,",
        "PartyNameInEnglish": "CITIC Securities Brokerage (HK) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD450",
        "PartyNameInChinese": "花旗環球金融亞洲有限公司,",
        "PartyNameInEnglish": "Citigroup Global Markets Asia Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD451",
        "PartyNameInChinese": "中信里昂證券有限公司,",
        "PartyNameInEnglish": "CLSA LIMITED"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD452",
        "PartyNameInChinese": "招銀國際證券有限公司,",
        "PartyNameInEnglish": "CMB International Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD453",
        "PartyNameInChinese": "民銀證券有限公司,",
        "PartyNameInEnglish": "CMBC Securities Company Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD454",
        "PartyNameInChinese": "瑞士信貸證券(香港)有限公司,",
        "PartyNameInEnglish": "Credit Suisse Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD455",
        "PartyNameInChinese": "DBS Vickers Securities (Singapore) Pte Ltd,",
        "PartyNameInEnglish": "DBS Vickers Securities (Singapore) Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD456",
        "PartyNameInChinese": "德意志證券亞洲有限公司,",
        "PartyNameInEnglish": "Deutsche Securities Asia Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD457",
        "PartyNameInChinese": "安信國際證券(香港)有限公司,",
        "PartyNameInEnglish": "Essence International Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD458",
        "PartyNameInChinese": "Flow Traders Asia Pte. Ltd,",
        "PartyNameInEnglish": "Flow Traders Asia Pte. Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD459",
        "PartyNameInChinese": "Futu Singapore Pte Ltd,",
        "PartyNameInEnglish": "Futu Singapore Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD460",
        "PartyNameInChinese": "廣發証券(香港)經紀有限公司,",
        "PartyNameInEnglish": "GF Securities (Hong Kong) Brokerage Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD461",
        "PartyNameInChinese": "高盛(亞洲)證券有限公司",
        "PartyNameInEnglish": "Goldman Sachs (Asia) Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD462",
        "PartyNameInChinese": "國泰君安證券(香港)有限公司",
        "PartyNameInEnglish": "Guotai Junan Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD463",
        "PartyNameInChinese": "國元証券經紀(香港)有限公司",
        "PartyNameInEnglish": "Guoyuan Securities Brokerage (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD464",
        "PartyNameInChinese": "海通國際證券有限公司",
        "PartyNameInEnglish": "Haitong International Securities Company Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD465",
        "PartyNameInChinese": "聯合證券有限公司",
        "PartyNameInEnglish": "Head \u0026 Shoulders Securities Limited "
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD466",
        "PartyNameInChinese": "華泰金融控股(香港)有限公司,",
        "PartyNameInEnglish": "Huatai Financial Holdings (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD467",
        "PartyNameInChinese": "工銀國際證券有限公司",
        "PartyNameInEnglish": "ICBC International Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD468",
        "PartyNameInChinese": "iFAST Financial Pte Ltd",
        "PartyNameInEnglish": "iFAST Financial Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD469",
        "PartyNameInChinese": "J.P. Morgan Broking (Hong Kong) Limited",
        "PartyNameInEnglish": "J.P. Morgan Broking (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD470",
        "PartyNameInChinese": "Jane Street Financial Limited",
        "PartyNameInEnglish": "Jane Street Financial Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD471",
        "PartyNameInChinese": "凱基證券亞洲有限公司",
        "PartyNameInEnglish": "KGI Asia Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD472",
        "PartyNameInChinese": "韓國投資證券亞洲有限公司",
        "PartyNameInEnglish": "Korea Investment \u0026 Securities Asia Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD473",
        "PartyNameInChinese": "長橋證券(香港)有限公司 ",
        "PartyNameInEnglish": "Long Bridge HK Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD474",
        "PartyNameInChinese": "陸國際(香港)有限公司",
        "PartyNameInEnglish": "Lu International (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD475",
        "PartyNameInChinese": "元富證券(香港)有限公司",
        "PartyNameInEnglish": "Masterlink Securities (Hong Kong) Corporation Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD476",
        "PartyNameInChinese": "Merrill Lynch (Singapore) Pte Ltd",
        "PartyNameInEnglish": "Merrill Lynch (Singapore) Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD477",
        "PartyNameInChinese": "Merrill Lynch Far East Limited",
        "PartyNameInEnglish": "Merrill Lynch Far East Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD478",
        "PartyNameInChinese": "未來資產證券(香港)有限公司",
        "PartyNameInEnglish": "Mirae Asset Securities (HK) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD479",
        "PartyNameInChinese": "Morgan Stanley Hong Kong Securities Limited",
        "PartyNameInEnglish": "Morgan Stanley Hong Kong Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD480",
        "PartyNameInChinese": "野村國際(香港)有限公司",
        "PartyNameInEnglish": "Nomura International (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD481",
        "PartyNameInChinese": "東英亞洲證券有限公司",
        "PartyNameInEnglish": "Oriental Patron Securities Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD482",
        "PartyNameInChinese": "輝立證券(香港)有限公司",
        "PartyNameInEnglish": "Phillip Securities (Hong Kong) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD483",
        "PartyNameInChinese": "Phillip Securities Pte Ltd",
        "PartyNameInEnglish": "Phillip Securities Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD484",
        "PartyNameInChinese": "法國興業證券(香港)有限公司",
        "PartyNameInEnglish": "SG Securities (HK) Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD485",
        "PartyNameInChinese": "山證國際證券有限公司,",
        "PartyNameInEnglish": "Shanxi Securities International Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD486",
        "PartyNameInChinese": "太平証券(香港)有限公司",
        "PartyNameInEnglish": "Taiping Securities (HK) Co Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD487",
        "PartyNameInChinese": "香港上海滙豐銀行有限公司",
        "PartyNameInEnglish": "The Hong Kong and Shanghai Banking Corporation Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD488",
        "PartyNameInChinese": "Tiger Brokers (Singapore) Pte Ltd",
        "PartyNameInEnglish": "Tiger Brokers (Singapore) Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD489",
        "PartyNameInChinese": "UBS Securities Hong Kong Limited",
        "PartyNameInEnglish": "UBS Securities Hong Kong Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD490",
        "PartyNameInChinese": "UBS Securities Pte Ltd",
        "PartyNameInEnglish": "UBS Securities Pte Ltd"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD491",
        "PartyNameInChinese": "UOB Kay Hian Private Limited",
        "PartyNameInEnglish": "UOB Kay Hian Private Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD492",
        "PartyNameInChinese": "華盛資本証券有限公司",
        "PartyNameInEnglish": "Valuable Capital Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD493",
        "PartyNameInChinese": "元大證券(香港)有限公司",
        "PartyNameInEnglish": "Yuanta Securities (Hong Kong) Company Limited"
      },
      {
        "PartyCategory": "PD",
        "PartyID": "PD494",
        "PartyNameInChinese": "中泰國際證券有限公司",
        "PartyNameInEnglish": "Zhongtai International Securities Limited"
      }
    ]
  }
  getCustodian() {
    return [
      {
        "PartyCategory": "CUST",
        "PartyID": "CU528",
        "PartyNameInChinese": "中銀國際英國保誠信託有限公司",
        "PartyNameInEnglish": "BOCI-Prudential Trustee Limited"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU527",
        "PartyNameInChinese": "花旗银行(中国)有限公司",
        "PartyNameInEnglish": "CITIBANK (CHINA) CO., LTD"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU529",
        "PartyNameInChinese": "花旗銀行香港分行",
        "PartyNameInEnglish": "Citibank, N.A., Hong Kong Branch"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU530",
        "PartyNameInChinese": "花旗信托有限公司",
        "PartyNameInEnglish": "Cititrust Limited"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU525",
        "PartyNameInChinese": "匯豐銀行(中國)有限公司",
        "PartyNameInEnglish": "HSBC Bank (China) Company Limited"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU526",
        "PartyNameInChinese": "匯豐機構信託服務(亞洲)有限公司",
        "PartyNameInEnglish": "HSBC Institutional Trust Services (Asia) Limited"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU531",
        "PartyNameInChinese": "HSBC Institutional Trust Services (Singapore)  Limited",
        "PartyNameInEnglish": "HSBC Institutional Trust Services (Singapore)  Limited"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU857",
        "PartyNameInChinese": "香港聯交所 ─ 主板",
        "PartyNameInEnglish": "SEHK - Main Board"
      },
      {
        "PartyCategory": "CUST",
        "PartyID": "CU906",
        "PartyNameInChinese": "香港上海汇丰银行有限公司",
        "PartyNameInEnglish": "The Hongkong and Shanghai Banking Corporation Limited"
      }
    ]
  }
  getdcDeops() {
    return {
      "data": [
        {
          "category": "CR_TD",
          "category_desc": "Creation/Redemption Trade Date",
          "code": "DD",
          "code_desc": "Dealing Date",
          "id": "023",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8233333Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "CR_TD",
          "category_desc": "Creation/Redemption Trade Date",
          "code": "DD-1",
          "code_desc": "Dealing Date - 1",
          "id": "024",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8266667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "DC_CALC_TYPE",
          "category_desc": "DC Calc Type",
          "code": "NAV1",
          "code_desc": "NAV1 + Spread",
          "id": "020",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.82Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "DC_CALC_TYPE",
          "category_desc": "DC Calc Type",
          "code": "PCF",
          "code_desc": "PCF",
          "id": "018",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.82Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DC_CALC_TYPE",
          "category_desc": "DC Calc Type",
          "code": "TRAD",
          "code_desc": "Trades Cost",
          "id": "019",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.82Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "EXEC_FX_DATE",
          "category_desc": "Execution FX Date",
          "code": "T0",
          "code_desc": "T0",
          "id": "021",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8233333Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "EXEC_FX_DATE",
          "category_desc": "Execution FX Date",
          "code": "T1",
          "code_desc": "T1",
          "id": "022",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8233333Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PD_FF",
          "category_desc": "PD Fee From",
          "code": "MGR",
          "code_desc": "Paid by Fund Manager",
          "id": "030",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8366667Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PD_FF",
          "category_desc": "PD Fee From",
          "code": "PD",
          "code_desc": "Paid by PD to TA",
          "id": "029",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.83Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PD_FF",
          "category_desc": "PD Fee From",
          "code": "PDFND",
          "code_desc": "PD to Fund and Fund to TA",
          "id": "B79F0D2E-951C-443E-A509-0D4B4C40BCF2",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T08:35:57.2688193Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "PREFUND_BASE",
          "category_desc": "PREFUND BASE",
          "code": "T-1NAVFull",
          "code_desc": "T-1NAVFull",
          "id": "025",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8266667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "TA_FF",
          "category_desc": "TA Transfer Fee",
          "code": "FUND",
          "code_desc": "Fund",
          "id": "026",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8266667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "TA_FF",
          "category_desc": "TA Transfer Fee",
          "code": "MGR",
          "code_desc": "Fund Manager",
          "id": "027",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.83Z",
          "version_user": "OPSUSER"
        }
      ],
      "status": "success"
    }
  }

  getdcCommonEnums() {
    return {
      "data": [
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Euro Bond",
          "id": "E9A91257-EC41-44DA-9B90-00F47B9ECF0F",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-02-10T10:23:32.7087183Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Currency Future",
          "id": "B1DAB892-7B26-49F7-B47C-2CEC97B9FD7E",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-02-10T10:23:11.1646299Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "ACCOUNT_DRIVERS_TYPE",
          "category_desc": "Account Drivers Type",
          "code": "T1FS",
          "code_desc": "T1 Full Sign-off",
          "id": "DCA38AFF-672C-4BCF-ACF8-A92BD5C5ED23",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-01-10T10:46:25.8131364Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ACCOUNT_DRIVERS_TYPE",
          "category_desc": "Account Drivers Type",
          "code": "T0FS",
          "code_desc": "T0 Full Sign-off",
          "id": "E9272C29-743B-43B0-A02A-09E233D429E5",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-01-10T10:46:06.6632116Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ACCOUNT_DRIVERS_TYPE",
          "category_desc": "Account Drivers Type",
          "code": "T0S",
          "code_desc": "T0 Sign-off",
          "id": "C260145E-E002-4960-9A2F-4CFA41E0BEE2",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-01-10T10:45:53.78965Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_TYPE",
          "category_desc": "ENUM TYPE",
          "code": "ENUM",
          "code_desc": "Enum",
          "id": "BF6D93D2-7B79-4D3E-AB48-F794FDD200C5",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-01-04T08:54:16.6415482Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "OPS_CAL_CODES",
          "category_desc": "Ops Calendar Codes",
          "code": "HKO",
          "code_desc": "HK Ops Team Calendar",
          "id": "980692D3-7DC4-4B4E-A95A-79E01750B3B1",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-01-04T07:51:59.8086499Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "OPS_CAL_CODES",
          "code_desc": "Ops Calendar Codes",
          "id": "15A5CAA3-9D65-47D3-8905-BBC99429590E",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-01-04T07:51:16.9224641Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@TotalCCASSFee",
          "code_desc": "TotalCCASSFee",
          "id": "794E9FD5-09A5-4B99-A011-1596B14459C1",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-27T06:00:15.0572769Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "RECORD_TYPE",
          "category_desc": "Record Type",
          "code": "test",
          "code_desc": "na",
          "id": "F3C4C655-6974-4A63-B006-E7CE17D9DC05",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-26T04:05:12.4397689Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DEPO",
          "category_desc": "InKind Depo",
          "code": "CCASS",
          "code_desc": "CCASS",
          "id": "BA376138-9334-4A2F-A021-F600E64D1E76",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-14T05:53:03.562927Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "DEPO",
          "code_desc": "InKind Depo",
          "id": "FA5E4F5D-C7DF-4CA2-B020-47E2CE28E41E",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-14T05:52:52.0534011Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "BDR_TYPE",
          "category_desc": "BDR Type",
          "code": "BDS",
          "code_desc": "bio divesities",
          "id": "624E0795-0726-4A36-AF99-C70CC1FCAE58",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-13T14:44:06.7898117Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "BDR_TYPE",
          "code_desc": "BDR Type",
          "id": "37218AAF-EA74-4F37-93F5-8488B10686DB",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-13T14:43:21.0005054Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "EXCHANGE",
          "category_desc": "Exchange ID's",
          "code": "CFETS",
          "code_desc": "CFETS",
          "id": "E70D216D-034A-40B4-8061-58B99FA0CA3F",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-08T06:31:54.339821Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ASSET_GROUP",
          "category_desc": "Asset Group",
          "code": "BOND",
          "code_desc": "Bond",
          "id": "FE4B521A-A21A-457B-A6AA-BA1CD6C1FB6B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-08T06:30:20.7477945Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "ASSET_GROUP",
          "code_desc": "Asset Group",
          "id": "A502C229-5A64-44D7-B465-1EF750C2B985",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-07T10:58:27.9769435Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "EXCHANGE",
          "code_desc": "Exchange ID's",
          "id": "D015AB96-C73E-4E3F-8A12-BAA89C767CEE",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-07T10:58:09.0513256Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PRFIND",
          "category_desc": "Prefund Indicator",
          "code": "N",
          "code_desc": "No",
          "id": "4FB95091-7505-4155-AF9C-93F3D1A64D95",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-06T12:55:14.3130366Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PRFIND",
          "category_desc": "Prefund Indicator",
          "code": "Y",
          "code_desc": "Yes",
          "id": "B039B26A-69F0-43CC-A66B-C2DDAAC2BDC2",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-06T12:55:05.5268109Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "PRFIND",
          "code_desc": "Prefund Indicator",
          "id": "7FA88D33-6C7E-4943-A24A-DA84CE5084FD",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-06T12:54:57.267983Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DRIVER_TYPE",
          "category_desc": "Driver Type",
          "code": "TAOFF",
          "code_desc": "TA Order Fee From",
          "id": "43B35AFB-7E6F-49A1-856B-1A4721C799EA",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-12-06T12:52:20.1343731Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Price_Type",
          "category_desc": "Account Posting Price Amount Type",
          "code": "@One",
          "code_desc": "1",
          "id": "46A3E745-D135-43B0-9B57-61D2988D7CBC",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-11-25T05:29:32.8228413Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Price_Type",
          "category_desc": "Account Posting Price Amount Type",
          "code": "10",
          "code_desc": "10",
          "id": "8EDA427D-31E1-4443-97CE-8FAABDF6C2F3",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-11-25T05:28:34.8881936Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@One",
          "code_desc": "1",
          "id": "BDCE9435-8130-4DE3-AB12-D47C96373036",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-11-25T05:21:48.0158048Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "SETTLE_CCY_TYPE",
          "category_desc": "Account Postings Ccy Types",
          "code": "@TradeCcy",
          "code_desc": "Trade Executions Ccy",
          "id": "E61DFBFD-44B0-454B-907C-109596141F69",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-11-18T08:18:43.8052272Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Price_Type",
          "category_desc": "Account Posting Price Amount Type",
          "code": "@T0UnderlierFundFXRate",
          "code_desc": "T0 Underlier Fund FX Rate",
          "id": "50379789-B698-4540-8CA7-6DCC9CA75B2E",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-11-18T08:12:34.5263055Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@InKindConsideration",
          "code_desc": "InKind Consideration",
          "id": "8BA166C6-484C-402B-9F26-6BB3C449B848",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-11-18T08:10:28.3097448Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "IK_FX",
          "code_desc": "InKind FX",
          "id": "7DF16FA2-C254-4ADC-A177-C122C46DEC5A",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-11-18T08:09:38.5688553Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "IK_CASH",
          "code_desc": "InKind Cash",
          "id": "3776DDE9-1C18-48D2-AAC4-F598DFA04CB8",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-11-18T08:09:24.8408795Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "Driver",
          "code_desc": "Driver",
          "id": "A2E0FC72-0ADE-4DF8-9F98-11F79052870C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-11-03T06:48:06.0313125Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DRIVER_TYPE",
          "category_desc": "Driver Type",
          "code": "PDFF",
          "code_desc": "PD Fee From",
          "id": "792BBB72-07E3-436A-9A1B-85CCEC73677C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-19T09:47:17.0430068Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "test Posting Driver",
          "code_desc": "test Posting Driver",
          "id": "C7DA30E8-5DF7-484F-9C37-4F06AD4E96F5",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-19T06:25:08.7323103Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Price_Type",
          "category_desc": "Account Posting Price Amount Type",
          "code": "@T2BlendedPayFundFXRate",
          "code_desc": "T2BlendedPayFundFXRate",
          "id": "7DFFFE10-B101-4E96-B70A-E0E65CB6F3AC",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-19T04:10:55.8465407Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Price_Type",
          "category_desc": "Account Posting Price Amount Type",
          "code": "@T1BlendedPayFundFXRate",
          "code_desc": "T1BlendedPayFundFXRate",
          "id": "1F73C8D7-806A-495B-BAB5-9DACD055DFAB",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-19T04:10:37.733699Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Price_Type",
          "category_desc": "Account Posting Price Amount Type",
          "code": "@T0BlendedPayFundFXRate",
          "code_desc": "T0BlendedPayFundFXRate",
          "id": "2AC6D8D2-6B2B-4210-8E00-2D3CBA0AD6F2",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-19T04:09:48.3547529Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ACCOUNT_DRIVERS_TYPE",
          "category_desc": "Account Drivers Type",
          "code": "T2FS",
          "code_desc": "T2 Full Signoff",
          "id": "5997E7FF-B2C0-4585-AFA7-0033F91FDCC2",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-18T06:05:55.8809419Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PRICE_CODE",
          "category_desc": "Price Codes",
          "code": "PX_LAST",
          "code_desc": "Last Price",
          "id": "8ADDB96A-2C3A-4741-81D5-1964860E1678",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-16T12:24:37.6377678Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PRICE_CODE",
          "category_desc": "Price Codes",
          "code": "FAIR_VALUE",
          "code_desc": "Fair Value",
          "id": "79C44D7A-60AA-4E68-B997-49BF96B44CDB",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-16T12:24:18.0664421Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "PRICE_CODE",
          "code_desc": "Price Codes",
          "id": "80F4E41F-D4BB-4977-9671-5CEF27E15270",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-16T12:23:55.9646914Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "CA_TYPES",
          "category_desc": "Corporate Action Event Types",
          "code": "SPLIT",
          "code_desc": "Stock Split / Reverse Stock Split",
          "id": "7821E7EE-CBBC-43A5-B087-9C91919546C6",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-13T13:18:51.2780426Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "CA_TYPES",
          "category_desc": "Corporate Action Event Types",
          "code": "DVD",
          "code_desc": "Cash Dividend",
          "id": "2D36E8D6-BE8F-44C5-AC08-70F27CFA46B7",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-10-13T13:17:43.7673055Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DRIVER_TYPE",
          "category_desc": "Driver Type",
          "code": "PRFIND",
          "code_desc": "Prefund Indicator",
          "id": "048A0AC3-32CB-4591-81FC-BB44EAEFFEF8",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-28T11:36:03.5212188Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DRIVER_TYPE",
          "category_desc": "Driver Type",
          "code": "EXFXD",
          "code_desc": "Execution FX Date",
          "id": "C825EB29-8000-4819-BEBA-CC2ADBF23224",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-28T11:34:29.888171Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "Price_Type",
          "code_desc": "Account Posting Price Amount Type",
          "id": "0D431B33-BD31-499A-9D53-154124F64BB2",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-28T10:42:43.325664Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "RECORD_TYPE",
          "category_desc": "Record Type",
          "code": "EQTRDS",
          "code_desc": "Equity Trades",
          "id": "9A805E74-1A70-4831-B7D6-497A3D78CE7D",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-27T13:49:13.9636194Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "CA_TYPES",
          "code_desc": "Corporate Action Event Types",
          "id": "92A67ABC-0D06-4C1D-973E-628111CC555E",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-27T10:23:10.1818843Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@T2BlendedPayFundFXRate",
          "code_desc": "T2BlendedPayFundFXRate",
          "id": "59F4600B-88C9-4EAB-BD50-B28B5F468F80",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-27T10:03:24.1003646Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@T1BlendedPayFundFXRate",
          "code_desc": "T1BlendedPayFundFXRate",
          "id": "3F2DE1AF-1D7D-42A9-855F-33CED6EC9FDC",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-27T10:03:05.3755356Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@T0BlendedPayFundFXRate",
          "code_desc": "T0BlendedPayFundFXRate",
          "id": "9C6636C4-CB23-4B42-9EFE-689869115F7D",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-27T10:02:44.2320382Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "EVENT_TYPE",
          "code_desc": "EVENT TYPE",
          "id": "C96184F0-18CD-4C86-A23B-829781860C61",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-26T11:08:09.0284566Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DATE_RULE",
          "category_desc": "Date Rule",
          "code": "DD+4",
          "code_desc": "Dealing Date + 4",
          "id": "C147F148-EB13-4AB9-8A2B-9D1A7F334F16",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-20T10:45:07.8692469Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DATE_RULE",
          "category_desc": "Date Rule",
          "code": "DD+3",
          "code_desc": "Dealing Date + 3",
          "id": "0C75B986-08BC-45C9-9580-DC5AC3A62086",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-20T10:44:47.6511319Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DATE_RULE",
          "category_desc": "Date Rule",
          "code": "DD+2",
          "code_desc": "Dealing Date + 2",
          "id": "0679A6CE-84C7-4641-9AA0-09EC6446F8A1",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-20T10:44:31.107466Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DATE_RULE",
          "category_desc": "Date Rule",
          "code": "DD+1",
          "code_desc": "Dealing Date + 1",
          "id": "AE822801-3486-4D71-B72A-5DE491E62D97",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-20T10:44:12.0885513Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DATE_RULE",
          "category_desc": "Date Rule",
          "code": "DD",
          "code_desc": "Dealing Date",
          "id": "E3C21B64-CA6F-4806-97A9-C4D133224E16",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-20T10:41:18.7581141Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "DATE_RULE",
          "code_desc": "Date Rule",
          "id": "9D3428C7-064E-4904-AEFC-750522D5F7C8",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-20T10:40:57.7962271Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@CapitalDummyFXT2Adjust",
          "code_desc": "CapitalDummyFXT2Adjust",
          "id": "0A7B0EE7-1957-44CE-8FF7-FC12F3EA529B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:33:51.165437Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@CapitalDummyFXT1Adjust",
          "code_desc": "CapitalDummyFXT1Adjust",
          "id": "785852F1-7085-4000-8AB8-8EAE94373331",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:33:33.3085457Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@SecurityPayAmtChangeT2",
          "code_desc": "SecurityPayAmtChangeT2",
          "id": "1CED1D7D-3D61-4F8A-9A87-058DA3289272",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:33:14.6847005Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@D\u0026CChangeT2FCCY",
          "code_desc": "D\u0026CChangeT2FCCY",
          "id": "E6758278-5E98-4BA3-955A-AC9FBF825C88",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:32:55.1085287Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@SecurityPayAmtChangeT1",
          "code_desc": "SecurityPayAmtChangeT1",
          "id": "E85B49BC-D692-4415-8758-3FB028FFC2AB",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:32:36.3330666Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@D\u0026CChangeT1FCCY",
          "code_desc": "D\u0026CChangeT1FCCY",
          "id": "FAF58155-ACC6-4664-BF86-8AA774CB8C56",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:32:16.8856695Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@PDFeePayCCY",
          "code_desc": "PDFeePayCCY",
          "id": "E5866562-0D03-4556-9A86-A8D4BE25B5FC",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:31:54.6484819Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@TAOrderFeeFCCY",
          "code_desc": "TAOrderFeeFCCY",
          "id": "45816B8D-FD09-4432-9ADC-ACDA2B92E2F4",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:31:38.2780946Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@T0Red2ndPayAmt",
          "code_desc": "T0Red2ndPayAmt",
          "id": "7AE42CE7-86FB-4B77-9F31-F964F598090B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:31:01.7033171Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@T0PayAmtFund",
          "code_desc": "T0PayAmtFund",
          "id": "CE19D2B2-9620-4F7F-964E-C823E93A7ED9",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:30:43.2620875Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@D\u0026CT0FCCY",
          "code_desc": "D\u0026CT0FCCY",
          "id": "B02E522B-A05C-42C0-97D0-8F8B1BD8D6F5",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:30:06.3027428Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@D\u0026CT1FXT0",
          "code_desc": "D\u0026CT1FXT0",
          "id": "D01FC771-9492-4092-92B2-62E19A103324",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:29:41.5217264Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@CrRdCapitalFCCY",
          "code_desc": "CrRdCapitalFCCY",
          "id": "57F56A02-D1EF-41B7-BC5A-837B9129876C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:29:16.8399417Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@RefundPayAmt",
          "code_desc": "RefundPayAmt",
          "id": "64AAFCCA-5468-4B73-9673-D850D2551E14",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:28:56.2325051Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "AMOUNT_TYPE",
          "category_desc": "Account Postings Amount Type",
          "code": "@PrefundCrCashPayAmt",
          "code_desc": "PrefundCrCashPayAmt",
          "id": "68AE5637-D3A2-4CCF-8C02-F3303CD1A3AD",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:28:24.4194292Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "AMOUNT_TYPE",
          "code_desc": "Account Postings Amount Type",
          "id": "ABB64AE0-AF55-40C0-9A42-2F6405527121",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:27:36.2444495Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "SETTLE_CCY_TYPE",
          "category_desc": "Account Postings Ccy Types",
          "code": "@ShareclassCcy",
          "code_desc": "Share Class Ccy",
          "id": "90D0D608-9B23-4FE3-9EBB-6F6D71882313",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:21:18.7199629Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "SETTLE_CCY_TYPE",
          "category_desc": "Account Postings Ccy Types",
          "code": "@CRCcy",
          "code_desc": "CR CCY",
          "id": "84518589-9C01-4247-982E-89C239D2384E",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:20:42.4873308Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "SETTLE_CCY_TYPE",
          "code_desc": "Account Postings Ccy Types",
          "id": "1A770BC6-4EBF-4CBD-B449-B929045013F6",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:19:59.2736614Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "DRIVER_TYPE",
          "code_desc": "Driver Type",
          "id": "90960A10-1864-4937-B3D3-8E1FE9DAB32C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:11:59.091337Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ACCOUNT_DRIVERS_TYPE",
          "category_desc": "Account Drivers Type",
          "code": "T1S",
          "code_desc": "T1 Sign-off",
          "id": "4B5F2C7C-0B74-4579-BD0D-E44E2269DAD2",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:10:29.9863582Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "ACCOUNT_DRIVERS_TYPE",
          "code_desc": "Account Drivers Type",
          "id": "FE1F5E17-230F-44F1-84F6-1D268BF1CC7C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:06:43.1691856Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "OPERATION_TYPE",
          "category_desc": "Operation Type",
          "code": "Redemption",
          "code_desc": "Redemption",
          "id": "EDCDB1F0-F844-4A50-8EA7-A9F070032B43",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:05:58.9496234Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "OPERATION_TYPE",
          "category_desc": "Operation Type",
          "code": "Creation",
          "code_desc": "Creation",
          "id": "A0CCD64F-F00C-4194-9E23-5A2186F4E68A",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:05:12.0893259Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "OPERATION_TYPE",
          "code_desc": "Operation Type",
          "id": "6B32C93E-2A85-493E-BC7B-DEF32175738F",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:04:41.7359256Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "RECORD_TYPE",
          "category_desc": "Record Type",
          "code": "CRRDORD",
          "code_desc": "Creation \u0026 Redemption Orders",
          "id": "C108E187-7E3C-4685-8181-CF32766B3A7B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:03:18.7624424Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "RECORD_TYPE",
          "code_desc": "Record Type",
          "id": "4CB517EB-E804-4ADF-A85D-AAB4DBB43704",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T10:00:28.2154625Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "PD_FEE_ACCRUAL",
          "code_desc": "PD Fee Accrual",
          "id": "36284022-7EA5-4211-AEAF-113EB381665F",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:50:51.4296753Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "PD_FEE",
          "code_desc": "PD Fee",
          "id": "1C42C24F-547D-4576-87B5-4E267DBECFAC",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:50:15.6538723Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "TRANX_FEE",
          "code_desc": "Transaction Fee",
          "id": "F0B4BA00-BF3A-4FD6-86FB-F5B0F9B5C294",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:49:46.854603Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "RD_PAY_2A",
          "code_desc": "Redemption Pay T2 Adjustment",
          "id": "9890D788-4DB9-4C11-9A5F-5E9702879B71",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:49:16.5604048Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "CR_PAY_2A",
          "code_desc": "Creation Pay T2 Adjustment",
          "id": "FC6E44CC-F108-4265-B57E-F6CC61BCB9FD",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:48:46.1289665Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "CAP_DUMM_FX_2A",
          "code_desc": "Capital Dummy FX T2 Adjustment",
          "id": "1DC343F6-CB7A-42A9-A7DE-0EC09B37E2CC",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:48:16.3123166Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "DC_T2",
          "code_desc": "D\u0026C T2",
          "id": "AAE97134-294E-4854-9CA3-C06F2E525031",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:47:22.2322756Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "RD_PAY_1A",
          "code_desc": "Redemption Pay T1 Adjustment",
          "id": "A9EE0DD3-87C7-4D33-8155-D780BB26984A",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:46:51.6232505Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "CR_PAY_1A",
          "code_desc": "Creation Pay T1 Adjustment",
          "id": "0964C923-F35E-48C8-A73A-03C1C9A71B7F",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:46:19.7144247Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "CAP_DUMM_FX_1A",
          "code_desc": "Capital Dummy FX T1 Adjustment",
          "id": "BA6E76A1-F03A-4D4C-9876-228BE560184A",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:45:52.034645Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "DC_T1",
          "code_desc": "D\u0026C T1",
          "id": "3F331DE8-8DE0-43F7-B124-6B8E0382AB63",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:45:14.1868753Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "RD_PAY_2",
          "code_desc": "Redemption Pay Second",
          "id": "23D520AB-0327-4831-A94E-2029B1F8FBCE",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:44:39.9016955Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "RD_PAY_1",
          "code_desc": "Redemption Pay First",
          "id": "950E97C7-7D11-4455-A5FF-6B0BE052F0A9",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:44:12.8796072Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "CR_PAY_1",
          "code_desc": "Creation Pay First",
          "id": "05311195-3FEA-482F-A072-648C4EBE8599",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:43:28.4026288Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "CAP_DUMM_FX_1",
          "code_desc": "Capital Dummy FX First",
          "id": "D58FA290-36EA-4960-AEFF-C1091F39A239",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:42:54.8495878Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "CAP_ENTRY",
          "code_desc": "Capital Entry",
          "id": "C42BAD44-6491-401E-8D07-431BED85B244",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:42:02.2323149Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "REFND_CSH_PAY",
          "code_desc": "Refund Cash Pay",
          "id": "0311C0EC-4C36-403D-B965-5A9E1C7CED7F",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:40:37.9100956Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "DC_T0",
          "code_desc": "D\u0026C T0",
          "id": "81EB9E1B-4AA7-4757-8688-08EB7253C1A0",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:38:33.3173028Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "Account_Posting_Driver",
          "category_desc": "Account Posting Driver",
          "code": "PFND_CSH_PAY",
          "code_desc": "Prefund Cash Pay",
          "id": "B555C2CD-E4EF-49B5-B303-25FFA52F8E76",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:36:28.7423974Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "Account_Posting_Driver",
          "code_desc": "Account Posting Driver",
          "id": "C062C700-6F4E-4056-8E90-9B306C6310E2",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-09-16T09:31:27.109708Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "POSTING_DRIVER",
          "code_desc": "Posting Driver Definition",
          "id": "71C50CB0-7650-4D28-807A-AD5228A9A4FE",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-08-26T06:17:49.5857895Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "HKD",
          "code_desc": "HKD",
          "id": "6738CC78-17E9-4F06-94E6-A69BC4DDFA93",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-08-22T10:48:00.5376193Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "CCY_CAL_CODES",
          "category_desc": "Ccy Settlement Calendar Codes",
          "code": "CNY",
          "code_desc": "CNY",
          "id": "3F3E7261-6434-4A68-BB7B-7F4E29EAF486",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:31:21.5725006Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "CCY_CAL_CODES",
          "category_desc": "Ccy Settlement Calendar Codes",
          "code": "HKD",
          "code_desc": "HKD",
          "id": "7DC233BE-B574-42A3-81C6-3FDA828B6E68",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:30:11.1129356Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "CCY_CAL_CODES",
          "category_desc": "Ccy Settlement Calendar Codes",
          "code": "USD",
          "code_desc": "USD",
          "id": "6BACB094-FBAC-456F-9E74-014D9441BB46",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:27:04.4241064Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "CCY_CAL_CODES",
          "category_desc": "Ccy Settlement Calendar Codes",
          "code": "SGD",
          "code_desc": "SGD",
          "id": "8DB99625-0CBB-49B2-8BB4-5B29F86C1FB1",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:02:39.0506609Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "CN",
          "code_desc": "CN",
          "id": "F7506029-0FC7-4701-95F0-3209AC2D8A04",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:54:22.3417065Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "US",
          "code_desc": "US",
          "id": "6948EF94-4C2D-41E4-8D8A-FBFBD2A18FA1",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:20:47.796745Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "US",
          "code_desc": "US",
          "id": "B5D9F72C-074B-415A-895D-ED19A35A5D2D",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:18:51.7153098Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "SG",
          "code_desc": "SG",
          "id": "7562DC71-581D-4BEA-B924-AAB6A12631A5",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-10T09:26:27.1846152Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "CCY_CAL_CODES",
          "code_desc": "Ccy Settlement Calendar Codes",
          "id": "031",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-08T07:44:41.94Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "HK",
          "code_desc": "HK",
          "id": "192B99F5-AF57-4564-957D-2F7385A47A23",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T08:52:10.8241609Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "HKSC",
          "code_desc": "Hong Kong Stock Connect Market",
          "id": "1294D328-3A7C-4D39-8A57-710116B6446C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T08:51:58.2468583Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "PD_FF",
          "category_desc": "PD Fee From",
          "code": "PDFND",
          "code_desc": "PD to Fund and Fund to TA",
          "id": "B79F0D2E-951C-443E-A509-0D4B4C40BCF2",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T08:35:57.2688193Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "CYM",
          "code_desc": "Cayman Islands(CYM)",
          "id": "716A8167-AFD4-4B61-A62B-3E0EF147E85B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T06:26:03.8323832Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "EXCLUDED_SECURITY_TYPE",
          "category_desc": "EXCLUDED SECURITY TYPE",
          "code": "Inde6",
          "id": "7BCE4726-8721-4F22-8D80-B04B38F721CB",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-04T14:17:33.2752676Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "EXCLUDED_SECURITY_TYPE",
          "category_desc": "EXCLUDED SECURITY TYPE",
          "code": "Inde3",
          "id": "4491DE34-757B-40EF-83A5-77CEE6AF9F6A",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-04T14:17:33.2752676Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "EXCLUDED_SECURITY_TYPE",
          "category_desc": "EXCLUDED SECURITY TYPE",
          "code": "Equity ADR",
          "id": "C1987F2C-D13C-4FFA-850A-61991C68AFCE",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-04T14:17:33.2752676Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "TRD_CAL_CODES",
          "code_desc": "Trading Calender Codes",
          "id": "B89AAD2E-BF71-4488-A859-F0577FF82D8A",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T12:48:59.8209626Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Equity HedgeFund",
          "id": "758FB131-05B7-4423-971B-16872A712FFA",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:54:13.5108418Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Corporate Bond",
          "id": "97B5CE20-DA6D-4C56-B623-E4F29EEA7145",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:54:13.5108418Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Inde4",
          "id": "9598FF9C-EC72-4927-B94B-09F6C45DED66",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:38:08.2101815Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Inde6",
          "id": "E76298DD-34E5-4F00-A7A5-3DC12DBE02E3",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:38:08.2101815Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Inde5",
          "id": "F658C208-8528-44DD-BCAD-83DD628635BB",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:37:49.1312008Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Equity123",
          "id": "78658710-DD88-4AEF-B414-A300718D488E",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:37:49.1312008Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Government Bond",
          "id": "4660ED80-4A26-4FF4-ADD9-4A58C95B4206",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:37:49.1312008Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "50",
          "id": "EC21AA1A-DA0A-4265-8A08-304DFF5B21D3",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:36:12.6003452Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "PMA",
          "id": "D3BB0646-FEF6-4ACD-84CC-2835CDBFAABB",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:36:12.6003452Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "123",
          "id": "F9329D4A-B7AF-4735-B47F-F39322644EA4",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:31:01.6615828Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Inde7",
          "id": "0E50CC60-DA4E-4413-98F5-893197BE4310",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T09:30:31.8735729Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Inde3",
          "id": "258EA7E4-4DBC-4B01-9377-04074A1F033A",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-02T06:20:43.4700654Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Inde9",
          "code_desc": "Index9",
          "id": "C5124EDF-8920-4F3B-8F73-EE68CF068E6B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T16:11:21.5382888Z",
          "version_user": "RISK"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Inde7",
          "code_desc": "Index7",
          "id": "28793645-1CFB-42D5-8669-C848300BE4F5",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T16:10:26.6583963Z",
          "version_user": "RISK"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Inde6",
          "code_desc": "Index6",
          "id": "0C9BE814-3EB7-4914-AD2C-4EF6794E93D8",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T16:05:21.8456776Z",
          "version_user": "RISK"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Inde5",
          "code_desc": "Index5",
          "id": "8CA0ACFA-EDD5-4DCE-9DED-4DCAE399482B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T16:03:58.4985362Z",
          "version_user": "RISK"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Inde4",
          "code_desc": "Index4",
          "id": "38798EC2-A474-4B9C-8071-B6DC0DB84039",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T16:01:03.3643273Z",
          "version_user": "RISK"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Inde3",
          "code_desc": "Index3",
          "id": "0B32965A-4C39-444D-A8AB-11B8B8A6ABFD",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T15:58:41.979729Z",
          "version_user": "RISK"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Inde2",
          "code_desc": "Index2",
          "id": "F686BB6B-5433-4A62-8A95-16EC86D0C206",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T15:55:22.6534535Z",
          "version_user": "RISK"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Index1",
          "code_desc": "Index1",
          "id": "E318CECD-B8EF-4ACB-B663-099751189FB8",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T15:52:09.9395303Z",
          "version_user": "RISK"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Index",
          "code_desc": "Index",
          "id": "AFDC1F79-978D-4968-82F0-3DA79E98099F",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T15:46:00.0450338Z",
          "version_user": "RISK"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "LUX",
          "code_desc": "Luxembourg(LUX)",
          "id": "AF210980-0D0B-4DBA-A9A7-F5BB64E89A9B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T10:27:38.4648984Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "SG",
          "code_desc": "Singapore(SG)",
          "id": "D4E8F539-11C7-4104-8128-2259B78193E3",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T10:23:03.5620517Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "HK",
          "code_desc": "Hong Kong(HK)",
          "id": "417A29DC-6AAC-479C-A02A-23DE2B2BDA27",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T10:21:48.1781189Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "ENTITIES",
          "code_desc": "Entities",
          "id": "62423C66-B65F-41A4-B98D-57C8CA7B5CC3",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T10:20:18.5945339Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "PMA CODE",
          "code_desc": "PMA Description",
          "id": "B525FB9D-97B4-444A-9139-2455EF1C77EA",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T05:02:31.5542489Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "PMA",
          "code_desc": "PMA Description",
          "id": "7515513D-C04F-423B-BF3A-9AE72BC5F170",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T04:59:34.141734Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "PMA security Type",
          "code_desc": "PMA security Type",
          "id": "1928A233-6017-4447-85F7-A16836986073",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T04:55:28.7598481Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "50",
          "code_desc": "testing",
          "id": "3EF1B2FB-E88F-4144-8734-8B68C174C7DF",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-30T12:49:09.4109624Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "UNDERLIER_STOCK_FILL_DATE",
          "category_desc": "Underlier Stock Fill Date",
          "code": "T2",
          "code_desc": "T2",
          "id": "57413A0E-A9B9-4236-A38A-B3EEC04AAB0E",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-27T10:25:47.8284104Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "UNDERLIER_STOCK_FILL_DATE",
          "category_desc": "Underlier Stock Fill Date",
          "code": "T0",
          "code_desc": "T0",
          "id": "2FD9B7DD-F482-40B4-917C-55DC54EB86B8",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-27T10:25:23.7016932Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "UNDERLIER_STOCK_FILL_DATE",
          "code_desc": "Underlier Stock Fill Date",
          "id": "D1118D38-1433-4545-93CE-1B01228BD603",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-27T10:25:05.0674725Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "123",
          "code_desc": "1234",
          "id": "579D14F3-E292-4922-BE6C-FBD11402DF01",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-26T10:21:59.174348Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PD_FF",
          "category_desc": "PD Fee From",
          "code": "MGR",
          "code_desc": "Paid by Fund Manager",
          "id": "030",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8366667Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "TA_FF",
          "category_desc": "TA Transfer Fee",
          "code": "MGR",
          "code_desc": "Fund Manager",
          "id": "027",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.83Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PD_FF",
          "category_desc": "PD Fee From",
          "code": "PD",
          "code_desc": "Paid by PD to TA",
          "id": "029",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.83Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "CR_TD",
          "category_desc": "Creation/Redemption Trade Date",
          "code": "DD-1",
          "code_desc": "Dealing Date - 1",
          "id": "024",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8266667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PREFUND_BASE",
          "category_desc": "PREFUND BASE",
          "code": "T-1NAVFull",
          "code_desc": "T-1NAVFull",
          "id": "025",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8266667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "TA_FF",
          "category_desc": "TA Transfer Fee",
          "code": "FUND",
          "code_desc": "Fund",
          "id": "026",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8266667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "EXEC_FX_DATE",
          "category_desc": "Execution FX Date",
          "code": "T0",
          "code_desc": "T0",
          "id": "021",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8233333Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "EXEC_FX_DATE",
          "category_desc": "Execution FX Date",
          "code": "T1",
          "code_desc": "T1",
          "id": "022",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8233333Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "CR_TD",
          "category_desc": "Creation/Redemption Trade Date",
          "code": "DD",
          "code_desc": "Dealing Date",
          "id": "023",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.8233333Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "DC_CALC_TYPE",
          "category_desc": "DC Calc Type",
          "code": "PCF",
          "code_desc": "PCF",
          "id": "018",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.82Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DC_CALC_TYPE",
          "category_desc": "DC Calc Type",
          "code": "TRAD",
          "code_desc": "Trades Cost",
          "id": "019",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.82Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "DC_CALC_TYPE",
          "category_desc": "DC Calc Type",
          "code": "NAV1",
          "code_desc": "NAV1 + Spread",
          "id": "020",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T11:24:46.82Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "DC_CALC_TYPE",
          "code_desc": "DC Calc Type",
          "id": "006",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T10:13:13.75Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "EXEC_FX_DATE",
          "code_desc": "Execution FX Date",
          "id": "007",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T10:13:13.75Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "CR_TD",
          "code_desc": "Creation/Redemption Trade Date",
          "id": "010",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T10:13:13.75Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "TA_FF",
          "code_desc": "TA Transfer Fee",
          "id": "013",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T10:13:13.75Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "PD_FF",
          "code_desc": "PD Fee From",
          "id": "014",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-25T10:13:13.75Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "ENUM_CATEGORY",
          "category_desc": "ENUM Types",
          "code": "ENUM_CATEGORY",
          "code_desc": "ENUM Types",
          "id": "24DEE921-A589-4F9D-A68F-E6B1579CC6DA",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-24T10:51:20.6647864Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Index Future",
          "code_desc": "Index Future",
          "id": "9AE9E5BB-3AB2-49EC-8377-8D6587489108",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-23T15:13:53.5161291Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Equity999",
          "code_desc": "Equity999",
          "id": "C932E8CC-60CE-432F-B7A4-6313E42AE2F4",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-23T15:13:53.5161291Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Fully Funded CFD TEST",
          "code_desc": "Fully Funded CFD TEST",
          "id": "CBE886D9-5C14-47B2-B1E3-01D2468B0D7F",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-03-10T10:48:29.5357003Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Equity ADR123",
          "code_desc": "Equity ADR123",
          "id": "B066AB64-41EC-4E7E-9A04-6FCD8A13E946",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-02-24T11:11:42.8958875Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Equity888",
          "code_desc": "Equity888",
          "id": "9829C3CC-E5EE-4D3C-BBB5-9F605ED3B49A",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-11-11T11:26:13.6552994Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Equity999",
          "code_desc": "Equity999",
          "id": "0E072B7E-281C-4665-BE91-8D24FEA632DB",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-11-11T11:26:13.6552994Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Equity123",
          "code_desc": "Equity123",
          "id": "81B12E12-7E75-45F0-B776-549C8DCBFB89",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-11-11T11:24:39.1455583Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Index CFD",
          "code_desc": "Index CFD",
          "id": "5FA9DCC3-2BBB-4A1C-8EDD-1DD9CD18298A",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-10-25T10:29:15.6184366Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Index CFD",
          "code_desc": "Index CFD",
          "id": "1FFB0F96-18C6-42FA-B75F-A28D6B895329",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-10-14T03:39:13.6572343Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Equity Mutual Fund/Load",
          "code_desc": "Equity Mutual Fund/Load",
          "id": "E832BF87-9552-4BB0-9DC0-022B5813CA3C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-10-14T03:28:11.4282422Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "EXCLUDED_SECURITY_TYPE",
          "category_desc": "EXCLUDED SECURITY TYPE",
          "code": "Index Future",
          "code_desc": "Index Future",
          "id": "BE4351EC-EFD0-4F86-B97D-DA7ADD381BE9",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-13T10:42:19.5966667Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Equity",
          "code_desc": "Equity",
          "id": "6E84FD8F-E632-42C3-A794-7F4C3E14F3F5",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-13T10:41:46.5733333Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "EXCLUDED_SECURITY_TYPE",
          "category_desc": "EXCLUDED SECURITY TYPE",
          "code": "Commodity Future",
          "code_desc": "Commodity Future",
          "id": "8966fc75-4c86-4dac-9f39-2fde824a5ed8",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:58:06.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Equity CFD",
          "code_desc": "Equity CFD",
          "id": "24370f67-013c-493d-86c2-7a926026876f",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:58:06.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "Equity ADR",
          "code_desc": "Equity ADR",
          "id": "2b0a2800-838c-40d4-a249-b52983125b17",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:58:06.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "EXCLUDED_SECURITY_TYPE",
          "category_desc": "EXCLUDED SECURITY TYPE",
          "code": "Fully Funded CFD",
          "code_desc": "Fully Funded CFD",
          "id": "19e64874-cbe1-4a37-9922-6696b5fcb2ca",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:58:06.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "EXCLUDED_SECURITY_TYPE",
          "category_desc": "EXCLUDED SECURITY TYPE",
          "code": "Swap Future",
          "code_desc": "Swap Future",
          "id": "1c97afac-1cbf-44a5-8c38-049031580a07",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:58:06.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "EXCLUDED_SECURITY_TYPE",
          "category_desc": "EXCLUDED SECURITY TYPE",
          "code": "Equity CFD",
          "code_desc": "Equity CFD",
          "id": "b2da977c-0b3f-4084-9c0e-ce05af99081e",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:58:06.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "LISTED_SECURITY_TYPE",
          "category_desc": "LISTED SECURITY TYPE",
          "code": "TBill Simple Discount",
          "code_desc": "TBill Simple Discount",
          "id": "c55b003c-1250-47b3-afcc-17f206bd9789",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:58:06.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Exchrate",
          "code_desc": "Exchrate",
          "id": "BDF85DB0-A4F3-499F-B83F-F11965C36CFB",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Commodity Future",
          "code_desc": "Commodity Future",
          "id": "FC1B83C7-1026-4462-8B37-816FA8751200",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Equity ADR",
          "code_desc": "Equity ADR",
          "id": "0CFD20AA-A6EA-43D0-A502-2875E6F8AF7C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Index Future",
          "code_desc": "Index Future",
          "id": "43CC3FFC-7FE3-45A9-9EB0-36DB865B2D22",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "TBill Simple Discount",
          "code_desc": "TBill Simple Discount",
          "id": "4FD46818-F3AC-49ED-A24E-B767BB8D28F5",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Corporate Bond",
          "code_desc": "Corporate Bond",
          "id": "9A2BE5C8-9C32-4C8E-90C4-DE19E36897C0",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Equity HedgeFund",
          "code_desc": "Equity HedgeFund",
          "id": "9FC90AC8-907F-41FC-B6C7-5E61D4635314",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Equity CFD",
          "code_desc": "Equity CFD",
          "id": "AA201693-381F-44DA-AD6E-F82F83BA7E95",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Fully Funded CFD",
          "code_desc": "Fully Funded CFD",
          "id": "ABA1DA6D-E446-4455-A76B-F74BB95DC0A6",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Swap Future",
          "code_desc": "Swap Future",
          "id": "ABF5A475-2BFB-45F6-869D-439D6F9389EB",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Equity",
          "code_desc": "Equity",
          "id": "8188AB38-B543-4637-9478-61E3FBC2DD64",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        },
        {
          "category": "PMA_SECURITY_TYPE",
          "category_desc": "PMA SECURITY TYPE",
          "code": "Government Bond",
          "code_desc": "Government Bond",
          "id": "792EA2DF-1698-4E5A-A3B5-116AD285BC9C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2021-09-07T08:56:31.5666667Z",
          "version_user": "SYSTEM"
        }
      ],
      "status": "success"
    }
  }

  getDcFundInfo() {
    return {
      "data": [
        {
          "classes": [],
          "fund_id": "1000004",
          "fund_name": "RMB High Yield Bond Fund",
          "tradar_code": "CNBB"
        },
        {
          "classes": [],
          "fund_id": "1000003",
          "fund_name": "China New Balance Opportunity Fund",
          "tradar_code": "CNBO"
        },
        {
          "classes": [
            {
              "basket_size": 500000,
              "ccy": "CNY",
              "class_id": "CLS00069",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000023",
          "fund_name": "CSOP FTSE China A50 ETF",
          "tradar_code": "CO-A50F"
        },
        {
          "classes": [],
          "fund_id": "1000039",
          "fund_name": "ABS Direct Eq Fund (S4)",
          "tradar_code": "CO-ABS"
        },
        {
          "classes": [],
          "fund_id": "1000009",
          "fund_name": "CSOP FTSE China A50 ETF (US)",
          "tradar_code": "CO-AFTY"
        },
        {
          "classes": [
            {
              "basket_size": 30000,
              "ccy": "CNY",
              "class_id": "CLS00002",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            },
            {
              "basket_size": 0,
              "ccy": "CNY",
              "class_id": "CLS00202",
              "class_id_name": "Unlisted Class A",
              "share_class_name": "RMB Share Class unlisted A"
            },
            {
              "basket_size": 0,
              "ccy": "CNY",
              "class_id": "CLS00203",
              "class_id_name": "Unlisted Class P",
              "share_class_name": "RMB Share Class P unlisted"
            }
          ],
          "fund_id": "1000002",
          "fund_name": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
          "tradar_code": "CO-B5"
        },
        {
          "classes": [],
          "fund_id": "1000036",
          "fund_name": "China Access Bond Fund SP7",
          "tradar_code": "CO-CABF"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "HKD",
              "class_id": "CLS00242",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class Q"
            },
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00243",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class Q"
            }
          ],
          "fund_id": "1000172",
          "fund_name": "CSOP Changjiang Absolute Return Fund SP",
          "tradar_code": "CO-CCJA"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00240",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000170",
          "fund_name": "CSOP Global Quant Allocation Fund I SP",
          "tradar_code": "CO-CGQA"
        },
        {
          "classes": [
            {
              "basket_size": 500000,
              "ccy": "HKD",
              "class_id": "CLS00178",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            },
            {
              "basket_size": 0,
              "ccy": "HKD",
              "class_id": "CLS00199",
              "class_id_name": "Unlisted class",
              "share_class_name": "HKD Share Class unlisted A"
            }
          ],
          "fund_id": "1000123",
          "fund_name": "CSOP Hang Seng TECH Index ETF",
          "tradar_code": "CO-CHST"
        },
        {
          "classes": [],
          "fund_id": "1000037",
          "fund_name": "Fortune Day International Limited - Discretionary A/C",
          "tradar_code": "CO-CMB"
        },
        {
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "CNY",
              "class_id": "CLS00044",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000010",
          "fund_name": "CSOP SZSE ChiNext ETF",
          "tradar_code": "CO-CNXT"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00238",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000168",
          "fund_name": "CSOP Global Quant Allocation Fund - Bocom Bond Plus II Greater Bay SP",
          "tradar_code": "CO-CQB2"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00239",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000169",
          "fund_name": "CSOP Global Quant Allocation Fund - Bond Plus SP",
          "tradar_code": "CO-CQBP"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00241",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000171",
          "fund_name": "CSOP Global Quant Allocation Growth Fund I SP",
          "tradar_code": "CO-CQGF"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00234",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000165",
          "fund_name": "CSOP Global Quant Allocation Dividend Fund - HT International SP I",
          "tradar_code": "CO-CQHT"
        },
        {
          "classes": [
            {
              "basket_size": 200000,
              "ccy": "CNY",
              "class_id": "CLS00181",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000125",
          "fund_name": "CSOP Yinhua CSI 5G Communications Theme ETF",
          "tradar_code": "CO-CS5G"
        },
        {
          "classes": [],
          "fund_id": "1000033",
          "fund_name": "CSOP Dragon Dynamic China Select Balanced Fund",
          "tradar_code": "CO-CSBF"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "HKD",
              "class_id": "CLS00247",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000175",
          "fund_name": "CSOP Asset Management Limited - SMA 1",
          "tradar_code": "CO-CSM1"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00235",
              "class_id_name": "USD A",
              "share_class_name": "USD Share Class A"
            },
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00236",
              "class_id_name": "USD N1",
              "share_class_name": "USD Share Class N1"
            }
          ],
          "fund_id": "1000166",
          "fund_name": "CSOP Finetree Fund SP",
          "tradar_code": "CO-CSTF"
        },
        {
          "classes": [],
          "fund_id": "1000041",
          "fund_name": "US TECH STRATEGY FUND SP",
          "tradar_code": "CO-CTEC"
        },
        {
          "classes": [
            {
              "basket_size": 1000,
              "ccy": "USD",
              "class_id": "CLS00091",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            },
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00172",
              "class_id_name": "Unlisted Share Class A",
              "share_class_name": "USD Share Class unlisted A"
            }
          ],
          "fund_id": "1000042",
          "fund_name": "CSOP US Dollar Money Market ETF",
          "tradar_code": "CO-CUSF"
        },
        {
          "classes": [],
          "fund_id": "1000020",
          "fund_name": "CSOP FountainCap New Dragon Dividend \u0026 Growth Fund",
          "tradar_code": "CO-FCDG"
        },
        {
          "classes": [],
          "fund_id": "1000021",
          "fund_name": "CSOP DRAGON DYN GTR CHINA PREM EQ FD SP",
          "tradar_code": "CO-GCPE"
        },
        {
          "classes": [],
          "fund_id": "1000007",
          "fund_name": "CSOP China Intelligence A-Share Fund",
          "tradar_code": "CO-HERM"
        },
        {
          "classes": [],
          "fund_id": "1000032",
          "fund_name": "Hong Kong Bond Fund",
          "tradar_code": "CO-HKBF"
        },
        {
          "classes": [],
          "fund_id": "1000040",
          "fund_name": "HKU HK and CN Equity Fund",
          "tradar_code": "CO-HKU"
        },
        {
          "classes": [],
          "fund_id": "1000034",
          "fund_name": "ICBC Advisory Account",
          "tradar_code": "CO-ICBC"
        },
        {
          "classes": [],
          "fund_id": "1000030",
          "fund_name": "IPO Fund",
          "tradar_code": "CO-IPO"
        },
        {
          "classes": [],
          "fund_id": "1000035",
          "fund_name": "Multi Access Fund of Funds SP",
          "tradar_code": "CO-MFOF"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00237",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class I"
            }
          ],
          "fund_id": "1000167",
          "fund_name": "Multi Access Fund of Funds SP",
          "tradar_code": "CO-MFOF"
        },
        {
          "classes": [
            {
              "basket_size": 1000,
              "ccy": "HKD",
              "class_id": "CLS00197",
              "class_id_name": "Unlisted share class P",
              "share_class_name": "HKD Share Class P unlisted"
            },
            {
              "basket_size": 1000,
              "ccy": "HKD",
              "class_id": "CLS00077",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            },
            {
              "basket_size": 1000,
              "ccy": "HKD",
              "class_id": "CLS00173",
              "class_id_name": "Unlisted share class A",
              "share_class_name": "HKD Share Class unlisted A"
            }
          ],
          "fund_id": "1000029",
          "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
          "tradar_code": "CO-MMF"
        },
        {
          "classes": [],
          "fund_id": "1000038",
          "fund_name": "Rock Creek Advisory account",
          "tradar_code": "CO-RC"
        },
        {
          "classes": [],
          "fund_id": "1000028",
          "fund_name": "Simpleway China Value",
          "tradar_code": "CO-SIMP"
        },
        {
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "CNY",
              "class_id": "CLS00186",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000130",
          "fund_name": "CSOP STAR 50 Index ETF",
          "tradar_code": "CO-STAR50"
        },
        {
          "classes": [],
          "fund_id": "1000013",
          "fund_name": "CSOP MSCI T50 ETF",
          "tradar_code": "CO-T50"
        },
        {
          "classes": [],
          "fund_id": "1000016",
          "fund_name": "CSOP Select USD Bond Fund",
          "tradar_code": "CO-USDB"
        },
        {
          "classes": [
            {
              "basket_size": 10000,
              "ccy": "CNY",
              "class_id": "CLS00040",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            },
            {
              "basket_size": 0,
              "ccy": "CNY",
              "class_id": "CLS00198",
              "class_id_name": "Unlisted Share Class A",
              "share_class_name": "RMB Share Class unlisted A"
            }
          ],
          "fund_id": "1000008",
          "fund_name": "CSOP RMB Money Market ETF",
          "tradar_code": "CO-USTB"
        },
        {
          "classes": [],
          "fund_id": "1000011",
          "fund_name": "CSOP China CSI 300 Smart ETF",
          "tradar_code": "HK-300AH"
        },
        {
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "USD",
              "class_id": "CLS00185",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000129",
          "fund_name": "CSOP FTSE China A50 Index Daily (-1x) Inverse Product",
          "tradar_code": "HK-A50-I"
        },
        {
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "USD",
              "class_id": "CLS00184",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000128",
          "fund_name": "CSOP FTSE China A50 Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-A50-L"
        },
        {
          "classes": [
            {
              "basket_size": 500000,
              "ccy": "USD",
              "class_id": "CLS00244",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000173",
          "fund_name": "CSOP Bitcoin Futures ETF",
          "tradar_code": "HK-BTCFUT"
        },
        {
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "USD",
              "class_id": "CLS00188",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000132",
          "fund_name": "CSOP Global Cloud Computing Technology Index ETF",
          "tradar_code": "HK-CLOUD"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "CNY",
              "class_id": "CLS00177",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000122",
          "fund_name": "CSOP CSI 300 Index Daily (-1x) Inverse Product",
          "tradar_code": "HK-CS3-I"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "CNY",
              "class_id": "CLS00176",
              "class_id_name": "CNY",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000121",
          "fund_name": "CSOP CSI 300 Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-CS3-L"
        },
        {
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "CNY",
              "class_id": "CLS00171",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000118",
          "fund_name": "CSOP CSI 500 ETF",
          "tradar_code": "HK-CSI5"
        },
        {
          "classes": [
            {
              "basket_size": 500000,
              "ccy": "USD",
              "class_id": "CLS00245",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000174",
          "fund_name": "CSOP Ether Futures ETF",
          "tradar_code": "HK-DCRFUT"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "USD",
              "class_id": "CLS00192",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000136",
          "fund_name": "CSOP Gold Futures Daily (-1x) Inverse Product",
          "tradar_code": "HK-GLD-I"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "USD",
              "class_id": "CLS00175",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000120",
          "fund_name": "CSOP Gold Futures Daily (2x) Leveraged Product",
          "tradar_code": "HK-GLD-L"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "HKD",
              "class_id": "CLS00071",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000024",
          "fund_name": "CSOP Hang Seng China Enterprises Index Daily (-2x) Inverse Product",
          "tradar_code": "HK-HCE-2I"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "HKD",
              "class_id": "CLS00072",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000025",
          "fund_name": "CSOP Hang Seng China Enterprises Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-HCE-L"
        },
        {
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "HKD",
              "class_id": "CLS00190",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000134",
          "fund_name": "CSOP China Healthcare Disruption Index ETF",
          "tradar_code": "HK-HEALTH"
        },
        {
          "classes": [
            {
              "basket_size": 1000000,
              "ccy": "HKD",
              "class_id": "CLS00187",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            },
            {
              "basket_size": 0,
              "ccy": "HKD",
              "class_id": "CLS00204",
              "class_id_name": "Unlisted Share Class A",
              "share_class_name": "HKD Share Class unlisted A"
            }
          ],
          "fund_id": "1000131",
          "fund_name": "CSOP Hang Seng Index ETF",
          "tradar_code": "HK-HSI"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "HKD",
              "class_id": "CLS00092",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000043",
          "fund_name": "CSOP Hang Seng Index Daily (-2x) Inverse Product",
          "tradar_code": "HK-HSI-2I"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "HKD",
              "class_id": "CLS00073",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000026",
          "fund_name": "CSOP Hang Seng Index Daily (-1x) Inverse Product",
          "tradar_code": "HK-HSI-I"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "HKD",
              "class_id": "CLS00074",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000027",
          "fund_name": "CSOP Hang Seng Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-HSI-L"
        },
        {
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "HKD",
              "class_id": "CLS00183",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000127",
          "fund_name": "CSOP Hang Seng TECH Index Daily (-2x) Inverse Product",
          "tradar_code": "HK-HST-2I"
        },
        {
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "HKD",
              "class_id": "CLS00182",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000126",
          "fund_name": "CSOP Hang Seng TECH Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-HST-L"
        },
        {
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "CNY",
              "class_id": "CLS00229",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000160",
          "fund_name": "CSOP CSI Brokerage Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-IBB-L"
        },
        {
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "USD",
              "class_id": "CLS00201",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000141",
          "fund_name": "CSOP Metaverse Concept ETF",
          "tradar_code": "HK-METAV"
        },
        {
          "classes": [
            {
              "basket_size": 500000,
              "ccy": "CNY",
              "class_id": "CLS00048",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000012",
          "fund_name": "CSOP MSCI China A Inclusion Index ETF",
          "tradar_code": "HK-MSCIA"
        },
        {
          "classes": [
            {
              "basket_size": 500000,
              "ccy": "CNY",
              "class_id": "CLS00196",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000139",
          "fund_name": "CSOP MSCI China A 50 Connect ETF",
          "tradar_code": "HK-MSCIA50"
        },
        {
          "classes": [],
          "fund_id": "1000015",
          "fund_name": "CSOP Nifty 50 Daily (-1x) Inverse Product",
          "tradar_code": "HK-N50I"
        },
        {
          "classes": [],
          "fund_id": "1000014",
          "fund_name": "CSOP Nifty 50 Daily (2x) Leveraged Product",
          "tradar_code": "HK-N50L"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "USD",
              "class_id": "CLS00162",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000113",
          "fund_name": "CSOP NASDAQ-100 Index Daily (-2x) Inverse Product",
          "tradar_code": "HK-NDX-2I"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "USD",
              "class_id": "CLS00174",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000119",
          "fund_name": "CSOP NASDAQ-100 Index Daily (2x) Leveraged Product",
          "tradar_code": "HK-NDX-L"
        },
        {
          "classes": [],
          "fund_id": "1000019",
          "fund_name": "CSOP WTI Oil Annual Roll December Futures ER ETF",
          "tradar_code": "HK-OIL"
        },
        {
          "classes": [
            {
              "basket_size": 800000,
              "ccy": "USD",
              "class_id": "CLS00195",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000138",
          "fund_name": "CSOP WTI Crude Oil Futures Daily (-1x) Inverse Product",
          "tradar_code": "HK-OIL-I"
        },
        {
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "USD",
              "class_id": "CLS00231",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000162",
          "fund_name": "CSOP US Large Oil \u0026 Gas Companies Daily (-2x) Inverse Product",
          "tradar_code": "HK-OLG-I"
        },
        {
          "classes": [
            {
              "basket_size": 400000,
              "ccy": "USD",
              "class_id": "CLS00230",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000161",
          "fund_name": "CSOP US Large Oil \u0026 Gas Companies Daily (2x) Leveraged Product",
          "tradar_code": "HK-OLG-L"
        },
        {
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "CNY",
              "class_id": "CLS00189",
              "class_id_name": "RMB",
              "share_class_name": "RMB Share Class A"
            }
          ],
          "fund_id": "1000133",
          "fund_name": "CSOP Huatai-PineBridge CSI Photovoltaic Industry ETF",
          "tradar_code": "HK-PTVT"
        },
        {
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "USD",
              "class_id": "CLS00191",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000135",
          "fund_name": "CSOP Global Smart Driving Index ETF",
          "tradar_code": "HK-SDRIVE"
        },
        {
          "classes": [
            {
              "basket_size": 100000,
              "ccy": "HKD",
              "class_id": "CLS00066",
              "class_id_name": "HKD",
              "share_class_name": "HKD Share Class A"
            }
          ],
          "fund_id": "1000022",
          "fund_name": "ICBC CSOP S\u0026P NEW CHINA SECTORS ETF",
          "tradar_code": "HK-SPNC"
        },
        {
          "classes": [
            {
              "basket_size": 300000,
              "ccy": "VND",
              "class_id": "CLS00225",
              "class_id_name": "VND",
              "share_class_name": "VND Share Class A"
            }
          ],
          "fund_id": "1000158",
          "fund_name": "CSOP FTSE Vietnam 30 ETF",
          "tradar_code": "HK-VN30"
        },
        {
          "classes": [],
          "fund_id": "1000183",
          "fund_name": "My Choice HK Tracking Fund",
          "tradar_code": "MYCHOICE"
        },
        {
          "classes": [],
          "fund_id": "1000006",
          "fund_name": "CSOP Dragon Dynamic China Select Equity SP",
          "tradar_code": "RQFII-DDCS"
        },
        {
          "classes": [],
          "fund_id": "1000031",
          "fund_name": "CSOP Select RMB Bond Fund",
          "tradar_code": "RQFII-SZHG"
        },
        {
          "classes": [],
          "fund_id": "1000005",
          "fund_name": "CSOP Shen Zhou RMB Fund",
          "tradar_code": "RQFII-SZRMB"
        },
        {
          "classes": [
            {
              "basket_size": 200000,
              "ccy": "USD",
              "class_id": "CLS00226",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000159",
          "fund_name": "CSOP CGS-CIMB FTSE Asia Pacific Low Carbon Index ETF",
          "tradar_code": "SG-ALC"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00232",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000163",
          "fund_name": "CSOP Global Quant Allocation Fund - Balanced II SP",
          "tradar_code": "SG-CSB2"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00233",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000164",
          "fund_name": "CSOP Global Quant Allocation Fund - Balanced I SP",
          "tradar_code": "SG-CSGT"
        },
        {
          "classes": [
            {
              "basket_size": 50000,
              "ccy": "SGD",
              "class_id": "CLS00193",
              "class_id_name": "SGD",
              "share_class_name": "SGD Share Class A"
            }
          ],
          "fund_id": "1000137",
          "fund_name": "CSOP iEdge S-REIT Leaders Index ETF",
          "tradar_code": "SG-REIT"
        },
        {
          "classes": [
            {
              "basket_size": 0,
              "ccy": "USD",
              "class_id": "CLS00205",
              "class_id_name": "USD",
              "share_class_name": "USD Share Class A"
            }
          ],
          "fund_id": "1000142",
          "fund_name": "CSOP USD Money Market Fund",
          "tradar_code": "SG-USDMF"
        },
        {
          "classes": [
            {
              "basket_size": 50000,
              "ccy": "CNY",
              "class_id": "CLS00180",
              "class_id_name": "SGD",
              "share_class_name": "RMB Share Class A"
            },
            {
              "basket_size": 50000,
              "ccy": "CNY",
              "class_id": "CLS00228",
              "class_id_name": "USD",
              "share_class_name": "RMB Share Class I"
            }
          ],
          "fund_id": "1000124",
          "fund_name": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
          "tradar_code": "SG-WGBI"
        },
        {
          "classes": [],
          "fund_id": "1000140",
          "fund_name": "CSOP Metaverse Dummy ETF",
          "tradar_code": "TEST2"
        },
        {
          "classes": [],
          "fund_id": "1000017",
          "fund_name": "CSOP China CSI 300 A-H Dynamic ETF",
          "tradar_code": "US-300AH"
        },
        {
          "classes": [],
          "fund_id": "1000018",
          "fund_name": "CSOP MSCI China A International Hedged ETF",
          "tradar_code": "US-MSCIH"
        }
      ],
      "status": "success"
    }
  }
  getHolidayEnums() {
    return {
      "data": [
        {
          "category": "CCY_CAL_CODES",
          "category_desc": "Ccy Settlement Calendar Codes",
          "code": "CNY",
          "code_desc": "CNY",
          "id": "3F3E7261-6434-4A68-BB7B-7F4E29EAF486",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:31:21.5725006Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "CCY_CAL_CODES",
          "category_desc": "Ccy Settlement Calendar Codes",
          "code": "HKD",
          "code_desc": "HKD",
          "id": "7DC233BE-B574-42A3-81C6-3FDA828B6E68",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:30:11.1129356Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "CCY_CAL_CODES",
          "category_desc": "Ccy Settlement Calendar Codes",
          "code": "SGD",
          "code_desc": "SGD",
          "id": "8DB99625-0CBB-49B2-8BB4-5B29F86C1FB1",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:02:39.0506609Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "CCY_CAL_CODES",
          "category_desc": "Ccy Settlement Calendar Codes",
          "code": "USD",
          "code_desc": "USD",
          "id": "6BACB094-FBAC-456F-9E74-014D9441BB46",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:27:04.4241064Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "CYM",
          "code_desc": "Cayman Islands(CYM)",
          "id": "716A8167-AFD4-4B61-A62B-3E0EF147E85B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T06:26:03.8323832Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "HK",
          "code_desc": "Hong Kong(HK)",
          "id": "417A29DC-6AAC-479C-A02A-23DE2B2BDA27",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T10:21:48.1781189Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "HKD",
          "code_desc": "HKD",
          "id": "6738CC78-17E9-4F06-94E6-A69BC4DDFA93",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-08-22T10:48:00.5376193Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "LUX",
          "code_desc": "Luxembourg(LUX)",
          "id": "AF210980-0D0B-4DBA-A9A7-F5BB64E89A9B",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T10:27:38.4648984Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "SG",
          "code_desc": "Singapore(SG)",
          "id": "D4E8F539-11C7-4104-8128-2259B78193E3",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-31T10:23:03.5620517Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "ENTITIES",
          "category_desc": "Entities",
          "code": "US",
          "code_desc": "US",
          "id": "B5D9F72C-074B-415A-895D-ED19A35A5D2D",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:18:51.7153098Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "CN",
          "code_desc": "CN",
          "id": "F7506029-0FC7-4701-95F0-3209AC2D8A04",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:54:22.3417065Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "HK",
          "code_desc": "HK",
          "id": "192B99F5-AF57-4564-957D-2F7385A47A23",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T08:52:10.8241609Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "HKSC",
          "code_desc": "Hong Kong Stock Connect Market",
          "id": "1294D328-3A7C-4D39-8A57-710116B6446C",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T08:51:58.2468583Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "SG",
          "code_desc": "SG",
          "id": "7562DC71-581D-4BEA-B924-AAB6A12631A5",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-10T09:26:27.1846152Z",
          "version_user": "OPSMANAGER"
        },
        {
          "category": "TRD_CAL_CODES",
          "category_desc": "Trading Calender Codes",
          "code": "US",
          "code_desc": "US",
          "id": "6948EF94-4C2D-41E4-8D8A-FBFBD2A18FA1",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:20:47.796745Z",
          "version_user": "OPSMANAGER"
        }
      ],
      "status": "success"
    }
  }
  getHoldingViews() {
    return {
      "naggrViewData": {
        "equityHoldings": [
          {
            "percentOfNav": 12.9,
            "name": "DASIDGHSDF",
            "isin": "SDFYASBDFGSDF",
            "securityType": "ISHDFYGYSDGF",
            "currency": "SDLFUSDGF",
            "ticker": "FSLDIYFGSDF",
            "country": "FISUDFGSYDF",
            "assetClass": "Equity",
            "averageCost": "SDFIYSGDF",
            "holdingType": "FLSIUDFG",
            "marketPrice": 12.99,
            "sector": "DFSUYDFGSDF",
            "sharesHeld": "FSDYFGSDF",
            "weightage": "FSIODYFGSDF",
            "accountID": "FOSYDFRSDF",
            "exposureNAV": "IYOFGODHFG",
            "exposureFC": "GERGUER",
            "marketValueFC": "FWEIOUFGWYEF",
            "assetGroup": "WEOIYFWGGEF",
            "marketCapitalInUSD": "IUOWHFEYFG",
            "marketCapital": "WEFILUWB",
            "creditRating": "WFIYWGFEW",
            "cds": "FIHWEFYBYFS",

          },
          {
            "percentOfNav": 12.9,
            "name": "DASIDGHSDF",
            "isin": "SDFYASBDFGSDF",
            "securityType": "ISHDFYGYSDGF",
            "currency": "SDLFUSDGF",
            "ticker": "FSLDIYFGSDF",
            "country": "FISUDFGSYDF",
            "assetClass": "Equity",
            "averageCost": "SDFIYSGDF",
            "holdingType": "FLSIUDFG",
            "marketPrice": 12.99,
            "sector": "DFSUYDFGSDF",
            "sharesHeld": "FSDYFGSDF",
            "weightage": "FSIODYFGSDF",
            "accountID": "FOSYDFRSDF",
            "exposureNAV": "IYOFGODHFG",
            "exposureFC": "GERGUER",
            "marketValueFC": "FWEIOUFGWYEF",
            "assetGroup": "WEOIYFWGGEF",
            "marketCapitalInUSD": "IUOWHFEYFG",
            "marketCapital": "WEFILUWB",
            "creditRating": "WFIYWGFEW",
            "cds": "FIHWEFYBYFS",

          },
          {
            "percentOfNav": 12.9,
            "name": "DASIDGHSDF",
            "isin": "SDFYASBDFGSDF",
            "securityType": "ISHDFYGYSDGF",
            "currency": "SDLFUSDGF",
            "ticker": "FSLDIYFGSDF",
            "country": "FISUDFGSYDF",
            "assetClass": "Equity",
            "averageCost": "SDFIYSGDF",
            "holdingType": "FLSIUDFG",
            "marketPrice": 12.99,
            "sector": "DFSUYDFGSDF",
            "sharesHeld": "FSDYFGSDF",
            "weightage": "FSIODYFGSDF",
            "accountID": "FOSYDFRSDF",
            "exposureNAV": "IYOFGODHFG",
            "exposureFC": "GERGUER",
            "marketValueFC": "FWEIOUFGWYEF",
            "assetGroup": "WEOIYFWGGEF",
            "marketCapitalInUSD": "IUOWHFEYFG",
            "marketCapital": "WEFILUWB",
            "creditRating": "WFIYWGFEW",
            "cds": "FIHWEFYBYFS",

          },

        ],
        "bondHoldings": [
          {
            "percentOfNav": "FSIYDFGSDYF",
            "name": "FSDLIFGSYDF",
            "isin": "FWELIFBYWEF",
            "securityType": "FSDLKUFSGDHF",
            "currency": "FIWYEFBWEF",
            "ticker": "FWILEFGWEF",
            "accountID": "FWEIYFGWEF",
            "exposureNAV": "EIRYGEFWD",
            "exposureFC": "GWEHGUYEWF",
            "marketValueFC": "GIOWREHGYGE",
            "assetGroup": "FWIE7HGYFWFE",
            "country": "FWYGFGWEHFR",
            "sector": "FWEGHFYWD",
            "coupon": "FWUEGFYVWF",
            "maturity": "FYWOGEF",
            "creditRating": "FSDUTBFUEG",
            "marketPrice": "WFELYGFWEF",
            "yieldToMaturity": "FWIUEFGWEHF",
            "modifiedDuration": "IDAOUSHSDF",
            "issuer": "FSDUSDFHGSDFH",
            "convexity": "FSDPFISDUFM",
            "dv01": "FDSLISNDFUSHDF",
            "remainingMaturity": "FSDUFSUDHF",
            "subsector": "FSDUFHSIUDFHU",
            "assetClass": "Bond",

          },
          {
            "percentOfNav": "FSIYDFGSDYF",
            "name": "FSDLIFGSYDF",
            "isin": "FWELIFBYWEF",
            "securityType": "FSDLKUFSGDHF",
            "currency": "FIWYEFBWEF",
            "ticker": "FWILEFGWEF",
            "accountID": "FWEIYFGWEF",
            "exposureNAV": "EIRYGEFWD",
            "exposureFC": "GWEHGUYEWF",
            "marketValueFC": "GIOWREHGYGE",
            "assetGroup": "FWIE7HGYFWFE",
            "country": "FWYGFGWEHFR",
            "sector": "FWEGHFYWD",
            "coupon": "FWUEGFYVWF",
            "maturity": "FYWOGEF",
            "creditRating": "FSDUTBFUEG",
            "marketPrice": "WFELYGFWEF",
            "yieldToMaturity": "FWIUEFGWEHF",
            "modifiedDuration": "IDAOUSHSDF",
            "issuer": "FSDUSDFHGSDFH",
            "convexity": "FSDPFISDUFM",
            "dv01": "FDSLISNDFUSHDF",
            "remainingMaturity": "FSDUFSUDHF",
            "subsector": "FSDUFHSIUDFHU",
            "assetClass": "Bond",

          },
          {
            "percentOfNav": "FSIYDFGSDYF",
            "name": "FSDLIFGSYDF",
            "isin": "FWELIFBYWEF",
            "securityType": "FSDLKUFSGDHF",
            "currency": "FIWYEFBWEF",
            "ticker": "FWILEFGWEF",
            "accountID": "FWEIYFGWEF",
            "exposureNAV": "EIRYGEFWD",
            "exposureFC": "GWEHGUYEWF",
            "marketValueFC": "GIOWREHGYGE",
            "assetGroup": "FWIE7HGYFWFE",
            "country": "FWYGFGWEHFR",
            "sector": "FWEGHFYWD",
            "coupon": "FWUEGFYVWF",
            "maturity": "FYWOGEF",
            "creditRating": "FSDUTBFUEG",
            "marketPrice": "WFELYGFWEF",
            "yieldToMaturity": "FWIUEFGWEHF",
            "modifiedDuration": "IDAOUSHSDF",
            "issuer": "FSDUSDFHGSDFH",
            "convexity": "FSDPFISDUFM",
            "dv01": "FDSLISNDFUSHDF",
            "remainingMaturity": "FSDUFSUDHF",
            "subsector": "FSDUFHSIUDFHU",
            "assetClass": "Bond",

          },

        ],
        "derivativeHoldings": [
          {
            "assetClass": "Derivatives",
            "percentOfNav": "FSDFHUHSDFN",
            "name": "WFEWUEFGB",
            "isin": "FSIDUFNSUDHF",
            "securityType": "SFDIUFNSUHDF",
            "currency": "FWUEFHUWHEF",
            "ticker": "FWUOEFUWER",
            "accountID": "FWEUORHUW",
            "exposureNAV": "WFEOUWEURH",
            "exposureFC": "WEOIRUWEGR",
            "marketValueFC": "RWEORU",
            "assetGroup": "WEOIRU",
            "country": "WEOHRUWER",
            "sector": "RWEORHU",
            "quantity": "WEROIJEHUR",
            "creditRating": "RWEORHRU",
            "cds": "WEORUWER",

          },
          {
            "assetClass": "Derivatives",
            "percentOfNav": "FSDFHUHSDFN",
            "name": "WFEWUEFGB",
            "isin": "FSIDUFNSUDHF",
            "securityType": "SFDIUFNSUHDF",
            "currency": "FWUEFHUWHEF",
            "ticker": "FWUOEFUWER",
            "accountID": "FWEUORHUW",
            "exposureNAV": "WFEOUWEURH",
            "exposureFC": "WEOIRUWEGR",
            "marketValueFC": "RWEORU",
            "assetGroup": "WEOIRU",
            "country": "WEOHRUWER",
            "sector": "RWEORHU",
            "quantity": "WEROIJEHUR",
            "creditRating": "RWEORHRU",
            "cds": "WEORUWER",

          },
          {
            "assetClass": "Derivatives",
            "percentOfNav": "FSDFHUHSDFN",
            "name": "WFEWUEFGB",
            "isin": "FSIDUFNSUDHF",
            "securityType": "SFDIUFNSUHDF",
            "currency": "FWUEFHUWHEF",
            "ticker": "FWUOEFUWER",
            "accountID": "FWEUORHUW",
            "exposureNAV": "WFEOUWEURH",
            "exposureFC": "WEOIRUWEGR",
            "marketValueFC": "RWEORU",
            "assetGroup": "WEOIRU",
            "country": "WEOHRUWER",
            "sector": "RWEORHU",
            "quantity": "WEROIJEHUR",
            "creditRating": "RWEORHRU",
            "cds": "WEORUWER",

          },

        ],
        "moneyMarketHoldings": [
          {
            "percentOfNav": "DIASUFGYSDF",
            "name": "SDFIUSBYDVF",
            "isin": "SFDFIUSDLGF",
            "securityType": "FSDLIFHUS",
            "currency": "SFDFJSDF",
            "ticker": "SOUDFGYDF",
            "accountID": "SFDUHFSUDF",
            "exposureNAV": "FSDUFNOBSYDGF",
            "exposureFC": "SFDUFHSDF",
            "marketValueFC": "SFDFHUSDYF",
            "assetGroup": "FSDUFGYSDF",
            "country": "FSUDIFHGSYDF",
            "sector": "SFDUHFGYDSH",
            "maturity": "SFDIUFGYSDFH",
            "creditRating": "SDIF7GYAWLUE",
            "marketPrice": "WELFU7WBIYGEY",
            "issuer": "WLFEI7HWEUR",
            "convexity": "EIF7HWEIYFGU",
            "dv01": "WEIRUYGWERU",
            "assetClass": "Money Market",

          },
          {
            "percentOfNav": "DIASUFGYSDF",
            "name": "SDFIUSBYDVF",
            "isin": "SFDFIUSDLGF",
            "securityType": "FSDLIFHUS",
            "currency": "SFDFJSDF",
            "ticker": "SOUDFGYDF",
            "accountID": "SFDUHFSUDF",
            "exposureNAV": "FSDUFNOBSYDGF",
            "exposureFC": "SFDUFHSDF",
            "marketValueFC": "SFDFHUSDYF",
            "assetGroup": "FSDUFGYSDF",
            "country": "FSUDIFHGSYDF",
            "sector": "SFDUHFGYDSH",
            "maturity": "SFDIUFGYSDFH",
            "creditRating": "SDIF7GYAWLUE",
            "marketPrice": "WELFU7WBIYGEY",
            "issuer": "WLFEI7HWEUR",
            "convexity": "EIF7HWEIYFGU",
            "dv01": "WEIRUYGWERU",
            "assetClass": "Money Market",

          },
          {
            "percentOfNav": "DIASUFGYSDF",
            "name": "SDFIUSBYDVF",
            "isin": "SFDFIUSDLGF",
            "securityType": "FSDLIFHUS",
            "currency": "SFDFJSDF",
            "ticker": "SOUDFGYDF",
            "accountID": "SFDUHFSUDF",
            "exposureNAV": "FSDUFNOBSYDGF",
            "exposureFC": "SFDUFHSDF",
            "marketValueFC": "SFDFHUSDYF",
            "assetGroup": "FSDUFGYSDF",
            "country": "FSUDIFHGSYDF",
            "sector": "SFDUHFGYDSH",
            "maturity": "SFDIUFGYSDFH",
            "creditRating": "SDIF7GYAWLUE",
            "marketPrice": "WELFU7WBIYGEY",
            "issuer": "WLFEI7HWEUR",
            "convexity": "EIF7HWEIYFGU",
            "dv01": "WEIRUYGWERU",
            "assetClass": "Money Market",

          },

        ],

      },
      "aggrViewData": {
        "equityHoldings": [
          {
            "percentOfNav": 12.9,
            "name": "DASIDGHSDF",
            "isin": "SDFYASBDFGSDF",
            "securityType": "ISHDFYGYSDGF",
            "currency": "SDLFUSDGF",
            "ticker": "FSLDIYFGSDF",
            "country": "FISUDFGSYDF",
            "assetClass": "Equity",
            "averageCost": "SDFIYSGDF",
            "holdingType": "FLSIUDFG",
            "marketPrice": 12.99,
            "sector": "DFSUYDFGSDF",
            "sharesHeld": "FSDYFGSDF",
            "weightage": "FSIODYFGSDF",
            "accountID": "FOSYDFRSDF",
            "exposureNAV": "IYOFGODHFG",
            "exposureFC": "GERGUER",
            "marketValueFC": "FWEIOUFGWYEF",
            "assetGroup": "WEOIYFWGGEF",
            "marketCapitalInUSD": "IUOWHFEYFG",
            "marketCapital": "WEFILUWB",
            "creditRating": "WFIYWGFEW",
            "cds": "FIHWEFYBYFS",

          },
          {
            "percentOfNav": 12.9,
            "name": "DASIDGHSDF",
            "isin": "SDFYASBDFGSDF",
            "securityType": "ISHDFYGYSDGF",
            "currency": "SDLFUSDGF",
            "ticker": "FSLDIYFGSDF",
            "country": "FISUDFGSYDF",
            "assetClass": "Equity",
            "averageCost": "SDFIYSGDF",
            "holdingType": "FLSIUDFG",
            "marketPrice": 12.99,
            "sector": "DFSUYDFGSDF",
            "sharesHeld": "FSDYFGSDF",
            "weightage": "FSIODYFGSDF",
            "accountID": "FOSYDFRSDF",
            "exposureNAV": "IYOFGODHFG",
            "exposureFC": "GERGUER",
            "marketValueFC": "FWEIOUFGWYEF",
            "assetGroup": "WEOIYFWGGEF",
            "marketCapitalInUSD": "IUOWHFEYFG",
            "marketCapital": "WEFILUWB",
            "creditRating": "WFIYWGFEW",
            "cds": "FIHWEFYBYFS",

          },
          {
            "percentOfNav": 12.9,
            "name": "DASIDGHSDF",
            "isin": "SDFYASBDFGSDF",
            "securityType": "ISHDFYGYSDGF",
            "currency": "SDLFUSDGF",
            "ticker": "FSLDIYFGSDF",
            "country": "FISUDFGSYDF",
            "assetClass": "Equity",
            "averageCost": "SDFIYSGDF",
            "holdingType": "FLSIUDFG",
            "marketPrice": 12.99,
            "sector": "DFSUYDFGSDF",
            "sharesHeld": "FSDYFGSDF",
            "weightage": "FSIODYFGSDF",
            "accountID": "FOSYDFRSDF",
            "exposureNAV": "IYOFGODHFG",
            "exposureFC": "GERGUER",
            "marketValueFC": "FWEIOUFGWYEF",
            "assetGroup": "WEOIYFWGGEF",
            "marketCapitalInUSD": "IUOWHFEYFG",
            "marketCapital": "WEFILUWB",
            "creditRating": "WFIYWGFEW",
            "cds": "FIHWEFYBYFS",

          },

        ],
        "bondHoldings": [
          {
            "percentOfNav": "FSIYDFGSDYF",
            "name": "FSDLIFGSYDF",
            "isin": "FWELIFBYWEF",
            "securityType": "FSDLKUFSGDHF",
            "currency": "FIWYEFBWEF",
            "ticker": "FWILEFGWEF",
            "accountID": "FWEIYFGWEF",
            "exposureNAV": "EIRYGEFWD",
            "exposureFC": "GWEHGUYEWF",
            "marketValueFC": "GIOWREHGYGE",
            "assetGroup": "FWIE7HGYFWFE",
            "country": "FWYGFGWEHFR",
            "sector": "FWEGHFYWD",
            "coupon": "FWUEGFYVWF",
            "maturity": "FYWOGEF",
            "creditRating": "FSDUTBFUEG",
            "marketPrice": "WFELYGFWEF",
            "yieldToMaturity": "FWIUEFGWEHF",
            "modifiedDuration": "IDAOUSHSDF",
            "issuer": "FSDUSDFHGSDFH",
            "convexity": "FSDPFISDUFM",
            "dv01": "FDSLISNDFUSHDF",
            "remainingMaturity": "FSDUFSUDHF",
            "subsector": "FSDUFHSIUDFHU",
            "assetClass": "Bond",

          },
          {
            "percentOfNav": "FSIYDFGSDYF",
            "name": "FSDLIFGSYDF",
            "isin": "FWELIFBYWEF",
            "securityType": "FSDLKUFSGDHF",
            "currency": "FIWYEFBWEF",
            "ticker": "FWILEFGWEF",
            "accountID": "FWEIYFGWEF",
            "exposureNAV": "EIRYGEFWD",
            "exposureFC": "GWEHGUYEWF",
            "marketValueFC": "GIOWREHGYGE",
            "assetGroup": "FWIE7HGYFWFE",
            "country": "FWYGFGWEHFR",
            "sector": "FWEGHFYWD",
            "coupon": "FWUEGFYVWF",
            "maturity": "FYWOGEF",
            "creditRating": "FSDUTBFUEG",
            "marketPrice": "WFELYGFWEF",
            "yieldToMaturity": "FWIUEFGWEHF",
            "modifiedDuration": "IDAOUSHSDF",
            "issuer": "FSDUSDFHGSDFH",
            "convexity": "FSDPFISDUFM",
            "dv01": "FDSLISNDFUSHDF",
            "remainingMaturity": "FSDUFSUDHF",
            "subsector": "FSDUFHSIUDFHU",
            "assetClass": "Bond",

          },
          {
            "percentOfNav": "FSIYDFGSDYF",
            "name": "FSDLIFGSYDF",
            "isin": "FWELIFBYWEF",
            "securityType": "FSDLKUFSGDHF",
            "currency": "FIWYEFBWEF",
            "ticker": "FWILEFGWEF",
            "accountID": "FWEIYFGWEF",
            "exposureNAV": "EIRYGEFWD",
            "exposureFC": "GWEHGUYEWF",
            "marketValueFC": "GIOWREHGYGE",
            "assetGroup": "FWIE7HGYFWFE",
            "country": "FWYGFGWEHFR",
            "sector": "FWEGHFYWD",
            "coupon": "FWUEGFYVWF",
            "maturity": "FYWOGEF",
            "creditRating": "FSDUTBFUEG",
            "marketPrice": "WFELYGFWEF",
            "yieldToMaturity": "FWIUEFGWEHF",
            "modifiedDuration": "IDAOUSHSDF",
            "issuer": "FSDUSDFHGSDFH",
            "convexity": "FSDPFISDUFM",
            "dv01": "FDSLISNDFUSHDF",
            "remainingMaturity": "FSDUFSUDHF",
            "subsector": "FSDUFHSIUDFHU",
            "assetClass": "Bond",

          },

        ],
        "derivativeHoldings": [
          {
            "assetClass": "Derivatives",
            "percentOfNav": "FSDFHUHSDFN",
            "name": "WFEWUEFGB",
            "isin": "FSIDUFNSUDHF",
            "securityType": "SFDIUFNSUHDF",
            "currency": "FWUEFHUWHEF",
            "ticker": "FWUOEFUWER",
            "accountID": "FWEUORHUW",
            "exposureNAV": "WFEOUWEURH",
            "exposureFC": "WEOIRUWEGR",
            "marketValueFC": "RWEORU",
            "assetGroup": "WEOIRU",
            "country": "WEOHRUWER",
            "sector": "RWEORHU",
            "quantity": "WEROIJEHUR",
            "creditRating": "RWEORHRU",
            "cds": "WEORUWER",

          },
          {
            "assetClass": "Derivatives",
            "percentOfNav": "FSDFHUHSDFN",
            "name": "WFEWUEFGB",
            "isin": "FSIDUFNSUDHF",
            "securityType": "SFDIUFNSUHDF",
            "currency": "FWUEFHUWHEF",
            "ticker": "FWUOEFUWER",
            "accountID": "FWEUORHUW",
            "exposureNAV": "WFEOUWEURH",
            "exposureFC": "WEOIRUWEGR",
            "marketValueFC": "RWEORU",
            "assetGroup": "WEOIRU",
            "country": "WEOHRUWER",
            "sector": "RWEORHU",
            "quantity": "WEROIJEHUR",
            "creditRating": "RWEORHRU",
            "cds": "WEORUWER",

          },
          {
            "assetClass": "Derivatives",
            "percentOfNav": "FSDFHUHSDFN",
            "name": "WFEWUEFGB",
            "isin": "FSIDUFNSUDHF",
            "securityType": "SFDIUFNSUHDF",
            "currency": "FWUEFHUWHEF",
            "ticker": "FWUOEFUWER",
            "accountID": "FWEUORHUW",
            "exposureNAV": "WFEOUWEURH",
            "exposureFC": "WEOIRUWEGR",
            "marketValueFC": "RWEORU",
            "assetGroup": "WEOIRU",
            "country": "WEOHRUWER",
            "sector": "RWEORHU",
            "quantity": "WEROIJEHUR",
            "creditRating": "RWEORHRU",
            "cds": "WEORUWER",

          },

        ],
        "moneyMarketHoldings": [
          {
            "percentOfNav": "DIASUFGYSDF",
            "name": "SDFIUSBYDVF",
            "isin": "SFDFIUSDLGF",
            "securityType": "FSDLIFHUS",
            "currency": "SFDFJSDF",
            "ticker": "SOUDFGYDF",
            "accountID": "SFDUHFSUDF",
            "exposureNAV": "FSDUFNOBSYDGF",
            "exposureFC": "SFDUFHSDF",
            "marketValueFC": "SFDFHUSDYF",
            "assetGroup": "FSDUFGYSDF",
            "country": "FSUDIFHGSYDF",
            "sector": "SFDUHFGYDSH",
            "maturity": "SFDIUFGYSDFH",
            "creditRating": "SDIF7GYAWLUE",
            "marketPrice": "WELFU7WBIYGEY",
            "issuer": "WLFEI7HWEUR",
            "convexity": "EIF7HWEIYFGU",
            "dv01": "WEIRUYGWERU",
            "assetClass": "Money Market",

          },
          {
            "percentOfNav": "DIASUFGYSDF",
            "name": "SDFIUSBYDVF",
            "isin": "SFDFIUSDLGF",
            "securityType": "FSDLIFHUS",
            "currency": "SFDFJSDF",
            "ticker": "SOUDFGYDF",
            "accountID": "SFDUHFSUDF",
            "exposureNAV": "FSDUFNOBSYDGF",
            "exposureFC": "SFDUFHSDF",
            "marketValueFC": "SFDFHUSDYF",
            "assetGroup": "FSDUFGYSDF",
            "country": "FSUDIFHGSYDF",
            "sector": "SFDUHFGYDSH",
            "maturity": "SFDIUFGYSDFH",
            "creditRating": "SDIF7GYAWLUE",
            "marketPrice": "WELFU7WBIYGEY",
            "issuer": "WLFEI7HWEUR",
            "convexity": "EIF7HWEIYFGU",
            "dv01": "WEIRUYGWERU",
            "assetClass": "Money Market",

          },
          {
            "percentOfNav": "DIASUFGYSDF",
            "name": "SDFIUSBYDVF",
            "isin": "SFDFIUSDLGF",
            "securityType": "FSDLIFHUS",
            "currency": "SFDFJSDF",
            "ticker": "SOUDFGYDF",
            "accountID": "SFDUHFSUDF",
            "exposureNAV": "FSDUFNOBSYDGF",
            "exposureFC": "SFDUFHSDF",
            "marketValueFC": "SFDFHUSDYF",
            "assetGroup": "FSDUFGYSDF",
            "country": "FSUDIFHGSYDF",
            "sector": "SFDUHFGYDSH",
            "maturity": "SFDIUFGYSDFH",
            "creditRating": "SDIF7GYAWLUE",
            "marketPrice": "WELFU7WBIYGEY",
            "issuer": "WLFEI7HWEUR",
            "convexity": "EIF7HWEIYFGU",
            "dv01": "WEIRUYGWERU",
            "assetClass": "Money Market",

          },

        ],


      },
      "fdiViewData": {
        "equityHoldings": [
          {
            "percentOfNav": 12.9,
            "name": "DASIDGHSDF",
            "isin": "SDFYASBDFGSDF",
            "securityType": "ISHDFYGYSDGF",
            "currency": "SDLFUSDGF",
            "ticker": "FSLDIYFGSDF",
            "country": "FISUDFGSYDF",
            "assetClass": "Equity",
            "averageCost": "SDFIYSGDF",
            "holdingType": "FLSIUDFG",
            "marketPrice": 12.99,
            "sector": "DFSUYDFGSDF",
            "sharesHeld": "FSDYFGSDF",
            "weightage": "FSIODYFGSDF",
            "accountID": "FOSYDFRSDF",
            "exposureNAV": "IYOFGODHFG",
            "exposureFC": "GERGUER",
            "marketValueFC": "FWEIOUFGWYEF",
            "assetGroup": "WEOIYFWGGEF",
            "marketCapitalInUSD": "IUOWHFEYFG",
            "marketCapital": "WEFILUWB",
            "creditRating": "WFIYWGFEW",
            "cds": "FIHWEFYBYFS",

          },
          {
            "percentOfNav": 12.9,
            "name": "DASIDGHSDF",
            "isin": "SDFYASBDFGSDF",
            "securityType": "ISHDFYGYSDGF",
            "currency": "SDLFUSDGF",
            "ticker": "FSLDIYFGSDF",
            "country": "FISUDFGSYDF",
            "assetClass": "Equity",
            "averageCost": "SDFIYSGDF",
            "holdingType": "FLSIUDFG",
            "marketPrice": 12.99,
            "sector": "DFSUYDFGSDF",
            "sharesHeld": "FSDYFGSDF",
            "weightage": "FSIODYFGSDF",
            "accountID": "FOSYDFRSDF",
            "exposureNAV": "IYOFGODHFG",
            "exposureFC": "GERGUER",
            "marketValueFC": "FWEIOUFGWYEF",
            "assetGroup": "WEOIYFWGGEF",
            "marketCapitalInUSD": "IUOWHFEYFG",
            "marketCapital": "WEFILUWB",
            "creditRating": "WFIYWGFEW",
            "cds": "FIHWEFYBYFS",

          },
          {
            "percentOfNav": 12.9,
            "name": "DASIDGHSDF",
            "isin": "SDFYASBDFGSDF",
            "securityType": "ISHDFYGYSDGF",
            "currency": "SDLFUSDGF",
            "ticker": "FSLDIYFGSDF",
            "country": "FISUDFGSYDF",
            "assetClass": "Equity",
            "averageCost": "SDFIYSGDF",
            "holdingType": "FLSIUDFG",
            "marketPrice": 12.99,
            "sector": "DFSUYDFGSDF",
            "sharesHeld": "FSDYFGSDF",
            "weightage": "FSIODYFGSDF",
            "accountID": "FOSYDFRSDF",
            "exposureNAV": "IYOFGODHFG",
            "exposureFC": "GERGUER",
            "marketValueFC": "FWEIOUFGWYEF",
            "assetGroup": "WEOIYFWGGEF",
            "marketCapitalInUSD": "IUOWHFEYFG",
            "marketCapital": "WEFILUWB",
            "creditRating": "WFIYWGFEW",
            "cds": "FIHWEFYBYFS",

          },

        ],
        "bondHoldings": [
          {
            "percentOfNav": "FSIYDFGSDYF",
            "name": "FSDLIFGSYDF",
            "isin": "FWELIFBYWEF",
            "securityType": "FSDLKUFSGDHF",
            "currency": "FIWYEFBWEF",
            "ticker": "FWILEFGWEF",
            "accountID": "FWEIYFGWEF",
            "exposureNAV": "EIRYGEFWD",
            "exposureFC": "GWEHGUYEWF",
            "marketValueFC": "GIOWREHGYGE",
            "assetGroup": "FWIE7HGYFWFE",
            "country": "FWYGFGWEHFR",
            "sector": "FWEGHFYWD",
            "coupon": "FWUEGFYVWF",
            "maturity": "FYWOGEF",
            "creditRating": "FSDUTBFUEG",
            "marketPrice": "WFELYGFWEF",
            "yieldToMaturity": "FWIUEFGWEHF",
            "modifiedDuration": "IDAOUSHSDF",
            "issuer": "FSDUSDFHGSDFH",
            "convexity": "FSDPFISDUFM",
            "dv01": "FDSLISNDFUSHDF",
            "remainingMaturity": "FSDUFSUDHF",
            "subsector": "FSDUFHSIUDFHU",
            "assetClass": "Bond",

          },
          {
            "percentOfNav": "FSIYDFGSDYF",
            "name": "FSDLIFGSYDF",
            "isin": "FWELIFBYWEF",
            "securityType": "FSDLKUFSGDHF",
            "currency": "FIWYEFBWEF",
            "ticker": "FWILEFGWEF",
            "accountID": "FWEIYFGWEF",
            "exposureNAV": "EIRYGEFWD",
            "exposureFC": "GWEHGUYEWF",
            "marketValueFC": "GIOWREHGYGE",
            "assetGroup": "FWIE7HGYFWFE",
            "country": "FWYGFGWEHFR",
            "sector": "FWEGHFYWD",
            "coupon": "FWUEGFYVWF",
            "maturity": "FYWOGEF",
            "creditRating": "FSDUTBFUEG",
            "marketPrice": "WFELYGFWEF",
            "yieldToMaturity": "FWIUEFGWEHF",
            "modifiedDuration": "IDAOUSHSDF",
            "issuer": "FSDUSDFHGSDFH",
            "convexity": "FSDPFISDUFM",
            "dv01": "FDSLISNDFUSHDF",
            "remainingMaturity": "FSDUFSUDHF",
            "subsector": "FSDUFHSIUDFHU",
            "assetClass": "Bond",

          },
          {
            "percentOfNav": "FSIYDFGSDYF",
            "name": "FSDLIFGSYDF",
            "isin": "FWELIFBYWEF",
            "securityType": "FSDLKUFSGDHF",
            "currency": "FIWYEFBWEF",
            "ticker": "FWILEFGWEF",
            "accountID": "FWEIYFGWEF",
            "exposureNAV": "EIRYGEFWD",
            "exposureFC": "GWEHGUYEWF",
            "marketValueFC": "GIOWREHGYGE",
            "assetGroup": "FWIE7HGYFWFE",
            "country": "FWYGFGWEHFR",
            "sector": "FWEGHFYWD",
            "coupon": "FWUEGFYVWF",
            "maturity": "FYWOGEF",
            "creditRating": "FSDUTBFUEG",
            "marketPrice": "WFELYGFWEF",
            "yieldToMaturity": "FWIUEFGWEHF",
            "modifiedDuration": "IDAOUSHSDF",
            "issuer": "FSDUSDFHGSDFH",
            "convexity": "FSDPFISDUFM",
            "dv01": "FDSLISNDFUSHDF",
            "remainingMaturity": "FSDUFSUDHF",
            "subsector": "FSDUFHSIUDFHU",
            "assetClass": "Bond",

          },

        ],
        "derivativeHoldings": [
          {
            "assetClass": "Derivatives",
            "percentOfNav": "FSDFHUHSDFN",
            "name": "WFEWUEFGB",
            "isin": "FSIDUFNSUDHF",
            "securityType": "SFDIUFNSUHDF",
            "currency": "FWUEFHUWHEF",
            "ticker": "FWUOEFUWER",
            "accountID": "FWEUORHUW",
            "exposureNAV": "WFEOUWEURH",
            "exposureFC": "WEOIRUWEGR",
            "marketValueFC": "RWEORU",
            "assetGroup": "WEOIRU",
            "country": "WEOHRUWER",
            "sector": "RWEORHU",
            "quantity": "WEROIJEHUR",
            "creditRating": "RWEORHRU",
            "cds": "WEORUWER",

          },
          {
            "assetClass": "Derivatives",
            "percentOfNav": "FSDFHUHSDFN",
            "name": "WFEWUEFGB",
            "isin": "FSIDUFNSUDHF",
            "securityType": "SFDIUFNSUHDF",
            "currency": "FWUEFHUWHEF",
            "ticker": "FWUOEFUWER",
            "accountID": "FWEUORHUW",
            "exposureNAV": "WFEOUWEURH",
            "exposureFC": "WEOIRUWEGR",
            "marketValueFC": "RWEORU",
            "assetGroup": "WEOIRU",
            "country": "WEOHRUWER",
            "sector": "RWEORHU",
            "quantity": "WEROIJEHUR",
            "creditRating": "RWEORHRU",
            "cds": "WEORUWER",

          },
          {
            "assetClass": "Derivatives",
            "percentOfNav": "FSDFHUHSDFN",
            "name": "WFEWUEFGB",
            "isin": "FSIDUFNSUDHF",
            "securityType": "SFDIUFNSUHDF",
            "currency": "FWUEFHUWHEF",
            "ticker": "FWUOEFUWER",
            "accountID": "FWEUORHUW",
            "exposureNAV": "WFEOUWEURH",
            "exposureFC": "WEOIRUWEGR",
            "marketValueFC": "RWEORU",
            "assetGroup": "WEOIRU",
            "country": "WEOHRUWER",
            "sector": "RWEORHU",
            "quantity": "WEROIJEHUR",
            "creditRating": "RWEORHRU",
            "cds": "WEORUWER",

          },

        ],
        "moneyMarketHoldings": [
          {
            "percentOfNav": "DIASUFGYSDF",
            "name": "SDFIUSBYDVF",
            "isin": "SFDFIUSDLGF",
            "securityType": "FSDLIFHUS",
            "currency": "SFDFJSDF",
            "ticker": "SOUDFGYDF",
            "accountID": "SFDUHFSUDF",
            "exposureNAV": "FSDUFNOBSYDGF",
            "exposureFC": "SFDUFHSDF",
            "marketValueFC": "SFDFHUSDYF",
            "assetGroup": "FSDUFGYSDF",
            "country": "FSUDIFHGSYDF",
            "sector": "SFDUHFGYDSH",
            "maturity": "SFDIUFGYSDFH",
            "creditRating": "SDIF7GYAWLUE",
            "marketPrice": "WELFU7WBIYGEY",
            "issuer": "WLFEI7HWEUR",
            "convexity": "EIF7HWEIYFGU",
            "dv01": "WEIRUYGWERU",
            "assetClass": "Money Market",

          },
          {
            "percentOfNav": "DIASUFGYSDF",
            "name": "SDFIUSBYDVF",
            "isin": "SFDFIUSDLGF",
            "securityType": "FSDLIFHUS",
            "currency": "SFDFJSDF",
            "ticker": "SOUDFGYDF",
            "accountID": "SFDUHFSUDF",
            "exposureNAV": "FSDUFNOBSYDGF",
            "exposureFC": "SFDUFHSDF",
            "marketValueFC": "SFDFHUSDYF",
            "assetGroup": "FSDUFGYSDF",
            "country": "FSUDIFHGSYDF",
            "sector": "SFDUHFGYDSH",
            "maturity": "SFDIUFGYSDFH",
            "creditRating": "SDIF7GYAWLUE",
            "marketPrice": "WELFU7WBIYGEY",
            "issuer": "WLFEI7HWEUR",
            "convexity": "EIF7HWEIYFGU",
            "dv01": "WEIRUYGWERU",
            "assetClass": "Money Market",

          },
          {
            "percentOfNav": "DIASUFGYSDF",
            "name": "SDFIUSBYDVF",
            "isin": "SFDFIUSDLGF",
            "securityType": "FSDLIFHUS",
            "currency": "SFDFJSDF",
            "ticker": "SOUDFGYDF",
            "accountID": "SFDUHFSUDF",
            "exposureNAV": "FSDUFNOBSYDGF",
            "exposureFC": "SFDUFHSDF",
            "marketValueFC": "SFDFHUSDYF",
            "assetGroup": "FSDUFGYSDF",
            "country": "FSUDIFHGSYDF",
            "sector": "SFDUHFGYDSH",
            "maturity": "SFDIUFGYSDFH",
            "creditRating": "SDIF7GYAWLUE",
            "marketPrice": "WELFU7WBIYGEY",
            "issuer": "WLFEI7HWEUR",
            "convexity": "EIF7HWEIYFGU",
            "dv01": "WEIRUYGWERU",
            "assetClass": "Money Market",

          },

        ],

      },
      "countryWiseExposureList": [
        {
          "exposure": 99.95,
          "label": "HK-listed stocks"
        },
        {
          "exposure": 0.05,
          "label": "Cash and Others "
        }
      ],

      "curMktCapWiseExposureList": [
        {
          "exposure": 90.4,
          "label": "Large Cap (\u003e7692.307692 Mn)"
        },
        {
          "exposure": 9.56,
          "label": "Mid Cap (1282.051282 Mn - 7692.307692 Mn)"
        },
        {
          "exposure": 0,
          "label": "Small Cap (\u003c1282.051282 Mn)"
        }
      ],

      "sectorWiseExposureList": [
        {
          "exposure": 46.85,
          "label": "Technology"
        },
        {
          "exposure": 24.75,
          "label": "Consumer Discretionary"
        },
        {
          "exposure": 12.89,
          "label": "Consumer Staples"
        },
        {
          "exposure": 11.35,
          "label": "Communications"
        },
        {
          "exposure": 2.06,
          "label": "Financials"
        },
        {
          "exposure": 1.74,
          "label": "Health Care"
        },
        {
          "exposure": 0.33,
          "label": "Industrials"
        },
        {
          "exposure": 0.05,
          "label": "Cash and Others "
        }
      ],
    }
  }

  getHoldings() {
    return {
      "cashDividend": [

      ],
      "cashNOthersExposure": {
        "countryWiseExclusion": {

        },
        "sectorWiseExclusion": {

        },
        "totalExclusion": 0
      },
      "countryWiseExposureList": [
        {
          "exposure": 100,
          "label": "Cash and Others "
        }
      ],
      "curMktCapWiseExposureList": [
        {
          "exposure": 0,
          "label": "Large Cap (\u003e7692.307692 Mn)"
        },
        {
          "exposure": 0,
          "label": "Mid Cap (1282.051282 Mn - 7692.307692 Mn)"
        },
        {
          "exposure": 0,
          "label": "Small Cap (\u003c1282.051282 Mn)"
        }
      ],
      "fundDetails": {
        "_id": "o8xNRK8tMW",
        "annualFundReturns": [
          {
            "key": 2022,
            "value": -0.321282080228795
          },
          {
            "key": 2021,
            "value": 0.4991749291285277
          },
          {
            "key": 2020,
            "value": -0.08475648323845664
          }
        ],
        "annualIndexReturns": [
          {
            "key": 2022,
            "value": -0.9038012181091002
          },
          {
            "key": 2021,
            "value": -0.3501347037111763
          },
          {
            "key": 2020,
            "value": 0.04000730704525046
          }
        ],
        "asOfDate": "2023-03-02T00:00:00Z",
        "aum": 0,
        "aumLastMonth": 451477411,
        "aumLastQuarter": 451477411,
        "aumLastYear": 451477411,
        "benchmarkIndexCcy": "HKD",
        "benchmarkIndexCode": "HSTECH Index",
        "benchmarkIndexName": "HSTECH Index",
        "betaYTD": -1.987650591162899,
        "ccy": "HKD",
        "classID": "CLS00183",
        "className": "HK-HST-2I",
        "companyID": "CSOP",
        "createdAt": "2023-03-03T16:37:04.704Z",
        "dtdDiff": -0.0027016997111080077,
        "fundDTDDiff": 0.02573863723933867,
        "fundID": "1000127",
        "fundName": "CSOP Hang Seng TECH Index Daily (-2x) Inverse Product",
        "fundNote": null,
        "fundReturn": 6.6553,
        "fundReturn1M": 0.24694133738032353,
        "fundReturn1Y": -0.4357093801137857,
        "fundReturn1YRolling": -0.4357093801137857,
        "fundReturn3M": -0.23104563835932979,
        "fundReturn3MRolling": -0.23104563835932979,
        "fundReturn3Y": "-",
        "fundReturn3YRolling": "-",
        "fundReturn6M": -0.3088490336784605,
        "fundReturn6MRolling": -0.3088490336784605,
        "fundReturnInception": -0.14090797609366323,
        "fundReturnMTD": -0.10902713629730765,
        "fundReturnQTD": -0.07751056899300013,
        "fundReturnRebase": 85.90920239063396,
        "fundReturnYTD": -0.07751056899300013,
        "fundType": "LE",
        "inceptionDate": "2020-12-09T00:00:00Z",
        "indexDTDDiff": 0.028440336950446676,
        "indexMTDVolatility": 0.6104231958944878,
        "indexQTDVolatility": 0.3963786712030899,
        "indexReturn": 525.66468,
        "indexReturn1M": -0.12209952723461315,
        "indexReturn1Y": -0.8951969838946995,
        "indexReturn1YRolling": -0.8951969838946995,
        "indexReturn3M": -0.8644778475872754,
        "indexReturn3MRolling": -0.8644778475872754,
        "indexReturn3Y": "-",
        "indexReturn3YRolling": "-",
        "indexReturn6M": -0.8731360265281053,
        "indexReturn6MRolling": -0.8731360265281053,
        "indexReturnChange": -0.093595226722063,
        "indexReturnChangePct": -0.014220168475223338,
        "indexReturnInception": -0.9351173039965341,
        "indexReturnMTD": 0.05121954960584807,
        "indexReturnQTD": -0.0020711499887604568,
        "indexReturnRebase": 6.488269600346602,
        "indexYTD": -0.0020711499887604568,
        "indexYTDVolatility": 0.3963786712030899,
        "location": "Hong Kong",
        "navChange": 2.15570099007347,
        "navChangePct": 0.02573863723933867,
        "navRebase": 85.90920239063396,
        "netAssetValue": 6.6553,
        "noOfHoldings": 0,
        "noOfLongPositions": 0,
        "noOfShortPositions": 0,
        "preferredBBGNav": "7552 HK EQUITY",
        "securitiesPct": 0,
        "stressedTDBYMpf": [
          {
            "key": -20,
            "value": -40
          },
          {
            "key": -15,
            "value": -30
          },
          {
            "key": -10,
            "value": -20
          },
          {
            "key": -5,
            "value": -10
          },
          {
            "key": 0,
            "value": 0
          },
          {
            "key": 5,
            "value": 10
          },
          {
            "key": 10,
            "value": 20
          },
          {
            "key": 15,
            "value": 30
          },
          {
            "key": 20,
            "value": 40
          }
        ],
        "td1M": -0.0006592068970053266,
        "td1Y": -0.02263135141075922,
        "tdAnnualYears": [
          {
            "key": 2022,
            "value": -0.023202738688168266
          },
          {
            "key": 2021,
            "value": -0.00032479446255218654
          }
        ],
        "tdCalYear": -0.023202738688168266,
        "tdCheck": "Fail",
        "tdInception": -0.008906041479749285,
        "tdYTD": -0.00011928680261027258,
        "tdYTDChange": -0.00006456032271244338,
        "te1M": 0.0028885210387536004,
        "te1Y": 0.20661311748487346,
        "teAnnualYears": [
          {
            "key": 2022,
            "value": 0.20908274728266227
          },
          {
            "key": 2021,
            "value": 0.0027528693047195294
          }
        ],
        "teCalYear": 0.20908274728266227,
        "teInception": 0.1287387036188927,
        "teYTD": 0.0018806212207298607,
        "theoriticalDTD": 5.688067390089335,
        "tradarCode": "HK-HST-2I",
        "updatedAt": "2023-03-03T16:37:04.704Z",
        "volatilityMTD": 1.1971609970238553,
        "volatilityQTD": 0.788390113493173,
        "volatilityYTD": 0.788390113493173
      },
      "listOfHoldings": [

      ],
      "sectorWiseExposureList": [
        {
          "exposure": 100,
          "label": "Cash and Others "
        }
      ],
      "status": "success",
      "topPerformingHoldings": [

      ],
      "topTenHoldings": [

      ],
      "topUnderPerformingHoldings": [

      ]
    }
  }

  getHoldingsData() {
    return {
      "aggrViewData": {
        "derivativeHoldings": [
          {
            "accountID": "",
            "assetClass": "DERV",
            "assetGroup": "",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 103968260,
            "exposureNAV": 5.29,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": 103968260,
            "name": "HKD - Hong Kong Dollar RepDeposit of 24005816 to 20 Mar 23 at 0.25%",
            "percentOfNav": 5.287399,
            "quantity": 103951939.45,
            "sector": "",
            "securityType": "",
            "seqno": 5,
            "ticker": "HKD"
          },
          {
            "accountID": "",
            "assetClass": "DERV",
            "assetGroup": "",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": -3918368163,
            "exposureNAV": -199.26,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": -146080529,
            "name": "HSTECH",
            "percentOfNav": -7.429057,
            "quantity": -18723,
            "sector": "",
            "securityType": "",
            "seqno": 3,
            "ticker": "HCTH3 Index"
          }
        ],
        "equityHoldings": [
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 365.3959,
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 793829624,
            "exposureNAV": 40.37,
            "holdingType": "NAV1",
            "isin": "HK0000426384",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 1055.6245,
            "marketValueFC": 793829624,
            "marketValueIc": 793829624,
            "name": "CSOP HKD MONEY MART ETF HKD",
            "percentOfNav": 40.370919,
            "sector": "",
            "securityType": "",
            "seqno": 9,
            "sharesHeld": 752000,
            "ticker": "3053 HK EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 1.0268,
            "cds": 0,
            "creditRating": "",
            "currency": "USD",
            "exposureFC": 96999877,
            "exposureNAV": 4.93,
            "holdingType": "NAV1",
            "isin": "HK0000503828",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 1.0541,
            "marketValueFC": 96999877,
            "marketValueIc": 12357223,
            "name": "CSOP US DOLLAR MONEY MAR-USD",
            "percentOfNav": 4.933016,
            "sector": "ETF",
            "securityType": "",
            "seqno": 6,
            "sharesHeld": 11723008.46,
            "ticker": "9096UA HK Equity"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 10.3454,
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 8905967,
            "exposureNAV": 0.45,
            "holdingType": "NAV1",
            "isin": "HK0000489705",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 10.5562,
            "marketValueFC": 8905967,
            "marketValueIc": 8905967,
            "name": "CSOP HONG KONG DOLLAR MO-HKD",
            "percentOfNav": 0.452921,
            "sector": "ETF",
            "securityType": "",
            "seqno": 7,
            "sharesHeld": 843671.73,
            "ticker": "3053UA HK Equity"
          }
        ]
      },
      "fdiViewData": {
        "derivativeHoldings": [
          {
            "accountID": "",
            "assetClass": "DERV",
            "assetGroup": "Currencies",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 103968260,
            "exposureNAV": 5.29,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": 103968260,
            "name": "HKD - Hong Kong Dollar RepDeposit of 24005816 to 20 Mar 23 at 0.25%",
            "percentOfNav": 5.287399,
            "quantity": 103951939.45,
            "sector": "",
            "securityType": "Exchrate",
            "seqno": 5,
            "ticker": "HKD"
          },
          {
            "accountID": "",
            "assetClass": "DERV",
            "assetGroup": "Swap",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": -3918368163,
            "exposureNAV": -199.26,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": -146080529,
            "name": "HSTECH",
            "percentOfNav": -7.429057,
            "quantity": -18723,
            "sector": "",
            "securityType": "Swap Future",
            "seqno": 3,
            "ticker": "HCTH3 Index"
          }
        ],
        "equityHoldings": [
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 365.3959,
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 793829624,
            "exposureNAV": 40.37,
            "holdingType": "NAV1",
            "isin": "HK0000426384",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 1055.6245,
            "marketValueFC": 793829624,
            "marketValueIc": 793829624,
            "name": "CSOP HKD MONEY MART ETF HKD",
            "percentOfNav": 40.370919,
            "sector": "",
            "securityType": "Equity HedgeFund",
            "seqno": 9,
            "sharesHeld": 752000,
            "ticker": "3053 HK EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 1.0268,
            "cds": 0,
            "creditRating": "",
            "currency": "USD",
            "exposureFC": 96999877,
            "exposureNAV": 4.93,
            "holdingType": "NAV1",
            "isin": "HK0000503828",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 1.0541,
            "marketValueFC": 96999877,
            "marketValueIc": 12357223,
            "name": "CSOP US DOLLAR MONEY MAR-USD",
            "percentOfNav": 4.933016,
            "sector": "ETF",
            "securityType": "Equity Mutual Fund/Load",
            "seqno": 6,
            "sharesHeld": 11723008.46,
            "ticker": "9096UA HK Equity"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 10.3454,
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 8905967,
            "exposureNAV": 0.45,
            "holdingType": "NAV1",
            "isin": "HK0000489705",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 10.5562,
            "marketValueFC": 8905967,
            "marketValueIc": 8905967,
            "name": "CSOP HONG KONG DOLLAR MO-HKD",
            "percentOfNav": 0.452921,
            "sector": "ETF",
            "securityType": "Equity Mutual Fund/Load",
            "seqno": 7,
            "sharesHeld": 843671.73,
            "ticker": "3053UA HK Equity"
          }
        ]
      },
      "marketCapExposureList": [
        {
          "exposure": 8.38,
          "label": "Small Cap (\u003c1282.051282 Mn)"
        },
        // {
        //   "exposure": 5,
        //   "label": "Large Cap (\u003c1282.051282 Mn)"
        // },
        // {
        //   "exposure": 4.23,
        //   "label": " MID (\u003c1282.051282 Mn)"
        // }
      ],
      "naggrViewData": {
        "derivativeHoldings": [
          {
            "accountID": "BOSH",
            "assetClass": "DERV",
            "assetGroup": "Currencies",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 79959813,
            "exposureNAV": 4.07,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": 79959813,
            "name": "HKD - Hong Kong Dollar RepDeposit of 79946124 to 13 Mar 23 at 0.25%",
            "percentOfNav": 4.066428,
            "quantity": 79946123.7,
            "sector": "",
            "securityType": "Exchrate",
            "seqno": 5,
            "ticker": ""
          },
          {
            "accountID": "BOSH",
            "assetClass": "DERV",
            "assetGroup": "Currencies",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 24008447,
            "exposureNAV": 1.22,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": 24008447,
            "name": "HKD - Hong Kong Dollar RepDeposit of 24005816 to 20 Mar 23 at 0.25%",
            "percentOfNav": 1.220971,
            "quantity": 24005815.75,
            "sector": "",
            "securityType": "Exchrate",
            "seqno": 4,
            "ticker": ""
          },
          {
            "accountID": "CGML",
            "assetClass": "DERV",
            "assetGroup": "Swap",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": -203002570,
            "exposureNAV": -10.32,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": -3434089,
            "name": "HSTECH",
            "percentOfNav": -0.174644,
            "quantity": -970,
            "sector": "",
            "securityType": "Swap Future",
            "seqno": 1,
            "ticker": ""
          },
          {
            "accountID": "JPM",
            "assetClass": "DERV",
            "assetGroup": "Swap",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": -595613726,
            "exposureNAV": -30.29,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": -9383927,
            "name": "HSTECH",
            "percentOfNav": -0.477228,
            "quantity": -2846,
            "sector": "",
            "securityType": "Swap Future",
            "seqno": 2,
            "ticker": ""
          },
          {
            "accountID": "BNPS",
            "assetClass": "DERV",
            "assetGroup": "Swap",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": -1159416740,
            "exposureNAV": -58.96,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": -19379945,
            "name": "HSTECH",
            "percentOfNav": -0.985585,
            "quantity": -5540,
            "sector": "",
            "securityType": "Swap Future",
            "seqno": 0,
            "ticker": ""
          },
          {
            "accountID": "SocGen",
            "assetClass": "DERV",
            "assetGroup": "Swap",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": -1960335127,
            "exposureNAV": -99.69,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": -113882568,
            "name": "HSTECH",
            "percentOfNav": -5.7916,
            "quantity": -9367,
            "sector": "",
            "securityType": "Swap Future",
            "seqno": 3,
            "ticker": ""
          }
        ],
        "equityHoldings": [
          {
            "accountID": "Socgen_IA",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 365.3959,
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 608039712,
            "exposureNAV": 30.92,
            "holdingType": "NAV1",
            "isin": "HK0000426384",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 1055.6245,
            "marketValueFC": 608039712,
            "marketValueIc": 608039712,
            "name": "CSOP HKD MONEY MART ETF HKD",
            "percentOfNav": 30.922406,
            "sector": "",
            "securityType": "Equity HedgeFund",
            "seqno": 8,
            "sharesHeld": 576000,
            "ticker": ""
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 365.3959,
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 185789912,
            "exposureNAV": 9.45,
            "holdingType": "NAV1",
            "isin": "HK0000426384",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 1055.6245,
            "marketValueFC": 185789912,
            "marketValueIc": 185789912,
            "name": "CSOP HKD MONEY MART ETF HKD",
            "percentOfNav": 9.448513,
            "sector": "",
            "securityType": "Equity HedgeFund",
            "seqno": 9,
            "sharesHeld": 176000,
            "ticker": ""
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 1.0268,
            "cds": 0,
            "creditRating": "",
            "currency": "USD",
            "exposureFC": 96999877,
            "exposureNAV": 4.93,
            "holdingType": "NAV1",
            "isin": "HK0000503828",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 1.0541,
            "marketValueFC": 96999877,
            "marketValueIc": 12357223,
            "name": "CSOP US DOLLAR MONEY MAR-USD",
            "percentOfNav": 4.933016,
            "sector": "ETF",
            "securityType": "Equity Mutual Fund/Load",
            "seqno": 6,
            "sharesHeld": 11723008.46,
            "ticker": ""
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 10.3454,
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 8905967,
            "exposureNAV": 0.45,
            "holdingType": "NAV1",
            "isin": "HK0000489705",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 10.5562,
            "marketValueFC": 8905967,
            "marketValueIc": 8905967,
            "name": "CSOP HONG KONG DOLLAR MO-HKD",
            "percentOfNav": 0.452921,
            "sector": "ETF",
            "securityType": "Equity Mutual Fund/Load",
            "seqno": 7,
            "sharesHeld": 843671.73,
            "ticker": ""
          }
        ]
      },
      "sectorWiseExposureList": [
        {
          "exposure": 5.40,
          "label": "ETF"
        },
        {
          "exposure": 94.62,
          "label": "Cash and Others"
        }
      ],
      "status": "success",
      "topTenHoldings": [
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 365.3959,
          "cds": 0,
          "creditRating": "",
          "currency": "HKD",
          "exposureFC": 793829624,
          "exposureNAV": 40.37,
          "holdingType": "NAV1",
          "isin": "HK0000426384",
          "marketCapital": 0,
          "marketCapitalInUsd": 0,
          "marketPrice": 1055.6245,
          "marketValueFC": 793829624,
          "marketValueIc": 793829624,
          "name": "CSOP HKD MONEY MART ETF HKD",
          "percentOfNav": 40.370919,
          "sector": "",
          "securityType": "",
          "seqno": 9,
          "sharesHeld": 752000,
          "ticker": "3053 HK EQUITY"
        },
        {
          "accountID": "",
          "assetClass": "DERV",
          "assetGroup": "",
          "cds": 0,
          "creditRating": "",
          "currency": "HKD",
          "exposureFC": 103968260,
          "exposureNAV": 5.29,
          "holdingType": "NAV1",
          "isin": "",
          "marketValueFC": 103968260,
          "name": "HKD - Hong Kong Dollar RepDeposit of 24005816 to 20 Mar 23 at 0.25%",
          "percentOfNav": 5.287399,
          "quantity": 103951939.45,
          "sector": "",
          "securityType": "",
          "seqno": 5,
          "ticker": "HKD"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 1.0268,
          "cds": 0,
          "creditRating": "",
          "currency": "USD",
          "exposureFC": 96999877,
          "exposureNAV": 4.93,
          "holdingType": "NAV1",
          "isin": "HK0000503828",
          "marketCapital": 0,
          "marketCapitalInUsd": 0,
          "marketPrice": 1.0541,
          "marketValueFC": 96999877,
          "marketValueIc": 12357223,
          "name": "CSOP US DOLLAR MONEY MAR-USD",
          "percentOfNav": 4.933016,
          "sector": "ETF",
          "securityType": "",
          "seqno": 6,
          "sharesHeld": 11723008.46,
          "ticker": "9096UA HK Equity"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 10.3454,
          "cds": 0,
          "creditRating": "",
          "currency": "HKD",
          "exposureFC": 8905967,
          "exposureNAV": 0.45,
          "holdingType": "NAV1",
          "isin": "HK0000489705",
          "marketCapital": 0,
          "marketCapitalInUsd": 0,
          "marketPrice": 10.5562,
          "marketValueFC": 8905967,
          "marketValueIc": 8905967,
          "name": "CSOP HONG KONG DOLLAR MO-HKD",
          "percentOfNav": 0.452921,
          "sector": "ETF",
          "securityType": "",
          "seqno": 7,
          "sharesHeld": 843671.73,
          "ticker": "3053UA HK Equity"
        },
        {
          "accountID": "",
          "assetClass": "DERV",
          "assetGroup": "",
          "cds": 0,
          "creditRating": "",
          "currency": "HKD",
          "exposureFC": -3918368163,
          "exposureNAV": -199.26,
          "holdingType": "NAV1",
          "isin": "",
          "marketValueFC": -146080529,
          "name": "HSTECH",
          "percentOfNav": -7.429057,
          "quantity": -18723,
          "sector": "",
          "securityType": "",
          "seqno": 3,
          "ticker": "HCTH3 Index"
        }
      ]
    }
  }

  pdfHoldingsData() {
    return {
      "NAV1": {
        "aggrViewData": {
          "derivativeHoldings": [
            {
              "accountID": "",
              "assetClass": "DERV",
              "assetGroup": "",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 103968260,
              "exposureNAV": 5.29,
              "holdingType": "NAV1",
              "isin": "",
              "marketValueFC": 103968260,
              "name": "HKD - Hong Kong Dollar RepDeposit of 24005816 to 20 Mar 23 at 0.25%",
              "percentOfNav": 5.287399,
              "quantity": 103951939.45,
              "sector": "",
              "securityType": "",
              "seqno": 5,
              "ticker": "HKD"
            },
            {
              "accountID": "",
              "assetClass": "DERV",
              "assetGroup": "",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -3918368163,
              "exposureNAV": -199.26,
              "holdingType": "NAV1",
              "isin": "",
              "marketValueFC": -146080529,
              "name": "HSTECH",
              "percentOfNav": -7.429057,
              "quantity": -18723,
              "sector": "",
              "securityType": "",
              "seqno": 3,
              "ticker": "HCTH3 Index"
            }
          ],
          "equityHoldings": [
            {
              "accountID": "",
              "assetClass": "EQTY",
              "assetGroup": "",
              "averageCost": 365.3959,
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 793829624,
              "exposureNAV": 40.37,
              "holdingType": "NAV1",
              "isin": "HK0000426384",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 1055.6245,
              "marketValueFC": 793829624,
              "marketValueIc": 793829624,
              "name": "CSOP HKD MONEY MART ETF HKD",
              "percentOfNav": 40.370919,
              "sector": "",
              "securityType": "",
              "seqno": 9,
              "sharesHeld": 752000,
              "ticker": "3053 HK EQUITY"
            },
            {
              "accountID": "",
              "assetClass": "EQTY",
              "assetGroup": "",
              "averageCost": 1.0268,
              "cds": 0,
              "creditRating": "",
              "currency": "USD",
              "exposureFC": 96999877,
              "exposureNAV": 4.93,
              "holdingType": "NAV1",
              "isin": "HK0000503828",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 1.0541,
              "marketValueFC": 96999877,
              "marketValueIc": 12357223,
              "name": "CSOP US DOLLAR MONEY MAR-USD",
              "percentOfNav": 4.933016,
              "sector": "ETF",
              "securityType": "",
              "seqno": 6,
              "sharesHeld": 11723008.46,
              "ticker": "9096UA HK Equity"
            },
            {
              "accountID": "",
              "assetClass": "EQTY",
              "assetGroup": "",
              "averageCost": 10.3454,
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 8905967,
              "exposureNAV": 0.45,
              "holdingType": "NAV1",
              "isin": "HK0000489705",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 10.5562,
              "marketValueFC": 8905967,
              "marketValueIc": 8905967,
              "name": "CSOP HONG KONG DOLLAR MO-HKD",
              "percentOfNav": 0.452921,
              "sector": "ETF",
              "securityType": "",
              "seqno": 7,
              "sharesHeld": 843671.73,
              "ticker": "3053UA HK Equity"
            }
          ]
        },
        "fdiViewData": {
          "derivativeHoldings": [
            {
              "accountID": "",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 103968260,
              "exposureNAV": 5.29,
              "holdingType": "NAV1",
              "isin": "",
              "marketValueFC": 103968260,
              "name": "HKD - Hong Kong Dollar RepDeposit of 24005816 to 20 Mar 23 at 0.25%",
              "percentOfNav": 5.287399,
              "quantity": 103951939.45,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 5,
              "ticker": "HKD"
            },
            {
              "accountID": "",
              "assetClass": "DERV",
              "assetGroup": "Swap",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -3918368163,
              "exposureNAV": -199.26,
              "holdingType": "NAV1",
              "isin": "",
              "marketValueFC": -146080529,
              "name": "HSTECH",
              "percentOfNav": -7.429057,
              "quantity": -18723,
              "sector": "",
              "securityType": "Swap Future",
              "seqno": 3,
              "ticker": "HCTH3 Index"
            }
          ],
          "equityHoldings": [
            {
              "accountID": "",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 365.3959,
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 793829624,
              "exposureNAV": 40.37,
              "holdingType": "NAV1",
              "isin": "HK0000426384",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 1055.6245,
              "marketValueFC": 793829624,
              "marketValueIc": 793829624,
              "name": "CSOP HKD MONEY MART ETF HKD",
              "percentOfNav": 40.370919,
              "sector": "",
              "securityType": "Equity HedgeFund",
              "seqno": 9,
              "sharesHeld": 752000,
              "ticker": "3053 HK EQUITY"
            },
            {
              "accountID": "",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 1.0268,
              "cds": 0,
              "creditRating": "",
              "currency": "USD",
              "exposureFC": 96999877,
              "exposureNAV": 4.93,
              "holdingType": "NAV1",
              "isin": "HK0000503828",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 1.0541,
              "marketValueFC": 96999877,
              "marketValueIc": 12357223,
              "name": "CSOP US DOLLAR MONEY MAR-USD",
              "percentOfNav": 4.933016,
              "sector": "ETF",
              "securityType": "Equity Mutual Fund/Load",
              "seqno": 6,
              "sharesHeld": 11723008.46,
              "ticker": "9096UA HK Equity"
            },
            {
              "accountID": "",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 10.3454,
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 8905967,
              "exposureNAV": 0.45,
              "holdingType": "NAV1",
              "isin": "HK0000489705",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 10.5562,
              "marketValueFC": 8905967,
              "marketValueIc": 8905967,
              "name": "CSOP HONG KONG DOLLAR MO-HKD",
              "percentOfNav": 0.452921,
              "sector": "ETF",
              "securityType": "Equity Mutual Fund/Load",
              "seqno": 7,
              "sharesHeld": 843671.73,
              "ticker": "3053UA HK Equity"
            }
          ]
        },
        "marketCapExposureList": [
          {
            "exposure": 5.38,
            "label": "Small Cap (\u003c1282.051282 Mn)"
          }
        ],
        "naggrViewData": {
          "derivativeHoldings": [
            {
              "accountID": "BOSH",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 79959813,
              "exposureNAV": 4.07,
              "holdingType": "NAV1",
              "isin": "",
              "marketValueFC": 79959813,
              "name": "HKD - Hong Kong Dollar RepDeposit of 79946124 to 13 Mar 23 at 0.25%",
              "percentOfNav": 4.066428,
              "quantity": 79946123.7,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 5,
              "ticker": ""
            },
            {
              "accountID": "BOSH",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 24008447,
              "exposureNAV": 1.22,
              "holdingType": "NAV1",
              "isin": "",
              "marketValueFC": 24008447,
              "name": "HKD - Hong Kong Dollar RepDeposit of 24005816 to 20 Mar 23 at 0.25%",
              "percentOfNav": 1.220971,
              "quantity": 24005815.75,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 4,
              "ticker": ""
            },
            {
              "accountID": "CGML",
              "assetClass": "DERV",
              "assetGroup": "Swap",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -203002570,
              "exposureNAV": -10.32,
              "holdingType": "NAV1",
              "isin": "",
              "marketValueFC": -3434089,
              "name": "HSTECH",
              "percentOfNav": -0.174644,
              "quantity": -970,
              "sector": "",
              "securityType": "Swap Future",
              "seqno": 1,
              "ticker": ""
            },
            {
              "accountID": "JPM",
              "assetClass": "DERV",
              "assetGroup": "Swap",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -595613726,
              "exposureNAV": -30.29,
              "holdingType": "NAV1",
              "isin": "",
              "marketValueFC": -9383927,
              "name": "HSTECH",
              "percentOfNav": -0.477228,
              "quantity": -2846,
              "sector": "",
              "securityType": "Swap Future",
              "seqno": 2,
              "ticker": ""
            },
            {
              "accountID": "BNPS",
              "assetClass": "DERV",
              "assetGroup": "Swap",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -1159416740,
              "exposureNAV": -58.96,
              "holdingType": "NAV1",
              "isin": "",
              "marketValueFC": -19379945,
              "name": "HSTECH",
              "percentOfNav": -0.985585,
              "quantity": -5540,
              "sector": "",
              "securityType": "Swap Future",
              "seqno": 0,
              "ticker": ""
            },
            {
              "accountID": "SocGen",
              "assetClass": "DERV",
              "assetGroup": "Swap",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -1960335127,
              "exposureNAV": -99.69,
              "holdingType": "NAV1",
              "isin": "",
              "marketValueFC": -113882568,
              "name": "HSTECH",
              "percentOfNav": -5.7916,
              "quantity": -9367,
              "sector": "",
              "securityType": "Swap Future",
              "seqno": 3,
              "ticker": ""
            }
          ],
          "equityHoldings": [
            {
              "accountID": "Socgen_IA",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 365.3959,
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 608039712,
              "exposureNAV": 30.92,
              "holdingType": "NAV1",
              "isin": "HK0000426384",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 1055.6245,
              "marketValueFC": 608039712,
              "marketValueIc": 608039712,
              "name": "CSOP HKD MONEY MART ETF HKD",
              "percentOfNav": 30.922406,
              "sector": "",
              "securityType": "Equity HedgeFund",
              "seqno": 8,
              "sharesHeld": 576000,
              "ticker": ""
            },
            {
              "accountID": "HSBC_HK",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 365.3959,
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 185789912,
              "exposureNAV": 9.45,
              "holdingType": "NAV1",
              "isin": "HK0000426384",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 1055.6245,
              "marketValueFC": 185789912,
              "marketValueIc": 185789912,
              "name": "CSOP HKD MONEY MART ETF HKD",
              "percentOfNav": 9.448513,
              "sector": "",
              "securityType": "Equity HedgeFund",
              "seqno": 9,
              "sharesHeld": 176000,
              "ticker": ""
            },
            {
              "accountID": "HSBC_HK",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 1.0268,
              "cds": 0,
              "creditRating": "",
              "currency": "USD",
              "exposureFC": 96999877,
              "exposureNAV": 4.93,
              "holdingType": "NAV1",
              "isin": "HK0000503828",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 1.0541,
              "marketValueFC": 96999877,
              "marketValueIc": 12357223,
              "name": "CSOP US DOLLAR MONEY MAR-USD",
              "percentOfNav": 4.933016,
              "sector": "ETF",
              "securityType": "Equity Mutual Fund/Load",
              "seqno": 6,
              "sharesHeld": 11723008.46,
              "ticker": ""
            },
            {
              "accountID": "HSBC_HK",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 10.3454,
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 8905967,
              "exposureNAV": 0.45,
              "holdingType": "NAV1",
              "isin": "HK0000489705",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 10.5562,
              "marketValueFC": 8905967,
              "marketValueIc": 8905967,
              "name": "CSOP HONG KONG DOLLAR MO-HKD",
              "percentOfNav": 0.452921,
              "sector": "ETF",
              "securityType": "Equity Mutual Fund/Load",
              "seqno": 7,
              "sharesHeld": 843671.73,
              "ticker": ""
            }
          ]
        },
        "sectorWiseExposureList": [
          {
            "exposure": 5.38,
            "label": "ETF"
          },
          {
            "exposure": 94.62,
            "label": "Cash and Others"
          }
        ],
        "topTenHoldings": [
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 365.3959,
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 793829624,
            "exposureNAV": 40.37,
            "holdingType": "NAV1",
            "isin": "HK0000426384",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 1055.6245,
            "marketValueFC": 793829624,
            "marketValueIc": 793829624,
            "name": "CSOP HKD MONEY MART ETF HKD",
            "percentOfNav": 40.370919,
            "sector": "",
            "securityType": "",
            "seqno": 9,
            "sharesHeld": 752000,
            "ticker": "3053 HK EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "DERV",
            "assetGroup": "",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 103968260,
            "exposureNAV": 5.29,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": 103968260,
            "name": "HKD - Hong Kong Dollar RepDeposit of 24005816 to 20 Mar 23 at 0.25%",
            "percentOfNav": 5.287399,
            "quantity": 103951939.45,
            "sector": "",
            "securityType": "",
            "seqno": 5,
            "ticker": "HKD"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 1.0268,
            "cds": 0,
            "creditRating": "",
            "currency": "USD",
            "exposureFC": 96999877,
            "exposureNAV": 4.93,
            "holdingType": "NAV1",
            "isin": "HK0000503828",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 1.0541,
            "marketValueFC": 96999877,
            "marketValueIc": 12357223,
            "name": "CSOP US DOLLAR MONEY MAR-USD",
            "percentOfNav": 4.933016,
            "sector": "ETF",
            "securityType": "",
            "seqno": 6,
            "sharesHeld": 11723008.46,
            "ticker": "9096UA HK Equity"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 10.3454,
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 8905967,
            "exposureNAV": 0.45,
            "holdingType": "NAV1",
            "isin": "HK0000489705",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 10.5562,
            "marketValueFC": 8905967,
            "marketValueIc": 8905967,
            "name": "CSOP HONG KONG DOLLAR MO-HKD",
            "percentOfNav": 0.452921,
            "sector": "ETF",
            "securityType": "",
            "seqno": 7,
            "sharesHeld": 843671.73,
            "ticker": "3053UA HK Equity"
          },
          {
            "accountID": "",
            "assetClass": "DERV",
            "assetGroup": "",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": -3918368163,
            "exposureNAV": -199.26,
            "holdingType": "NAV1",
            "isin": "",
            "marketValueFC": -146080529,
            "name": "HSTECH",
            "percentOfNav": -7.429057,
            "quantity": -18723,
            "sector": "",
            "securityType": "",
            "seqno": 3,
            "ticker": "HCTH3 Index"
          }
        ]
      },
      "NAV2": {
        "aggrViewData": {
          "derivativeHoldings": [
            {
              "accountID": "",
              "assetClass": "DERV",
              "assetGroup": "",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 84159746,
              "exposureNAV": 13.61,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 84159746,
              "name": "HKD - Hong Kong Dollar RepDeposit of 5325267 to 08 Nov 21 at 0.03%",
              "percentOfNav": 13.616336,
              "quantity": 84158739.53,
              "sector": "",
              "securityType": "",
              "seqno": 44,
              "ticker": "HKD"
            },
            {
              "accountID": "",
              "assetClass": "DERV",
              "assetGroup": "",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -1234287420,
              "exposureNAV": -199.7,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 3213908,
              "name": "HSTECH",
              "percentOfNav": 0.519984,
              "quantity": -3660,
              "sector": "",
              "securityType": "",
              "seqno": 4,
              "ticker": "HCTX1 Index"
            }
          ],
          "equityHoldings": [
            {
              "accountID": "",
              "assetClass": "EQTY",
              "assetGroup": "",
              "averageCost": 10.343,
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 138354150,
              "exposureNAV": 22.38,
              "holdingType": "NAV2",
              "isin": "HK0000489705",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 10.344,
              "marketValueFC": 138354150,
              "marketValueIc": 138354150,
              "name": "CSOP HONG KONG DOLLAR MO-HKD",
              "percentOfNav": 22.384536,
              "sector": "ETF",
              "securityType": "",
              "seqno": 54,
              "sharesHeld": 13375304.48,
              "ticker": "3053UA HK Equity"
            },
            {
              "accountID": "",
              "assetClass": "EQTY",
              "assetGroup": "",
              "averageCost": 1.0264,
              "cds": 0,
              "creditRating": "",
              "currency": "USD",
              "exposureFC": 36660769,
              "exposureNAV": 5.93,
              "holdingType": "NAV2",
              "isin": "HK0000503828",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 1.0264,
              "marketValueFC": 36660769,
              "marketValueIc": 4716000,
              "name": "CSOP US DOLLAR MONEY MAR-USD",
              "percentOfNav": 5.931404,
              "sector": "ETF",
              "securityType": "",
              "seqno": 46,
              "sharesHeld": 4594699.92,
              "ticker": "9096UA HK Equity"
            }
          ]
        },
        "fdiViewData": {
          "derivativeHoldings": [
            {
              "accountID": "",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 84159746,
              "exposureNAV": 13.61,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 84159746,
              "name": "HKD - Hong Kong Dollar RepDeposit of 5325267 to 08 Nov 21 at 0.03%",
              "percentOfNav": 13.616336,
              "quantity": 84158739.53,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 44,
              "ticker": "HKD"
            },
            {
              "accountID": "",
              "assetClass": "DERV",
              "assetGroup": "Swap",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -1234287420,
              "exposureNAV": -199.7,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 3213908,
              "name": "HSTECH",
              "percentOfNav": 0.519984,
              "quantity": -3660,
              "sector": "",
              "securityType": "Swap Future",
              "seqno": 4,
              "ticker": "HCTX1 Index"
            }
          ],
          "equityHoldings": [
            {
              "accountID": "",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 10.343,
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 138354150,
              "exposureNAV": 22.38,
              "holdingType": "NAV2",
              "isin": "HK0000489705",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 10.344,
              "marketValueFC": 138354150,
              "marketValueIc": 138354150,
              "name": "CSOP HONG KONG DOLLAR MO-HKD",
              "percentOfNav": 22.384536,
              "sector": "ETF",
              "securityType": "Equity Mutual Fund/Load",
              "seqno": 54,
              "sharesHeld": 13375304.48,
              "ticker": "3053UA HK Equity"
            },
            {
              "accountID": "",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 1.0264,
              "cds": 0,
              "creditRating": "",
              "currency": "USD",
              "exposureFC": 36660769,
              "exposureNAV": 5.93,
              "holdingType": "NAV2",
              "isin": "HK0000503828",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 1.0264,
              "marketValueFC": 36660769,
              "marketValueIc": 4716000,
              "name": "CSOP US DOLLAR MONEY MAR-USD",
              "percentOfNav": 5.931404,
              "sector": "ETF",
              "securityType": "Equity Mutual Fund/Load",
              "seqno": 46,
              "sharesHeld": 4594699.92,
              "ticker": "9096UA HK Equity"
            }
          ]
        },
        "marketCapExposureList": [
          {
            "exposure": 28.31,
            "label": "Small Cap (\u003c1282.051282 Mn)"
          }
        ],
        "naggrViewData": {
          "derivativeHoldings": [
            {
              "accountID": "BOSH",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 14780061,
              "exposureNAV": 2.39,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 14780061,
              "name": "HKD - Hong Kong Dollar RepDeposit of 14780000 to 22 Nov 21 at 0.03%",
              "percentOfNav": 2.391289,
              "quantity": 14780000,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 44,
              "ticker": ""
            },
            {
              "accountID": "BOSH",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 12061674,
              "exposureNAV": 1.95,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 12061674,
              "name": "HKD - Hong Kong Dollar RepDeposit of 12061585 to 18 Nov 21 at 0.03%",
              "percentOfNav": 1.951477,
              "quantity": 12061584.59,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 42,
              "ticker": ""
            },
            {
              "accountID": "BOSH",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 10931270,
              "exposureNAV": 1.77,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 10931270,
              "name": "HKD - Hong Kong Dollar RepDeposit of 10931000 to 27 Oct 21 at 0.03%",
              "percentOfNav": 1.768587,
              "quantity": 10931000,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 41,
              "ticker": ""
            },
            {
              "accountID": "BOSH",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 10721194,
              "exposureNAV": 1.73,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 10721194,
              "name": "HKD - Hong Kong Dollar RepDeposit of 10721000 to 05 Nov 21 at 0.03%",
              "percentOfNav": 1.734599,
              "quantity": 10721000,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 40,
              "ticker": ""
            },
            {
              "accountID": "BOSH",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 9122045,
              "exposureNAV": 1.48,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 9122045,
              "name": "HKD - Hong Kong Dollar RepDeposit of 9122000 to 22 Nov 21 at 0.03%",
              "percentOfNav": 1.47587,
              "quantity": 9122000,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 39,
              "ticker": ""
            },
            {
              "accountID": "BOSH",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 7967426,
              "exposureNAV": 1.29,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 7967426,
              "name": "HKD - Hong Kong Dollar RepDeposit of 7967413 to 25 Nov 21 at 0.03%",
              "percentOfNav": 1.289062,
              "quantity": 7967412.54,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 38,
              "ticker": ""
            },
            {
              "accountID": "BOSH",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 7925358,
              "exposureNAV": 1.28,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 7925358,
              "name": "HKD - Hong Kong Dollar RepDeposit of 7925208 to 04 Nov 21 at 0.03%",
              "percentOfNav": 1.282256,
              "quantity": 7925208.44,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 36,
              "ticker": ""
            },
            {
              "accountID": "Wing Lung",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 5325359,
              "exposureNAV": 0.86,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 5325359,
              "name": "HKD - Hong Kong Dollar RepDeposit of 5325267 to 08 Nov 21 at 0.03%",
              "percentOfNav": 0.861598,
              "quantity": 5325266.98,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 34,
              "ticker": ""
            },
            {
              "accountID": "BOSH",
              "assetClass": "DERV",
              "assetGroup": "Currencies",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 5325359,
              "exposureNAV": 0.86,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 5325359,
              "name": "HKD - Hong Kong Dollar RepDeposit of 5325267 to 08 Nov 21 at 0.03%",
              "percentOfNav": 0.861598,
              "quantity": 5325266.98,
              "sector": "",
              "securityType": "Exchrate",
              "seqno": 35,
              "ticker": ""
            },
            {
              "accountID": "CGML",
              "assetClass": "DERV",
              "assetGroup": "Swap",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -124777690,
              "exposureNAV": -20.19,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 858764,
              "name": "HSTECH",
              "percentOfNav": 0.138941,
              "quantity": -370,
              "sector": "",
              "securityType": "Swap Future",
              "seqno": 4,
              "ticker": ""
            },
            {
              "accountID": "BNPS",
              "assetClass": "DERV",
              "assetGroup": "Swap",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -249555380,
              "exposureNAV": -40.38,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 570170,
              "name": "HSTECH",
              "percentOfNav": 0.092249,
              "quantity": -740,
              "sector": "",
              "securityType": "Swap Future",
              "seqno": 3,
              "ticker": ""
            },
            {
              "accountID": "SocGen",
              "assetClass": "DERV",
              "assetGroup": "Swap",
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": -859954350,
              "exposureNAV": -139.13,
              "holdingType": "NAV2",
              "isin": "",
              "marketValueFC": 1784974,
              "name": "HSTECH",
              "percentOfNav": 0.288794,
              "quantity": -2550,
              "sector": "",
              "securityType": "Swap Future",
              "seqno": 1,
              "ticker": ""
            }
          ],
          "equityHoldings": [
            {
              "accountID": "HSBC_HK",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 10.343,
              "cds": 0,
              "creditRating": "",
              "currency": "HKD",
              "exposureFC": 138354150,
              "exposureNAV": 22.38,
              "holdingType": "NAV2",
              "isin": "HK0000489705",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 10.344,
              "marketValueFC": 138354150,
              "marketValueIc": 138354150,
              "name": "CSOP HONG KONG DOLLAR MO-HKD",
              "percentOfNav": 22.384536,
              "sector": "ETF",
              "securityType": "Equity Mutual Fund/Load",
              "seqno": 54,
              "sharesHeld": 13375304.48,
              "ticker": ""
            },
            {
              "accountID": "HSBC_HK",
              "assetClass": "EQTY",
              "assetGroup": "Equity",
              "averageCost": 1.0264,
              "cds": 0,
              "creditRating": "",
              "currency": "USD",
              "exposureFC": 36660769,
              "exposureNAV": 5.93,
              "holdingType": "NAV2",
              "isin": "HK0000503828",
              "marketCapital": 0,
              "marketCapitalInUsd": 0,
              "marketPrice": 1.0264,
              "marketValueFC": 36660769,
              "marketValueIc": 4716000,
              "name": "CSOP US DOLLAR MONEY MAR-USD",
              "percentOfNav": 5.931404,
              "sector": "ETF",
              "securityType": "Equity Mutual Fund/Load",
              "seqno": 46,
              "sharesHeld": 4594699.92,
              "ticker": ""
            }
          ]
        },
        "sectorWiseExposureList": [
          {
            "exposure": 28.31,
            "label": "ETF"
          },
          {
            "exposure": 71.69,
            "label": "Cash and Others"
          }
        ],
        "topTenHoldings": [
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 10.343,
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 138354150,
            "exposureNAV": 22.38,
            "holdingType": "NAV2",
            "isin": "HK0000489705",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 10.344,
            "marketValueFC": 138354150,
            "marketValueIc": 138354150,
            "name": "CSOP HONG KONG DOLLAR MO-HKD",
            "percentOfNav": 22.384536,
            "sector": "ETF",
            "securityType": "",
            "seqno": 54,
            "sharesHeld": 13375304.48,
            "ticker": "3053UA HK Equity"
          },
          {
            "accountID": "",
            "assetClass": "DERV",
            "assetGroup": "",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": 84159746,
            "exposureNAV": 13.61,
            "holdingType": "NAV2",
            "isin": "",
            "marketValueFC": 84159746,
            "name": "HKD - Hong Kong Dollar RepDeposit of 5325267 to 08 Nov 21 at 0.03%",
            "percentOfNav": 13.616336,
            "quantity": 84158739.53,
            "sector": "",
            "securityType": "",
            "seqno": 44,
            "ticker": "HKD"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 1.0264,
            "cds": 0,
            "creditRating": "",
            "currency": "USD",
            "exposureFC": 36660769,
            "exposureNAV": 5.93,
            "holdingType": "NAV2",
            "isin": "HK0000503828",
            "marketCapital": 0,
            "marketCapitalInUsd": 0,
            "marketPrice": 1.0264,
            "marketValueFC": 36660769,
            "marketValueIc": 4716000,
            "name": "CSOP US DOLLAR MONEY MAR-USD",
            "percentOfNav": 5.931404,
            "sector": "ETF",
            "securityType": "",
            "seqno": 46,
            "sharesHeld": 4594699.92,
            "ticker": "9096UA HK Equity"
          },
          {
            "accountID": "",
            "assetClass": "DERV",
            "assetGroup": "",
            "cds": 0,
            "creditRating": "",
            "currency": "HKD",
            "exposureFC": -1234287420,
            "exposureNAV": -199.7,
            "holdingType": "NAV2",
            "isin": "",
            "marketValueFC": 3213908,
            "name": "HSTECH",
            "percentOfNav": 0.519984,
            "quantity": -3660,
            "sector": "",
            "securityType": "",
            "seqno": 4,
            "ticker": "HCTX1 Index"
          }
        ]
      },
      "status": "success"
    }
  }

  getData() {
    return {
      "fundInformation": {
        "Domicile": "Hong Kong",
        "NoOfHoldings": 0,
        "assetClass": "Equity",
        "baseCcy": "HKD",
        "fundInceptionDate": "2020-12-09T00:00:00Z",
        "investmentExposure": 0,
        "totalNav": "N.A"
      },
      "shareClassInformation": [
        {
          "TER": "N.A",
          "baseCurrency": "HKD",
          "classID": "CLS00183",
          "classType": "LISTED",
          "indexCcy": "HKD",
          "indexName": "HSTECH Index",
          "indexTicker": "HSTECH Index",
          "lotSize": 0,
          "managementFee": "1.6",
          "navPerShare": "N.A",
          "shareClassName": "HKD Share Class A",
          "ticker": [
            "7552 HK EQUITY"
          ],
          "totalNav": "N.A",
          "tradingCurrency": [
            "HKD"
          ]
        }
      ],
      "status": "success"
    }
  }

  getPerformanceData() {
    return {
      "data": [],
      "status": "success"
    }
  }

  getPdfChartImages() {
    return {
      "dtd": "/data/images/1000127dtdif020323.png",
      "h_ex": "/data/images/1000127hex020323.png",
      "h_news_ltr_pr": "/data/images/1000127hnpr020323.png",
      "h_perf": "/data/images/1000127hprf020323.png",
      "h_pr": "/data/images/1000127hpr020323.png",
      "h_ps": "/data/images/1000127hps020323.png",
      "hld_cu_bar": "/data/images/1000127hcbar020323.png",
      "hld_cu_pie": "/data/images/1000127hcpie020323.png",
      "hld_mc_bar": "/data/images/1000127hmbar020323.png",
      "hld_mc_pie": "/data/images/1000127hmpie020323.png",
      "hld_nl_cu_pie": "/data/images/1000127hncpie020323.png",
      "hld_nl_sc_pie": "/data/images/1000127hnspie020323.png",
      "hld_sc_bar": "/data/images/1000127hsbar020323.png",
      "hld_sc_pie": "/data/images/1000127hspie020323.png",
      "status": "success"
    }
  }

  getPerformancePdfChartImage() {
    return {}
  }

  getInvestmentStrategies() {
    return [
      {
        "PartyCategory": "INST",
        "PartyID": "934",
        "PartyNameInChinese": "主動投資策略",
        "PartyNameInEnglish": "Active investment strategy"
      },
      {
        "PartyCategory": "INST",
        "PartyID": "935",
        "PartyNameInChinese": " 代表性抽样策略",
        "PartyNameInEnglish": "Representative Sampling Strategy"
      }
    ]
  }


  getDRDRDR() {
    return {
      "aggrViewData": {
        "equityHoldings": [
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 1493.1483856293,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 1399964712,
            "exposureNAV": 14.52,
            "holdingType": "NAV1",
            "isin": "CNE0000018R8",
            "marketCapital": 2218445.31,
            "marketCapitalInUsd": 322927.2045765525,
            "marketPrice": 1766,
            "marketValueFC": 1399964712,
            "marketValueIc": 1399964712,
            "name": "KWEICHOW MOUTAI CO LTD-A",
            "percentOfNav": 14.510797,
            "sector": "Consumer Staples",
            "securityType": "",
            "seqno": 99,
            "sharesHeld": 792732,
            "ticker": "600519 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 449.1147828773,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 596401578,
            "exposureNAV": 6.18,
            "holdingType": "NAV1",
            "isin": "CNE100003662",
            "marketCapital": 945814.9,
            "marketCapitalInUsd": 137677.2103991383,
            "marketPrice": 387.23,
            "marketValueFC": 596401578,
            "marketValueIc": 596401578,
            "name": "CONTEMPORARY AMPEREX TECHN-A",
            "percentOfNav": 6.181772,
            "sector": "Consumer Discretionary",
            "securityType": "",
            "seqno": 30,
            "sharesHeld": 1540174,
            "ticker": "300750 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 177.3981498607,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 478681663,
            "exposureNAV": 4.96,
            "holdingType": "NAV1",
            "isin": "CNE000000VQ8",
            "marketCapital": 758000.41,
            "marketCapitalInUsd": 110338.0607877959,
            "marketPrice": 195.28,
            "marketValueFC": 478681663,
            "marketValueIc": 478681663,
            "name": "WULIANGYE YIBIN CO LTD-A",
            "percentOfNav": 4.961591,
            "sector": "Consumer Staples",
            "securityType": "",
            "seqno": 111,
            "sharesHeld": 2451258,
            "ticker": "000858 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 37.4445206455,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 459836195,
            "exposureNAV": 4.77,
            "holdingType": "NAV1",
            "isin": "CNE000001B33",
            "marketCapital": 871913.56,
            "marketCapitalInUsd": 126919.7880578765,
            "marketPrice": 34.58,
            "marketValueFC": 459836195,
            "marketValueIc": 459836195,
            "name": "CHINA MERCHANTS BANK-A",
            "percentOfNav": 4.766255,
            "sector": "Financials",
            "securityType": "",
            "seqno": 101,
            "sharesHeld": 13297750,
            "ticker": "600036 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 50.6820796376,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 310958129,
            "exposureNAV": 3.22,
            "holdingType": "NAV1",
            "isin": "CNE000001R84",
            "marketCapital": 823458.41,
            "marketCapitalInUsd": 119866.4313371568,
            "marketPrice": 45.46,
            "marketValueFC": 310958129,
            "marketValueIc": 310958129,
            "name": "PING AN INSURANCE GROUP CO-A",
            "percentOfNav": 3.223118,
            "sector": "Financials",
            "securityType": "",
            "seqno": 110,
            "sharesHeld": 6840258,
            "ticker": "601318 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 21.238654259,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 302561808,
            "exposureNAV": 3.14,
            "holdingType": "NAV1",
            "isin": "CNE000001G87",
            "marketCapital": 498595.88,
            "marketCapitalInUsd": 72577.9323997787,
            "marketPrice": 21.07,
            "marketValueFC": 302561808,
            "marketValueIc": 302561808,
            "name": "CHINA YANGTZE POWER CO LTD-A",
            "percentOfNav": 3.136088,
            "sector": "Utilities",
            "securityType": "",
            "seqno": 98,
            "sharesHeld": 14359839,
            "ticker": "600900 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 230.940507061,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 274753342,
            "exposureNAV": 2.85,
            "holdingType": "NAV1",
            "isin": "CNE100001526",
            "marketCapital": 623581.52,
            "marketCapitalInUsd": 90771.4227488428,
            "marketPrice": 239.98,
            "marketValueFC": 274753342,
            "marketValueIc": 274753342,
            "name": "BYD CO LTD -A",
            "percentOfNav": 2.84785,
            "sector": "Consumer Discretionary",
            "securityType": "",
            "seqno": 85,
            "sharesHeld": 1144901,
            "ticker": "002594 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 295.4918903877,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 236318923,
            "exposureNAV": 2.45,
            "holdingType": "NAV1",
            "isin": "CNE100003G67",
            "marketCapital": 374317.03,
            "marketCapitalInUsd": 54487.3256863373,
            "marketPrice": 308.73,
            "marketValueFC": 236318923,
            "marketValueIc": 236318923,
            "name": "SHENZHEN MINDRAY BIO-MEDIC-A",
            "percentOfNav": 2.449473,
            "sector": "Health Care",
            "securityType": "",
            "seqno": 25,
            "sharesHeld": 765455,
            "ticker": "300760 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 158.0633032023,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 229726130,
            "exposureNAV": 2.38,
            "holdingType": "NAV1",
            "isin": "CNE100000G29",
            "marketCapital": 383432.48,
            "marketCapitalInUsd": 55814.2129319631,
            "marketPrice": 186.35,
            "marketValueFC": 229726130,
            "marketValueIc": 229726130,
            "name": "CHINA INTERNATIONAL TRAVEL-A",
            "percentOfNav": 2.381138,
            "sector": "Consumer Discretionary",
            "securityType": "",
            "seqno": 67,
            "sharesHeld": 1232767,
            "ticker": "601888 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 215.8519939621,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 223456968,
            "exposureNAV": 2.32,
            "holdingType": "NAV1",
            "isin": "CNE000000GF2",
            "marketCapital": 354013.06,
            "marketCapitalInUsd": 51531.7854959387,
            "marketPrice": 240.5,
            "marketValueFC": 223456968,
            "marketValueIc": 223456968,
            "name": "LUZHOU LAOJIAO CO LTD-A",
            "percentOfNav": 2.316157,
            "sector": "Consumer Staples",
            "securityType": "",
            "seqno": 109,
            "sharesHeld": 929135,
            "ticker": "000568 C2 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 17.9653421604,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 216279542,
            "exposureNAV": 2.25,
            "holdingType": "NAV1",
            "isin": "CNE000001QZ7",
            "marketCapital": 342567.57,
            "marketCapitalInUsd": 49865.7268042738,
            "marketPrice": 16.49,
            "marketValueFC": 216279542,
            "marketValueIc": 216279542,
            "name": "INDUSTRIAL BANK CO LTD -A",
            "percentOfNav": 2.241762,
            "sector": "Financials",
            "securityType": "",
            "seqno": 96,
            "sharesHeld": 13115800,
            "ticker": "601166 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 4.6730664454,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 214471905,
            "exposureNAV": 2.22,
            "holdingType": "NAV1",
            "isin": "CNE000001P37",
            "marketCapital": 1502462.3,
            "marketCapitalInUsd": 218705.3917144604,
            "marketPrice": 4.41,
            "marketValueFC": 214471905,
            "marketValueIc": 214471905,
            "name": "IND \u0026 COMM BK OF CHINA-A",
            "percentOfNav": 2.223027,
            "sector": "Financials",
            "securityType": "",
            "seqno": 93,
            "sharesHeld": 48633085,
            "ticker": "601398 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 284.8262400104,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 209605977,
            "exposureNAV": 2.17,
            "holdingType": "NAV1",
            "isin": "CNE000000DH5",
            "marketCapital": 331981.98,
            "marketCapitalInUsd": 48324.839151067,
            "marketPrice": 272.1,
            "marketValueFC": 209605977,
            "marketValueIc": 209605977,
            "name": "SHANXI XINGHUACUN FEN WINE-A",
            "percentOfNav": 2.17259,
            "sector": "Consumer Staples",
            "securityType": "",
            "seqno": 79,
            "sharesHeld": 770327,
            "ticker": "600809 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 46.3734199299,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 200367489,
            "exposureNAV": 2.07,
            "holdingType": "NAV1",
            "isin": "CNE100001FR6",
            "marketCapital": 317513.63,
            "marketCapitalInUsd": 46218.7589158345,
            "marketPrice": 41.88,
            "marketValueFC": 200367489,
            "marketValueIc": 200367489,
            "name": "LONGI GREEN ENERGY TECHNOL-A",
            "percentOfNav": 2.076832,
            "sector": "Energy",
            "securityType": "",
            "seqno": 78,
            "sharesHeld": 4784324,
            "ticker": "601012 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 79.3280442547,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 195616237,
            "exposureNAV": 2.03,
            "holdingType": "NAV1",
            "isin": "CNE100001SL2",
            "marketCapital": 351522.63,
            "marketCapitalInUsd": 51169.2669364465,
            "marketPrice": 75.86,
            "marketValueFC": 195616237,
            "marketValueIc": 195616237,
            "name": "FOSHAN HAITIAN FLAVOURING -A",
            "percentOfNav": 2.027585,
            "sector": "Consumer Staples",
            "securityType": "",
            "seqno": 108,
            "sharesHeld": 2578648,
            "ticker": "603288 C1 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 86.8979718198,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 194673728,
            "exposureNAV": 2.02,
            "holdingType": "NAV1",
            "isin": "CNE0000016J9",
            "marketCapital": 308354.52,
            "marketCapitalInUsd": 44885.5163177967,
            "marketPrice": 98.21,
            "marketValueFC": 194673728,
            "marketValueIc": 194673728,
            "name": "WANHUA CHEMICAL GROUP CO -A",
            "percentOfNav": 2.017815,
            "sector": "Materials",
            "securityType": "",
            "seqno": 75,
            "sharesHeld": 1982219,
            "ticker": "600309 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 3.2239280605,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 179832383,
            "exposureNAV": 1.86,
            "holdingType": "NAV1",
            "isin": "CNE100000RJ0",
            "marketCapital": 1035835.84,
            "marketCapitalInUsd": 150781.0765961163,
            "marketPrice": 3.01,
            "marketValueFC": 179832383,
            "marketValueIc": 179832383,
            "name": "AGRICULTURAL BANK OF CHINA-A",
            "percentOfNav": 1.863984,
            "sector": "Financials",
            "securityType": "",
            "seqno": 95,
            "sharesHeld": 59744978,
            "ticker": "601288 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 50.9739157578,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 169322479,
            "exposureNAV": 1.75,
            "holdingType": "NAV1",
            "isin": "CNE100001RQ3",
            "marketCapital": 275803.27,
            "marketCapitalInUsd": 40147.205158811,
            "marketPrice": 50.4,
            "marketValueFC": 169322479,
            "marketValueIc": 169322479,
            "name": "MUYUAN FOODSTUFF CO LTD-A",
            "percentOfNav": 1.755048,
            "sector": "Consumer Staples",
            "securityType": "",
            "seqno": 80,
            "sharesHeld": 3359573,
            "ticker": "002714 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 22.2555600817,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 166580993,
            "exposureNAV": 1.73,
            "holdingType": "NAV1",
            "isin": "CNE100000MD4",
            "marketCapital": 263886.83,
            "marketCapitalInUsd": 38412.5927974614,
            "marketPrice": 19.97,
            "marketValueFC": 166580993,
            "marketValueIc": 166580993,
            "name": "EAST MONEY INFORMATION CO-A",
            "percentOfNav": 1.726631,
            "sector": "Financials",
            "securityType": "",
            "seqno": 81,
            "sharesHeld": 8341562,
            "ticker": "300059 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 38.8091441319,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 167032203,
            "exposureNAV": 1.73,
            "holdingType": "NAV1",
            "isin": "CNE0000014W7",
            "marketCapital": 264601.01,
            "marketCapitalInUsd": 38516.5521558124,
            "marketPrice": 41.48,
            "marketValueFC": 167032203,
            "marketValueIc": 167032203,
            "name": "JIANGSU HENGRUI MEDICINE C-A 31 Dec 99",
            "percentOfNav": 1.731308,
            "sector": "Health Care",
            "securityType": "",
            "seqno": 77,
            "sharesHeld": 4026813,
            "ticker": "600276 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 50.3758406551,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 164701317,
            "exposureNAV": 1.7,
            "holdingType": "NAV1",
            "isin": "CNE100000L63",
            "marketCapital": 260914.29,
            "marketCapitalInUsd": 37979.8960668433,
            "marketPrice": 53.3,
            "marketValueFC": 164701317,
            "marketValueIc": 164701317,
            "name": "S F HOLDING CO LTD-A",
            "percentOfNav": 1.707148,
            "sector": "Industrials",
            "securityType": "",
            "seqno": 43,
            "sharesHeld": 3090081,
            "ticker": "002352 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 5.1253090246,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 158610767,
            "exposureNAV": 1.64,
            "holdingType": "NAV1",
            "isin": "CNE100000F46",
            "marketCapital": 251187.25,
            "marketCapitalInUsd": 36563.9829398236,
            "marketPrice": 5.99,
            "marketValueFC": 158610767,
            "marketValueIc": 158610767,
            "name": "CHINA STATE CONSTRUCTION -A",
            "percentOfNav": 1.644019,
            "sector": "Industrials",
            "securityType": "",
            "seqno": 90,
            "sharesHeld": 26479260,
            "ticker": "601668 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 166.4743841543,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 157821389,
            "exposureNAV": 1.64,
            "holdingType": "NAV1",
            "isin": "CNE100000HB8",
            "marketCapital": 250024.38,
            "marketCapitalInUsd": 36394.7101807913,
            "marketPrice": 165.91,
            "marketValueFC": 157821389,
            "marketValueIc": 157821389,
            "name": "JIANGSU YANGHE BREWERY -A",
            "percentOfNav": 1.635837,
            "sector": "Consumer Staples",
            "securityType": "",
            "seqno": 105,
            "sharesHeld": 951247,
            "ticker": "002304 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 14.4220869374,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 157658247,
            "exposureNAV": 1.64,
            "holdingType": "NAV1",
            "isin": "CNE000000040",
            "marketCapital": 249754.17,
            "marketCapitalInUsd": 36355.3771579959,
            "marketPrice": 12.87,
            "marketValueFC": 157658247,
            "marketValueIc": 157658247,
            "name": "PING AN BANK CO LTD-A",
            "percentOfNav": 1.634146,
            "sector": "Financials",
            "securityType": "",
            "seqno": 107,
            "sharesHeld": 12250058,
            "ticker": "000001 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 10.2530009106,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 156370957,
            "exposureNAV": 1.62,
            "holdingType": "NAV1",
            "isin": "CNE100000B24",
            "marketCapital": 311180.1,
            "marketCapitalInUsd": 45296.8208681475,
            "marketPrice": 12.03,
            "marketValueFC": 156370957,
            "marketValueIc": 156370957,
            "name": "ZIJIN MINING GROUP CO LTD-A",
            "percentOfNav": 1.620803,
            "sector": "Materials",
            "securityType": "",
            "seqno": 66,
            "sharesHeld": 12998417,
            "ticker": "601899 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 21.3652328323,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 152947897,
            "exposureNAV": 1.59,
            "holdingType": "NAV1",
            "isin": "CNE000001DB6",
            "marketCapital": 279326.01,
            "marketCapitalInUsd": 40659.9915572506,
            "marketPrice": 19.86,
            "marketValueFC": 152947897,
            "marketValueIc": 152947897,
            "name": "CITIC SECURITIES CO-A",
            "percentOfNav": 1.585322,
            "sector": "Financials",
            "securityType": "",
            "seqno": 102,
            "sharesHeld": 7701304,
            "ticker": "600030 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 9.4790595246,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 130052612,
            "exposureNAV": 1.35,
            "holdingType": "NAV1",
            "isin": "CNE0000011B7",
            "marketCapital": 206052.26,
            "marketCapitalInUsd": 29993.9241317069,
            "marketPrice": 7.02,
            "marketValueFC": 130052612,
            "marketValueIc": 130052612,
            "name": "SHANGHAI PUDONG DEVEL BANK-A",
            "percentOfNav": 1.34801,
            "sector": "Financials",
            "securityType": "",
            "seqno": 104,
            "sharesHeld": 18526013,
            "ticker": "600000 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 24.1846796964,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 128299015,
            "exposureNAV": 1.33,
            "holdingType": "NAV1",
            "isin": "CNE100000767",
            "marketCapital": 547954.91,
            "marketCapitalInUsd": 79762.8620920551,
            "marketPrice": 28.73,
            "marketValueFC": 128299015,
            "marketValueIc": 128299015,
            "name": "CHINA SHENHUA ENERGY CO-A",
            "percentOfNav": 1.329834,
            "sector": "Materials",
            "securityType": "",
            "seqno": 97,
            "sharesHeld": 4465681,
            "ticker": "601088 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 86.4269124762,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 127990195,
            "exposureNAV": 1.33,
            "holdingType": "NAV1",
            "isin": "CNE1000031K4",
            "marketCapital": 229865.98,
            "marketCapitalInUsd": 33460.3598358031,
            "marketPrice": 79.02,
            "marketValueFC": 127990195,
            "marketValueIc": 127990195,
            "name": "WUXI APPTEC CO LTD-A",
            "percentOfNav": 1.326633,
            "sector": "Health Care",
            "securityType": "",
            "seqno": 27,
            "sharesHeld": 1619719,
            "ticker": "603259 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 34.2509968083,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 126734142,
            "exposureNAV": 1.31,
            "holdingType": "NAV1",
            "isin": "CNE100000TP3",
            "marketCapital": 201581.66,
            "marketCapitalInUsd": 29343.1628286122,
            "marketPrice": 28.33,
            "marketValueFC": 126734142,
            "marketValueIc": 126734142,
            "name": "LUXSHARE PRECISION INDUSTR-A",
            "percentOfNav": 1.313614,
            "sector": "Technology",
            "securityType": "",
            "seqno": 83,
            "sharesHeld": 4473496,
            "ticker": "002475 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 5.2041152456,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 125413446,
            "exposureNAV": 1.3,
            "holdingType": "NAV1",
            "isin": "CNE1000000S2",
            "marketCapital": 343783.29,
            "marketCapitalInUsd": 50042.692654808,
            "marketPrice": 5.06,
            "marketValueFC": 125413446,
            "marketValueIc": 125413446,
            "name": "BANK OF COMMUNICATIONS CO-A",
            "percentOfNav": 1.299925,
            "sector": "Financials",
            "securityType": "",
            "seqno": 94,
            "sharesHeld": 24785266,
            "ticker": "601328 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 35.8294266255,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 119604890,
            "exposureNAV": 1.24,
            "holdingType": "NAV1",
            "isin": "CNE000000JP5",
            "marketCapital": 189024.06,
            "marketCapitalInUsd": 27515.2202393083,
            "marketPrice": 29.54,
            "marketValueFC": 119604890,
            "marketValueIc": 119604890,
            "name": "INNER MONGOLIA YILI INDUS-A",
            "percentOfNav": 1.239718,
            "sector": "Consumer Staples",
            "securityType": "",
            "seqno": 84,
            "sharesHeld": 4048913,
            "ticker": "600887 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 21.4291074283,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 118794774,
            "exposureNAV": 1.23,
            "holdingType": "NAV1",
            "isin": "CNE100001T64",
            "marketCapital": 187986.05,
            "marketCapitalInUsd": 27364.122681883,
            "marketPrice": 19.39,
            "marketValueFC": 118794774,
            "marketValueIc": 118794774,
            "name": "SHAANXI COAL INDUSTRY CO L-A",
            "percentOfNav": 1.231323,
            "sector": "Materials",
            "securityType": "",
            "seqno": 65,
            "sharesHeld": 6126600,
            "ticker": "601225 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 4.5990153678,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 118925286,
            "exposureNAV": 1.23,
            "holdingType": "NAV1",
            "isin": "CNE0000018G1",
            "marketCapital": 625834.47,
            "marketCapitalInUsd": 91099.3726163789,
            "marketPrice": 5.52,
            "marketValueFC": 118925286,
            "marketValueIc": 118925286,
            "name": "CHINA PETROLEUM \u0026 CHEMICAL-A",
            "percentOfNav": 1.232675,
            "sector": "Energy",
            "securityType": "",
            "seqno": 103,
            "sharesHeld": 21544436,
            "ticker": "600028 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 33.8864883428,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 115524697,
            "exposureNAV": 1.19,
            "holdingType": "NAV1",
            "isin": "CNE1000005P7",
            "marketCapital": 182523.25,
            "marketCapitalInUsd": 26568.9321377624,
            "marketPrice": 27.64,
            "marketValueFC": 115524697,
            "marketValueIc": 115524697,
            "name": "BANK OF NINGBO CO LTD -A",
            "percentOfNav": 1.197427,
            "sector": "Financials",
            "securityType": "",
            "seqno": 73,
            "sharesHeld": 4179620,
            "ticker": "002142 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 25.1458155562,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 113295475,
            "exposureNAV": 1.18,
            "holdingType": "NAV1",
            "isin": "CNE1000008M8",
            "marketCapital": 230271.16,
            "marketCapitalInUsd": 33519.3397187691,
            "marketPrice": 26.23,
            "marketValueFC": 113295475,
            "marketValueIc": 113295475,
            "name": "CHINA PACIFIC INSURANCE GR-A",
            "percentOfNav": 1.174321,
            "sector": "Financials",
            "securityType": "",
            "seqno": 92,
            "sharesHeld": 4319309,
            "ticker": "601601 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 53.3651979778,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 110985350,
            "exposureNAV": 1.15,
            "holdingType": "NAV1",
            "isin": "CNE000001GS3",
            "marketCapital": 176026.09,
            "marketCapitalInUsd": 25623.1753471717,
            "marketPrice": 39.1,
            "marketValueFC": 110985350,
            "marketValueIc": 110985350,
            "name": "TONGWEI CO LTD-A",
            "percentOfNav": 1.150376,
            "sector": "Energy",
            "securityType": "",
            "seqno": 39,
            "sharesHeld": 2838500,
            "ticker": "600438 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 22.5193809273,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 96766929,
            "exposureNAV": 1.01,
            "holdingType": "NAV1",
            "isin": "CNE0000000T2",
            "marketCapital": 176947.7,
            "marketCapitalInUsd": 25757.3291798888,
            "marketPrice": 15.75,
            "marketValueFC": 96766929,
            "marketValueIc": 96766929,
            "name": "CHINA VANKE CO LTD -A",
            "percentOfNav": 1.003,
            "sector": "Real Estate",
            "securityType": "",
            "seqno": 106,
            "sharesHeld": 6143932,
            "ticker": "000002 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 26.5555261671,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 93824490,
            "exposureNAV": 0.97,
            "holdingType": "NAV1",
            "isin": "CNE000000CG9",
            "marketCapital": 215174.21,
            "marketCapitalInUsd": 31321.7575475269,
            "marketPrice": 23.58,
            "marketValueFC": 93824490,
            "marketValueIc": 93824490,
            "name": "QINGDAO HAIER CO LTD-A",
            "percentOfNav": 0.972502,
            "sector": "Consumer Discretionary",
            "securityType": "",
            "seqno": 63,
            "sharesHeld": 3978986,
            "ticker": "600690 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 10.4133520857,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 92650160,
            "exposureNAV": 0.96,
            "holdingType": "NAV1",
            "isin": "CNE1000031P3",
            "marketCapital": 233548.81,
            "marketCapitalInUsd": 33996.4496783021,
            "marketPrice": 11.76,
            "marketValueFC": 92650160,
            "marketValueIc": 92650160,
            "name": "FOXCONN INDUSTRIAL INTERNE-A",
            "percentOfNav": 0.96033,
            "sector": "Technology",
            "securityType": "",
            "seqno": 31,
            "sharesHeld": 7878415,
            "ticker": "601138 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 5.3208896925,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 87216585,
            "exposureNAV": 0.9,
            "holdingType": "NAV1",
            "isin": "CNE1000007Q1",
            "marketCapital": 978569.19,
            "marketCapitalInUsd": 142445.0770036973,
            "marketPrice": 5.55,
            "marketValueFC": 87216585,
            "marketValueIc": 87216585,
            "name": "PETROCHINA CO LTD-A",
            "percentOfNav": 0.90401,
            "sector": "Energy",
            "securityType": "",
            "seqno": 89,
            "sharesHeld": 15714700,
            "ticker": "601857 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 7.2846019609,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 86223451,
            "exposureNAV": 0.89,
            "holdingType": "NAV1",
            "isin": "CNE100000601",
            "marketCapital": 161820.76,
            "marketCapitalInUsd": 23555.3815249352,
            "marketPrice": 10.79,
            "marketValueFC": 86223451,
            "marketValueIc": 86223451,
            "name": "COSCO SHIPPING HOLDINGS CO-A",
            "percentOfNav": 0.893716,
            "sector": "Industrials",
            "securityType": "",
            "seqno": 64,
            "sharesHeld": 7991052,
            "ticker": "601919 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 17.3225749588,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 85771471,
            "exposureNAV": 0.89,
            "holdingType": "NAV1",
            "isin": "CNE000000TY6",
            "marketCapital": 164152.63,
            "marketCapitalInUsd": 23894.8193542752,
            "marketPrice": 14.05,
            "marketValueFC": 85771471,
            "marketValueIc": 85771471,
            "name": "SAIC MOTOR CORP LTD-A",
            "percentOfNav": 0.889032,
            "sector": "Consumer Discretionary",
            "securityType": "",
            "seqno": 100,
            "sharesHeld": 6104731,
            "ticker": "600104 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 3.3076768194,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 84772053,
            "exposureNAV": 0.88,
            "holdingType": "NAV1",
            "isin": "CNE000001N05",
            "marketCapital": 915900.07,
            "marketCapitalInUsd": 133322.6687822062,
            "marketPrice": 3.33,
            "marketValueFC": 84772053,
            "marketValueIc": 84772053,
            "name": "BANK OF CHINA LTD-A",
            "percentOfNav": 0.878672,
            "sector": "Financials",
            "securityType": "",
            "seqno": 87,
            "sharesHeld": 25457073,
            "ticker": "601988 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 63.2488916954,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 78627315,
            "exposureNAV": 0.82,
            "holdingType": "NAV1",
            "isin": "CNE100000GS4",
            "marketCapital": 134608.47,
            "marketCapitalInUsd": 19594.2341844013,
            "marketPrice": 65.8,
            "marketValueFC": 78627315,
            "marketValueIc": 78627315,
            "name": "EVE ENERGY CO LTD-A",
            "percentOfNav": 0.814982,
            "sector": "Energy",
            "securityType": "",
            "seqno": 48,
            "sharesHeld": 1194944,
            "ticker": "300014 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 5.0466531673,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 62800423,
            "exposureNAV": 0.65,
            "holdingType": "NAV1",
            "isin": "CNE100003PZ4",
            "marketCapital": 427303.22,
            "marketCapitalInUsd": 62200.2416373111,
            "marketPrice": 4.73,
            "marketValueFC": 62800423,
            "marketValueIc": 62800423,
            "name": "POSTAL SAVINGS BANK OF CHI-A",
            "percentOfNav": 0.650934,
            "sector": "Financials",
            "securityType": "",
            "seqno": 20,
            "sharesHeld": 13277045,
            "ticker": "601658 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 47.8889810025,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 52493678,
            "exposureNAV": 0.55,
            "holdingType": "NAV1",
            "isin": "CNE1000048D3",
            "marketCapital": 232803.14,
            "marketCapitalInUsd": 33887.9064892719,
            "marketPrice": 42.94,
            "marketValueFC": 52493678,
            "marketValueIc": 52493678,
            "name": "YIHAI KERRY ARAWANA HOLDIN-A",
            "percentOfNav": 0.544102,
            "sector": "Consumer Staples",
            "securityType": "",
            "seqno": 18,
            "sharesHeld": 1222489,
            "ticker": "300999 C2 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 34.5528158522,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 47297606,
            "exposureNAV": 0.49,
            "holdingType": "NAV1",
            "isin": "CNE1000018V8",
            "marketCapital": 184883.75,
            "marketCapitalInUsd": 26912.5374828962,
            "marketPrice": 27.27,
            "marketValueFC": 47297606,
            "marketValueIc": 47297606,
            "name": "GREAT WALL MOTOR CO LTD-A",
            "percentOfNav": 0.490245,
            "sector": "Consumer Discretionary",
            "securityType": "",
            "seqno": 91,
            "sharesHeld": 1734419,
            "ticker": "601633 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 6.1152092679,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 35237726,
            "exposureNAV": 0.36,
            "holdingType": "NAV1",
            "isin": "CNE100000742",
            "marketCapital": 1092845.85,
            "marketCapitalInUsd": 159079.7184779761,
            "marketPrice": 5.83,
            "marketValueFC": 35237726,
            "marketValueIc": 35237726,
            "name": "CHINA CONSTRUCTION BANK-A",
            "percentOfNav": 0.365244,
            "sector": "Financials",
            "securityType": "",
            "seqno": 88,
            "sharesHeld": 6044207,
            "ticker": "601939 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "",
            "averageCost": 4.9318029102,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 31278208,
            "exposureNAV": 0.32,
            "holdingType": "NAV1",
            "isin": "CNE1000000R4",
            "marketCapital": 220895.27,
            "marketCapitalInUsd": 32154.5416169321,
            "marketPrice": 5.1,
            "marketValueFC": 31278208,
            "marketValueIc": 31278208,
            "name": "CHINA CITIC BANK CORP LTD-A",
            "percentOfNav": 0.324202,
            "sector": "Financials",
            "securityType": "",
            "seqno": 86,
            "sharesHeld": 6132982,
            "ticker": "601998 CG EQUITY"
          }
        ]
      },
      "countryWiseExposureList": [
        {
          "exposure": 99.98,
          "label": "Ch Listed Stocks"
        }
      ],
      "fdiViewData": {
        "equityHoldings": [
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 1493.1483856293,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 1399964712,
            "exposureNAV": 14.52,
            "holdingType": "NAV1",
            "isin": "CNE0000018R8",
            "marketCapital": 2218445.31,
            "marketCapitalInUsd": 322927.2045765525,
            "marketPrice": 1766,
            "marketValueFC": 1399964712,
            "marketValueIc": 1399964712,
            "name": "KWEICHOW MOUTAI CO LTD-A",
            "percentOfNav": 14.510797,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 99,
            "sharesHeld": 792732,
            "ticker": "600519 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 449.1147828773,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 596401578,
            "exposureNAV": 6.18,
            "holdingType": "NAV1",
            "isin": "CNE100003662",
            "marketCapital": 945814.9,
            "marketCapitalInUsd": 137677.2103991383,
            "marketPrice": 387.23,
            "marketValueFC": 596401578,
            "marketValueIc": 596401578,
            "name": "CONTEMPORARY AMPEREX TECHN-A",
            "percentOfNav": 6.181772,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 30,
            "sharesHeld": 1540174,
            "ticker": "300750 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 37.4445206455,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 459836195,
            "exposureNAV": 4.77,
            "holdingType": "NAV1",
            "isin": "CNE000001B33",
            "marketCapital": 871913.56,
            "marketCapitalInUsd": 126919.7880578765,
            "marketPrice": 34.58,
            "marketValueFC": 459836195,
            "marketValueIc": 459836195,
            "name": "CHINA MERCHANTS BANK-A",
            "percentOfNav": 4.766255,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 101,
            "sharesHeld": 13297750,
            "ticker": "600036 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 174.2177784347,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 416192063,
            "exposureNAV": 4.31,
            "holdingType": "NAV1",
            "isin": "CNE000000VQ8",
            "marketCapital": 758000.41,
            "marketCapitalInUsd": 110338.0607877959,
            "marketPrice": 195.28,
            "marketValueFC": 416192063,
            "marketValueIc": 416192063,
            "name": "WULIANGYE YIBIN CO LTD-A",
            "percentOfNav": 4.313879,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 111,
            "sharesHeld": 2131258,
            "ticker": "000858 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 50.6820796376,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 310958129,
            "exposureNAV": 3.22,
            "holdingType": "NAV1",
            "isin": "CNE000001R84",
            "marketCapital": 823458.41,
            "marketCapitalInUsd": 119866.4313371568,
            "marketPrice": 45.46,
            "marketValueFC": 310958129,
            "marketValueIc": 310958129,
            "name": "PING AN INSURANCE GROUP CO-A",
            "percentOfNav": 3.223118,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 110,
            "sharesHeld": 6840258,
            "ticker": "601318 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 230.940507061,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 274753342,
            "exposureNAV": 2.85,
            "holdingType": "NAV1",
            "isin": "CNE100001526",
            "marketCapital": 623581.52,
            "marketCapitalInUsd": 90771.4227488428,
            "marketPrice": 239.98,
            "marketValueFC": 274753342,
            "marketValueIc": 274753342,
            "name": "BYD CO LTD -A",
            "percentOfNav": 2.84785,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 85,
            "sharesHeld": 1144901,
            "ticker": "002594 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 295.4918903877,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 236318923,
            "exposureNAV": 2.45,
            "holdingType": "NAV1",
            "isin": "CNE100003G67",
            "marketCapital": 374317.03,
            "marketCapitalInUsd": 54487.3256863373,
            "marketPrice": 308.73,
            "marketValueFC": 236318923,
            "marketValueIc": 236318923,
            "name": "SHENZHEN MINDRAY BIO-MEDIC-A",
            "percentOfNav": 2.449473,
            "sector": "Health Care",
            "securityType": "Equity",
            "seqno": 25,
            "sharesHeld": 765455,
            "ticker": "300760 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 158.0633032023,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 229726130,
            "exposureNAV": 2.38,
            "holdingType": "NAV1",
            "isin": "CNE100000G29",
            "marketCapital": 383432.48,
            "marketCapitalInUsd": 55814.2129319631,
            "marketPrice": 186.35,
            "marketValueFC": 229726130,
            "marketValueIc": 229726130,
            "name": "CHINA INTERNATIONAL TRAVEL-A",
            "percentOfNav": 2.381138,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 67,
            "sharesHeld": 1232767,
            "ticker": "601888 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 215.8519939621,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 223456968,
            "exposureNAV": 2.32,
            "holdingType": "NAV1",
            "isin": "CNE000000GF2",
            "marketCapital": 354013.06,
            "marketCapitalInUsd": 51531.7854959387,
            "marketPrice": 240.5,
            "marketValueFC": 223456968,
            "marketValueIc": 223456968,
            "name": "LUZHOU LAOJIAO CO LTD-A",
            "percentOfNav": 2.316157,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 109,
            "sharesHeld": 929135,
            "ticker": "000568 C2 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 17.9653421604,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 216279542,
            "exposureNAV": 2.25,
            "holdingType": "NAV1",
            "isin": "CNE000001QZ7",
            "marketCapital": 342567.57,
            "marketCapitalInUsd": 49865.7268042738,
            "marketPrice": 16.49,
            "marketValueFC": 216279542,
            "marketValueIc": 216279542,
            "name": "INDUSTRIAL BANK CO LTD -A",
            "percentOfNav": 2.241762,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 96,
            "sharesHeld": 13115800,
            "ticker": "601166 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 4.6730664454,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 214471905,
            "exposureNAV": 2.22,
            "holdingType": "NAV1",
            "isin": "CNE000001P37",
            "marketCapital": 1502462.3,
            "marketCapitalInUsd": 218705.3917144604,
            "marketPrice": 4.41,
            "marketValueFC": 214471905,
            "marketValueIc": 214471905,
            "name": "IND \u0026 COMM BK OF CHINA-A",
            "percentOfNav": 2.223027,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 93,
            "sharesHeld": 48633085,
            "ticker": "601398 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 284.8262400104,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 209605977,
            "exposureNAV": 2.17,
            "holdingType": "NAV1",
            "isin": "CNE000000DH5",
            "marketCapital": 331981.98,
            "marketCapitalInUsd": 48324.839151067,
            "marketPrice": 272.1,
            "marketValueFC": 209605977,
            "marketValueIc": 209605977,
            "name": "SHANXI XINGHUACUN FEN WINE-A",
            "percentOfNav": 2.17259,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 79,
            "sharesHeld": 770327,
            "ticker": "600809 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 20.8848,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 201218500,
            "exposureNAV": 2.09,
            "holdingType": "NAV1",
            "isin": "CNE000001G87",
            "marketCapital": 498595.88,
            "marketCapitalInUsd": 72577.9323997787,
            "marketPrice": 21.07,
            "marketValueFC": 201218500,
            "marketValueIc": 201218500,
            "name": "CHINA YANGTZE POWER CO LTD-A",
            "percentOfNav": 2.085653,
            "sector": "Utilities",
            "securityType": "Fully Funded CFD",
            "seqno": 11,
            "sharesHeld": 9550000,
            "ticker": "600900 C1 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 79.3280442547,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 195616237,
            "exposureNAV": 2.03,
            "holdingType": "NAV1",
            "isin": "CNE100001SL2",
            "marketCapital": 351522.63,
            "marketCapitalInUsd": 51169.2669364465,
            "marketPrice": 75.86,
            "marketValueFC": 195616237,
            "marketValueIc": 195616237,
            "name": "FOSHAN HAITIAN FLAVOURING -A",
            "percentOfNav": 2.027585,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 108,
            "sharesHeld": 2578648,
            "ticker": "603288 C1 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 3.2239280605,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 179832383,
            "exposureNAV": 1.86,
            "holdingType": "NAV1",
            "isin": "CNE100000RJ0",
            "marketCapital": 1035835.84,
            "marketCapitalInUsd": 150781.0765961163,
            "marketPrice": 3.01,
            "marketValueFC": 179832383,
            "marketValueIc": 179832383,
            "name": "AGRICULTURAL BANK OF CHINA-A",
            "percentOfNav": 1.863984,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 95,
            "sharesHeld": 59744978,
            "ticker": "601288 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 50.3758406551,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 164701317,
            "exposureNAV": 1.7,
            "holdingType": "NAV1",
            "isin": "CNE100000L63",
            "marketCapital": 260914.29,
            "marketCapitalInUsd": 37979.8960668433,
            "marketPrice": 53.3,
            "marketValueFC": 164701317,
            "marketValueIc": 164701317,
            "name": "S F HOLDING CO LTD-A",
            "percentOfNav": 1.707148,
            "sector": "Industrials",
            "securityType": "Equity",
            "seqno": 43,
            "sharesHeld": 3090081,
            "ticker": "002352 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 86.1397658559,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 162264428,
            "exposureNAV": 1.68,
            "holdingType": "NAV1",
            "isin": "CNE0000016J9",
            "marketCapital": 308354.52,
            "marketCapitalInUsd": 44885.5163177967,
            "marketPrice": 98.21,
            "marketValueFC": 162264428,
            "marketValueIc": 162264428,
            "name": "WANHUA CHEMICAL GROUP CO -A",
            "percentOfNav": 1.681889,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 75,
            "sharesHeld": 1652219,
            "ticker": "600309 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.1253090246,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 158610767,
            "exposureNAV": 1.64,
            "holdingType": "NAV1",
            "isin": "CNE100000F46",
            "marketCapital": 251187.25,
            "marketCapitalInUsd": 36563.9829398236,
            "marketPrice": 5.99,
            "marketValueFC": 158610767,
            "marketValueIc": 158610767,
            "name": "CHINA STATE CONSTRUCTION -A",
            "percentOfNav": 1.644019,
            "sector": "Industrials",
            "securityType": "Equity",
            "seqno": 90,
            "sharesHeld": 26479260,
            "ticker": "601668 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 14.4220869374,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 157658247,
            "exposureNAV": 1.64,
            "holdingType": "NAV1",
            "isin": "CNE000000040",
            "marketCapital": 249754.17,
            "marketCapitalInUsd": 36355.3771579959,
            "marketPrice": 12.87,
            "marketValueFC": 157658247,
            "marketValueIc": 157658247,
            "name": "PING AN BANK CO LTD-A",
            "percentOfNav": 1.634146,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 107,
            "sharesHeld": 12250058,
            "ticker": "000001 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 166.4743841543,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 157821389,
            "exposureNAV": 1.64,
            "holdingType": "NAV1",
            "isin": "CNE100000HB8",
            "marketCapital": 250024.38,
            "marketCapitalInUsd": 36394.7101807913,
            "marketPrice": 165.91,
            "marketValueFC": 157821389,
            "marketValueIc": 157821389,
            "name": "JIANGSU YANGHE BREWERY -A",
            "percentOfNav": 1.635837,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 105,
            "sharesHeld": 951247,
            "ticker": "002304 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 10.2530009106,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 156370957,
            "exposureNAV": 1.62,
            "holdingType": "NAV1",
            "isin": "CNE100000B24",
            "marketCapital": 311180.1,
            "marketCapitalInUsd": 45296.8208681475,
            "marketPrice": 12.03,
            "marketValueFC": 156370957,
            "marketValueIc": 156370957,
            "name": "ZIJIN MINING GROUP CO LTD-A",
            "percentOfNav": 1.620803,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 66,
            "sharesHeld": 12998417,
            "ticker": "601899 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 21.3652328323,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 152947897,
            "exposureNAV": 1.59,
            "holdingType": "NAV1",
            "isin": "CNE000001DB6",
            "marketCapital": 279326.01,
            "marketCapitalInUsd": 40659.9915572506,
            "marketPrice": 19.86,
            "marketValueFC": 152947897,
            "marketValueIc": 152947897,
            "name": "CITIC SECURITIES CO-A",
            "percentOfNav": 1.585322,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 102,
            "sharesHeld": 7701304,
            "ticker": "600030 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 9.4790595246,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 130052612,
            "exposureNAV": 1.35,
            "holdingType": "NAV1",
            "isin": "CNE0000011B7",
            "marketCapital": 206052.26,
            "marketCapitalInUsd": 29993.9241317069,
            "marketPrice": 7.02,
            "marketValueFC": 130052612,
            "marketValueIc": 130052612,
            "name": "SHANGHAI PUDONG DEVEL BANK-A",
            "percentOfNav": 1.34801,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 104,
            "sharesHeld": 18526013,
            "ticker": "600000 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 24.1846796964,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 128299015,
            "exposureNAV": 1.33,
            "holdingType": "NAV1",
            "isin": "CNE100000767",
            "marketCapital": 547954.91,
            "marketCapitalInUsd": 79762.8620920551,
            "marketPrice": 28.73,
            "marketValueFC": 128299015,
            "marketValueIc": 128299015,
            "name": "CHINA SHENHUA ENERGY CO-A",
            "percentOfNav": 1.329834,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 97,
            "sharesHeld": 4465681,
            "ticker": "601088 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 34.2509968083,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 126734142,
            "exposureNAV": 1.31,
            "holdingType": "NAV1",
            "isin": "CNE100000TP3",
            "marketCapital": 201581.66,
            "marketCapitalInUsd": 29343.1628286122,
            "marketPrice": 28.33,
            "marketValueFC": 126734142,
            "marketValueIc": 126734142,
            "name": "LUXSHARE PRECISION INDUSTR-A",
            "percentOfNav": 1.313614,
            "sector": "Technology",
            "securityType": "Equity",
            "seqno": 83,
            "sharesHeld": 4473496,
            "ticker": "002475 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.2041152456,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 125413446,
            "exposureNAV": 1.3,
            "holdingType": "NAV1",
            "isin": "CNE1000000S2",
            "marketCapital": 343783.29,
            "marketCapitalInUsd": 50042.692654808,
            "marketPrice": 5.06,
            "marketValueFC": 125413446,
            "marketValueIc": 125413446,
            "name": "BANK OF COMMUNICATIONS CO-A",
            "percentOfNav": 1.299925,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 94,
            "sharesHeld": 24785266,
            "ticker": "601328 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 39.4461,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 124440000,
            "exposureNAV": 1.29,
            "holdingType": "NAV1",
            "isin": "CNE0000014W7",
            "marketCapital": 264601.01,
            "marketCapitalInUsd": 38516.5521558124,
            "marketPrice": 41.48,
            "marketValueFC": 124440000,
            "marketValueIc": 124440000,
            "name": "JIANGSU HENGRUI MEDICINE C-A 31 Dec 99",
            "percentOfNav": 1.289835,
            "sector": "Health Care",
            "securityType": "Fully Funded CFD",
            "seqno": 8,
            "sharesHeld": 3000000,
            "ticker": "600276 C1 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 35.8294266255,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 119604890,
            "exposureNAV": 1.24,
            "holdingType": "NAV1",
            "isin": "CNE000000JP5",
            "marketCapital": 189024.06,
            "marketCapitalInUsd": 27515.2202393083,
            "marketPrice": 29.54,
            "marketValueFC": 119604890,
            "marketValueIc": 119604890,
            "name": "INNER MONGOLIA YILI INDUS-A",
            "percentOfNav": 1.239718,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 84,
            "sharesHeld": 4048913,
            "ticker": "600887 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 4.5990153678,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 118925286,
            "exposureNAV": 1.23,
            "holdingType": "NAV1",
            "isin": "CNE0000018G1",
            "marketCapital": 625834.47,
            "marketCapitalInUsd": 91099.3726163789,
            "marketPrice": 5.52,
            "marketValueFC": 118925286,
            "marketValueIc": 118925286,
            "name": "CHINA PETROLEUM \u0026 CHEMICAL-A",
            "percentOfNav": 1.232675,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 103,
            "sharesHeld": 21544436,
            "ticker": "600028 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 33.8864883428,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 115524697,
            "exposureNAV": 1.19,
            "holdingType": "NAV1",
            "isin": "CNE1000005P7",
            "marketCapital": 182523.25,
            "marketCapitalInUsd": 26568.9321377624,
            "marketPrice": 27.64,
            "marketValueFC": 115524697,
            "marketValueIc": 115524697,
            "name": "BANK OF NINGBO CO LTD -A",
            "percentOfNav": 1.197427,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 73,
            "sharesHeld": 4179620,
            "ticker": "002142 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 25.1458155562,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 113295475,
            "exposureNAV": 1.18,
            "holdingType": "NAV1",
            "isin": "CNE1000008M8",
            "marketCapital": 230271.16,
            "marketCapitalInUsd": 33519.3397187691,
            "marketPrice": 26.23,
            "marketValueFC": 113295475,
            "marketValueIc": 113295475,
            "name": "CHINA PACIFIC INSURANCE GR-A",
            "percentOfNav": 1.174321,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 92,
            "sharesHeld": 4319309,
            "ticker": "601601 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 53.3651979778,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 110985350,
            "exposureNAV": 1.15,
            "holdingType": "NAV1",
            "isin": "CNE000001GS3",
            "marketCapital": 176026.09,
            "marketCapitalInUsd": 25623.1753471717,
            "marketPrice": 39.1,
            "marketValueFC": 110985350,
            "marketValueIc": 110985350,
            "name": "TONGWEI CO LTD-A",
            "percentOfNav": 1.150376,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 39,
            "sharesHeld": 2838500,
            "ticker": "600438 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 49.3259236447,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 104043489,
            "exposureNAV": 1.07,
            "holdingType": "NAV1",
            "isin": "CNE100001FR6",
            "marketCapital": 317513.63,
            "marketCapitalInUsd": 46218.7589158345,
            "marketPrice": 41.88,
            "marketValueFC": 104043489,
            "marketValueIc": 104043489,
            "name": "LONGI GREEN ENERGY TECHNOL-A",
            "percentOfNav": 1.078423,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 78,
            "sharesHeld": 2484324,
            "ticker": "601012 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 21.9412366477,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 101343308,
            "exposureNAV": 1.05,
            "holdingType": "NAV1",
            "isin": "CNE000001G87",
            "marketCapital": 498595.88,
            "marketCapitalInUsd": 72577.9323997787,
            "marketPrice": 21.07,
            "marketValueFC": 101343308,
            "marketValueIc": 101343308,
            "name": "CHINA YANGTZE POWER CO LTD-A",
            "percentOfNav": 1.050435,
            "sector": "Utilities",
            "securityType": "Equity",
            "seqno": 98,
            "sharesHeld": 4809839,
            "ticker": "600900 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 49.4251,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 100800000,
            "exposureNAV": 1.04,
            "holdingType": "NAV1",
            "isin": "CNE100001RQ3",
            "marketCapital": 275803.27,
            "marketCapitalInUsd": 40147.205158811,
            "marketPrice": 50.4,
            "marketValueFC": 100800000,
            "marketValueIc": 100800000,
            "name": "MUYUAN FOODSTUFF CO LTD-A",
            "percentOfNav": 1.044804,
            "sector": "Consumer Staples",
            "securityType": "Fully Funded CFD",
            "seqno": 7,
            "sharesHeld": 2000000,
            "ticker": "002714 C2 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 22.5193809273,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 96766929,
            "exposureNAV": 1.01,
            "holdingType": "NAV1",
            "isin": "CNE0000000T2",
            "marketCapital": 176947.7,
            "marketCapitalInUsd": 25757.3291798888,
            "marketPrice": 15.75,
            "marketValueFC": 96766929,
            "marketValueIc": 96766929,
            "name": "CHINA VANKE CO LTD -A",
            "percentOfNav": 1.003,
            "sector": "Real Estate",
            "securityType": "Equity",
            "seqno": 106,
            "sharesHeld": 6143932,
            "ticker": "000002 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 43.1843,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 96324000,
            "exposureNAV": 1,
            "holdingType": "NAV1",
            "isin": "CNE100001FR6",
            "marketCapital": 317513.63,
            "marketCapitalInUsd": 46218.7589158345,
            "marketPrice": 41.88,
            "marketValueFC": 96324000,
            "marketValueIc": 96324000,
            "name": "LONGI GREEN ENERGY TECHNOL-A",
            "percentOfNav": 0.998409,
            "sector": "Energy",
            "securityType": "Fully Funded CFD",
            "seqno": 4,
            "sharesHeld": 2300000,
            "ticker": "601012 C1 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 21.8429686944,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 95526774,
            "exposureNAV": 0.99,
            "holdingType": "NAV1",
            "isin": "CNE100001T64",
            "marketCapital": 187986.05,
            "marketCapitalInUsd": 27364.122681883,
            "marketPrice": 19.39,
            "marketValueFC": 95526774,
            "marketValueIc": 95526774,
            "name": "SHAANXI COAL INDUSTRY CO L-A",
            "percentOfNav": 0.990147,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 65,
            "sharesHeld": 4926600,
            "ticker": "601225 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 26.5555261671,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 93824490,
            "exposureNAV": 0.97,
            "holdingType": "NAV1",
            "isin": "CNE000000CG9",
            "marketCapital": 215174.21,
            "marketCapitalInUsd": 31321.7575475269,
            "marketPrice": 23.58,
            "marketValueFC": 93824490,
            "marketValueIc": 93824490,
            "name": "QINGDAO HAIER CO LTD-A",
            "percentOfNav": 0.972502,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 63,
            "sharesHeld": 3978986,
            "ticker": "600690 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 10.4133520857,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 92650160,
            "exposureNAV": 0.96,
            "holdingType": "NAV1",
            "isin": "CNE1000031P3",
            "marketCapital": 233548.81,
            "marketCapitalInUsd": 33996.4496783021,
            "marketPrice": 11.76,
            "marketValueFC": 92650160,
            "marketValueIc": 92650160,
            "name": "FOXCONN INDUSTRIAL INTERNE-A",
            "percentOfNav": 0.96033,
            "sector": "Technology",
            "securityType": "Equity",
            "seqno": 31,
            "sharesHeld": 7878415,
            "ticker": "601138 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 22.6041,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 91862000,
            "exposureNAV": 0.95,
            "holdingType": "NAV1",
            "isin": "CNE100000MD4",
            "marketCapital": 263886.83,
            "marketCapitalInUsd": 38412.5927974614,
            "marketPrice": 19.97,
            "marketValueFC": 91862000,
            "marketValueIc": 91862000,
            "name": "EAST MONEY INFORMATION CO-A",
            "percentOfNav": 0.95216,
            "sector": "Financials",
            "securityType": "Fully Funded CFD",
            "seqno": 10,
            "sharesHeld": 4600000,
            "ticker": "300059 C2 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.3208896925,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 87216585,
            "exposureNAV": 0.9,
            "holdingType": "NAV1",
            "isin": "CNE1000007Q1",
            "marketCapital": 978569.19,
            "marketCapitalInUsd": 142445.0770036973,
            "marketPrice": 5.55,
            "marketValueFC": 87216585,
            "marketValueIc": 87216585,
            "name": "PETROCHINA CO LTD-A",
            "percentOfNav": 0.90401,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 89,
            "sharesHeld": 15714700,
            "ticker": "601857 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 17.3225749588,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 85771471,
            "exposureNAV": 0.89,
            "holdingType": "NAV1",
            "isin": "CNE000000TY6",
            "marketCapital": 164152.63,
            "marketCapitalInUsd": 23894.8193542752,
            "marketPrice": 14.05,
            "marketValueFC": 85771471,
            "marketValueIc": 85771471,
            "name": "SAIC MOTOR CORP LTD-A",
            "percentOfNav": 0.889032,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 100,
            "sharesHeld": 6104731,
            "ticker": "600104 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 3.3076768194,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 84772053,
            "exposureNAV": 0.88,
            "holdingType": "NAV1",
            "isin": "CNE000001N05",
            "marketCapital": 915900.07,
            "marketCapitalInUsd": 133322.6687822062,
            "marketPrice": 3.33,
            "marketValueFC": 84772053,
            "marketValueIc": 84772053,
            "name": "BANK OF CHINA LTD-A",
            "percentOfNav": 0.878672,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 87,
            "sharesHeld": 25457073,
            "ticker": "601988 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 63.2488916954,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 78627315,
            "exposureNAV": 0.82,
            "holdingType": "NAV1",
            "isin": "CNE100000GS4",
            "marketCapital": 134608.47,
            "marketCapitalInUsd": 19594.2341844013,
            "marketPrice": 65.8,
            "marketValueFC": 78627315,
            "marketValueIc": 78627315,
            "name": "EVE ENERGY CO LTD-A",
            "percentOfNav": 0.814982,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 48,
            "sharesHeld": 1194944,
            "ticker": "300014 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 21.8270535852,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 74718993,
            "exposureNAV": 0.78,
            "holdingType": "NAV1",
            "isin": "CNE100000MD4",
            "marketCapital": 263886.83,
            "marketCapitalInUsd": 38412.5927974614,
            "marketPrice": 19.97,
            "marketValueFC": 74718993,
            "marketValueIc": 74718993,
            "name": "EAST MONEY INFORMATION CO-A",
            "percentOfNav": 0.774471,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 81,
            "sharesHeld": 3741562,
            "ticker": "300059 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 91,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 71118000,
            "exposureNAV": 0.74,
            "holdingType": "NAV1",
            "isin": "CNE1000031K4",
            "marketCapital": 229865.98,
            "marketCapitalInUsd": 33460.3598358031,
            "marketPrice": 79.02,
            "marketValueFC": 71118000,
            "marketValueIc": 71118000,
            "name": "WUXI APPTEC CO LTD-A",
            "percentOfNav": 0.737146,
            "sector": "Health Care",
            "securityType": "Fully Funded CFD",
            "seqno": 3,
            "sharesHeld": 900000,
            "ticker": "603259 C1 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 53.2523013359,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 68522479,
            "exposureNAV": 0.71,
            "holdingType": "NAV1",
            "isin": "CNE100001RQ3",
            "marketCapital": 275803.27,
            "marketCapitalInUsd": 40147.205158811,
            "marketPrice": 50.4,
            "marketValueFC": 68522479,
            "marketValueIc": 68522479,
            "name": "MUYUAN FOODSTUFF CO LTD-A",
            "percentOfNav": 0.710244,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 80,
            "sharesHeld": 1359573,
            "ticker": "002714 CS EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.0466531673,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 62800423,
            "exposureNAV": 0.65,
            "holdingType": "NAV1",
            "isin": "CNE100003PZ4",
            "marketCapital": 427303.22,
            "marketCapitalInUsd": 62200.2416373111,
            "marketPrice": 4.73,
            "marketValueFC": 62800423,
            "marketValueIc": 62800423,
            "name": "POSTAL SAVINGS BANK OF CHI-A",
            "percentOfNav": 0.650934,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 20,
            "sharesHeld": 13277045,
            "ticker": "601658 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 198.58,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 62489600,
            "exposureNAV": 0.65,
            "holdingType": "NAV1",
            "isin": "CNE000000VQ8",
            "marketCapital": 758000.41,
            "marketCapitalInUsd": 110338.0607877959,
            "marketPrice": 195.28,
            "marketValueFC": 62489600,
            "marketValueIc": 62489600,
            "name": "WULIANGYE YIBIN CO LTD-A",
            "percentOfNav": 0.647712,
            "sector": "Consumer Staples",
            "securityType": "Fully Funded CFD",
            "seqno": 1,
            "sharesHeld": 320000,
            "ticker": "000858 C2 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 80.7083212325,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 56872195,
            "exposureNAV": 0.59,
            "holdingType": "NAV1",
            "isin": "CNE1000031K4",
            "marketCapital": 229865.98,
            "marketCapitalInUsd": 33460.3598358031,
            "marketPrice": 79.02,
            "marketValueFC": 56872195,
            "marketValueIc": 56872195,
            "name": "WUXI APPTEC CO LTD-A",
            "percentOfNav": 0.589487,
            "sector": "Health Care",
            "securityType": "Equity",
            "seqno": 27,
            "sharesHeld": 719719,
            "ticker": "603259 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 13.5658186079,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 46300451,
            "exposureNAV": 0.48,
            "holdingType": "NAV1",
            "isin": "CNE100000601",
            "marketCapital": 161820.76,
            "marketCapitalInUsd": 23555.3815249352,
            "marketPrice": 10.79,
            "marketValueFC": 46300451,
            "marketValueIc": 46300451,
            "name": "COSCO SHIPPING HOLDINGS CO-A",
            "percentOfNav": 0.479909,
            "sector": "Industrials",
            "securityType": "Equity",
            "seqno": 64,
            "sharesHeld": 4291052,
            "ticker": "601919 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 36.9481747008,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 42592203,
            "exposureNAV": 0.44,
            "holdingType": "NAV1",
            "isin": "CNE0000014W7",
            "marketCapital": 264601.01,
            "marketCapitalInUsd": 38516.5521558124,
            "marketPrice": 41.48,
            "marketValueFC": 42592203,
            "marketValueIc": 42592203,
            "name": "JIANGSU HENGRUI MEDICINE C-A",
            "percentOfNav": 0.441473,
            "sector": "Health Care",
            "securityType": "Equity",
            "seqno": 77,
            "sharesHeld": 1026813,
            "ticker": "600276 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 0,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 39923000,
            "exposureNAV": 0.41,
            "holdingType": "NAV1",
            "isin": "CNE100000601",
            "marketCapital": 161820.76,
            "marketCapitalInUsd": 23555.3815249352,
            "marketPrice": 10.79,
            "marketValueFC": 39923000,
            "marketValueIc": 39923000,
            "name": "COSCO SHIPPING HOLDINGS CO-A",
            "percentOfNav": 0.413807,
            "sector": "Industrials",
            "securityType": "Fully Funded CFD",
            "seqno": 5,
            "sharesHeld": 3700000,
            "ticker": "601919 C1 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 46.0823,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 34352000,
            "exposureNAV": 0.36,
            "holdingType": "NAV1",
            "isin": "CNE1000048D3",
            "marketCapital": 232803.14,
            "marketCapitalInUsd": 33887.9064892719,
            "marketPrice": 42.94,
            "marketValueFC": 34352000,
            "marketValueIc": 34352000,
            "name": "YIHAI KERRY ARAWANA HOLDIN-A",
            "percentOfNav": 0.356062,
            "sector": "Consumer Staples",
            "securityType": "Fully Funded CFD",
            "seqno": 9,
            "sharesHeld": 800000,
            "ticker": "300999 C2 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 6.1152092679,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 35237726,
            "exposureNAV": 0.36,
            "holdingType": "NAV1",
            "isin": "CNE100000742",
            "marketCapital": 1092845.85,
            "marketCapitalInUsd": 159079.7184779761,
            "marketPrice": 5.83,
            "marketValueFC": 35237726,
            "marketValueIc": 35237726,
            "name": "CHINA CONSTRUCTION BANK-A",
            "percentOfNav": 0.365244,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 88,
            "sharesHeld": 6044207,
            "ticker": "601939 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 90.6941,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 32409300,
            "exposureNAV": 0.34,
            "holdingType": "NAV1",
            "isin": "CNE0000016J9",
            "marketCapital": 308354.52,
            "marketCapitalInUsd": 44885.5163177967,
            "marketPrice": 98.21,
            "marketValueFC": 32409300,
            "marketValueIc": 32409300,
            "name": "WANHUA CHEMICAL GROUP CO -A",
            "percentOfNav": 0.335926,
            "sector": "Materials",
            "securityType": "Fully Funded CFD",
            "seqno": 2,
            "sharesHeld": 330000,
            "ticker": "600309 C1 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 4.9318029102,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 31278208,
            "exposureNAV": 0.32,
            "holdingType": "NAV1",
            "isin": "CNE1000000R4",
            "marketCapital": 220895.27,
            "marketCapitalInUsd": 32154.5416169321,
            "marketPrice": 5.1,
            "marketValueFC": 31278208,
            "marketValueIc": 31278208,
            "name": "CHINA CITIC BANK CORP LTD-A",
            "percentOfNav": 0.324202,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 86,
            "sharesHeld": 6132982,
            "ticker": "601998 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 28.7673,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 27270000,
            "exposureNAV": 0.28,
            "holdingType": "NAV1",
            "isin": "CNE1000018V8",
            "marketCapital": 184883.75,
            "marketCapitalInUsd": 26912.5374828962,
            "marketPrice": 27.27,
            "marketValueFC": 27270000,
            "marketValueIc": 27270000,
            "name": "GREAT WALL MOTOR CO LTD-A",
            "percentOfNav": 0.282657,
            "sector": "Consumer Discretionary",
            "securityType": "Fully Funded CFD",
            "seqno": 6,
            "sharesHeld": 1000000,
            "ticker": "601633 C1 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 19.73,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 23268000,
            "exposureNAV": 0.24,
            "holdingType": "NAV1",
            "isin": "CNE100001T64",
            "marketCapital": 187986.05,
            "marketCapitalInUsd": 27364.122681883,
            "marketPrice": 19.39,
            "marketValueFC": 23268000,
            "marketValueIc": 23268000,
            "name": "SHAANXI COAL INDUSTRY CO L-A",
            "percentOfNav": 0.241176,
            "sector": "Materials",
            "securityType": "Fully Funded CFD",
            "seqno": 0,
            "sharesHeld": 1200000,
            "ticker": "601225 C1 EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 42.4304931076,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 20027606,
            "exposureNAV": 0.21,
            "holdingType": "NAV1",
            "isin": "CNE1000018V8",
            "marketCapital": 184883.75,
            "marketCapitalInUsd": 26912.5374828962,
            "marketPrice": 27.27,
            "marketValueFC": 20027606,
            "marketValueIc": 20027606,
            "name": "GREAT WALL MOTOR CO LTD-A",
            "percentOfNav": 0.207588,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 91,
            "sharesHeld": 734419,
            "ticker": "601633 CG EQUITY"
          },
          {
            "accountID": "",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 51.3100045133,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 18141678,
            "exposureNAV": 0.19,
            "holdingType": "NAV1",
            "isin": "CNE1000048D3",
            "marketCapital": 232803.14,
            "marketCapitalInUsd": 33887.9064892719,
            "marketPrice": 42.94,
            "marketValueFC": 18141678,
            "marketValueIc": 18141678,
            "name": "YIHAI KERRY ARAWANA HOLDIN-A",
            "percentOfNav": 0.18804,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 18,
            "sharesHeld": 422489,
            "ticker": "300999 C2 EQUITY"
          }
        ]
      },
      "marketCapExposureList": [
        {
          "exposure": 99.98,
          "label": "Large Cap (\u003e7692.307692 Mn)"
        }
      ],
      "naggrViewData": {
        "equityHoldings": [
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 1420.1663,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 1133159198,
            "exposureNAV": 11.75,
            "holdingType": "NAV1",
            "isin": "CNE0000018R8",
            "marketCapital": 2218445.31,
            "marketCapitalInUsd": 322927.2045765525,
            "marketPrice": 1766,
            "marketValueFC": 1133159198,
            "marketValueIc": 1133159198,
            "name": "KWEICHOW MOUTAI CO LTD-A",
            "percentOfNav": 11.745327,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 62,
            "sharesHeld": 641653,
            "ticker": "600519 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 442.1622,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 538432860,
            "exposureNAV": 5.58,
            "holdingType": "NAV1",
            "isin": "CNE100003662",
            "marketCapital": 945814.9,
            "marketCapitalInUsd": 137677.2103991383,
            "marketPrice": 387.23,
            "marketValueFC": 538432860,
            "marketValueIc": 538432860,
            "name": "CONTEMPORARY AMPEREX TECHN-A",
            "percentOfNav": 5.580919,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 23,
            "sharesHeld": 1390473,
            "ticker": "300750 C2 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 35.5479,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 374259790,
            "exposureNAV": 3.88,
            "holdingType": "NAV1",
            "isin": "CNE000001B33",
            "marketCapital": 871913.56,
            "marketCapitalInUsd": 126919.7880578765,
            "marketPrice": 34.58,
            "marketValueFC": 374259790,
            "marketValueIc": 374259790,
            "name": "CHINA MERCHANTS BANK-A",
            "percentOfNav": 3.879246,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 76,
            "sharesHeld": 10823013,
            "ticker": "600036 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 174.6416,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 323593411,
            "exposureNAV": 3.35,
            "holdingType": "NAV1",
            "isin": "CNE000000VQ8",
            "marketCapital": 758000.41,
            "marketCapitalInUsd": 110338.0607877959,
            "marketPrice": 195.28,
            "marketValueFC": 323593411,
            "marketValueIc": 323593411,
            "name": "WULIANGYE YIBIN CO LTD-A",
            "percentOfNav": 3.354083,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 54,
            "sharesHeld": 1657074,
            "ticker": "000858 C2 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 1803.1132,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 266805514,
            "exposureNAV": 2.77,
            "holdingType": "NAV1",
            "isin": "CNE0000018R8",
            "marketCapital": 2218445.31,
            "marketCapitalInUsd": 322927.2045765525,
            "marketPrice": 1766,
            "marketValueFC": 266805514,
            "marketValueIc": 266805514,
            "name": "KWEICHOW MOUTAI CO LTD-A",
            "percentOfNav": 2.76547,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 99,
            "sharesHeld": 151079,
            "ticker": "600519 CG EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 50.4459,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 250843552,
            "exposureNAV": 2.6,
            "holdingType": "NAV1",
            "isin": "CNE000001R84",
            "marketCapital": 823458.41,
            "marketCapitalInUsd": 119866.4313371568,
            "marketPrice": 45.46,
            "marketValueFC": 250843552,
            "marketValueIc": 250843552,
            "name": "PING AN INSURANCE GROUP CO-A",
            "percentOfNav": 2.600023,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 60,
            "sharesHeld": 5517896,
            "ticker": "601318 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 226.141,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 221602812,
            "exposureNAV": 2.3,
            "holdingType": "NAV1",
            "isin": "CNE100001526",
            "marketCapital": 623581.52,
            "marketCapitalInUsd": 90771.4227488428,
            "marketPrice": 239.98,
            "marketValueFC": 221602812,
            "marketValueIc": 221602812,
            "name": "BYD CO LTD -A",
            "percentOfNav": 2.296939,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 41,
            "sharesHeld": 923422,
            "ticker": "002594 C2 EQUITY"
          },
          {
            "accountID": "GSI",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 20.8848,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 201218500,
            "exposureNAV": 2.09,
            "holdingType": "NAV1",
            "isin": "CNE000001G87",
            "marketCapital": 498595.88,
            "marketCapitalInUsd": 72577.9323997787,
            "marketPrice": 21.07,
            "marketValueFC": 201218500,
            "marketValueIc": 201218500,
            "name": "CHINA YANGTZE POWER CO LTD-A",
            "percentOfNav": 2.085653,
            "sector": "Utilities",
            "securityType": "Fully Funded CFD",
            "seqno": 11,
            "sharesHeld": 9550000,
            "ticker": "600900 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 290.7105,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 190724750,
            "exposureNAV": 1.98,
            "holdingType": "NAV1",
            "isin": "CNE100003G67",
            "marketCapital": 374317.03,
            "marketCapitalInUsd": 54487.3256863373,
            "marketPrice": 308.73,
            "marketValueFC": 190724750,
            "marketValueIc": 190724750,
            "name": "SHENZHEN MINDRAY BIO-MEDIC-A",
            "percentOfNav": 1.976884,
            "sector": "Health Care",
            "securityType": "Equity",
            "seqno": 24,
            "sharesHeld": 617772,
            "ticker": "300760 C2 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 148.0388,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 185366072,
            "exposureNAV": 1.92,
            "holdingType": "NAV1",
            "isin": "CNE100000G29",
            "marketCapital": 383432.48,
            "marketCapitalInUsd": 55814.2129319631,
            "marketPrice": 186.35,
            "marketValueFC": 185366072,
            "marketValueIc": 185366072,
            "name": "CHINA INTERNATIONAL TRAVEL-A",
            "percentOfNav": 1.921341,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 49,
            "sharesHeld": 994720,
            "ticker": "601888 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 214.536,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 180287218,
            "exposureNAV": 1.87,
            "holdingType": "NAV1",
            "isin": "CNE000000GF2",
            "marketCapital": 354013.06,
            "marketCapitalInUsd": 51531.7854959387,
            "marketPrice": 240.5,
            "marketValueFC": 180287218,
            "marketValueIc": 180287218,
            "name": "LUZHOU LAOJIAO CO LTD-A",
            "percentOfNav": 1.868698,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 109,
            "sharesHeld": 749635,
            "ticker": "000568 C2 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 17.8901,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 174240431,
            "exposureNAV": 1.81,
            "holdingType": "NAV1",
            "isin": "CNE000001QZ7",
            "marketCapital": 342567.57,
            "marketCapitalInUsd": 49865.7268042738,
            "marketPrice": 16.49,
            "marketValueFC": 174240431,
            "marketValueIc": 174240431,
            "name": "INDUSTRIAL BANK CO LTD -A",
            "percentOfNav": 1.806022,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 59,
            "sharesHeld": 10566430,
            "ticker": "601166 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 4.7535,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 172903862,
            "exposureNAV": 1.79,
            "holdingType": "NAV1",
            "isin": "CNE000001P37",
            "marketCapital": 1502462.3,
            "marketCapitalInUsd": 218705.3917144604,
            "marketPrice": 4.41,
            "marketValueFC": 172903862,
            "marketValueIc": 172903862,
            "name": "IND \u0026 COMM BK OF CHINA-A",
            "percentOfNav": 1.792169,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 70,
            "sharesHeld": 39207225,
            "ticker": "601398 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 284.8375,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 169122939,
            "exposureNAV": 1.75,
            "holdingType": "NAV1",
            "isin": "CNE000000DH5",
            "marketCapital": 331981.98,
            "marketCapitalInUsd": 48324.839151067,
            "marketPrice": 272.1,
            "marketValueFC": 169122939,
            "marketValueIc": 169122939,
            "name": "SHANXI XINGHUACUN FEN WINE-A",
            "percentOfNav": 1.752979,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 38,
            "sharesHeld": 621547,
            "ticker": "600809 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 78.9353,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 157486725,
            "exposureNAV": 1.63,
            "holdingType": "NAV1",
            "isin": "CNE100001SL2",
            "marketCapital": 351522.63,
            "marketCapitalInUsd": 51169.2669364465,
            "marketPrice": 75.86,
            "marketValueFC": 157486725,
            "marketValueIc": 157486725,
            "name": "FOSHAN HAITIAN FLAVOURING -A",
            "percentOfNav": 1.632368,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 108,
            "sharesHeld": 2076018,
            "ticker": "603288 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 3.2444,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 144803671,
            "exposureNAV": 1.5,
            "holdingType": "NAV1",
            "isin": "CNE100000RJ0",
            "marketCapital": 1035835.84,
            "marketCapitalInUsd": 150781.0765961163,
            "marketPrice": 3.01,
            "marketValueFC": 144803671,
            "marketValueIc": 144803671,
            "name": "AGRICULTURAL BANK OF CHINA-A",
            "percentOfNav": 1.500907,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 47,
            "sharesHeld": 48107532,
            "ticker": "601288 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 50.1873,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 132588707,
            "exposureNAV": 1.37,
            "holdingType": "NAV1",
            "isin": "CNE100000L63",
            "marketCapital": 260914.29,
            "marketCapitalInUsd": 37979.8960668433,
            "marketPrice": 53.3,
            "marketValueFC": 132588707,
            "marketValueIc": 132588707,
            "name": "S F HOLDING CO LTD-A",
            "percentOfNav": 1.374297,
            "sector": "Industrials",
            "securityType": "Equity",
            "seqno": 42,
            "sharesHeld": 2487593,
            "ticker": "002352 C2 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 13.8418,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 127111662,
            "exposureNAV": 1.32,
            "holdingType": "NAV1",
            "isin": "CNE000000040",
            "marketCapital": 249754.17,
            "marketCapitalInUsd": 36355.3771579959,
            "marketPrice": 12.87,
            "marketValueFC": 127111662,
            "marketValueIc": 127111662,
            "name": "PING AN BANK CO LTD-A",
            "percentOfNav": 1.317527,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 12,
            "sharesHeld": 9876586,
            "ticker": "000001 C2 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 166.292,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 127049398,
            "exposureNAV": 1.32,
            "holdingType": "NAV1",
            "isin": "CNE100000HB8",
            "marketCapital": 250024.38,
            "marketCapitalInUsd": 36394.7101807913,
            "marketPrice": 165.91,
            "marketValueFC": 127049398,
            "marketValueIc": 127049398,
            "name": "JIANGSU YANGHE BREWERY -A",
            "percentOfNav": 1.316882,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 53,
            "sharesHeld": 765773,
            "ticker": "002304 C2 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.1227,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 127728723,
            "exposureNAV": 1.32,
            "holdingType": "NAV1",
            "isin": "CNE100000F46",
            "marketCapital": 251187.25,
            "marketCapitalInUsd": 36563.9829398236,
            "marketPrice": 5.99,
            "marketValueFC": 127728723,
            "marketValueIc": 127728723,
            "name": "CHINA STATE CONSTRUCTION -A",
            "percentOfNav": 1.323923,
            "sector": "Industrials",
            "securityType": "Equity",
            "seqno": 61,
            "sharesHeld": 21323660,
            "ticker": "601668 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 10.5857,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 126085432,
            "exposureNAV": 1.31,
            "holdingType": "NAV1",
            "isin": "CNE100000B24",
            "marketCapital": 311180.1,
            "marketCapitalInUsd": 45296.8208681475,
            "marketPrice": 12.03,
            "marketValueFC": 126085432,
            "marketValueIc": 126085432,
            "name": "ZIJIN MINING GROUP CO LTD-A",
            "percentOfNav": 1.30689,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 36,
            "sharesHeld": 10480917,
            "ticker": "601899 C1 EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 39.4461,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 124440000,
            "exposureNAV": 1.29,
            "holdingType": "NAV1",
            "isin": "CNE0000014W7",
            "marketCapital": 264601.01,
            "marketCapitalInUsd": 38516.5521558124,
            "marketPrice": 41.48,
            "marketValueFC": 124440000,
            "marketValueIc": 124440000,
            "name": "JIANGSU HENGRUI MEDICINE C-A 31 Dec 99",
            "percentOfNav": 1.289835,
            "sector": "Health Care",
            "securityType": "Fully Funded CFD",
            "seqno": 8,
            "sharesHeld": 3000000,
            "ticker": "600276 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 83.8854,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 124375010,
            "exposureNAV": 1.29,
            "holdingType": "NAV1",
            "isin": "CNE0000016J9",
            "marketCapital": 308354.52,
            "marketCapitalInUsd": 44885.5163177967,
            "marketPrice": 98.21,
            "marketValueFC": 124375010,
            "marketValueIc": 124375010,
            "name": "WANHUA CHEMICAL GROUP CO -A",
            "percentOfNav": 1.289161,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 29,
            "sharesHeld": 1266419,
            "ticker": "600309 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 20.8994,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 123171839,
            "exposureNAV": 1.28,
            "holdingType": "NAV1",
            "isin": "CNE000001DB6",
            "marketCapital": 279326.01,
            "marketCapitalInUsd": 40659.9915572506,
            "marketPrice": 19.86,
            "marketValueFC": 123171839,
            "marketValueIc": 123171839,
            "name": "CITIC SECURITIES CO-A",
            "percentOfNav": 1.27669,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 72,
            "sharesHeld": 6202006,
            "ticker": "600030 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 9.5355,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 104854427,
            "exposureNAV": 1.09,
            "holdingType": "NAV1",
            "isin": "CNE0000011B7",
            "marketCapital": 206052.26,
            "marketCapitalInUsd": 29993.9241317069,
            "marketPrice": 7.02,
            "marketValueFC": 104854427,
            "marketValueIc": 104854427,
            "name": "SHANGHAI PUDONG DEVEL BANK-A",
            "percentOfNav": 1.086828,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 57,
            "sharesHeld": 14936528,
            "ticker": "600000 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 22.886,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 103353388,
            "exposureNAV": 1.07,
            "holdingType": "NAV1",
            "isin": "CNE100000767",
            "marketCapital": 547954.91,
            "marketCapitalInUsd": 79762.8620920551,
            "marketPrice": 28.73,
            "marketValueFC": 103353388,
            "marketValueIc": 103353388,
            "name": "CHINA SHENHUA ENERGY CO-A",
            "percentOfNav": 1.07127,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 71,
            "sharesHeld": 3597403,
            "ticker": "601088 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 32.667,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 102202005,
            "exposureNAV": 1.06,
            "holdingType": "NAV1",
            "isin": "CNE100000TP3",
            "marketCapital": 201581.66,
            "marketCapitalInUsd": 29343.1628286122,
            "marketPrice": 28.33,
            "marketValueFC": 102202005,
            "marketValueIc": 102202005,
            "name": "LUXSHARE PRECISION INDUSTR-A",
            "percentOfNav": 1.059336,
            "sector": "Technology",
            "securityType": "Equity",
            "seqno": 51,
            "sharesHeld": 3607554,
            "ticker": "002475 C2 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.3235,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 101008469,
            "exposureNAV": 1.05,
            "holdingType": "NAV1",
            "isin": "CNE1000000S2",
            "marketCapital": 343783.29,
            "marketCapitalInUsd": 50042.692654808,
            "marketPrice": 5.06,
            "marketValueFC": 101008469,
            "marketValueIc": 101008469,
            "name": "BANK OF COMMUNICATIONS CO-A",
            "percentOfNav": 1.046965,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 45,
            "sharesHeld": 19962148,
            "ticker": "601328 C1 EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 49.4251,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 100800000,
            "exposureNAV": 1.04,
            "holdingType": "NAV1",
            "isin": "CNE100001RQ3",
            "marketCapital": 275803.27,
            "marketCapitalInUsd": 40147.205158811,
            "marketPrice": 50.4,
            "marketValueFC": 100800000,
            "marketValueIc": 100800000,
            "name": "MUYUAN FOODSTUFF CO LTD-A",
            "percentOfNav": 1.044804,
            "sector": "Consumer Staples",
            "securityType": "Fully Funded CFD",
            "seqno": 7,
            "sharesHeld": 2000000,
            "ticker": "002714 C2 EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 43.1843,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 96324000,
            "exposureNAV": 1,
            "holdingType": "NAV1",
            "isin": "CNE100001FR6",
            "marketCapital": 317513.63,
            "marketCapitalInUsd": 46218.7589158345,
            "marketPrice": 41.88,
            "marketValueFC": 96324000,
            "marketValueIc": 96324000,
            "name": "LONGI GREEN ENERGY TECHNOL-A",
            "percentOfNav": 0.998409,
            "sector": "Energy",
            "securityType": "Fully Funded CFD",
            "seqno": 4,
            "sharesHeld": 2300000,
            "ticker": "601012 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 35.9189,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 96356024,
            "exposureNAV": 1,
            "holdingType": "NAV1",
            "isin": "CNE000000JP5",
            "marketCapital": 189024.06,
            "marketCapitalInUsd": 27515.2202393083,
            "marketPrice": 29.54,
            "marketValueFC": 96356024,
            "marketValueIc": 96356024,
            "name": "INNER MONGOLIA YILI INDUS-A",
            "percentOfNav": 0.998741,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 58,
            "sharesHeld": 3261883,
            "ticker": "600887 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 4.6911,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 95792225,
            "exposureNAV": 0.99,
            "holdingType": "NAV1",
            "isin": "CNE0000018G1",
            "marketCapital": 625834.47,
            "marketCapitalInUsd": 91099.3726163789,
            "marketPrice": 5.52,
            "marketValueFC": 95792225,
            "marketValueIc": 95792225,
            "name": "CHINA PETROLEUM \u0026 CHEMICAL-A",
            "percentOfNav": 0.992898,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 13,
            "sharesHeld": 17353664,
            "ticker": "600028 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 34.4474,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 93074881,
            "exposureNAV": 0.96,
            "holdingType": "NAV1",
            "isin": "CNE1000005P7",
            "marketCapital": 182523.25,
            "marketCapitalInUsd": 26568.9321377624,
            "marketPrice": 27.64,
            "marketValueFC": 93074881,
            "marketValueIc": 93074881,
            "name": "BANK OF NINGBO CO LTD -A",
            "percentOfNav": 0.964732,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 52,
            "sharesHeld": 3367398,
            "ticker": "002142 C2 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 172.7367,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 92598652,
            "exposureNAV": 0.96,
            "holdingType": "NAV1",
            "isin": "CNE000000VQ8",
            "marketCapital": 758000.41,
            "marketCapitalInUsd": 110338.0607877959,
            "marketPrice": 195.28,
            "marketValueFC": 92598652,
            "marketValueIc": 92598652,
            "name": "WULIANGYE YIBIN CO LTD-A",
            "percentOfNav": 0.959796,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 111,
            "sharesHeld": 474184,
            "ticker": "000858 CS EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 25.3549,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 91325227,
            "exposureNAV": 0.95,
            "holdingType": "NAV1",
            "isin": "CNE1000008M8",
            "marketCapital": 230271.16,
            "marketCapitalInUsd": 33519.3397187691,
            "marketPrice": 26.23,
            "marketValueFC": 91325227,
            "marketValueIc": 91325227,
            "name": "CHINA PACIFIC INSURANCE GR-A",
            "percentOfNav": 0.946597,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 69,
            "sharesHeld": 3481709,
            "ticker": "601601 C1 EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 22.6041,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 91862000,
            "exposureNAV": 0.95,
            "holdingType": "NAV1",
            "isin": "CNE100000MD4",
            "marketCapital": 263886.83,
            "marketCapitalInUsd": 38412.5927974614,
            "marketPrice": 19.97,
            "marketValueFC": 91862000,
            "marketValueIc": 91862000,
            "name": "EAST MONEY INFORMATION CO-A",
            "percentOfNav": 0.95216,
            "sector": "Financials",
            "securityType": "Fully Funded CFD",
            "seqno": 10,
            "sharesHeld": 4600000,
            "ticker": "300059 C2 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 53.5582,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 89374780,
            "exposureNAV": 0.93,
            "holdingType": "NAV1",
            "isin": "CNE000001GS3",
            "marketCapital": 176026.09,
            "marketCapitalInUsd": 25623.1753471717,
            "marketPrice": 39.1,
            "marketValueFC": 89374780,
            "marketValueIc": 89374780,
            "name": "TONGWEI CO LTD-A",
            "percentOfNav": 0.92638,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 32,
            "sharesHeld": 2285800,
            "ticker": "600438 C1 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 45.7392,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 85576405,
            "exposureNAV": 0.89,
            "holdingType": "NAV1",
            "isin": "CNE000001B33",
            "marketCapital": 871913.56,
            "marketCapitalInUsd": 126919.7880578765,
            "marketPrice": 34.58,
            "marketValueFC": 85576405,
            "marketValueIc": 85576405,
            "name": "CHINA MERCHANTS BANK-A",
            "percentOfNav": 0.887009,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 101,
            "sharesHeld": 2474737,
            "ticker": "600036 CG EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 22.4519,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 77943285,
            "exposureNAV": 0.81,
            "holdingType": "NAV1",
            "isin": "CNE0000000T2",
            "marketCapital": 176947.7,
            "marketCapitalInUsd": 25757.3291798888,
            "marketPrice": 15.75,
            "marketValueFC": 77943285,
            "marketValueIc": 77943285,
            "name": "CHINA VANKE CO LTD -A",
            "percentOfNav": 0.807891,
            "sector": "Real Estate",
            "securityType": "Equity",
            "seqno": 40,
            "sharesHeld": 4948780,
            "ticker": "000002 C2 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 26.5199,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 75568854,
            "exposureNAV": 0.78,
            "holdingType": "NAV1",
            "isin": "CNE000000CG9",
            "marketCapital": 215174.21,
            "marketCapitalInUsd": 31321.7575475269,
            "marketPrice": 23.58,
            "marketValueFC": 75568854,
            "marketValueIc": 75568854,
            "name": "QINGDAO HAIER CO LTD-A",
            "percentOfNav": 0.78328,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 55,
            "sharesHeld": 3204786,
            "ticker": "600690 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 10.5219,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 74636580,
            "exposureNAV": 0.77,
            "holdingType": "NAV1",
            "isin": "CNE1000031P3",
            "marketCapital": 233548.81,
            "marketCapitalInUsd": 33996.4496783021,
            "marketPrice": 11.76,
            "marketValueFC": 74636580,
            "marketValueIc": 74636580,
            "name": "FOXCONN INDUSTRIAL INTERNE-A",
            "percentOfNav": 0.773617,
            "sector": "Technology",
            "securityType": "Equity",
            "seqno": 26,
            "sharesHeld": 6346648,
            "ticker": "601138 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 22.3047,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 72536051,
            "exposureNAV": 0.75,
            "holdingType": "NAV1",
            "isin": "CNE100001T64",
            "marketCapital": 187986.05,
            "marketCapitalInUsd": 27364.122681883,
            "marketPrice": 19.39,
            "marketValueFC": 72536051,
            "marketValueIc": 72536051,
            "name": "SHAANXI COAL INDUSTRY CO L-A",
            "percentOfNav": 0.751845,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 33,
            "sharesHeld": 3740900,
            "ticker": "601225 C1 EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 91,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 71118000,
            "exposureNAV": 0.74,
            "holdingType": "NAV1",
            "isin": "CNE1000031K4",
            "marketCapital": 229865.98,
            "marketCapitalInUsd": 33460.3598358031,
            "marketPrice": 79.02,
            "marketValueFC": 71118000,
            "marketValueIc": 71118000,
            "name": "WUXI APPTEC CO LTD-A",
            "percentOfNav": 0.737146,
            "sector": "Health Care",
            "securityType": "Fully Funded CFD",
            "seqno": 3,
            "sharesHeld": 900000,
            "ticker": "603259 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.3497,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 70341255,
            "exposureNAV": 0.73,
            "holdingType": "NAV1",
            "isin": "CNE1000007Q1",
            "marketCapital": 978569.19,
            "marketCapitalInUsd": 142445.0770036973,
            "marketPrice": 5.55,
            "marketValueFC": 70341255,
            "marketValueIc": 70341255,
            "name": "PETROCHINA CO LTD-A",
            "percentOfNav": 0.729095,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 68,
            "sharesHeld": 12674100,
            "ticker": "601857 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 17.8401,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 69173152,
            "exposureNAV": 0.72,
            "holdingType": "NAV1",
            "isin": "CNE000000TY6",
            "marketCapital": 164152.63,
            "marketCapitalInUsd": 23894.8193542752,
            "marketPrice": 14.05,
            "marketValueFC": 69173152,
            "marketValueIc": 69173152,
            "name": "SAIC MOTOR CORP LTD-A",
            "percentOfNav": 0.716988,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 15,
            "sharesHeld": 4923356,
            "ticker": "600104 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 3.3659,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 68412429,
            "exposureNAV": 0.71,
            "holdingType": "NAV1",
            "isin": "CNE000001N05",
            "marketCapital": 915900.07,
            "marketCapitalInUsd": 133322.6687822062,
            "marketPrice": 3.33,
            "marketValueFC": 68412429,
            "marketValueIc": 68412429,
            "name": "BANK OF CHINA LTD-A",
            "percentOfNav": 0.709103,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 46,
            "sharesHeld": 20544273,
            "ticker": "601988 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 45.711,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 65051869,
            "exposureNAV": 0.67,
            "holdingType": "NAV1",
            "isin": "CNE100001FR6",
            "marketCapital": 317513.63,
            "marketCapitalInUsd": 46218.7589158345,
            "marketPrice": 41.88,
            "marketValueFC": 65051869,
            "marketValueIc": 65051869,
            "name": "LONGI GREEN ENERGY TECHNOL-A",
            "percentOfNav": 0.67427,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 35,
            "sharesHeld": 1553292,
            "ticker": "601012 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 78.5471,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 63313484,
            "exposureNAV": 0.66,
            "holdingType": "NAV1",
            "isin": "CNE100000GS4",
            "marketCapital": 134608.47,
            "marketCapitalInUsd": 19594.2341844013,
            "marketPrice": 65.8,
            "marketValueFC": 63313484,
            "marketValueIc": 63313484,
            "name": "EVE ENERGY CO LTD-A",
            "percentOfNav": 0.656252,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 28,
            "sharesHeld": 962211,
            "ticker": "300014 C2 EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 198.58,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 62489600,
            "exposureNAV": 0.65,
            "holdingType": "NAV1",
            "isin": "CNE000000VQ8",
            "marketCapital": 758000.41,
            "marketCapitalInUsd": 110338.0607877959,
            "marketPrice": 195.28,
            "marketValueFC": 62489600,
            "marketValueIc": 62489600,
            "name": "WULIANGYE YIBIN CO LTD-A",
            "percentOfNav": 0.647712,
            "sector": "Consumer Staples",
            "securityType": "Fully Funded CFD",
            "seqno": 1,
            "sharesHeld": 320000,
            "ticker": "000858 C2 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 51.6676,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 60114577,
            "exposureNAV": 0.62,
            "holdingType": "NAV1",
            "isin": "CNE000001R84",
            "marketCapital": 823458.41,
            "marketCapitalInUsd": 119866.4313371568,
            "marketPrice": 45.46,
            "marketValueFC": 60114577,
            "marketValueIc": 60114577,
            "name": "PING AN INSURANCE GROUP CO-A",
            "percentOfNav": 0.623095,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 110,
            "sharesHeld": 1322362,
            "ticker": "601318 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 22.3029,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 58490320,
            "exposureNAV": 0.61,
            "holdingType": "NAV1",
            "isin": "CNE000001G87",
            "marketCapital": 498595.88,
            "marketCapitalInUsd": 72577.9323997787,
            "marketPrice": 21.07,
            "marketValueFC": 58490320,
            "marketValueIc": 58490320,
            "name": "CHINA YANGTZE POWER CO LTD-A",
            "percentOfNav": 0.606259,
            "sector": "Utilities",
            "securityType": "Equity",
            "seqno": 98,
            "sharesHeld": 2776000,
            "ticker": "600900 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 513.6927,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 57968718,
            "exposureNAV": 0.6,
            "holdingType": "NAV1",
            "isin": "CNE100003662",
            "marketCapital": 945814.9,
            "marketCapitalInUsd": 137677.2103991383,
            "marketPrice": 387.23,
            "marketValueFC": 57968718,
            "marketValueIc": 57968718,
            "name": "CONTEMPORARY AMPEREX TECHN-A",
            "percentOfNav": 0.600853,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 30,
            "sharesHeld": 149701,
            "ticker": "300750 CS EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 250.9513,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 53150530,
            "exposureNAV": 0.55,
            "holdingType": "NAV1",
            "isin": "CNE100001526",
            "marketCapital": 623581.52,
            "marketCapitalInUsd": 90771.4227488428,
            "marketPrice": 239.98,
            "marketValueFC": 53150530,
            "marketValueIc": 53150530,
            "name": "BYD CO LTD -A",
            "percentOfNav": 0.550911,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 85,
            "sharesHeld": 221479,
            "ticker": "002594 CS EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.1346,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 50597378,
            "exposureNAV": 0.52,
            "holdingType": "NAV1",
            "isin": "CNE100003PZ4",
            "marketCapital": 427303.22,
            "marketCapitalInUsd": 62200.2416373111,
            "marketPrice": 4.73,
            "marketValueFC": 50597378,
            "marketValueIc": 50597378,
            "name": "POSTAL SAVINGS BANK OF CHI-A",
            "percentOfNav": 0.524448,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 19,
            "sharesHeld": 10697120,
            "ticker": "601658 C1 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 315.4929,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 45594173,
            "exposureNAV": 0.47,
            "holdingType": "NAV1",
            "isin": "CNE100003G67",
            "marketCapital": 374317.03,
            "marketCapitalInUsd": 54487.3256863373,
            "marketPrice": 308.73,
            "marketValueFC": 45594173,
            "marketValueIc": 45594173,
            "name": "SHENZHEN MINDRAY BIO-MEDIC-A",
            "percentOfNav": 0.472589,
            "sector": "Health Care",
            "securityType": "Equity",
            "seqno": 25,
            "sharesHeld": 147683,
            "ticker": "300760 CS EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 199.9524,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 44360058,
            "exposureNAV": 0.46,
            "holdingType": "NAV1",
            "isin": "CNE100000G29",
            "marketCapital": 383432.48,
            "marketCapitalInUsd": 55814.2129319631,
            "marketPrice": 186.35,
            "marketValueFC": 44360058,
            "marketValueIc": 44360058,
            "name": "CHINA INTERNATIONAL TRAVEL-A",
            "percentOfNav": 0.459797,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 67,
            "sharesHeld": 238047,
            "ticker": "601888 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 221.3479,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 43169750,
            "exposureNAV": 0.45,
            "holdingType": "NAV1",
            "isin": "CNE000000GF2",
            "marketCapital": 354013.06,
            "marketCapitalInUsd": 51531.7854959387,
            "marketPrice": 240.5,
            "marketValueFC": 43169750,
            "marketValueIc": 43169750,
            "name": "LUZHOU LAOJIAO CO LTD-A",
            "percentOfNav": 0.447459,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 74,
            "sharesHeld": 179500,
            "ticker": "000568 CS EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 21.032,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 42278187,
            "exposureNAV": 0.44,
            "holdingType": "NAV1",
            "isin": "CNE100000MD4",
            "marketCapital": 263886.83,
            "marketCapitalInUsd": 38412.5927974614,
            "marketPrice": 19.97,
            "marketValueFC": 42278187,
            "marketValueIc": 42278187,
            "name": "EAST MONEY INFORMATION CO-A",
            "percentOfNav": 0.438218,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 50,
            "sharesHeld": 2117085,
            "ticker": "300059 C2 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 21.4476,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 42852988,
            "exposureNAV": 0.44,
            "holdingType": "NAV1",
            "isin": "CNE000001G87",
            "marketCapital": 498595.88,
            "marketCapitalInUsd": 72577.9323997787,
            "marketPrice": 21.07,
            "marketValueFC": 42852988,
            "marketValueIc": 42852988,
            "name": "CHINA YANGTZE POWER CO LTD-A",
            "percentOfNav": 0.444176,
            "sector": "Utilities",
            "securityType": "Equity",
            "seqno": 56,
            "sharesHeld": 2033839,
            "ticker": "600900 C1 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 18.2772,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 42039111,
            "exposureNAV": 0.44,
            "holdingType": "NAV1",
            "isin": "CNE000001QZ7",
            "marketCapital": 342567.57,
            "marketCapitalInUsd": 49865.7268042738,
            "marketPrice": 16.49,
            "marketValueFC": 42039111,
            "marketValueIc": 42039111,
            "name": "INDUSTRIAL BANK CO LTD -A",
            "percentOfNav": 0.43574,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 96,
            "sharesHeld": 2549370,
            "ticker": "601166 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 4.3385,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 41568043,
            "exposureNAV": 0.43,
            "holdingType": "NAV1",
            "isin": "CNE000001P37",
            "marketCapital": 1502462.3,
            "marketCapitalInUsd": 218705.3917144604,
            "marketPrice": 4.41,
            "marketValueFC": 41568043,
            "marketValueIc": 41568043,
            "name": "IND \u0026 COMM BK OF CHINA-A",
            "percentOfNav": 0.430858,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 93,
            "sharesHeld": 9425860,
            "ticker": "601398 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 284.7792,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 40483038,
            "exposureNAV": 0.42,
            "holdingType": "NAV1",
            "isin": "CNE000000DH5",
            "marketCapital": 331981.98,
            "marketCapitalInUsd": 48324.839151067,
            "marketPrice": 272.1,
            "marketValueFC": 40483038,
            "marketValueIc": 40483038,
            "name": "SHANXI XINGHUACUN FEN WINE-A",
            "percentOfNav": 0.419611,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 79,
            "sharesHeld": 148780,
            "ticker": "600809 CG EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 0,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 39923000,
            "exposureNAV": 0.41,
            "holdingType": "NAV1",
            "isin": "CNE100000601",
            "marketCapital": 161820.76,
            "marketCapitalInUsd": 23555.3815249352,
            "marketPrice": 10.79,
            "marketValueFC": 39923000,
            "marketValueIc": 39923000,
            "name": "COSCO SHIPPING HOLDINGS CO-A",
            "percentOfNav": 0.413807,
            "sector": "Industrials",
            "securityType": "Fully Funded CFD",
            "seqno": 5,
            "sharesHeld": 3700000,
            "ticker": "601919 C1 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 80.9502,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 38129512,
            "exposureNAV": 0.4,
            "holdingType": "NAV1",
            "isin": "CNE100001SL2",
            "marketCapital": 351522.63,
            "marketCapitalInUsd": 51169.2669364465,
            "marketPrice": 75.86,
            "marketValueFC": 38129512,
            "marketValueIc": 38129512,
            "name": "FOSHAN HAITIAN FLAVOURING -A",
            "percentOfNav": 0.395217,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 82,
            "sharesHeld": 502630,
            "ticker": "603288 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 55.3569,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 38991620,
            "exposureNAV": 0.4,
            "holdingType": "NAV1",
            "isin": "CNE100001FR6",
            "marketCapital": 317513.63,
            "marketCapitalInUsd": 46218.7589158345,
            "marketPrice": 41.88,
            "marketValueFC": 38991620,
            "marketValueIc": 38991620,
            "name": "LONGI GREEN ENERGY TECHNOL-A",
            "percentOfNav": 0.404153,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 78,
            "sharesHeld": 931032,
            "ticker": "601012 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 93.5399,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 37889418,
            "exposureNAV": 0.39,
            "holdingType": "NAV1",
            "isin": "CNE0000016J9",
            "marketCapital": 308354.52,
            "marketCapitalInUsd": 44885.5163177967,
            "marketPrice": 98.21,
            "marketValueFC": 37889418,
            "marketValueIc": 37889418,
            "name": "WANHUA CHEMICAL GROUP CO -A",
            "percentOfNav": 0.392728,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 75,
            "sharesHeld": 385800,
            "ticker": "600309 CG EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 48.3831,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 35518241,
            "exposureNAV": 0.37,
            "holdingType": "NAV1",
            "isin": "CNE100001RQ3",
            "marketCapital": 275803.27,
            "marketCapitalInUsd": 40147.205158811,
            "marketPrice": 50.4,
            "marketValueFC": 35518241,
            "marketValueIc": 35518241,
            "name": "MUYUAN FOODSTUFF CO LTD-A",
            "percentOfNav": 0.368151,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 34,
            "sharesHeld": 704727,
            "ticker": "002714 C2 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 3.1393,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 35028712,
            "exposureNAV": 0.36,
            "holdingType": "NAV1",
            "isin": "CNE100000RJ0",
            "marketCapital": 1035835.84,
            "marketCapitalInUsd": 150781.0765961163,
            "marketPrice": 3.01,
            "marketValueFC": 35028712,
            "marketValueIc": 35028712,
            "name": "AGRICULTURAL BANK OF CHINA-A",
            "percentOfNav": 0.363077,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 95,
            "sharesHeld": 11637446,
            "ticker": "601288 CG EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 46.0823,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 34352000,
            "exposureNAV": 0.36,
            "holdingType": "NAV1",
            "isin": "CNE1000048D3",
            "marketCapital": 232803.14,
            "marketCapitalInUsd": 33887.9064892719,
            "marketPrice": 42.94,
            "marketValueFC": 34352000,
            "marketValueIc": 34352000,
            "name": "YIHAI KERRY ARAWANA HOLDIN-A",
            "percentOfNav": 0.356062,
            "sector": "Consumer Staples",
            "securityType": "Fully Funded CFD",
            "seqno": 9,
            "sharesHeld": 800000,
            "ticker": "300999 C2 EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 90.6941,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 32409300,
            "exposureNAV": 0.34,
            "holdingType": "NAV1",
            "isin": "CNE0000016J9",
            "marketCapital": 308354.52,
            "marketCapitalInUsd": 44885.5163177967,
            "marketPrice": 98.21,
            "marketValueFC": 32409300,
            "marketValueIc": 32409300,
            "name": "WANHUA CHEMICAL GROUP CO -A",
            "percentOfNav": 0.335926,
            "sector": "Materials",
            "securityType": "Fully Funded CFD",
            "seqno": 2,
            "sharesHeld": 330000,
            "ticker": "600309 C1 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 58.4924,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 33004238,
            "exposureNAV": 0.34,
            "holdingType": "NAV1",
            "isin": "CNE100001RQ3",
            "marketCapital": 275803.27,
            "marketCapitalInUsd": 40147.205158811,
            "marketPrice": 50.4,
            "marketValueFC": 33004238,
            "marketValueIc": 33004238,
            "name": "MUYUAN FOODSTUFF CO LTD-A",
            "percentOfNav": 0.342093,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 80,
            "sharesHeld": 654846,
            "ticker": "002714 CS EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 22.8632,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 32440806,
            "exposureNAV": 0.34,
            "holdingType": "NAV1",
            "isin": "CNE100000MD4",
            "marketCapital": 263886.83,
            "marketCapitalInUsd": 38412.5927974614,
            "marketPrice": 19.97,
            "marketValueFC": 32440806,
            "marketValueIc": 32440806,
            "name": "EAST MONEY INFORMATION CO-A",
            "percentOfNav": 0.336253,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 81,
            "sharesHeld": 1624477,
            "ticker": "300059 CS EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 36.3966,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 32539235,
            "exposureNAV": 0.34,
            "holdingType": "NAV1",
            "isin": "CNE0000014W7",
            "marketCapital": 264601.01,
            "marketCapitalInUsd": 38516.5521558124,
            "marketPrice": 41.48,
            "marketValueFC": 32539235,
            "marketValueIc": 32539235,
            "name": "JIANGSU HENGRUI MEDICINE C-A",
            "percentOfNav": 0.337273,
            "sector": "Health Care",
            "securityType": "Equity",
            "seqno": 77,
            "sharesHeld": 784456,
            "ticker": "600276 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 51.1543,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 32112610,
            "exposureNAV": 0.33,
            "holdingType": "NAV1",
            "isin": "CNE100000L63",
            "marketCapital": 260914.29,
            "marketCapitalInUsd": 37979.8960668433,
            "marketPrice": 53.3,
            "marketValueFC": 32112610,
            "marketValueIc": 32112610,
            "name": "S F HOLDING CO LTD-A",
            "percentOfNav": 0.332851,
            "sector": "Industrials",
            "securityType": "Equity",
            "seqno": 43,
            "sharesHeld": 602488,
            "ticker": "002352 CS EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 78.2109,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 32097845,
            "exposureNAV": 0.33,
            "holdingType": "NAV1",
            "isin": "CNE1000031K4",
            "marketCapital": 229865.98,
            "marketCapitalInUsd": 33460.3598358031,
            "marketPrice": 79.02,
            "marketValueFC": 32097845,
            "marketValueIc": 32097845,
            "name": "WUXI APPTEC CO LTD-A",
            "percentOfNav": 0.332698,
            "sector": "Health Care",
            "securityType": "Equity",
            "seqno": 22,
            "sharesHeld": 406199,
            "ticker": "603259 C1 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.1361,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 30882044,
            "exposureNAV": 0.32,
            "holdingType": "NAV1",
            "isin": "CNE100000F46",
            "marketCapital": 251187.25,
            "marketCapitalInUsd": 36563.9829398236,
            "marketPrice": 5.99,
            "marketValueFC": 30882044,
            "marketValueIc": 30882044,
            "name": "CHINA STATE CONSTRUCTION -A",
            "percentOfNav": 0.320096,
            "sector": "Industrials",
            "securityType": "Equity",
            "seqno": 90,
            "sharesHeld": 5155600,
            "ticker": "601668 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 167.2274,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 30771991,
            "exposureNAV": 0.32,
            "holdingType": "NAV1",
            "isin": "CNE100000HB8",
            "marketCapital": 250024.38,
            "marketCapitalInUsd": 36394.7101807913,
            "marketPrice": 165.91,
            "marketValueFC": 30771991,
            "marketValueIc": 30771991,
            "name": "JIANGSU YANGHE BREWERY -A",
            "percentOfNav": 0.318955,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 105,
            "sharesHeld": 185474,
            "ticker": "002304 CS EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 16.8368,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 30546585,
            "exposureNAV": 0.32,
            "holdingType": "NAV1",
            "isin": "CNE000000040",
            "marketCapital": 249754.17,
            "marketCapitalInUsd": 36355.3771579959,
            "marketPrice": 12.87,
            "marketValueFC": 30546585,
            "marketValueIc": 30546585,
            "name": "PING AN BANK CO LTD-A",
            "percentOfNav": 0.316619,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 107,
            "sharesHeld": 2373472,
            "ticker": "000001 CS EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 23.2922,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 29776058,
            "exposureNAV": 0.31,
            "holdingType": "NAV1",
            "isin": "CNE000001DB6",
            "marketCapital": 279326.01,
            "marketCapitalInUsd": 40659.9915572506,
            "marketPrice": 19.86,
            "marketValueFC": 29776058,
            "marketValueIc": 29776058,
            "name": "CITIC SECURITIES CO-A",
            "percentOfNav": 0.308632,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 102,
            "sharesHeld": 1499298,
            "ticker": "600030 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 8.8679,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 30285525,
            "exposureNAV": 0.31,
            "holdingType": "NAV1",
            "isin": "CNE100000B24",
            "marketCapital": 311180.1,
            "marketCapitalInUsd": 45296.8208681475,
            "marketPrice": 12.03,
            "marketValueFC": 30285525,
            "marketValueIc": 30285525,
            "name": "ZIJIN MINING GROUP CO LTD-A",
            "percentOfNav": 0.313913,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 66,
            "sharesHeld": 2517500,
            "ticker": "601899 CG EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 13.3571,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 29523080,
            "exposureNAV": 0.31,
            "holdingType": "NAV1",
            "isin": "CNE100000601",
            "marketCapital": 161820.76,
            "marketCapitalInUsd": 23555.3815249352,
            "marketPrice": 10.79,
            "marketValueFC": 29523080,
            "marketValueIc": 29523080,
            "name": "COSCO SHIPPING HOLDINGS CO-A",
            "percentOfNav": 0.30601,
            "sector": "Industrials",
            "securityType": "Equity",
            "seqno": 37,
            "sharesHeld": 2736152,
            "ticker": "601919 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 6.1238,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 28404366,
            "exposureNAV": 0.29,
            "holdingType": "NAV1",
            "isin": "CNE100000742",
            "marketCapital": 1092845.85,
            "marketCapitalInUsd": 159079.7184779761,
            "marketPrice": 5.83,
            "marketValueFC": 28404366,
            "marketValueIc": 28404366,
            "name": "CHINA CONSTRUCTION BANK-A",
            "percentOfNav": 0.294415,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 16,
            "sharesHeld": 4872104,
            "ticker": "601939 C1 EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 28.7673,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 27270000,
            "exposureNAV": 0.28,
            "holdingType": "NAV1",
            "isin": "CNE1000018V8",
            "marketCapital": 184883.75,
            "marketCapitalInUsd": 26912.5374828962,
            "marketPrice": 27.27,
            "marketValueFC": 27270000,
            "marketValueIc": 27270000,
            "name": "GREAT WALL MOTOR CO LTD-A",
            "percentOfNav": 0.282657,
            "sector": "Consumer Discretionary",
            "securityType": "Fully Funded CFD",
            "seqno": 6,
            "sharesHeld": 1000000,
            "ticker": "601633 C1 EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.043,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 25255618,
            "exposureNAV": 0.26,
            "holdingType": "NAV1",
            "isin": "CNE1000000R4",
            "marketCapital": 220895.27,
            "marketCapitalInUsd": 32154.5416169321,
            "marketPrice": 5.1,
            "marketValueFC": 25255618,
            "marketValueIc": 25255618,
            "name": "CHINA CITIC BANK CORP LTD-A",
            "percentOfNav": 0.261777,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 44,
            "sharesHeld": 4952082,
            "ticker": "601998 C1 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 83.944,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 24774350,
            "exposureNAV": 0.26,
            "holdingType": "NAV1",
            "isin": "CNE1000031K4",
            "marketCapital": 229865.98,
            "marketCapitalInUsd": 33460.3598358031,
            "marketPrice": 79.02,
            "marketValueFC": 24774350,
            "marketValueIc": 24774350,
            "name": "WUXI APPTEC CO LTD-A",
            "percentOfNav": 0.256789,
            "sector": "Health Care",
            "securityType": "Equity",
            "seqno": 27,
            "sharesHeld": 313520,
            "ticker": "603259 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 29.5653,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 24945627,
            "exposureNAV": 0.26,
            "holdingType": "NAV1",
            "isin": "CNE100000767",
            "marketCapital": 547954.91,
            "marketCapitalInUsd": 79762.8620920551,
            "marketPrice": 28.73,
            "marketValueFC": 24945627,
            "marketValueIc": 24945627,
            "name": "CHINA SHENHUA ENERGY CO-A",
            "percentOfNav": 0.258564,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 97,
            "sharesHeld": 868278,
            "ticker": "601088 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 9.2442,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 25198185,
            "exposureNAV": 0.26,
            "holdingType": "NAV1",
            "isin": "CNE0000011B7",
            "marketCapital": 206052.26,
            "marketCapitalInUsd": 29993.9241317069,
            "marketPrice": 7.02,
            "marketValueFC": 25198185,
            "marketValueIc": 25198185,
            "name": "SHANGHAI PUDONG DEVEL BANK-A",
            "percentOfNav": 0.261182,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 104,
            "sharesHeld": 3589485,
            "ticker": "600000 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 4.71,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 24404977,
            "exposureNAV": 0.25,
            "holdingType": "NAV1",
            "isin": "CNE1000000S2",
            "marketCapital": 343783.29,
            "marketCapitalInUsd": 50042.692654808,
            "marketPrice": 5.06,
            "marketValueFC": 24404977,
            "marketValueIc": 24404977,
            "name": "BANK OF COMMUNICATIONS CO-A",
            "percentOfNav": 0.25296,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 94,
            "sharesHeld": 4823118,
            "ticker": "601328 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 40.85,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 24532137,
            "exposureNAV": 0.25,
            "holdingType": "NAV1",
            "isin": "CNE100000TP3",
            "marketCapital": 201581.66,
            "marketCapitalInUsd": 29343.1628286122,
            "marketPrice": 28.33,
            "marketValueFC": 24532137,
            "marketValueIc": 24532137,
            "name": "LUXSHARE PRECISION INDUSTR-A",
            "percentOfNav": 0.254278,
            "sector": "Technology",
            "securityType": "Equity",
            "seqno": 83,
            "sharesHeld": 865942,
            "ticker": "002475 CS EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 35.4586,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 23248866,
            "exposureNAV": 0.24,
            "holdingType": "NAV1",
            "isin": "CNE000000JP5",
            "marketCapital": 189024.06,
            "marketCapitalInUsd": 27515.2202393083,
            "marketPrice": 29.54,
            "marketValueFC": 23248866,
            "marketValueIc": 23248866,
            "name": "INNER MONGOLIA YILI INDUS-A",
            "percentOfNav": 0.240977,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 84,
            "sharesHeld": 787030,
            "ticker": "600887 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 20.3862,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 22990723,
            "exposureNAV": 0.24,
            "holdingType": "NAV1",
            "isin": "CNE100001T64",
            "marketCapital": 187986.05,
            "marketCapitalInUsd": 27364.122681883,
            "marketPrice": 19.39,
            "marketValueFC": 22990723,
            "marketValueIc": 22990723,
            "name": "SHAANXI COAL INDUSTRY CO L-A",
            "percentOfNav": 0.238302,
            "sector": "Materials",
            "securityType": "Equity",
            "seqno": 65,
            "sharesHeld": 1185700,
            "ticker": "601225 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 4.2177,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 23133061,
            "exposureNAV": 0.24,
            "holdingType": "NAV1",
            "isin": "CNE0000018G1",
            "marketCapital": 625834.47,
            "marketCapitalInUsd": 91099.3726163789,
            "marketPrice": 5.52,
            "marketValueFC": 23133061,
            "marketValueIc": 23133061,
            "name": "CHINA PETROLEUM \u0026 CHEMICAL-A",
            "percentOfNav": 0.239777,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 103,
            "sharesHeld": 4190772,
            "ticker": "600028 CG EQUITY"
          },
          {
            "accountID": "JPM",
            "assetClass": "EQTY",
            "assetGroup": "Swap",
            "averageCost": 19.73,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 23268000,
            "exposureNAV": 0.24,
            "holdingType": "NAV1",
            "isin": "CNE100001T64",
            "marketCapital": 187986.05,
            "marketCapitalInUsd": 27364.122681883,
            "marketPrice": 19.39,
            "marketValueFC": 23268000,
            "marketValueIc": 23268000,
            "name": "SHAANXI COAL INDUSTRY CO L-A",
            "percentOfNav": 0.241176,
            "sector": "Materials",
            "securityType": "Fully Funded CFD",
            "seqno": 0,
            "sharesHeld": 1200000,
            "ticker": "601225 C1 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 24.2767,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 21970248,
            "exposureNAV": 0.23,
            "holdingType": "NAV1",
            "isin": "CNE1000008M8",
            "marketCapital": 230271.16,
            "marketCapitalInUsd": 33519.3397187691,
            "marketPrice": 26.23,
            "marketValueFC": 21970248,
            "marketValueIc": 21970248,
            "name": "CHINA PACIFIC INSURANCE GR-A",
            "percentOfNav": 0.227724,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 92,
            "sharesHeld": 837600,
            "ticker": "601601 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 31.561,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 22449816,
            "exposureNAV": 0.23,
            "holdingType": "NAV1",
            "isin": "CNE1000005P7",
            "marketCapital": 182523.25,
            "marketCapitalInUsd": 26568.9321377624,
            "marketPrice": 27.64,
            "marketValueFC": 22449816,
            "marketValueIc": 22449816,
            "name": "BANK OF NINGBO CO LTD -A",
            "percentOfNav": 0.232695,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 73,
            "sharesHeld": 812222,
            "ticker": "002142 CS EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 52.567,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 21610570,
            "exposureNAV": 0.22,
            "holdingType": "NAV1",
            "isin": "CNE000001GS3",
            "marketCapital": 176026.09,
            "marketCapitalInUsd": 25623.1753471717,
            "marketPrice": 39.1,
            "marketValueFC": 21610570,
            "marketValueIc": 21610570,
            "name": "TONGWEI CO LTD-A",
            "percentOfNav": 0.223996,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 39,
            "sharesHeld": 552700,
            "ticker": "600438 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 22.7988,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 18823644,
            "exposureNAV": 0.2,
            "holdingType": "NAV1",
            "isin": "CNE0000000T2",
            "marketCapital": 176947.7,
            "marketCapitalInUsd": 25757.3291798888,
            "marketPrice": 15.75,
            "marketValueFC": 18823644,
            "marketValueIc": 18823644,
            "name": "CHINA VANKE CO LTD -A",
            "percentOfNav": 0.195109,
            "sector": "Real Estate",
            "securityType": "Equity",
            "seqno": 106,
            "sharesHeld": 1195152,
            "ticker": "000002 CS EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 9.9636,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 18013580,
            "exposureNAV": 0.19,
            "holdingType": "NAV1",
            "isin": "CNE1000031P3",
            "marketCapital": 233548.81,
            "marketCapitalInUsd": 33996.4496783021,
            "marketPrice": 11.76,
            "marketValueFC": 18013580,
            "marketValueIc": 18013580,
            "name": "FOXCONN INDUSTRIAL INTERNE-A",
            "percentOfNav": 0.186713,
            "sector": "Technology",
            "securityType": "Equity",
            "seqno": 31,
            "sharesHeld": 1531767,
            "ticker": "601138 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 26.703,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 18255636,
            "exposureNAV": 0.19,
            "holdingType": "NAV1",
            "isin": "CNE000000CG9",
            "marketCapital": 215174.21,
            "marketCapitalInUsd": 31321.7575475269,
            "marketPrice": 23.58,
            "marketValueFC": 18255636,
            "marketValueIc": 18255636,
            "name": "QINGDAO HAIER CO LTD-A",
            "percentOfNav": 0.189222,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 63,
            "sharesHeld": 774200,
            "ticker": "600690 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 13.9331,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 16777371,
            "exposureNAV": 0.17,
            "holdingType": "NAV1",
            "isin": "CNE100000601",
            "marketCapital": 161820.76,
            "marketCapitalInUsd": 23555.3815249352,
            "marketPrice": 10.79,
            "marketValueFC": 16777371,
            "marketValueIc": 16777371,
            "name": "CHINA COSCO HOLDINGS-A",
            "percentOfNav": 0.173899,
            "sector": "Industrials",
            "securityType": "Equity",
            "seqno": 64,
            "sharesHeld": 1554900,
            "ticker": "601919 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 5.2008,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 16875330,
            "exposureNAV": 0.17,
            "holdingType": "NAV1",
            "isin": "CNE1000007Q1",
            "marketCapital": 978569.19,
            "marketCapitalInUsd": 142445.0770036973,
            "marketPrice": 5.55,
            "marketValueFC": 16875330,
            "marketValueIc": 16875330,
            "name": "PETROCHINA CO LTD-A",
            "percentOfNav": 0.174915,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 89,
            "sharesHeld": 3040600,
            "ticker": "601857 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 3.0642,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 16359624,
            "exposureNAV": 0.17,
            "holdingType": "NAV1",
            "isin": "CNE000001N05",
            "marketCapital": 915900.07,
            "marketCapitalInUsd": 133322.6687822062,
            "marketPrice": 3.33,
            "marketValueFC": 16359624,
            "marketValueIc": 16359624,
            "name": "BANK OF CHINA LTD-A",
            "percentOfNav": 0.169569,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 87,
            "sharesHeld": 4912800,
            "ticker": "601988 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 15.1658,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 16598319,
            "exposureNAV": 0.17,
            "holdingType": "NAV1",
            "isin": "CNE000000TY6",
            "marketCapital": 164152.63,
            "marketCapitalInUsd": 23894.8193542752,
            "marketPrice": 14.05,
            "marketValueFC": 16598319,
            "marketValueIc": 16598319,
            "name": "SAIC MOTOR CORPORATION LTD-A",
            "percentOfNav": 0.172044,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 100,
            "sharesHeld": 1181375,
            "ticker": "600104 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 0,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 15313831,
            "exposureNAV": 0.16,
            "holdingType": "NAV1",
            "isin": "CNE100000GS4",
            "marketCapital": 134608.47,
            "marketCapitalInUsd": 19594.2341844013,
            "marketPrice": 65.8,
            "marketValueFC": 15313831,
            "marketValueIc": 15313831,
            "name": "EVE ENERGY CO LTD-A",
            "percentOfNav": 0.15873,
            "sector": "Energy",
            "securityType": "Equity",
            "seqno": 48,
            "sharesHeld": 232733,
            "ticker": "300014 CS EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 4.682,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 12203045,
            "exposureNAV": 0.13,
            "holdingType": "NAV1",
            "isin": "CNE100003PZ4",
            "marketCapital": 427303.22,
            "marketCapitalInUsd": 62200.2416373111,
            "marketPrice": 4.73,
            "marketValueFC": 12203045,
            "marketValueIc": 12203045,
            "name": "POSTAL SAVINGS BANK OF CHI-A",
            "percentOfNav": 0.126486,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 20,
            "sharesHeld": 2579925,
            "ticker": "601658 CG EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 40.9204,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 11145767,
            "exposureNAV": 0.12,
            "holdingType": "NAV1",
            "isin": "CNE1000018V8",
            "marketCapital": 184883.75,
            "marketCapitalInUsd": 26912.5374828962,
            "marketPrice": 27.27,
            "marketValueFC": 11145767,
            "marketValueIc": 11145767,
            "name": "GREAT WALL MOTOR CO LTD-A",
            "percentOfNav": 0.115527,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 21,
            "sharesHeld": 408719,
            "ticker": "601633 C1 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 47.6392,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 10168192,
            "exposureNAV": 0.11,
            "holdingType": "NAV1",
            "isin": "CNE1000048D3",
            "marketCapital": 232803.14,
            "marketCapitalInUsd": 33887.9064892719,
            "marketPrice": 42.94,
            "marketValueFC": 10168192,
            "marketValueIc": 10168192,
            "name": "YIHAI KERRY ARAWANA HOLDIN-A",
            "percentOfNav": 0.105394,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 17,
            "sharesHeld": 236800,
            "ticker": "300999 CS EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 38.7335,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 10052968,
            "exposureNAV": 0.1,
            "holdingType": "NAV1",
            "isin": "CNE0000014W7",
            "marketCapital": 264601.01,
            "marketCapitalInUsd": 38516.5521558124,
            "marketPrice": 41.48,
            "marketValueFC": 10052968,
            "marketValueIc": 10052968,
            "name": "JIANGSU HENGRUI MEDICINE C-A",
            "percentOfNav": 0.1042,
            "sector": "Health Care",
            "securityType": "Equity",
            "seqno": 14,
            "sharesHeld": 242357,
            "ticker": "600276 C1 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 44.3255,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 8881839,
            "exposureNAV": 0.09,
            "holdingType": "NAV1",
            "isin": "CNE1000018V8",
            "marketCapital": 184883.75,
            "marketCapitalInUsd": 26912.5374828962,
            "marketPrice": 27.27,
            "marketValueFC": 8881839,
            "marketValueIc": 8881839,
            "name": "GREAT WALL MOTOR CO LTD-A",
            "percentOfNav": 0.092061,
            "sector": "Consumer Discretionary",
            "securityType": "Equity",
            "seqno": 91,
            "sharesHeld": 325700,
            "ticker": "601633 CG EQUITY"
          },
          {
            "accountID": "HSBC_HK",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 55.9912,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 7973486,
            "exposureNAV": 0.08,
            "holdingType": "NAV1",
            "isin": "CNE1000048D3",
            "marketCapital": 232803.14,
            "marketCapitalInUsd": 33887.9064892719,
            "marketPrice": 42.94,
            "marketValueFC": 7973486,
            "marketValueIc": 7973486,
            "name": "YIHAI KERRY ARAWANA HOLDIN-A",
            "percentOfNav": 0.082646,
            "sector": "Consumer Staples",
            "securityType": "Equity",
            "seqno": 18,
            "sharesHeld": 185689,
            "ticker": "300999 C2 EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 6.0795,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 6833360,
            "exposureNAV": 0.07,
            "holdingType": "NAV1",
            "isin": "CNE100000742",
            "marketCapital": 1092845.85,
            "marketCapitalInUsd": 159079.7184779761,
            "marketPrice": 5.83,
            "marketValueFC": 6833360,
            "marketValueIc": 6833360,
            "name": "CHINA CONSTRUCTION BANK-A",
            "percentOfNav": 0.070829,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 88,
            "sharesHeld": 1172103,
            "ticker": "601939 CG EQUITY"
          },
          {
            "accountID": "HSBC_CN",
            "assetClass": "EQTY",
            "assetGroup": "Equity",
            "averageCost": 4.4655,
            "cds": 0,
            "creditRating": "",
            "currency": "CNY",
            "exposureFC": 6022590,
            "exposureNAV": 0.06,
            "holdingType": "NAV1",
            "isin": "CNE1000000R4",
            "marketCapital": 220895.27,
            "marketCapitalInUsd": 32154.5416169321,
            "marketPrice": 5.1,
            "marketValueFC": 6022590,
            "marketValueIc": 6022590,
            "name": "CHINA CITIC BANK CORP LTD-A",
            "percentOfNav": 0.062425,
            "sector": "Financials",
            "securityType": "Equity",
            "seqno": 86,
            "sharesHeld": 1180900,
            "ticker": "601998 CG EQUITY"
          }
        ]
      },
      "sectorWiseExposureList": [
        {
          "exposure": 13.76,
          "label": "Consumer Discretionary"
        },
        {
          "exposure": 31.18,
          "label": "Consumer Staples"
        },
        {
          "exposure": 6.17,
          "label": "Energy"
        },
        {
          "exposure": 26.51,
          "label": "Financials"
        },
        {
          "exposure": 5.51,
          "label": "Health Care"
        },
        {
          "exposure": 4.23,
          "label": "Industrials"
        },
        {
          "exposure": 6.2,
          "label": "Materials"
        },
        {
          "exposure": 1.01,
          "label": "Real Estate"
        },
        {
          "exposure": 2.27,
          "label": "Technology"
        },
        {
          "exposure": 3.14,
          "label": "Utilities"
        },
        {
          "exposure": 0.01999999999998181,
          "label": "Cash and Others"
        }
      ],
      "status": "success",
      "topTenHoldings": [
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 1493.1483856293,
          "cds": 0,
          "creditRating": "",
          "currency": "CNY",
          "exposureFC": 1399964712,
          "exposureNAV": 14.52,
          "holdingType": "NAV1",
          "isin": "CNE0000018R8",
          "marketCapital": 2218445.31,
          "marketCapitalInUsd": 322927.2045765525,
          "marketPrice": 1766,
          "marketValueFC": 1399964712,
          "marketValueIc": 1399964712,
          "name": "KWEICHOW MOUTAI CO LTD-A",
          "percentOfNav": 14.510797,
          "sector": "Consumer Staples",
          "securityType": "",
          "seqno": 99,
          "sharesHeld": 792732,
          "ticker": "600519 CG EQUITY"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 449.1147828773,
          "cds": 0,
          "creditRating": "",
          "currency": "CNY",
          "exposureFC": 596401578,
          "exposureNAV": 6.18,
          "holdingType": "NAV1",
          "isin": "CNE100003662",
          "marketCapital": 945814.9,
          "marketCapitalInUsd": 137677.2103991383,
          "marketPrice": 387.23,
          "marketValueFC": 596401578,
          "marketValueIc": 596401578,
          "name": "CONTEMPORARY AMPEREX TECHN-A",
          "percentOfNav": 6.181772,
          "sector": "Consumer Discretionary",
          "securityType": "",
          "seqno": 30,
          "sharesHeld": 1540174,
          "ticker": "300750 CS EQUITY"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 177.3981498607,
          "cds": 0,
          "creditRating": "",
          "currency": "CNY",
          "exposureFC": 478681663,
          "exposureNAV": 4.96,
          "holdingType": "NAV1",
          "isin": "CNE000000VQ8",
          "marketCapital": 758000.41,
          "marketCapitalInUsd": 110338.0607877959,
          "marketPrice": 195.28,
          "marketValueFC": 478681663,
          "marketValueIc": 478681663,
          "name": "WULIANGYE YIBIN CO LTD-A",
          "percentOfNav": 4.961591,
          "sector": "Consumer Staples",
          "securityType": "",
          "seqno": 111,
          "sharesHeld": 2451258,
          "ticker": "000858 CS EQUITY"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 37.4445206455,
          "cds": 0,
          "creditRating": "",
          "currency": "CNY",
          "exposureFC": 459836195,
          "exposureNAV": 4.77,
          "holdingType": "NAV1",
          "isin": "CNE000001B33",
          "marketCapital": 871913.56,
          "marketCapitalInUsd": 126919.7880578765,
          "marketPrice": 34.58,
          "marketValueFC": 459836195,
          "marketValueIc": 459836195,
          "name": "CHINA MERCHANTS BANK-A",
          "percentOfNav": 4.766255,
          "sector": "Financials",
          "securityType": "",
          "seqno": 101,
          "sharesHeld": 13297750,
          "ticker": "600036 CG EQUITY"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 50.6820796376,
          "cds": 0,
          "creditRating": "",
          "currency": "CNY",
          "exposureFC": 310958129,
          "exposureNAV": 3.22,
          "holdingType": "NAV1",
          "isin": "CNE000001R84",
          "marketCapital": 823458.41,
          "marketCapitalInUsd": 119866.4313371568,
          "marketPrice": 45.46,
          "marketValueFC": 310958129,
          "marketValueIc": 310958129,
          "name": "PING AN INSURANCE GROUP CO-A",
          "percentOfNav": 3.223118,
          "sector": "Financials",
          "securityType": "",
          "seqno": 110,
          "sharesHeld": 6840258,
          "ticker": "601318 CG EQUITY"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 21.238654259,
          "cds": 0,
          "creditRating": "",
          "currency": "CNY",
          "exposureFC": 302561808,
          "exposureNAV": 3.14,
          "holdingType": "NAV1",
          "isin": "CNE000001G87",
          "marketCapital": 498595.88,
          "marketCapitalInUsd": 72577.9323997787,
          "marketPrice": 21.07,
          "marketValueFC": 302561808,
          "marketValueIc": 302561808,
          "name": "CHINA YANGTZE POWER CO LTD-A",
          "percentOfNav": 3.136088,
          "sector": "Utilities",
          "securityType": "",
          "seqno": 98,
          "sharesHeld": 14359839,
          "ticker": "600900 CG EQUITY"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 230.940507061,
          "cds": 0,
          "creditRating": "",
          "currency": "CNY",
          "exposureFC": 274753342,
          "exposureNAV": 2.85,
          "holdingType": "NAV1",
          "isin": "CNE100001526",
          "marketCapital": 623581.52,
          "marketCapitalInUsd": 90771.4227488428,
          "marketPrice": 239.98,
          "marketValueFC": 274753342,
          "marketValueIc": 274753342,
          "name": "BYD CO LTD -A",
          "percentOfNav": 2.84785,
          "sector": "Consumer Discretionary",
          "securityType": "",
          "seqno": 85,
          "sharesHeld": 1144901,
          "ticker": "002594 CS EQUITY"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 295.4918903877,
          "cds": 0,
          "creditRating": "",
          "currency": "CNY",
          "exposureFC": 236318923,
          "exposureNAV": 2.45,
          "holdingType": "NAV1",
          "isin": "CNE100003G67",
          "marketCapital": 374317.03,
          "marketCapitalInUsd": 54487.3256863373,
          "marketPrice": 308.73,
          "marketValueFC": 236318923,
          "marketValueIc": 236318923,
          "name": "SHENZHEN MINDRAY BIO-MEDIC-A",
          "percentOfNav": 2.449473,
          "sector": "Health Care",
          "securityType": "",
          "seqno": 25,
          "sharesHeld": 765455,
          "ticker": "300760 CS EQUITY"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 158.0633032023,
          "cds": 0,
          "creditRating": "",
          "currency": "CNY",
          "exposureFC": 229726130,
          "exposureNAV": 2.38,
          "holdingType": "NAV1",
          "isin": "CNE100000G29",
          "marketCapital": 383432.48,
          "marketCapitalInUsd": 55814.2129319631,
          "marketPrice": 186.35,
          "marketValueFC": 229726130,
          "marketValueIc": 229726130,
          "name": "CHINA INTERNATIONAL TRAVEL-A",
          "percentOfNav": 2.381138,
          "sector": "Consumer Discretionary",
          "securityType": "",
          "seqno": 67,
          "sharesHeld": 1232767,
          "ticker": "601888 CG EQUITY"
        },
        {
          "accountID": "",
          "assetClass": "EQTY",
          "assetGroup": "",
          "averageCost": 215.8519939621,
          "cds": 0,
          "creditRating": "",
          "currency": "CNY",
          "exposureFC": 223456968,
          "exposureNAV": 2.32,
          "holdingType": "NAV1",
          "isin": "CNE000000GF2",
          "marketCapital": 354013.06,
          "marketCapitalInUsd": 51531.7854959387,
          "marketPrice": 240.5,
          "marketValueFC": 223456968,
          "marketValueIc": 223456968,
          "name": "LUZHOU LAOJIAO CO LTD-A",
          "percentOfNav": 2.316157,
          "sector": "Consumer Staples",
          "securityType": "",
          "seqno": 109,
          "sharesHeld": 929135,
          "ticker": "000568 C2 EQUITY"
        }
      ]
    }
  }

  getPartyCodes() {
    return {
      "data": [
        {
          "class_desc": "Asset Class Types",
          "class_type": "ASSET_CLASS",
          "code": "BOND",
          "code_desc": "Bond",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-03-16T05:25:44.2618645Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Asset Class Types",
          "class_type": "ASSET_CLASS",
          "code": "DERV",
          "code_desc": "Derivative",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-15T10:55:22.2751747Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Asset Class Types",
          "class_type": "ASSET_CLASS",
          "code": "EQTY",
          "code_desc": "Equity",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:57:13.2579399Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Asset Class Types",
          "class_type": "ASSET_CLASS",
          "code": "INDX",
          "code_desc": "Index",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076866Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Asset Class Types",
          "class_type": "ASSET_CLASS",
          "code": "MM",
          "code_desc": "Money Market",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076863Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Ccy Settlement Calendar Codes",
          "class_type": "CCY_CAL_CODES",
          "code": "CNY",
          "code_desc": "CNY",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:31:21.5725006Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": "Ccy Settlement Calendar Codes",
          "class_type": "CCY_CAL_CODES",
          "code": "HKD",
          "code_desc": "HKD",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:30:11.1129356Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": "Ccy Settlement Calendar Codes",
          "class_type": "CCY_CAL_CODES",
          "code": "SGD",
          "code_desc": "SGD",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:02:39.0506609Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": "Ccy Settlement Calendar Codes",
          "class_type": "CCY_CAL_CODES",
          "code": "USD",
          "code_desc": "USD",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T07:27:04.4241064Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "ASSET_CLASS",
          "code_desc": "Asset Class Types",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:57:48.3197876Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "CCY_CAL_CODES",
          "code_desc": "Ccy Settlement Calendar Codes",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-08T07:44:41.94Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "CLASS_TYPES",
          "code_desc": "Class Types",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-04T07:49:16.5533333Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "COUNTRIES",
          "code_desc": "Countries",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144181Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "DIVI_FREQ",
          "code_desc": "Dividend Frequency",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:25:05.5241544Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "INSTR_CATG",
          "code_desc": "Instrument Category",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144178Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "INSTR_DRV",
          "code_desc": "Instrument Drivers",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144185Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "INSTR_NARR",
          "code_desc": "Instrument Narratives",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144188Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "INSTR_PRTY",
          "code_desc": "Instrument Parties",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144184Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "INSTR_REF",
          "code_desc": "Instrument Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144186Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "INSTR_TYPE_R",
          "code_desc": "Instrument Type (Risk)",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-16T06:19:33.791804Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "INVST_STRATEGY",
          "code_desc": "Investment Strategy",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-03-17T07:29:46.6438765Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "LANGUAGE",
          "code_desc": "Language Codes",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-22T08:40:37.0068657Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "OPS_CAL_CODES",
          "code_desc": "Ops Calendar Codes",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-01-04T07:51:16.9224641Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "PARTY_ASSC",
          "code_desc": "Party Associations",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144189Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "PARTY_DRV",
          "code_desc": "Party Drivers",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.814419Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "PARTY_NARR",
          "code_desc": "Party Narratives",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144192Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "PARTY_REF",
          "code_desc": "Party Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144191Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "PARTY_TYPE",
          "code_desc": "Party Types",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144176Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "PMA_ASSET_GROUP",
          "code_desc": "PMA Asset Groups",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:54:36.1362127Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "PMA_CASH_CLASS",
          "code_desc": "PMA Cash Class",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:15:00.4057102Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "PMA_ISSUER",
          "code_desc": "Instrument Issuer",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-15T06:01:20.7768648Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "PMA_SECURITY_TYPE",
          "code_desc": "PMA Security Type",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:45:19.7333333Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "PRODUCT_TYPE",
          "code_desc": "Product Type",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.8144179Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "SLA",
          "code_desc": "SLA Options",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T11:17:10.0535849Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "TICKER_REF",
          "code_desc": "Ticker References",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:51:57.2631541Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "CLASS TYPES",
          "class_type": "CLASS_TYPES",
          "code": "TRD_CAL_CODES",
          "code_desc": "Trading Calender Codes",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T12:48:59.8209626Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AD",
          "code_desc": "Andorra",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927979Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AE",
          "code_desc": "United Arab Emirates",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280223Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AF",
          "code_desc": "Afghanistan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927978Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AG",
          "code_desc": "Antugua and Barbuda",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279798Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AI",
          "code_desc": "Anguilla",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279794Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AL",
          "code_desc": "Albania",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279784Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AM",
          "code_desc": "Armenia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279802Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AO",
          "code_desc": "Angola",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279792Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AQ",
          "code_desc": "Antartica",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279796Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AR",
          "code_desc": "Argentina",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.92798Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AS",
          "code_desc": "American Samoa",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279788Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AT",
          "code_desc": "Austria",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279807Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AU",
          "code_desc": "Australia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279805Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AW",
          "code_desc": "Aruba",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279803Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AX",
          "code_desc": "Aland Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279782Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "AZ",
          "code_desc": "Azerbaijan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279809Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BA",
          "code_desc": "Bosnia and Herzegovina",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279833Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BB",
          "code_desc": "Barbados",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279817Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BD",
          "code_desc": "Bangladesh",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279815Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BE",
          "code_desc": "Belgium",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927982Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BF",
          "code_desc": "Burkina Faso",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279848Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BG",
          "code_desc": "Bulgaria",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279846Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BH",
          "code_desc": "Bahrain",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279813Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BI",
          "code_desc": "Burundi",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927985Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BJ",
          "code_desc": "Benin",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279824Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BL",
          "code_desc": "Saint Barthelemy",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280129Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BM",
          "code_desc": "Bermuda",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279826Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BN",
          "code_desc": "Brunei",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279845Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BO",
          "code_desc": "Bolivia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927983Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BQ",
          "code_desc": "Bonaire",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279832Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BR",
          "code_desc": "Brazil",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279839Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BS",
          "code_desc": "Bahamas",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279811Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BT",
          "code_desc": "Bhutan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279828Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BV",
          "code_desc": "Bouvet Island",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279837Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BW",
          "code_desc": "Botswana",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279835Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BY",
          "code_desc": "Belarus",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279818Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "BZ",
          "code_desc": "Belize",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279822Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CA",
          "code_desc": "Canada",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279856Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CC",
          "code_desc": "Cocos [Keeling] Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279871Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CD",
          "code_desc": "Republic of the Congo",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279893Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CF",
          "code_desc": "Central African Repulic",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279861Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CG",
          "code_desc": "Congo",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279877Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CH",
          "code_desc": "Switzerland",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280187Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CI",
          "code_desc": "Cote Divoire",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279883Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CK",
          "code_desc": "Cook Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279879Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CL",
          "code_desc": "Chile",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279866Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CM",
          "code_desc": "Cameron",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279854Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CN",
          "code_desc": "China",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279868Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CO",
          "code_desc": "Colombia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279873Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CR",
          "code_desc": "Costa Rica",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279881Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CS",
          "code_desc": "Channel Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279864Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CU",
          "code_desc": "Cuba",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279886Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CV",
          "code_desc": "Cape Verde",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279857Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CW",
          "code_desc": "Curacao",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279888Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CX",
          "code_desc": "Christmas Island",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927987Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CY",
          "code_desc": "Cyprus",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927989Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "CZ",
          "code_desc": "Czech Republic",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279892Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "DC",
          "code_desc": "Dutch Caribbean",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279903Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "DE",
          "code_desc": "Germany",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927994Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "DJ",
          "code_desc": "Djibouti",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279897Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "DK",
          "code_desc": "Denmark",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279895Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "DM",
          "code_desc": "Dominica",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279899Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "DO",
          "code_desc": "Dominican Republic",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279901Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "DR",
          "code_desc": "East Carribean",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279905Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "DZ",
          "code_desc": "Algeria",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279787Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "EC",
          "code_desc": "Ecuador",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279908Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "EE",
          "code_desc": "Estonia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279917Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "EG",
          "code_desc": "Egypt",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927991Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "EH",
          "code_desc": "Western Sahara",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280245Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "EO",
          "code_desc": "Ethiopia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279919Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ER",
          "code_desc": "Eritrea",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279915Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ES",
          "code_desc": "Spain",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280175Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ET",
          "code_desc": "East Timor",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279906Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "FI",
          "code_desc": "Finland",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279926Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "FJ",
          "code_desc": "Fiji",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279924Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "FK",
          "code_desc": "Falkland Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279921Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "FM",
          "code_desc": "Micronesia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280052Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "FO",
          "code_desc": "Faroe Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279922Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "FR",
          "code_desc": "France",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279928Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GA",
          "code_desc": "Gabon",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279935Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GB",
          "code_desc": "United Kingdom",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280225Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GD",
          "code_desc": "Grenada",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279949Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GE",
          "code_desc": "Georgia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279939Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GF",
          "code_desc": "French Guiana",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927993Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GG",
          "code_desc": "Guernsey",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279957Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GH",
          "code_desc": "Ghana",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279942Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GI",
          "code_desc": "Gibraltar",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279944Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GL",
          "code_desc": "Greenland",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279948Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GM",
          "code_desc": "Gambia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279937Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GN",
          "code_desc": "Guinea",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279959Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GP",
          "code_desc": "Guadeloupe",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279951Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GQ",
          "code_desc": "Equatorial Guinea",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279914Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GR",
          "code_desc": "Greece",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279946Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GS",
          "code_desc": "South Georgia and Sandwich Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280169Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GT",
          "code_desc": "Guatemala",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279955Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GU",
          "code_desc": "Guam",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279953Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GW",
          "code_desc": "Guinea-Bissau",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279961Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "GY",
          "code_desc": "Guyana",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279963Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "HK",
          "code_desc": "Hong Kong",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927997Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "HM",
          "code_desc": "Heard and Macdoland Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279966Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "HN",
          "code_desc": "Honduras",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279968Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "HR",
          "code_desc": "Croatia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279884Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "HT",
          "code_desc": "Haiti",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279964Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "HU",
          "code_desc": "Hungary",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279972Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ID",
          "code_desc": "Indonesia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279977Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "IE",
          "code_desc": "Ireland",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279983Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "IL",
          "code_desc": "Isreal",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279986Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "IM",
          "code_desc": "Isle of Man",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279984Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "IN",
          "code_desc": "India",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279975Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "IO",
          "code_desc": "British Indian Ocean Territory",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279841Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "IQ",
          "code_desc": "Iraq",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279981Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "IR",
          "code_desc": "Iran",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279979Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "IS",
          "code_desc": "Iceland",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279973Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "IT",
          "code_desc": "Italy",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279988Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "IV",
          "code_desc": "Ivory Coast",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.927999Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "JE",
          "code_desc": "Jersey",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279995Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "JM",
          "code_desc": "Jamaica",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279992Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "JO",
          "code_desc": "Jordan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279997Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "JP",
          "code_desc": "Japan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279993Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KE",
          "code_desc": "Kenya",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280001Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KG",
          "code_desc": "Kyrgyzstan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280008Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KH",
          "code_desc": "Cambodia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279852Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KI",
          "code_desc": "Kiribati",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280003Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KM",
          "code_desc": "Comoros",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279875Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KN",
          "code_desc": "Saint Kitts and Nevis",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280133Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KO",
          "code_desc": "Kosova",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280004Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KP",
          "code_desc": "North Korea",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280089Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KR",
          "code_desc": "South Korea",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280171Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KW",
          "code_desc": "Kuwait",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280006Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KY",
          "code_desc": "Cayman Islands(CYM)",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279859Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "KZ",
          "code_desc": "kazakhstan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279999Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LA",
          "code_desc": "Laos",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928001Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LB",
          "code_desc": "Lebanon",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280014Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LC",
          "code_desc": "Saint Lucia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280135Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LI",
          "code_desc": "Liechtenstein",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280021Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LK",
          "code_desc": "Sri Lanka",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280177Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LR",
          "code_desc": "Liberia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280017Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LS",
          "code_desc": "Lesotho",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280015Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LT",
          "code_desc": "Lithuania",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280023Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LU",
          "code_desc": "Luxembourg",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280025Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LV",
          "code_desc": "Latvia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280012Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "LY",
          "code_desc": "Libya",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280019Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MA",
          "code_desc": "Morocco",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280063Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MC",
          "code_desc": "Monaco",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280056Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MD",
          "code_desc": "Moldova",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280054Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ME",
          "code_desc": "Montenegro",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928006Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MF",
          "code_desc": "Saint Martin (French Part)",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280137Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MG",
          "code_desc": "Madagascar",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928003Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MH",
          "code_desc": "Marshall Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280041Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MK",
          "code_desc": "Macedonia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280029Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ML",
          "code_desc": "Mali",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280038Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MM",
          "code_desc": "Myanmar [Burma]",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280067Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MN",
          "code_desc": "Mongolia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280058Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MO",
          "code_desc": "Macao",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280027Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MP",
          "code_desc": "Northern Mariana Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280091Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MQ",
          "code_desc": "Martinique",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280043Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MR",
          "code_desc": "Mauritania",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280045Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MS",
          "code_desc": "Montserrat",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280061Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MT",
          "code_desc": "Malta",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928004Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MU",
          "code_desc": "Mauritius",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280047Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MV",
          "code_desc": "Maldives",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280036Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MW",
          "code_desc": "Malawi",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280032Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MX",
          "code_desc": "Mexico",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928005Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MY",
          "code_desc": "Malaysia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280034Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "MZ",
          "code_desc": "Mozambique",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280065Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NA",
          "code_desc": "Namibia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280069Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NC",
          "code_desc": "New Caledonia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280076Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NE",
          "code_desc": "Niger",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280081Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NF",
          "code_desc": "Norfolk Island",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280087Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NG",
          "code_desc": "Nigeria",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280083Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NI",
          "code_desc": "Nicaragua",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928008Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NL",
          "code_desc": "Netherlands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280074Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NO",
          "code_desc": "Norway",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280092Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NP",
          "code_desc": "Nepal",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280072Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NR",
          "code_desc": "Nauru",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928007Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NU",
          "code_desc": "Noue",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280085Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "NZ",
          "code_desc": "New Zealand",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280078Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "OM",
          "code_desc": "Oman",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280094Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PA",
          "code_desc": "Panama",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280102Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PE",
          "code_desc": "Peru",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280107Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PF",
          "code_desc": "French Polynesia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279931Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PG",
          "code_desc": "Papua New Guinea",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280104Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PH",
          "code_desc": "Philippines",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280109Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PK",
          "code_desc": "Pakistan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280096Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PL",
          "code_desc": "Poland",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280113Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PM",
          "code_desc": "Saint Pierre and Miquelon",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928014Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PN",
          "code_desc": "Pitcairn Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280111Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PR",
          "code_desc": "Puerto Rico",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280116Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PS",
          "code_desc": "Palestine",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.92801Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PT",
          "code_desc": "Protugal",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280115Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PW",
          "code_desc": "Palau",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280098Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "PY",
          "code_desc": "Paraguay",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280105Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "QA",
          "code_desc": "Qatar",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280118Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "RE",
          "code_desc": "Reunion",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280126Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "RO",
          "code_desc": "Romania",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928012Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "RS",
          "code_desc": "Serbia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280151Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "RU",
          "code_desc": "Russia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280122Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "RW",
          "code_desc": "Rwanda",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280124Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SA",
          "code_desc": "Saudi Arabia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280147Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SB",
          "code_desc": "Saba",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280127Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SC",
          "code_desc": "Seychelles",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280153Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SD",
          "code_desc": "Sudan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280179Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SE",
          "code_desc": "Sweden",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280186Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SG",
          "code_desc": "Singapore",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280157Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SH",
          "code_desc": "Saint Helena",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280131Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SI",
          "code_desc": "Slovenia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280162Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SJ",
          "code_desc": "Svalbard and Jan Mayen",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280182Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SK",
          "code_desc": "Slovakia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928016Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SL",
          "code_desc": "Sierra Leone",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280155Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SM",
          "code_desc": "San Marino",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280146Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SMT",
          "code_desc": "Saint Martin",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280139Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SN",
          "code_desc": "Senegal",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280149Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SO",
          "code_desc": "Somalia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280166Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SR",
          "code_desc": "Suriname",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928018Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SS",
          "code_desc": "South Sudan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280173Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ST",
          "code_desc": "Sao Tome and Principe",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280191Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SV",
          "code_desc": "El Salvador",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279912Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SX",
          "code_desc": "Sint Maarten (Dutch Part)",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280158Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SY",
          "code_desc": "Syria",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280189Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "SZ",
          "code_desc": "Swaziland",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280184Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TC",
          "code_desc": "Turks and Caicos Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280214Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TD",
          "code_desc": "Chad",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279862Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TF",
          "code_desc": "French Southern Territories",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279933Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TG",
          "code_desc": "Togo",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280202Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TH",
          "code_desc": "Thailand",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280198Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TJ",
          "code_desc": "Tajikistan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280195Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TK",
          "code_desc": "Tokelau",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280204Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TL",
          "code_desc": "Timor Leste",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.92802Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TM",
          "code_desc": "Turkmenistan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280213Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TN",
          "code_desc": "Tunisia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280209Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TO",
          "code_desc": "Tonga",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280205Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TR",
          "code_desc": "Turkey",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280211Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TT",
          "code_desc": "Trinidad and Tobago",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280207Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TV",
          "code_desc": "Tuvalu",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280216Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TW",
          "code_desc": "Taiwan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280193Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "TZ",
          "code_desc": "Tanzania",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280196Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "UA",
          "code_desc": "Ukraine",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280222Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "UG",
          "code_desc": "Uganda",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928022Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "UM",
          "code_desc": "United States Minor Outlying Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280229Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "US",
          "code_desc": "United States",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280227Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "UY",
          "code_desc": "Uruguay",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280231Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "UZ",
          "code_desc": "Uzbekistan",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280233Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "VA",
          "code_desc": "Vatican City",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280236Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "VC",
          "code_desc": "Saint Vincent and the Grenadines",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280142Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "VE",
          "code_desc": "Venezuela",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280238Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "VG",
          "code_desc": "British Virgin Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9279843Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "VI",
          "code_desc": "U.S. Virgin Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280218Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "VN",
          "code_desc": "Vietnam",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.928024Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "VU",
          "code_desc": "Vanuatu",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280234Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "WF",
          "code_desc": "Wallis and Futuna",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280242Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "WS",
          "code_desc": "Samoa",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280144Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "WZ",
          "code_desc": "West bank \u0026 Gaza",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280244Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "YE",
          "code_desc": "Yemen",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280247Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "YT",
          "code_desc": "Mayotte",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280049Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ZA",
          "code_desc": "South Africa",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280167Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ZL",
          "code_desc": "Soloman Islands",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280164Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ZM",
          "code_desc": "Zambia",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280249Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Countries",
          "class_type": "COUNTRIES",
          "code": "ZW",
          "code_desc": "Zimbabwe",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.9280251Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Dividend Frequency",
          "class_type": "DIVI_FREQ",
          "code": "HALF_YEAR",
          "code_desc": "Half Yearly",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:26:11.6940656Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Dividend Frequency",
          "class_type": "DIVI_FREQ",
          "code": "QUARTER",
          "code_desc": "Quarterly",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:25:38.8697405Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Dividend Frequency",
          "class_type": "DIVI_FREQ",
          "code": "YEAR",
          "code_desc": "Yearly",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:25:56.0199658Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "ENUM_CATEGORY",
          "code": "CCY_CAL_CODES",
          "code_desc": "Ccy Settlement Calendar Codes",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-08T07:44:41.94Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": null,
          "class_type": "ENUM_CATEGORY",
          "code": "OPS_CAL_CODES",
          "code_desc": "Ops Calendar Codes",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-01-04T07:51:16.9224641Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": null,
          "class_type": "ENUM_CATEGORY",
          "code": "TRD_CAL_CODES",
          "code_desc": "Trading Calender Codes",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-03T12:48:59.8209626Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "ADVA",
          "code_desc": "Advisory Account",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-13T06:47:19.6356407Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "BOND",
          "code_desc": "Fixed Income / Bonds",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076848Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "CFD",
          "code_desc": "Contract for Difference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076851Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "EQTY",
          "code_desc": "Equities",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076846Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "ETF",
          "code_desc": "Exchange Traded Funds",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-13T06:30:40.8871137Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "FUTU",
          "code_desc": "Futures",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076852Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "INDX",
          "code_desc": "Indexes",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076855Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "LI",
          "code_desc": "L\u0026I Fund",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-13T06:46:18.6025162Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "MF",
          "code_desc": "Mutual Funds",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076858Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "OPT",
          "code_desc": "Options",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076853Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "PF",
          "code_desc": "Private Fund",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-13T06:46:58.2216239Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "SMA",
          "code_desc": "Separatley Managed Accounts",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076859Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "SWAPS",
          "code_desc": "Swaps",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-13T06:33:11.2408815Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Category",
          "class_type": "INSTR_CATG",
          "code": "WARR",
          "code_desc": "Warrants",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076849Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "COMMFU",
          "code_desc": "Commodity Futures",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:01:23.2170177Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "EQTS",
          "code_desc": "Equities",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-17T11:33:12.4742116Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "EQTY_ADR",
          "code_desc": "Equity ADR",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:00:35.9629135Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "EQTY_HDG_FND",
          "code_desc": "Equity Hedge Fund ",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:05:55.1709994Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "EQTY_MF",
          "code_desc": "Equity Mutual Fund or Load ",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:06:34.6167917Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "EQTY_SWAP",
          "code_desc": "Equity Swaps",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:01:44.7861562Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "FIXED_DEP",
          "code_desc": "Fixed Deposits",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:11:52.7762156Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "FUTU_SWAP",
          "code_desc": "Futures Swaps",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:04:53.3136226Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "FX_FWD",
          "code_desc": "FX Forwards",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:05:23.6283515Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "IDX_SWAP",
          "code_desc": "Index Swaps",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:04:37.0615279Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "IDXFU",
          "code_desc": "Index Futures",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:01:09.0652391Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "OFFSH_CORP_BOND",
          "code_desc": "Offshore Corporate Bonds",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:10:25.2208403Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "OFFSH_GOV_BOND",
          "code_desc": "Offshore Government Bonds",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:10:56.5437487Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "ONSH_CORP_BOND",
          "code_desc": "Onshore Corporate Bonds ",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:10:07.5467157Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "ONSH_GOV_BOND_PB",
          "code_desc": "Onshore Government Bonds (Issued by Policy Banks)",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:08:27.3981171Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "ONSH_GOV_BOND_TR",
          "code_desc": "Onshore Government Bonds (Issued by Treasury)",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:07:54.4422068Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Type (Risk)",
          "class_type": "INSTR_TYPE_R",
          "code": "PRV_NOTES",
          "code_desc": "Private Notes",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:11:30.6377906Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Investment Strategy",
          "class_type": "INVST_STRATEGY",
          "code": "INVST_STRATEGY",
          "code_desc": "Replication Strategy",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-03-17T08:52:34.9192048Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Language Codes",
          "class_type": "LANGUAGE",
          "code": "CH",
          "code_desc": "Chinese",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-22T08:43:33.6836863Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Ops Calendar Codes",
          "class_type": "OPS_CAL_CODES",
          "code": "HKO",
          "code_desc": "HK Ops Team Calendar",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2023-01-04T07:51:59.8086499Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": "Party Reference",
          "class_type": "PARTY_REF",
          "code": "BBGTICKER",
          "code_desc": "BBG Ticker",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-17T07:21:31.6803584Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Reference",
          "class_type": "PARTY_REF",
          "code": "BIC",
          "code_desc": "BIC Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:50:35.7376096Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Reference",
          "class_type": "PARTY_REF",
          "code": "CDSREF",
          "code_desc": "CDS BBG Ticker",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-23T06:13:25.2570038Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Reference",
          "class_type": "PARTY_REF",
          "code": "CREDIT_RATING_ISIN",
          "code_desc": "Credit Rating ISIN",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-23T06:16:01.5848682Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Reference",
          "class_type": "PARTY_REF",
          "code": "CUST",
          "code_desc": "Custodian Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T06:00:56.3656017Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Reference",
          "class_type": "PARTY_REF",
          "code": "FLEX",
          "code_desc": "Flex System Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:56:52.0301714Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Reference",
          "class_type": "PARTY_REF",
          "code": "INT",
          "code_desc": "Internal Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:50:06.5343032Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Reference",
          "class_type": "PARTY_REF",
          "code": "PMA_ACCT_ID",
          "code_desc": "PMA Account ID",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-23T06:07:23.7734862Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Reference",
          "class_type": "PARTY_REF",
          "code": "PMA_CUST",
          "code_desc": "PMA Custodian Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T11:50:24.1658652Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Reference",
          "class_type": "PARTY_REF",
          "code": "TRADAR_CODE",
          "code_desc": "Tradar Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T06:02:25.8486552Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "BOOK",
          "code_desc": "Books",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076831Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "CBNK",
          "code_desc": "Clearing Banks",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076845Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "CLNT",
          "code_desc": "Client Account",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076842Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "CPTY",
          "code_desc": "Counterpaty",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T09:23:24.8311834Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "CUST",
          "code_desc": "Custodians",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076841Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "DEPO",
          "code_desc": "Depositories",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-03-07T08:23:08.2910576Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "FA",
          "code_desc": "Fund Administrator",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076838Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "FMGR",
          "code_desc": "Fund Manager",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:21:51.9270173Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "INVA",
          "code_desc": "Investment Advisor",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:22:35.114675Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "ISSUER",
          "code_desc": "Issuer",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-23T06:05:53.9442367Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "LEGAL",
          "code_desc": "Legal Structure",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:21:06.2544525Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "MKT",
          "code_desc": "Markets",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076832Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "MM",
          "code_desc": "Market Maker",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076839Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "PD",
          "code_desc": "Participant Dealer",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076835Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "PMGR",
          "code_desc": "Portfolio Manager",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:22:11.0361051Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "RGTR",
          "code_desc": "Registrar",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:23:54.1843827Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "TA",
          "code_desc": "Transfer Agent",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-09T09:39:55.7076837Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Party Types",
          "class_type": "PARTY_TYPE",
          "code": "TRUS",
          "code_desc": "Trustee",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-27T06:24:26.7308213Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Asset Groups",
          "class_type": "PMA_ASSET_GROUP",
          "code": "BOND",
          "code_desc": "Bond",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:55:11.9831077Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Asset Groups",
          "class_type": "PMA_ASSET_GROUP",
          "code": "CURR",
          "code_desc": "Currencies",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:56:18.4411276Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Asset Groups",
          "class_type": "PMA_ASSET_GROUP",
          "code": "EQTY",
          "code_desc": "Equity",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:55:01.5748957Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Asset Groups",
          "class_type": "PMA_ASSET_GROUP",
          "code": "FUTU",
          "code_desc": "Future",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:55:40.6681627Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Asset Groups",
          "class_type": "PMA_ASSET_GROUP",
          "code": "SWAP",
          "code_desc": "Swap",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:56:07.9769766Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Cash Class",
          "class_type": "PMA_CASH_CLASS",
          "code": "Deposit",
          "code_desc": "Deposit",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:15:37.7295222Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Cash Class",
          "class_type": "PMA_CASH_CLASS",
          "code": "FX Fwd DLV",
          "code_desc": "FX Fwd DLV",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:16:13.8983859Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Issuer",
          "class_type": "PMA_ISSUER",
          "code": "ADBC",
          "code_desc": "Agricultural Development Bank of China\t",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-16T05:51:13.1324968Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Issuer",
          "class_type": "PMA_ISSUER",
          "code": "CCG",
          "code_desc": "Chinese Central Government\t",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-16T05:54:43.0978092Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Instrument Issuer",
          "class_type": "PMA_ISSUER",
          "code": "CDBEIBC",
          "code_desc": "China Development Bank or Export-Import Bank of China\t",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-16T05:56:14.5276938Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Commodity Future",
          "code_desc": "Commodity Future",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Corporate Bond",
          "code_desc": "Corporate Bond",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Currency Future",
          "code_desc": "Currency Future",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:51:06.5700798Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Equity",
          "code_desc": "Equity",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Equity ADR",
          "code_desc": "Equity ADR",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Equity CFD",
          "code_desc": "Equity CFD",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Equity HedgeFund",
          "code_desc": "Equity HedgeFund",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Equity Mutual Fund/L",
          "code_desc": "Equity Mutual Fund/Load",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Euro Bond",
          "code_desc": "Euro Bond",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:51:36.1454353Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Exchrate",
          "code_desc": "Exchrate",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Fixed Deposit",
          "code_desc": "Fixed Deposit",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:13:02.2711451Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Fully Funded CFD",
          "code_desc": "Fully Funded CFD",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Fully Funded CFD TES",
          "code_desc": "Fully Funded CFD TEST",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Government Bond",
          "code_desc": "Government Bond",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Index CFD",
          "code_desc": "Index CFD",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Index Future",
          "code_desc": "Index Future",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Private Notes",
          "code_desc": "Private Notes",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T05:12:31.707141Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "Swap Future",
          "code_desc": "Swap Future",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "PMA Security Type",
          "class_type": "PMA_SECURITY_TYPE",
          "code": "TBill Simple Discoun",
          "code_desc": "TBill Simple Discount",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-14T04:49:59.68Z",
          "version_user": "SYSTEM"
        },
        {
          "class_desc": "Product Type",
          "class_type": "PRODUCT_TYPE",
          "code": "ADVA",
          "code_desc": "Advisory Account",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-13T06:45:47.4303956Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Product Type",
          "class_type": "PRODUCT_TYPE",
          "code": "ETF",
          "code_desc": "ETF",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-15T10:11:30.8815889Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Product Type",
          "class_type": "PRODUCT_TYPE",
          "code": "LI",
          "code_desc": "L\u0026I",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-11T05:45:15.0119491Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Product Type",
          "class_type": "PRODUCT_TYPE",
          "code": "MF",
          "code_desc": "Mutual Fund",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2022-12-15T10:11:50.5474059Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Product Type",
          "class_type": "PRODUCT_TYPE",
          "code": "PF",
          "code_desc": "Private Fund",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-13T06:45:27.6743179Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "SLA Options",
          "class_type": "SLA",
          "code": "T0",
          "code_desc": "T0",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T11:17:23.7786573Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "SLA Options",
          "class_type": "SLA",
          "code": "T1",
          "code_desc": "T1",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T11:17:33.1802068Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Ticker References",
          "class_type": "TICKER_REF",
          "code": "8IGHT",
          "code_desc": "8ight Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:54:26.2423052Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Ticker References",
          "class_type": "TICKER_REF",
          "code": "ISIN",
          "code_desc": "ISIN Ref",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:52:43.4845078Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Ticker References",
          "class_type": "TICKER_REF",
          "code": "REUT",
          "code_desc": "Reuter Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:53:44.6619172Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Ticker References",
          "class_type": "TICKER_REF",
          "code": "SEDOL",
          "code_desc": "Sedol Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:53:01.1827425Z",
          "version_user": "RISK"
        },
        {
          "class_desc": "Trading Calender Codes",
          "class_type": "TRD_CAL_CODES",
          "code": "CN",
          "code_desc": "CN",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:54:22.3417065Z",
          "version_user": "OPSUSER"
        },
        {
          "class_desc": "Trading Calender Codes",
          "class_type": "TRD_CAL_CODES",
          "code": "HK",
          "code_desc": "HK",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T08:52:10.8241609Z",
          "version_user": "OPSMANAGER"
        },
        {
          "class_desc": "Trading Calender Codes",
          "class_type": "TRD_CAL_CODES",
          "code": "HKSC",
          "code_desc": "Hong Kong Stock Connect Market",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-06T08:51:58.2468583Z",
          "version_user": "OPSMANAGER"
        },
        {
          "class_desc": "Trading Calender Codes",
          "class_type": "TRD_CAL_CODES",
          "code": "SG",
          "code_desc": "SG",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-10T09:26:27.1846152Z",
          "version_user": "OPSMANAGER"
        },
        {
          "class_desc": "Trading Calender Codes",
          "class_type": "TRD_CAL_CODES",
          "code": "US",
          "code_desc": "US",
          "seq_no": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-06-16T06:20:47.796745Z",
          "version_user": "OPSMANAGER"
        }
      ],
      "status": "success"
    }
  }

  getFundList() {
    return {
      "data": [
        {
          "_id": "1000002",
          "active": "YES",
          "activeStatus": true,
          "applicationFee": {
            "creationFee": 3000,
            "dcBuffer": 5,
            "redemptionFee": 300
          },
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2014-02-18T00:00:00.000Z",
                  "name": "ChinaBond 5-Year Government Bond Total Return index",
                  "ticker": "CWTBTRID Index",
                  "toDate": "2019-06-23T00:00:00.000Z"
                },
                {
                  "ID": 2,
                  "ccy": "CNY",
                  "fromDate": "2019-06-24T00:00:00.000Z",
                  "name": "Bloomberg Barclays China Treasury + Policy Bank Index",
                  "ticker": "I32561CN Index",
                  "toDate": "2022-05-31T00:00:00.000Z"
                },
                {
                  "ID": 3,
                  "ccy": "CNY",
                  "fromDate": "2022-06-01T00:00:00.000Z",
                  "name": "FTSE Chinese Government and Policy Bank Bond Index",
                  "ticker": "CFIICNGL Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00203",
              "classIDName": "Unlisted Class P",
              "classNameInChinese": "",
              "classType": "UNLISTED",
              "description": "Unlisted Class P",
              "inceptionDate": "2022-02-24T00:00:00.000Z",
              "lotSize": 0,
              "name": "RMB Share Class P unlisted",
              "nameChinese": "",
              "nameCustodian": "CTB2",
              "namePMA": "RMB Share Class P unlisted",
              "pcfCode": "CSOPBOND5Y",
              "terminationDate": null,
              "tickers": [
                {
                  "listingDate": "2014-02-18T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mms": [],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "8IGHT",
                      "value": "ds"
                    }
                  ],
                  "ticker": "3199UP HK EQUITY",
                  "tickerCcy": "CNY"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            },
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2014-02-18T00:00:00.000Z",
                  "name": "ChinaBond 5-Year Government Bond Total Return index",
                  "ticker": "CWTBTRID Index",
                  "toDate": "2019-06-23T00:00:00.000Z"
                },
                {
                  "ID": 2,
                  "ccy": "CNY",
                  "fromDate": "2019-06-24T00:00:00.000Z",
                  "name": "Bloomberg Barclays China Treasury + Policy Bank Index",
                  "ticker": "I32561CN Index",
                  "toDate": "2022-05-31T00:00:00.000Z"
                },
                {
                  "ID": 3,
                  "ccy": "CNY",
                  "fromDate": "2022-06-01T00:00:00.000Z",
                  "name": "FTSE Chinese Government and Policy Bank Bond Index",
                  "ticker": "CFIICNGL Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00202",
              "classIDName": "Unlisted Class A",
              "classNameInChinese": " 人民币非上市A股_TEST",
              "classType": "UNLISTED",
              "description": "Unlisted Class A",
              "inceptionDate": "2022-02-24T00:00:00.000Z",
              "lotSize": 0,
              "mktPriceValue": "LAST_PRICE",
              "name": "RMB Share Class unlisted A",
              "nameChinese": "",
              "nameCustodian": "CTBB",
              "namePMA": "RMB Share Class unlisted A",
              "pcfCode": "CSOPBOND5Y",
              "terminationDate": null,
              "tickers": [
                {
                  "listingDate": "2014-02-18T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "8IGHT",
                      "value": "es"
                    }
                  ],
                  "ticker": "cny",
                  "tickerCcy": "CNY"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            },
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2014-02-18T00:00:00.000Z",
                  "name": "ChinaBond 5-Year Government Bond Total Return index",
                  "ticker": "CWTBTRID Index",
                  "toDate": "2019-06-23T00:00:00.000Z"
                },
                {
                  "ID": 2,
                  "ccy": "CNY",
                  "fromDate": "2019-06-24T00:00:00.000Z",
                  "name": "Bloomberg Barclays China Treasury + Policy Bank Index",
                  "ticker": "I32561CN Index",
                  "toDate": "2022-05-31T00:00:00.000Z"
                },
                {
                  "ID": 3,
                  "ccy": "CNY",
                  "fromDate": "2022-06-01T00:00:00.000Z",
                  "name": "FTSE Chinese Government and Policy Bank Bond Index",
                  "ticker": "CFIICNGL Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00002",
              "classIDName": "RMB",
              "classNameInChinese": "",
              "classType": "LISTED",
              "description": "CO-B5",
              "inceptionDate": "2014-02-19T00:00:00.000Z",
              "lotSize": 30000,
              "managementFee": "0.49",
              "mktPriceValue": "LAST_PRICE",
              "name": "Unlisted Class A",
              "nameChinese": "",
              "nameCustodian": "CTBA",
              "namePMA": "Unlisted Class A",
              "pcfCode": "CSOPBOND5Y",
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "NO",
                  "listingDate": "2014-02-18T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "",
                  "mms": [],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "83199"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000182979"
                    },
                    {
                      "type": "SEDOL",
                      "value": "BB2BNK8"
                    },
                    {
                      "type": "REUTER",
                      "value": "83199.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: RMB 83199, HKD 3199)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CTBA"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "83199 HK EQUITY"
                    }
                  ],
                  "ticker": "3199 HK EQUITY",
                  "tickerCcy": "HKD"
                },
                {
                  "cashComponent": "NO",
                  "listingDate": "2014-02-18T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "83199"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000182979"
                    },
                    {
                      "type": "SEDOL",
                      "value": "BB2BNK8"
                    },
                    {
                      "type": "REUTER",
                      "value": "83199.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: RMB 83199, HKD 3199)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CTBA"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "83199 HK EQUITY"
                    }
                  ],
                  "ticker": "83199 HK EQUITY",
                  "tickerCcy": "CNY"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "20",
              "underlierStockFillDate": "T0",
              "unitsCu": "30000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-09-14T00:00:00Z",
          "custodianFundCode": "CTBF",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK",
            "CN"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [
            {
              "ccy": "CNY",
              "name": "Index Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 0.09,
              "type": "FR"
            },
            {
              "ccy": "CNY",
              "name": "Audit Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 2335,
              "type": "FR"
            },
            {
              "bands": [
                {
                  "range": 200000000,
                  "rate": 0.16,
                  "sq": "First RMB",
                  "trustFeeBand": "First RMB 200000000 mn"
                },
                {
                  "range": 1000000000,
                  "rate": 0.14,
                  "sq": "Next RMB",
                  "trustFeeBand": "Next RMB 1000000000 mn"
                },
                {
                  "range": 1000000000,
                  "rate": 0.12,
                  "sq": "Next RMB",
                  "trustFeeBand": "Next RMB 1000000000 mn"
                }
              ],
              "ccy": "CNY",
              "name": "Management Fee",
              "rateType": "BAND_RATE",
              "remainingRate": 0.1,
              "type": "FR"
            },
            {
              "ccy": "CNY",
              "name": "HKEX\u0026SFC Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 190,
              "type": "FR"
            },
            {
              "ccy": "HKD",
              "name": "Trustee Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 0.02,
              "type": "FR"
            }
          ],
          "fundClass": "ETF",
          "fundID": "1000002",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000002",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2014-02-18T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategyID": "935",
          "isMultiMarket": false,
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 30000,
          "managed": "YES",
          "maturityAllocations": [],
          "maturityCheck": true,
          "name": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
          "nameChinese": "工銀南方東英富時中國國債及政策性銀行債券指數ETF",
          "nav1SignOffCutOff": "09:02",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK",
            "CN"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "BE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Goldman Sachs (Asia) Securities Limited",
              "nameChinese": "高盛(亞洲)證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD461"
            },
            {
              "name": "KGI Asia Limited",
              "nameChinese": "凱基證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD471"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "Yuanta Securities (Hong Kong) Company Limited",
              "nameChinese": "元大證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD493"
            },
            {
              "name": "China Industrial Securities International Brokerage Limited",
              "nameChinese": "興證國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD443"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "09:52",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao ",
            "nameChinese": "科特斯瓦拉朗",
            "partyCategory": "PMGR",
            "partyID": "757",
            "references": []
          },
          "preferredClass": "CLS00002",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-B5"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "BOND"
            },
            {
              "type": "PMA_CUST",
              "value": "34"
            },
            {
              "type": "CUST",
              "value": "34"
            },
            {
              "type": "INT",
              "value": "456"
            },
            {
              "type": "FLEX",
              "value": "45"
            },
            {
              "type": "TRADER_NAME",
              "value": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CTBF"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG903",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "remainingMaturityRange": 10,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "tradarFundLongName": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000003",
          "active": "YES",
          "activeStatus": true,
          "applicationFee": {
            "creationFee": 4002,
            "redemptionFee": 9003
          },
          "assetClass": "INDX",
          "assetClassDescription": "Index",
          "ccy": "USD",
          "ccyOps": "AED",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2011-01-21T00:00:00.000Z",
                  "name": "MSCI China Index",
                  "ticker": "MXCN Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "EGP",
              "classID": "CLS00014",
              "classIDName": "R - Hedged-Acc (EUR)",
              "classType": "LISTED",
              "description": "CO-CNBO",
              "inceptionDate": "2013-04-04T00:00:00.000Z",
              "lotSize": 0,
              "name": "CO-CNBO",
              "nameChinese": "",
              "namePMA": "CO-CNBO",
              "pcfCode": "34",
              "terminationDate": null,
              "tickers": [
                {
                  "deListingDate": "2023-03-24T00:00:00.000Z",
                  "listingDate": "2023-03-21T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    }
                  ],
                  "pdcc": true,
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "REUT",
                      "value": "12"
                    }
                  ],
                  "ticker": "dfff",
                  "tickerCcy": "AED"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            },
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2011-01-21T00:00:00.000Z",
                  "name": "MSCI China Index",
                  "ticker": "MXCN Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00008",
              "classIDName": "A - Acc (HKD)",
              "classType": "LISTED",
              "description": "CO-CNBO",
              "inceptionDate": "2012-12-07T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 0,
              "managementFee": "2",
              "mktPriceValue": "LAST_PRICE",
              "name": "CO-CNBOV",
              "nameChinese": "",
              "pcf_code": "dd",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CHNEBAO LX"
                },
                {
                  "type": "BBG",
                  "value": "CHNEBHA LX"
                },
                {
                  "type": "ISIN",
                  "value": "LU0744789560"
                },
                {
                  "type": "SEDOL",
                  "value": "CHNEBHA"
                }
              ],
              "terminationDate": null,
              "tickers": [
                {
                  "deListingDate": "2023-02-23T00:00:00.000Z",
                  "listingDate": "2023-02-20T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM502",
                      "PartyNameInChinese": "CLSA Singapore Pte Ltd",
                      "PartyNameInEnglish": "CLSA Singapore Pte Ltd"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM503",
                      "PartyNameInChinese": "DRW Singapore Pte. Ltd",
                      "PartyNameInEnglish": "DRW Singapore Pte. Ltd"
                    }
                  ],
                  "pdcc": false,
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "SEDOL",
                      "value": "12"
                    }
                  ],
                  "ticker": "CD",
                  "tickerCcy": "AED"
                }
              ],
              "totalExpenseRatio": "34",
              "underlierStockFillDate": "T0"
            },
            {
              "benchmarkIndex": [],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00007",
              "classIDName": "A - Acc (USD)",
              "classType": "LISTED",
              "description": "CO-CNBO",
              "inceptionDate": "2012-12-05T00:00:00.000Z",
              "lotSize": 0,
              "mktPriceValue": "LAST_PRICE",
              "name": "CO-CNBO",
              "nameChinese": "",
              "nameCustodian": "DF",
              "pcfCode": "FGD",
              "terminationDate": null,
              "tickers": [
                {
                  "deListingDate": "2023-02-07T00:00:00.000Z",
                  "listingDate": "2023-02-06T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM905",
                      "PartyNameInChinese": "安联资本管理（香港）有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    }
                  ],
                  "pdcc": true,
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "23"
                    }
                  ],
                  "ticker": "DGF",
                  "tickerCcy": "AED"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2011-01-21T05:30:00+05:30",
                  "name": "MSCI China Index",
                  "ticker": "MXCN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00009",
              "classIDName": "I - Dis (HKD)",
              "description": "CO-CNBO",
              "inceptionDate": "2016-01-26T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-CNBO",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CHNEBAO LX"
                },
                {
                  "type": "BBG",
                  "value": "CHNEIHD LX"
                },
                {
                  "type": "ISIN",
                  "value": "LU1033681542"
                },
                {
                  "type": "SEDOL",
                  "value": "CHNEIHD"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2011-01-21T05:30:00+05:30",
                  "name": "MSCI China Index",
                  "ticker": "MXCN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00010",
              "classIDName": "A - Dis (USD)",
              "description": "CO-CNBO",
              "inceptionDate": "2014-09-26T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-CNBO",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CHNEBAO LX"
                },
                {
                  "type": "BBG",
                  "value": "CHSNBAU LX"
                },
                {
                  "type": "ISIN",
                  "value": "LU1033680908"
                },
                {
                  "type": "SEDOL",
                  "value": "CHSNBAU"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2011-01-21T05:30:00+05:30",
                  "name": "MSCI China Index",
                  "ticker": "MXCN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00011",
              "classIDName": "A - Dis (HKD)",
              "description": "CO-CNBO",
              "inceptionDate": "2014-06-10T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-CNBO",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CHNEBAO LX"
                },
                {
                  "type": "BBG",
                  "value": "CNBOADH LX"
                },
                {
                  "type": "ISIN",
                  "value": "LU1033681039"
                },
                {
                  "type": "SEDOL",
                  "value": "CNBOADH"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2011-01-21T05:30:00+05:30",
                  "name": "MSCI China Index",
                  "ticker": "MXCN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00012",
              "classIDName": "I - Dis (USD)",
              "description": "CO-CNBO",
              "inceptionDate": "2015-11-25T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-CNBO",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CHNEBAO LX"
                },
                {
                  "type": "BBG",
                  "value": "CSCNIUD LX"
                },
                {
                  "type": "ISIN",
                  "value": "LU1033681468"
                },
                {
                  "type": "SEDOL",
                  "value": "CSCNIUD"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2011-01-21T05:30:00+05:30",
                  "name": "MSCI China Index",
                  "ticker": "MXCN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00013",
              "classIDName": "R - Acc (USD)",
              "description": "CO-CNBO",
              "inceptionDate": "2014-10-10T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-CNBO",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CHNEBAO LX"
                },
                {
                  "type": "BBG",
                  "value": "CSNBRUA LX"
                },
                {
                  "type": "ISIN",
                  "value": "LU0974894320"
                },
                {
                  "type": "SEDOL",
                  "value": "CSNBRUA"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2011-01-21T05:30:00+05:30",
                  "name": "MSCI China Index",
                  "ticker": "MXCN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00005",
              "classIDName": "I - Acc (USD)",
              "description": "CO-CNBO",
              "inceptionDate": "2011-01-21T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-CNBO",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CHNEBAO"
                },
                {
                  "type": "BBG",
                  "value": "CHNEBAO LX"
                },
                {
                  "type": "ISIN",
                  "value": "LU0562028620"
                },
                {
                  "type": "SEDOL",
                  "value": "CHNEBAO"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2011-01-21T05:30:00+05:30",
                  "name": "MSCI China Index",
                  "ticker": "MXCN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00015",
              "classIDName": "V - Acc (USD)",
              "description": "CO-CNBO",
              "inceptionDate": "2014-12-05T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-CNBO",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CHNEBAO LX"
                },
                {
                  "type": "BBG",
                  "value": "CHNBVCU LX"
                },
                {
                  "type": "ISIN",
                  "value": "LU0974893868"
                },
                {
                  "type": "SEDOL",
                  "value": "CHNBVCU"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2011-01-21T05:30:00+05:30",
                  "name": "MSCI China Index",
                  "ticker": "MXCN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "EUR",
              "classID": "CLS00016",
              "classIDName": "V - Hedged-Acc (EUR)",
              "description": "CO-CNBO",
              "inceptionDate": "2014-01-10T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-CNBO",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CHNEBAO LX"
                },
                {
                  "type": "BBG",
                  "value": "CHNBVCE LX"
                },
                {
                  "type": "ISIN",
                  "value": "LU0974894163"
                },
                {
                  "type": "SEDOL",
                  "value": "CHNBVCE"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianFundCode": "CUSt_TEST",
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "CN"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": null,
          "entity": "HK",
          "exchangeListingID": {
            "location": "CN",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "748",
            "references": []
          },
          "fees": [
            {
              "ccy": "USD",
              "name": "Audit Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 14,
              "type": "FV_GAG"
            }
          ],
          "fundClass": "Private",
          "fundID": "1000003",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000003",
          "investmentAdvisorID": {
            "location": "SG",
            "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "partyCategory": "INVA",
            "partyID": "747",
            "references": []
          },
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2023-01-02T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "RI",
          "isMultiMarket": false,
          "legalStructureID": {
            "location": "CN",
            "name": "Hong Kong Unit Trust",
            "nameChinese": "香港单位信托",
            "partyCategory": "LEGAL",
            "partyID": "750",
            "references": []
          },
          "liMultipliers": [],
          "location": "Latvia",
          "lotSize": 0,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "China New Balance Opportunity Fund",
          "nameChinese": "",
          "nav1SignOffCutOff": "11:18",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "onShoreAccID": "",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "BE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaNavFileTime": "08:59",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "prcCustodianID": {
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "CUST",
            "partyID": "CU530"
          },
          "prcCustodianId": "711",
          "preferredClass": "CLS00005",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CNBO"
            },
            {
              "type": "FLEX",
              "value": "CNBO"
            },
            {
              "type": "TRADER_NAME",
              "value": "sdff_TEST"
            },
            {
              "type": "CUSTODIAN",
              "value": "CUSt_TEST"
            },
            {
              "type": "BIC",
              "value": "34"
            },
            {
              "type": "CREDIT_RATING_ISIN",
              "value": "34"
            }
          ],
          "registrarID": {
            "location": "SG",
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "RGTR",
            "partyID": "753",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {
            "ccy": "USD",
            "name": "USD 3m Libor Total Return Index",
            "ticker": "BXIIU3MC Index"
          },
          "slaSignOffTime": "09:59",
          "slaType": "T0",
          "tradarFundLongName": "sdff_TEST",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "754",
            "references": []
          },
          "type": "ETF",
          "updatedAt": "2021-05-24T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000005",
          "active": "NO",
          "activeStatus": false,
          "applicationFee": {
            "creationFee": 3000,
            "dcBuffer": 1.4,
            "redemptionFee": 500
          },
          "assetClass": "DERV",
          "assetClassDescription": "Derivative",
          "ccy": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2012-02-22T05:30:00+05:30",
                  "name": "CTAGTRID Index",
                  "ticker": "CTAGTRID Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00028",
              "classIDName": "CSOPSZA HK",
              "description": "CSOP Shen Zhou Fund - CSOP Shen Zhou RMB Fund A CNY",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-SZRMB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSOPSZA"
                },
                {
                  "type": "BBG",
                  "value": "CSOPSZA HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000099173"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2012-02-22T05:30:00+05:30",
                  "name": "CTAGTRID Index",
                  "ticker": "CTAGTRID Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00029",
              "classIDName": "CSOPSZI HK",
              "description": "CSOP Shen Zhou Fund - CSOP Shen Zhou RMB Fund I CNY",
              "inceptionDate": "2012-02-22T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-SZRMB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSOPSZI"
                },
                {
                  "type": "BBG",
                  "value": "CSOPSZI HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000099181"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2012-02-22T05:30:00+05:30",
                  "name": "CTAGTRID Index",
                  "ticker": "CTAGTRID Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00030",
              "classIDName": "CSOHKDI HK",
              "description": "CSOP Shen Zhou Fund - CSOP Shen Zhou RMB Fund I HKD",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-SZRMB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSOHKDI"
                },
                {
                  "type": "BBG",
                  "value": "CSOHKDI HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000186053"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2012-02-22T05:30:00+05:30",
                  "name": "CTAGTRID Index",
                  "ticker": "CTAGTRID Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00031",
              "classIDName": "CSOUSDA HK",
              "description": "CSOP Shen Zhou Fund - CSOP Shen Zhou RMB Fund A USD",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-SZRMB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSOUSDA"
                },
                {
                  "type": "BBG",
                  "value": "CSOUSDA HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000186020"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2012-02-22T05:30:00+05:30",
                  "name": "CTAGTRID Index",
                  "ticker": "CTAGTRID Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00032",
              "classIDName": "CSOUSDI HK",
              "description": "CSOP Shen Zhou Fund - CSOP Shen Zhou RMB Fund I USD",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-SZRMB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSOUSDI"
                },
                {
                  "type": "BBG",
                  "value": "CSOUSDI HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000186046"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2012-02-22T05:30:00+05:30",
                  "name": "CTAGTRID Index",
                  "ticker": "CTAGTRID Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00033",
              "classIDName": "CSOHKDA HK",
              "description": "CSOP Shen Zhou Fund - CSOP Shen Zhou RMB Fund A HKD",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-SZRMB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSOHKDA"
                },
                {
                  "type": "BBG",
                  "value": "CSOHKDA HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000186038"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianFundCode": "",
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "fees": [
            {
              "ccy": "CNY",
              "name": "NA",
              "rateType": "FIXED_RATE",
              "remainingRate": 15,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000005",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000005",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2023-01-02T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "hjjh",
          "isMultiMarket": false,
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Shen Zhou RMB Fund",
          "nameChinese": "",
          "navCalendar": [
            "CN"
          ],
          "navCondition": "AND",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "participatingDealerIDs": [],
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "prcCustodianID": {
            "location": "AF",
            "name": "HSBC Bank (China) Company Limited",
            "nameChinese": "匯豐銀行(中國)有限公司",
            "partyCategory": "PRC_CUST",
            "partyID": "711",
            "references": []
          },
          "preferredClass": "CLS00029",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "RQFII-SZRMB"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "SHENZHOU"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG910",
            "references": []
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "terminatedDate": "2023-02-24T00:00:00Z",
          "tradarFundLongName": "",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC CUSTODIAN\t\n",
            "nameChinese": "汇丰托管人\n",
            "partyCategory": "TRUS",
            "partyID": "TS900",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2019-07-26T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000006",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "MM",
          "assetClassDescription": "Money Market",
          "ccy": "AED",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "AED",
                  "fromDate": "2023-02-09T00:00:00.000Z",
                  "name": "dc",
                  "ticker": "d",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "AED",
              "classID": "CLS00314",
              "classType": "UNLISTED",
              "inceptionDate": "2020-04-09T00:00:00.000Z",
              "lotSize": 11213,
              "mktPriceValue": "LAST_PRICE",
              "name": "dvS",
              "nameChinese": "",
              "pcfCode": "ds",
              "terminationDate": null,
              "tickers": [
                {
                  "marketMakerIDs": [],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM905",
                      "PartyNameInChinese": "安联资本管理（香港）有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "8IGHT",
                      "value": "DS"
                    }
                  ],
                  "ticker": "FDA",
                  "tickerCcy": "AED"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianFundCode": "",
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "dealingCalendar": [
            "HKSC"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "fees": [],
          "fundID": "1000006",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000006",
          "investmentStrategies": [],
          "investmentStrategy": "24",
          "isMultiMarket": false,
          "liMultipliers": [],
          "location": "Cayman Islands",
          "lotSize": 11213,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Dragon Dynamic China Select Equity SP",
          "nameChinese": "",
          "navCalendar": [
            "HKSC"
          ],
          "navCondition": "AND",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "participatingDealerIDs": [],
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "preferredClass": "CLS00314",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "RQFII-DDCS"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "SPC3"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "HSBC Trustee (Cayman) Limited ",
            "partyCategory": "RGTR",
            "partyID": "755",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "tradarFundLongName": "",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "754",
            "references": []
          },
          "type": "ETF",
          "updatedAt": "2019-07-26T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000007",
          "active": "YES",
          "activeStatus": true,
          "applicationFee": {
            "creationFee": 345,
            "redemptionFee": 565
          },
          "benchmarkIndex": [
            {
              "ccy": "CNY",
              "fromDate": "2014-09-03T05:30:00+05:30",
              "name": "CSIR0300 Index",
              "ticker": "CSIR0300 Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "CNY",
            "fromDate": "2014-09-03T00:00:00Z",
            "name": "CSIR0300 Index",
            "ticker": "CSIR0300 Index",
            "toDate": "2099-12-31T00:00:00Z"
          },
          "ccy": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2014-09-03T05:30:00+05:30",
                  "name": "CSIR0300 Index",
                  "ticker": "CSIR0300 Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00036",
              "classIDName": "Class A (unhedged)",
              "description": "CO-HERM",
              "inceptionDate": "2014-09-17T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-HERM",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAAU"
                },
                {
                  "type": "BBG",
                  "value": "CSHCAAU ID"
                },
                {
                  "type": "ISIN",
                  "value": "IE00BPCWXK98"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2014-09-03T05:30:00+05:30",
                  "name": "CSIR0300 Index",
                  "ticker": "CSIR0300 Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00035",
              "classIDName": "Class A",
              "description": "CO-HERM",
              "inceptionDate": "2014-09-03T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-HERM",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAAR"
                },
                {
                  "type": "BBG",
                  "value": "CSHCAAR ID"
                },
                {
                  "type": "ISIN",
                  "value": "IE00BP8RPK21"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2014-09-03T05:30:00+05:30",
                  "name": "CSIR0300 Index",
                  "ticker": "CSIR0300 Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00037",
              "classIDName": "Class I",
              "description": "CO-HERM",
              "inceptionDate": "2014-09-03T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-HERM",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR"
                },
                {
                  "type": "BBG",
                  "value": "CSHCAIR ID"
                },
                {
                  "type": "ISIN",
                  "value": "IE00BP8RPQ82"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2014-09-03T05:30:00+05:30",
                  "name": "CSIR0300 Index",
                  "ticker": "CSIR0300 Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00038",
              "classIDName": "Class I (hedged)",
              "description": "CO-HERM",
              "inceptionDate": "2014-10-10T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-HERM",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCHIU"
                },
                {
                  "type": "BBG",
                  "value": "CSHCHIU ID"
                },
                {
                  "type": "ISIN",
                  "value": "IE00BP8RPR99"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2014-09-03T05:30:00+05:30",
                  "name": "CSIR0300 Index",
                  "ticker": "CSIR0300 Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00039",
              "classIDName": "Class I (unhedged)",
              "description": "CO-HERM",
              "inceptionDate": "2014-09-03T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-HERM",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIU"
                },
                {
                  "type": "BBG",
                  "value": "CSHCAIU ID"
                },
                {
                  "type": "ISIN",
                  "value": "IE00BPCWXQ50"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 6"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Trustee Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 17,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000007",
          "instrumentID": "1000007",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Ireland",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP China Intelligence A-Share Fund",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00035",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-HERM"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "HERMES"
            }
          ],
          "registrar": {
            "en": "Ireland"
          },
          "riskfreeIndex": {},
          "type": "EM",
          "updatedAt": "2020-01-15T15:17:01.862+05:30"
        },
        {
          "_id": "1000008",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2015-01-20T00:00:00.000Z",
                  "name": "FTSE China Govt/Policy Bank Bond Index 0-1 Year",
                  "ticker": "CFIIGPSL Index",
                  "toDate": "2019-10-08T00:00:00.000Z"
                },
                {
                  "ID": 2,
                  "ccy": "CNY",
                  "fromDate": "2019-10-09T00:00:00.000Z",
                  "name": "7-Day Fixing Repo Rate (FR007)",
                  "ticker": "CNRR007 Index",
                  "toDate": "2022-03-04T00:00:00.000Z"
                },
                {
                  "ID": 3,
                  "ccy": "HKD",
                  "fromDate": "2022-03-07T00:00:00.000Z",
                  "name": "HSCIUT Index",
                  "ticker": "HSCIUT Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00040",
              "classIDName": "RMB",
              "classType": "LISTED",
              "description": "CO-USTB",
              "inceptionDate": "2015-01-21T00:00:00.000Z",
              "lotSize": 10000,
              "managementFee": "0.49",
              "mktPriceValue": "LAST_PRICE",
              "name": "RMB Share Class A",
              "nameChinese": "人民幣A股",
              "nameCustodian": "CST1",
              "namePMA": "RMB Share Class A",
              "pcfCode": "FGH",
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "NO",
                  "deListingDate": "2023-02-08T00:00:00.000Z",
                  "listingDate": "2023-02-07T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "83122"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000226131"
                    },
                    {
                      "type": "REUTER",
                      "value": "83122.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: RMB 83122, HKD 3122)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CST1"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "83122 HK EQUITY"
                    }
                  ],
                  "ticker": "3122 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "10",
              "underlierStockFillDate": "T0",
              "unitsCu": "10000"
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2015-01-20T00:00:00.000Z",
                  "name": "FTSE China Govt/Policy Bank Bond Index 0-1 Year",
                  "ticker": "CFIIGPSL Index",
                  "toDate": "2019-10-08T00:00:00.000Z"
                },
                {
                  "ccy": "CNY",
                  "fromDate": "2019-10-09T00:00:00.000Z",
                  "name": "7-Day Fixing Repo Rate (FR007)",
                  "ticker": "CNRR007 Index",
                  "toDate": "2022-03-04T00:00:00.000Z"
                },
                {
                  "ccy": "HKD",
                  "fromDate": "2022-03-07T00:00:00.000Z",
                  "name": "HSCIUT Index",
                  "ticker": "HSCIUT Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00198",
              "classIDName": "Unlisted Share Class A",
              "classType": "UNLISTED",
              "description": "Unlisted Share Class A",
              "inceptionDate": "2021-12-10T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "Unlisted Share Class A",
              "nameChinese": "",
              "nameCustodian": "CSTA",
              "namePMA": "RMB Share Class unlisted A",
              "references": [
                {
                  "type": "BBG",
                  "value": "3122UA HK EQUITY"
                },
                {
                  "type": "8IGHT",
                  "value": "3122UA"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CSTA"
                },
                {
                  "type": "PMA",
                  "value": "RMB Share Class unlisted A"
                }
              ],
              "shareClassAcctCode": "zzzUConCapRMB",
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianFundCode": "CSTB",
          "custodianID": {
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "CUST",
            "partyID": "CU528"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fees": [
            {
              "bands": [
                {
                  "range": 100000000,
                  "rate": 0.24,
                  "sq": "First RMB",
                  "trustFeeBand": "First RMB 100000000 mn"
                },
                {
                  "range": 200000000,
                  "rate": 0.22,
                  "sq": "Next RMB",
                  "trustFeeBand": "Next RMB 200000000 mn"
                },
                {
                  "range": 1000000000,
                  "rate": 0.2,
                  "sq": "Next RMB",
                  "trustFeeBand": "Next RMB 1000000000 mn"
                },
                {
                  "range": 2000000000,
                  "rate": 0.18,
                  "sq": "Next RMB",
                  "trustFeeBand": "Next RMB 2000000000 mn"
                },
                {
                  "range": 500000000,
                  "rate": 0.26,
                  "sq": "Next RMB",
                  "trustFeeBand": "Next RMB 500000000 mn"
                }
              ],
              "ccy": "HKD",
              "name": "Management Fee",
              "rateType": "BAND_RATE",
              "remainingRate": 0.12,
              "type": "FR"
            },
            {
              "ccy": "CNY",
              "name": "Setup Expense",
              "rateType": "FIXED_RATE",
              "remainingRate": 18,
              "type": "FR"
            }
          ],
          "fundClass": "ETF",
          "fundID": "1000008",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000008",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2021-02-10T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "DG",
          "isMultiMarket": false,
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 10000,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "maturityCheck": true,
          "name": "CSOP RMB Money Market ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "17:43",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "ME",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Goldman Sachs (Asia) Securities Limited",
              "nameChinese": "高盛(亞洲)證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD461"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "China Merchant Securities (HK) Co., Limited",
              "nameChinese": "招商證券(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD445"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "10:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00040",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-USTB"
            },
            {
              "type": "PCF_CODE",
              "value": "USTBOND"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "USTB"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP RMB Money Market ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSTB"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "remainingMaturityRange": 3,
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "12:45",
          "slaType": "T0",
          "tradarFundLongName": "CSOP RMB Money Market ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000009",
          "active": "YES",
          "activeStatus": true,
          "applicationFee": {
            "creationFee": 2000,
            "redemptionFee": 5000
          },
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2015-03-12T05:30:00+05:30",
              "name": "FTSE China A50 Index",
              "ticker": "A50CNHN Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "USD",
            "fromDate": "2015-03-12T00:00:00Z",
            "name": "FTSE China A50 Index",
            "ticker": "A50CNHN Index",
            "toDate": "2099-12-31T00:00:00Z"
          },
          "ccy": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2015-03-12T05:30:00+05:30",
                  "name": "FTSE China A50 Index",
                  "ticker": "A50CNHN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00042",
              "classIDName": "USD",
              "description": "CO-USA50",
              "inceptionDate": "2015-03-12T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-USA50",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "AFTY"
                },
                {
                  "type": "BBG",
                  "value": "AFTY US Equity"
                },
                {
                  "type": "ISIN",
                  "value": "US12649L1052"
                },
                {
                  "type": "SEDOL",
                  "value": "BVW2851"
                },
                {
                  "type": "REUTER",
                  "value": "AFTY.US"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 9"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Trustee Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 19,
              "type": "FR"
            },
            {
              "ccy": "CNY",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 0.2,
              "type": "FR"
            }
          ],
          "fundID": "1000009",
          "instrumentID": "1000009",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "United States",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP FTSE China A50 ETF (US)",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00042",
          "productType": "EE",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "USA50ETF"
            },
            {
              "type": "TRADAR_CODE",
              "value": "CO-AFTY"
            }
          ],
          "registrar": {
            "en": "US"
          },
          "riskfreeIndex": {},
          "type": "EE",
          "updatedAt": "2020-05-25T14:23:00.771+05:30"
        },
        {
          "_id": "1000010",
          "active": "YES",
          "activeStatus": true,
          "applicationFee": {
            "creationFee": 3000,
            "dcBuffer": 8,
            "redemptionFee": 3000
          },
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2015-05-15T00:00:00.000Z",
                  "name": "CHINEXT Price Index",
                  "ticker": "SZ399006 Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00044",
              "classIDName": "RMB",
              "classType": "LISTED",
              "description": "CO-CNXT",
              "inceptionDate": "2015-05-15T00:00:00.000Z",
              "lotSize": 300000,
              "managementFee": "0.99",
              "mktPriceValue": "LAST_PRICE",
              "name": "RMB Share Class A",
              "nameChinese": "人民幣A股",
              "nameCustodian": "CCNT",
              "namePMA": "RMB Share Class A",
              "pcfCode": "Chinext",
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2015-05-15T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000248226"
                    },
                    {
                      "type": "REUTER",
                      "value": "83147.HK"
                    },
                    {
                      "type": "EXCH",
                      "value": "83147"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: RMB 83147, HKD 3147)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CCNT"
                    },
                    {
                      "type": "BBG",
                      "value": "83147 HK EQUITY"
                    }
                  ],
                  "ticker": "3147 HK EQUITY",
                  "tickerCcy": "HKD"
                },
                {
                  "cashComponent": "YES",
                  "listingDate": "2015-05-15T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000248226"
                    },
                    {
                      "type": "REUTER",
                      "value": "83147.HK"
                    },
                    {
                      "type": "EXCH",
                      "value": "83147"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: RMB 83147, HKD 3147)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CCNT"
                    },
                    {
                      "type": "BBG",
                      "value": "83147 HK EQUITY"
                    }
                  ],
                  "ticker": "83147 HK EQUITY",
                  "tickerCcy": "CNY"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "200",
              "underlierStockFillDate": "T2",
              "unitsCu": "300000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianFundCode": "CCNT",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "CN",
            "HK"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": "YEAR",
          "entity": "HK",
          "exchangeListingID": {
            "country": "Afghanistan",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "EL804",
            "references": []
          },
          "fees": [
            {
              "ccy": "CNY",
              "name": "Trustee Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 20,
              "type": "FR"
            },
            {
              "ccy": "CNY",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 20,
              "type": "FR"
            }
          ],
          "fundClass": "ETF",
          "fundID": "1000010",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000010",
          "investmentAdvisorID": {
            "location": "SG",
            "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "partyCategory": "INVA",
            "partyID": "747",
            "references": []
          },
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2015-05-15T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategyID": "934",
          "isMultiMarket": false,
          "legalStructureID": {
            "location": "CN",
            "name": "Hong Kong Unit Trust",
            "nameChinese": "香港单位信托",
            "partyCategory": "LEGAL",
            "partyID": "750",
            "references": []
          },
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 300000,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "CSOP SZSE ChiNext ETF",
          "nameChinese": "南方東英深證創業板ETF",
          "nav1SignOffCutOff": "10:30",
          "nav2Recon": "NO",
          "navCalendar": [
            "CN",
            "HK"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "CITIC Securities Brokerage (HK) Limited",
              "nameChinese": "中信証券經紀(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD449"
            },
            {
              "name": "Goldman Sachs (Asia) Securities Limited",
              "nameChinese": "高盛(亞洲)證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD461"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Morgan Stanley Hong Kong Securities Limited",
              "nameChinese": "Morgan Stanley Hong Kong Securities Limited",
              "partyCategory": "PD",
              "partyID": "PD479"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "CLSA LIMITED",
              "nameChinese": "中信里昂證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD451"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Korea Investment \u0026 Securities Asia Limited",
              "nameChinese": "韓國投資證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD472"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Yuanta Securities (Hong Kong) Company Limited",
              "nameChinese": "元大證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD493"
            },
            {
              "name": "Zhongtai International Securities Limited",
              "nameChinese": "中泰國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD494"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "17:45",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "prcCustodianID": {
            "location": "AF",
            "name": "HSBC Bank (China) Company Limited",
            "nameChinese": "匯豐銀行(中國)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU525",
            "references": [],
            "versionNo": 2
          },
          "preferredClass": "CLS00044",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CNXT"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "CHINEXT"
            },
            {
              "type": "PMA_CUST",
              "value": "HSBC_HK"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP SZSE Chinext ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CCNT"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG903",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "tradarFundLongName": "CSOP SZSE Chinext ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000011",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [
            {
              "ccy": "CNY",
              "fromDate": "2015-10-23T05:30:00+05:30",
              "name": "CSI300 Smart TRI",
              "ticker": "CSIH0667 Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "CNY",
            "fromDate": "2015-10-23T00:00:00Z",
            "name": "CSI300 Smart TRI",
            "ticker": "CSIH0667 Index",
            "toDate": "2099-12-31T00:00:00Z"
          },
          "ccy": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2015-10-23T05:30:00+05:30",
                  "name": "CSI300 Smart TRI",
                  "ticker": "CSIH0667 Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00046",
              "classIDName": "RMB",
              "description": "HK-300AH",
              "inceptionDate": "2015-10-23T05:30:00+05:30",
              "lastDistribution": {
                "en": ""
              },
              "lotSize": 0,
              "managementFee": "0.88",
              "marketMakers": {
                "en": [
                  "KGI Asia Limited",
                  "SG Securities (HK) Limited"
                ],
                "tch": [
                  "凱基證券亞洲有限公司",
                  "法國興業證券（香港）有限公司"
                ]
              },
              "name": "HK-300AH",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "83129"
                },
                {
                  "type": "BBG",
                  "value": "83129 HK Equity"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000268315"
                },
                {
                  "type": "REUTER",
                  "value": "83129.HK"
                }
              ],
              "tickers": [],
              "tradingLotSize": "200"
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2015-10-23T05:30:00+05:30",
                  "name": "CSI300 Smart TRI",
                  "ticker": "CSIH0667 Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00045",
              "classIDName": "HKD",
              "description": "HK-300AH",
              "inceptionDate": "2015-10-23T05:30:00+05:30",
              "lastDistribution": {
                "en": ""
              },
              "lotSize": 0,
              "managementFee": "0.88",
              "marketMakers": {
                "en": [
                  "KGI Asia Limited",
                  "SG Securities (HK) Limited"
                ],
                "tch": [
                  "凱基證券亞洲有限公司",
                  "法國興業證券（香港）有限公司"
                ]
              },
              "name": "HK-300AH",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "3129"
                },
                {
                  "type": "BBG",
                  "value": "3129 HK Equity"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000268323"
                },
                {
                  "type": "REUTER",
                  "value": "3129.HK"
                }
              ],
              "tickers": [],
              "tradingLotSize": "200"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited"
          },
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [
            {
              "ccy": "CNY",
              "name": "HKEX\u0026SFC Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 356,
              "type": "FV_GAV"
            },
            {
              "ccy": "CNY",
              "name": "Trustee Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 0.04,
              "type": "FR"
            },
            {
              "ccy": "CNY",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 0.68,
              "type": "FR"
            },
            {
              "ccy": "CNY",
              "name": "Index Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 0.76,
              "type": "FV_GAV"
            },
            {
              "ccy": "CNY",
              "name": "Setup Expense",
              "rateType": "FIXED_RATE",
              "remainingRate": 453,
              "type": "FV_GAV"
            }
          ],
          "fundID": "1000011",
          "instrumentID": "1000011",
          "investmentAdvisor": {
            "en": "CSOP"
          },
          "investmentStrategy": {
            "en": "Representative Sampling",
            "tch": "代表性抽樣策略"
          },
          "isMultiMarket": false,
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信托"
          },
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資産管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP China CSI 300 Smart ETF",
          "nameChinese": "",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "Goldman Sachs (Asia) Securities Limited",
              "nameChinese": "高盛(亞洲)證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD461"
            }
          ],
          "participatingDealers": {
            "en": [
              "ABN AMRO Clearing Hong Kong Limited",
              "Nomura International (Hong Kong) Limited",
              "Goldman Sachs (Asia) Securities Limited",
              "BNP Paribas SA"
            ],
            "tch": [
              "ABN AMRO Clearing Hong Kong Limited",
              "野村國際(香港)有限公司",
              "高盛(亞洲)證券有限公司",
              "法國巴黎銀行全球托管行"
            ]
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {
            "en": "HSBC Bank (China) Company Limited"
          },
          "preferredClass": "CLS00046",
          "productType": "EE",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-300AH"
            },
            {
              "type": "PCF_CODE",
              "value": "CSOPCSI300"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "SMARTETF"
            }
          ],
          "registrar": {
            "en": "HSBC Institutional Trust Services (Asia) Limited",
            "tch": "匯豐機構信託服務（亞洲）有限公司"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2019-12-20T05:30:00+05:30",
          "type": "EE",
          "updatedAt": "2020-04-14T18:50:23.138+05:30"
        },
        {
          "_id": "1000012",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "benchmarkIndex": [
            {
              "ccy": "CNY",
              "fromDate": "2015-11-25T05:30:00+05:30",
              "name": "MSCI China A International Net Toal Return Index",
              "ticker": "M7CNAIR Index",
              "toDate": "2018-05-02T05:30:00+05:30"
            },
            {
              "ccy": "CNY",
              "fromDate": "2018-05-03T05:30:00+05:30",
              "name": "MSCI China A Inclusion Net Total Return Index",
              "ticker": "MBCNA Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "CNY",
            "fromDate": "2015-11-26T00:00:00Z",
            "name": "MSCI China A Inclusion Net Total Return Index",
            "ticker": "MBCNA Index",
            "toDate": "2099-12-31T00:00:00Z"
          },
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2015-11-25T00:00:00.000Z",
                  "name": "MSCI China A International Net Toal Return Index",
                  "ticker": "M7CNAIR Index",
                  "toDate": "2018-05-02T00:00:00.000Z"
                },
                {
                  "ID": 2,
                  "ccy": "CNY",
                  "fromDate": "2018-05-03T00:00:00.000Z",
                  "name": "MSCI China A Inclusion Net Total Return Index",
                  "ticker": "MBCNA Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00048",
              "classIDName": "RMB",
              "classType": "LISTED",
              "description": "HK-MSCIA",
              "inceptionDate": "2015-11-25T21:30:00+05:30",
              "lastDistribution": {
                "en": "RMB 0.20",
                "tch": "人民幣0.20"
              },
              "lotSize": 500000,
              "managementFee": "0.75",
              "marketMakers": {
                "en": [
                  "BNP Paribas Securities (Asia) Limited",
                  "Optiver Trading Hong Kong Limited",
                  "SG Securities (HK) Limited"
                ],
                "tch": [
                  "法國巴黎證券(亞洲)有限公司",
                  "Optiver Trading Hong Kong Limited",
                  "法國興業證券(香港)有限公司"
                ]
              },
              "name": "HK-MSCIA",
              "nameChinese": "",
              "nameCustodian": "CITN",
              "namePMA": "RMB Share Class A",
              "references": [
                {
                  "type": "EXCH",
                  "value": "83149"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000273489"
                },
                {
                  "type": "REUTER",
                  "value": "83149.HK"
                },
                {
                  "type": "8IGHT",
                  "value": "(Stock code: RMB 83149, HKD 3149)"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CITN"
                },
                {
                  "type": "PMA",
                  "value": "RMB Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "83149 HK EQUITY"
                }
              ],
              "shareClassAcctCode": "zzzConCapRMB",
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "83149"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000273489"
                    },
                    {
                      "type": "REUTER",
                      "value": "83149.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: RMB 83149, HKD 3149)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CITN"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "83149 HK EQUITY"
                    }
                  ],
                  "ticker": "3149 HK EQUITY",
                  "tickerCcy": "HKD"
                },
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "83149"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000273489"
                    },
                    {
                      "type": "REUTER",
                      "value": "83149.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: RMB 83149, HKD 3149)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CITN"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "83149 HK EQUITY"
                    }
                  ],
                  "ticker": "83149 HK EQUITY",
                  "tickerCcy": "CNY"
                }
              ],
              "tradingLotSize": "200",
              "underlierStockFillDate": "T0",
              "unitsCu": "500000"
            },
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2015-11-25T00:00:00.000Z",
                  "name": "MSCI China A International Net Toal Return Index",
                  "ticker": "M7CNAIR Index",
                  "toDate": "2018-05-02T00:00:00.000Z"
                },
                {
                  "ID": 2,
                  "ccy": "CNY",
                  "fromDate": "2018-05-03T00:00:00.000Z",
                  "name": "MSCI China A Inclusion Net Total Return Index",
                  "ticker": "MBCNA Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00047",
              "classIDName": "HKD",
              "description": "HK-MSCIA",
              "inceptionDate": "2015-11-25T05:30:00+05:30",
              "lastDistribution": {
                "en": "RMB 0.20",
                "tch": "人民幣0.20"
              },
              "lotSize": 0,
              "managementFee": "0.75",
              "marketMakers": {
                "en": [
                  "BNP Paribas Securities (Asia) Limited",
                  "Optiver Trading Hong Kong Limited",
                  "SG Securities (HK) Limited"
                ],
                "tch": [
                  "法國巴黎證券(亞洲)有限公司",
                  "Optiver Trading Hong Kong Limited",
                  "法國興業證券(香港)有限公司"
                ]
              },
              "name": "HK-MSCIA",
              "nameChinese": "",
              "nameCustodian": "",
              "namePMA": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "3149"
                },
                {
                  "type": "BBG",
                  "value": "3149 HK Equity"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000273497"
                },
                {
                  "type": "REUTER",
                  "value": "3149.HK"
                }
              ],
              "tickers": [],
              "tradingLotSize": "200",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodian": {},
          "custodianFundCode": "CITN",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "CN",
            "HK"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000012",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000012",
          "investmentAdvisor": {
            "en": "Investment Advisor11"
          },
          "investmentStrategies": [
            {
              "fromDate": "2015-11-25T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {
            "en": "Representative Sampling",
            "tch": "代表性抽樣策略"
          },
          "investmentStrategyEn": "Representative Strategy",
          "isMultiMarket": false,
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信托"
          },
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 500000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資産管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP MSCI China A Inclusion Index ETF",
          "nameChinese": "",
          "navCalendar": [
            "HK",
            "CN"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "CN"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "CITIC Securities Brokerage (HK) Limited",
              "nameChinese": "中信証券經紀(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD449"
            },
            {
              "name": "Goldman Sachs (Asia) Securities Limited",
              "nameChinese": "高盛(亞洲)證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD461"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "CLSA LIMITED",
              "nameChinese": "中信里昂證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD451"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            }
          ],
          "participatingDealers": {
            "en": [
              "ABN AMRO Clearing Hong Kong Limited",
              "China International Capital Corporation Hong Kong Securities Limited",
              "CITIC Securities Brokerage (HK) Limited",
              "Goldman Sachs (Asia) Securities Limited",
              "Haitong International Securities Company Limited",
              "Nomura International (Hong Kong) Limited",
              "GF Securities (Hong Kong) Brokerage Limited",
              "BNP Paribas SA",
              "China Merchants Securities (HK) Co., Limited",
              "CLSA LIMITED",
              "Guotai Junan Securities (Hong Kong) Limited",
              "Merrill Lynch Far East Limited",
              "UBS Securities Hong Kong Limited",
              "Citigroup Global Markets Asia Limited",
              "Phillip Securities (Hong Kong) Limited",
              "Huatai Financial Holdings (Hong Kong) Limited"
            ],
            "tch": [
              "ABN AMRO Clearing Hong Kong Limited",
              "中國國際金融香港證券有限公司",
              "中信証券經紀(香港)有限公司",
              "高盛(亞洲)證券有限公司",
              "海通國際證券有限公司",
              "野村國際(香港)有限公司",
              "廣發証券(香港)經紀有限公司",
              "法國巴黎銀行全球托管行",
              "招商證券(香港)有限公司",
              "中信里昂證券有限公司",
              "國泰君安證券(香港)有限公司",
              "Merrill Lynch Far East Limited",
              "UBS Securities Hong Kong Limited",
              "花旗環球金融亞洲有限公司",
              "輝立證券(香港)有限公司",
              "華泰金融控股(香港)有限公司"
            ]
          },
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "17:50",
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {
            "en": "HSBC Bank (China) Company Limited"
          },
          "preferredClass": "CLS00048",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-MSCIA"
            },
            {
              "type": "PCF_CODE",
              "value": "CSOPMSCIA"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "INTERETF"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP MSCI China A International ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CITN"
            }
          ],
          "registrar": {
            "en": "HSBC Institutional Trust Services (Asia) Limited",
            "tch": "匯豐機構信託服務（亞洲）有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP MSCI China A International ETF",
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000013",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2015-01-28T05:30:00+05:30",
              "name": "M1CXCU50 Index",
              "ticker": "M1CXCU50 Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "USD",
            "fromDate": "2015-01-28T05:30:00+05:30",
            "name": "M1CXCU50 Index",
            "ticker": "M1CXCU50 Index",
            "toDate": "2099-12-31T05:30:00+05:30"
          },
          "ccy": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2015-01-28T05:30:00+05:30",
                  "name": "M1CXCU50 Index",
                  "ticker": "M1CXCU50 Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00049",
              "classIDName": "USD",
              "description": "CO-T50",
              "inceptionDate": "2015-01-28T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-T50",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "3021"
                },
                {
                  "type": "BBG",
                  "value": "3021 HK Equity"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000233988"
                },
                {
                  "type": "REUTER",
                  "value": "3021.HK"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 12"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Audit Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 23,
              "type": "FR"
            }
          ],
          "fundID": "1000013",
          "instrumentID": "1000013",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 300,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP MSCI T50 ETF",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00049",
          "productType": "EE",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-T50"
            },
            {
              "type": "PCF_CODE",
              "value": "CSOPT50"
            }
          ],
          "registrar": {
            "en": "Hong Kong"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2018-06-29T05:30:00+05:30",
          "type": "EE",
          "updatedAt": "2019-07-26T01:01:00.313+05:30"
        },
        {
          "_id": "1000014",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [
            {
              "ccy": "INR",
              "fromDate": "2016-07-19T05:30:00+05:30",
              "name": "NSE Nifty 50 Index",
              "ticker": "NIFTY Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "INR",
            "fromDate": "2016-07-19T05:30:00+05:30",
            "name": "NSE Nifty 50 Index",
            "ticker": "NIFTY Index",
            "toDate": "2099-12-31T05:30:00+05:30"
          },
          "ccy": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "INR",
                  "fromDate": "2016-07-19T05:30:00+05:30",
                  "name": "NSE Nifty 50 Index",
                  "ticker": "NIFTY Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00050",
              "classIDName": "USD",
              "description": "HK-N50L",
              "inceptionDate": "2016-07-19T05:30:00+05:30",
              "lotSize": 0,
              "name": "HK-N50L",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "7202"
                },
                {
                  "type": "BBG",
                  "value": "7202 HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000299963"
                },
                {
                  "type": "REUTER",
                  "value": "7202.HK"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 13"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Audit Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 24,
              "type": "FR"
            }
          ],
          "fundID": "1000014",
          "instrumentID": "1000014",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "liMultiplier": 2,
          "location": "Hong Kong",
          "lotSize": 100,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP Nifty 50 Daily (2x) Leveraged Product",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00050",
          "productType": "LE",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-N50L"
            },
            {
              "type": "PCF_CODE",
              "value": "NIFTY50_LEV"
            }
          ],
          "registrar": {
            "en": "Hong Kong"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2018-08-08T05:30:00+05:30",
          "type": "LE",
          "updatedAt": "2019-07-26T01:01:00.365+05:30"
        },
        {
          "_id": "1000015",
          "active": "NO",
          "activeStatus": false,
          "applicationFee": {
            "creationFee": 125,
            "redemptionFee": 152
          },
          "assetClassDescription": "",
          "benchmarkIndex": [
            {
              "ccy": "INR",
              "fromDate": "2016-07-19T05:30:00+05:30",
              "name": "NSE Nifty 50 Index",
              "ticker": "NIFTY Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "INR",
            "fromDate": "2016-07-19T05:30:00+05:30",
            "name": "NSE Nifty 50 Index",
            "ticker": "NIFTY Index",
            "toDate": "2099-12-31T05:30:00+05:30"
          },
          "ccy": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "INR",
                  "fromDate": "2016-07-19T05:30:00+05:30",
                  "name": "NSE Nifty 50 Index",
                  "ticker": "NIFTY Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00051",
              "classIDName": "USD",
              "description": "HK-N50I",
              "inceptionDate": "2016-07-19T05:30:00+05:30",
              "lotSize": 0,
              "name": "HK-N50I",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "7335"
                },
                {
                  "type": "BBG",
                  "value": "7335 HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000299971"
                },
                {
                  "type": "REUTER",
                  "value": "7335.HK"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 14"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Setup Expense",
              "rateType": "FIXED_RATE",
              "remainingRate": 25,
              "type": "FR"
            },
            {
              "ccy": "HKD",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 0.09,
              "type": "FR"
            },
            {
              "ccy": "CNY",
              "name": "Index Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 0.045,
              "type": "FR"
            }
          ],
          "fundID": "1000015",
          "instrumentID": "1000015",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "liMultiplier": -1,
          "location": "Hong Kong",
          "lotSize": 100,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP Nifty 50 Daily (-1x) Inverse Product",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00051",
          "productType": "LE",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-N50I"
            },
            {
              "type": "PCF_CODE",
              "value": "NIFTY50_INV"
            }
          ],
          "registrar": {
            "en": "Hong Kong"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2018-08-08T05:30:00+05:30",
          "type": "LE",
          "updatedAt": "2019-07-26T01:01:00.421+05:30"
        },
        {
          "_id": "1000016",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2016-01-13T05:30:00+05:30",
              "name": "Citi Asia Broad Bond Index",
              "ticker": "SBABBI Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "USD",
            "fromDate": "2016-01-13T05:30:00+05:30",
            "name": "Citi Asia Broad Bond Index",
            "ticker": "SBABBI Index",
            "toDate": "2099-12-31T05:30:00+05:30"
          },
          "ccy": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-01-13T05:30:00+05:30",
                  "name": "Citi Asia Broad Bond Index",
                  "ticker": "SBABBI Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00052",
              "classIDName": "CEHKBFA HK",
              "description": "CSOP Select US Dollar Bond Fund - Class A HKD",
              "inceptionDate": "2014-12-01T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-USDB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CEHKBFA"
                },
                {
                  "type": "BBG",
                  "value": "CEHKBFA HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000225901"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-01-13T05:30:00+05:30",
                  "name": "Citi Asia Broad Bond Index",
                  "ticker": "SBABBI Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00053",
              "classIDName": "CEHKBFI HK",
              "description": "CSOP Select US Dollar Bond Fund - Class I HKD",
              "inceptionDate": "2014-12-01T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-USDB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CEHKBFI"
                },
                {
                  "type": "BBG",
                  "value": "CEHKBFI HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000225935"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-01-13T05:30:00+05:30",
                  "name": "Citi Asia Broad Bond Index",
                  "ticker": "SBABBI Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00054",
              "classIDName": "CEHKBUI HK",
              "description": "CSOP Select US Dollar Bond Fund - Class I USD",
              "inceptionDate": "2016-01-13T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-USDB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CEHKBUI"
                },
                {
                  "type": "BBG",
                  "value": "CEHKBUI HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000225950"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-01-13T05:30:00+05:30",
                  "name": "Citi Asia Broad Bond Index",
                  "ticker": "SBABBI Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00055",
              "classIDName": "CEHKBRA HK",
              "description": "CSOP Select US Dollar Bond Fund - Class A CNY",
              "inceptionDate": "2016-02-01T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-USDB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CEHKBRA"
                },
                {
                  "type": "BBG",
                  "value": "CEHKBRA HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000225919"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-01-13T05:30:00+05:30",
                  "name": "Citi Asia Broad Bond Index",
                  "ticker": "SBABBI Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00056",
              "classIDName": "CEHKBUA HK",
              "description": "CSOP Select US Dollar Bond Fund - Class A USD",
              "inceptionDate": "2016-02-01T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-USDB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CEHKBUA"
                },
                {
                  "type": "BBG",
                  "value": "CEHKBUA HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000225927"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-01-13T05:30:00+05:30",
                  "name": "Citi Asia Broad Bond Index",
                  "ticker": "SBABBI Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00057",
              "classIDName": "CEHKBRI HK",
              "description": "CSOP Select US Dollar Bond Fund - Class I CNY",
              "inceptionDate": "2016-02-01T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-USDB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CEHKBRI"
                },
                {
                  "type": "BBG",
                  "value": "CEHKBRI HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000225943"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 15"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Trustee Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 26,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000016",
          "instrumentID": "1000016",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP Select USD Bond Fund",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Xh6Ve_gjM",
          "prcCustodian": {},
          "preferredClass": "CLS00054",
          "productType": "BM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-USDB"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "HK$BOND"
            }
          ],
          "registrar": {
            "en": "Hong Kong"
          },
          "riskfreeIndex": {
            "ccy": "USD",
            "name": "USD 3m Libor Total Return Index",
            "ticker": "BXIIU3MC Index"
          },
          "type": "BM",
          "updatedAt": "2019-07-26T01:01:00.472+05:30"
        },
        {
          "_id": "1000017",
          "active": "NO",
          "activeStatus": false,
          "applicationFee": {
            "creationFee": 5000,
            "redemptionFee": 6000
          },
          "assetClass": "MM",
          "assetClassDescription": "Money Market",
          "ccy": "USD",
          "ccyOps": "AFN",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2015-10-20T05:30:00+05:30",
                  "name": "CSI300 Smart TRI (USD)",
                  "ticker": "CSIH0669 Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00058",
              "classIDName": "USD",
              "description": "US-300AH",
              "inceptionDate": "2015-10-20T05:30:00+05:30",
              "lotSize": 0,
              "name": "US-300AH",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "HAHA"
                },
                {
                  "type": "BBG",
                  "value": "HAHA US Equity"
                },
                {
                  "type": "ISIN",
                  "value": "US12649L3033"
                },
                {
                  "type": "SEDOL",
                  "value": "BYNMHQ5"
                },
                {
                  "type": "REUTER",
                  "value": "HAHA.US"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HKSC"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "entity": "CYM",
          "fees": [
            {
              "ccy": "USD",
              "name": "Audit Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 27,
              "type": "FR"
            }
          ],
          "fundID": "1000017",
          "indexCalendar": [
            "HKSC"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000017",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2023-02-14T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "INGHHG",
          "isMultiMarket": false,
          "legalStructureID": {
            "location": "CN",
            "name": "Hong Kong Unit Trust",
            "nameChinese": "香港单位信托",
            "partyCategory": "LEGAL",
            "partyID": "750",
            "references": []
          },
          "liMultipliers": [],
          "location": "Tokelau",
          "lotSize": 0,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP China CSI 300 A-H Dynamic ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "14:32",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "QUARTERLY",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [],
          "pmaNavFileTime": "13:32",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "preferredClass": "CLS00058",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "US-300AH"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "USCSI300"
            },
            {
              "type": "TRADER_NAME",
              "value": "CUSTO"
            },
            {
              "type": "CUSTODIAN",
              "value": "CUSTO_CODE"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "HSBC Trustee (Cayman) Limited ",
            "partyCategory": "RGTR",
            "partyID": "755",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "13:32",
          "slaType": "T0",
          "terminatedDate": "2018-09-26T00:00:00Z",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "754",
            "references": []
          },
          "type": "ETF",
          "updatedAt": "2019-07-26T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000018",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2015-10-20T05:30:00+05:30",
              "name": "MSCI China A International with CNH 100% Hedged to USD USD Net Total Return Index",
              "ticker": "M1CXCNA Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "USD",
            "fromDate": "2015-10-20T05:30:00+05:30",
            "name": "MSCI China A International with CNH 100% Hedged to USD USD Net Total Return Index",
            "ticker": "M1CXCNA Index",
            "toDate": "2099-12-31T05:30:00+05:30"
          },
          "ccy": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2015-10-20T05:30:00+05:30",
                  "name": "MSCI China A International with CNH 100% Hedged to USD USD Net Total Return Index",
                  "ticker": "M1CXCNA Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00059",
              "classIDName": "USD",
              "description": "US-MSCIH",
              "inceptionDate": "2015-10-20T05:30:00+05:30",
              "lotSize": 0,
              "name": "US-MSCIH",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CNHX"
                },
                {
                  "type": "BBG",
                  "value": "CNHX US Equity"
                },
                {
                  "type": "ISIN",
                  "value": "US12649L2043"
                },
                {
                  "type": "SEDOL",
                  "value": "BYNMHT8"
                },
                {
                  "type": "REUTER",
                  "value": "CNHX.US"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 17"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Audit Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 28,
              "type": "FR"
            }
          ],
          "fundID": "1000018",
          "instrumentID": "1000018",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "United States",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP MSCI China A International Hedged ETF",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00059",
          "productType": "EE",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "US-MSCIH"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "USMSCIA"
            }
          ],
          "registrar": {
            "en": "US"
          },
          "riskfreeIndex": {
            "ccy": "USD",
            "name": "USD 3m Libor Total Return Index",
            "ticker": "BXIIU3MC Index"
          },
          "type": "EE",
          "updatedAt": "2019-07-26T01:01:00.58+05:30"
        },
        {
          "_id": "1000019",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2016-05-11T05:30:00+05:30",
              "name": "ICE BofAML Commodity Index eXtra CLA Index",
              "ticker": "MLCXCLAE Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "USD",
            "fromDate": "2016-05-11T00:00:00Z",
            "name": "ICE BofAML Commodity Index eXtra CLA Index",
            "ticker": "MLCXCLAE Index",
            "toDate": "2099-12-31T00:00:00Z"
          },
          "ccy": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-05-11T05:30:00+05:30",
                  "name": "ICE BofAML Commodity Index eXtra CLA Index",
                  "ticker": "MLCXCLAE Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00060",
              "classIDName": "USD",
              "description": "HK-OIL",
              "inceptionDate": "2016-05-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "HK-OIL",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "3135"
                },
                {
                  "type": "BBG",
                  "value": "3135 HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000293818"
                },
                {
                  "type": "REUTER",
                  "value": "3135.HK"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-09-15T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 18"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000019",
          "instrumentID": "1000019",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP WTI Oil Annual Roll December Futures ER ETF",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00060",
          "productType": "CE",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-OIL"
            },
            {
              "type": "PCF_CODE",
              "value": "CSOPOIL"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "OIL"
            }
          ],
          "registrar": {
            "en": "Hong Kong"
          },
          "riskfreeIndex": {
            "ccy": "USD",
            "name": "USD 3m Libor Total Return Index",
            "ticker": "BXIIU3MC Index"
          },
          "terminatedDate": "2019-08-04T05:30:00+05:30",
          "type": "CE",
          "updatedAt": "2019-11-01T07:27:46.487+05:30"
        },
        {
          "_id": "1000020",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2016-05-02T05:30:00+05:30",
              "name": "NDEUCHF Index",
              "ticker": "NDEUCHF Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "USD",
            "fromDate": "2016-05-02T05:30:00+05:30",
            "name": "NDEUCHF Index",
            "ticker": "NDEUCHF Index",
            "toDate": "2099-12-31T05:30:00+05:30"
          },
          "ccy": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-05-02T05:30:00+05:30",
                  "name": "NDEUCHF Index",
                  "ticker": "NDEUCHF Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00061",
              "classIDName": "I USD (ACC)",
              "description": "CO-FCDG",
              "inceptionDate": "2016-05-02T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-FCDG",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSFNIUA"
                },
                {
                  "type": "BBG",
                  "value": "CSFNIUA ID"
                },
                {
                  "type": "ISIN",
                  "value": "IE00BYVT8J87"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-05-02T05:30:00+05:30",
                  "name": "NDEUCHF Index",
                  "ticker": "NDEUCHF Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00062",
              "classIDName": "I USD (Dist)",
              "description": "CO-FCDG",
              "inceptionDate": "2016-05-03T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-FCDG",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSFNIUD"
                },
                {
                  "type": "BBG",
                  "value": "CSFNIUD ID"
                },
                {
                  "type": "ISIN",
                  "value": "IE00BYVT8L00"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-05-02T05:30:00+05:30",
                  "name": "NDEUCHF Index",
                  "ticker": "NDEUCHF Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00063",
              "classIDName": "A USD (ACC)",
              "description": "CO-FCDG",
              "inceptionDate": "2016-05-03T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-FCDG",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSFNAUA"
                },
                {
                  "type": "BBG",
                  "value": "CSFNAUA ID"
                },
                {
                  "type": "ISIN",
                  "value": "IE00BYVT8N24"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-05-02T05:30:00+05:30",
                  "name": "NDEUCHF Index",
                  "ticker": "NDEUCHF Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00064",
              "classIDName": "A USD (Dist)",
              "description": "CO-FCDG",
              "inceptionDate": "2016-05-03T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-FCDG",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSFNAUD"
                },
                {
                  "type": "BBG",
                  "value": "CSFNAUD ID"
                },
                {
                  "type": "ISIN",
                  "value": "IE00BYVT8R61"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-09-15T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 20"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 29,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000020",
          "instrumentID": "1000020",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Ireland",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP FountainCap New Dragon Dividend \u0026 Growth Fund",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00061",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-FCDG"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "FOUNTAIN"
            }
          ],
          "registrar": {
            "en": "Ireland"
          },
          "riskfreeIndex": {
            "ccy": "USD",
            "name": "USD 3m Libor Total Return Index",
            "ticker": "BXIIU3MC Index"
          },
          "terminatedDate": "2018-08-30T05:30:00+05:30",
          "type": "EM",
          "updatedAt": "2019-07-26T01:01:00.687+05:30"
        },
        {
          "_id": "1000021",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2015-06-19T05:30:00+05:30",
              "name": "NDEUCHF Index",
              "ticker": "NDEUCHF Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "USD",
            "fromDate": "2015-06-19T05:30:00+05:30",
            "name": "NDEUCHF Index",
            "ticker": "NDEUCHF Index",
            "toDate": "2099-12-31T05:30:00+05:30"
          },
          "ccy": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2015-06-19T05:30:00+05:30",
                  "name": "NDEUCHF Index",
                  "ticker": "NDEUCHF Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00065",
              "classIDName": "F",
              "description": "CO-GCPE",
              "inceptionDate": "2015-06-19T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-GCPE",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSDDGCF"
                },
                {
                  "type": "BBG",
                  "value": "CSDDGCF KY"
                },
                {
                  "type": "ISIN",
                  "value": "KYG2116N1667"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 21"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Trustee Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 30,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000021",
          "instrumentID": "1000021",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP DRAGON DYN GTR CHINA PREM EQ FD SP",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00065",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-GCPE"
            }
          ],
          "registrar": {
            "en": "Cayman"
          },
          "riskfreeIndex": {
            "ccy": "USD",
            "name": "USD 3m Libor Total Return Index",
            "ticker": "BXIIU3MC Index"
          },
          "terminatedDate": "2018-12-21T05:30:00+05:30",
          "type": "EM",
          "updatedAt": "2019-07-26T01:01:00.738+05:30"
        },
        {
          "_id": "1000022",
          "active": "YES",
          "activeStatus": true,
          "applicationFee": {
            "creationFee": 4000,
            "dcBuffer": 8,
            "redemptionFee": 4000
          },
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2016-12-08T00:00:00.000Z",
                  "name": "S\u0026P New China Sectors Net Total Return (HKD) Index",
                  "ticker": "SPNCSHN Index",
                  "toDate": "2019-04-07T00:00:00.000Z"
                },
                {
                  "ID": 2,
                  "ccy": "HKD",
                  "fromDate": "2019-04-08T00:00:00.000Z",
                  "name": "S\u0026P New China Sectors (A-shares Capped) Index",
                  "ticker": "SPNCSCHN Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00066",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "HK-SPNC",
              "inceptionDate": "2016-12-08T00:00:00.000Z",
              "lotSize": 100000,
              "managementFee": "0.99",
              "mktPriceValue": "LAST_PRICE",
              "name": "HK-SPNC",
              "nameChinese": "",
              "nameCustodian": "CSPC",
              "namePMA": "HK-SPNC",
              "pcfCode": "CSOPSPNC",
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2016-12-08T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM506",
                      "PartyNameInChinese": "Flow Traders Hong Kong Limited",
                      "PartyNameInEnglish": "Flow Traders Hong Kong Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM520",
                      "PartyNameInChinese": "法國興業證券(香港)有限公司",
                      "PartyNameInEnglish": "SG Securities (HK) Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3167"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000316767"
                    },
                    {
                      "type": "REUTER",
                      "value": "3167.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: HKD 3167, RMB 83167, USD 9167)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CSPC"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "3167 HK EQUITY"
                    }
                  ],
                  "ticker": "3167 HK EQUITY",
                  "tickerCcy": "HKD"
                },
                {
                  "cashComponent": "YES",
                  "listingDate": "2016-12-08T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM506",
                      "PartyNameInChinese": "Flow Traders Hong Kong Limited",
                      "PartyNameInEnglish": "Flow Traders Hong Kong Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM520",
                      "PartyNameInChinese": "法國興業證券(香港)有限公司",
                      "PartyNameInEnglish": "SG Securities (HK) Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3167"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000316767"
                    },
                    {
                      "type": "REUTER",
                      "value": "3167.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: HKD 3167, RMB 83167, USD 9167)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CSPC"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "3167 HK EQUITY"
                    }
                  ],
                  "ticker": "83167 HK EQUITY",
                  "tickerCcy": "CNY"
                },
                {
                  "cashComponent": "YES",
                  "listingDate": "2016-12-08T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM520",
                      "PartyNameInChinese": "法國興業證券(香港)有限公司",
                      "PartyNameInEnglish": "SG Securities (HK) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM506",
                      "PartyNameInChinese": "Flow Traders Hong Kong Limited",
                      "PartyNameInEnglish": "Flow Traders Hong Kong Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3167"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000316767"
                    },
                    {
                      "type": "REUTER",
                      "value": "3167.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: HKD 3167, RMB 83167, USD 9167)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CSPC"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "3167 HK EQUITY"
                    }
                  ],
                  "ticker": "9167 HK EQUITY",
                  "tickerCcy": "USD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "50",
              "underlierStockFillDate": "T0",
              "unitsCu": "100000"
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2016-12-08T05:30:00+05:30",
                  "name": "S\u0026P New China Sectors Net Total Return (HKD) Index",
                  "ticker": "SPNCSHN Index",
                  "toDate": "2019-04-07T05:30:00+05:30"
                },
                {
                  "ccy": "HKD",
                  "fromDate": "2019-04-08T05:30:00+05:30",
                  "name": "S\u0026P New China Sectors (A-shares Capped) Index",
                  "ticker": "SPNCSCHN Index",
                  "toDate": "2099-12-31T10:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00067",
              "classIDName": "RMB",
              "description": "HK-SPNC",
              "inceptionDate": "2018-04-03T05:30:00+05:30",
              "lastDistribution": {
                "en": "HKD 1.01",
                "tch": "港幣 1.01"
              },
              "lotSize": 0,
              "managementFee": "0.99",
              "marketMakers": {
                "en": [
                  "BNP Paribas Securities (Asia) Limited",
                  "Flow Traders Hong Kong Limited"
                ],
                "tch": [
                  "法國巴黎證券(亞洲)有限公司",
                  "Flow Traders Hong Kong Limited"
                ]
              },
              "name": "HK-SPNC",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "83167"
                },
                {
                  "type": "BBG",
                  "value": "83167 HK Equity"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000406931"
                },
                {
                  "type": "REUTER",
                  "value": "83167.HK"
                }
              ],
              "tickers": [],
              "tradingLotSize": "50"
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2016-12-08T05:30:00+05:30",
                  "name": "S\u0026P New China Sectors Net Total Return (HKD) Index",
                  "ticker": "SPNCSHN Index",
                  "toDate": "2019-04-07T05:30:00+05:30"
                },
                {
                  "ccy": "HKD",
                  "fromDate": "2019-04-08T05:30:00+05:30",
                  "name": "S\u0026P New China Sectors (A-shares Capped) Index",
                  "ticker": "SPNCSCHN Index",
                  "toDate": "2099-12-31T10:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00068",
              "classIDName": "USD",
              "description": "HK-SPNC",
              "inceptionDate": "2018-04-03T05:30:00+05:30",
              "lastDistribution": {
                "en": "HKD 1.01",
                "tch": "港幣 1.01"
              },
              "lotSize": 0,
              "managementFee": "0.99",
              "marketMakers": {
                "en": [
                  "BNP Paribas Securities (Asia) Limited",
                  "Flow Traders Hong Kong Limited"
                ],
                "tch": [
                  "法國巴黎證券(亞洲)有限公司",
                  "Flow Traders Hong Kong Limited"
                ]
              },
              "name": "HK-SPNC",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "9167"
                },
                {
                  "type": "BBG",
                  "value": "9167 HK Equity"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000406949"
                },
                {
                  "type": "REUTER",
                  "value": "9167.HK"
                }
              ],
              "tickers": [],
              "tradingLotSize": "50"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-09-15T00:00:00Z",
          "custodianFundCode": "CSPC",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "CN",
            "HK",
            "US"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000022",
          "indexCalendar": [
            "CN",
            "HK",
            "US"
          ],
          "indexCondition": "AND",
          "indexSla": "T1",
          "instrumentID": "1000022",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2016-12-08T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Full Replication Strategy",
          "isMultiMarket": true,
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 100000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "ICBC CSOP S\u0026P NEW CHINA SECTORS ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "15:50",
          "nav2Recon": "NO",
          "navCalendar": [
            "CN",
            "HK",
            "US"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "CLSA LIMITED",
              "nameChinese": "中信里昂證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD451"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "Goldman Sachs (Asia) Securities Limited",
              "nameChinese": "高盛(亞洲)證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD461"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Masterlink Securities (Hong Kong) Corporation Limited",
              "nameChinese": "元富證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD475"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "07:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00066",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-SPNC"
            },
            {
              "type": "PCF_CODE",
              "value": "CSOPSPNC"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "S\u0026P"
            },
            {
              "type": "TRADER_NAME",
              "value": " S\u0026P NEW CHINA SECTORS ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSPC"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "skipCutOffCheck": false,
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "tradarFundLongName": " S\u0026P NEW CHINA SECTORS ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000023",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2012-08-27T00:00:00.000Z",
                  "name": "FTSE China A50 Net Total Return Index",
                  "ticker": "XINA50NC Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00069",
              "classIDName": "RMB",
              "classType": "LISTED",
              "description": "CO-A50F",
              "inceptionDate": "2012-08-27T00:00:00.000Z",
              "lotSize": 500000,
              "managementFee": "0.99",
              "mktPriceValue": "LAST_PRICE",
              "name": "RMB Share Class A",
              "nameChinese": "人民幣A股",
              "nameCustodian": "CA50",
              "namePMA": "RMB Share Class A",
              "pcfCode": "CSOPA50",
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2012-08-28T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "82822"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000112307"
                    },
                    {
                      "type": "SEDOL",
                      "value": "B825VV6"
                    },
                    {
                      "type": "REUTER",
                      "value": "82822.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: RMB 82822, HKD 2822)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CA50"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "82822 HK EQUITY"
                    }
                  ],
                  "ticker": "82822 HK EQUITY",
                  "tickerCcy": "CNY"
                },
                {
                  "listingDate": "2012-11-08T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000112307"
                    }
                  ],
                  "ticker": "2822 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "200",
              "underlierStockFillDate": "T2",
              "unitsCu": "500000"
            }
          ],
          "companyID": "CSOP",
          "custodianFundCode": "CA50",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "CN",
            "HK"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": "YEAR",
          "entity": "HK",
          "exchangeListingID": {
            "country": "Afghanistan",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "EL804",
            "references": []
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000023",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000023",
          "investmentAdvisorID": {
            "location": "CH",
            "name": "Investment Advisor",
            "nameChinese": "華盛資本証券有限公司",
            "partyCategory": "INVA",
            "partyID": "IA891",
            "references": []
          },
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2012-08-27T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategyID": "934",
          "isMultiMarket": false,
          "legalStructureID": {
            "location": "CN",
            "name": "Hong Kong Unit Trust",
            "nameChinese": "香港单位信托",
            "partyCategory": "LEGAL",
            "partyID": "750",
            "references": []
          },
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 500000,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "FTSE China A50 ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "14:30",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "06:30",
          "navCalendar": [
            "HK",
            "CN"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            },
            {
              "name": "BOCI Securities Limited",
              "nameChinese": "中銀國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD438"
            },
            {
              "name": "China Galaxy International Securities (Hong Kong) Co., Limited",
              "nameChinese": "中國銀河國際證券(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD442"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "CIMB Securities Limited",
              "nameChinese": "聯昌證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD447"
            },
            {
              "name": "CITIC Securities Brokerage (HK) Limited",
              "nameChinese": "中信証券經紀(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD449"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Credit Suisse Securities (Hong Kong) Limited",
              "nameChinese": "瑞士信貸證券(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD454"
            },
            {
              "name": "Deutsche Securities Asia Limited",
              "nameChinese": "德意志證券亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD456"
            },
            {
              "name": "Essence International Securities (Hong Kong) Limited",
              "nameChinese": "安信國際證券(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD457"
            },
            {
              "name": "Goldman Sachs (Asia) Securities Limited",
              "nameChinese": "高盛(亞洲)證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD461"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "KGI Asia Limited",
              "nameChinese": "凱基證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD471"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Morgan Stanley Hong Kong Securities Limited",
              "nameChinese": "Morgan Stanley Hong Kong Securities Limited",
              "partyCategory": "PD",
              "partyID": "PD479"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "Oriental Patron Securities Limited",
              "nameChinese": "東英亞洲證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD481"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "The Hong Kong and Shanghai Banking Corporation Limited",
              "nameChinese": "香港上海滙豐銀行有限公司",
              "partyCategory": "PD",
              "partyID": "PD487"
            },
            {
              "name": "Yuanta Securities (Hong Kong) Company Limited",
              "nameChinese": "元大證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD493"
            },
            {
              "name": "CLSA LIMITED",
              "nameChinese": "中信里昂證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD451"
            },
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Korea Investment \u0026 Securities Asia Limited",
              "nameChinese": "韓國投資證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD472"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Zhongtai International Securities Limited",
              "nameChinese": "中泰國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD494"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "09:52",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "prcCustodianID": {
            "location": "AF",
            "name": "HSBC Bank (China) Company Limited",
            "nameChinese": "匯豐銀行(中國)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU525",
            "references": [],
            "versionNo": 2
          },
          "preferredClass": "CLS00069",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-A50F"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "A50ETF"
            },
            {
              "type": "PMA_CUST",
              "value": "CTG"
            },
            {
              "type": "TRADER_NAME",
              "value": "FTSE China A50 ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CA50"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "tradarFundLongName": "FTSE China A50 ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000024",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2017-03-13T00:00:00.000Z",
                  "name": "Hong Kong Stock Exchange Hang Seng China Enterprises Index",
                  "ticker": "HSCEI Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00071",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "HK-HCE-2I",
              "inceptionDate": "2017-03-14T00:00:00.000Z",
              "lotSize": 800000,
              "managementFee": "1.50",
              "mktPriceValue": "LAST_PRICE",
              "name": "HKD Share Class A",
              "nameChinese": "港元 A 類股份",
              "nameCustodian": "CHS4",
              "namePMA": "HKD Share Class A",
              "pcfCode": "HSCEI_2INV",
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2017-03-14T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000330172"
                    },
                    {
                      "type": "EXCH",
                      "value": "7588"
                    },
                    {
                      "type": "REUTER",
                      "value": "7588.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7588.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CHS4"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7588 HK EQUITY"
                    }
                  ],
                  "ticker": "7588 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianFundCode": "CHS4",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000024",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000024",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2017-03-13T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategyID": "934",
          "isMultiMarket": false,
          "liMultiplier": -2,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2017-03-13T00:00:00.000Z",
              "liMultiplier": -1,
              "toDate": "2019-06-26T00:00:00.000Z"
            },
            {
              "ID": 2,
              "fromDate": "2019-06-26T00:00:00.000Z",
              "liMultiplier": -2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Hang Seng China Enterprises Index Daily (-2x) Inverse Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "17:30",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "15:30",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "BOCI Securities Limited",
              "nameChinese": "中銀國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD438"
            },
            {
              "name": "KGI Asia Limited",
              "nameChinese": "凱基證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD471"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Korea Investment \u0026 Securities Asia Limited",
              "nameChinese": "韓國投資證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD472"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "09:43",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao ",
            "nameChinese": "科特斯瓦拉朗",
            "partyCategory": "PMGR",
            "partyID": "757",
            "references": []
          },
          "preferredClass": "CLS00071",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-HCE-2I"
            },
            {
              "type": "TRADER_NAME",
              "value": "Hang Seng China Enterprises Index -2x Inverse"
            },
            {
              "type": "CUSTODIAN",
              "value": "CHS4"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "Hang Seng China Enterprises Index -2x Inverse",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "LI",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000025",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equities",
          "benchmarkIndex": [
            {
              "ccy": "HKD",
              "fromDate": "2017-03-13T05:30:00+05:30",
              "name": "Hong Kong Stock Exchange Hang Seng China Enterprises Index",
              "ticker": "HSCEI Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "HKD",
            "fromDate": "2017-03-13T00:00:00Z",
            "name": "Hong Kong Stock Exchange Hang Seng China Enterprises Index",
            "ticker": "HSCEI Index",
            "toDate": "2099-12-31T00:00:00Z"
          },
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2017-03-13T00:00:00.000Z",
                  "name": "Hong Kong Stock Exchange Hang Seng China Enterprises Index",
                  "ticker": "HSCEI Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00072",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "HK-HCE-L",
              "inceptionDate": "2017-03-14T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 800000,
              "managementFee": "0.99",
              "marketMakers": {
                "en": [
                  "Flow Traders Hong Kong Limited",
                  "Haitong International Securities Company Limited",
                  "IMC Asia Pacific Limited",
                  "Optiver Trading Hong Kong Limited",
                  "Virtu Financial Singapore PTE. Ltd",
                  "Korea Investment \u0026 Securities Asia Limited",
                  "Vivienne Court Trading Pty. Ltd."
                ],
                "tch": [
                  "Flow Traders Hong Kong Limited",
                  "海通國際證券有限公司",
                  "IMC Asia Pacific Limited",
                  "Optiver Trading Hong Kong Limited",
                  "Virtu Financial Singapore PTE. Ltd",
                  "韓國投資證券亞洲有限公司",
                  "Vivienne Court Trading Pty. Ltd."
                ]
              },
              "mktPriceValue": "LAST_PRICE",
              "name": "HK-HCE-L",
              "nameChinese": "",
              "nameCustodian": "CHS3",
              "namePMA": "HKD Share Class A",
              "pcf_code": "jkjk",
              "references": [
                {
                  "type": "EXCH",
                  "value": "7288"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000330164"
                },
                {
                  "type": "REUTER",
                  "value": "7288.HK"
                },
                {
                  "type": "8IGHT",
                  "value": "7288.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CHS3"
                },
                {
                  "type": "PMA",
                  "value": "HKD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "7288 HK EQUITY"
                }
              ],
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "deListingDate": "2023-01-12T00:00:00.000Z",
                  "listingDate": "2023-01-10T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "name": "Haitong International Securities Company Limited",
                      "nameChinese": "海通國際證券有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM508"
                    },
                    {
                      "name": "IMC Asia Pacific Limited",
                      "nameChinese": "IMC Asia Pacific Limited",
                      "partyCategory": "MM",
                      "partyID": "MM511"
                    },
                    {
                      "name": "Optiver Trading Hong Kong Limited",
                      "nameChinese": "Optiver Trading Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM518"
                    },
                    {
                      "name": "Virtu Financial Singapore PTE. Ltd",
                      "nameChinese": "Virtu Financial Singapore PTE. Ltd",
                      "partyCategory": "MM",
                      "partyID": "MM522"
                    },
                    {
                      "name": "Korea Investment \u0026 Securities Asia Limited",
                      "nameChinese": "韓國投資證券亞洲有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM516"
                    },
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    },
                    {
                      "name": "BNP Paribas Securities (Asia) Limited",
                      "nameChinese": "法國巴黎證券(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM498"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyID": "MM506",
                      "PartyNameInChinese": "Flow Traders Hong Kong Limited",
                      "PartyNameInEnglish": "Flow Traders Hong Kong Limited",
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "PartyID": "MM508",
                      "PartyNameInChinese": "海通國際證券有限公司",
                      "PartyNameInEnglish": "Haitong International Securities Company Limited",
                      "name": "Haitong International Securities Company Limited",
                      "nameChinese": "海通國際證券有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM508"
                    },
                    {
                      "PartyID": "MM511",
                      "PartyNameInChinese": "IMC Asia Pacific Limited",
                      "PartyNameInEnglish": "IMC Asia Pacific Limited",
                      "name": "IMC Asia Pacific Limited",
                      "nameChinese": "IMC Asia Pacific Limited",
                      "partyCategory": "MM",
                      "partyID": "MM511"
                    },
                    {
                      "PartyID": "MM518",
                      "PartyNameInChinese": "Optiver Trading Hong Kong Limited",
                      "PartyNameInEnglish": "Optiver Trading Hong Kong Limited",
                      "name": "Optiver Trading Hong Kong Limited",
                      "nameChinese": "Optiver Trading Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM518"
                    },
                    {
                      "PartyID": "MM522",
                      "PartyNameInChinese": "Virtu Financial Singapore PTE. Ltd",
                      "PartyNameInEnglish": "Virtu Financial Singapore PTE. Ltd",
                      "name": "Virtu Financial Singapore PTE. Ltd",
                      "nameChinese": "Virtu Financial Singapore PTE. Ltd",
                      "partyCategory": "MM",
                      "partyID": "MM522"
                    },
                    {
                      "PartyID": "MM516",
                      "PartyNameInChinese": "韓國投資證券亞洲有限公司",
                      "PartyNameInEnglish": "Korea Investment \u0026 Securities Asia Limited",
                      "name": "Korea Investment \u0026 Securities Asia Limited",
                      "nameChinese": "韓國投資證券亞洲有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM516"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7288"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000330164"
                    },
                    {
                      "type": "REUTER",
                      "value": "7288.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7288.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CHS3"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7288 HK EQUITY"
                    }
                  ],
                  "ticker": "7288 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodian": {},
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000025",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000025",
          "investmentAdvisor": {},
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2017-03-13T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {
            "en": "Futures-based Replication Strategy",
            "tch": "以期貨為基礎的模擬投資策略"
          },
          "investmentStrategyEn": "Futures-based Replication Strategy",
          "isMultiMarket": false,
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信託"
          },
          "liMultiplier": 2,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2017-03-13T00:00:00.000Z",
              "liMultiplier": 2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資産管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP Hang Seng China Enterprises Index Daily (2x) Leveraged Product",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "CN"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "BOCI Securities Limited",
              "nameChinese": "中銀國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD438"
            },
            {
              "name": "KGI Asia Limited",
              "nameChinese": "凱基證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD471"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Korea Investment \u0026 Securities Asia Limited",
              "nameChinese": "韓國投資證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD472"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "participatingDealers": {
            "en": [
              "ABN AMRO Clearing Hong Kong Limited",
              "Guotai Junan Securities (Hong Kong) Limited",
              "J.P. Morgan Broking (Hong Kong) Limited",
              "UBS Securities Hong Kong Limited",
              "Nomura International (Hong Kong) Limited",
              "Merrill Lynch Far East Limited",
              "Mirae Asset Securities (HK) Limited",
              "China International Capital Corporation Hong Kong Securities Limited",
              "GF Securities (Hong Kong) Brokerage Limited",
              "BOCI Securities Limited",
              "BNP Paribas SA",
              "KGI Asia Limited",
              "Haitong International Securities Company Limited",
              "Citigroup Global Markets Asia Limited",
              "Phillip Securities (Hong Kong) Limited",
              "Korea Investment \u0026 Securities Asia Limited ",
              "Huatai Financial Holdings (Hong Kong) Limited",
              "China Merchants Securities (HK) Co., Limited",
              "Barclays Bank PLC"
            ],
            "tch": [
              "ABN AMRO Clearing Hong Kong Limited",
              "國泰君安證券(香港)有限公司",
              "J.P. Morgan Broking (Hong Kong) Limited",
              "UBS Securities Hong Kong Limited",
              "野村國際(香港)有限公司",
              "Merrill Lynch Far East Limited",
              "未來資產證券(香港)有限公司",
              "中國國際金融香港證券有限公司",
              "廣發証券(香港)經紀有限公司",
              "中銀國際證券有限公司",
              "法國巴黎銀行全球托管行",
              "凱基證券亞洲有限公司",
              "海通國際證券有限公司",
              "花旗環球金融亞洲有限公司",
              "輝立證券(香港)有限公司",
              "韓國投資證券亞洲有限公司",
              "華泰金融控股(香港)有限公司",
              "招商證券(香港)有限公司",
              "Barclays Bank PLC"
            ]
          },
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "18:00",
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {
            "tch": ""
          },
          "prcCustodianID": null,
          "preferredClass": "CLS00072",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-HCE-L"
            },
            {
              "type": "PCF_CODE",
              "value": "HSCEI_LEV"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "HSCEILEV"
            },
            {
              "type": "TRADER_NAME",
              "value": "Hang Seng China Enterprises Index 2x Leveraged"
            },
            {
              "type": "CUSTODIAN",
              "value": "CHS3"
            }
          ],
          "registrar": {
            "en": "HSBC Institutional Trust Services (Asia) Limited",
            "tch": "匯豐機構信託服務（亞洲）有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "type": "LI",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000026",
          "active": "NO",
          "activeStatus": false,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2017-03-13T00:00:00.000Z",
                  "name": "Hong Kong Hang Seng Index",
                  "ticker": "HSI Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00073",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "HK-HSI-I",
              "inceptionDate": "2017-03-14T00:00:00.000Z",
              "lotSize": 800000,
              "managementFee": "0.99",
              "mktPriceValue": null,
              "name": "HK-HSI-I",
              "nameChinese": "",
              "nameCustodian": "CHS2",
              "namePMA": "HKD Share Class A",
              "pcfCode": "gjg",
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "deListingDate": "2023-02-22T00:00:00.000Z",
                  "listingDate": "2023-02-21T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Management (Hong Kong) Limited",
                      "nameChinese": "安联资本管理（香港）有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM905",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    },
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    },
                    {
                      "name": "BNP Paribas Securities (Asia) Limited",
                      "nameChinese": "法國巴黎證券(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM498"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM905",
                      "PartyNameInChinese": "安联资本管理（香港）有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7300 HK"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000330156"
                    },
                    {
                      "type": "REUTER",
                      "value": "7300.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7300.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CHS2"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7300 HK EQUITY"
                    }
                  ],
                  "ticker": "7300 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianFundCode": "CHS2",
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000026",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000026",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2017-03-13T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "FHG",
          "isMultiMarket": false,
          "liMultiplier": -1,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2017-03-13T00:00:00.000Z",
              "liMultiplier": -1,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Hang Seng Index Daily (-1x) Inverse Product",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "CN"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "BOCI Securities Limited",
              "nameChinese": "中銀國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD438"
            },
            {
              "name": "KGI Asia Limited",
              "nameChinese": "凱基證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD471"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Korea Investment \u0026 Securities Asia Limited",
              "nameChinese": "韓國投資證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD472"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "18:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00073",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-HSI-I"
            },
            {
              "type": "PCF_CODE",
              "value": "HSI_INV"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "HSIINVER"
            },
            {
              "type": "TRADER_NAME",
              "value": "Hang Seng Index -1x Inverse"
            },
            {
              "type": "CUSTODIAN",
              "value": "CHS2"
            }
          ],
          "registrarID": {
            "location": "SG",
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中银国际英国保诚信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG902",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "terminatedDate": "2023-02-22T00:00:00Z",
          "tradarFundLongName": "Hang Seng Index -1x Inverse",
          "trusteeID": {
            "location": "HK",
            "name": "cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS909",
            "references": []
          },
          "type": "LI",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000027",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2017-03-13T00:00:00.000Z",
                  "name": "Hong Kong Hang Seng Index",
                  "ticker": "HSI Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00074",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "HK-HSI-L",
              "inceptionDate": "2017-03-14T00:00:00.000Z",
              "lotSize": 800000,
              "managementFee": "0.99",
              "mktPriceValue": "LAST_PRICE",
              "name": "HKD Share Class A",
              "nameChinese": "港元 A 類股份",
              "nameCustodian": "CHS1",
              "namePMA": "HKD Share Class A",
              "pcfCode": "HSI_LEV",
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2017-03-14T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7200 HK"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000330149"
                    },
                    {
                      "type": "REUTER",
                      "value": "7200.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7200.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CHS1"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7200 HK EQUITY"
                    }
                  ],
                  "ticker": "7200 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianFundCode": "CHS1",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "YEAR",
          "entity": "HK",
          "exchangeListingID": {
            "country": "Afghanistan",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "EL804",
            "references": []
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000027",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000027",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2017-03-13T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Futures-based replication strategy and swap-based synthetic replication strategy",
          "isMultiMarket": false,
          "legalStructureID": {
            "location": "CN",
            "name": "Hong Kong Unit Trust",
            "nameChinese": "香港单位信托",
            "partyCategory": "LEGAL",
            "partyID": "750",
            "references": []
          },
          "liMultiplier": 2,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2017-03-13T00:00:00.000Z",
              "liMultiplier": 2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "CSOP Hang Seng Index Daily (2x) Leveraged Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "18:00",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "BOCI Securities Limited",
              "nameChinese": "中銀國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD438"
            },
            {
              "name": "KGI Asia Limited",
              "nameChinese": "凱基證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD471"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Korea Investment \u0026 Securities Asia Limited",
              "nameChinese": "韓國投資證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD472"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "18:00",
          "portfolioManagerID": {
            "country": "China",
            "name": "Portfolio Manager",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "PMGR",
            "partyID": "784",
            "references": []
          },
          "preferredClass": "CLS00074",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-HSI-L"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "HSILEVER"
            },
            {
              "type": "PMA_CUST",
              "value": "HSBC_HK"
            },
            {
              "type": "TRADER_NAME",
              "value": "Hang Seng Index 2x Leveraged"
            },
            {
              "type": "CUSTODIAN",
              "value": "CHS1"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "tradarFundLongName": "Hang Seng Index 2x Leveraged",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "LI",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000028",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2016-02-12T05:30:00+05:30",
              "name": "NDEUCHF Index",
              "ticker": "NDEUCHF Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "benchmarkIndex_copy": {
            "ccy": "USD",
            "fromDate": "2016-02-12T00:00:00Z",
            "name": "NDEUCHF Index",
            "ticker": "NDEUCHF Index",
            "toDate": "2099-12-31T00:00:00Z"
          },
          "ccy": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-02-12T05:30:00+05:30",
                  "name": "NDEUCHF Index",
                  "ticker": "NDEUCHF Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00075",
              "classIDName": "Class A - USD",
              "description": "CO-SIMP",
              "inceptionDate": "2016-02-12T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-SIMP",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSOSCAU KY EQUITY"
                },
                {
                  "type": "BBG",
                  "value": "CSOSCAU KY EQUITY"
                },
                {
                  "type": "ISIN",
                  "value": "KYG2587G1047"
                }
              ],
              "tickers": []
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2016-02-12T05:30:00+05:30",
                  "name": "NDEUCHF Index",
                  "ticker": "NDEUCHF Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00076",
              "classIDName": "Class B - USD",
              "description": "CO-SIMP",
              "inceptionDate": "2016-03-31T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-SIMP",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSOSCAU KY EQUITY"
                },
                {
                  "type": "BBG",
                  "value": "CSOSCBU KY EQUITY"
                },
                {
                  "type": "ISIN",
                  "value": "KYG2587G1120"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 26"
          },
          "custodianFundCode": "",
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 37,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000028",
          "instrumentID": "1000028",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "Simpleway China Value",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00075",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-SIMP"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "SIMPLE1"
            }
          ],
          "registrar": {
            "en": "Cayman"
          },
          "riskfreeIndex": {
            "ccy": "USD",
            "name": "USD 3m Libor Total Return Index",
            "ticker": "BXIIU3MC Index"
          },
          "terminatedDate": "2021-08-31T05:30:00+05:30",
          "tradarFundLongName": "",
          "type": "EM",
          "updatedAt": "2022-09-12T12:57:02.821+05:30"
        },
        {
          "_id": "1000029",
          "active": "YES",
          "activeStatus": true,
          "applicationFee": {
            "creationFee": null,
            "dcBuffer": 2,
            "redemptionFee": null
          },
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2018-07-17T00:00:00.000Z",
                  "name": "MMF Index",
                  "ticker": "MMF Index",
                  "toDate": "2021-06-11T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00173",
              "classIDName": "Unlisted share class A",
              "classType": "UNLISTED",
              "description": "CO-MMF",
              "inceptionDate": "2019-08-02T00:00:00.000Z",
              "lotSize": 1000,
              "mktPriceValue": "LAST_PRICE",
              "name": "HKD Share Class unlisted A",
              "nameChinese": "",
              "nameCustodian": "CMM2",
              "namePMA": "HKD Share Class unlisted A",
              "pcfCode": "CSOPMMF",
              "shareClassAcctCode": "zzzUConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "listingDate": "2018-07-19T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Investment Limited",
                      "nameChinese": "安联资本投资有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM904",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000426384"
                    }
                  ],
                  "ticker": "3053UA HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            },
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2018-07-17T00:00:00.000Z",
                  "name": "MMF Index",
                  "ticker": "MMF Index",
                  "toDate": "2021-06-11T00:00:00.000Z"
                },
                {
                  "ID": 2,
                  "ccy": "HKD",
                  "fromDate": "2021-06-14T00:00:00.000Z",
                  "name": "HSCIUT Index",
                  "ticker": "HSCIUT Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00077",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "CO-MMF",
              "inceptionDate": "2018-07-19T00:00:00.000Z",
              "lotSize": 1000,
              "managementFee": "0.3",
              "mktPriceValue": "LAST_PRICE",
              "name": "HKD Share Class A",
              "nameCh": "港幣",
              "nameChinese": "港元 A 類股份",
              "nameCustodian": "CMM1",
              "namePMA": "HKD Share Class A",
              "pcfCode": "CSOPMMF",
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "NO",
                  "listingDate": "2018-07-19T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "Haitong International Securities Company Limited",
                      "nameChinese": "海通國際證券有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM508"
                    }
                  ],
                  "mktPriceValue": "",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM508",
                      "PartyNameInChinese": "海通國際證券有限公司",
                      "PartyNameInEnglish": "Haitong International Securities Company Limited"
                    }
                  ],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3053 HK"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000426384"
                    },
                    {
                      "type": "REUTER",
                      "value": "3053.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3053.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CMM1"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "3053 HK EQUITY"
                    }
                  ],
                  "ticker": "3053 HK EQUITY",
                  "tickerCcy": "HKD"
                },
                {
                  "listingDate": "2018-07-18T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Investment Limited",
                      "nameChinese": "安联资本投资有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM904",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000426384"
                    }
                  ],
                  "ticker": "83053 HK EQUITY",
                  "tickerCcy": "CNY"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "1000",
              "underlierStockFillDate": "T0",
              "unitsCu": "1000"
            },
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2018-07-17T00:00:00.000Z",
                  "name": "MMF Index",
                  "ticker": "MMF Index",
                  "toDate": "2021-06-11T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "HKD",
              "classID": "CLS00197",
              "classIDName": "Unlisted share class P",
              "classType": "UNLISTED",
              "description": "Unlisted share class P",
              "inceptionDate": "2020-07-07T00:00:00.000Z",
              "lotSize": 0,
              "mktPriceValue": "LAST_PRICE",
              "name": "HKD Share Class P unlisted",
              "nameChinese": "",
              "nameCustodian": "CMMP",
              "namePMA": "HKD Share Class P unlisted",
              "pcfCode": "CSOPMMF",
              "shareClassAcctCode": "zzzPConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "listingDate": "2020-07-18T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Investment Limited",
                      "nameChinese": "安联资本投资有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM904",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "8IGHT",
                      "value": "3053UP"
                    }
                  ],
                  "ticker": "3053UP HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianFundCode": "CMMF",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000029",
          "fundNote": "Static Note",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000029",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2018-07-18T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2021-06-11T00:00:00.000Z"
            },
            {
              "ID": 2,
              "fromDate": "2021-06-15T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Passive Tracking Strategy",
          "isMultiMarket": false,
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 1000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Hong Kong Dollar Money Market ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "11:41",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "09:39",
          "navCalendar": [
            "CN"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "BE",
          "participatingDealerIDs": [
            {
              "name": "BOCOM International Securities Limited",
              "nameChinese": "交銀國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD439"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "KGI Asia Limited",
              "nameChinese": "凱基證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD471"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "CMBC Securities Company Limited",
              "nameChinese": "民銀證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD453"
            },
            {
              "name": "China Securities (International) Brokerage Company Limited",
              "nameChinese": "中信建投(國際)証券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD446"
            },
            {
              "name": "Guoyuan Securities Brokerage (Hong Kong) Limited",
              "nameChinese": "國元証券經紀(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD463"
            },
            {
              "name": "Zhongtai International Securities Limited",
              "nameChinese": "中泰國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD494"
            },
            {
              "name": "ICBC International Securities Limited",
              "nameChinese": "工銀國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD467"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "CLSA LIMITED",
              "nameChinese": "中信里昂證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD451"
            },
            {
              "name": "China Industrial Securities International Brokerage Limited",
              "nameChinese": "興證國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD443"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "Taiping Securities (HK) Co Limited",
              "nameChinese": "太平証券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD486"
            },
            {
              "name": "Valuable Capital Limited",
              "nameChinese": "華盛資本証券有限公司",
              "partyCategory": "PD",
              "partyID": "PD492"
            },
            {
              "name": "Credit Suisse Securities (Hong Kong) Limited",
              "nameChinese": "瑞士信貸證券(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD454"
            },
            {
              "name": "Shanxi Securities International Limited",
              "nameChinese": "山證國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD485"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "CMB International Securities Limited",
              "nameChinese": "招銀國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD452"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Cinda International Securities Limited",
              "nameChinese": "信達國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD448"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "10:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00077",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-MMF"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "MONEY"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Hong Kong Dollar Money Market ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CMMF"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "skipCutOffCheck": false,
          "slaSignOffTime": "12:45",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "tradarFundLongName": "CSOP Hong Kong Dollar Money Market ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000030",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00079",
              "description": "CO-IPO",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-IPO",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR ID"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 28"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "CNY",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 39,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000030",
          "instrumentID": "1000030",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "NO",
          "manager": {},
          "maturityAllocations": [],
          "name": "IPO Fund",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00079",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-IPO"
            }
          ],
          "registrar": {
            "en": "Hong Kong"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2015-12-29T05:30:00+05:30",
          "type": "EM",
          "updatedAt": "2019-07-26T01:01:01.233+05:30"
        },
        {
          "_id": "1000031",
          "active": "NO",
          "activeStatus": false,
          "assetClass": "DERV",
          "assetClassDescription": "Derivative",
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00080",
              "description": "RQFII-SZHG\n",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "RQFII-SZHG",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR ID"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T00:00:00Z",
          "custodianFundCode": "",
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "dealingCalendar": [
            "HKSC"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "estAnnualTD": "23",
          "exchangeListingID": null,
          "fees": [
            {
              "ccy": "USD",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 40,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000031",
          "indexAvailableSla": "T0",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000031",
          "investmentAdvisorID": null,
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2023-02-20T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "45",
          "isMultiMarket": false,
          "legalStructureID": null,
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "NO",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "CSOP Select RMB Bond Fund",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "participatingDealerIDs": [],
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "prcCustodianID": null,
          "preferredClass": "CLS00080",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "RQFII-SZHG"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "HSBC Trustee (Cayman) Limited ",
            "partyCategory": "RGTR",
            "partyID": "755",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {},
          "terminatedDate": "2019-05-10T00:00:00Z",
          "tradarFundLongName": "",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "754",
            "references": []
          },
          "type": "ETF",
          "updatedAt": "2019-07-26T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000032",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "HKD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00081",
              "description": "CO-HKBF",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-HKBF",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR ID"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-09-15T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 30"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 41,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000032",
          "instrumentID": "1000032",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "NO",
          "manager": {},
          "maturityAllocations": [],
          "name": "Hong Kong Bond Fund",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00081",
          "productType": "BM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-HKBF"
            }
          ],
          "registrar": {
            "en": "Hong Kong"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2015-12-01T05:30:00+05:30",
          "type": "BM",
          "updatedAt": "2019-07-26T01:01:01.338+05:30"
        },
        {
          "_id": "1000033",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "CNY",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00082",
              "classIDName": "CODDCSB KY",
              "description": "CO-CSBF",
              "inceptionDate": "2014-11-26T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CO-CSBF",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR"
                },
                {
                  "type": "BBG",
                  "value": "CODDCSB KY Equity"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 31"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 43,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000033",
          "instrumentID": "1000033",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CSOP Dragon Dynamic China Select Balanced Fund",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00082",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CSBF"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "SPC2"
            }
          ],
          "registrar": {
            "en": "Cayman"
          },
          "riskfreeIndex": {},
          "type": "EM",
          "updatedAt": "2019-07-26T01:01:01.388+05:30"
        },
        {
          "_id": "1000034",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "CNY",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00083",
              "classIDName": "CNY",
              "description": "CO-ICBC",
              "inceptionDate": "2017-06-15T21:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "managementFee": "",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CO-ICBC",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR ID"
                }
              ],
              "tickers": [],
              "tradingLotSize": "0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 34"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 54,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000034",
          "instrumentID": "1000034",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "China",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "ICBC Advisory Account",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00083",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-ICBC"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "ICBCA"
            }
          ],
          "registrar": {
            "en": "China"
          },
          "riskfreeIndex": {},
          "type": "EM",
          "updatedAt": "2019-11-15T06:43:55.273+05:30"
        },
        {
          "_id": "1000035",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00084",
              "description": "CO-MFOF",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-MFOF",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR ID"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 45"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Trustee Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 65,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000035",
          "instrumentID": "1000035",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "Multi Access Fund of Funds SP",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00084",
          "productType": "BM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-MFOF"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "SP6"
            }
          ],
          "registrar": {
            "en": "Cayman"
          },
          "riskfreeIndex": {},
          "type": "BM",
          "updatedAt": "2019-07-26T01:01:01.491+05:30"
        },
        {
          "_id": "1000036",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "CNY",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00085",
              "description": "CO-CABF",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-CABF",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR ID"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 67"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 76,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000036",
          "instrumentID": "1000036",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "China Access Bond Fund SP7",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00085",
          "productType": "BM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CABF"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "SP7"
            }
          ],
          "registrar": {
            "en": "Cayman"
          },
          "riskfreeIndex": {},
          "type": "BM",
          "updatedAt": "2019-07-26T01:01:01.543+05:30"
        },
        {
          "_id": "1000037",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00086",
              "description": "CO-CMB",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-CMB",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR ID"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 87"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Management Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 78,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000037",
          "instrumentID": "1000037",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "Fortune Day International Limited - Discretionary A/C",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00086",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CMB"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "CMB"
            }
          ],
          "registrar": {
            "en": "Cayman"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2019-02-21T05:30:00+05:30",
          "type": "EM",
          "updatedAt": "2019-07-26T01:01:01.597+05:30"
        },
        {
          "_id": "1000038",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00087",
              "description": "CO-RC",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-RC",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR ID"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-25T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 87"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Trustee Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 76,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000038",
          "instrumentID": "1000038",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "Rock Creek Advisory account",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "a6R1iCzTM",
          "prcCustodian": {},
          "preferredClass": "CLS00087",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-RC"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "ROCK"
            }
          ],
          "registrar": {
            "en": "Hongkong"
          },
          "riskfreeIndex": {},
          "type": "EM",
          "updatedAt": "2019-07-26T01:01:01.649+05:30"
        },
        {
          "_id": "1000039",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00088",
              "description": "CO-ABS",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-ABS",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR ID"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-28T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 39"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "bands": [
                {
                  "range": 12,
                  "rate": 13,
                  "sq": "First"
                }
              ],
              "ccy": "USD",
              "name": "Management Fee",
              "rateType": "BAND_RATE",
              "remainingRate": 23,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000039",
          "instrumentID": "1000039",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "ABS Direct Eq Fund (S4)",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00088",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-ABS"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "ABS"
            }
          ],
          "registrar": {
            "en": "Hong Kong"
          },
          "riskfreeIndex": {},
          "type": "EM",
          "updatedAt": "2019-07-26T01:01:01.701+05:30"
        },
        {
          "_id": "1000040",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "HKD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00089",
              "description": "CO-HKU",
              "inceptionDate": "2010-10-11T05:30:00+05:30",
              "lotSize": 0,
              "name": "CO-HKU",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CSHCAIR ID"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-08-28T05:30:00+05:30",
          "custodian": {
            "en": "Custodian 40"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [
            {
              "ccy": "USD",
              "name": "Trustee Fee",
              "rateType": "FIXED_RATE",
              "remainingRate": 45,
              "type": "FV_GAG"
            }
          ],
          "fundID": "1000040",
          "instrumentID": "1000040",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "HKU HK and CN Equity Fund",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00089",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-HKU"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "HKU"
            }
          ],
          "registrar": {
            "en": "Hong Kong"
          },
          "riskfreeIndex": {},
          "type": "EM",
          "updatedAt": "2019-07-26T01:01:01.807+05:30"
        },
        {
          "_id": "1000041",
          "active": "NO",
          "activeStatus": false,
          "applicationFee": {
            "creationFee": 300,
            "redemptionFee": 400
          },
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "benchmarkIndex_copy": {},
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00090",
              "description": "US TECH STRATEGY FUND SP",
              "inceptionDate": "2018-11-15T05:30:00+05:30",
              "lotSize": 10,
              "name": "US TECH STRATEGY FUND SP",
              "nameChinese": "",
              "references": [],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2018-11-16T05:30:00+05:30",
          "custodian": {
            "en": " Custodian"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000041",
          "instrumentID": "1000041",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "isMultiMarket": false,
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 10,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "US TECH STRATEGY FUND SP",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "Bijulb9Tp",
          "prcCustodian": {},
          "preferredClass": "CLS00090",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CTEC"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "USTECH"
            }
          ],
          "registrar": {
            "en": "Cayman"
          },
          "riskfreeIndex": {
            "ccy": "USD",
            "name": "USD 3m Libor Total Return Index",
            "ticker": "BXIIU3MC Index"
          },
          "terminatedDate": "2019-05-28T05:30:00+05:30",
          "type": "EM",
          "updatedAt": "2019-07-26T01:01:01.86+05:30"
        },
        {
          "_id": "1000042",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "USD",
                  "fromDate": "2019-01-24T00:00:00.000Z",
                  "name": "FTSE 3-Month US Dollar Eurodeposit Index",
                  "ticker": "SBWMUD3L Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00091",
              "classIDName": "USD",
              "classNameInChinese": "",
              "classType": "LISTED",
              "description": "CO-CUSF",
              "inceptionDate": "2019-01-25T00:00:00.000Z",
              "lotSize": 1000,
              "managementFee": "0.35",
              "mktPriceValue": "LAST_PRICE",
              "name": "USD Share Class A",
              "nameCh": "美元",
              "nameChinese": "",
              "nameCustodian": "CUSA",
              "namePMA": "USD Share Class A",
              "pcfCode": "USDMMF",
              "shareClassAcctCode": "zzzConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "NO",
                  "listingDate": "2019-01-25T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000473311"
                    },
                    {
                      "type": "EXCH",
                      "value": "9096"
                    },
                    {
                      "type": "8IGHT",
                      "value": "9096.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CUSA"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "9096 HK EQUITY"
                    }
                  ],
                  "ticker": "9096 HK EQUITY",
                  "tickerCcy": "USD"
                },
                {
                  "cashComponent": "NO",
                  "listingDate": "2019-01-25T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000473311"
                    },
                    {
                      "type": "EXCH",
                      "value": "9096"
                    },
                    {
                      "type": "8IGHT",
                      "value": "9096.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CUSA"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "9096 HK EQUITY"
                    }
                  ],
                  "ticker": "3096 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "1000",
              "underlierStockFillDate": "T0",
              "unitsCu": "1000"
            },
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "USD",
                  "fromDate": "2019-01-24T00:00:00.000Z",
                  "name": "FTSE 3-Month US Dollar Eurodeposit Index",
                  "ticker": "SBWMUD3L Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00172",
              "classIDName": "Unlisted Share Class A",
              "classNameInChinese": "",
              "classType": "UNLISTED",
              "description": "CO-CUSF",
              "inceptionDate": "2019-08-02T00:00:00.000Z",
              "lotSize": 0,
              "mktPriceValue": "LAST_PRICE",
              "name": "USD Share Class A\t",
              "nameCh": "Unlisted Share Class A",
              "nameChinese": "",
              "nameCustodian": "CUSF",
              "namePMA": "USD Share Class unlisted A",
              "pcfCode": "USDMMF",
              "shareClassAcctCode": "zzzUConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "listingDate": "2019-08-02T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mms": [],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "8IGHT",
                      "value": "3096UA"
                    }
                  ],
                  "ticker": "9096UA HK EQUITY",
                  "tickerCcy": "USD"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2019-01-24T00:00:00Z",
          "custodianFundCode": "CUSF",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000042",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000042",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2019-05-27T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Passive Tracking Strategy",
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP US Dollar Money Market ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "16:09",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "ME",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "China Industrial Securities International Brokerage Limited",
              "nameChinese": "興證國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD443"
            },
            {
              "name": "BOCOM International Securities Limited",
              "nameChinese": "交銀國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD439"
            },
            {
              "name": "Credit Suisse Securities (Hong Kong) Limited",
              "nameChinese": "瑞士信貸證券(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD454"
            },
            {
              "name": "China Securities (International) Brokerage Company Limited",
              "nameChinese": "中信建投(國際)証券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD446"
            },
            {
              "name": "ICBC International Securities Limited",
              "nameChinese": "工銀國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD467"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Valuable Capital Limited",
              "nameChinese": "華盛資本証券有限公司",
              "partyCategory": "PD",
              "partyID": "PD492"
            },
            {
              "name": "Shanxi Securities International Limited",
              "nameChinese": "山證國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD485"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Cinda International Securities Limited",
              "nameChinese": "信達國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD448"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "10:00",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao ",
            "nameChinese": "科特斯瓦拉朗",
            "partyCategory": "PMGR",
            "partyID": "757",
            "references": []
          },
          "preferredClass": "CLS00172",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CUSF"
            },
            {
              "type": "PCF_CODE",
              "value": "USDMMF"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "USDMMF"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP US Dollar Money Market ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CUSF"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG903",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "12:45",
          "slaType": "T0",
          "tradarFundLongName": "CSOP US Dollar Money Market ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-21T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000043",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2019-05-26T00:00:00.000Z",
                  "name": "Hong Kong Hang Seng Index",
                  "ticker": "HSI Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00092",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "CSOP Hang Seng Index Daily (-2x) Inverse Product",
              "inceptionDate": "2019-05-28T00:00:00.000Z",
              "lotSize": 800000,
              "managementFee": "1.50",
              "mktPriceValue": null,
              "name": "HKD Share Class A",
              "nameChinese": "港元 A 類股份",
              "nameCustodian": "CHS5",
              "namePMA": "HKD Share Class A",
              "pcfCode": "HSI_2INV",
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2019-05-27T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM503",
                      "PartyNameInChinese": "DRW Singapore Pte. Ltd",
                      "PartyNameInEnglish": "DRW Singapore Pte. Ltd"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM508",
                      "PartyNameInChinese": "海通國際證券有限公司",
                      "PartyNameInEnglish": "Haitong International Securities Company Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000503190"
                    },
                    {
                      "type": "REUTER",
                      "value": "7500.HK"
                    },
                    {
                      "type": "EXCH",
                      "value": "7500"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7500.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CHS5"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7500 HK EQUITY"
                    }
                  ],
                  "ticker": "7500 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2019-05-21T00:00:00Z",
          "custodianFundCode": "CHS5",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000043",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000043",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2019-05-27T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Futures-based replication and a swap-based synthetic",
          "liMultiplier": -2,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2019-05-27T00:00:00.000Z",
              "liMultiplier": -2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Hang Seng Index Daily (-2x) Inverse Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "15:46",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Korea Investment \u0026 Securities Asia Limited",
              "nameChinese": "韓國投資證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD472"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "18:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00092",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-HSI-2I"
            },
            {
              "type": "PCF_CODE",
              "value": "HSI_2INV"
            },
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "HSI-2XIN"
            },
            {
              "type": "PMA_CUST",
              "value": "4"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Hang Seng Index Daily (-2x) Inverse Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CHS5"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "tradarFundLongName": "CSOP Hang Seng Index Daily (-2x) Inverse Product",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "LI",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000113",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "USD",
                  "fromDate": "2019-09-18T00:00:00.000Z",
                  "name": "NASDAQ 100 Index",
                  "ticker": "NDX Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00162",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "7568 HK",
              "inceptionDate": "2019-09-19T00:00:00.000Z",
              "lotSize": 800000,
              "managementFee": "1.50",
              "mktPriceValue": "LAST_PRICE",
              "name": "USD Share Class A",
              "nameChinese": "",
              "nameCustodian": "CND1",
              "namePMA": "USD Share Class A",
              "pcfCode": "NDX_2INV\t",
              "shareClassAcctCode": "zzzConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2019-09-18T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "SETTLEMENT_PRICE",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000528882"
                    },
                    {
                      "type": "EXCH",
                      "value": "7568"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7568.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CND1"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7568 HK EQUITY"
                    }
                  ],
                  "ticker": "7568 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2019-09-24T00:00:00Z",
          "custodianFundCode": "CND1",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK",
            "US"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": "QUARTER",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000113",
          "indexCalendar": [
            "US"
          ],
          "indexCondition": "AND",
          "indexSla": "T1",
          "instrumentID": "1000113",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2019-09-18T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategyID": "934",
          "liMultiplier": -2,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2019-09-18T00:00:00.000Z",
              "liMultiplier": -2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP NASDAQ-100 Index Daily (-2x) Inverse Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "11:59",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK",
            "US"
          ],
          "navCondition": "AND",
          "navFrequency": "WEEKLY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "07:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00162",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-NDX-2I"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP NASDAQ-100 Index Daily (-2x) Inverse Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CND1"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG903",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "tradarFundLongName": "CSOP NASDAQ-100 Index Daily (-2x) Inverse Product",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC CUSTODIAN\t\n",
            "nameChinese": "汇丰托管人\n",
            "partyCategory": "TRUS",
            "partyID": "TS900",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "LI",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000118",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "benchmarkIndex": [
            {
              "ccy": "CNY",
              "fromDate": "2020-03-17T21:30:00+05:30",
              "name": "CSI 500 Index",
              "ticker": "CSIN0905 Index",
              "toDate": "2099-12-31T10:30:00+05:30"
            }
          ],
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2020-03-18T00:00:00.000Z",
                  "name": "CSI 500 Index",
                  "ticker": "CSIN0905 Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00171",
              "classIDName": "RMB",
              "classType": "LISTED",
              "description": "HK-CSI5",
              "inceptionDate": "2020-03-18T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 400000,
              "managementFee": "0.99",
              "marketMakers": {
                "en": [
                  "Flow Traders Hong Kong Limited",
                  "Haitong International Securities Company Limited"
                ],
                "tch": [
                  "Flow Traders Hong Kong Limited",
                  "海通國際證券有限公司"
                ]
              },
              "mktPriceValue": "LAST_PRICE",
              "name": "HK-CSI5",
              "nameChinese": "",
              "nameCustodian": "CSI5",
              "namePMA": "RMB Share Class A",
              "pcf_code": "CSOPCSIS00",
              "references": [
                {
                  "type": "ISIN",
                  "value": "HK0000578531"
                },
                {
                  "type": "REUTER",
                  "value": "83005.HK"
                },
                {
                  "type": "EXCH",
                  "value": "83005"
                },
                {
                  "type": "8IGHT",
                  "value": "(Stock code: RMB 83005, HKD 3005)"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CSI5"
                },
                {
                  "type": "PMA",
                  "value": "RMB Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "83005 HK EQUITY"
                }
              ],
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "name": "Haitong International Securities Company Limited",
                      "nameChinese": "海通國際證券有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM508"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000578531"
                    },
                    {
                      "type": "REUTER",
                      "value": "83005.HK"
                    },
                    {
                      "type": "EXCH",
                      "value": "83005"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: RMB 83005, HKD 3005)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CSI5"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "83005 HK EQUITY"
                    }
                  ],
                  "ticker": "3005 HK EQUITY",
                  "tickerCcy": "HKD"
                },
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "name": "Haitong International Securities Company Limited",
                      "nameChinese": "海通國際證券有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM508"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000578531"
                    },
                    {
                      "type": "REUTER",
                      "value": "83005.HK"
                    },
                    {
                      "type": "EXCH",
                      "value": "83005"
                    },
                    {
                      "type": "8IGHT",
                      "value": "(Stock code: RMB 83005, HKD 3005)"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CSI5"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "83005 HK EQUITY"
                    }
                  ],
                  "ticker": "83005 HK EQUITY",
                  "tickerCcy": "CNY"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "200",
              "underlierStockFillDate": "T2",
              "unitsCu": "400000"
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2020-03-17T21:30:00+05:30",
                  "name": "CSI 500 Index",
                  "ticker": "CSIN0905 Index",
                  "toDate": "2099-12-31T10:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00170",
              "classIDName": "HKD",
              "description": "HK-CSI5",
              "inceptionDate": "2020-03-17T21:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 400000,
              "managementFee": "0.99",
              "marketMakers": {
                "en": [
                  "AP Capital Management (Hong Kong) Limited",
                  "Flow Traders Hong Kong Limited",
                  "Haitong International Securities Company Limited",
                  "SG Securities (HK) Limited"
                ],
                "tch": [
                  "雅柏資本管理(香港)有限公司",
                  "Flow Traders Hong Kong Limited",
                  "海通國際證券有限公司",
                  "法國興業證券(香港)有限公司"
                ]
              },
              "name": "HK-CSI5",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "3005"
                },
                {
                  "type": "BBG",
                  "value": "3005 HK Equity"
                },
                {
                  "type": "REUTER",
                  "value": "3005.HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000578549"
                }
              ],
              "tickers": [],
              "tradingLotSize": "200"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2020-03-18T00:00:00Z",
          "custodian": {},
          "custodianFundCode": "CSI5",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "CN",
            "HK"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000118",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000118",
          "investmentAdvisor": {
            "en": "Investment Advisor"
          },
          "investmentStrategies": [
            {
              "fromDate": "2020-03-18T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {
            "en": "Physical and a synthetic representative sampling strategy",
            "tch": "實物及合成代表性抽樣策略"
          },
          "investmentStrategyEn": "Physical and a synthetic representative sampling strategy",
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信托"
          },
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 400000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資産管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP CSI 500 ETF",
          "nameChinese": "",
          "navCalendar": [
            "CN",
            "HK"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "CN"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Yuanta Securities (Hong Kong) Company Limited",
              "nameChinese": "元大證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD493"
            }
          ],
          "participatingDealers": {
            "en": [
              "SG Securities (HK) Limited",
              "Haitong International Securities Company Limited",
              "J.P. Morgan Broking (Hong Kong) Limited",
              "Merrill Lynch Far East Limited",
              "The Hongkong and Shanghai Banking Corporation Limited",
              "ABN AMRO Clearing Hong Kong Limited",
              "Citigroup Global Markets Asia Limited",
              "Mirae Asset Securities (HK) Limited",
              "Phillip Securities (Hong Kong) Limited",
              "Huatai Financial Holdings (Hong Kong) Limited",
              "Yuanta Securities (Hong Kong) Company Limited",
              "China Merchants Securities (HK) Co., Limited"
            ],
            "tch": [
              "J.P. Morgan Broking (Hong Kong) Limited ",
              "Merrill Lynch Far East Limited",
              "香港上海滙豐銀行有限公司",
              "ABN AMRO Clearing Hong Kong Limited",
              "法國興業證券(香港)有限公司",
              "海通國際證券有限公司",
              "花旗環球金融亞洲有限公司",
              "未來資產證券(香港)有限公司",
              "輝立證券(香港)有限公司",
              "華泰金融控股(香港)有限公司",
              "元大證券(香港)有限公司",
              "招商證券(香港)有限公司"
            ]
          },
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "18:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {
            "en": "HSBC Bank (China) Company Limited",
            "tch": "匯豐銀行(中國)有限公司"
          },
          "preferredClass": "CLS00171",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-CSI5"
            },
            {
              "type": "PCF_CODE",
              "value": "CSOPCSI500"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP CSI 500 ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSI5"
            }
          ],
          "registrar": {
            "en": "HSBC Institutional Trust Services (Asia) Limited",
            "tch": "匯豐機構信託服務（亞洲）有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP CSI 500 ETF",
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000119",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "USD",
                  "fromDate": "2020-05-13T00:00:00.000Z",
                  "name": "NASDAQ 100 Index",
                  "ticker": "NDX Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00174",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "7266 HK",
              "inceptionDate": "2020-05-14T00:00:00.000Z",
              "lotSize": 800000,
              "managementFee": "1.5",
              "mktPriceValue": "LAST_PRICE",
              "name": "USD Share Class A",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "USD Share Class A",
              "pcfCode": "NDX_LEV",
              "shareClassAcctCode": "zzzConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2020-05-14T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "name": "IMC Asia Pacific Limited",
                      "nameChinese": "IMC Asia Pacific Limited",
                      "partyCategory": "MM",
                      "partyID": "MM511"
                    },
                    {
                      "name": "Korea Investment \u0026 Securities Asia Limited",
                      "nameChinese": "韓國投資證券亞洲有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM516"
                    }
                  ],
                  "mktPriceValue": "SETTLEMENT_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM506",
                      "PartyNameInChinese": "Flow Traders Hong Kong Limited",
                      "PartyNameInEnglish": "Flow Traders Hong Kong Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM511",
                      "PartyNameInChinese": "IMC Asia Pacific Limited",
                      "PartyNameInEnglish": "IMC Asia Pacific Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM516",
                      "PartyNameInChinese": "韓國投資證券亞洲有限公司",
                      "PartyNameInEnglish": "Korea Investment \u0026 Securities Asia Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7266"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000604659"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7266.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7266 HK EQUITY"
                    }
                  ],
                  "ticker": "7266 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2020-05-15T00:00:00Z",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "US",
            "HK"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000119",
          "indexCalendar": [
            "US"
          ],
          "indexCondition": "AND",
          "indexSla": "T1",
          "instrumentID": "1000119",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2020-05-14T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Futures-based Replication strategy",
          "liMultiplier": 2,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2020-05-14T00:00:00.000Z",
              "liMultiplier": 2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP NASDAQ-100 Index Daily (2x) Leveraged Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "11:11",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "China Industrial Securities International Brokerage Limited",
              "nameChinese": "興證國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD443"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Zhongtai International Securities Limited",
              "nameChinese": "中泰國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD494"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "BOCOM International Securities Limited",
              "nameChinese": "交銀國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD439"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "07:00",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao ",
            "nameChinese": "科特斯瓦拉朗",
            "partyCategory": "PMGR",
            "partyID": "757",
            "references": []
          },
          "preferredClass": "CLS00174",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-NDX-L"
            },
            {
              "type": "PCF_CODE",
              "value": "NDX_LEV"
            },
            {
              "type": "TRADER_NAME"
            },
            {
              "type": "CUSTODIAN"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG910",
            "references": []
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "trusteeID": {
            "location": "HK",
            "name": "cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS909",
            "references": []
          },
          "type": "LI",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000120",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "INDX",
          "assetClassDescription": "Index",
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "USD",
                  "fromDate": "2020-06-04T00:00:00.000Z",
                  "name": "Solactive Gold 1-Day Rolling Futures Index",
                  "ticker": "SOLGOLD1 Index",
                  "toDate": "2099-12-30T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00175",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "7299 HK",
              "inceptionDate": "2020-06-04T00:00:00.000Z",
              "lotSize": 800000,
              "managementFee": "1.5",
              "mktPriceValue": "LAST_PRICE",
              "name": "USD Share Class A",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "USD Share Class A",
              "pcfCode": "GOLD_INV",
              "shareClassAcctCode": "zzzConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2021-10-19T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "SETTLEMENT_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM505",
                      "PartyNameInChinese": "Flow Traders Asia Pte Ltd",
                      "PartyNameInEnglish": "Flow Traders Asia Pte Ltd"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7299"
                    },
                    {
                      "type": "ISIN",
                      "value": "\tHK0000612744"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7299.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7299 HK EQUITY"
                    }
                  ],
                  "ticker": "7299 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2020-06-08T00:00:00Z",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK",
            "US"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000120",
          "indexCalendar": [
            "US"
          ],
          "indexCondition": "AND",
          "indexSla": "T1",
          "instrumentID": "1000120",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2020-06-04T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Futures-based replication strategy and a swap-based",
          "liMultiplier": 2,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2020-06-04T00:00:00.000Z",
              "liMultiplier": 2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Gold Futures Daily (2x) Leveraged Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "12:47",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "12:47",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "China Industrial Securities International Brokerage Limited",
              "nameChinese": "興證國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD443"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Zhongtai International Securities Limited",
              "nameChinese": "中泰國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD494"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "BOCOM International Securities Limited",
              "nameChinese": "交銀國際證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD439"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "07:00",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao ",
            "nameChinese": "科特斯瓦拉朗",
            "partyCategory": "PMGR",
            "partyID": "757",
            "references": []
          },
          "preferredClass": "CLS00175",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-GLD-L"
            },
            {
              "type": "PCF_CODE",
              "value": "GOLD_LEV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP GOLD FUTURES DAILY (2X) LEVERAGED PRODUCT"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSGD"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG910",
            "references": []
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "slaTypeNAV2": "T0",
          "trusteeID": {
            "location": "HK",
            "name": "cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS909",
            "references": []
          },
          "type": "LI",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000121",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "LEVE",
          "benchmarkIndex": [
            {
              "ccy": "CNY",
              "fromDate": "2020-07-23T21:30:00+05:30",
              "name": "CSI 300 Index",
              "ticker": "SHSZ300 Index",
              "toDate": "2099-12-31T10:30:00+05:30"
            }
          ],
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2020-07-23T00:00:00.000Z",
                  "name": "CSI 300 Index",
                  "ticker": "SHSZ300 Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00176",
              "classIDName": "CNY",
              "classType": "LISTED",
              "description": "HK-CS3-L",
              "inceptionDate": "2020-07-24T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 800000,
              "managementFee": "1.6",
              "marketMakers": {
                "en": [
                  "DRW Singapore Pte. Ltd.",
                  "Flow Traders Hong Kong Limited",
                  "Optiver Trading Hong Kong Limited",
                  "IMC Asia Pacific Limited",
                  "China International Capital Corporation Hong Kong Securities Limited "
                ],
                "tch": [
                  "Flow Traders Hong Kong Limited",
                  "DRW Singapore Pte. Ltd.",
                  "Optiver Trading Hong Kong Limited",
                  "IMC Asia Pacific Limited",
                  "中國國際金融香港證券有限公司"
                ]
              },
              "mktPriceValue": "LAST_PRICE",
              "name": "HK-CS3-L",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "RMB Share Class A",
              "pcf_code": "CSI300_LEV",
              "references": [
                {
                  "type": "ISIN",
                  "value": "\tHK0000629656"
                },
                {
                  "type": "EXCH",
                  "value": "7233"
                },
                {
                  "type": "SEDOL",
                  "value": "\tBMXTWY5"
                },
                {
                  "type": "REUTER",
                  "value": "7233.HK"
                },
                {
                  "type": "BBG",
                  "value": "7233 HK EQUITY"
                },
                {
                  "type": "8IGHT",
                  "value": "7233.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "A"
                },
                {
                  "type": "PMA",
                  "value": "RMB Share Class A"
                }
              ],
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "name": "Optiver Trading Hong Kong Limited",
                      "nameChinese": "Optiver Trading Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM518"
                    },
                    {
                      "name": "IMC Asia Pacific Limited",
                      "nameChinese": "IMC Asia Pacific Limited",
                      "partyCategory": "MM",
                      "partyID": "MM511"
                    },
                    {
                      "name": "China International Capital Corporation Hong Kong Securities Limited",
                      "nameChinese": "中國國際金融香港證券有限公司,",
                      "partyCategory": "MM",
                      "partyID": "MM499"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "\tHK0000629656"
                    },
                    {
                      "type": "EXCH",
                      "value": "7233"
                    },
                    {
                      "type": "SEDOL",
                      "value": "\tBMXTWY5"
                    },
                    {
                      "type": "REUTER",
                      "value": "7233.HK"
                    },
                    {
                      "type": "BBG",
                      "value": "7233 HK EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7233.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    }
                  ],
                  "ticker": "7233 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2020-07-27T00:00:00Z",
          "custodian": {},
          "custodianFundCode": "CS3L",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK",
            "CN"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 ─ 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000121",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000121",
          "investmentAdvisor": {},
          "investmentStrategies": [
            {
              "fromDate": "2020-07-24T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {
            "en": "Swap-based synthetic replication strategy",
            "tch": "以掉期為基礎的合成模擬策略"
          },
          "investmentStrategyEn": "Swap-based synthetic replication strategy",
          "legalStructure": {
            "en": "Hong Kong Unit trust",
            "tch": "香港單位信託"
          },
          "liMultiplier": 2,
          "liMultipliers": [
            {
              "fromDate": "2020-07-24T00:00:00.000Z",
              "liMultiplier": 2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資產管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP CSI 300 Index Daily (2x) Leveraged Product",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "CN"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            }
          ],
          "participatingDealers": {
            "en": [
              "UBS Securities Hong Kong Limited",
              "\tChina International Capital Corporation Hong Kong Securities Limited",
              "ABN AMRO Clearing Hong Kong Limited",
              "Citigroup Global Markets Asia Limited",
              "Haitong International Securities Company Limited",
              "J.P. Morgan Broking (Hong Kong) Limited",
              "China Merchants Securities (HK) Co., Limited",
              "Mirae Asset Securities (HK) Limited",
              "Huatai Financial Holdings (Hong Kong) Limited",
              "Barclays Bank PLC"
            ],
            "tch": [
              "UBS Securities Hong Kong Limited",
              "ABN AMRO Clearing Hong Kong Limited",
              "海通國際證券有限公司",
              "花旗環球金融亞洲有限公司",
              "中國國際金融香港證券有限公司",
              "J.P. Morgan Broking (Hong Kong) Limited",
              "招商證券（香港）有限公司",
              "未來資產證券(香港)有限公司",
              "華泰金融控股(香港)有限公司",
              "Barclays Bank PLC"
            ]
          },
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "18:45",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00176",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-CS3-L"
            },
            {
              "type": "PCF_CODE",
              "value": "CSI300_LEV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP CSI 300 Index Daily (2x) Leveraged Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CS3L"
            }
          ],
          "registrar": {
            "en": "Cititrust Limited \u0026 Computershare Hong Kong Investor Services Limited",
            "tch": "花旗信託有限公司/ 香港中央證券登記有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP CSI 300 Index Daily (2x) Leveraged Product",
          "type": "ETF",
          "updatedAt": "2022-12-06T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000122",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "LEVE",
          "benchmarkIndex": [
            {
              "ccy": "CNY",
              "fromDate": "2020-07-24T05:30:00+05:30",
              "name": "CSI 300 INDEX",
              "ticker": "SHSZ300 Index",
              "toDate": "2099-12-31T10:30:00+05:30"
            }
          ],
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2020-07-24T00:00:00.000Z",
                  "name": "CSI 300 INDEX",
                  "ticker": "SHSZ300 Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00177",
              "classIDName": "RMB",
              "classType": "LISTED",
              "description": "HK-CS3-I",
              "inceptionDate": "2020-07-24T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 800000,
              "managementFee": "1.6",
              "marketMakers": {
                "en": [
                  "DRW Singapore Pte. Ltd.",
                  "Flow Traders Hong Kong Limited",
                  "Optiver Trading Hong Kong Limited",
                  "IMC Asia Pacific Limited",
                  "China International Capital Corporation Hong Kong Securities Limited "
                ],
                "tch": [
                  "DRW Singapore Pte. Ltd.",
                  "Flow Traders Hong Kong Limited",
                  "Optiver Trading Hong Kong Limited",
                  "IMC Asia Pacific Limited",
                  "China International Capital Corporation Hong Kong Securities Limited "
                ]
              },
              "mktPriceValue": "LAST_PRICE",
              "name": "HK-CS3-I",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "RMB Share Class A",
              "pcf_code": "CSI300_INV",
              "references": [
                {
                  "type": "REUTER",
                  "value": "\t7333.HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000629649"
                },
                {
                  "type": "EXCH",
                  "value": "7333"
                },
                {
                  "type": "SEDOL",
                  "value": "\tBMXTWR8"
                },
                {
                  "type": "BBG",
                  "value": "7333 HK EQUITY"
                },
                {
                  "type": "8IGHT",
                  "value": "7333.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "A"
                },
                {
                  "type": "PMA",
                  "value": "RMB Share Class A"
                }
              ],
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "name": "Optiver Trading Hong Kong Limited",
                      "nameChinese": "Optiver Trading Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM518"
                    },
                    {
                      "name": "IMC Asia Pacific Limited",
                      "nameChinese": "IMC Asia Pacific Limited",
                      "partyCategory": "MM",
                      "partyID": "MM511"
                    },
                    {
                      "name": "China International Capital Corporation Hong Kong Securities Limited",
                      "nameChinese": "中國國際金融香港證券有限公司,",
                      "partyCategory": "MM",
                      "partyID": "MM499"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "REUTER",
                      "value": "\t7333.HK"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000629649"
                    },
                    {
                      "type": "EXCH",
                      "value": "7333"
                    },
                    {
                      "type": "SEDOL",
                      "value": "\tBMXTWR8"
                    },
                    {
                      "type": "BBG",
                      "value": "7333 HK EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7333.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    }
                  ],
                  "ticker": "7333 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2020-07-27T00:00:00Z",
          "custodian": {},
          "custodianFundCode": "CS3I",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK",
            "CN"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000122",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000122",
          "investmentAdvisor": {},
          "investmentStrategies": [
            {
              "fromDate": "2020-07-24T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {
            "en": " Swap-based synthetic replication strategy",
            "tch": "以掉期為基礎的合成模擬策略"
          },
          "investmentStrategyEn": "Swap-based synthetic  replication strategy",
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信託"
          },
          "liMultiplier": -1,
          "liMultipliers": [
            {
              "fromDate": "2020-07-24T00:00:00.000Z",
              "liMultiplier": -1,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資産管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP CSI 300 Index Daily (-1x) Inverse Product",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "CN"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "UBS Securities Hong Kong Limited",
              "nameChinese": "UBS Securities Hong Kong Limited",
              "partyCategory": "PD",
              "partyID": "PD489"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "participatingDealers": {
            "en": [
              "UBS Securities Hong Kong Limited",
              "ABN AMRO Clearing Hong Kong Limited",
              "Haitong International Securities Company Limited",
              "China International Capital Corporation Hong Kong Securities Limited",
              "Citigroup Global Markets Asia Limited",
              "J.P. Morgan Broking (Hong Kong) Limited",
              "China Merchants Securities (HK) Co., Limited",
              "Mirae Asset Securities (HK) Limited",
              "Huatai Financial Holdings (Hong Kong) Limited",
              "Barclays Bank PLC"
            ],
            "tch": [
              "J.P. Morgan Broking (Hong Kong) Limited",
              "ABN AMRO Clearing Hong Kong Limited",
              "UBS Securities Hong Kong Limited",
              "海通國際證券有限公司",
              "中國國際金融香港證券有限公司",
              "花旗環球金融亞洲有限公司",
              "招商證券（香港）有限公司",
              "未來資產證券(香港)有限公司",
              "華泰金融控股(香港)有限公司",
              "Barclays Bank PLC "
            ]
          },
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "18:45",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00177",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-CS3-I"
            },
            {
              "type": "PCF_CODE",
              "value": "CSI300_INV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP CSI 300 Index Daily (-1x) Inverse Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CS3I"
            }
          ],
          "registrar": {
            "en": "Cititrust Limited \u0026 Computershare Hong Kong Investor Services Limited",
            "tch": "花旗信託有限公司 /香港中央證券登記有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP CSI 300 Index Daily (-1x) Inverse Product",
          "type": "ETF",
          "updatedAt": "2022-12-06T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000123",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2020-07-26T00:00:00.000Z",
                  "name": "Hang Seng TECH Index",
                  "ticker": "HSTECHN Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00199",
              "classIDName": "Unlisted class",
              "classType": "LISTED",
              "description": "Unlisted class",
              "inceptionDate": "2021-09-06T00:00:00.000Z",
              "lotSize": 10,
              "mktPriceValue": "LAST_PRICE",
              "name": "HKD Share Class A",
              "nameChinese": "港元 A 類股份",
              "nameCustodian": "CHSB",
              "namePMA": "HKD Share Class A",
              "pcfCode": "HSTECH",
              "terminationDate": null,
              "tickers": [
                {
                  "listingDate": "2020-08-27T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000642568"
                    }
                  ],
                  "ticker": "3033UA HK EQUITY",
                  "tickerCcy": "CNY"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0",
              "unitsCu": "0"
            },
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2020-07-27T00:00:00.000Z",
                  "name": "Hang Seng TECH Index",
                  "ticker": "HSTECHN Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00178",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "CO-CHST",
              "inceptionDate": "2020-08-27T00:00:00.000Z",
              "lotSize": 500000,
              "managementFee": "0.99",
              "mktPriceValue": "LAST_PRICE",
              "name": "HKD Share Class unlisted A",
              "nameChinese": "",
              "nameCustodian": "CHSA",
              "namePMA": "HKD Share Class unlisted A",
              "pcfCode": "HSTECH",
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2021-08-30T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3033"
                    },
                    {
                      "type": "REUTER",
                      "value": "3033.HK"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000642568"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3033.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CHSA"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "3033 HK EQUITY"
                    }
                  ],
                  "ticker": "3033UA HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "200",
              "underlierStockFillDate": "T0",
              "unitsCu": "500000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2020-08-26T00:00:00Z",
          "custodianFundCode": "CHST",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000123",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000123",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2020-08-27T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Full Replication Strategy",
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 500000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Hang Seng TECH Index ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "11:28",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "Merrill Lynch Far East Limited",
              "nameChinese": "Merrill Lynch Far East Limited",
              "partyCategory": "PD",
              "partyID": "PD477"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Valuable Capital Limited",
              "nameChinese": "華盛資本証券有限公司",
              "partyCategory": "PD",
              "partyID": "PD492"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "Morgan Stanley Hong Kong Securities Limited",
              "nameChinese": "Morgan Stanley Hong Kong Securities Limited",
              "partyCategory": "PD",
              "partyID": "PD479"
            },
            {
              "name": "Goldman Sachs (Asia) Securities Limited",
              "nameChinese": "高盛(亞洲)證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD461"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            },
            {
              "name": "Phillip Securities (Hong Kong) Limited",
              "nameChinese": "輝立證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD482"
            },
            {
              "name": "Credit Suisse Securities (Hong Kong) Limited",
              "nameChinese": "瑞士信貸證券(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD454"
            },
            {
              "name": "China Galaxy International Securities (Hong Kong) Co., Limited",
              "nameChinese": "中國銀河國際證券(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD442"
            },
            {
              "name": "Korea Investment \u0026 Securities Asia Limited",
              "nameChinese": "韓國投資證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD472"
            },
            {
              "name": "Nomura International (Hong Kong) Limited",
              "nameChinese": "野村國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD480"
            },
            {
              "name": "Zhongtai International Securities Limited",
              "nameChinese": "中泰國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD494"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "17:45",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao ",
            "nameChinese": "科特斯瓦拉朗",
            "partyCategory": "PMGR",
            "partyID": "757",
            "references": []
          },
          "preferredClass": "CLS00178",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CHST"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Hang Seng TECH Index ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CHST"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "tradarFundLongName": "CSOP Hang Seng TECH Index ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000124",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2020-09-17T00:00:00.000Z",
                  "name": "FTSE Chinese Government Bond Index",
                  "ticker": "CFIICNL Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00228",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "USD",
              "inceptionDate": "2022-10-11T00:00:00.000Z",
              "lotSize": 50000,
              "managementFee": "0.25",
              "mktPriceValue": "LAST_PRICE",
              "name": "USD",
              "nameCh": "USD",
              "nameChinese": "",
              "nameCustodian": "CCBG",
              "namePMA": "USD",
              "pcfCode": "CSOPCGB",
              "shareClassAcctCode": "zzzIConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "listingDate": "2022-10-12T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "8IGHT",
                      "value": "CYX"
                    }
                  ],
                  "ticker": "CYX SP EQUITY",
                  "tickerCcy": "USD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "10",
              "underlierStockFillDate": "T0"
            },
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2020-09-17T00:00:00.000Z",
                  "name": "FTSE Chinese Government Bond Index",
                  "ticker": "CFIICNL Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00180",
              "classIDName": "SGD",
              "classType": "LISTED",
              "description": "SGD",
              "inceptionDate": "2020-09-18T00:00:00.000Z",
              "lotSize": 50000,
              "managementFee": "0.25",
              "name": "SG-WGBI",
              "nameCh": "SG-WGBI",
              "nameChinese": "",
              "nameCustodian": "CCGA",
              "namePMA": "SG-WGBI",
              "pcfCode": "CSOPCGB",
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "NO",
                  "listingDate": "2020-09-21T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "CYC"
                    },
                    {
                      "type": "REUTER",
                      "value": "ICGB.SI"
                    },
                    {
                      "type": "ISIN",
                      "value": "SGXC34971074"
                    },
                    {
                      "type": "BBG",
                      "value": "CYC SP EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "CYC CYB"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CCGA"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    }
                  ],
                  "ticker": "CYB SP EQUITY",
                  "tickerCcy": "USD"
                },
                {
                  "cashComponent": "NO",
                  "listingDate": "2020-09-21T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "CYC"
                    },
                    {
                      "type": "REUTER",
                      "value": "ICGB.SI"
                    },
                    {
                      "type": "ISIN",
                      "value": "SGXC34971074"
                    },
                    {
                      "type": "BBG",
                      "value": "CYC SP EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "CYC CYB"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CCGA"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    }
                  ],
                  "ticker": "CYC SP EQUITY",
                  "tickerCcy": "SGD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "10",
              "underlierStockFillDate": "T0",
              "unitsCu": "50000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2020-09-16T00:00:00Z",
          "custodianFundCode": "CCGB",
          "custodianID": {
            "name": "HSBC Institutional Trust Services (Singapore)  Limited",
            "nameChinese": "HSBC Institutional Trust Services (Singapore)  Limited",
            "partyCategory": "CUST",
            "partyID": "CU531"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "SG",
            "CN"
          ],
          "dealingCondition": "OR",
          "entity": "SG",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000124",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "indexSla": "T1",
          "instrumentID": "1000124",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2020-09-18T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Strategy",
          "liMultipliers": [],
          "location": "Singapore",
          "lotSize": 50000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "17:11",
          "nav2Recon": "NO",
          "navCalendar": [
            "SG",
            "CN"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "ICBC",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "BE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Bank N.V. Singapore Branch",
              "nameChinese": "ABN AMRO Clearing Bank N.V. Singapore Branch,",
              "partyCategory": "PD",
              "partyID": "PD433"
            },
            {
              "name": "Merrill Lynch (Singapore) Pte Ltd",
              "nameChinese": "Merrill Lynch (Singapore) Pte Ltd",
              "partyCategory": "PD",
              "partyID": "PD476"
            },
            {
              "name": "Phillip Securities Pte Ltd",
              "nameChinese": "Phillip Securities Pte Ltd",
              "partyCategory": "PD",
              "partyID": "PD483"
            },
            {
              "name": "iFAST Financial Pte Ltd",
              "nameChinese": "iFAST Financial Pte Ltd",
              "partyCategory": "PD",
              "partyID": "PD468"
            },
            {
              "name": "UOB Kay Hian Private Limited",
              "nameChinese": "UOB Kay Hian Private Limited",
              "partyCategory": "PD",
              "partyID": "PD491"
            },
            {
              "name": "DBS Vickers Securities (Singapore) Pte Ltd",
              "nameChinese": "DBS Vickers Securities (Singapore) Pte Ltd,",
              "partyCategory": "PD",
              "partyID": "PD455"
            },
            {
              "name": "UBS Securities Pte Ltd",
              "nameChinese": "UBS Securities Pte Ltd",
              "partyCategory": "PD",
              "partyID": "PD490"
            },
            {
              "name": "CGS-CIMB Securities (Singapore) Pte Ltd",
              "nameChinese": "CGS-CIMB Securities (Singapore) Pte Ltd,",
              "partyCategory": "PD",
              "partyID": "PD441"
            },
            {
              "name": "Jane Street Financial Limited",
              "nameChinese": "Jane Street Financial Limited",
              "partyCategory": "PD",
              "partyID": "PD470"
            }
          ],
          "pmaCustodianCode": "HSBC_SG",
          "pmaNavFileTime": "19:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00180",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "SG-WGBI"
            },
            {
              "type": "PCF_CODE",
              "value": "CSOPCGB"
            },
            {
              "type": "PMA_CUST",
              "value": "34"
            },
            {
              "type": "TRADER_NAME",
              "value": "ICBC CSOP FTSE Chinese Government Bond Index ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CCGB"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "tradarFundLongName": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-08T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000125",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2020-10-21T00:00:00.000Z",
                  "name": "CSI 5G Communication Index",
                  "ticker": "SH931079 Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00181",
              "classIDName": "RMB",
              "classType": "LISTED",
              "description": "RMB",
              "inceptionDate": "2020-10-22T00:00:00.000Z",
              "lotSize": 200000,
              "managementFee": "0.99",
              "mktPriceValue": "LAST_PRICE",
              "name": "RMB Share Class A",
              "nameChinese": "人民幣A股",
              "nameCustodian": "CS5G",
              "namePMA": "RMB Share Class A",
              "pcfCode": "CSOPSCI5G",
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2020-10-22T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "BBG",
                      "value": "3193 HK EQUITY"
                    },
                    {
                      "type": "EXCH",
                      "value": "3193"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000656949"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3193.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CS5G"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    }
                  ],
                  "ticker": "3193 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "200000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2020-10-20T00:00:00Z",
          "custodianFundCode": "CS5G",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "CN",
            "HK"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000125",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000125",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2020-10-21T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Invests at least 90% of its NAV in Yinhua CSI 5G ",
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 200000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Yinhua CSI 5G Communications Theme ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "11:30",
          "nav2Recon": "NO",
          "navCalendar": [
            "CN",
            "HK"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "17:30",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00181",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "PCF_CODE",
              "value": "CSOPCSI5G"
            },
            {
              "type": "TRADAR_CODE",
              "value": "CO-CS5G"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP YINHUA CSI 5G COMMUNICATIONS THEME ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CS5G"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "tradarFundLongName": "CSOP YINHUA CSI 5G COMMUNICATIONS THEME ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-06T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000126",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2020-12-08T00:00:00.000Z",
                  "name": "HSTECH Index",
                  "ticker": "HSTECH Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00182",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "HKD",
              "inceptionDate": "2020-12-09T00:00:00.000Z",
              "lotSize": 400000,
              "managementFee": "1.60",
              "mktPriceValue": "LAST_PRICE",
              "name": "HKD Share Class A",
              "nameCh": "HK-HST-L",
              "nameChinese": "港元 A 類股份",
              "nameCustodian": "CHS6",
              "namePMA": "HKD Share Class A",
              "pcfCode": "CSTN_HSTECH_INV",
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2020-12-09T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "IMC Asia Pacific Limited",
                      "nameChinese": "IMC Asia Pacific Limited",
                      "partyCategory": "MM",
                      "partyID": "MM511"
                    },
                    {
                      "name": "DRW Singapore Pte. Ltd",
                      "nameChinese": "DRW Singapore Pte. Ltd",
                      "partyCategory": "MM",
                      "partyID": "MM503"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM511",
                      "PartyNameInChinese": "IMC Asia Pacific Limited",
                      "PartyNameInEnglish": "IMC Asia Pacific Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM503",
                      "PartyNameInChinese": "DRW Singapore Pte. Ltd",
                      "PartyNameInEnglish": "DRW Singapore Pte. Ltd"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000672946"
                    },
                    {
                      "type": "BBG",
                      "value": "7226 HK EQUITY"
                    },
                    {
                      "type": "EXCH",
                      "value": "7226"
                    },
                    {
                      "type": "REUTER",
                      "value": "7226.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7226.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CHS6"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    }
                  ],
                  "ticker": "7226 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "400000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2020-12-09T00:00:00Z",
          "custodianFundCode": "CHS6",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000126",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000126",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2020-12-09T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Swap-based Synthetic replication strategy",
          "liMultiplier": 2,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2020-12-09T00:00:00.000Z",
              "liMultiplier": 2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 400000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Hang Seng TECH Index Daily (2x) Leveraged Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "16:23",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "13:23",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "18:00",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao ",
            "nameChinese": "科特斯瓦拉朗",
            "partyCategory": "PMGR",
            "partyID": "757",
            "references": []
          },
          "preferredClass": "CLS00182",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-HST-L"
            },
            {
              "type": "PCF_CODE",
              "value": "CSTN_HSTECH_LEV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Hang Seng TECH Index Daily (2x) Leveraged Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CHS6"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "skipCutOffCheck": false,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "tradarFundLongName": "CSOP Hang Seng TECH Index Daily (2x) Leveraged Product",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "LI",
          "updatedAt": "2022-12-03T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000127",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2020-12-08T00:00:00.000Z",
                  "name": "HSTECH Index",
                  "ticker": "HSTECH Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00183",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "HKD",
              "inceptionDate": "2020-12-09T00:00:00.000Z",
              "lotSize": 400000,
              "managementFee": "1.6",
              "mktPriceValue": null,
              "name": "HKD Share Class A",
              "nameCh": "HK-HST-2I",
              "nameChinese": "港元 A 類股份",
              "nameCustodian": "CHS7",
              "namePMA": "HKD Share Class A",
              "pcfCode": "CSTN_HSTECH_2INV",
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2020-12-09T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "IMC Asia Pacific Limited",
                      "nameChinese": "IMC Asia Pacific Limited",
                      "partyCategory": "MM",
                      "partyID": "MM511"
                    },
                    {
                      "name": "DRW Singapore Pte. Ltd",
                      "nameChinese": "DRW Singapore Pte. Ltd",
                      "partyCategory": "MM",
                      "partyID": "MM503"
                    },
                    {
                      "name": "Optiver Trading Hong Kong Limited",
                      "nameChinese": "Optiver Trading Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM518"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM511",
                      "PartyNameInChinese": "IMC Asia Pacific Limited",
                      "PartyNameInEnglish": "IMC Asia Pacific Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM503",
                      "PartyNameInChinese": "DRW Singapore Pte. Ltd",
                      "PartyNameInEnglish": "DRW Singapore Pte. Ltd"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM518",
                      "PartyNameInChinese": "Optiver Trading Hong Kong Limited",
                      "PartyNameInEnglish": "Optiver Trading Hong Kong Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000672953"
                    },
                    {
                      "type": "EXCH",
                      "value": "7552"
                    },
                    {
                      "type": "BBG",
                      "value": "7552 HK EQUITY"
                    },
                    {
                      "type": "REUTER",
                      "value": "7552.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7552.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CHS7"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    }
                  ],
                  "ticker": "7552 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "400000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2020-12-09T00:00:00Z",
          "custodianFundCode": "CHS7",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000127",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000127",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2020-12-09T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Swap-based synthetic replication strategy",
          "liMultiplier": -2,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2020-12-09T00:00:00.000Z",
              "liMultiplier": -2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 400000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Hang Seng TECH Index Daily (-2x) Inverse Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "16:15",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "14:15",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "18:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00183",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-HST-2I"
            },
            {
              "type": "PCF_CODE",
              "value": "CSTN_HSTECH_2INV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Hang Seng TECH Index Daily (-2x) Inverse Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CHS7"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG923",
            "references": []
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "tradarFundLongName": "CSOP Hang Seng TECH Index Daily (-2x) Inverse Product",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "LI",
          "updatedAt": "2022-12-03T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000128",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2021-01-18T00:00:00.000Z",
                  "name": "FTSE China A50 Index",
                  "ticker": "XIN9I Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00184",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "HK-A50-L",
              "inceptionDate": "2021-01-19T00:00:00.000Z",
              "lotSize": 400000,
              "managementFee": "1.60",
              "mktPriceValue": "LAST_PRICE",
              "name": "USD Share Class A",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "USD Share Class A",
              "pcfCode": "CSTN_FTSEA",
              "shareClassAcctCode": "zzzConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2021-01-20T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Investment Limited",
                      "nameChinese": "安联资本投资有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM904",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7248"
                    },
                    {
                      "type": "REUTER",
                      "value": "7248.HK"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000687415"
                    },
                    {
                      "type": "BBG",
                      "value": "7248 HK EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7248.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    }
                  ],
                  "ticker": "7248 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "400000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-01-18T00:00:00Z",
          "custodianFundCode": "CA5L",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK",
            "CN"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000128",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000128",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2021-01-20T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Swap-based synthetic replication strategy",
          "liMultiplier": 2,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2021-01-20T00:00:00.000Z",
              "liMultiplier": 2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 400000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP FTSE China A50 Index Daily (2x) Leveraged Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "12:27",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "12:27",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "18:30",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00184",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-A50-L"
            },
            {
              "type": "PCF_CODE",
              "value": "CSTN_FTSEA50_LEV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP FTSE China A50 Index Daily (2x) Leveraged Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CA5L"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG910",
            "references": []
          },
          "skipCutOffCheck": false,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "tradarFundLongName": "CSOP FTSE China A50 Index Daily (2x) Leveraged Product",
          "trusteeID": {
            "location": "HK",
            "name": "cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS909",
            "references": []
          },
          "type": "LI",
          "updatedAt": "2022-12-06T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000129",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "INDX",
          "assetClassDescription": "Index",
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2021-01-18T00:00:00.000Z",
                  "name": "FTSE China A50 Index",
                  "ticker": "XIN9I Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00185",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "HK-A50-I",
              "inceptionDate": "2021-01-19T00:00:00.000Z",
              "lotSize": 400000,
              "managementFee": "1.60",
              "name": "RMB Share Class A",
              "nameChinese": "人民幣A股",
              "nameCustodian": "A",
              "namePMA": "RMB Share Class A",
              "pcfCode": "CSTN_FTSEA",
              "shareClassAcctCode": "zzzConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2021-01-20T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7348"
                    },
                    {
                      "type": "REUTER",
                      "value": "7348.HK"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000687423"
                    },
                    {
                      "type": "BBG",
                      "value": "7348 HK EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7348.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    }
                  ],
                  "ticker": "7348 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "400000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-01-18T00:00:00Z",
          "custodianFundCode": "CA5I",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK",
            "CN"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000129",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000129",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2021-01-20T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Swap-based synthetc replication strategy",
          "liMultiplier": -1,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2021-01-20T00:00:00.000Z",
              "liMultiplier": -1,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 400000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP FTSE China A50 Index Daily (-1x) Inverse Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "12:15",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "12:20",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "18:30",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00185",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-A50-I"
            },
            {
              "type": "PCF_CODE",
              "value": "CSTN_FTSEA50_INV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP FTSE China A50 Index Daily (-1x) Inverse Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CA5I"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG910",
            "references": []
          },
          "skipCutOffCheck": false,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "tradarFundLongName": "CSOP FTSE China A50 Index Daily (-1x) Inverse Product",
          "trusteeID": {
            "location": "HK",
            "name": "cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS909",
            "references": []
          },
          "type": "LI",
          "updatedAt": "2022-12-06T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000131",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [
            {
              "ccy": "HKD",
              "fromDate": "2021-05-04T21:30:00+05:30",
              "name": "Hang Seng Index",
              "ticker": "HSINH Index",
              "toDate": "2099-12-31T10:30:00+05:30"
            }
          ],
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2021-05-04T21:30:00+05:30",
                  "name": "Hang Seng Index",
                  "ticker": "HSINH Index",
                  "toDate": "2099-12-31T10:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00204",
              "classIDName": "Unlisted Share Class A",
              "classType": "UNLISTED",
              "description": "Unlisted Share Class A",
              "inceptionDate": "2022-06-30T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "Unlisted Share Class A",
              "nameChinese": "",
              "nameCustodian": "2",
              "namePMA": "HKD Share Class unlisted A",
              "references": [
                {
                  "type": "BBG",
                  "value": "3037UA HK EQUITY"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000862059"
                },
                {
                  "type": "REUTER",
                  "value": "3037.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "2"
                },
                {
                  "type": "PMA",
                  "value": "HKD Share Class unlisted A"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "HKD",
                  "fromDate": "2021-05-04T21:30:00+05:30",
                  "name": "Hang Seng Index",
                  "ticker": "HSINH Index",
                  "toDate": "2099-12-31T10:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00187",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "HK-HSI",
              "inceptionDate": "2021-05-05T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 1000000,
              "managementFee": "0.1",
              "marketMakers": {
                "en": [
                  "BNP Paribas Securities (Asia) Limited",
                  "Flow Traders Hong Kong Limited",
                  "Head \u0026 Shoulders Securities Limited",
                  "HSBC Securities Brokers (Asia) Limited ",
                  "Vivienne Court Trading Pty. Ltd.",
                  "Optiver Trading Hong Kong Limited",
                  "Korea Investment \u0026 Securities Asia Limited"
                ],
                "tch": [
                  "法國巴黎證券(亞洲)有限公司",
                  "Flow Traders Hong Kong Limited",
                  "Head \u0026 Shoulders Securities Limited",
                  "HSBC Securities Brokers (Asia) Limited ",
                  "Vivienne Court Trading Pty. Ltd.",
                  "Optiver Trading Hong Kong Limited",
                  "Korea Investment \u0026 Securities Asia Limited"
                ]
              },
              "name": "HK-HSI",
              "nameChinese": "",
              "nameCustodian": "1",
              "namePMA": "HKD Share Class A",
              "references": [
                {
                  "type": "EXCH",
                  "value": "3037"
                },
                {
                  "type": "REUTER",
                  "value": "3037.HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000720745"
                },
                {
                  "type": "8IGHT",
                  "value": "3037.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "1"
                },
                {
                  "type": "PMA",
                  "value": "HKD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "3037 HK EQUITY"
                }
              ],
              "shareClassAcctCode": "zzzConCapHKD",
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [
                    {
                      "name": "BNP Paribas Securities (Asia) Limited",
                      "nameChinese": "法國巴黎證券(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM498"
                    },
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "name": "Head \u0026 Shoulders Securities Limited",
                      "nameChinese": "聯合證券有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM509"
                    },
                    {
                      "name": "HSBC Securities Brokers (Asia) Limited",
                      "nameChinese": "滙豐證券經紀(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM510"
                    },
                    {
                      "name": "Optiver Trading Hong Kong Limited",
                      "nameChinese": "Optiver Trading Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM518"
                    },
                    {
                      "name": "Korea Investment \u0026 Securities Asia Limited",
                      "nameChinese": "韓國投資證券亞洲有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM516"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3037"
                    },
                    {
                      "type": "REUTER",
                      "value": "3037.HK"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000720745"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3037.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "1"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "3037 HK EQUITY"
                    }
                  ],
                  "ticker": "3037 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "1000000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-04-09T05:30:00+05:30",
          "custodian": {
            "en": "BOCI-Prudential Trustee Limited",
            "tch": "中銀國際英國保誠信託有限公司"
          },
          "custodianFundCode": "CSOPHSIETF",
          "custodianID": {
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "CUST",
            "partyID": "CU528"
          },
          "custodianReconParty": "BOC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {
            "en": "Semi-Annually ",
            "tch": "每半年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000131",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000131",
          "investmentAdvisor": {},
          "investmentStrategy": {
            "en": "Full Replication Strategy",
            "tch": "全面複製策略"
          },
          "legalStructure": {
            "en": "Hong Kong Unit trust",
            "tch": "香港單位信托"
          },
          "location": "Hong Kong",
          "lotSize": 1000000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資産管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP Hang Seng Index ETF",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "BOC_HK",
          "onShoreAccID": "BOC_CN",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "Head \u0026 Shoulders Securities Limited ",
              "nameChinese": "聯合證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD465"
            }
          ],
          "participatingDealers": {
            "en": [
              "BNP Paribas SA",
              "Citigroup Global Markets Asia Limited",
              "China International Capital Corporation Hong Kong Securities Limited",
              "Haitong International Securities Company Limited",
              "The Hongkong and Shanghai Banking Corporation Limited",
              "Huatai Financial Holdings (Hong Kong) Limited",
              "Mirae Asset Securities (HK) Limited",
              "SG Securities (HK) Limited",
              "Head \u0026 Shoulders Securities Limited "
            ],
            "tch": [
              "法國巴黎銀行全球托管行",
              "花旗環球金融亞洲有限公司",
              "中國國際金融香港證券有限公司",
              "海通國際證券有限公司",
              "香港上海滙豐銀行有限公司",
              "華泰金融控股(香港)有限公司",
              "未來資產證券(香港)有限公司",
              "法國興業證券(香港)有限公司",
              "聯合證券有限公司"
            ]
          },
          "pmaCustodianCode": "BOC_HK",
          "pmaNavFileTime": "17:30",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00187",
          "productType": "EE",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-HSI"
            },
            {
              "type": "PCF_CODE",
              "value": "CSOPHSI"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Hang Seng Index ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSOPHSIETF"
            }
          ],
          "registrar": {
            "en": "BOCI-Prudential Trustee Limited",
            "tch": "中銀國際英國保誠信託有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP Hang Seng Index ETF",
          "type": "EE",
          "updatedAt": "2022-12-13T16:01:12.491+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000132",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "USD",
                  "fromDate": "2021-05-11T00:00:00.000Z",
                  "name": "Solactive Global Cloud Computing Technology Index",
                  "ticker": "SOCLOUDN Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00188",
              "classIDName": "USD",
              "classNameInChinese": "",
              "classType": "LISTED",
              "description": "3194 HK",
              "inceptionDate": "2021-05-12T00:00:00.000Z",
              "lotSize": 300000,
              "managementFee": "0.99",
              "mktPriceValue": "LAST_PRICE",
              "name": "USD Share Class A\t",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "USD Share Class A",
              "pcfCode": "CLOUD",
              "shareClassAcctCode": "zzzConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2021-05-12T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Management (Hong Kong) Limited",
                      "nameChinese": "安联资本管理（香港）有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM905",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    },
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "name": "Valuable Capital Limited",
                      "nameChinese": "華盛資本証券有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM521"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM905",
                      "PartyNameInChinese": "安联资本管理（香港）有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM506",
                      "PartyNameInChinese": "Flow Traders Hong Kong Limited",
                      "PartyNameInEnglish": "Flow Traders Hong Kong Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM521",
                      "PartyNameInChinese": "華盛資本証券有限公司",
                      "PartyNameInEnglish": "Valuable Capital Limited"
                    }
                  ],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3194"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000733870"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3194.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "3194 HK EQUITY"
                    }
                  ],
                  "ticker": "3194 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "300000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-05-07T00:00:00Z",
          "custodianID": {
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "CUST",
            "partyID": "CU530"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK",
            "US"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000132",
          "indexCalendar": [
            "HK",
            "US"
          ],
          "indexCondition": "AND",
          "indexSla": "T1",
          "instrumentID": "1000132",
          "investmentStrategies": [
            {
              "fromDate": "2021-05-12T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Strategy",
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 300000,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "CSOP Global Cloud Computing Technology Index ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "16:51",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            }
          ],
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "07:00",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao ",
            "nameChinese": "科特斯瓦拉朗",
            "partyCategory": "PMGR",
            "partyID": "757",
            "references": []
          },
          "preferredClass": "CLS00188",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-CLOUD"
            },
            {
              "type": "PCF_CODE",
              "value": "CLOUD"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Global Cloud Computing Technology Index ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CGCT"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG910",
            "references": []
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "trusteeID": {
            "location": "HK",
            "name": "cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS909",
            "references": []
          },
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000133",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2021-05-30T00:00:00.000Z",
                  "name": "CSI Photovoltaic Industry Index",
                  "ticker": "SH931151 Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00189",
              "classIDName": "RMB",
              "classType": "LISTED",
              "description": "RMB",
              "inceptionDate": "2021-05-31T00:00:00.000Z",
              "lotSize": 300000,
              "managementFee": "0.99",
              "mktPriceValue": "LAST_PRICE",
              "name": "RMB Share Class A",
              "nameChinese": "人民幣A股",
              "nameCustodian": "A",
              "namePMA": "RMB Share Class A",
              "pcfCode": "CSOPPTVT",
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2021-05-31T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "name": "Jane Street Financial Limited",
                      "nameChinese": "Jane Street Financial Limited",
                      "partyCategory": "MM",
                      "partyID": "MM513"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM506",
                      "PartyNameInChinese": "Flow Traders Hong Kong Limited",
                      "PartyNameInEnglish": "Flow Traders Hong Kong Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM513",
                      "PartyNameInChinese": "Jane Street Financial Limited",
                      "PartyNameInEnglish": "Jane Street Financial Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3134"
                    },
                    {
                      "type": "BBG",
                      "value": "3134 HK EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3134.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    }
                  ],
                  "ticker": "3134 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "300000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-05-25T00:00:00Z",
          "custodianFundCode": "CHPC",
          "custodianID": {
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "CUST",
            "partyID": "CU530"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "CN",
            "HK"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000133",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000133",
          "investmentStrategies": [
            {
              "fromDate": "2021-05-31T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Invest atleast 90% of in Master Fund",
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 300000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Huatai-PineBridge CSI Photovoltaic Industry ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "13:19",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "11:19",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Valuable Capital Limited",
              "nameChinese": "華盛資本証券有限公司",
              "partyCategory": "PD",
              "partyID": "PD492"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            }
          ],
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "18:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00189",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-PTVT"
            },
            {
              "type": "PCF_CODE",
              "value": "CSOPPTVT"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Huatai-PineBridge CSI Photovoltaic Industry ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CHPC"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "Tricor Investor Services Limited",
            "nameChinese": "卓佳证券投资有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG926",
            "references": []
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "tradarFundLongName": "CSOP Huatai-PineBridge CSI Photovoltaic Industry ETF",
          "trusteeID": {
            "location": "HK",
            "name": "Tricor Investor Services Limited",
            "nameChinese": "卓佳证券投资有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS925",
            "references": []
          },
          "type": "ETF",
          "updatedAt": "2022-12-06T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000134",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "HKD",
                  "fromDate": "2021-07-19T00:00:00.000Z",
                  "name": "Solactive China Healthcare Disruption Index",
                  "ticker": "SOLCHCDN Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00190",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "HK-HEALTH",
              "inceptionDate": "2021-07-20T00:00:00.000Z",
              "lotSize": 300000,
              "managementFee": "0.99",
              "mktPriceValue": null,
              "name": "HK-HEALTH",
              "nameCh": "南方東英中國醫療健康革新指數ETF",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "HKD Share Class A",
              "pcfCode": "HC",
              "shareClassAcctCode": "zzzConCapHKD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2021-07-21T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3174"
                    },
                    {
                      "type": "REUTER",
                      "value": "3174.HK"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000756202"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3174.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "HKD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "3174 HK EQUITY"
                    }
                  ],
                  "ticker": "3174 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "300000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-07-19T00:00:00Z",
          "custodianFundCode": "CSCH",
          "custodianID": {
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "CUST",
            "partyID": "CU528"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000134",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000134",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2021-07-20T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Replication Engineering",
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 300000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP China Healthcare Disruption Index ETF",
          "nameChinese": "",
          "nav1SignOffCutOff": "18:48",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Valuable Capital Limited",
              "nameChinese": "華盛資本証券有限公司",
              "partyCategory": "PD",
              "partyID": "PD492"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Goldman Sachs (Asia) Securities Limited",
              "nameChinese": "高盛(亞洲)證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD461"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            }
          ],
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "18:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00190",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-HEALTH"
            },
            {
              "type": "PCF_CODE",
              "value": "HC"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP China Healthcare Disruption Index ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSCH"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG910",
            "references": []
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "tradarFundLongName": "CSOP China Healthcare Disruption Index ETF",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS901",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000135",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equities",
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2021-08-10T21:30:00+05:30",
              "name": "Solactive Global Smart Driving Index",
              "ticker": "SGDRIVEN Index",
              "toDate": "2099-12-31T10:30:00+05:30"
            }
          ],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "USD",
                  "fromDate": "2021-08-10T16:00:00.000Z",
                  "name": "Solactive Global Smart Driving Index",
                  "ticker": "SGDRIVEN Index",
                  "toDate": "2099-12-31T05:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00191",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "3162 HK",
              "inceptionDate": "2021-08-11T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 300000,
              "managementFee": "0.99",
              "marketMakers": {
                "en": [
                  "Flow Traders Hong Kong Limited"
                ],
                "tch": [
                  "Flow Traders Hong Kong Limited"
                ]
              },
              "name": "HK-SDRIVE",
              "nameCh": " 南方東英全球智能駕駛指數ETF",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "EXCH",
                  "value": "3162"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000765286"
                },
                {
                  "type": "8IGHT",
                  "value": "3162.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "A"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "3162 HK EQUITY"
                }
              ],
              "shareClassAcctCode": "zzzConCapUSD",
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3162"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000765286"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3162.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "3162 HK EQUITY"
                    }
                  ],
                  "ticker": "3162 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "300000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-08-09T00:00:00Z",
          "custodian": {},
          "custodianID": {
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "CUST",
            "partyID": "CU530"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "CN",
            "HK"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000135",
          "indexCalendar": [
            "CN",
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000135",
          "investmentAdvisor": {},
          "investmentStrategies": [],
          "investmentStrategy": {
            "en": "Physical Representative Sampling Strategy",
            "tch": "實物代表性抽樣策略"
          },
          "investmentStrategyEn": "SDRIVE",
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信托"
          },
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 300000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資產管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP Global Smart Driving Index ETF",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Valuable Capital Limited",
              "nameChinese": "華盛資本証券有限公司",
              "partyCategory": "PD",
              "partyID": "PD492"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            }
          ],
          "participatingDealers": {
            "en": [
              "Citigroup Global Markets Asia Limited",
              "\tMirae Asset Securities (HK) Limited",
              "China International Capital Corporation Hong Kong Securities Limited",
              "Haitong International Securities Company Limited",
              "Huatai Financial Holdings (Hong Kong) Limited",
              "Valuable Capital Limited",
              "SG Securities (HK) Limited"
            ],
            "tch": [
              "花旗環球金融亞洲有限公司",
              "未來資產證券(香港)有限公司",
              "中國國際金融香港證券有限公司",
              "海通國際證券有限公司",
              "華泰金融控股(香港)有限公司",
              "華盛資本証券有限公司",
              "法國興業證券(香港)有限公司"
            ]
          },
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "07:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "prcCustodianID": null,
          "preferredClass": "CLS00191",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-SDRIVE"
            },
            {
              "type": "PCF_CODE",
              "value": "SD"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Global Smart Driving Index ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSGS"
            }
          ],
          "registrar": {
            "en": "Tricor Investor Services Limited ",
            "tch": "卓佳證券登記有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "slaTypeNAV2": "T1",
          "type": "ETF",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000136",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "INDX",
          "assetClassDescription": "Index",
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "USD",
                  "fromDate": "2021-10-18T00:00:00.000Z",
                  "name": "Solactive Gold 1-Day Rolling Futures Index",
                  "ticker": "SOLGOLD1 Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00192",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "7374 HK",
              "inceptionDate": "2021-10-19T00:00:00.000Z",
              "lotSize": 800000,
              "managementFee": "1.5",
              "mktPriceValue": "LAST_PRICE",
              "name": "USD Share Class A",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "USD Share Class A",
              "pcfCode": "GOLD_INV",
              "shareClassAcctCode": "zzzConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2021-10-20T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "SETTLEMENT_PRICE",
                  "mms": [],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7374"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000787140"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7374.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7374 HK EQUITY"
                    }
                  ],
                  "ticker": "7374 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-10-18T00:00:00Z",
          "custodianFundCode": "CSGI",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "US",
            "HK"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000136",
          "indexCalendar": [
            "US"
          ],
          "indexCondition": "AND",
          "indexSla": "T1",
          "instrumentID": "1000136",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2020-10-20T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategyID": "937",
          "liMultiplier": -1,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2021-10-19T00:00:00.000Z",
              "liMultiplier": -1,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP Gold Futures Daily (-1x) Inverse Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "12:00",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            }
          ],
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "07:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00192",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-GLD-I"
            },
            {
              "type": "PCF_CODE",
              "value": "GOLD_INV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Gold Futures Daily (-1x) Inverse Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSGI"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG910",
            "references": []
          },
          "slaSignOffTime": "08:15",
          "slaType": "T1",
          "tradarFundLongName": "CSOP Gold Futures Daily (-1x) Inverse Product",
          "trusteeID": {
            "location": "HK",
            "name": "cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS909",
            "references": []
          },
          "type": "LI",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000137",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [
            {
              "ccy": "SGD",
              "fromDate": "2021-11-16T21:30:00+05:30",
              "name": "iEdge S-REIT Leaders Index",
              "ticker": "SREITLSN Index",
              "toDate": "2099-12-31T10:30:00+05:30"
            }
          ],
          "ccy": "SGD",
          "ccyOps": "SGD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "SGD",
                  "fromDate": "2021-11-16T21:30:00+05:30",
                  "name": "iEdge S-REIT Leaders Index",
                  "ticker": "SREITLSN Index",
                  "toDate": "2099-12-31T10:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "SGD",
              "classID": "CLS00193",
              "classIDName": "SGD",
              "classType": "LISTED",
              "description": "SGD",
              "inceptionDate": "2021-11-17T05:30:00+05:30",
              "lastDistribution": {
                "en": ""
              },
              "lotSize": 50000,
              "managementFee": "0.5",
              "marketMakers": {
                "en": [
                  "Phillip Securities Pte Ltd",
                  "Flow Traders Asia Pte. Ltd"
                ],
                "tch": [
                  "Phillip Securities Pte Ltd ",
                  "Flow Traders Asia Pte. Ltd"
                ]
              },
              "name": "SG-REIT",
              "nameCh": "SG-REIT",
              "nameChinese": "",
              "nameCustodian": "CISR",
              "namePMA": "SGD Share Class A",
              "references": [
                {
                  "type": "EXCH",
                  "value": "SRT"
                },
                {
                  "type": "REUTER",
                  "value": "CSOP.SI"
                },
                {
                  "type": "ISIN",
                  "value": "SGXC35358685"
                },
                {
                  "type": "BBG",
                  "value": "SRT SP EQUITY"
                },
                {
                  "type": "8IGHT",
                  "value": "SRT SRU"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CISR"
                },
                {
                  "type": "PMA",
                  "value": "SGD Share Class A"
                }
              ],
              "shareClassAcctCode": "zzzConCapSGD",
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [
                    {
                      "name": "Phillip Securities Pte Ltd",
                      "nameChinese": "Phillip Securities Pte Ltd",
                      "partyCategory": "MM",
                      "partyID": "MM519"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "SRT"
                    },
                    {
                      "type": "REUTER",
                      "value": "CSOP.SI"
                    },
                    {
                      "type": "ISIN",
                      "value": "SGXC35358685"
                    },
                    {
                      "type": "BBG",
                      "value": "SRT SP EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "SRT SRU"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CISR"
                    },
                    {
                      "type": "PMA",
                      "value": "SGD Share Class A"
                    }
                  ],
                  "ticker": "SRT SP EQUITY",
                  "tickerCcy": "SGD"
                },
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [
                    {
                      "name": "Phillip Securities Pte Ltd",
                      "nameChinese": "Phillip Securities Pte Ltd",
                      "partyCategory": "MM",
                      "partyID": "MM519"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "SRT"
                    },
                    {
                      "type": "REUTER",
                      "value": "CSOP.SI"
                    },
                    {
                      "type": "ISIN",
                      "value": "SGXC35358685"
                    },
                    {
                      "type": "BBG",
                      "value": "SRT SP EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "SRT SRU"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CISR"
                    },
                    {
                      "type": "PMA",
                      "value": "SGD Share Class A"
                    }
                  ],
                  "ticker": "SRU SP EQUITY",
                  "tickerCcy": "USD"
                }
              ],
              "tradingLotSize": "10",
              "underlierStockFillDate": "T0",
              "unitsCu": "50000"
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "SGD",
                  "fromDate": "2021-11-16T21:30:00+05:30",
                  "name": "iEdge S-REIT Leaders Index",
                  "ticker": "SREITLSN Index",
                  "toDate": "2099-12-31T10:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "SGD",
              "classID": "CLS00194",
              "classIDName": "USD",
              "description": "USD",
              "inceptionDate": "2021-11-16T21:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 50000,
              "marketMakers": {
                "en": [
                  "Phillip Securities Pte Ltd",
                  "Flow Traders Asia Pte. Ltd"
                ],
                "tch": [
                  "Phillip Securities Pte Ltd",
                  "Flow Traders Asia Pte. Ltd"
                ]
              },
              "name": "SG_REIT",
              "nameCh": "SG_REIT",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "SRU"
                },
                {
                  "type": "REUTER",
                  "value": "CSOP-D.SI"
                },
                {
                  "type": "ISIN",
                  "value": "SGXC35358685"
                },
                {
                  "type": "BBG",
                  "value": "SRU SP EQUITY"
                }
              ],
              "tickers": [],
              "tradingLotSize": "10"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-11-15T05:30:00+05:30",
          "custodian": {
            "en": "HSBC Institutional Trust Services (Singapore)  Limited"
          },
          "custodianFundCode": "CISR",
          "custodianID": {
            "name": "HSBC Institutional Trust Services (Singapore)  Limited",
            "nameChinese": "HSBC Institutional Trust Services (Singapore)  Limited",
            "partyCategory": "CUST",
            "partyID": "CU531"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "SG"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {
            "en": "Semi-annually"
          },
          "entity": "SG",
          "exchangeListing": {
            "en": "SGX-ST – Main Board"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000137",
          "indexCalendar": [
            "SG"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000137",
          "investmentAdvisor": {},
          "investmentStrategy": {
            "en": "Full Replication Strategy"
          },
          "legalStructure": {},
          "location": "Singapore",
          "lotSize": 50000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Pte. Ltd"
          },
          "maturityAllocations": [],
          "name": "CSOP iEdge S-REIT Leaders Index ETF",
          "nameChinese": "",
          "navCalendar": [
            "SG"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_SG",
          "onShoreAccID": "HSBC_CN",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "UOB Kay Hian Private Limited",
              "nameChinese": "UOB Kay Hian Private Limited",
              "partyCategory": "PD",
              "partyID": "PD491"
            },
            {
              "name": "Tiger Brokers (Singapore) Pte Ltd",
              "nameChinese": "Tiger Brokers (Singapore) Pte Ltd",
              "partyCategory": "PD",
              "partyID": "PD488"
            },
            {
              "name": "Phillip Securities Pte Ltd",
              "nameChinese": "Phillip Securities Pte Ltd",
              "partyCategory": "PD",
              "partyID": "PD483"
            },
            {
              "name": "iFAST Financial Pte Ltd",
              "nameChinese": "iFAST Financial Pte Ltd",
              "partyCategory": "PD",
              "partyID": "PD468"
            },
            {
              "name": "Futu Singapore Pte Ltd",
              "nameChinese": "Futu Singapore Pte Ltd,",
              "partyCategory": "PD",
              "partyID": "PD459"
            },
            {
              "name": "DBS Vickers Securities (Singapore) Pte Ltd",
              "nameChinese": "DBS Vickers Securities (Singapore) Pte Ltd,",
              "partyCategory": "PD",
              "partyID": "PD455"
            },
            {
              "name": "Flow Traders Asia Pte. Ltd",
              "nameChinese": "Flow Traders Asia Pte. Ltd,",
              "partyCategory": "PD",
              "partyID": "PD458"
            },
            {
              "name": "Jane Street Financial Limited",
              "nameChinese": "Jane Street Financial Limited",
              "partyCategory": "PD",
              "partyID": "PD470"
            }
          ],
          "participatingDealers": {
            "en": [
              "UOB Kay Hian Private Limited",
              "Tiger Brokers (Singapore) Pte Ltd",
              "Phillip Securities Pte Ltd",
              "iFAST Financial Pte Ltd",
              "Futu Singapore Pte Ltd",
              "DBS Vickers Securities (Singapore) Pte Ltd",
              "CGS-CIMB Securities (Singapore) Pte. Ltd",
              "Flow Traders Asia Pte. Ltd",
              "Jane Street Financial Limited"
            ],
            "tch": []
          },
          "pmaCustodianCode": "HSBC_SG",
          "pmaNavFileTime": "18:30",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00193",
          "productType": "EE",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "SG-REIT"
            },
            {
              "type": "PCF_CODE",
              "value": "CSOPREITS"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP IEDGE S-REIT LEADERS INDEX ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CISR"
            }
          ],
          "registrar": {
            "en": "HSBC Institutional Trust Services (Singapore) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP IEDGE S-REIT LEADERS INDEX ETF",
          "type": "EE",
          "updatedAt": "2022-12-03T15:16:48.183+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000138",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "USD",
                  "fromDate": "2021-11-29T00:00:00.000Z",
                  "name": "Solactive WTI 1-Day Rolling Futures Index",
                  "ticker": "SOLWTI1 Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00195",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "7345 HK",
              "inceptionDate": "2021-11-30T00:00:00.000Z",
              "lotSize": 800000,
              "managementFee": "1.6",
              "mktPriceValue": null,
              "name": "USD Share Class A",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "USD Share Class A",
              "pcfCode": "OIL_INV",
              "shareClassAcctCode": "zzzConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "listingDate": "2021-11-30T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mktPriceValue": "SETTLEMENT_PRICE",
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7345"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000799285"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7345.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7345 HK EQUITY"
                    }
                  ],
                  "ticker": "7345 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "800000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-11-25T00:00:00Z",
          "custodianFundCode": "CSWC",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK",
            "US"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000138",
          "indexCalendar": [
            "US"
          ],
          "indexCondition": "AND",
          "indexSla": "T1",
          "instrumentID": "1000138",
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2021-11-30T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategyID": "934",
          "liMultiplier": -1,
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2021-11-30T00:00:00.000Z",
              "liMultiplier": -1,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Hong Kong",
          "lotSize": 800000,
          "managed": "YES",
          "maturityAllocations": [],
          "name": "CSOP WTI Crude Oil Futures Daily (-1x) Inverse Product",
          "nameChinese": "",
          "nav1SignOffCutOff": "11:59",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "WEEKLY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            }
          ],
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "07:15",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao ",
            "nameChinese": "科特斯瓦拉朗",
            "partyCategory": "PMGR",
            "partyID": "757",
            "references": []
          },
          "preferredClass": "CLS00195",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-OIL-I"
            },
            {
              "type": "PCF_CODE",
              "value": "OIL_INV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP WTI Crude Oil Futures Daily (-1x) Inverse Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSWC"
            }
          ],
          "registrarID": {
            "location": "HK",
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG910",
            "references": []
          },
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "tradarFundLongName": "CSOP WTI Crude Oil Futures Daily (-1x) Inverse Product",
          "trusteeID": {
            "location": "HK",
            "name": "cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "TRUS",
            "partyID": "TS909",
            "references": []
          },
          "type": "LI",
          "updatedAt": "2022-12-13T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000139",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "benchmarkIndex": [
            {
              "ccy": "CNY",
              "fromDate": "2021-12-09T21:30:00+05:30",
              "name": "MSCI China A 50 Connect Index",
              "ticker": "MXA50CNC Index",
              "toDate": "2099-12-31T10:30:00+05:30"
            }
          ],
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2021-12-09T00:00:00.000Z",
                  "name": "MSCI China A 50 Connect Index",
                  "ticker": "MXA50CNC Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00196",
              "classIDName": "RMB",
              "classType": "LISTED",
              "description": "RMB",
              "inceptionDate": "2021-12-10T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 500000,
              "managementFee": "0.99",
              "marketMakers": {
                "en": [
                  "Flow Traders Hong Kong Limited",
                  "Optiver Trading Hong Kong Limited",
                  "DRW Singapore Pte. Ltd"
                ],
                "tch": [
                  "Flow Traders Hong Kong Limited",
                  "Optiver Trading Hong Kong Limited",
                  "DRW Singapore Pte. Ltd"
                ]
              },
              "mktPriceValue": "LAST_PRICE",
              "name": "HK-MSCIA50",
              "nameCh": "南方東英MSCI中國A50互聯互通ETF",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "RMB Share Class A",
              "pcf_code": "HKMSCIA",
              "references": [
                {
                  "type": "ISIN",
                  "value": "HK0000805785"
                },
                {
                  "type": "BBG",
                  "value": "3003 HK EQUITY"
                },
                {
                  "type": "EXCH",
                  "value": "3003"
                },
                {
                  "type": "8IGHT",
                  "value": "3003.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "A"
                },
                {
                  "type": "PMA",
                  "value": "RMB Share Class A"
                }
              ],
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    "MM506",
                    "MM518",
                    "MM503"
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000805785"
                    },
                    {
                      "type": "BBG",
                      "value": "3003 HK EQUITY"
                    },
                    {
                      "type": "EXCH",
                      "value": "3003"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3003.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    }
                  ],
                  "ticker": "3003 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0",
              "unitsCu": "500000"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2021-12-08T00:00:00Z",
          "custodian": {},
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "CN",
            "HK"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK - Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000139",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000139",
          "investmentAdvisor": {
            "tch": ""
          },
          "investmentStrategies": [
            {
              "fromDate": "2022-12-14T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {
            "en": "Physical representative and synthetic representative sampling strategy",
            "tch": "實物代表性抽樣及合成代表性抽樣策略的組合"
          },
          "investmentStrategyEn": "Investment starategy",
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信托"
          },
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 500000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資産管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP MSCI China A 50 Connect ETF",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "Korea Investment \u0026 Securities Asia Limited",
              "nameChinese": "韓國投資證券亞洲有限公司",
              "partyCategory": "PD",
              "partyID": "PD472"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Valuable Capital Limited",
              "nameChinese": "華盛資本証券有限公司",
              "partyCategory": "PD",
              "partyID": "PD492"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            },
            {
              "name": "Goldman Sachs (Asia) Securities Limited",
              "nameChinese": "高盛(亞洲)證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD461"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "J.P. Morgan Broking (Hong Kong) Limited",
              "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
              "partyCategory": "PD",
              "partyID": "PD469"
            }
          ],
          "participatingDealers": {
            "en": [
              "Korea Investment \u0026 Securities Asia Limited",
              "Haitong International Securities Company Limited",
              "China International Capital Corporation Hong Kong Securities Limited",
              "Valuable Capital Limited",
              "Mirae Asset Securities (HK) Limited",
              "SG Securities (HK) Limited",
              "Citigroup Global Markets Asia Limited",
              "Barclays Bank PLC",
              "BNP Paribas SA",
              "Goldman Sachs (Asia) Securities Limited",
              "Huatai Financial Holdings (Hong Kong) Limited",
              "ABN AMRO Clearing Hong Kong Limited",
              "China Merchants Securities (HK) Co Limited",
              "J.P. Morgan Broking (Hong Kong) Limited"
            ],
            "tch": [
              "韓國投資證券亞洲有限公司",
              "海通國際證券有限公司",
              "中國國際金融香港證券有限公司",
              "華盛資本証券有限公司",
              "未來資產證券(香港)有限公司",
              "法國興業證券(香港)有限公司",
              "花旗環球金融亞洲有限公司",
              "Barclays Bank PLC",
              "法國巴黎銀行全球托管行",
              "高盛(亞洲)證券有限公司",
              "華泰金融控股(香港)有限公司",
              "ABN AMRO Clearing Hong Kong Limited",
              "招商證券（香港）有限公司",
              "J.P. Morgan Broking (Hong Kong) Limited"
            ]
          },
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "18:30",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {
            "en": "CITIBANK (CHINA) CO., LTD",
            "tch": ""
          },
          "preferredClass": "CLS00196",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-MSCIA50"
            },
            {
              "type": "PCF_CODE",
              "value": "MSCIA50"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP MSCI China A 50 Connect ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CM50"
            }
          ],
          "registrar": {
            "en": "Cititrust Limited",
            "tch": "卓佳證券登記有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "20:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "type": "ETF",
          "updatedAt": "2022-12-06T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000140",
          "active": "YES",
          "activeStatus": true,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "HKD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00200",
              "classIDName": "HKD",
              "description": "Test2",
              "inceptionDate": "2022-02-03T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 500000,
              "managementFee": "0.99",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "Test2",
              "nameChinese": "",
              "references": [
                {
                  "type": "BBG",
                  "value": "3067 HK Equity"
                },
                {
                  "type": "EXCH",
                  "value": "3067"
                },
                {
                  "type": "REUTER",
                  "value": "3067.HK"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000651213"
                }
              ],
              "tickers": [],
              "tradingLotSize": "200"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-02-04T16:32:01.972+05:30",
          "custodian": {
            "en": "Custodian 3"
          },
          "dividendFrequency": {
            "en": "Annually"
          },
          "exchangeListing": {
            "en": "SEHK – Main Board"
          },
          "fees": [],
          "fundID": "1000140",
          "instrumentID": "1000140",
          "investmentAdvisor": {},
          "investmentStrategy": {
            "en": "Active"
          },
          "legalStructure": {
            "en": "Hong Kong Unit trust"
          },
          "location": "Hong Kong",
          "lotSize": 500000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited"
          },
          "maturityAllocations": [],
          "name": "CSOP Metaverse Dummy ETF",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00200",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "TEST2"
            }
          ],
          "registrar": {
            "en": "Hong Kong"
          },
          "riskfreeIndex": {},
          "type": "EM",
          "updatedAt": "2022-02-04T16:32:01.972+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000141",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00201",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "USD",
              "inceptionDate": "2022-02-18T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 300000,
              "managementFee": "0.99",
              "marketMakers": {
                "en": [
                  "Flow Traders Hong Kong Limited",
                  "Optiver Trading Hong Kong Limited"
                ],
                "tch": [
                  "Flow Traders Hong Kong Limited",
                  "Optiver Trading Hong Kong Limited"
                ]
              },
              "name": "USD",
              "nameCh": "USD",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "EXCH",
                  "value": "3034"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000825338"
                },
                {
                  "type": "REUTER",
                  "value": "3034.HK"
                },
                {
                  "type": "8IGHT",
                  "value": "3034.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "A"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "3034 HK EQUITY"
                }
              ],
              "shareClassAcctCode": "zzzConCapUSD",
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [
                    {
                      "name": "Flow Traders Hong Kong Limited",
                      "nameChinese": "Flow Traders Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM506"
                    },
                    {
                      "name": "Optiver Trading Hong Kong Limited",
                      "nameChinese": "Optiver Trading Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM518"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "3034"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000825338"
                    },
                    {
                      "type": "REUTER",
                      "value": "3034.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3034.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "3034 HK EQUITY"
                    }
                  ],
                  "ticker": "3034 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-02-14T05:30:00+05:30",
          "custodian": {
            "en": "Cititrust Limited",
            "tch": "花旗信托有限公司"
          },
          "custodianFundCode": "CSME",
          "custodianID": {
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "CUST",
            "partyID": "CU530"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK",
            "US"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK - Main Board",
            "tch": "香港聯交所 ─ 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000141",
          "indexCalendar": [
            "US"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000141",
          "investmentAdvisor": {},
          "investmentStrategy": {
            "en": "Actively Managed Investment Strategy",
            "tch": "主動管理投資策略"
          },
          "legalStructure": {
            "en": "Open-ended Fund Company",
            "tch": "開放式基金型公司"
          },
          "location": "Hong Kong",
          "lotSize": 300000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資產管理有限公司"
          },
          "marketType": "multiMarket",
          "maturityAllocations": [],
          "name": "CSOP Metaverse Concept ETF",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CIT_CN",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Valuable Capital Limited",
              "nameChinese": "華盛資本証券有限公司",
              "partyCategory": "PD",
              "partyID": "PD492"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Long Bridge HK Limited",
              "nameChinese": "長橋證券(香港)有限公司 ",
              "partyCategory": "PD",
              "partyID": "PD473"
            },
            {
              "name": "Lu International (Hong Kong) Limited",
              "nameChinese": "陸國際(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD474"
            }
          ],
          "participatingDealers": {
            "en": [
              "Haitong International Securities Company Limited",
              "China International Capital Corporation Hong Kong Securities Limited",
              "SG Securities (HK) Limited",
              "Citigroup Global Markets Asia Limited",
              "Valuable Capital Limited",
              "Mirae Asset Securities (HK) Limited",
              "Long Bridge HK Limited",
              "Lu International (Hong Kong) Limited"
            ],
            "tch": [
              "海通國際證券有限公司",
              "中國國際金融香港證券有限公司",
              "法國興業證券(香港)有限公司",
              "花旗環球金融亞洲有限公司",
              "華盛資本証券有限公司",
              "未來資產證券(香港)有限公司",
              "長橋證券(香港)有限公司 ",
              "陸國際(香港)有限公司"
            ]
          },
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "07:15",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00201",
          "productType": "EE",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-METAV"
            },
            {
              "type": "PCF_CODE",
              "value": "MTVS"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP METAVERSE CONCEPT ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSME"
            }
          ],
          "registrar": {
            "en": "Tricor Investor Services Limited ",
            "tch": "卓佳證券登記有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP METAVERSE CONCEPT ETF",
          "type": "EE",
          "updatedAt": "2022-12-13T16:12:22.382+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000142",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00205",
              "classIDName": "USD",
              "classType": "UNLISTED",
              "description": "USD",
              "inceptionDate": "2022-07-12T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "USD",
              "nameCh": "",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "BBG",
                  "value": "CSOPUMM SP EQUITY"
                },
                {
                  "type": "ISIN",
                  "value": "SGXZ96797238"
                },
                {
                  "type": "8IGHT",
                  "value": "CSOPUMM"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "A"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                }
              ],
              "shareClassAcctCode": "zzzConCapUSD",
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-07-12T00:00:00Z",
          "custodian": {},
          "custodianID": {
            "name": "HSBC Institutional Trust Services (Singapore)  Limited",
            "nameChinese": "HSBC Institutional Trust Services (Singapore)  Limited",
            "partyCategory": "CUST",
            "partyID": "CU531"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "SG"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "SG",
          "exchangeListing": {},
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000142",
          "indexCalendar": [
            "SG"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000142",
          "investmentAdvisor": {},
          "investmentStrategies": [
            {
              "fromDate": "2022-07-12T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {
            "en": "Actively Managed Investment Strategy"
          },
          "investmentStrategyEn": "Actively Managed Investment Strategy ",
          "legalStructure": {
            "en": "Variable Capital Company"
          },
          "liMultipliers": [],
          "location": "Singapore",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Pte. Ltd."
          },
          "maturityAllocations": [],
          "name": "CSOP USD Money Market Fund",
          "nameChinese": "",
          "navCalendar": [
            "SG"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITI",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "ME",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "11:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "prcCustodianID": null,
          "preferredClass": "CLS00205",
          "productType": "MF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "SG-USDMF"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP USD MONEY MARKET FUND"
            },
            {
              "type": "CUSTODIAN",
              "value": "CUMF"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "13:00",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "type": "MF",
          "updatedAt": "2022-12-03T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000143",
          "active": "YES",
          "activeStatus": true,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00206",
              "classIDName": "A",
              "description": "A",
              "inceptionDate": "2015-10-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "A",
              "nameCh": "A",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CG PARTNER"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:00:48.232+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000143",
          "instrumentID": "1000143",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CG Partner",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00206",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "0009"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "type": "OT",
          "updatedAt": "2022-08-23T08:00:48.232+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000144",
          "active": "YES",
          "activeStatus": true,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00207",
              "classIDName": "USD",
              "description": "USD",
              "inceptionDate": "2016-02-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "USD",
              "nameCh": "USD",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "ROCK CREEK"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:11:14.679+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000144",
          "instrumentID": "1000144",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "United States",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "Rock Creek",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00207",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "0005"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "type": "OT",
          "updatedAt": "2022-08-23T08:11:14.679+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000145",
          "active": "YES",
          "activeStatus": true,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "CNY",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00208",
              "classIDName": "A",
              "description": "A",
              "inceptionDate": "2016-03-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "A",
              "nameCh": "A",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "FOTIC-A"
                }
              ],
              "tickers": []
            },
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00209",
              "classIDName": "B",
              "description": "B",
              "inceptionDate": "2016-03-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "B",
              "nameCh": "B",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "FOTIC-B"
                }
              ],
              "tickers": []
            },
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00210",
              "classIDName": "C",
              "description": "C",
              "inceptionDate": "2016-03-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "C",
              "nameCh": "C",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "FOTIC-C"
                }
              ],
              "tickers": []
            },
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00211",
              "classIDName": "D",
              "description": "D",
              "inceptionDate": "2016-03-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "D",
              "nameCh": "D",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "FOTIC-D"
                }
              ],
              "tickers": []
            },
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00212",
              "classIDName": "E",
              "description": "E",
              "inceptionDate": "2016-03-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "E",
              "nameCh": "E",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "FOTIC-E"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:15:13.247+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000145",
          "instrumentID": "1000145",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "China",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "Fotic-HK Connect",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00208",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "0034"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "type": "OT",
          "updatedAt": "2022-08-23T08:15:13.247+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000146",
          "active": "YES",
          "activeStatus": true,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "HKD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00213",
              "classIDName": "HKD",
              "description": "HKD",
              "inceptionDate": "2017-11-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "HKD",
              "nameCh": "HKD",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "HKU"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:18:38.698+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000146",
          "instrumentID": "1000146",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "HKU",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00213",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "0006"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "type": "OT",
          "updatedAt": "2022-08-23T08:18:38.698+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000147",
          "active": "YES",
          "activeStatus": true,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "CNY",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00214",
              "classIDName": "CNY",
              "description": "CNY",
              "inceptionDate": "2017-07-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CNY",
              "nameCh": "CNY",
              "nameChinese": "中國新年",
              "references": [
                {
                  "type": "EXCH",
                  "value": "PING AN"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:22:47.125+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000147",
          "instrumentID": "1000147",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "China",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "平安鑫福26号港股通",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00214",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "0035"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "type": "OT",
          "updatedAt": "2022-08-23T08:22:47.125+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000148",
          "active": "YES",
          "activeStatus": true,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "CNY",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00215",
              "classIDName": "CNY",
              "description": "CNY",
              "inceptionDate": "2018-01-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CNY",
              "nameCh": "CNY",
              "nameChinese": "中國新年",
              "references": [
                {
                  "type": "EXCH",
                  "value": "SHE BAO"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:25:37.117+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000148",
          "instrumentID": "1000148",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "China",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "社保1303港股通",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00215",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "0023"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "type": "OT",
          "updatedAt": "2022-08-23T08:25:37.117+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000149",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "HKD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00216",
              "classIDName": "A",
              "description": "A",
              "inceptionDate": "2015-02-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "A",
              "nameCh": "A",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CMB"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:27:22.594+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000149",
          "instrumentID": "1000149",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "CMB",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00216",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "CMB"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2018-12-31T00:00:00.000Z",
          "type": "OT",
          "updatedAt": "2022-08-23T08:27:22.594+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000150",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "CNY",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00217",
              "classIDName": "CNY",
              "description": "CNY",
              "inceptionDate": "2017-06-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CNY",
              "nameCh": "CNY",
              "nameChinese": "中國新年",
              "references": [
                {
                  "type": "EXCH",
                  "value": "ICBCA"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:31:38.408+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000150",
          "instrumentID": "1000150",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "China",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "ICBCA",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00217",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "0007"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2020-12-31T00:00:00.000Z",
          "type": "OT",
          "updatedAt": "2022-08-23T08:31:38.408+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000151",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "CNY",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00218",
              "classIDName": "CNY",
              "description": "CNY",
              "inceptionDate": "2018-03-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CNY",
              "nameCh": "CNY",
              "nameChinese": "中國新年",
              "references": [
                {
                  "type": "EXCH",
                  "value": "DONG GUAN"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:33:16.78+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000151",
          "instrumentID": "1000151",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "China",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "东莞信托·汇信港股通",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00218",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "东莞信托"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2018-11-30T00:00:00.000Z",
          "type": "OT",
          "updatedAt": "2022-08-23T08:33:16.78+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000152",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "TWD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "TWD",
              "classID": "CLS00219",
              "classIDName": "TWD",
              "description": "TWD",
              "inceptionDate": "2015-04-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "TWD",
              "nameCh": "TWD",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "CATHAY"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:37:01.707+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000152",
          "instrumentID": "1000152",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Taiwan",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "Cathay FTSE A50 ETF",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00219",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "0008"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2021-01-31T00:00:00.000Z",
          "type": "OT",
          "updatedAt": "2022-08-23T08:37:01.707+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000153",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "AUD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "AUD",
              "classID": "CLS00220",
              "classIDName": "A",
              "description": "A",
              "inceptionDate": "2017-07-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "A",
              "nameCh": "A",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "OCEANA"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:39:23.645+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000153",
          "instrumentID": "1000153",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "South Korea",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "Oceana",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00220",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "OCEANA"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2020-01-31T00:00:00.000Z",
          "type": "OT",
          "updatedAt": "2022-08-23T08:39:23.645+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000154",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "CNY",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00221",
              "classIDName": "CNY",
              "description": "CNY",
              "inceptionDate": "2015-08-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CNY",
              "nameCh": "CNY",
              "nameChinese": "中國新年",
              "references": [
                {
                  "type": "EXCH",
                  "value": "JOEONE"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:42:31.388+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000154",
          "instrumentID": "1000154",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "China",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "JoeOne",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00221",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "JOEONE"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2019-08-31T00:00:00.000Z",
          "type": "OT",
          "updatedAt": "2022-08-23T08:42:31.388+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000155",
          "active": "NO",
          "activeStatus": false,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "CNY",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00222",
              "classIDName": "CNY",
              "description": "CNY",
              "inceptionDate": "2021-06-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CNY",
              "nameCh": "CNY",
              "nameChinese": "中國新年",
              "references": [
                {
                  "type": "EXCH",
                  "value": "ZI JING"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:45:26.028+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000155",
          "instrumentID": "1000155",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "China",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "鑫泰紫荆花1号集合",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00222",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "0038"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "terminatedDate": "2021-11-30T00:00:00.000Z",
          "type": "OT",
          "updatedAt": "2022-08-23T08:45:26.028+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000156",
          "active": "YES",
          "activeStatus": true,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00223",
              "classIDName": "USD",
              "description": "USD",
              "inceptionDate": "2020-05-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "USD",
              "nameCh": "USD",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "FINETREE"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:47:02.228+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000156",
          "instrumentID": "1000156",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "Finetree",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00223",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "0022"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "type": "OT",
          "updatedAt": "2022-08-23T08:47:02.228+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000157",
          "active": "YES",
          "activeStatus": true,
          "assetClassDescription": "",
          "benchmarkIndex": [],
          "ccy": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00224",
              "classIDName": "USD",
              "description": "USD",
              "inceptionDate": "2018-11-01T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "USD",
              "nameCh": "USD",
              "nameChinese": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "GAMA"
                }
              ],
              "tickers": []
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-08-23T08:48:31.314+05:30",
          "custodian": {
            "en": "N/A"
          },
          "dividendFrequency": {},
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000157",
          "instrumentID": "1000157",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Hong Kong",
          "lotSize": 0,
          "managed": "YES",
          "manager": {},
          "maturityAllocations": [],
          "name": "GAMA",
          "nameChinese": "",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00224",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "FLEX_SYSTEM_CODE",
              "value": "GAMA"
            }
          ],
          "registrar": {
            "en": "N/A"
          },
          "riskfreeIndex": {},
          "type": "OT",
          "updatedAt": "2022-08-23T08:48:31.314+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000158",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equities",
          "benchmarkIndex": [
            {
              "ccy": "VND",
              "fromDate": "2022-09-19T05:30:00+05:30",
              "name": "FTSE Vietnam 30 Index",
              "ticker": "FIVNM3NV Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "ccy": "VND",
          "ccyOps": "VND",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "VND",
                  "fromDate": "2022-09-19T00:00:00.000Z",
                  "name": "FTSE Vietnam 30 Index",
                  "ticker": "FIVNM3NV Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "VND",
              "classID": "CLS00225",
              "classIDName": "VND",
              "classType": "LISTED",
              "description": "VND",
              "inceptionDate": "2022-09-19T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 300000,
              "managementFee": "0.99",
              "marketMakers": {
                "en": [
                  "Flow Traders Hong Kong Limited"
                ],
                "tch": []
              },
              "mktPriceValue": "LAST_PRICE",
              "name": "VND",
              "nameCh": "VND",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "VND Share Class A",
              "pcf_code": "Test",
              "references": [
                {
                  "type": "BBG",
                  "value": "3004 HK EQUITY"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000873700"
                },
                {
                  "type": "EXCH",
                  "value": "3004"
                },
                {
                  "type": "REUTER",
                  "value": "3004.HK"
                },
                {
                  "type": "8IGHT",
                  "value": "3004.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "A"
                },
                {
                  "type": "PMA",
                  "value": "VND Share Class A"
                }
              ],
              "shareClassAcctCode": "zzzConCapUSD",
              "terminationDate": null,
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "LAST_PRICE",
                  "mms": [
                    null
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "BBG",
                      "value": "3004 HK EQUITY"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000873700"
                    },
                    {
                      "type": "EXCH",
                      "value": "3004"
                    },
                    {
                      "type": "REUTER",
                      "value": "3004.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3004.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "VND Share Class A"
                    }
                  ],
                  "ticker": "3004 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "totalExpenseRatio": "",
              "tradingLotSize": "100",
              "underlierStockFillDate": "T1"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-09-13T00:00:00Z",
          "custodian": {},
          "custodianFundCode": "CSV1",
          "custodianID": {
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "CUST",
            "partyID": "CU530"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK",
            "VN"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000158",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000158",
          "investmentAdvisor": {},
          "investmentStrategies": [
            {
              "fromDate": "2022-09-19T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {
            "en": "Physical and synthetic representative sampling strategy ",
            "tch": "實物和合成代表性抽樣策略"
          },
          "investmentStrategyEn": "Test1",
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信托"
          },
          "liMultipliers": [],
          "location": "Hong Kong",
          "lotSize": 300000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資產管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP FTSE Vietnam 30 ETF",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITVN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Long Bridge HK Limited",
              "nameChinese": "長橋證券(香港)有限公司 ",
              "partyCategory": "PD",
              "partyID": "PD473"
            },
            {
              "name": "Huatai Financial Holdings (Hong Kong) Limited",
              "nameChinese": "華泰金融控股(香港)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD466"
            },
            {
              "name": "Valuable Capital Limited",
              "nameChinese": "華盛資本証券有限公司",
              "partyCategory": "PD",
              "partyID": "PD492"
            },
            {
              "name": "Guotai Junan Securities (Hong Kong) Limited",
              "nameChinese": "國泰君安證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD462"
            },
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "SG Securities (HK) Limited",
              "nameChinese": "法國興業證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD484"
            }
          ],
          "participatingDealers": {
            "en": [
              "Haitong International Securities Company Limited ",
              "China International Capital Corporation Hong Kong Securities Limited",
              "Mirae Asset Securities (HK) Limited",
              "Citigroup Global Markets Asia Limited",
              "Long Bridge HK Limited",
              "Huatai Financial Holdings (Hong Kong) Limited",
              "Valuable Capital Limited",
              "Guotai Junan Securities (Hong Kong) Limited",
              "GF Securities (Hong Kong) Brokerage Limited",
              "SG Securities (HK) Limited",
              "The Hongkong and Shanghai Banking Corporation Limited"
            ],
            "tch": []
          },
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "18:30",
          "portfolioManager": "AijultrR45",
          "prcCustodian": {},
          "prcCustodianID": null,
          "preferredClass": "CLS00225",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-VN30"
            },
            {
              "type": "PCF_CODE",
              "value": "VN30"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP FTSE Vietnam 30 ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSV1"
            }
          ],
          "registrar": {
            "en": "Tricor Investor Services Limited ",
            "tch": "卓佳證券登記有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "20:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP FTSE Vietnam 30 ETF",
          "type": "ETF",
          "updatedAt": "2022-12-06T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000159",
          "active": "YES",
          "activeStatus": true,
          "assetClassDescription": "",
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2022-09-27T05:30:00+05:30",
              "name": "FTSE Asia Pacific Low Carbon Select Index",
              "ticker": "GPCSP7UR Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2022-09-27T05:30:00+05:30",
                  "name": "FTSE Asia Pacific Low Carbon Select Index",
                  "ticker": "GPCSP7UR Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00226",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "USD",
              "inceptionDate": "2022-09-27T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 200000,
              "managementFee": "0.6",
              "marketMakers": {
                "en": [
                  "Phillip Securities Pte Ltd",
                  "Flow Traders Asia Pte. Ltd."
                ],
                "tch": [
                  "Phillip Securities Pte Ltd",
                  "Flow Traders Asia Pte. Ltd."
                ]
              },
              "name": "USD",
              "nameCh": "USD",
              "nameChinese": "",
              "nameCustodian": "CALC",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "EXCH",
                  "value": "LCU"
                },
                {
                  "type": "ISIN",
                  "value": "SGXC58537918"
                },
                {
                  "type": "REUTER",
                  "value": "COPC-D.SI"
                },
                {
                  "type": "BBG",
                  "value": "LCU SP EQUITY"
                },
                {
                  "type": "8IGHT",
                  "value": "LCU LCS"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CALC"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                }
              ],
              "shareClassAcctCode": "zzzConCapUSD",
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [
                    {
                      "name": "Phillip Securities Pte Ltd",
                      "nameChinese": "Phillip Securities Pte Ltd",
                      "partyCategory": "MM",
                      "partyID": "MM519"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "LCU"
                    },
                    {
                      "type": "ISIN",
                      "value": "SGXC58537918"
                    },
                    {
                      "type": "REUTER",
                      "value": "COPC-D.SI"
                    },
                    {
                      "type": "BBG",
                      "value": "LCU SP EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "LCU LCS"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CALC"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    }
                  ],
                  "ticker": "LCU SP EQUITY",
                  "tickerCcy": "USD"
                },
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [
                    {
                      "name": "Phillip Securities Pte Ltd",
                      "nameChinese": "Phillip Securities Pte Ltd",
                      "partyCategory": "MM",
                      "partyID": "MM519"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "LCU"
                    },
                    {
                      "type": "ISIN",
                      "value": "SGXC58537918"
                    },
                    {
                      "type": "REUTER",
                      "value": "COPC-D.SI"
                    },
                    {
                      "type": "BBG",
                      "value": "LCU SP EQUITY"
                    },
                    {
                      "type": "8IGHT",
                      "value": "LCU LCS"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CALC"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    }
                  ],
                  "ticker": "LCS SP EQUITY",
                  "tickerCcy": "SGD"
                }
              ],
              "tradingLotSize": "1",
              "underlierStockFillDate": "T0"
            },
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2022-09-27T05:30:00+05:30",
                  "name": "FTSE Asia Pacific Low Carbon Select Index",
                  "ticker": "GPCSP7UR Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00227",
              "classIDName": "SGD",
              "description": "SGD",
              "inceptionDate": "2022-09-28T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 200000,
              "managementFee": "0.6",
              "marketMakers": {
                "en": [
                  "Phillip Securities Pte Ltd",
                  "Flow Traders Asia Pte. Ltd."
                ],
                "tch": [
                  "Phillip Securities Pte Ltd",
                  "Flow Traders Asia Pte. Ltd."
                ]
              },
              "name": "SGD",
              "nameCh": "SGD",
              "nameChinese": "",
              "nameCustodian": "",
              "namePMA": "",
              "references": [
                {
                  "type": "EXCH",
                  "value": "LCS"
                },
                {
                  "type": "ISIN",
                  "value": "SGXC58537918"
                },
                {
                  "type": "REUTER",
                  "value": "COPC.SI"
                },
                {
                  "type": "BBG",
                  "value": "LCS SP EQUITY"
                }
              ],
              "tickers": [],
              "tradingLotSize": "1"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-09-20T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited"
          },
          "custodianFundCode": "CALC",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "SG",
            "AU",
            "HK",
            "IN",
            "JP",
            "KR",
            "TW"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually"
          },
          "entity": "SG",
          "exchangeListing": {
            "en": "SGX-ST – Main Board"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000159",
          "indexCalendar": [
            "HK",
            "IN",
            "JP",
            "KR",
            "TW"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000159",
          "investmentAdvisor": {
            "en": "CGS-CIMB Securities (Singapore) Pte. Ltd."
          },
          "investmentStrategy": {
            "en": "Representative Sampling Strategy "
          },
          "legalStructure": {},
          "location": "Singapore",
          "lotSize": 200000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Pte. Ltd."
          },
          "marketType": "multiMarket",
          "maturityAllocations": [],
          "name": "CSOP CGS-CIMB FTSE Asia Pacific Low Carbon Index ETF",
          "nameChinese": "",
          "navCalendar": [
            "SG",
            "AU",
            "HK",
            "IN",
            "JP",
            "KR",
            "TW"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsFundType": "EE",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaCustodianCode": "HSBC_SG",
          "pmaNavFileTime": "19:15",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00226",
          "productType": "EE",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "SG-ALC"
            },
            {
              "type": "PCF_CODE",
              "value": "LC"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP CGS-CIMB FTSE Asia Pacific Low Carbon Index ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CALC"
            }
          ],
          "registrar": {
            "en": "HSBC Institutional Trust Services (Singapore) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP CGS-CIMB FTSE Asia Pacific Low Carbon Index ETF",
          "type": "EE",
          "updatedAt": "2022-12-06T12:03:12.427+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000160",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [
            {
              "ccy": "CNY",
              "fromDate": "2022-10-18T05:30:00+05:30",
              "name": "CSI All Share Investment Banking \u0026 Brokerage Index",
              "ticker": "SH399975 Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "CNY",
                  "fromDate": "2022-10-18T05:30:00+05:30",
                  "name": "CSI All Share Investment Banking \u0026 Brokerage Index",
                  "ticker": "SH399975 Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "CNY",
              "classID": "CLS00229",
              "classIDName": "RMB",
              "classType": "LISTED",
              "description": "HK-IBB-L",
              "inceptionDate": "2022-10-18T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 400000,
              "managementFee": "1.6",
              "marketMakers": {
                "en": [
                  "Optiver Trading Hong Kong Limited"
                ],
                "tch": [
                  "Optiver Trading Hong Kong Limited"
                ]
              },
              "name": "HK-IBB-L",
              "nameCh": "",
              "nameChinese": "",
              "nameCustodian": "A",
              "namePMA": "RMB Share Class A",
              "references": [
                {
                  "type": "ISIN",
                  "value": "HK0000884129"
                },
                {
                  "type": "BBG",
                  "value": "7252 HK EQUITY"
                },
                {
                  "type": "EXCH",
                  "value": "7252"
                },
                {
                  "type": "8IGHT",
                  "value": "7252.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "A"
                },
                {
                  "type": "PMA",
                  "value": "RMB Share Class A"
                }
              ],
              "shareClassAcctCode": "zzzConCapRMB",
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [
                    {
                      "name": "Optiver Trading Hong Kong Limited",
                      "nameChinese": "Optiver Trading Hong Kong Limited",
                      "partyCategory": "MM",
                      "partyID": "MM518"
                    }
                  ],
                  "mktPriceValue": "LAST_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "HK0000884129"
                    },
                    {
                      "type": "BBG",
                      "value": "7252 HK EQUITY"
                    },
                    {
                      "type": "EXCH",
                      "value": "7252"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7252.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "A"
                    },
                    {
                      "type": "PMA",
                      "value": "RMB Share Class A"
                    }
                  ],
                  "ticker": "7252 HK Equity",
                  "tickerCcy": "HKD"
                }
              ],
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-10-05T05:30:00+05:30",
          "custodian": {
            "en": "Cititrust Limited",
            "tch": "花旗信託有限公司"
          },
          "custodianFundCode": "CSBL",
          "custodianID": {
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "CUST",
            "partyID": "CU530"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK",
            "CN"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 ─ 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000160",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000160",
          "investmentAdvisor": {},
          "investmentStrategy": {
            "en": "Swap-based synthetic replication strategy",
            "tch": "以掉期為基礎的合成模擬策略"
          },
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信託"
          },
          "liMultiplier": 2,
          "location": "Hong Kong",
          "lotSize": 400000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資產管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP CSI Brokerage Index Daily (2x) Leveraged Product",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "CITI",
          "onShoreAccID": "CITCN",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "GF Securities (Hong Kong) Brokerage Limited",
              "nameChinese": "廣發証券(香港)經紀有限公司,",
              "partyCategory": "PD",
              "partyID": "PD460"
            },
            {
              "name": "China International Capital Corporation Hong Kong Securities Limited",
              "nameChinese": "中國國際金融香港證券有限公司,",
              "partyCategory": "PD",
              "partyID": "PD444"
            },
            {
              "name": "Citigroup Global Markets Asia Limited",
              "nameChinese": "花旗環球金融亞洲有限公司,",
              "partyCategory": "PD",
              "partyID": "PD450"
            },
            {
              "name": "Haitong International Securities Company Limited",
              "nameChinese": "海通國際證券有限公司",
              "partyCategory": "PD",
              "partyID": "PD464"
            },
            {
              "name": "Mirae Asset Securities (HK) Limited",
              "nameChinese": "未來資產證券(香港)有限公司",
              "partyCategory": "PD",
              "partyID": "PD478"
            }
          ],
          "participatingDealers": {
            "en": [
              "GF Securities (Hong Kong) Brokerage Limited",
              "China International Capital Corporation Hong Kong Securities Limited",
              "Citigroup Global Markets Asia Limited",
              "Haitong International Securities Company Limited",
              "Mirae Asset Securities (HK) Limited"
            ],
            "tch": [
              "廣發証券(香港)經紀有限公司",
              "中國國際金融香港證券有限公司",
              "花旗環球金融亞洲有限公司",
              "海通國際證券有限公司",
              "未來資產證券(香港)有限公司"
            ]
          },
          "pmaCustodianCode": "CITI",
          "pmaNavFileTime": "18:30",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00229",
          "productType": "LE",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-IBB-L"
            },
            {
              "type": "PCF_CODE",
              "value": "BROKERAGE_LEV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP CSI Brokerage Index Daily (2x) Leveraged Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSBL"
            }
          ],
          "registrar": {
            "en": "Cititrust Limited \u0026 Computershare Hong Kong Investor Services Limited",
            "tch": "花旗信託有限公司/ 香港中央證券登記有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP CSI Brokerage Index Daily (2x) Leveraged Product",
          "type": "LE",
          "updatedAt": "2022-12-06T12:03:35.886+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000161",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2022-11-22T05:30:00+05:30",
              "name": "Solactive US Large Oil \u0026 Gas Companies Index NTR",
              "ticker": "SOULOGCN Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2022-11-22T05:30:00+05:30",
                  "name": "Solactive US Large Oil \u0026 Gas Companies Index NTR",
                  "ticker": "SOULOGCN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00230",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "USD",
              "inceptionDate": "2022-11-23T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 400000,
              "managementFee": "1.6",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "USD",
              "nameCh": "USD",
              "nameChinese": "",
              "nameCustodian": "COG1",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "EXCH",
                  "value": "7204"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000891363"
                },
                {
                  "type": "REUTER",
                  "value": "7204.HK"
                },
                {
                  "type": "8IGHT",
                  "value": "7204.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "COG1"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "7204 HK EQUITY"
                }
              ],
              "shareClassAcctCode": "zzzConCapUSD",
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "SETTLEMENT_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7204"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000891363"
                    },
                    {
                      "type": "REUTER",
                      "value": "7204.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7204.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "COG1"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7204 HK EQUITY"
                    }
                  ],
                  "ticker": "7204 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-11-11T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited",
            "tch": "香港上海滙豐銀行有限公司"
          },
          "custodianFundCode": "COG1",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK",
            "US"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000161",
          "indexCalendar": [
            "US"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000161",
          "investmentAdvisor": {},
          "investmentStrategy": {
            "en": "Swap-based synthetic replication strategy",
            "tch": "以掉期為基礎的合成模擬策略"
          },
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信托"
          },
          "liMultiplier": 2,
          "location": "Hong Kong",
          "lotSize": 400000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資產管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP US Large Oil \u0026 Gas Companies Daily (2x) Leveraged Product",
          "nameChinese": "",
          "navCalendar": [
            "HK",
            "US"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsFundType": "LE",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "07:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00230",
          "productType": "LE",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-OLG-L"
            },
            {
              "type": "PCF_CODE",
              "value": "OILGAS_LEV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP US Large Oil \u0026 Gas Companies Daily (2x) Leveraged Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "COG1"
            }
          ],
          "registrar": {
            "en": "HSBC Institutional Trust Services (Asia) Limited",
            "tch": "滙豐機構信託服務（亞洲）有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP US Large Oil \u0026 Gas Companies Daily (2x) Leveraged Product",
          "type": "LE",
          "updatedAt": "2022-12-13T16:12:59.913+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000162",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [
            {
              "ccy": "USD",
              "fromDate": "2022-11-22T05:30:00+05:30",
              "name": "Solactive US Large Oil \u0026 Gas Companies Index NTR",
              "ticker": "SOULOGCN Index",
              "toDate": "2099-12-31T05:30:00+05:30"
            }
          ],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "USD",
                  "fromDate": "2022-11-22T05:30:00+05:30",
                  "name": "Solactive US Large Oil \u0026 Gas Companies Index NTR",
                  "ticker": "SOULOGCN Index",
                  "toDate": "2099-12-31T05:30:00+05:30"
                }
              ],
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00231",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "USD",
              "inceptionDate": "2022-11-23T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 400000,
              "managementFee": "1.6",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "USD",
              "nameCh": "USD",
              "nameChinese": "",
              "nameCustodian": "COG2",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "EXCH",
                  "value": "7505"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000891355"
                },
                {
                  "type": "REUTER",
                  "value": "7505.HK"
                },
                {
                  "type": "8IGHT",
                  "value": "7505.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "COG2"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "7505 HK EQUITY"
                }
              ],
              "shareClassAcctCode": "zzzConCapUSD",
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "SETTLEMENT_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "EXCH",
                      "value": "7505"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000891355"
                    },
                    {
                      "type": "REUTER",
                      "value": "7505.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "7505.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "COG2"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "BBG",
                      "value": "7505 HK EQUITY"
                    }
                  ],
                  "ticker": "7505 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-11-11T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited",
            "tch": "香港上海滙豐銀行有限公司"
          },
          "custodianFundCode": "COG2",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK",
            "US"
          ],
          "dealingCondition": "OR",
          "dividendFrequency": {
            "en": "Annually",
            "tch": "每年"
          },
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000162",
          "indexCalendar": [
            "US"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000162",
          "investmentAdvisor": {},
          "investmentStrategy": {
            "en": "Swap-based synthetic replication strategy",
            "tch": "以掉期為基礎的合成模擬策略"
          },
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信托"
          },
          "liMultiplier": -2,
          "location": "Hong Kong",
          "lotSize": 400000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資產管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP US Large Oil \u0026 Gas Companies Daily (-2x) Inverse Product",
          "nameChinese": "",
          "navCalendar": [
            "HK",
            "US"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsFundType": "LE",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "07:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00231",
          "productType": "LE",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-OLG-I"
            },
            {
              "type": "PCF_CODE",
              "value": "OILGAS_INV"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP US Large Oil \u0026 Gas Companies Daily (-2x) Inverse Product"
            },
            {
              "type": "CUSTODIAN",
              "value": "COG2"
            }
          ],
          "registrar": {
            "en": "HSBC Institutional Trust Services (Asia) Limited",
            "tch": "滙豐機構信託服務（亞洲）有限公司"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP US Large Oil \u0026 Gas Companies Daily (-2x) Inverse Product",
          "type": "LE",
          "updatedAt": "2022-12-13T16:13:18.868+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000163",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00232",
              "classIDName": "USD",
              "classType": "UNLISTED",
              "description": "SG-CSB2",
              "inceptionDate": "2019-12-02T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "SG-CSB2",
              "nameChinese": "",
              "nameCustodian": "CSB2",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "test"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CSB2"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "CSOPGQG KY EQUITY"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-01T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited"
          },
          "custodianFundCode": "CSB2",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "SG"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "CYM",
          "exchangeListing": {},
          "fees": [],
          "fundClass": "Private",
          "fundID": "1000163",
          "indexCalendar": [
            "SG"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000163",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited"
          },
          "maturityAllocations": [],
          "name": "CSOP Global Quant Allocation Fund - Balanced II SP",
          "nameChinese": "",
          "navCalendar": [
            "SG"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaNavFileTime": "14:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00232",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "SG-CSB2"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Global Quant Allocation Fund - Balanced II SP"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSB2"
            }
          ],
          "registrar": {
            "en": "HSBC Trustee (Cayman) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "18:00",
          "slaType": "T1",
          "tradarFundLongName": "CSOP Global Quant Allocation Fund - Balanced II SP",
          "type": "OT",
          "updatedAt": "2022-12-13T16:19:17.945+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000164",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00233",
              "classIDName": "USD",
              "classType": "UNLISTED",
              "description": "SG-CSGT",
              "inceptionDate": "2019-10-01T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "SG-CSGT",
              "nameChinese": "",
              "nameCustodian": "CSGT",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "TEST"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CSGT"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "CSOPGQF KY EQUITY"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-01T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited"
          },
          "custodianFundCode": "CSGT",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "SG"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "CYM",
          "exchangeListing": {},
          "fees": [],
          "fundClass": "Private",
          "fundID": "1000164",
          "indexCalendar": [
            "SG"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000164",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited"
          },
          "maturityAllocations": [],
          "name": "CSOP Global Quant Allocation Fund - Balanced I SP",
          "nameChinese": "",
          "navCalendar": [
            "SG"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaNavFileTime": "14:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00233",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "SG-CSGT"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Global Quant Allocation Fund - Balanced I SP"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSGT"
            }
          ],
          "registrar": {
            "en": "HSBC Trustee (Cayman) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "18:00",
          "slaType": "T1",
          "tradarFundLongName": "CSOP Global Quant Allocation Fund - Balanced I SP",
          "type": "OT",
          "updatedAt": "2022-12-13T16:19:35.497+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000165",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00234",
              "classIDName": "USD",
              "classType": "UNLISTED",
              "description": "CO-CQHT",
              "inceptionDate": "2019-10-01T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "managementFee": "0.03",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CO-CQHT",
              "nameChinese": "",
              "nameCustodian": "CQHT",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "test"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CQHT"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "CSOPGQC KY EQUITY"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-01T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited"
          },
          "custodianFundCode": "CQHT",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "SG"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "CYM",
          "exchangeListing": {},
          "fees": [],
          "fundClass": "Private",
          "fundID": "1000165",
          "indexCalendar": [
            "SG"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000165",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited"
          },
          "maturityAllocations": [],
          "name": "CSOP Global Quant Allocation Dividend Fund - HT International SP I",
          "nameChinese": "",
          "navCalendar": [
            "SG"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaNavFileTime": "14:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00234",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CQHT"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Global Quant Allocation Dividend Fund - HT International SP I"
            },
            {
              "type": "CUSTODIAN",
              "value": "CQHT"
            }
          ],
          "registrar": {
            "en": "HSBC Trustee (Cayman) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "18:00",
          "slaType": "T1",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP Global Quant Allocation Dividend Fund - HT International SP I",
          "type": "OT",
          "updatedAt": "2022-12-13T16:19:55.001+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000166",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00236",
              "classIDName": "USD N1",
              "classType": "UNLISTED",
              "description": "CO-CSTF",
              "inceptionDate": "2020-06-01T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CO-CSTF",
              "nameChinese": "",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "TEST"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CFT2"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class N1"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            },
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00235",
              "classIDName": "USD A",
              "classType": "UNLISTED",
              "description": "CO-CSTF",
              "inceptionDate": "2020-06-01T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CO-CSTF",
              "nameChinese": "",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "TEST"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CFT1"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-01T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited"
          },
          "custodianFundCode": "CSFT",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "CYM",
          "exchangeListing": {},
          "fees": [],
          "fundID": "1000166",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000166",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited"
          },
          "maturityAllocations": [],
          "name": "CSOP Finetree Fund SP",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "opsFundType": "OT",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaNavFileTime": "14:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00235",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CSTF"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Finetree Fund SP"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSFT"
            }
          ],
          "registrar": {
            "en": "HSBC Trustee (Cayman) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "18:00",
          "slaType": "T1",
          "tradarFundLongName": "CSOP Finetree Fund SP",
          "type": "OT",
          "updatedAt": "2022-12-05T11:00:35.515+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000167",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00237",
              "classIDName": "USD",
              "classType": "UNLISTED",
              "description": "CO-MFOF",
              "inceptionDate": "2020-07-28T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "managementFee": "0.05",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CO-MFOF",
              "nameChinese": "",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "TEST"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CSFF"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class I"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-01T05:30:00+05:30",
          "custodian": {
            "en": "HSBC Institutional Trust Services (Asia) Limited"
          },
          "custodianID": {
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "匯豐機構信託服務(亞洲)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU526"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "CYM",
          "exchangeListing": {},
          "fees": [],
          "fundClass": "Private",
          "fundID": "1000167",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000167",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited"
          },
          "maturityAllocations": [],
          "name": "Multi Access Fund of Funds SP",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaNavFileTime": "14:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00237",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-MFOF"
            },
            {
              "type": "TRADER_NAME",
              "value": "Multi Access Fund of Funds SP"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSFF"
            }
          ],
          "registrar": {
            "en": "HSBC Trustee (Cayman) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "18:00",
          "slaType": "T1",
          "type": "OT",
          "updatedAt": "2022-12-05T20:33:47.35+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000168",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00238",
              "classIDName": "USD",
              "classType": "UNLISTED",
              "description": "CO-CQB2",
              "inceptionDate": "2019-08-01T21:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "managementFee": "0.002",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CO-CQB2",
              "nameChinese": "",
              "nameCustodian": "CQB2",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "TEST"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CQB2"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "CSOPGQE KY EQUITY"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-01T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited"
          },
          "custodianFundCode": "CQB2",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "CYM",
          "exchangeListing": {},
          "fees": [],
          "fundClass": "Private",
          "fundID": "1000168",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000168",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited"
          },
          "maturityAllocations": [],
          "name": "CSOP Global Quant Allocation Fund - Bocom Bond Plus II Greater Bay SP",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaNavFileTime": "14:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00238",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CQB2"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Global Quant Allocation Fund - Bocom Bond Plus II Greater Bay SP"
            },
            {
              "type": "CUSTODIAN",
              "value": "CQB2"
            }
          ],
          "registrar": {
            "en": "HSBC Trustee (Cayman) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "18:00",
          "slaType": "T1",
          "tradarFundLongName": "CSOP Global Quant Allocation Fund - Bocom Bond Plus II Greater Bay SP",
          "type": "OT",
          "updatedAt": "2022-12-13T16:25:16.668+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000169",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00239",
              "classIDName": "USD",
              "classType": "UNLISTED",
              "description": "CO-CQBP",
              "inceptionDate": "2019-03-01T21:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "managementFee": "0.003",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CO-CQBP",
              "nameChinese": "",
              "nameCustodian": "CQBP",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "TEST"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CQBP"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "CSOPGQB KY EQUITY"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-01T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited"
          },
          "custodianFundCode": "CQBP",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "CYM",
          "exchangeListing": {},
          "fees": [],
          "fundClass": "Private",
          "fundID": "1000169",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000169",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP ASSEST MANAGEMENT LIMITED "
          },
          "maturityAllocations": [],
          "name": "CSOP Global Quant Allocation Fund - Bond Plus SP",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaNavFileTime": "14:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00239",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CQBP"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Global Quant Allocation Fund - Bond Plus SP"
            },
            {
              "type": "CUSTODIAN",
              "value": "CQBP"
            }
          ],
          "registrar": {
            "en": "HSBC Trustee (Cayman) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "18:00",
          "slaType": "T1",
          "tradarFundLongName": "CSOP Global Quant Allocation Fund - Bond Plus SP",
          "type": "OT",
          "updatedAt": "2022-12-13T16:23:39.805+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000170",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00240",
              "classIDName": "USD",
              "classType": "UNLISTED",
              "description": "CO-CGQA",
              "inceptionDate": "2018-12-01T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "managementFee": "0.009",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CO-CGQA",
              "nameChinese": "",
              "nameCustodian": "CGQA",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "TEST"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CGQA"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "CSOPGQA KY EQUITY"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-01T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited"
          },
          "custodianFundCode": "CGQA",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "CYM",
          "exchangeListing": {},
          "fees": [],
          "fundClass": "Private",
          "fundID": "1000170",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000170",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited"
          },
          "maturityAllocations": [],
          "name": "CSOP Global Quant Allocation Fund I SP",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaNavFileTime": "14:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00240",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CGQA"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Global Quant Allocation Fund I SP"
            },
            {
              "type": "CUSTODIAN",
              "value": "CGQA"
            }
          ],
          "registrar": {
            "en": "HSBC Trustee (Cayman) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "18:00",
          "slaType": "T1",
          "tradarFundLongName": "CSOP Global Quant Allocation Fund I SP",
          "type": "OT",
          "updatedAt": "2022-12-13T16:21:21.489+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000171",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00241",
              "classIDName": "USD",
              "classType": "UNLISTED",
              "description": "CO-CQGF",
              "inceptionDate": "2019-06-01T13:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CO-CQGF",
              "nameChinese": "",
              "nameCustodian": "CQGF",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "TEST"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CQGF"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "BBG",
                  "value": "CSOPGQD KY EQUITY"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-01T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited"
          },
          "custodianFundCode": "CQGF",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "CYM",
          "exchangeListing": {},
          "fees": [],
          "fundClass": "Private",
          "fundID": "1000171",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000171",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited"
          },
          "maturityAllocations": [],
          "name": "CSOP Global Quant Allocation Growth Fund I SP",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaNavFileTime": "14:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00241",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CQGF"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Global Quant Allocation Growth Fund I SP"
            },
            {
              "type": "CUSTODIAN",
              "value": "CQGF"
            }
          ],
          "registrar": {
            "en": "HSBC Trustee (Cayman) Limited"
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "18:00",
          "slaType": "T1",
          "tradarFundLongName": "CSOP Global Quant Allocation Growth Fund I SP",
          "type": "OT",
          "updatedAt": "2022-12-13T16:21:42.329+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000172",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "HKD",
          "ccyOps": "HKD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "HKD",
              "classID": "CLS00242",
              "classIDName": "HKD",
              "classType": "UNLISTED",
              "description": "CCJA CJQ2 HKD",
              "inceptionDate": "2021-07-01T21:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "managementFee": "0.75",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CJQ2 HKD",
              "nameChinese": "",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "TEST"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CJQ2"
                },
                {
                  "type": "PMA",
                  "value": "HKD Share Class Q"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            },
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00243",
              "classIDName": "USD",
              "classType": "UNLISTED",
              "description": "CCJA CJQ1 USD",
              "inceptionDate": "2021-07-01T21:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 0,
              "managementFee": "0.75",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "CJQ1 USD",
              "nameChinese": "",
              "references": [
                {
                  "type": "8IGHT",
                  "value": "TEST"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CJQ1"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class Q"
                }
              ],
              "tickers": [],
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-01T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited "
          },
          "custodianFundCode": "CCJA",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "CYM",
          "exchangeListing": {},
          "fees": [],
          "fundClass": "Private",
          "fundID": "1000172",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000172",
          "investmentAdvisor": {},
          "investmentStrategy": {},
          "legalStructure": {},
          "location": "Cayman Islands",
          "lotSize": 0,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited "
          },
          "maturityAllocations": [],
          "name": "CSOP Changjiang Absolute Return Fund SP",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaNavFileTime": "14:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00242",
          "productType": "OT",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-CCJA"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Changjiang Absolute Return Fund SP"
            },
            {
              "type": "CUSTODIAN",
              "value": "CCJA"
            }
          ],
          "registrar": {
            "en": "HSBC Trustee (Cayman) Limited "
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "18:00",
          "slaType": "T1",
          "tradarFundLongName": "CSOP Changjiang Absolute Return Fund SP",
          "type": "OT",
          "updatedAt": "2022-12-05T20:31:23.438+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000173",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00244",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "USD",
              "inceptionDate": "2022-12-15T05:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 500000,
              "managementFee": "1.99",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "USD",
              "nameCh": "USD",
              "nameChinese": "",
              "nameCustodian": "CBTC",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "BBG",
                  "value": "3066 HK EQUITY"
                },
                {
                  "type": "EXCH",
                  "value": "3066"
                },
                {
                  "type": "REUTER",
                  "value": "3066.HK"
                },
                {
                  "type": "8IGHT",
                  "value": "3066.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CBTC"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000900347"
                }
              ],
              "shareClassAcctCode": "zzzConCapUSD",
              "tickers": [],
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-06T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited",
            "tch": "香港上海滙豐銀行有限公司"
          },
          "custodianFundCode": "CBTC",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK",
            "US"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000173",
          "indexCalendar": [
            "HK",
            "US"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000173",
          "investmentAdvisor": {},
          "investmentStrategy": {
            "en": "Active investment strategy",
            "tch": "主動投資策略"
          },
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信托"
          },
          "location": "Hong Kong",
          "lotSize": 500000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資産管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP Bitcoin Futures ETF",
          "nameChinese": "",
          "navCalendar": [
            "HK",
            "US"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsFundType": "EE",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "07:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00244",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-BTCFUT"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Bitcoin Futures ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CBTC"
            },
            {
              "type": "PCF_CODE",
              "value": "BTCFUTURE"
            }
          ],
          "registrar": {
            "en": "HSBC Institutional Trust Services (Asia) Limited",
            "tch": "滙豐機構信託服務（亞洲）有限公司 "
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP Bitcoin Futures ETF",
          "type": "EM",
          "updatedAt": "2022-12-16T05:53:43.704+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000174",
          "active": "YES",
          "activeStatus": true,
          "benchmarkIndex": [],
          "ccy": "USD",
          "ccyOps": "USD",
          "classes": [
            {
              "cashComponent": "NO",
              "ccy": "USD",
              "classID": "CLS00245",
              "classIDName": "USD",
              "classType": "LISTED",
              "description": "USD",
              "inceptionDate": "2022-12-15T21:30:00+05:30",
              "lastDistribution": {},
              "lotSize": 500000,
              "managementFee": "1.99",
              "marketMakers": {
                "en": [],
                "tch": []
              },
              "name": "USD",
              "nameCh": "USD",
              "nameChinese": "",
              "nameCustodian": "CEHR",
              "namePMA": "USD Share Class A",
              "references": [
                {
                  "type": "BBG",
                  "value": "3068 HK EQUITY"
                },
                {
                  "type": "EXCH",
                  "value": "3068"
                },
                {
                  "type": "REUTER",
                  "value": "3068.HK"
                },
                {
                  "type": "8IGHT",
                  "value": "3068.HK"
                },
                {
                  "type": "CUSTODIAN",
                  "value": "CEHR"
                },
                {
                  "type": "PMA",
                  "value": "USD Share Class A"
                },
                {
                  "type": "ISIN",
                  "value": "HK0000900354"
                }
              ],
              "shareClassAcctCode": "zzzConCapUSD",
              "tickers": [
                {
                  "cashComponent": "YES",
                  "marketMakerIDs": [],
                  "mktPriceValue": "SETTLEMENT_PRICE",
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "BBG",
                      "value": "3068 HK EQUITY"
                    },
                    {
                      "type": "EXCH",
                      "value": "3068"
                    },
                    {
                      "type": "REUTER",
                      "value": "3068.HK"
                    },
                    {
                      "type": "8IGHT",
                      "value": "3068.HK"
                    },
                    {
                      "type": "CUSTODIAN",
                      "value": "CEHR"
                    },
                    {
                      "type": "PMA",
                      "value": "USD Share Class A"
                    },
                    {
                      "type": "ISIN",
                      "value": "HK0000900354"
                    }
                  ],
                  "ticker": "3068 HK EQUITY",
                  "tickerCcy": "HKD"
                }
              ],
              "tradingLotSize": "100",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-06T05:30:00+05:30",
          "custodian": {
            "en": "The Hongkong and Shanghai Banking Corporation Limited",
            "tch": "香港上海滙豐銀行有限公司"
          },
          "custodianFundCode": "CEHR",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海滙豐銀行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU532"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HK",
            "US"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "HK",
          "exchangeListing": {
            "en": "SEHK – Main Board",
            "tch": "香港聯交所 - 主板"
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000174",
          "indexCalendar": [
            "HK",
            "US"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000174",
          "investmentAdvisor": {},
          "investmentStrategy": {
            "en": "Active investment strategy",
            "tch": "主動投資策略"
          },
          "legalStructure": {
            "en": "Hong Kong Unit Trust",
            "tch": "香港單位信托"
          },
          "location": "Hong Kong",
          "lotSize": 500000,
          "managed": "YES",
          "manager": {
            "en": "CSOP Asset Management Limited",
            "tch": "南方東英資産管理有限公司"
          },
          "maturityAllocations": [],
          "name": "CSOP Ether Futures ETF",
          "nameChinese": "",
          "navCalendar": [
            "HK",
            "US"
          ],
          "navCondition": "AND",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsFundType": "EE",
          "participatingDealerIDs": [],
          "participatingDealers": {
            "en": [],
            "tch": []
          },
          "pmaCustodianCode": "HSBC_HK",
          "pmaNavFileTime": "07:00",
          "portfolioManager": "5JUGqZfjp",
          "prcCustodian": {},
          "preferredClass": "CLS00245",
          "productType": "EM",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "HK-DCRFUT"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP Ether Futures ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CEHR"
            },
            {
              "type": "PCF_CODE",
              "value": "ETHFUTURE"
            }
          ],
          "registrar": {
            "en": "HSBC Institutional Trust Services (Asia) Limited",
            "tch": "滙豐機構信託服務（亞洲）有限公司 "
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "09:30",
          "slaType": "T1",
          "slaTypeNAV2": "T1",
          "tradarFundLongName": "CSOP Ether Futures ETF",
          "type": "EM",
          "updatedAt": "2022-12-14T18:23:58.023+05:30",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000175",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equity",
          "ccy": "CNY",
          "ccyOps": "CNY",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "CNY",
                  "fromDate": "2022-12-29T00:00:00.000Z",
                  "name": "CSI STAR \u0026 CHINEXT 50 Index",
                  "ticker": "SH931643 Index",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "CNY",
              "classID": "CLS00247",
              "classIDName": "HKD",
              "classType": "LISTED",
              "description": "HKD Share Class A",
              "inceptionDate": "2022-12-29T00:00:00.000Z",
              "lotSize": 200000,
              "mktPriceValue": "LAST_PRICE",
              "name": "CNY",
              "nameChinese": "中國新年",
              "nameCustodian": "CCSI",
              "pcfCode": "CSOPSCY",
              "shareClassAcctCode": "zzzConCapRMB",
              "terminationDate": null,
              "tickers": [
                {
                  "listingDate": "2022-12-30T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Investment Limited",
                      "nameChinese": "安联资本投资有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM904",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    }
                  ],
                  "pdcc": true,
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "8IGHT",
                      "value": "SCY"
                    },
                    {
                      "type": "ISIN",
                      "value": "SGXC15225995"
                    }
                  ],
                  "ticker": "SCY SP EQUITY",
                  "tickerCcy": "SGD"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T2"
            }
          ],
          "companyID": "CSOP",
          "createdAt": "2022-12-05T00:00:00Z",
          "custodianID": {
            "name": "The Hongkong and Shanghai Banking Corporation Limited",
            "nameChinese": "香港上海汇丰银行有限公司",
            "partyCategory": "CUST",
            "partyID": "CU906",
            "references": []
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "CN",
            "SG"
          ],
          "dealingCondition": "OR",
          "entity": "HK",
          "exchangeListingID": {
            "location": "AF",
            "name": "SGX-ST-Main Board",
            "nameChinese": "SGX-ST-主板",
            "partyCategory": "MKT",
            "partyID": "EL919",
            "references": [
              {
                "type": "BBGTICKER",
                "value": "fd"
              }
            ]
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000175",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000175",
          "investmentStrategies": [
            {
              "fromDate": "2022-12-29T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Representative sampling strategy",
          "liMultipliers": [],
          "location": "Singapore",
          "lotSize": 200000,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "CSOP CSI STAR and CHINEXT 50 Index ETF",
          "nameChinese": "南方東證中證之星及創業板50指數ETF",
          "nav1SignOffCutOff": "21:30",
          "nav2Recon": "NO",
          "navCalendar": [
            "CN",
            "SG"
          ],
          "navCondition": "OR",
          "navFrequency": "DAILY",
          "offShoreAccID": "HSBC_HK",
          "onShoreAccID": "HSBC_CN",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [],
          "pmaNavFileTime": "19:00",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "preferredClass": "CLS00247",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "SG-CSI50"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSOP CSI STAR and CHINEXT 50 Index ETF"
            },
            {
              "type": "CUSTODIAN",
              "value": "CCSI"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG903",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": true,
          "slaSignOffTime": "21:30",
          "slaType": "T0",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC CUSTODIAN\t\n",
            "nameChinese": "汇丰托管人\n",
            "partyCategory": "TRUS",
            "partyID": "TS900",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "updatedAt": "2022-12-05T00:00:00Z",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000268",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equities",
          "ccy": "INR",
          "ccyOps": "INR",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "INR",
                  "fromDate": "2023-01-02T00:00:00.000Z",
                  "name": "Tectoro Index",
                  "ticker": "Tect 500051",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "INR",
              "classID": "CLS00314",
              "classIDName": "Tect ID",
              "classType": "LISTED",
              "description": "Tectoro",
              "inceptionDate": "2023-01-02T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 300,
              "mktPriceValue": "LAST_PRICE",
              "name": "Tectoro share class T",
              "nameChinese": "",
              "nameCustodian": "Tectoro consultancy pvt ltd",
              "namePMA": "Tectoro share class T",
              "pcf_code": "CO-TECT",
              "terminationDate": "2024-01-01T00:00:00.000Z",
              "tickers": [
                {
                  "deListingDate": "2024-01-01T00:00:00.000Z",
                  "listingDate": "2023-01-02T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    },
                    {
                      "name": "BNP Paribas Securities (Asia) Limited",
                      "nameChinese": "法國巴黎證券(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM498"
                    },
                    {
                      "name": "China International Capital Corporation Hong Kong Securities Limited",
                      "nameChinese": "中國國際金融香港證券有限公司,",
                      "partyCategory": "MM",
                      "partyID": "MM499"
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM499",
                      "PartyNameInChinese": "中國國際金融香港證券有限公司,",
                      "PartyNameInEnglish": "China International Capital Corporation Hong Kong Securities Limited"
                    }
                  ],
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "500051"
                    }
                  ],
                  "ticker": "Tect 500051",
                  "tickerCcy": "INR"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodian": {},
          "custodianFundCode": "Tectoro Custodian",
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "CN"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": {},
          "entity": "HKD",
          "exchangeListing": {},
          "exchangeListingID": null,
          "fees": [],
          "fundID": "1000268",
          "indexAvailableSla": "T1",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000268",
          "investmentAdvisor": {},
          "investmentAdvisorID": null,
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2023-01-02T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "strategy",
          "investmentStrategyEn": "Csop Asset Management ",
          "legalStructure": {},
          "legalStructureID": null,
          "liMultipliers": [],
          "location": "India",
          "lotSize": 300,
          "managed": "YES",
          "manager": {},
          "managerID": null,
          "maturityAllocations": [],
          "name": "Tectoro Fund",
          "nameChinese": "",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "MONTHLY",
          "opsCalendar": [
            "HK",
            "HKSC",
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Bank N.V. Singapore Branch",
              "nameChinese": "ABN AMRO Clearing Bank N.V. Singapore Branch,",
              "partyCategory": "PD",
              "partyID": "PD433"
            }
          ],
          "pmaNavFileTime": "14:00",
          "portfolioManager": "AijultrR45",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "prcCustodian": {},
          "prcCustodianID": null,
          "preferredClass": "CLS00314",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CO-TECT"
            },
            {
              "type": "TRADER_NAME",
              "value": "Tectoro consultancy pvt ltd"
            },
            {
              "type": "CUSTODIAN",
              "value": "Tectoro Custodian"
            }
          ],
          "registrar": {
            "en": "Tectoro Trustee"
          },
          "registrarID": {
            "location": "SG",
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中银国际英国保诚信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG902",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "11:40",
          "slaType": "T0",
          "tradarFundLongName": "Tectoro consultancy pvt ltd",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC CUSTODIAN\t\n",
            "nameChinese": "汇丰托管人\n",
            "partyCategory": "TRUS",
            "partyID": "TS900",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000271",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "ccy": "AED",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "AED",
                  "fromDate": "2023-01-24T00:00:00.000Z",
                  "name": "INDEX",
                  "ticker": "TICKER",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "AED",
              "classID": "CLS00318",
              "classType": "LISTED",
              "inceptionDate": "2023-01-17T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 22,
              "managementFee": "2",
              "mktPriceValue": "LAST_PRICE",
              "name": "TEST SHARE CLASS",
              "nameChinese": "",
              "pcf_code": "FCP",
              "terminationDate": "2023-01-25T00:00:00.000Z",
              "tickers": [
                {
                  "deListingDate": "2023-01-24T00:00:00.000Z",
                  "listingDate": "2023-01-24T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    },
                    {
                      "name": "BNP Paribas Securities (Asia) Limited",
                      "nameChinese": "法國巴黎證券(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM498"
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "SEDOL",
                      "value": "34"
                    }
                  ],
                  "ticker": "BBG",
                  "tickerCcy": "AED"
                }
              ],
              "totalExpenseRatio": "2",
              "tradingLotSize": 2,
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodian": {},
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "dealingCalendar": [
            "CN"
          ],
          "dealingCondition": "AND",
          "estAnnualAvgTD": "234",
          "exchangeListingID": {
            "location": "AF",
            "name": "SGX-ST – Main Board",
            "nameChinese": "SGX-ST – Main Board",
            "partyCategory": "MKT",
            "partyID": "751",
            "references": []
          },
          "fees": [],
          "fundID": "1000271",
          "indexCalendar": [
            "HKSC"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000271",
          "investmentStrategies": [
            {
              "fromDate": "2023-01-22T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategyEn": "INSD",
          "legalStructureID": {
            "location": "SG",
            "name": "SEHK – Main Board",
            "nameChinese": "香港联交所 – 主板",
            "partyCategory": "LEGAL",
            "partyID": "759",
            "references": []
          },
          "liMultipliers": [
            {
              "fromDate": "2023-01-15T00:00:00.000Z",
              "liMultiplier": -2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Afghanistan",
          "lotSize": 22,
          "managed": "NO",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "FNAME",
          "nameChinese": "",
          "navCalendar": [
            "HKSC"
          ],
          "navCondition": "AND",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "participatingDealerIDs": [
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            },
            {
              "name": "BNP Paribas Securities (Asia) Limited",
              "nameChinese": "法國巴黎證券(亞洲)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD436"
            }
          ],
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "prcCustodian": {},
          "preferredClass": "CLS00318",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "234"
            }
          ],
          "registrarID": {
            "location": "AF",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "TRUS",
            "partyID": "760",
            "references": []
          },
          "riskfreeIndex": {},
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "754",
            "references": []
          },
          "type": "LI",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000273",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "ccy": "AED",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "AED",
                  "fromDate": "2023-01-17T00:00:00.000Z",
                  "name": "CSOP TEST INDEX",
                  "ticker": "CSOP TEST TICKER",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "AED",
              "classID": "CLS00319",
              "classType": "LISTED",
              "description": "Description",
              "inceptionDate": "2023-01-18T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 100,
              "managementFee": "45",
              "mktPriceValue": "LAST_PRICE",
              "name": "NEW TEST SHARE CLASS",
              "nameChinese": "",
              "pcf_code": "FCP2396",
              "terminationDate": "2023-01-30T00:00:00.000Z",
              "tickers": [
                {
                  "deListingDate": "2023-01-25T00:00:00.000Z",
                  "listingDate": "2023-01-16T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Management (Hong Kong) Limited",
                      "nameChinese": "雅柏資本管理(香港)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM496"
                    },
                    {
                      "name": "BNP Paribas Securities (Asia) Limited",
                      "nameChinese": "法國巴黎證券(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM498"
                    },
                    {
                      "name": "China International Capital Corporation Hong Kong Securities Limited",
                      "nameChinese": "中國國際金融香港證券有限公司,",
                      "partyCategory": "MM",
                      "partyID": "MM499"
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM496",
                      "PartyNameInChinese": "雅柏資本管理(香港)有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM499",
                      "PartyNameInChinese": "中國國際金融香港證券有限公司,",
                      "PartyNameInEnglish": "China International Capital Corporation Hong Kong Securities Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "23"
                    }
                  ],
                  "ticker": "TEST BBG",
                  "tickerCcy": "AFN"
                }
              ],
              "totalExpenseRatio": "23",
              "tradingLotSize": 23,
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodian": {},
          "custodianFundCode": "",
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "dealingCalendar": [
            "HK",
            "CN"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "dividendFrequencyID": "QUARTER",
          "estAnnualAvgTD": "23",
          "exchangeListingID": {
            "location": "CN",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "748",
            "references": []
          },
          "fees": [],
          "fundID": "1000273",
          "fundNote": "CSOP Test Note",
          "indexCalendar": [
            "SG",
            "HKSC"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000273",
          "investmentAdvisorID": {
            "location": "SG",
            "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "partyCategory": "INVA",
            "partyID": "747",
            "references": []
          },
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2023-01-30T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {},
          "investmentStrategyEn": "CNBD2598",
          "legalStructureID": {
            "location": "CN",
            "name": "Hong Kong Unit Trust",
            "nameChinese": "香港单位信托",
            "partyCategory": "LEGAL",
            "partyID": "750",
            "references": []
          },
          "liMultipliers": [
            {
              "ID": 1,
              "fromDate": "2023-01-10T00:00:00.000Z",
              "liMultiplier": -3,
              "toDate": "2023-01-25T00:00:00.000Z"
            },
            {
              "ID": 2,
              "fromDate": "2023-01-31T00:00:00.000Z",
              "liMultiplier": -2,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Brazil",
          "lotSize": 100,
          "managed": "NO",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "CSOP TEST FUND NEW",
          "nameChinese": "",
          "navCalendar": [
            "HK",
            "HKSC"
          ],
          "navCondition": "AND",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "participatingDealerIDs": [
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            },
            {
              "name": "BNP Paribas Securities (Asia) Limited",
              "nameChinese": "法國巴黎證券(亞洲)有限公司,",
              "partyCategory": "PD",
              "partyID": "PD436"
            },
            {
              "name": "BNP Paribas Securities Services",
              "nameChinese": "法國巴黎證券服務公司,",
              "partyCategory": "PD",
              "partyID": "PD437"
            }
          ],
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "prcCustodian": {},
          "preferredClass": "CLS00319",
          "productType": "LI",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "TRAD"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "HSBC Trustee (Cayman) Limited ",
            "partyCategory": "RGTR",
            "partyID": "755",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {},
          "tradarFundLongName": "",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC CUSTODIAN",
            "nameChinese": " 汇丰托管人",
            "partyCategory": "TRUS",
            "partyID": "777",
            "references": []
          },
          "type": "LI",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000274",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "MM",
          "assetClassDescription": "Money Market",
          "ccy": "AFN",
          "ccyOps": "AFN",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "AFN",
                  "fromDate": "2023-01-25T00:00:00.000Z",
                  "name": "TEST INDEX TE",
                  "ticker": "TEST TICKER RE",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "AFN",
              "classID": "CLS00314",
              "classType": "LISTED",
              "inceptionDate": "2023-01-18T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 22,
              "managementFee": "15",
              "mktPriceValue": "LAST_PRICE",
              "name": "LIQUID_CLASS",
              "nameChinese": "",
              "nameCustodian": "CKBD_65",
              "pcf_code": "FVB_289",
              "terminationDate": null,
              "tickers": [
                {
                  "deListingDate": "2023-02-17T00:00:00.000Z",
                  "listingDate": "2023-01-17T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Management (Hong Kong) Limited",
                      "nameChinese": "雅柏資本管理(香港)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM496"
                    },
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    },
                    {
                      "name": "BNP Paribas Securities (Asia) Limited",
                      "nameChinese": "法國巴黎證券(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM498"
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM496",
                      "PartyNameInChinese": "雅柏資本管理(香港)有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "34"
                    }
                  ],
                  "ticker": "BBG RE",
                  "tickerCcy": "AFN"
                }
              ],
              "totalExpenseRatio": "13",
              "tradingLotSize": 12,
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodian": {},
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HKSC"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "entity": "CYM",
          "estAnnualTD": "23",
          "exchangeListingID": {
            "location": "CN",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "748",
            "references": []
          },
          "fees": [],
          "fundClass": "Public",
          "fundID": "1000274",
          "fundNote": "CSOP NOTE",
          "indexAvailablesla": "T0",
          "indexCalendar": [
            "HKSC"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000274",
          "investmentAdvisorID": {
            "location": "SG",
            "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "partyCategory": "INVA",
            "partyID": "747",
            "references": []
          },
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2023-01-24T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {},
          "investmentStrategyEn": "CKHG_STRAP",
          "legalStructureID": {
            "location": "CN",
            "name": "Hong Kong Unit Trust",
            "nameChinese": "香港单位信托",
            "partyCategory": "LEGAL",
            "partyID": "750",
            "references": []
          },
          "liMultipliers": [],
          "location": "Afghanistan",
          "lotSize": 22,
          "managed": "NO",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "CSOP LIQUID FUND",
          "nameChinese": "",
          "navCalendar": [
            "HKSC"
          ],
          "navCondition": "AND",
          "navFrequency": "QUARTERLY",
          "offShoreAccID": "COP_23",
          "onShoreAccID": "CBD_23",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "LE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaNavFileTime": "21:21",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "prcCustodian": {},
          "preferredClass": "CLS00314",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "COP_"
            },
            {
              "type": "TRADER_NAME",
              "value": "LIQUID TRADAR"
            },
            {
              "type": "CUSTODIAN",
              "value": "CDB_"
            }
          ],
          "registrarID": {
            "location": "SG",
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "RGTR",
            "partyID": "753",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "21:15",
          "slaType": "T1",
          "slaTypeNAV2": "T1",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "754",
            "references": []
          },
          "type": "ETF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000275",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "MM",
          "assetClassDescription": "Money Market",
          "ccy": "AFN",
          "ccyOps": "AFN",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "AFN",
                  "fromDate": "2023-02-08T00:00:00.000Z",
                  "name": "SEBT INDEX",
                  "ticker": "SEBT",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "AFN",
              "classID": "CLS00314",
              "classType": "LISTED",
              "inceptionDate": "2023-02-08T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 12,
              "managementFee": "9",
              "mktPriceValue": "LAST_PRICE",
              "name": "PMA TEST FUND",
              "nameChinese": "",
              "nameCustodian": "CSFBG SHARE CLASS",
              "pcf_code": "FCP34",
              "terminationDate": null,
              "tickers": [
                {
                  "deListingDate": "2023-02-28T00:00:00.000Z",
                  "listingDate": "2023-02-14T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    },
                    {
                      "name": "BNP Paribas Securities (Asia) Limited",
                      "nameChinese": "法國巴黎證券(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM498"
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "12"
                    }
                  ],
                  "ticker": "SEBT",
                  "tickerCcy": "AFN"
                }
              ],
              "totalExpenseRatio": "11",
              "tradingLotSize": 13,
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodian": {},
          "custodianID": {
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "CUST",
            "partyID": "CU528"
          },
          "custodianReconParty": "BOC",
          "dealingCalendar": [
            "HKSC"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "entity": "HK",
          "estAnnualTD": "12",
          "exchangeListingID": {
            "location": "AF",
            "name": "SGX-ST – Main Board",
            "nameChinese": "SGX-ST – Main Board",
            "partyCategory": "MKT",
            "partyID": "751",
            "references": []
          },
          "fees": [],
          "fundClass": "Private",
          "fundID": "1000275",
          "fundNote": "CSOP TEST NOTE",
          "indexAvailablesla": "T0",
          "indexCalendar": [
            "HKSC"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000275",
          "investmentAdvisorID": {
            "location": "SG",
            "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "partyCategory": "INVA",
            "partyID": "747",
            "references": []
          },
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2023-02-13T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {},
          "investmentStrategyEn": "CITI",
          "legalStructureID": {
            "location": "SG",
            "name": "SEHK – Main Board",
            "nameChinese": "香港联交所 – 主板",
            "partyCategory": "LEGAL",
            "partyID": "759",
            "references": []
          },
          "liMultipliers": [],
          "location": "Afghanistan",
          "lotSize": 12,
          "managed": "YES",
          "managerID": {
            "country": "United Arab Emirates",
            "name": "Fund Manager",
            "nameChinese": "滙豐證券經紀(亞洲)有限公司",
            "partyCategory": "FMGR",
            "partyID": "FM797",
            "references": []
          },
          "maturityAllocations": [],
          "name": "NEW TEST FUND CSOP",
          "nameChinese": "",
          "navCalendar": [
            "HKSC"
          ],
          "navCondition": "AND",
          "navFrequency": "QUARTERLY",
          "offShoreAccID": "CDB2",
          "onShoreAccID": "CDB2",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "ME",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Bank N.V. Singapore Branch",
              "nameChinese": "ABN AMRO Clearing Bank N.V. Singapore Branch,",
              "partyCategory": "PD",
              "partyID": "PD433"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaNavFileTime": "15:26",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "prcCustodian": {},
          "preferredClass": "CLS00314",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "SEBI"
            },
            {
              "type": "TRADER_NAME",
              "value": "TEST FUND CDB2"
            },
            {
              "type": "CUSTODIAN",
              "value": "CSEBD"
            }
          ],
          "registrarID": {
            "location": "SG",
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "RGTR",
            "partyID": "753",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "14:25",
          "slaType": "T1",
          "slaTypeNAV2": "T0",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC CUSTODIAN",
            "nameChinese": " 汇丰托管人",
            "partyCategory": "TRUS",
            "partyID": "777",
            "references": []
          },
          "type": "ETF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000276",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "ccy": "AFN",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "AFN",
                  "fromDate": "2023-02-15T00:00:00.000Z",
                  "name": "TRISK INDEX",
                  "ticker": "TRISK",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "AFN",
              "classID": "CLS00314",
              "classType": "LISTED",
              "inceptionDate": "2023-02-16T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 12,
              "managementFee": "12",
              "mktPriceValue": "LAST_PRICE",
              "name": "TEST PMA",
              "nameChinese": "",
              "pcf_code": "FCP34",
              "terminationDate": null,
              "tickers": [
                {
                  "deListingDate": "2023-02-21T00:00:00.000Z",
                  "listingDate": "2023-02-13T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Investment Limited",
                      "nameChinese": "AP Capital Investment Limited",
                      "partyCategory": "MM",
                      "partyID": "MM495"
                    },
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM495",
                      "PartyNameInChinese": "AP Capital Investment Limited",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "12"
                    }
                  ],
                  "ticker": "BBG",
                  "tickerCcy": "AFN"
                }
              ],
              "totalExpenseRatio": "12",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodian": {},
          "custodianFundCode": "",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "dealingCalendar": [
            "HKSC"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "estAnnualTD": "23",
          "exchangeListingID": {
            "location": "CN",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "748",
            "references": []
          },
          "fees": [],
          "fundID": "1000276",
          "fundNote": "TEST NOTE",
          "indexCalendar": [
            "HKSC"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000276",
          "investmentAdvisorID": {
            "location": "CN",
            "name": "CSOP",
            "nameChinese": "南方光电",
            "partyCategory": "INVA",
            "partyID": "758",
            "references": []
          },
          "investmentStrategies": [
            {
              "ID": 1,
              "fromDate": "2023-02-13T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": {},
          "investmentStrategyEn": "CITI",
          "legalStructureID": {
            "location": "SG",
            "name": "SEHK – Main Board",
            "nameChinese": "香港联交所 – 主板",
            "partyCategory": "LEGAL",
            "partyID": "759",
            "references": []
          },
          "liMultipliers": [],
          "location": "Afghanistan",
          "lotSize": 12,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "TEST RISK",
          "nameChinese": "",
          "navCalendar": [
            "HKSC"
          ],
          "navCondition": "AND",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Bank N.V. Singapore Branch",
              "nameChinese": "ABN AMRO Clearing Bank N.V. Singapore Branch,",
              "partyCategory": "PD",
              "partyID": "PD433"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            }
          ],
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "prcCustodian": {},
          "prcCustodianID": {
            "name": "Cititrust Limited",
            "nameChinese": "花旗信托有限公司",
            "partyCategory": "CUST",
            "partyID": "CU530"
          },
          "preferredClass": "CLS00314",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "TRACD"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "HSBC Trustee (Cayman) Limited ",
            "partyCategory": "RGTR",
            "partyID": "755",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {},
          "tradarFundLongName": "",
          "trusteeID": {
            "location": "AF",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "TRUS",
            "partyID": "760",
            "references": []
          },
          "type": "ETF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000277",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "EQTY",
          "assetClassDescription": "Equities",
          "ccy": "AFN",
          "ccyOps": "AFN",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "AED",
                  "fromDate": "2023-02-08T00:00:00.000Z",
                  "name": "EFT INDEX",
                  "ticker": "ETF TICKER",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "AFN",
              "classID": "CLS00314",
              "classType": "LISTED",
              "inceptionDate": "2023-02-16T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 12,
              "managementFee": "11",
              "mktPriceValue": "LAST_PRICE",
              "name": "PMA ETF",
              "nameChinese": "",
              "nameCustodian": "FTRT",
              "pcf_code": "FCP_37",
              "terminationDate": null,
              "tickers": [
                {
                  "deListingDate": "2023-02-09T00:00:00.000Z",
                  "listingDate": "2023-02-08T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Investment Limited",
                      "nameChinese": "安联资本投资有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM904",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    },
                    {
                      "name": "AP Capital Management (Hong Kong) Limited",
                      "nameChinese": "安联资本管理（香港）有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM905",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM905",
                      "PartyNameInChinese": "安联资本管理（香港）有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "12"
                    }
                  ],
                  "ticker": "ETF BBG",
                  "tickerCcy": "AED"
                }
              ],
              "totalExpenseRatio": "11",
              "tradingLotSize": 11,
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodian": {},
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HKSC"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "entity": "HK",
          "estAnnualTD": "CD 12",
          "exchangeListingID": {
            "location": "AF",
            "name": "SGX-ST – Main Board",
            "nameChinese": "SGX-ST – Main Board",
            "partyCategory": "MKT",
            "partyID": "751",
            "references": []
          },
          "fees": [],
          "fundClass": "ETF",
          "fundID": "1000277",
          "fundNote": "TEST ETF NOTE",
          "indexAvailableSla": "T1",
          "indexCalendar": [
            "HK",
            "HKSC"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000277",
          "investmentAdvisorID": {
            "location": "CH",
            "name": "Investment Advisor",
            "nameChinese": "華盛資本証券有限公司",
            "partyCategory": "INVA",
            "partyID": "IA891",
            "references": []
          },
          "investmentStrategies": [
            {
              "fromDate": "2023-02-07T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategyEn": "STR",
          "legalStructureID": {
            "location": "CN",
            "name": "Hong Kong Unit Trust",
            "nameChinese": "香港单位信托",
            "partyCategory": "LEGAL",
            "partyID": "750",
            "references": []
          },
          "liMultipliers": [],
          "location": "Afghanistan",
          "lotSize": 12,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "TEST CSOP ETF FUND",
          "nameChinese": "",
          "navCalendar": [
            "HKSC",
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "QUARTERLY",
          "offShoreAccID": "ETF_PVT_38",
          "onShoreAccID": "ETF_36",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Bank N.V. Singapore Branch",
              "nameChinese": "ABN AMRO Clearing Bank N.V. Singapore Branch,",
              "partyCategory": "PD",
              "partyID": "PD433"
            },
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaNavFileTime": "09:58",
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "prcCustodian": {},
          "preferredClass": "CLS00314",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "ETF QA"
            },
            {
              "type": "TRADER_NAME",
              "value": "ETF TRADAR"
            },
            {
              "type": "CUSTODIAN",
              "value": "ETF"
            }
          ],
          "registrarID": {
            "location": "SG",
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中银国际英国保诚信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG902",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "riskfreeIndex": {},
          "slaSignOffTime": "09:57",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC CUSTODIAN\t\n",
            "nameChinese": "汇丰托管人\n",
            "partyCategory": "TRUS",
            "partyID": "TS900",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000280",
          "active": "NO",
          "activeStatus": false,
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "ccy": "AED",
          "ccyOps": "AFN",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ID": 1,
                  "ccy": "AFN",
                  "fromDate": "2023-02-21T00:00:00.000Z",
                  "name": "INNFINFY",
                  "ticker": "INFY",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "AED",
              "classID": "CLS00315",
              "classType": "LISTED",
              "description": "DEScrpition",
              "inceptionDate": "2023-02-15T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 12,
              "managementFee": "15",
              "mktPriceValue": "LAST_PRICE",
              "name": "TEST BOND ETF CLASS",
              "nameChinese": "",
              "nameCustodian": "BOND EFT",
              "pcf_code": "FCP_BET_67",
              "shareClassAcctCode": "CDBFT",
              "terminationDate": "2023-02-22T00:00:00.000Z",
              "tickers": [
                {
                  "deListingDate": "2023-01-10T00:00:00.000Z",
                  "listingDate": "2023-02-20T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Management (Hong Kong) Limited",
                      "nameChinese": "安联资本管理（香港）有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM905",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    },
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    },
                    {
                      "name": "BNP Paribas Securities (Asia) Limited",
                      "nameChinese": "法國巴黎證券(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM498"
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM905",
                      "PartyNameInChinese": "安联资本管理（香港）有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "12"
                    }
                  ],
                  "ticker": "INFY",
                  "tickerCcy": "AFN"
                }
              ],
              "totalExpenseRatio": "14",
              "tradingLotSize": 13,
              "underlierStockFillDate": "T2"
            }
          ],
          "companyID": "CSOP",
          "custodian": {},
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HKSC"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "entity": "CYM",
          "estAnnualTD": "FCVG",
          "exchangeListingID": {
            "location": "CN",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "748",
            "references": []
          },
          "fees": [],
          "fundClass": "Public",
          "fundID": "1000280",
          "fundNote": "TEST NOTE",
          "indexAvailableSla": "T1",
          "indexCalendar": [
            "HKSC"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000280",
          "investmentAdvisorID": {
            "location": "SG",
            "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "partyCategory": "INVA",
            "partyID": "747",
            "references": []
          },
          "investmentStrategies": [
            {
              "fromDate": "2023-02-07T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategyEn": "CFGHJ",
          "legalStructureID": {
            "location": "SG",
            "name": "SEHK – Main Board",
            "nameChinese": "香港联交所 – 主板",
            "partyCategory": "LEGAL",
            "partyID": "759",
            "references": []
          },
          "liMultipliers": [],
          "location": "Afghanistan",
          "lotSize": 12,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "TEST BOND ETF FUND",
          "nameChinese": "",
          "navCalendar": [
            "HKSC"
          ],
          "navCondition": "AND",
          "navFrequency": "QUARTERLY",
          "offShoreAccID": "CSDF",
          "onShoreAccID": "CBFS",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "BE",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Hong Kong Limited",
              "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
              "partyCategory": "PD",
              "partyID": "PD434"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "pmaNavFileTime": "16:56",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "prcCustodian": {},
          "prcCustodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "preferredClass": "CLS00315",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "TBEF"
            },
            {
              "type": "TRADER_NAME"
            },
            {
              "type": "CUSTODIAN"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "HSBC Trustee (Cayman) Limited ",
            "partyCategory": "RGTR",
            "partyID": "755",
            "references": [],
            "versionNo": 2
          },
          "remainingMaturityRange": 2024,
          "riskfreeIndex": {},
          "slaSignOffTime": "16:49",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "terminatedDate": "2023-02-21T11:00:00.000Z",
          "trusteeID": {
            "location": "CN",
            "name": "HSBC Institutional Trust Services (Asia) Limited",
            "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
            "partyCategory": "TRUS",
            "partyID": "754",
            "references": []
          },
          "type": "ETF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000284",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "BOND",
          "assetClassDescription": "Bond",
          "ccy": "ALL",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "AED",
                  "fromDate": "2021-02-09T00:00:00.000Z",
                  "name": "BBg",
                  "ticker": "BBG",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "NO",
              "ccy": "ALL",
              "classID": "CLS00314",
              "classType": "UNLISTED",
              "inceptionDate": "2021-02-09T00:00:00.000Z",
              "lastDistribution": {},
              "lotSize": 2000,
              "mktPriceValue": "LAST_PRICE",
              "name": "PMA Share class a",
              "nameChinese": "",
              "pcfCode": "CTB",
              "terminationDate": null,
              "tickers": [
                {
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Management (Hong Kong) Limited",
                      "nameChinese": "安联资本管理（香港）有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM905",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    },
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM905",
                      "PartyNameInChinese": "安联资本管理（香港）有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    }
                  ],
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "8IGHT",
                      "value": "1000"
                    }
                  ],
                  "ticker": "HH",
                  "tickerCcy": "AED"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodian": {},
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "dealingCalendar": [
            "CN"
          ],
          "dealingCondition": "AND",
          "exchangeListingID": {
            "country": "Afghanistan",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "EL804",
            "references": []
          },
          "fees": [],
          "fundID": "1000284",
          "indexAvailableSla": "T0",
          "indexCalendar": [
            "CN"
          ],
          "indexCondition": "AND",
          "instrumentID": "1000284",
          "investmentAdvisorID": {
            "location": "SG",
            "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "partyCategory": "INVA",
            "partyID": "747",
            "references": []
          },
          "investmentStrategies": [
            {
              "fromDate": "2021-02-02T00:00:00.000Z",
              "investmentStrategyIn": "P",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Replica",
          "legalStructureID": {
            "location": "CN",
            "name": "Hong Kong Unit Trust",
            "nameChinese": "香港单位信托",
            "partyCategory": "LEGAL",
            "partyID": "750",
            "references": []
          },
          "liMultipliers": [],
          "location": "Afghanistan",
          "lotSize": 2000,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "ISOP CSOP BOND 5",
          "nameChinese": "",
          "navCalendar": [
            "CN"
          ],
          "navCondition": "AND",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "participatingDealerIDs": [
            {
              "name": "ABN AMRO Clearing Bank N.V. Singapore Branch",
              "nameChinese": "ABN AMRO Clearing Bank N.V. Singapore Branch,",
              "partyCategory": "PD",
              "partyID": "PD433"
            },
            {
              "name": "Barclays Bank PLC",
              "nameChinese": "Barclays Bank PLC,",
              "partyCategory": "PD",
              "partyID": "PD435"
            }
          ],
          "portfolioManagerID": {
            "country": "Afghanistan",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "PM805",
            "references": []
          },
          "prcCustodian": {},
          "prcCustodianID": {
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "CUST",
            "partyID": "CU528"
          },
          "preferredClass": "CLS00314",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "PMA_CUST",
              "value": "CTBA"
            }
          ],
          "registrarID": {
            "location": "SG",
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中银国际英国保诚信托有限公司",
            "partyCategory": "RGTR",
            "partyID": "RG902",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "riskfreeIndex": {},
          "trusteeID": {
            "location": "CN",
            "name": "HSBC CUSTODIAN\t\n",
            "nameChinese": "汇丰托管人\n",
            "partyCategory": "TRUS",
            "partyID": "TS900",
            "references": [
              {
                "type": "",
                "value": ""
              }
            ]
          },
          "type": "ETF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000289",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "DERV",
          "assetClassDescription": "Derivative",
          "ccy": "AED",
          "ccyOps": "AED",
          "classes": [
            {
              "benchmarkIndex": [
                {
                  "ccy": "AED",
                  "fromDate": "2023-02-13T00:00:00.000Z",
                  "name": "DFG",
                  "ticker": "DCV",
                  "toDate": "2023-03-07T00:00:00.000Z"
                },
                {
                  "ccy": "ALL",
                  "fromDate": "2023-03-08T00:00:00.000Z",
                  "name": "CVG",
                  "ticker": "DSA",
                  "toDate": "2099-12-31T00:00:00.000Z"
                }
              ],
              "cashComponent": "YES",
              "ccy": "AED",
              "classID": "CLS00315",
              "classType": "LISTED",
              "inceptionDate": "2023-02-08T00:00:00.000Z",
              "lotSize": 12,
              "mktPriceValue": "SETTLEMENT_PRICE",
              "name": "OKL",
              "nameChinese": "",
              "nameCustodian": "SDF",
              "pcfCode": "DF",
              "terminationDate": "2023-02-07T00:00:00.000Z",
              "tickers": [
                {
                  "deListingDate": "2023-02-03T00:00:00.000Z",
                  "listingDate": "2023-02-06T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Investment Limited",
                      "nameChinese": "安联资本投资有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM904",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    },
                    {
                      "name": "AP Capital Management (Hong Kong) Limited",
                      "nameChinese": "安联资本管理（香港）有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM905",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM905",
                      "PartyNameInChinese": "安联资本管理（香港）有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    }
                  ],
                  "pdcc": true,
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "REUT",
                      "value": "23"
                    }
                  ],
                  "ticker": "CDFS",
                  "tickerCcy": "AED"
                }
              ],
              "totalExpenseRatio": "23",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "entity": "HK",
          "fundID": "1000289",
          "indexCalendar": [
            "HK"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000289",
          "investmentStrategies": [
            {
              "fromDate": "2023-02-13T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-02-11T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "njk",
          "legalStructureID": {
            "location": "SG",
            "name": "SEHK – Main Board",
            "nameChinese": "香港联交所 – 主板",
            "partyCategory": "LEGAL",
            "partyID": "759",
            "references": []
          },
          "liMultipliers": [],
          "location": "Anguilla",
          "lotSize": 12,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "CLS MATE FUND",
          "nameChinese": "",
          "nav1SignOffCutOff": "12:02",
          "nav2Recon": "NO",
          "navCalendar": [
            "HK"
          ],
          "navCondition": "AND",
          "navFrequency": "WEEKLY",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [],
          "pmaNavFileTime": "13:02",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "preferredClass": "CLS00315",
          "productType": "ETF",
          "quantDcActive": "NO",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "TPBC"
            },
            {
              "type": "TRADER_NAME",
              "value": "CSL CODE DU"
            },
            {
              "type": "CUSTODIAN",
              "value": "CFV"
            }
          ],
          "registrarID": {
            "location": "AE",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "HSBC Trustee (Cayman) Limited ",
            "partyCategory": "RGTR",
            "partyID": "755",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": true,
          "slaSignOffTime": "14:02",
          "slaType": "T0",
          "trusteeID": {
            "location": "AF",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "TRUS",
            "partyID": "760",
            "references": []
          },
          "type": "ETF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1000290",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "DERV",
          "assetClassDescription": "Derivative",
          "ccy": "BIF",
          "ccyOps": "AED",
          "classes": [
            {
              "benchmarkIndex": [],
              "cashComponent": "YES",
              "ccy": "BIF",
              "classID": "CLS00255",
              "classType": "UNLISTED",
              "inceptionDate": "2023-02-23T00:00:00.000Z",
              "lotSize": 12,
              "managementFee": "2",
              "mktPriceValue": "SETTLEMENT_PRICE",
              "name": "RBM SHARE CLASS UNLISTED",
              "nameChinese": "",
              "nameCustodian": "CDB CUST SHARE",
              "pcfCode": "CFB_CODE",
              "terminationDate": "2023-02-28T00:00:00.000Z",
              "tickers": [
                {
                  "deListingDate": "2023-02-28T00:00:00.000Z",
                  "listingDate": "2023-02-27T00:00:00.000Z",
                  "marketMakerIDs": [],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM499",
                      "PartyNameInChinese": "中國國際金融香港證券有限公司,",
                      "PartyNameInEnglish": "China International Capital Corporation Hong Kong Securities Limited"
                    }
                  ],
                  "pdcc": true,
                  "primaryTicker": "YES",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "12"
                    }
                  ],
                  "ticker": "TICK45",
                  "tickerCcy": "BRL"
                }
              ],
              "totalExpenseRatio": "2",
              "tradingLotSize": 11,
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodianFundCode": "CDBT",
          "custodianID": {
            "location": "AF",
            "name": "HSBC Bank (China) Company Limited",
            "nameChinese": "匯豐銀行(中國)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU525",
            "references": [],
            "versionNo": 2
          },
          "custodianReconParty": "HSBC",
          "dealingCalendar": [
            "HKSC"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "entity": "LUX",
          "exchangeListingID": {
            "location": "CN",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "748",
            "references": []
          },
          "fundClass": "Private",
          "fundID": "1000290",
          "indexCalendar": [
            "HKSC"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1000290",
          "investmentAdvisorID": {
            "location": "SG",
            "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "partyCategory": "INVA",
            "partyID": "747",
            "references": []
          },
          "investmentStrategies": [
            {
              "fromDate": "2023-02-23T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2023-02-26T00:00:00.000Z"
            },
            {
              "fromDate": "2023-02-28T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-02-11T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Replication Ind Strategy",
          "legalStructureID": {
            "location": "SG",
            "name": "SEHK – Main Board",
            "nameChinese": "香港联交所 – 主板",
            "partyCategory": "LEGAL",
            "partyID": "759",
            "references": []
          },
          "liMultipliers": [
            {
              "fromDate": "2023-02-24T00:00:00.000Z",
              "liMultiplier": -3,
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "location": "Afghanistan",
          "lotSize": 12,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "TEST FUND NAME",
          "nameChinese": "",
          "nav1SignOffCutOff": "18:43",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "20:43",
          "navCalendar": [
            "HKSC"
          ],
          "navCondition": "AND",
          "navFrequency": "QUARTERLY",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "pmaNavFileTime": "16:46",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "prcCustodianID": {
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "CUST",
            "partyID": "CU528"
          },
          "preferredClass": "CLS00255",
          "productType": "LI",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "TRADAR_CODE",
              "value": "CDBTECT"
            },
            {
              "type": "PMA_CUST",
              "value": "CUST_PMA"
            },
            {
              "type": "TRADER_NAME",
              "value": "CDBT TRADAR FUND LONG NAME_TEST"
            },
            {
              "type": "CUSTODIAN",
              "value": "CDBT"
            }
          ],
          "registrarID": {
            "location": "SG",
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "RGTR",
            "partyID": "753",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {},
          "skipCutOffCheck": false,
          "slaSignOffTime": "16:43",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "tradarFundLongName": "CDBT TRADAR FUND LONG NAME_TEST",
          "trusteeID": {
            "location": "AF",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "TRUS",
            "partyID": "760",
            "references": []
          },
          "type": "LI",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1001508",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "DERV",
          "assetClassDescription": "Derivative",
          "ccy": "AFN",
          "ccyOps": "AFN",
          "classes": [
            {
              "benchmarkIndex": [],
              "cashComponent": "NO",
              "ccy": "AFN",
              "classID": "CLS00255",
              "classType": "UNLISTED",
              "inceptionDate": "2023-03-02T00:00:00.000Z",
              "lotSize": 1,
              "mktPriceValue": null,
              "name": "Zepto PMA",
              "nameChinese": "",
              "nameCustodian": "Zepto CUST",
              "namePMA": "Zepto PMA",
              "pcfCode": "CFGT",
              "terminationDate": "2023-03-08T00:00:00.000Z",
              "tickers": [
                {
                  "deListingDate": "2023-03-06T00:00:00.000Z",
                  "listingDate": "2023-03-02T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "AP Capital Investment Limited",
                      "nameChinese": "安联资本投资有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM904",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    },
                    {
                      "name": "AP Capital Management (Hong Kong) Limited",
                      "nameChinese": "安联资本管理（香港）有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM905",
                      "references": [
                        {
                          "type": "",
                          "value": ""
                        }
                      ]
                    },
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM904",
                      "PartyNameInChinese": "安联资本投资有限公司",
                      "PartyNameInEnglish": "AP Capital Investment Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM905",
                      "PartyNameInChinese": "安联资本管理（香港）有限公司",
                      "PartyNameInEnglish": "AP Capital Management (Hong Kong) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    }
                  ],
                  "pdcc": true,
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "23"
                    }
                  ],
                  "ticker": "ZT BBG",
                  "tickerCcy": "AED"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodianFundCode": "ZTBT",
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "custodianReconParty": "CITI",
          "dealingCalendar": [
            "HKSC"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "QUARTER",
          "entity": "LUX",
          "exchangeListingID": {
            "location": "CN",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "748",
            "references": []
          },
          "fundID": "1001508",
          "indexCalendar": [
            "HKSC"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1001508",
          "investmentAdvisorID": {
            "location": "SG",
            "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "partyCategory": "INVA",
            "partyID": "747",
            "references": []
          },
          "investmentStrategies": [
            {
              "fromDate": "2023-03-14T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "Zepto",
          "legalStructureID": {
            "location": "CN",
            "name": "Hong Kong Unit Trust",
            "nameChinese": "香港单位信托",
            "partyCategory": "LEGAL",
            "partyID": "750",
            "references": []
          },
          "liMultipliers": [],
          "location": "Algeria",
          "lotSize": 1,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "ZEPTO CSOP ETF MATE",
          "nameChinese": "",
          "nav1SignOffCutOff": "13:11",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "09:11",
          "navCalendar": [
            "HKSC"
          ],
          "navCondition": "AND",
          "navFrequency": "QUARTERLY",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "PVMF",
          "participatingDealerIDs": [],
          "pmaNavFileTime": "10:14",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "prcCustodianID": {
            "name": "Citibank, N.A., Hong Kong Branch",
            "nameChinese": "花旗銀行香港分行",
            "partyCategory": "CUST",
            "partyID": "CU529"
          },
          "preferredClass": "CLS00255",
          "productType": "ETF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "PMA_CUST",
              "value": "CDFG"
            },
            {
              "type": "TRADAR_CODE",
              "value": "CKBT"
            },
            {
              "type": "TRADER_NAME",
              "value": "Zepto Tradar Fund Long Name"
            },
            {
              "type": "CUSTODIAN",
              "value": "ZTBT"
            }
          ],
          "registrarID": {
            "location": "SG",
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "RGTR",
            "partyID": "753",
            "references": [],
            "versionNo": 2
          },
          "skipCutOffCheck": false,
          "slaSignOffTime": "12:11",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "tradarFundLongName": "Zepto Tradar Fund Long Name",
          "trusteeID": {
            "location": "AF",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "TRUS",
            "partyID": "760",
            "references": []
          },
          "type": "ETF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        },
        {
          "_id": "1001509",
          "active": "YES",
          "activeStatus": true,
          "assetClass": "DERV",
          "assetClassDescription": "Derivative",
          "ccy": "AED",
          "ccyOps": "AED",
          "classes": [
            {
              "benchmarkIndex": [],
              "cashComponent": "NO",
              "ccy": "AED",
              "classID": "CLS00256",
              "classType": "UNLISTED",
              "inceptionDate": "2023-03-08T00:00:00.000Z",
              "lotSize": 12,
              "mktPriceValue": null,
              "name": "CDFF",
              "nameChinese": "",
              "nameCustodian": "CSFGT",
              "namePMA": "CDFF",
              "pcfCode": "CXFG",
              "terminationDate": "2023-03-16T00:00:00.000Z",
              "tickers": [
                {
                  "deListingDate": "2023-03-10T00:00:00.000Z",
                  "listingDate": "2023-03-08T00:00:00.000Z",
                  "marketMakerIDs": [
                    {
                      "name": "Bluefin HK Limited",
                      "nameChinese": "Bluefin HK Limited,",
                      "partyCategory": "MM",
                      "partyID": "MM497"
                    },
                    {
                      "name": "BNP Paribas Securities (Asia) Limited",
                      "nameChinese": "法國巴黎證券(亞洲)有限公司",
                      "partyCategory": "MM",
                      "partyID": "MM498"
                    },
                    {
                      "name": "China International Capital Corporation Hong Kong Securities Limited",
                      "nameChinese": "中國國際金融香港證券有限公司,",
                      "partyCategory": "MM",
                      "partyID": "MM499"
                    }
                  ],
                  "mms": [
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM497",
                      "PartyNameInChinese": "Bluefin HK Limited,",
                      "PartyNameInEnglish": "Bluefin HK Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM498",
                      "PartyNameInChinese": "法國巴黎證券(亞洲)有限公司",
                      "PartyNameInEnglish": "BNP Paribas Securities (Asia) Limited"
                    },
                    {
                      "PartyCategory": "MM",
                      "PartyID": "MM499",
                      "PartyNameInChinese": "中國國際金融香港證券有限公司,",
                      "PartyNameInEnglish": "China International Capital Corporation Hong Kong Securities Limited"
                    }
                  ],
                  "pdcc": true,
                  "primaryTicker": "NO",
                  "references": [
                    {
                      "type": "ISIN",
                      "value": "sdr"
                    }
                  ],
                  "ticker": "XSDF",
                  "tickerCcy": "AED"
                }
              ],
              "totalExpenseRatio": "",
              "underlierStockFillDate": "T0"
            }
          ],
          "companyID": "CSOP",
          "custodianFundCode": "ZTFR",
          "custodianID": {
            "location": "AD",
            "name": "CITIBANK (CHINA) CO., LTD",
            "nameChinese": "花旗银行(中国)有限公司",
            "partyCategory": "CUST",
            "partyID": "CU527",
            "references": [],
            "versionNo": 3
          },
          "custodianReconParty": "BOC",
          "dealingCalendar": [
            "HK"
          ],
          "dealingCondition": "AND",
          "dividendFrequency": "HALF_YEAR",
          "entity": "HK",
          "exchangeListingID": {
            "location": "CN",
            "name": "SEHK - Main Board",
            "nameChinese": "香港聯交所 ─ 主板",
            "partyCategory": "MKT",
            "partyID": "748",
            "references": []
          },
          "fundID": "1001509",
          "indexCalendar": [
            "HKSC"
          ],
          "indexCondition": "AND",
          "indexSla": "T0",
          "instrumentID": "1001509",
          "investmentAdvisorID": {
            "location": "SG",
            "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
            "partyCategory": "INVA",
            "partyID": "747",
            "references": []
          },
          "investmentStrategies": [
            {
              "fromDate": "2023-03-14T00:00:00.000Z",
              "investmentStrategyIn": "A",
              "toDate": "2099-12-31T00:00:00.000Z"
            }
          ],
          "investmentStrategy": "ZEPTO FUND",
          "legalStructureID": {
            "location": "SG",
            "name": "SEHK – Main Board",
            "nameChinese": "香港联交所 – 主板",
            "partyCategory": "LEGAL",
            "partyID": "759",
            "references": []
          },
          "liMultipliers": [],
          "location": "Afghanistan",
          "lotSize": 12,
          "managed": "YES",
          "managerID": {
            "location": "AE",
            "name": "CSOP Asset Management Limited",
            "nameChinese": "南方東英資産管理有限公司",
            "partyCategory": "FMGR",
            "partyID": "746",
            "references": [
              {
                "type": "CREDIT_RATING_ISIN",
                "typeDescription": "Credit Rating ISIN",
                "value": "34"
              }
            ],
            "versionNo": 2
          },
          "maturityAllocations": [],
          "name": "Fund Zepto",
          "nameChinese": "",
          "nav1SignOffCutOff": "11:20",
          "nav2Recon": "YES",
          "nav2SignOffCutOff": "12:19",
          "navCalendar": [
            "HKSC"
          ],
          "navCondition": "AND",
          "navFrequency": "SEMI_ANNUALLY",
          "opsCalendar": [
            "HKO"
          ],
          "opsCondition": "AND",
          "opsFundType": "EE",
          "participatingDealerIDs": [],
          "pmaNavFileTime": "09:19",
          "portfolioManagerID": {
            "location": "CN",
            "name": "Koteswararao Perumulla",
            "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
            "partyCategory": "PMGR",
            "partyID": "752",
            "references": []
          },
          "prcCustodianID": {
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "CUST",
            "partyID": "CU528"
          },
          "preferredClass": "CLS00256",
          "productType": "MF",
          "quantDcActive": "YES",
          "references": [
            {
              "type": "PMA_CUST",
              "value": "F_ZEPTO"
            },
            {
              "type": "TRADAR_CODE",
              "value": "T_ZEPTO"
            },
            {
              "type": "TRADER_NAME",
              "value": "ZEPTO FUND"
            },
            {
              "type": "CUSTODIAN",
              "value": "ZTFR"
            }
          ],
          "registrarID": {
            "location": "SG",
            "name": "BOCI-Prudential Trustee Limited",
            "nameChinese": "中銀國際英國保誠信託有限公司",
            "partyCategory": "RGTR",
            "partyID": "753",
            "references": [],
            "versionNo": 2
          },
          "riskfreeIndex": {
            "ccy": "AOA",
            "name": "RISK_ZEPTO",
            "ticker": "RZT"
          },
          "skipCutOffCheck": false,
          "slaSignOffTime": "10:19",
          "slaType": "T0",
          "slaTypeNAV2": "T0",
          "tradarFundLongName": "ZEPTO FUND",
          "trusteeID": {
            "location": "AF",
            "name": "HSBC Trustee (Cayman) Limited ",
            "nameChinese": "汇丰信托（开曼）有限公司",
            "partyCategory": "TRUS",
            "partyID": "760",
            "references": []
          },
          "type": "MF",
          "userIdAttributes": [
            "portfolioManager"
          ]
        }
      ],
      "status": "success"
    }
  }


  getParties() {
    return {
      "data": [
        {
          "location": "United Arab Emirates",
          "locationCode": "AE",
          "name": "CSOP Asset Management Limited",
          "nameChinese": "南方東英資産管理有限公司",
          "partyCategory": "FMGR",
          "partyCategoryDesc": "Fund Manager",
          "partyId": "746",
          "references": [
            {
              "type": "CREDIT_RATING_ISIN",
              "value": "34"
            }
          ]
        },
        {
          "location": "Singapore",
          "locationCode": "SG",
          "name": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
          "nameChinese": "CGS-CIMB Securities (Singapore) Pte. Ltd.",
          "partyCategory": "INVA",
          "partyCategoryDesc": "Investment Advisor",
          "partyId": "747",
          "references": []
        },
        {
          "location": "China",
          "locationCode": "CN",
          "name": "Hong Kong Unit Trust",
          "nameChinese": "香港单位信托",
          "partyCategory": "LEGAL",
          "partyCategoryDesc": "Legal Structure",
          "partyId": "750",
          "references": []
        },
        {
          "location": "India",
          "locationCode": "IN",
          "name": "CSOP",
          "nameChinese": "南方光电",
          "partyCategory": "FMGR",
          "partyCategoryDesc": "Fund Manager",
          "partyId": "756",
          "references": []
        },
        {
          "location": "China",
          "locationCode": "CN",
          "name": "Koteswararao ",
          "nameChinese": "科特斯瓦拉朗",
          "partyCategory": "PMGR",
          "partyCategoryDesc": "Portfolio Manager",
          "partyId": "757",
          "references": []
        },
        {
          "location": "China",
          "locationCode": "CN",
          "name": "CSOP",
          "nameChinese": "南方光电",
          "partyCategory": "INVA",
          "partyCategoryDesc": "Investment Advisor",
          "partyId": "758",
          "references": []
        },
        {
          "location": "Singapore",
          "locationCode": "SG",
          "name": "SEHK – Main Board",
          "nameChinese": "香港联交所 – 主板",
          "partyCategory": "LEGAL",
          "partyCategoryDesc": "Legal Structure",
          "partyId": "759",
          "references": []
        },
        {
          "location": "Australia",
          "locationCode": "AU",
          "name": "Manager1",
          "nameChinese": "csc_TEST",
          "partyCategory": "FMGR",
          "partyCategoryDesc": "Fund Manager",
          "partyId": "763",
          "references": [
            {
              "type": null,
              "value": "1"
            },
            {
              "type": "BIC",
              "value": "45"
            }
          ]
        },
        {
          "location": "Afghanistan",
          "locationCode": "AF",
          "name": "InvestmentAdviser11",
          "nameChinese": "fss",
          "partyCategory": "INVA",
          "partyCategoryDesc": "Investment Advisor",
          "partyId": "765",
          "references": [
            {
              "type": "CUST",
              "value": "w"
            }
          ]
        },
        {
          "location": "United Arab Emirates",
          "locationCode": "AE",
          "name": "LegalStructure",
          "nameChinese": "dsd",
          "partyCategory": "LEGAL",
          "partyCategoryDesc": "Legal Structure",
          "partyId": "766",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Hong Kong Unit Trust",
          "nameChinese": "匯豐銀行(中國)有限公司",
          "partyCategory": "FMGR",
          "partyCategoryDesc": "Fund Manager",
          "partyId": "769",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "SEHK - Main Board",
          "nameChinese": "中銀國際英國保誠信託有限公司",
          "partyCategory": "INVA",
          "partyCategoryDesc": "Investment Advisor",
          "partyId": "771",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "SGX-ST – Main Board",
          "nameChinese": "匯豐銀行(中國)有限公司",
          "partyCategory": "LEGAL",
          "partyCategoryDesc": "Legal Structure",
          "partyId": "772",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "AP Capital Investment Limited",
          "nameChinese": "香港聯交所 ─ 主板",
          "partyCategory": "FA",
          "partyCategoryDesc": "Fund Administrator",
          "partyId": "773",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Portfolio Manager",
          "nameChinese": "南方東英資産管理有限公司",
          "partyCategory": "PMGR",
          "partyCategoryDesc": "Portfolio Manager",
          "partyId": "784",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Transfer Agent",
          "nameChinese": "凱基證券亞洲有限公司",
          "partyCategory": "TA",
          "partyCategoryDesc": "Transfer Agent",
          "partyId": "785",
          "references": []
        },
        {
          "location": "United States",
          "locationCode": "US",
          "name": "JPM",
          "nameChinese": "JPM",
          "partyCategory": "CPTY",
          "partyCategoryDesc": "Counterpaty",
          "partyId": "913",
          "references": [
            {
              "type": "BBGTICKER",
              "value": "JPM US EQUITY"
            },
            {
              "type": "PMA_ACCT_ID",
              "value": "JPM"
            },
            {
              "type": "CDSREF",
              "value": "JPMCC CDS USD SR 5Y D14 Corp"
            },
            {
              "type": "CREDIT_RATING_ISIN",
              "value": "US46647PDE34"
            }
          ]
        },
        {
          "location": "Hong Kong",
          "locationCode": "HK",
          "name": "CITI",
          "nameChinese": "CITI",
          "partyCategory": "CPTY",
          "partyCategoryDesc": "Counterpaty",
          "partyId": "914",
          "references": [
            {
              "type": "PMA_ACCT_ID",
              "value": "CITI"
            },
            {
              "type": "CREDIT_RATING_ISIN",
              "value": "XS2439108205"
            }
          ]
        },
        {
          "location": "Hong Kong",
          "locationCode": "HK",
          "name": "HUATA",
          "nameChinese": "HUATA",
          "partyCategory": "CPTY",
          "partyCategoryDesc": "Counterpaty",
          "partyId": "915",
          "references": [
            {
              "type": "INT",
              "value": "HUATA"
            }
          ]
        },
        {
          "location": "Spain",
          "locationCode": "ES",
          "name": "SocGen",
          "nameChinese": "SocGen",
          "partyCategory": "CPTY",
          "partyCategoryDesc": "Counterpaty",
          "partyId": "916",
          "references": [
            {
              "type": "CDSREF",
              "value": "SOCGEN CDS EUR SR 5Y D14 Corp"
            },
            {
              "type": "PMA_ACCT_ID",
              "value": "SocGen"
            }
          ]
        },
        {
          "location": "Hong Kong",
          "locationCode": "HK",
          "name": "CITI",
          "nameChinese": "CITI",
          "partyCategory": "CBNK",
          "partyCategoryDesc": "Clearing Banks",
          "partyId": "921",
          "references": [
            {
              "type": "PMA_ACCT_ID",
              "value": "CITI"
            },
            {
              "type": "CREDIT_RATING_ISIN",
              "value": "XS2439108205"
            }
          ]
        },
        {
          "location": "Hong Kong",
          "locationCode": "HK",
          "name": "Citic Bank",
          "nameChinese": "Citic Bank",
          "partyCategory": "CBNK",
          "partyCategoryDesc": "Clearing Banks",
          "partyId": "922",
          "references": [
            {
              "type": "PMA_ACCT_ID",
              "value": "Citic Bank"
            },
            {
              "type": "CREDIT_RATING_ISIN",
              "value": "XS2439108205"
            }
          ]
        },
        {
          "location": "Hong Kong",
          "locationCode": "HK",
          "name": "HSBC_HK",
          "nameChinese": "HSBC_HK",
          "partyCategory": "CPTY",
          "partyCategoryDesc": "Counterpaty",
          "partyId": "929",
          "references": [
            {
              "type": "PMA_ACCT_ID",
              "value": "HSBC_HK"
            },
            {
              "type": "CDSREF",
              "value": "JPMCC CDS USD SR 5Y D14 Corp"
            },
            {
              "type": "CREDIT_RATING_ISIN",
              "value": "US01609WAQ50"
            }
          ]
        },
        {
          "location": "Afghanistan",
          "locationCode": "AF",
          "name": "HSBC Bank (China) Company Limited",
          "nameChinese": "匯豐銀行(中國)有限公司",
          "partyCategory": "CUST",
          "partyCategoryDesc": "Custodians",
          "partyId": "CU525",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "HSBC Institutional Trust Services (Asia) Limited",
          "nameChinese": "匯豐機構信託服務(亞洲)有限公司",
          "partyCategory": "CUST",
          "partyCategoryDesc": "Custodians",
          "partyId": "CU526",
          "references": []
        },
        {
          "location": "Andorra",
          "locationCode": "AD",
          "name": "CITIBANK (CHINA) CO., LTD",
          "nameChinese": "花旗银行(中国)有限公司",
          "partyCategory": "CUST",
          "partyCategoryDesc": "Custodians",
          "partyId": "CU527",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "BOCI-Prudential Trustee Limited",
          "nameChinese": "中銀國際英國保誠信託有限公司",
          "partyCategory": "CUST",
          "partyCategoryDesc": "Custodians",
          "partyId": "CU528",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Citibank, N.A., Hong Kong Branch",
          "nameChinese": "花旗銀行香港分行",
          "partyCategory": "CUST",
          "partyCategoryDesc": "Custodians",
          "partyId": "CU529",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Cititrust Limited",
          "nameChinese": "花旗信托有限公司",
          "partyCategory": "CUST",
          "partyCategoryDesc": "Custodians",
          "partyId": "CU530",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "HSBC Institutional Trust Services (Singapore)  Limited",
          "nameChinese": "HSBC Institutional Trust Services (Singapore)  Limited",
          "partyCategory": "CUST",
          "partyCategoryDesc": "Custodians",
          "partyId": "CU531",
          "references": []
        },
        {
          "location": "United Arab Emirates",
          "locationCode": "AE",
          "name": "SEHK - Main Board",
          "nameChinese": "香港聯交所 ─ 主板",
          "partyCategory": "CUST",
          "partyCategoryDesc": "Custodians",
          "partyId": "CU857",
          "references": [
            {
              "type": "PMA_CUST",
              "value": "SEHK"
            },
            {
              "type": "TRADAR_CODE",
              "value": "SEHK"
            },
            {
              "type": "BIC",
              "value": "10009"
            }
          ]
        },
        {
          "location": null,
          "locationCode": null,
          "name": "The Hongkong and Shanghai Banking Corporation Limited",
          "nameChinese": "香港上海汇丰银行有限公司",
          "partyCategory": "CUST",
          "partyCategoryDesc": "Custodians",
          "partyId": "CU906",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "SEHK - Main Board",
          "nameChinese": "香港聯交所 ─ 主板",
          "partyCategory": "MKT",
          "partyCategoryDesc": "Markets",
          "partyId": "EL804",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "SGX-ST- Main Board",
          "nameChinese": "SGX-ST- 主板",
          "partyCategory": "MKT",
          "partyCategoryDesc": "Markets",
          "partyId": "EL863",
          "references": [
            {
              "type": "",
              "value": ""
            }
          ]
        },
        {
          "location": "Afghanistan",
          "locationCode": "AF",
          "name": "SGX-ST-Main Board",
          "nameChinese": "SGX-ST-主板",
          "partyCategory": "MKT",
          "partyCategoryDesc": "Markets",
          "partyId": "EL919",
          "references": [
            {
              "type": "BBGTICKER",
              "value": "fd"
            }
          ]
        },
        {
          "location": "United Arab Emirates",
          "locationCode": "AE",
          "name": "Fund Manager",
          "nameChinese": "滙豐證券經紀(亞洲)有限公司",
          "partyCategory": "FMGR",
          "partyCategoryDesc": "Fund Manager",
          "partyId": "FM890",
          "references": []
        },
        {
          "location": "Switzerland",
          "locationCode": "CH",
          "name": "Investment Advisor",
          "nameChinese": "華盛資本証券有限公司",
          "partyCategory": "INVA",
          "partyCategoryDesc": "Investment Advisor",
          "partyId": "IA891",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Bluefin HK Limited",
          "nameChinese": "Bluefin HK Limited,",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM497",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "BNP Paribas Securities (Asia) Limited",
          "nameChinese": "法國巴黎證券(亞洲)有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM498",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "China International Capital Corporation Hong Kong Securities Limited",
          "nameChinese": "中國國際金融香港證券有限公司,",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM499",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Citadel Securities (Hong Kong) Limited",
          "nameChinese": "Citadel Securities (Hong Kong) Limited",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM500",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Citadel Securities Arbitrage Trading Limited",
          "nameChinese": "Citadel Securities Arbitrage Trading Limited",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM501",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "CLSA Singapore Pte Ltd",
          "nameChinese": "CLSA Singapore Pte Ltd",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM502",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "DRW Singapore Pte. Ltd",
          "nameChinese": "DRW Singapore Pte. Ltd",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM503",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Eclipse Options (HK) Limited",
          "nameChinese": "Eclipse Options (HK) Limited",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM504",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Flow Traders Asia Pte Ltd",
          "nameChinese": "Flow Traders Asia Pte Ltd",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM505",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Flow Traders Hong Kong Limited",
          "nameChinese": "Flow Traders Hong Kong Limited",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM506",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Guotai Junan Investments (Hong Kong) Limited",
          "nameChinese": "Guotai Junan Investments (Hong Kong) Limited",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM507",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Haitong International Securities Company Limited",
          "nameChinese": "海通國際證券有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM508",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Head \u0026 Shoulders Securities Limited",
          "nameChinese": "聯合證券有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM509",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "HSBC Securities Brokers (Asia) Limited",
          "nameChinese": "滙豐證券經紀(亞洲)有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM510",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "IMC Asia Pacific Limited",
          "nameChinese": "IMC Asia Pacific Limited",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM511",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Interactive Brokers Hong Kong Limited",
          "nameChinese": "盈透證券香港有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM512",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Jane Street Financial Limited",
          "nameChinese": "Jane Street Financial Limited",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM513",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Jump Trading Pacific Pte. Ltd",
          "nameChinese": "Jump Trading Pacific Pte. Ltd",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM514",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "KGI Asia Limited",
          "nameChinese": "凱基證券亞洲有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM515",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Korea Investment \u0026 Securities Asia Limited",
          "nameChinese": "韓國投資證券亞洲有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM516",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Mirae Asset Securities (HK) Limited",
          "nameChinese": "未來資產證券(香港)有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM517",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Optiver Trading Hong Kong Limited",
          "nameChinese": "Optiver Trading Hong Kong Limited",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM518",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Phillip Securities Pte Ltd",
          "nameChinese": "Phillip Securities Pte Ltd",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM519",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "SG Securities (HK) Limited",
          "nameChinese": "法國興業證券(香港)有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM520",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Valuable Capital Limited",
          "nameChinese": "華盛資本証券有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM521",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Virtu Financial Singapore PTE. Ltd",
          "nameChinese": "Virtu Financial Singapore PTE. Ltd",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM522",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Vivienne Court Trading Pty. Ltd",
          "nameChinese": "Vivienne Court Trading Pty. Ltd",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM523",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Yue Kun Research Limited",
          "nameChinese": "躍鯤研發有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM524",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "AP Capital Investment Limited",
          "nameChinese": "安联资本投资有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM904",
          "references": [
            {
              "type": "",
              "value": ""
            }
          ]
        },
        {
          "location": null,
          "locationCode": null,
          "name": "AP Capital Management (Hong Kong) Limited",
          "nameChinese": "安联资本管理（香港）有限公司",
          "partyCategory": "MM",
          "partyCategoryDesc": "Market Maker",
          "partyId": "MM905",
          "references": [
            {
              "type": "",
              "value": ""
            }
          ]
        },
        {
          "location": null,
          "locationCode": null,
          "name": "ABN AMRO Clearing Bank N.V. Singapore Branch",
          "nameChinese": "ABN AMRO Clearing Bank N.V. Singapore Branch,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD433",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "ABN AMRO Clearing Hong Kong Limited",
          "nameChinese": "ABN AMRO Clearing Hong Kong Limited,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD434",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Barclays Bank PLC",
          "nameChinese": "Barclays Bank PLC,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD435",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "BNP Paribas Securities (Asia) Limited",
          "nameChinese": "法國巴黎證券(亞洲)有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD436",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "BNP Paribas Securities Services",
          "nameChinese": "法國巴黎證券服務公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD437",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "BOCI Securities Limited",
          "nameChinese": "中銀國際證券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD438",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "BOCOM International Securities Limited",
          "nameChinese": "交銀國際證券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD439",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Cathay Securities (Hong Kong) Limited",
          "nameChinese": "國泰證券(香港)有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD440",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "CGS-CIMB Securities (Singapore) Pte Ltd",
          "nameChinese": "CGS-CIMB Securities (Singapore) Pte Ltd,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD441",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "China Galaxy International Securities (Hong Kong) Co., Limited",
          "nameChinese": "中國銀河國際證券(香港)有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD442",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "China Industrial Securities International Brokerage Limited",
          "nameChinese": "興證國際證券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD443",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "China International Capital Corporation Hong Kong Securities Limited",
          "nameChinese": "中國國際金融香港證券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD444",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "China Merchant Securities (HK) Co., Limited",
          "nameChinese": "招商證券(香港)有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD445",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "China Securities (International) Brokerage Company Limited",
          "nameChinese": "中信建投(國際)証券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD446",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "CIMB Securities Limited",
          "nameChinese": "聯昌證券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD447",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Cinda International Securities Limited",
          "nameChinese": "信達國際證券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD448",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "CITIC Securities Brokerage (HK) Limited",
          "nameChinese": "中信証券經紀(香港)有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD449",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Citigroup Global Markets Asia Limited",
          "nameChinese": "花旗環球金融亞洲有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD450",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "CLSA LIMITED",
          "nameChinese": "中信里昂證券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD451",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "CMB International Securities Limited",
          "nameChinese": "招銀國際證券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD452",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "CMBC Securities Company Limited",
          "nameChinese": "民銀證券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD453",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Credit Suisse Securities (Hong Kong) Limited",
          "nameChinese": "瑞士信貸證券(香港)有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD454",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "DBS Vickers Securities (Singapore) Pte Ltd",
          "nameChinese": "DBS Vickers Securities (Singapore) Pte Ltd,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD455",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Deutsche Securities Asia Limited",
          "nameChinese": "德意志證券亞洲有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD456",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Essence International Securities (Hong Kong) Limited",
          "nameChinese": "安信國際證券(香港)有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD457",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Flow Traders Asia Pte. Ltd",
          "nameChinese": "Flow Traders Asia Pte. Ltd,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD458",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Futu Singapore Pte Ltd",
          "nameChinese": "Futu Singapore Pte Ltd,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD459",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "GF Securities (Hong Kong) Brokerage Limited",
          "nameChinese": "廣發証券(香港)經紀有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD460",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Goldman Sachs (Asia) Securities Limited",
          "nameChinese": "高盛(亞洲)證券有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD461",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Guotai Junan Securities (Hong Kong) Limited",
          "nameChinese": "國泰君安證券(香港)有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD462",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Guoyuan Securities Brokerage (Hong Kong) Limited",
          "nameChinese": "國元証券經紀(香港)有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD463",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Haitong International Securities Company Limited",
          "nameChinese": "海通國際證券有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD464",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Head \u0026 Shoulders Securities Limited ",
          "nameChinese": "聯合證券有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD465",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Huatai Financial Holdings (Hong Kong) Limited",
          "nameChinese": "華泰金融控股(香港)有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD466",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "ICBC International Securities Limited",
          "nameChinese": "工銀國際證券有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD467",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "iFAST Financial Pte Ltd",
          "nameChinese": "iFAST Financial Pte Ltd",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD468",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "J.P. Morgan Broking (Hong Kong) Limited",
          "nameChinese": "J.P. Morgan Broking (Hong Kong) Limited",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD469",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Jane Street Financial Limited",
          "nameChinese": "Jane Street Financial Limited",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD470",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "KGI Asia Limited",
          "nameChinese": "凱基證券亞洲有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD471",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Korea Investment \u0026 Securities Asia Limited",
          "nameChinese": "韓國投資證券亞洲有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD472",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Long Bridge HK Limited",
          "nameChinese": "長橋證券(香港)有限公司 ",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD473",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Lu International (Hong Kong) Limited",
          "nameChinese": "陸國際(香港)有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD474",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Masterlink Securities (Hong Kong) Corporation Limited",
          "nameChinese": "元富證券(香港)有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD475",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Merrill Lynch (Singapore) Pte Ltd",
          "nameChinese": "Merrill Lynch (Singapore) Pte Ltd",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD476",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Merrill Lynch Far East Limited",
          "nameChinese": "Merrill Lynch Far East Limited",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD477",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Mirae Asset Securities (HK) Limited",
          "nameChinese": "未來資產證券(香港)有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD478",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Morgan Stanley Hong Kong Securities Limited",
          "nameChinese": "Morgan Stanley Hong Kong Securities Limited",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD479",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Nomura International (Hong Kong) Limited",
          "nameChinese": "野村國際(香港)有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD480",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Oriental Patron Securities Limited",
          "nameChinese": "東英亞洲證券有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD481",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Phillip Securities (Hong Kong) Limited",
          "nameChinese": "輝立證券(香港)有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD482",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Phillip Securities Pte Ltd",
          "nameChinese": "Phillip Securities Pte Ltd",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD483",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "SG Securities (HK) Limited",
          "nameChinese": "法國興業證券(香港)有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD484",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Shanxi Securities International Limited",
          "nameChinese": "山證國際證券有限公司,",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD485",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Taiping Securities (HK) Co Limited",
          "nameChinese": "太平証券(香港)有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD486",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "The Hong Kong and Shanghai Banking Corporation Limited",
          "nameChinese": "香港上海滙豐銀行有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD487",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Tiger Brokers (Singapore) Pte Ltd",
          "nameChinese": "Tiger Brokers (Singapore) Pte Ltd",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD488",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "UBS Securities Hong Kong Limited",
          "nameChinese": "UBS Securities Hong Kong Limited",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD489",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "UBS Securities Pte Ltd",
          "nameChinese": "UBS Securities Pte Ltd",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD490",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "UOB Kay Hian Private Limited",
          "nameChinese": "UOB Kay Hian Private Limited",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD491",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Valuable Capital Limited",
          "nameChinese": "華盛資本証券有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD492",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Yuanta Securities (Hong Kong) Company Limited",
          "nameChinese": "元大證券(香港)有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD493",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Zhongtai International Securities Limited",
          "nameChinese": "中泰國際證券有限公司",
          "partyCategory": "PD",
          "partyCategoryDesc": "Participant Dealer",
          "partyId": "PD494",
          "references": []
        },
        {
          "location": null,
          "locationCode": null,
          "name": "Koteswararao Perumulla",
          "nameChinese": "科特斯瓦拉朗佩鲁穆拉",
          "partyCategory": "PMGR",
          "partyCategoryDesc": "Portfolio Manager",
          "partyId": "PM805",
          "references": []
        },
        {
          "location": "India",
          "locationCode": "IN",
          "name": "Registrar",
          "nameChinese": "海通國際證券有限公司",
          "partyCategory": "RGTR",
          "partyCategoryDesc": "Registrar",
          "partyId": "RG892",
          "references": []
        },
        {
          "location": "Singapore",
          "locationCode": "SG",
          "name": "BOCI-Prudential Trustee Limited",
          "nameChinese": "中银国际英国保诚信托有限公司",
          "partyCategory": "RGTR",
          "partyCategoryDesc": "Registrar",
          "partyId": "RG902",
          "references": [
            {
              "type": "",
              "value": ""
            }
          ]
        },
        {
          "location": "United Arab Emirates",
          "locationCode": "AE",
          "name": "HSBC Trustee (Cayman) Limited",
          "nameChinese": "汇丰信托（开曼）有限公司",
          "partyCategory": "RGTR",
          "partyCategoryDesc": "Registrar",
          "partyId": "RG903",
          "references": [
            {
              "type": "",
              "value": ""
            }
          ]
        },
        {
          "location": "Hong Kong",
          "locationCode": "HK",
          "name": "Cititrust Limited",
          "nameChinese": "花旗信托有限公司",
          "partyCategory": "RGTR",
          "partyCategoryDesc": "Registrar",
          "partyId": "RG910",
          "references": []
        },
        {
          "location": "Hong Kong",
          "locationCode": "HK",
          "name": "HSBC Institutional Trust Services (Asia) Limited",
          "nameChinese": "汇丰设计信托服务（Azowyo）有限公司",
          "partyCategory": "RGTR",
          "partyCategoryDesc": "Registrar",
          "partyId": "RG923",
          "references": []
        },
        {
          "location": "Hong Kong",
          "locationCode": "HK",
          "name": "Tricor Investor Services Limited",
          "nameChinese": "卓佳证券投资有限公司",
          "partyCategory": "RGTR",
          "partyCategoryDesc": "Registrar",
          "partyId": "RG926",
          "references": []
        },
        {
          "location": "China",
          "locationCode": "CN",
          "name": "HSBC CUSTODIAN\t\n",
          "nameChinese": "汇丰托管人\n",
          "partyCategory": "TRUS",
          "partyCategoryDesc": "Trustee",
          "partyId": "TS900",
          "references": [
            {
              "type": "",
              "value": ""
            }
          ]
        },
        {
          "location": "China",
          "locationCode": "CN",
          "name": "HSBC Institutional Trust Services (Asia) Limited",
          "nameChinese": "汇丰机构信托服务（亚洲）有限公司",
          "partyCategory": "TRUS",
          "partyCategoryDesc": "Trustee",
          "partyId": "TS901",
          "references": [
            {
              "type": "",
              "value": ""
            }
          ]
        },
        {
          "location": "Hong Kong",
          "locationCode": "HK",
          "name": "cititrust Limited",
          "nameChinese": "花旗信托有限公司",
          "partyCategory": "TRUS",
          "partyCategoryDesc": "Trustee",
          "partyId": "TS909",
          "references": []
        },
        {
          "location": "Hong Kong",
          "locationCode": "HK",
          "name": "Tricor Investor Services Limited",
          "nameChinese": "卓佳证券投资有限公司",
          "partyCategory": "TRUS",
          "partyCategoryDesc": "Trustee",
          "partyId": "TS925",
          "references": []
        }
      ],
      "status": "success"
    }
  }

  getCode() {
    return {
      "data": [
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "BBGTICKER",
          "code_desc": "BBG Ticker",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-17T07:21:31.6803584Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "BIC",
          "code_desc": "BIC Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:50:35.7376096Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "CDSREF",
          "code_desc": "CDS BBG Ticker",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-23T06:13:25.2570038Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "CREDIT_RATING_ISIN",
          "code_desc": "Credit Rating ISIN",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-23T06:16:01.5848682Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "CUST",
          "code_desc": "Custodian Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T06:00:56.3656017Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "FLEX",
          "code_desc": "Flex System Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:56:52.0301714Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "INT",
          "code_desc": "Internal Reference",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T05:50:06.5343032Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "PMA_ACCT_ID",
          "code_desc": "PMA Account ID",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-02-23T06:07:23.7734862Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "PMA_CUST",
          "code_desc": "PMA Custodian Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T11:50:24.1658652Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "PARTY_REF",
          "code": "TRADAR_CODE",
          "code_desc": "Tradar Code",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-01-31T06:02:25.8486552Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "NAV1_SC_SLA",
          "code": "09:02",
          "code_desc": "09:02",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-04-14T09:09:05.8806944Z",
          "version_user": "RISK"
        },
        {
          "class_desc": null,
          "class_type": "NAV2_SC_SLA",
          "code": "16:29",
          "code_desc": "16:29",
          "seq_no": 0,
          "tenant_id": "CSOP",
          "version_date": "2023-04-14T09:10:48.6839181Z",
          "version_user": "RISK"
        },
      ],
      "status": "success"
    }
  }

  getDcDropdownOptions() {
    return {
      "data": [
        {
          "category": "UNDERLIER_STOCK_FILL_DATE",
          "category_desc": "Underlier Stock Fill Date",
          "code": "T0",
          "code_desc": "T0",
          "id": "2FD9B7DD-F482-40B4-917C-55DC54EB86B8",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-27T10:25:23.7016932Z",
          "version_user": "OPSUSER"
        },
        {
          "category": "UNDERLIER_STOCK_FILL_DATE",
          "category_desc": "Underlier Stock Fill Date",
          "code": "T2",
          "code_desc": "T2",
          "id": "57413A0E-A9B9-4236-A38A-B3EEC04AAB0E",
          "seq": 1,
          "tenant_id": "CSOP",
          "version_date": "2022-05-27T10:25:47.8284104Z",
          "version_user": "OPSUSER"
        }
      ],
      "status": "success"
    }
  }

  getFundTypes() {
    return {
      "rows": [
        {
          "OPS_Attr": true,
          "RISK_Attr": true,
          "_id": "60fa98a38bcb6cccf15c270a",
          "companyID": "CSOP",
          "enumType": "FUND_TYPES",
          "label": "BOND ETF",
          "name": "BOND_ETF",
          "type": "BOND ETF",
          "value": "BE"
        },
        {
          "OPS_Attr": false,
          "RISK_Attr": true,
          "_id": "60fa9dbc8bcb6cccf15c4a8d",
          "companyID": "CSOP",
          "enumType": "FUND_TYPES",
          "label": "BOND MF",
          "name": "BOND_MF",
          "type": "BOND MF",
          "value": "BM"
        },
        {
          "OPS_Attr": true,
          "RISK_Attr": true,
          "_id": "60fa9dfc8bcb6cccf15c4c48",
          "companyID": "CSOP",
          "enumType": "FUND_TYPES",
          "label": "EQUITY ETF",
          "name": "EQUITY_ETF",
          "type": "EQUITY ETF",
          "value": "EE"
        },
        {
          "OPS_Attr": false,
          "RISK_Attr": true,
          "_id": "60fa9e7f8bcb6cccf15c4fa4",
          "companyID": "CSOP",
          "enumType": "FUND_TYPES",
          "label": "EQUITY MF",
          "name": "EQUITY_MF",
          "type": "EQUITY MF",
          "value": "EM"
        },
        {
          "OPS_Attr": true,
          "RISK_Attr": true,
          "_id": "60fa9ee68bcb6cccf15c5263",
          "companyID": "CSOP",
          "enumType": "FUND_TYPES",
          "label": "L\u0026I ETF",
          "name": "L\u0026I_ETF",
          "type": "L\u0026I ETF",
          "value": "LE"
        },
        {
          "OPS_Attr": false,
          "RISK_Attr": true,
          "_id": "60fa9efe8bcb6cccf15c5304",
          "companyID": "CSOP",
          "enumType": "FUND_TYPES",
          "label": "L\u0026I MF",
          "name": "L\u0026I_MF",
          "type": "L\u0026I MF",
          "value": "LM"
        },
        {
          "OPS_Attr": false,
          "RISK_Attr": true,
          "_id": "60fa9f3e8bcb6cccf15c54db",
          "companyID": "CSOP",
          "enumType": "FUND_TYPES",
          "label": "COMMODITIES",
          "name": "COMMODITIES",
          "type": "COMMODITIES",
          "value": "CE"
        },
        {
          "OPS_Attr": true,
          "RISK_Attr": true,
          "_id": "60faa6598bcb6cccf15c8ab4",
          "companyID": "CSOP",
          "enumType": "FUND_TYPES",
          "label": "MMF ETF",
          "name": "MMF ETF",
          "type": "MMF_ETF",
          "value": "ME"
        },
        {
          "OPS_Attr": true,
          "RISK_Attr": false,
          "_id": "PRIVATE_MF_OPS",
          "companyID": "CSOP",
          "enumType": "FUND_TYPES",
          "label": "PRIVATE MF",
          "name": "PRIVATE_MF",
          "type": "PRIVATE MF",
          "value": "PVMF"
        },
        {
          "OPS_Attr": true,
          "RISK_Attr": false,
          "_id": "PUBLIC_MF_OPS",
          "companyID": "CSOP",
          "enumType": "FUND_TYPES",
          "label": "PUBLIC MF",
          "name": "PUBLIC_MF",
          "type": "PUBLIC MF",
          "value": "PBMF"
        }
      ],
      "status": "success",
      "total": 1,
      "totalRecords": 10
    }
  }

  getReconData() {
    return {
      "data": {
        "rows": [
          {
            "bbg": "3033 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00178",
            "custodian_fund_code": "CHST",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHSA",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000123",
            "fund_name": "CSOP Hang Seng TECH Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CHST",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3033UA HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00199",
            "custodian_fund_code": "CHST",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHSB",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000123",
            "fund_name": "CSOP Hang Seng TECH Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "HKD Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted class",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CHST",
            "type": "EE",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3193 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00181",
            "custodian_fund_code": "CS5G",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CS5G",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000125",
            "fund_name": "CSOP Yinhua CSI 5G Communications Theme ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CS5G",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "9096 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00091",
            "custodian_fund_code": "CUSF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CUSA",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000042",
            "fund_name": "CSOP US Dollar Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CUSF",
            "type": "ME",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "9096UA HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00172",
            "custodian_fund_code": "CUSF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CUSB",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000042",
            "fund_name": "CSOP US Dollar Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "USD Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Share Class A",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CUSF",
            "type": "ME",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3053 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00077",
            "custodian_fund_code": "CMMF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CMM1",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000029",
            "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-MMF",
            "type": "ME",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3053UA HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00173",
            "custodian_fund_code": "CMMF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CMM2",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000029",
            "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "HKD Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted share class A",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-MMF",
            "type": "ME",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3053UP HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00197",
            "custodian_fund_code": "CMMF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CMMP",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000029",
            "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "HKD Share Class P unlisted",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted share class P",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-MMF",
            "type": "ME",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3109 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00186",
            "custodian_fund_code": "CS50",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "OK",
            "fa_gav_trading": 0,
            "fa_nav": 49679055,
            "fa_nav/share": 8.8713,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 1,
            "fa_units": 5600000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000130",
            "fund_name": "CSOP STAR 50 Index ETF",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29533,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 0,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": -225,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_trading": 0,
            "pma_nav": 49679055,
            "pma_nav/share": 8.8713,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": 1,
            "pma_units": 5600000,
            "recon_status": "RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37375#37368",
            "source2_id": "37012",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-STAR50",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "EE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-31T09:58:19.4166667Z"
          },
          {
            "bbg": "83122 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00040",
            "custodian_fund_code": "CSTB",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CST1",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000008",
            "fund_name": "CSOP RMB Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-USTB",
            "type": "ME",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3122UA HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00198",
            "custodian_fund_code": "CSTB",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSTA",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000008",
            "fund_name": "CSOP RMB Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "RMB Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Share Class A",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-USTB",
            "type": "ME",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7348 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00185",
            "custodian_fund_code": "CA5I",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 2783562,
            "fa_nav/share": 1.1598,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": -1,
            "fa_units": 2400000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000129",
            "fund_name": "CSOP FTSE China A50 Index Daily (-1x) Inverse Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29532,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.0001,
            "nav/share_diff_status": "Unmatched",
            "nav2_custodian_err": 2,
            "nav2_custodian_error_diff": 2,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": -207,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 2,
            "nav_px_error_diff": 2,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": -0.9,
            "normalized_nav_diff": -198,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 2783769,
            "pma_nav/share": 1.1599,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 0,
            "pma_units": 2400000,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37112#37357",
            "source2_id": "37006",
            "total_nav_diff_diff": -1,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-A50-I",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-31T09:58:19.0866667Z"
          },
          {
            "bbg": "7248 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00184",
            "custodian_fund_code": "CA5L",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 3481265,
            "fa_nav/share": 0.4581,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": -13443.3446788,
            "fa_units": 7600000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000128",
            "fund_name": "CSOP FTSE China A50 Index Daily (2x) Leveraged Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29465,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 2,
            "nav2_custodian_error_diff": 2,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": -243,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": -295,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 3481508,
            "pma_nav/share": 0.4581,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": -2569.23456788,
            "pma_units": 7600000,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:22.4582322Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37120#37076",
            "source2_id": "37030",
            "total_nav_diff_diff": -132,
            "total_nav_diff_diff_status": "Unmatched",
            "tradar_code": "HK-A50-L",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:05:56.4Z"
          },
          {
            "bbg": "3066 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00244",
            "custodian_fund_code": "CBTC",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 28152540,
            "fa_nav/share": 1.5308,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "CBTC",
            "fa_total_nav_diff": 3,
            "fa_units": 18390300,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000173",
            "fund_name": "CSOP Bitcoin Futures ETF",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29460,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": 0.0003,
            "nav/share_diff_status": "Unmatched",
            "nav2_custodian_err": 2,
            "nav2_custodian_error_diff": 2,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 6557,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 1,
            "nav_px_error_diff": 1,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 2,
            "normalized_nav_diff": 6186,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_trading": 0,
            "pma_nav": 28145983,
            "pma_nav/share": 1.5305,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 3,
            "pma_units": 18390300,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:52.1219647Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "37096#37051",
            "source2_id": "37038",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-BTCFUT",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:04:49.8866667Z"
          },
          {
            "bbg": "3194 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00188",
            "custodian_fund_code": "CGCT",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000132",
            "fund_name": "CSOP Global Cloud Computing Technology Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CLOUD",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7333 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00177",
            "custodian_fund_code": "CS3I",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 25725976,
            "fa_nav/share": 6.4315,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 4000000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000122",
            "fund_name": "CSOP CSI 300 Index Daily (-1x) Inverse Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29462,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 1,
            "nav2_pos_error_diff": 1,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 45,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 1,
            "nav_px_error_diff": 1,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": -24,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 25725931,
            "pma_nav/share": 6.4315,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -1,
            "pma_units": 4000000,
            "recon_status": "RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:22.4542373Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37107#37088",
            "source2_id": "37015",
            "total_nav_diff_diff": 1,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-CS3-I",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:05:17.39Z"
          },
          {
            "bbg": "7233 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00176",
            "custodian_fund_code": "CS3L",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 203759525,
            "fa_nav/share": 4.3538,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": -1,
            "fa_units": 46800000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000121",
            "fund_name": "CSOP CSI 300 Index Daily (2x) Leveraged Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29527,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.0015,
            "nav/share_diff_status": "Unmatched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": -70762,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 1,
            "nav_px_error_diff": 1,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": -3.4,
            "normalized_nav_diff": -68515,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 203830287,
            "pma_nav/share": 4.3553,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": 0,
            "pma_units": 46800000,
            "recon_status": "NOT RECONCILED",
            "share_class": "CNY",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37104#37369",
            "source2_id": "37007",
            "total_nav_diff_diff": -1,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-CS3-L",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-31T09:58:17.54Z"
          },
          {
            "bbg": "3068 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00245",
            "custodian_fund_code": "CEHR",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 11162206,
            "fa_nav/share": 1.4422,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "CEHR",
            "fa_total_nav_diff": 2,
            "fa_units": 7739500,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000174",
            "fund_name": "CSOP Ether Futures ETF",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29471,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.0002,
            "nav/share_diff_status": "Unmatched",
            "nav2_custodian_err": 2,
            "nav2_custodian_error_diff": 2,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": -1536,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 1,
            "nav_px_error_diff": 1,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": -1.4,
            "normalized_nav_diff": -1249,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_trading": 0,
            "pma_nav": 11163742,
            "pma_nav/share": 1.4424,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 1,
            "pma_units": 7739500,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:52.1238785Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "37062#37131",
            "source2_id": "37037",
            "total_nav_diff_diff": 1,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-DCRFUT",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:08:34Z"
          },
          {
            "bbg": "7299 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00175",
            "custodian_fund_code": "CSGD",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 183986261,
            "fa_nav/share": 1.021,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 1,
            "fa_units": 180200000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000120",
            "fund_name": "CSOP Gold Futures Daily (2x) Leveraged Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29463,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 1,
            "nav2_custodian_error_diff": 1,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 1,
            "nav2_pos_error_diff": 1,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 100,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": 2061,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 183986161,
            "pma_nav/share": 1.021,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 0,
            "pma_units": 180200000,
            "recon_status": "RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:52.113067Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "37095#37049",
            "source2_id": "37036",
            "total_nav_diff_diff": 1,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-GLD-L",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:05:17.68Z"
          },
          {
            "bbg": "7288 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00072",
            "custodian_fund_code": "CHS3",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 291104255,
            "fa_nav/share": 2.3629,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "CHS3",
            "fa_total_nav_diff": 0,
            "fa_units": 123200000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000025",
            "fund_name": "CSOP Hang Seng China Enterprises Index Daily (2x) Leveraged Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29529,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 1,
            "nav2_pos_error_diff": 1,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 5667156,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": -5025,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 285437099,
            "pma_nav/share": 2.3629,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": 0,
            "pma_units": 120800000,
            "recon_status": "RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37105#37363",
            "source2_id": "37034",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HCE-L",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": 2400000,
            "units_diff_in_baskets": 3,
            "units_diff_status": "Matched",
            "version_date": "2023-05-31T09:58:18.1333333Z"
          },
          {
            "bbg": "3174 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00190",
            "custodian_fund_code": "CSCH",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "OK",
            "fa_gav_trading": 0,
            "fa_nav": 28555210,
            "fa_nav/share": 2.8661,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 2,
            "fa_units": 9963100,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000134",
            "fund_name": "CSOP China Healthcare Disruption Index ETF",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29534,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 0,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": -31,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_trading": 0,
            "pma_nav": 28555210,
            "pma_nav/share": 2.8661,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": 2,
            "pma_units": 9963100,
            "recon_status": "RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37123#37353",
            "source2_id": "37031",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HEALTH",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "EE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-31T09:58:25.3333333Z"
          },
          {
            "bbg": "3037 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00187",
            "custodian_fund_code": "CSOPHSIETF",
            "custodian_recon_party": "BOC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "1",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000131",
            "fund_name": "CSOP Hang Seng Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HSI",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3037UA HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00204",
            "custodian_fund_code": "CSOPHSIETF",
            "custodian_recon_party": "BOC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "2",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000131",
            "fund_name": "CSOP Hang Seng Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "HKD Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Share Class A",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HSI",
            "type": "EE",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7500 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00092",
            "custodian_fund_code": "CHS5",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHS5",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000043",
            "fund_name": "CSOP Hang Seng Index Daily (-2x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HSI-2I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7200 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00074",
            "custodian_fund_code": "CHS1",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 4720239881,
            "fa_nav/share": 4.1522,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "CHS1",
            "fa_total_nav_diff": 234567890,
            "fa_units": 1136800000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000027",
            "fund_name": "CSOP Hang Seng Index Daily (2x) Leveraged Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29461,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.0001,
            "nav/share_diff_status": "Unmatched",
            "nav2_custodian_err": 3,
            "nav2_custodian_error_diff": 3,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 3,
            "nav2_pos_error_diff": 3,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": -33259231,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": -0.2,
            "normalized_nav_diff": -94759,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 4753499112,
            "pma_nav/share": 4.1523,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": -2334545678.323467788998,
            "pma_units": 1144800000,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:33.0145071Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37100#37077",
            "source2_id": "37021",
            "total_nav_diff_diff": 1,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HSI-L",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": -8000000,
            "units_diff_in_baskets": -10,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:04:59.5733333Z"
          },
          {
            "bbg": "7552 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00183",
            "custodian_fund_code": "CHS7",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHS7",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000127",
            "fund_name": "CSOP Hang Seng TECH Index Daily (-2x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HST-2I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7226 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00182",
            "custodian_fund_code": "CHS6",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 7801175424,
            "fa_nav/share": 4.6051,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "CHS6",
            "fa_total_nav_diff": 0,
            "fa_units": 1694040000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000126",
            "fund_name": "CSOP Hang Seng TECH Index Daily (2x) Leveraged Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29530,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.0002,
            "nav/share_diff_status": "Unmatched",
            "nav2_custodian_err": 7,
            "nav2_custodian_error_diff": 7,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 3,
            "nav2_pos_error_diff": 3,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": -5988212,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": -0.4,
            "normalized_nav_diff": -386988,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 7807163636,
            "pma_nav/share": 4.6053,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": 1,
            "pma_units": 1695240000,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37118#37361",
            "source2_id": "37018",
            "total_nav_diff_diff": -1,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HST-L",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": -1200000,
            "units_diff_in_baskets": -3,
            "units_diff_status": "Matched",
            "version_date": "2023-05-31T09:58:18.5366667Z"
          },
          {
            "bbg": "7252 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00229",
            "custodian_fund_code": "CSBL",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 2996938,
            "fa_nav/share": 7.4923,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0.1234567890,
            "fa_units": 400000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000160",
            "fund_name": "CSOP CSI Brokerage Index Daily (2x) Leveraged Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29464,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.0017,
            "nav/share_diff_status": "Unmatched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 1,
            "nav2_pos_error_diff": 1,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": -673,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 1,
            "nav_px_error_diff": 1,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": -2.3,
            "normalized_nav_diff": -662,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 2997611,
            "pma_nav/share": 7.494,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": 1234567890,
            "pma_units": 400000,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:33.018158Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37121#37084",
            "source2_id": "37020",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-IBB-L",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:05:47.41Z"
          },
          {
            "bbg": "3034 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00201",
            "custodian_fund_code": "CSME",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "OK",
            "fa_gav_trading": 0,
            "fa_nav": 5264320,
            "fa_nav/share": 0.7861,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": -1,
            "fa_units": 6696600,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000141",
            "fund_name": "CSOP Metaverse Concept ETF",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29468,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 0,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": 123,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_trading": 0,
            "pma_nav": 5264320,
            "pma_nav/share": 0.7861,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": -156789.3446890,
            "pma_units": 6696600,
            "recon_status": "RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:52.1199575Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "37098#37136",
            "source2_id": "37042",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-METAV",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "EE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:08:33.7133333Z"
          },
          {
            "bbg": "3003 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00196",
            "custodian_fund_code": "CM50",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 70751896,
            "fa_nav/share": 4.7254,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": -2,
            "fa_units": 14972700,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000139",
            "fund_name": "CSOP MSCI China A 50 Connect ETF",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29528,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 1,
            "nav2_custodian_error_diff": 1,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 0,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": -101,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_trading": 0,
            "pma_nav": 70751896,
            "pma_nav/share": 4.7254,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -2,
            "pma_units": 14972700,
            "recon_status": "RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37378#37347",
            "source2_id": "37026",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-MSCIA50",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "EE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-31T09:58:18.19Z"
          },
          {
            "bbg": "7568 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00162",
            "custodian_fund_code": "CND1",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 242218290,
            "fa_nav/share": 1.4777,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "CND1",
            "fa_total_nav_diff": 1,
            "fa_units": 163920000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000113",
            "fund_name": "CSOP NASDAQ-100 Index Daily (-2x) Inverse Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29467,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 2,
            "nav2_pos_error_diff": 2,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": -3598,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": -6294,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 242221888,
            "pma_nav/share": 1.4777,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 1,
            "pma_units": 163920000,
            "recon_status": "RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:52.1090422Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "37064#37132",
            "source2_id": "37041",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-NDX-2I",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:08:33.5133333Z"
          },
          {
            "bbg": "7266 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00174",
            "custodian_fund_code": "CSND",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 12332177,
            "fa_nav/share": 1.7081,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 7220000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000119",
            "fund_name": "CSOP NASDAQ-100 Index Daily (2x) Leveraged Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29470,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 1,
            "nav2_pos_error_diff": 1,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": -67,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": -305,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 12332244,
            "pma_nav/share": 1.7081,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 1,
            "pma_units": 7220000,
            "recon_status": "RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:52.1111553Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "37060#37129",
            "source2_id": "37043",
            "total_nav_diff_diff": -1,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-NDX-L",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:08:33.9933333Z"
          },
          {
            "bbg": "7345 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00195",
            "custodian_fund_code": "CSWC",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_trading": 0,
            "fa_nav": 1845189,
            "fa_nav/share": 0.5766,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 1,
            "fa_units": 3200000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000138",
            "fund_name": "CSOP WTI Crude Oil Futures Daily (-1x) Inverse Product",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29469,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 1,
            "nav2_pos_error_diff": 1,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 1,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": 69,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_trading": 0,
            "pma_nav": 1845188,
            "pma_nav/share": 0.5766,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 1,
            "pma_units": 3200000,
            "recon_status": "RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:52.1150516Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "37166#37135",
            "source2_id": "37040",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-OIL-I",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "LE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:08:33.7466667Z"
          },
          {
            "bbg": "3134 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00189",
            "custodian_fund_code": "CHPC",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "OK",
            "fa_gav_trading": 0,
            "fa_nav": 70421055,
            "fa_nav/share": 6.6435,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": -1,
            "fa_units": 10600000,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000133",
            "fund_name": "CSOP Huatai-PineBridge CSI Photovoltaic Industry ETF",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29531,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 0,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": -45,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_trading": 0,
            "pma_nav": 70421055,
            "pma_nav/share": 6.6435,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -1,
            "pma_units": 10600000,
            "recon_status": "RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37103#37367",
            "source2_id": "37017",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-PTVT",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "EE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-31T09:58:18.7866667Z"
          },
          {
            "bbg": "3162 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00191",
            "custodian_fund_code": "CSGS",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000135",
            "fund_name": "CSOP Global Smart Driving Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-SDRIVE",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3004 HK EQUITY",
            "ccy": "VND",
            "class_id": "CLS00225",
            "custodian_fund_code": "CSV1",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000158",
            "fund_name": "CSOP FTSE Vietnam 30 ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "VND Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "VND",
            "share_class_ccy": "VND",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-VN30",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "SCY SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00246",
            "custodian_fund_code": "CCSI",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CCSI",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000175",
            "fund_name": "CSOP CSI STAR and CHINEXT 50 Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "CNY",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-CSI50",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "SRT SP EQUITY",
            "ccy": "SGD",
            "class_id": "CLS00193",
            "custodian_fund_code": "CISR",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "OK",
            "fa_gav_trading": 0,
            "fa_nav": 86830572,
            "fa_nav/share": 0.8516,
            "fa_nav2_custodian_error": 0,
            "fa_nav2_pos_error": 0,
            "fa_nav_px_error": 0,
            "fa_share_class": "CISR",
            "fa_total_nav_diff": 2,
            "fa_units": 101957250,
            "file_status": "PROCESSED",
            "fund_class": "ETF",
            "fund_id": "1000137",
            "fund_name": "CSOP iEdge S-REIT Leaders Index ETF",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 29472,
            "is_holiday": false,
            "match_status": "Matched",
            "nav/share_diff": 0,
            "nav/share_diff_status": "Matched",
            "nav2_custodian_err": 0,
            "nav2_custodian_error_diff": 0,
            "nav2_custodian_error_diff_status": "NA",
            "nav2_pos_error": 0,
            "nav2_pos_error_diff": 0,
            "nav2_pos_error_diff_status": "NA",
            "nav_diff": 0,
            "nav_diff_status": "Matched",
            "nav_frequency": "DAILY",
            "nav_px_error": 0,
            "nav_px_error_diff": 0,
            "nav_px_error_diff_status": "NA",
            "nav_share_diff_bps": 0,
            "normalized_nav_diff": 3778,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_trading": 0,
            "pma_nav": 86830572,
            "pma_nav/share": 0.8516,
            "pma_nav2_custodian_error": 0,
            "pma_nav2_pos_error": 0,
            "pma_nav_px_error": 0,
            "pma_share_class": "SGD Share Class A",
            "pma_total_nav_diff": 2,
            "pma_units": 101957250,
            "recon_status": "RECONCILED",
            "share_class": "SGD",
            "share_class_ccy": "SGD",
            "sign_off_comments": "",
            "sign_off_date": "2023-05-30T07:10:33.0162223Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "37170#37089",
            "source2_id": "37024",
            "total_nav_diff_diff": 0,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "SG-REIT",
            "trade_date": "2023-05-23T00:00:00Z",
            "type": "EE",
            "units_diff": 0,
            "units_diff_in_baskets": 0,
            "units_diff_status": "Matched",
            "version_date": "2023-05-30T07:08:34.62Z"
          },
          {
            "bbg": "CSOPUMM SP EQUITY",
            "ccy": "USD",
            "class_id": "CLS00205",
            "custodian_fund_code": "CUMF",
            "custodian_recon_party": "CITI",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000142",
            "fund_name": "CSOP USD Money Market Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-USDMF",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CYX SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00228",
            "custodian_fund_code": "CCGB",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CCBG",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000124",
            "fund_name": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_share_class": "RMB Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-WGBI",
            "type": "",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CYC SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00180",
            "custodian_fund_code": "CCGB",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CCGA",
            "file_status": "NAV2, OMS Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000124",
            "fund_name": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "SGD",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-WGBI",
            "type": "",
            "version_date": "0001-01-01T00:00:00Z"
          }
        ]
      },
      "status": "success"
    }


  }

  getNewsletterData() {
    return {

      "status": "success"
    }
  }

  getLiquidationCostData() {
    return {
      data: [
        {
          fundName: "CO-CHST",
          liquidationCost: "1,00,000",
          currentAum: "0.01%",
          threshold: "<=0.12%",
          monitoring: "Pass"
        },
        {
          fundName: "CO-A50F",
          liquidationCost: "1,45,000",
          currentAum: "0.012%",
          threshold: "<=0.12%",
          monitoring: "Pass"
        },
        {
          fundName: "CO-USDB",
          liquidationCost: "2,34,000",
          currentAum: "0.01%",
          threshold: "<=0.12%",
          monitoring: "Pass"
        },
      ],
      status: "success"
    }
  }

  getProfileEquityConfigData() {
    return {
      data: [
        {
          fundCode: "CO-A50F",
          high: "70%",
          highCondition: "",
          medium: "",
          mediumCondition: "",
          low: "",
          lowCondition: "",
          illiquid: "15%",
          effectiveFrom: "2023-05-06T00:00:00Z",
          effectiveTo: "2023-08-06T00:00:00Z"
        },
        {
          fundCode: "CO-CHST",
          high: "70%",
          highCondition: "",
          medium: "",
          mediumCondition: "",
          low: "",
          lowCondition: "",
          illiquid: "15%",
          effectiveFrom: "2023-05-06T00:00:00Z",
          effectiveTo: "2023-08-06T00:00:00Z"
        },
        {
          fundCode: "CO-B5",
          high: "60%",
          highCondition: "",
          medium: "",
          mediumCondition: "",
          low: "",
          lowCondition: "",
          illiquid: "15%",
          effectiveFrom: "2023-05-06T00:00:00Z",
          effectiveTo: "2023-08-06T00:00:00Z"
        }
      ],
      status: "success"
    }
  }

  getNewsletterReports() {
    return {
      data: [
        {
          fundName: "CSOP Source FTSE China A50 UCITS ETF",
          shareClassName: "CO-LNA50",
          tradarCode: "CO-LNA50",
          generatedDate: "2023-05-05T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Completed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
          shareClassName: "RMB Share Class A",
          tradarCode: "CO-B5",
          generatedDate: "2023-05-06T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Completed",
          type: "Ad-hoc",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "RMB High Yield Bond Fund",
          shareClassName: "RMB Share Class P unlisted",
          tradarCode: "CNBB",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Completed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
        {
          fundName: "China New Balance Opportunity Fund",
          shareClassName: "RMB Share Class unlisted A",
          tradarCode: "CNBO",
          generatedDate: "2023-05-07T00:00:00Z",
          user: "system",
          asofDate: "2023-04-28T00:00:00Z",
          status: "Failed",
          type: "Month End",
          remarks: "comments..."
        },
      ],
      "Chartdata": {
        "generated": 9,
        "non_generated": 10
      },
      status: "success"
    }
  }


  getLiquidCardData() {
    return {
      "data": {
        "equity": {
          "liquidity_profile": {
            "breach": 11,
            "un_breach": 6
          },
          "liquidation_cost": {
            "breach": 8,
            "un_breach": 13
          }
        },
        "fixed_income": {
          "liquidity_profile": {
            "breach": 1,
            "un_breach": 6
          },
          "liquidation_cost": {
            "breach": 9,
            "un_breach": 3
          }
        },
        "mmf": {
          "liquidity_profile": {
            "breach": 9,
            "un_breach": 8
          },
        }
      },
      "status": "success"
    }
  }

  getLiquidityCardData() {
    return {
      "data": {
        "equities": {
          "liquidation_cost": {
            "breached": 2,
            "un_breached": 5
          },
          "liquidity_Profile": {
            "breached": 3,
            "un_breached": 8
          }
        },
        "fixed_incomes": {
          "liquidation_cost": {
            "breached": 6,
            "un_breached": 2
          },
          "liquidity_Profile": {
            "breached": 4,
            "un_breached": 1
          }
        },
        "mmf": {
          "liquidity_Profile": {
            "breached": 8,
            "un_breached": 7
          }
        }
      },
      "status": "success"
    }
  }


  getLiquidationCostDashboardData() {
    return {
      "data": [{
        "acctBookID": "1000023",
        "acctHoldingID": 15372,
        "liquidationCost": 310.58,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.304,
        "securityClassification": "EQTY",
        "classificationName": "Equity",
        "threshold": 0,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "CO-A50F"
      },
      {
        "acctBookID": "1000002",
        "acctHoldingID": 15373,
        "liquidationCost": 824.58,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.30,
        "securityClassification": "BOND",
        "classificationName": "Fixed",
        "threshold": 3,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "CO-B5"
      },
      {
        "acctBookID": "100000",
        "acctHoldingID": 15374,
        "liquidationCost": 958.25,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.71,
        "securityClassification": "BOND",
        "classificationName": "Fixed",
        "threshold": 5,
        "thresholdCheck": "MEDIUM",
        "thresholdCond": "",
        "tradarCode": "RQFII-SZRMB"
      },
      {
        "acctBookID": "1000010",
        "acctHoldingID": 15375,
        "liquidationCost": 314.5,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.35,
        "securityClassification": "BOND",
        "classificationName": "Fixed",
        "threshold": 8,
        "thresholdCheck": "HIGH",
        "thresholdCond": "",
        "tradarCode": "CO-CNXT"
      },
      {
        "acctBookID": "1000008",
        "acctHoldingID": 15376,
        "liquidationCost": 104.825,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.114,
        "securityClassification": "EQTY",
        "classificationName": "Equity",
        "threshold": 6,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "CO-USTB"
      },
      {
        "acctBookID": "1000008",
        "acctHoldingID": 15376,
        "liquidationCost": 104.825,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.114,
        "securityClassification": "EQTY",
        "classificationName": "Equity",
        "threshold": 6,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "CO-UB"
      },
      {
        "acctBookID": "1000008",
        "acctHoldingID": 15376,
        "liquidationCost": 104.825,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.114,
        "securityClassification": "EQTY",
        "classificationName": "Equity",
        "threshold": 6,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "C-USTB"
      },
      {
        "acctBookID": "1000008",
        "acctHoldingID": 15376,
        "liquidationCost": 104.825,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.114,
        "securityClassification": "EQTY",
        "classificationName": "Equity",
        "threshold": 6,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "CO-B"
      },
      {
        "acctBookID": "1000008",
        "acctHoldingID": 15376,
        "liquidationCost": 104.825,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.114,
        "securityClassification": "EQTY",
        "classificationName": "Equity",
        "threshold": 6,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "COB"
      },
      {
        "acctBookID": "1000008",
        "acctHoldingID": 15376,
        "liquidationCost": 104.825,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.114,
        "securityClassification": "EQTY",
        "classificationName": "Equity",
        "threshold": 6,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "COBXDF"
      },
      {
        "acctBookID": "1000008",
        "acctHoldingID": 15376,
        "liquidationCost": 104.825,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.114,
        "securityClassification": "EQTY",
        "classificationName": "Equity",
        "threshold": 6,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "COBDF"
      },
      {
        "acctBookID": "1000008",
        "acctHoldingID": 15376,
        "liquidationCost": 104.825,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.114,
        "securityClassification": "EQTY",
        "classificationName": "Equity",
        "threshold": 6,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "COBL"
      }, {
        "acctBookID": "1000008",
        "acctHoldingID": 15376,
        "liquidationCost": 104.825,
        "monitoring": "Fail",
        "pctOfCurrentAum": 0.114,
        "securityClassification": "EQTY",
        "classificationName": "Equity",
        "threshold": 6,
        "thresholdCheck": "LOW",
        "thresholdCond": "",
        "tradarCode": "CLB"
      }
      ],
      "status": "success"
    }

  }


  getdataman() {
    return {
      data: [
        {
          id: 1000,
          name: 'James Butt',
          country: {
            name: 'Algeria',
            code: 'dz'
          },
          company: 'Benton, John B Jr',
          date: '2015-09-13',
          status: 'unqualified',
          verified: true,
          activity: 17,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 70663
        },
        {
          id: 1001,
          name: 'Josephine Darakjy',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Chanay, Jeffrey A Esq',
          date: '2019-02-09',
          status: 'proposal',
          verified: true,
          activity: 0,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 82429
        },
        {
          id: 1002,
          name: 'Art Venere',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Chemel, James L Cpa',
          date: '2017-05-13',
          status: 'qualified',
          verified: false,
          activity: 63,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 28334
        },
        {
          id: 1003,
          name: 'Lenna Paprocki',
          country: {
            name: 'Slovenia',
            code: 'si'
          },
          company: 'Feltz Printing Service',
          date: '2020-09-15',
          status: 'new',
          verified: false,
          activity: 37,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 88521
        },
        {
          id: 1004,
          name: 'Donette Foller',
          country: {
            name: 'South Africa',
            code: 'za'
          },
          company: 'Printing Dimensions',
          date: '2016-05-20',
          status: 'proposal',
          verified: true,
          activity: 33,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 93905
        },
        {
          id: 1005,
          name: 'Simona Morasca',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Chapman, Ross E Esq',
          date: '2018-02-16',
          status: 'qualified',
          verified: false,
          activity: 68,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 50041
        },
        {
          id: 1006,
          name: 'Mitsue Tollner',
          country: {
            name: 'Paraguay',
            code: 'py'
          },
          company: 'Morlong Associates',
          date: '2018-02-19',
          status: 'renewal',
          verified: true,
          activity: 54,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 58706
        },
        {
          id: 1007,
          name: 'Leota Dilliard',
          country: {
            name: 'Serbia',
            code: 'rs'
          },
          company: 'Commercial Press',
          date: '2019-08-13',
          status: 'renewal',
          verified: true,
          activity: 69,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 26640
        },
        {
          id: 1008,
          name: 'Sage Wieser',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Truhlar And Truhlar Attys',
          date: '2018-11-21',
          status: 'unqualified',
          verified: true,
          activity: 76,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 65369
        },
        {
          id: 1009,
          name: 'Kris Marrier',
          country: {
            name: 'Mexico',
            code: 'mx'
          },
          company: 'King, Christopher A Esq',
          date: '2015-07-07',
          status: 'proposal',
          verified: false,
          activity: 3,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 63451
        },
        {
          id: 1010,
          name: 'Minna Amigon',
          country: {
            name: 'Romania',
            code: 'ro'
          },
          company: 'Dorl, James J Esq',
          date: '2018-11-07',
          status: 'qualified',
          verified: false,
          activity: 38,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 71169
        },
        {
          id: 1011,
          name: 'Abel Maclead',
          country: {
            name: 'Singapore',
            code: 'sg'
          },
          company: 'Rangoni Of Florence',
          date: '2017-03-11',
          status: 'qualified',
          verified: true,
          activity: 87,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 96842
        },
        {
          id: 1012,
          name: 'Kiley Caldarera',
          country: {
            name: 'Serbia',
            code: 'rs'
          },
          company: 'Feiner Bros',
          date: '2015-10-20',
          status: 'unqualified',
          verified: false,
          activity: 80,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 92734
        },
        {
          id: 1013,
          name: 'Graciela Ruta',
          country: {
            name: 'Chile',
            code: 'cl'
          },
          company: 'Buckley Miller & Wright',
          date: '2016-07-25',
          status: 'negotiation',
          verified: false,
          activity: 59,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 45250
        },
        {
          id: 1014,
          name: 'Cammy Albares',
          country: {
            name: 'Philippines',
            code: 'ph'
          },
          company: 'Rousseaux, Michael Esq',
          date: '2019-06-25',
          status: 'new',
          verified: true,
          activity: 90,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 30236
        },
        {
          id: 1015,
          name: 'Mattie Poquette',
          country: {
            name: 'Venezuela',
            code: 've'
          },
          company: 'Century Communications',
          date: '2017-12-12',
          status: 'negotiation',
          verified: false,
          activity: 52,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 64533
        },
        {
          id: 1016,
          name: 'Meaghan Garufi',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'Bolton, Wilbur Esq',
          date: '2018-07-04',
          status: 'unqualified',
          verified: false,
          activity: 31,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 37279
        },
        {
          id: 1017,
          name: 'Gladys Rim',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'T M Byxbee Company Pc',
          date: '2020-02-27',
          status: 'renewal',
          verified: true,
          activity: 48,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 27381
        },
        {
          id: 1018,
          name: 'Yuki Whobrey',
          country: {
            name: 'Israel',
            code: 'il'
          },
          company: 'Farmers Insurance Group',
          date: '2017-12-21',
          status: 'negotiation',
          verified: true,
          activity: 16,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 9257
        },
        {
          id: 1019,
          name: 'Fletcher Flosi',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'Post Box Services Plus',
          date: '2016-01-04',
          status: 'renewal',
          verified: true,
          activity: 19,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 67783
        },
        {
          id: 1020,
          name: 'Bette Nicka',
          country: {
            name: 'Paraguay',
            code: 'py'
          },
          company: 'Sport En Art',
          date: '2016-10-21',
          status: 'renewal',
          verified: false,
          activity: 100,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 4609
        },
        {
          id: 1021,
          name: 'Veronika Inouye',
          country: {
            name: 'Ecuador',
            code: 'ec'
          },
          company: 'C 4 Network Inc',
          date: '2017-03-24',
          status: 'renewal',
          verified: false,
          activity: 72,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 26565
        },
        {
          id: 1022,
          name: 'Willard Kolmetz',
          country: {
            name: 'Tunisia',
            code: 'tn'
          },
          company: 'Ingalls, Donald R Esq',
          date: '2017-04-15',
          status: 'renewal',
          verified: true,
          activity: 94,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 75876
        },
        {
          id: 1023,
          name: 'Maryann Royster',
          country: {
            name: 'Belarus',
            code: 'by'
          },
          company: 'Franklin, Peter L Esq',
          date: '2017-03-11',
          status: 'qualified',
          verified: false,
          activity: 56,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 41121
        },
        {
          id: 1024,
          name: 'Alisha Slusarski',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Wtlz Power 107 Fm',
          date: '2018-03-27',
          status: 'qualified',
          verified: true,
          activity: 7,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 91691
        },
        {
          id: 1025,
          name: 'Allene Iturbide',
          country: {
            name: 'Italy',
            code: 'it'
          },
          company: 'Ledecky, David Esq',
          date: '2016-02-20',
          status: 'qualified',
          verified: true,
          activity: 1,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 40137
        },
        {
          id: 1026,
          name: 'Chanel Caudy',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'Professional Image Inc',
          date: '2018-06-24',
          status: 'new',
          verified: true,
          activity: 26,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 21304
        },
        {
          id: 1027,
          name: 'Ezekiel Chui',
          country: {
            name: 'Ireland',
            code: 'ie'
          },
          company: 'Sider, Donald C Esq',
          date: '2016-09-24',
          status: 'new',
          verified: false,
          activity: 76,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 60454
        },
        {
          id: 1028,
          name: 'Willow Kusko',
          country: {
            name: 'Romania',
            code: 'ro'
          },
          company: 'U Pull It',
          date: '2020-04-11',
          status: 'qualified',
          verified: true,
          activity: 7,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 17565
        },
        {
          id: 1029,
          name: 'Bernardo Figeroa',
          country: {
            name: 'Israel',
            code: 'il'
          },
          company: 'Clark, Richard Cpa',
          date: '2018-04-11',
          status: 'renewal',
          verified: true,
          activity: 81,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 17774
        },
        {
          id: 1030,
          name: 'Ammie Corrio',
          country: {
            name: 'Hungary',
            code: 'hu'
          },
          company: 'Moskowitz, Barry S',
          date: '2016-06-11',
          status: 'negotiation',
          verified: true,
          activity: 56,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 49201
        },
        {
          id: 1031,
          name: 'Francine Vocelka',
          country: {
            name: 'Honduras',
            code: 'hn'
          },
          company: 'Cascade Realty Advisors Inc',
          date: '2017-08-02',
          status: 'qualified',
          verified: true,
          activity: 94,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 67126
        },
        {
          id: 1032,
          name: 'Ernie Stenseth',
          country: {
            name: 'Australia',
            code: 'au'
          },
          company: 'Knwz Newsradio',
          date: '2018-06-06',
          status: 'renewal',
          verified: true,
          activity: 68,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 76017
        },
        {
          id: 1033,
          name: 'Albina Glick',
          country: {
            name: 'Ukraine',
            code: 'ua'
          },
          company: 'Giampetro, Anthony D',
          date: '2019-08-08',
          status: 'proposal',
          verified: true,
          activity: 85,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 91201
        },
        {
          id: 1034,
          name: 'Alishia Sergi',
          country: {
            name: 'Qatar',
            code: 'qa'
          },
          company: 'Milford Enterprises Inc',
          date: '2018-05-19',
          status: 'negotiation',
          verified: false,
          activity: 46,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 12237
        },
        {
          id: 1035,
          name: 'Solange Shinko',
          country: {
            name: 'Cameroon',
            code: 'cm'
          },
          company: 'Mosocco, Ronald A',
          date: '2015-02-12',
          status: 'qualified',
          verified: true,
          activity: 32,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 34072
        },
        {
          id: 1036,
          name: 'Jose Stockham',
          country: {
            name: 'Italy',
            code: 'it'
          },
          company: 'Tri State Refueler Co',
          date: '2018-04-25',
          status: 'qualified',
          verified: true,
          activity: 77,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 94909
        },
        {
          id: 1037,
          name: 'Rozella Ostrosky',
          country: {
            name: 'Venezuela',
            code: 've'
          },
          company: 'Parkway Company',
          date: '2016-02-27',
          status: 'unqualified',
          verified: true,
          activity: 66,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 57245
        },
        {
          id: 1038,
          name: 'Valentine Gillian',
          country: {
            name: 'Paraguay',
            code: 'py'
          },
          company: 'Fbs Business Finance',
          date: '2019-09-17',
          status: 'qualified',
          verified: true,
          activity: 25,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 75502
        },
        {
          id: 1039,
          name: 'Kati Rulapaugh',
          country: {
            name: 'Puerto Rico',
            code: 'pr'
          },
          company: 'Eder Assocs Consltng Engrs Pc',
          date: '2016-12-03',
          status: 'renewal',
          verified: false,
          activity: 51,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 82075
        },
        {
          id: 1040,
          name: 'Youlanda Schemmer',
          country: {
            name: 'Bolivia',
            code: 'bo'
          },
          company: 'Tri M Tool Inc',
          date: '2017-12-15',
          status: 'negotiation',
          verified: true,
          activity: 49,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 19208
        },
        {
          id: 1041,
          name: 'Dyan Oldroyd',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'International Eyelets Inc',
          date: '2017-02-02',
          status: 'qualified',
          verified: false,
          activity: 5,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 50194
        },
        {
          id: 1042,
          name: 'Roxane Campain',
          country: {
            name: 'France',
            code: 'fr'
          },
          company: 'Rapid Trading Intl',
          date: '2018-12-25',
          status: 'unqualified',
          verified: false,
          activity: 100,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 77714
        },
        {
          id: 1043,
          name: 'Lavera Perin',
          country: {
            name: 'Vietnam',
            code: 'vn'
          },
          company: 'Abc Enterprises Inc',
          date: '2018-04-10',
          status: 'qualified',
          verified: false,
          activity: 71,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 35740
        },
        {
          id: 1044,
          name: 'Erick Ferencz',
          country: {
            name: 'Belgium',
            code: 'be'
          },
          company: 'Cindy Turner Associates',
          date: '2018-05-06',
          status: 'unqualified',
          verified: true,
          activity: 54,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 30790
        },
        {
          id: 1045,
          name: 'Fatima Saylors',
          country: {
            name: 'Canada',
            code: 'ca'
          },
          company: 'Stanton, James D Esq',
          date: '2019-07-10',
          status: 'renewal',
          verified: true,
          activity: 93,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 52343
        },
        {
          id: 1046,
          name: 'Jina Briddick',
          country: {
            name: 'Mexico',
            code: 'mx'
          },
          company: 'Grace Pastries Inc',
          date: '2018-02-19',
          status: 'unqualified',
          verified: false,
          activity: 97,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 53966
        },
        {
          id: 1047,
          name: 'Kanisha Waycott',
          country: {
            name: 'Ecuador',
            code: 'ec'
          },
          company: 'Schroer, Gene E Esq',
          date: '2019-11-27',
          status: 'new',
          verified: false,
          activity: 80,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 9920
        },
        {
          id: 1048,
          name: 'Emerson Bowley',
          country: {
            name: 'Finland',
            code: 'fi'
          },
          company: 'Knights Inn',
          date: '2018-11-24',
          status: 'new',
          verified: false,
          activity: 63,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 78069
        },
        {
          id: 1049,
          name: 'Blair Malet',
          country: {
            name: 'Finland',
            code: 'fi'
          },
          company: 'Bollinger Mach Shp & Shipyard',
          date: '2018-04-19',
          status: 'new',
          verified: true,
          activity: 92,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 65005
        },
        {
          id: 1050,
          name: 'Brock Bolognia',
          country: {
            name: 'Bolivia',
            code: 'bo'
          },
          company: 'Orinda News',
          date: '2019-09-06',
          status: 'renewal',
          verified: true,
          activity: 72,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 51038
        },
        {
          id: 1051,
          name: 'Lorrie Nestle',
          country: {
            name: 'Germany',
            code: 'de'
          },
          company: 'Ballard Spahr Andrews',
          date: '2018-04-26',
          status: 'renewal',
          verified: false,
          activity: 36,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 28218
        },
        {
          id: 1052,
          name: 'Sabra Uyetake',
          country: {
            name: 'Peru',
            code: 'pe'
          },
          company: 'Lowy Limousine Service',
          date: '2018-04-12',
          status: 'new',
          verified: false,
          activity: 31,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 78527
        },
        {
          id: 1053,
          name: 'Marjory Mastella',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'Vicon Corporation',
          date: '2018-01-24',
          status: 'negotiation',
          verified: false,
          activity: 89,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 23381
        },
        {
          id: 1054,
          name: 'Karl Klonowski',
          country: {
            name: 'Saudi Arabia',
            code: 'sa'
          },
          company: 'Rossi, Michael M',
          date: '2017-04-17',
          status: 'unqualified',
          verified: true,
          activity: 27,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 64821
        },
        {
          id: 1055,
          name: 'Tonette Wenner',
          country: {
            name: 'Australia',
            code: 'au'
          },
          company: 'Northwest Publishing',
          date: '2019-04-14',
          status: 'qualified',
          verified: false,
          activity: 27,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 55334
        },
        {
          id: 1056,
          name: 'Amber Monarrez',
          country: {
            name: 'Sweden',
            code: 'se'
          },
          company: 'Branford Wire & Mfg Co',
          date: '2019-09-09',
          status: 'new',
          verified: false,
          activity: 79,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 83391
        },
        {
          id: 1057,
          name: 'Shenika Seewald',
          country: {
            name: 'Australia',
            code: 'au'
          },
          company: 'East Coast Marketing',
          date: '2017-02-18',
          status: 'renewal',
          verified: true,
          activity: 39,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 31580
        },
        {
          id: 1058,
          name: 'Delmy Ahle',
          country: {
            name: 'Belgium',
            code: 'be'
          },
          company: 'Wye Technologies Inc',
          date: '2020-10-05',
          status: 'unqualified',
          verified: false,
          activity: 55,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 11723
        },
        {
          id: 1059,
          name: 'Deeanna Juhas',
          country: {
            name: 'Sweden',
            code: 'se'
          },
          company: 'Healy, George W Iv',
          date: '2018-09-28',
          status: 'negotiation',
          verified: false,
          activity: 79,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 8454
        },
        {
          id: 1060,
          name: 'Blondell Pugh',
          country: {
            name: 'Ireland',
            code: 'ie'
          },
          company: 'Alpenlite Inc',
          date: '2016-06-16',
          status: 'renewal',
          verified: false,
          activity: 49,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 99235
        },
        {
          id: 1061,
          name: 'Jamal Vanausdal',
          country: {
            name: 'Morocco',
            code: 'ma'
          },
          company: 'Hubbard, Bruce Esq',
          date: '2017-05-25',
          status: 'proposal',
          verified: true,
          activity: 87,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 15656
        },
        {
          id: 1062,
          name: 'Cecily Hollack',
          country: {
            name: 'Bolivia',
            code: 'bo'
          },
          company: 'Arthur A Oliver & Son Inc',
          date: '2020-05-09',
          status: 'negotiation',
          verified: true,
          activity: 5,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 60586
        },
        {
          id: 1063,
          name: 'Carmelina Lindall',
          country: {
            name: 'Puerto Rico',
            code: 'pr'
          },
          company: 'George Jessop Carter Jewelers',
          date: '2019-09-07',
          status: 'qualified',
          verified: true,
          activity: 77,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 86239
        },
        {
          id: 1064,
          name: 'Maurine Yglesias',
          country: {
            name: 'Taiwan',
            code: 'tw'
          },
          company: 'Schultz, Thomas C Md',
          date: '2015-08-10',
          status: 'renewal',
          verified: false,
          activity: 94,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 15621
        },
        {
          id: 1065,
          name: 'Tawna Buvens',
          country: {
            name: 'Indonesia',
            code: 'id'
          },
          company: 'H H H Enterprises Inc',
          date: '2018-03-20',
          status: 'new',
          verified: false,
          activity: 25,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 77248
        },
        {
          id: 1066,
          name: 'Penney Weight',
          country: {
            name: 'South Africa',
            code: 'za'
          },
          company: 'Hawaiian King Hotel',
          date: '2020-03-03',
          status: 'qualified',
          verified: false,
          activity: 96,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 478
        },
        {
          id: 1067,
          name: 'Elly Morocco',
          country: {
            name: 'Thailand',
            code: 'th'
          },
          company: 'Killion Industries',
          date: '2018-09-18',
          status: 'qualified',
          verified: true,
          activity: 38,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 62505
        },
        {
          id: 1068,
          name: 'Ilene Eroman',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'Robinson, William J Esq',
          date: '2019-06-08',
          status: 'new',
          verified: true,
          activity: 49,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 91480
        },
        {
          id: 1069,
          name: 'Vallie Mondella',
          country: {
            name: 'Latvia',
            code: 'lv'
          },
          company: 'Private Properties',
          date: '2018-12-06',
          status: 'new',
          verified: false,
          activity: 16,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 21671
        },
        {
          id: 1070,
          name: 'Kallie Blackwood',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Rowley Schlimgen Inc',
          date: '2017-04-05',
          status: 'unqualified',
          verified: false,
          activity: 25,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 13775
        },
        {
          id: 1071,
          name: 'Johnetta Abdallah',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'Forging Specialties',
          date: '2015-02-02',
          status: 'new',
          verified: false,
          activity: 16,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 60253
        },
        {
          id: 1072,
          name: 'Bobbye Rhym',
          country: {
            name: 'Ukraine',
            code: 'ua'
          },
          company: 'Smits, Patricia Garity',
          date: '2018-08-17',
          status: 'qualified',
          verified: true,
          activity: 85,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 75225
        },
        {
          id: 1073,
          name: 'Micaela Rhymes',
          country: {
            name: 'France',
            code: 'fr'
          },
          company: 'H Lee Leonard Attorney At Law',
          date: '2018-09-08',
          status: 'renewal',
          verified: true,
          activity: 92,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 3308
        },
        {
          id: 1074,
          name: 'Tamar Hoogland',
          country: {
            name: 'Guatemala',
            code: 'gt'
          },
          company: 'A K Construction Co',
          date: '2018-11-13',
          status: 'proposal',
          verified: true,
          activity: 22,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 19711
        },
        {
          id: 1075,
          name: 'Moon Parlato',
          country: {
            name: 'Czech Republic',
            code: 'cz'
          },
          company: 'Ambelang, Jessica M Md',
          date: '2019-08-18',
          status: 'renewal',
          verified: false,
          activity: 64,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 55110
        },
        {
          id: 1076,
          name: 'Laurel Reitler',
          country: {
            name: 'United Kingdom',
            code: 'gb'
          },
          company: 'Q A Service',
          date: '2015-04-02',
          status: 'negotiation',
          verified: false,
          activity: 80,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 62392
        },
        {
          id: 1077,
          name: 'Delisa Crupi',
          country: {
            name: 'Taiwan',
            code: 'tw'
          },
          company: 'Wood & Whitacre Contractors',
          date: '2017-09-15',
          status: 'unqualified',
          verified: false,
          activity: 70,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 76530
        },
        {
          id: 1078,
          name: 'Viva Toelkes',
          country: {
            name: 'United States',
            code: 'us'
          },
          company: 'Mark Iv Press Ltd',
          date: '2017-03-27',
          status: 'qualified',
          verified: false,
          activity: 16,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 7460
        },
        {
          id: 1079,
          name: 'Elza Lipke',
          country: {
            name: 'Ireland',
            code: 'ie'
          },
          company: 'Museum Of Science & Industry',
          date: '2017-06-01',
          status: 'proposal',
          verified: true,
          activity: 90,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 42251
        },
        {
          id: 1080,
          name: 'Devorah Chickering',
          country: {
            name: 'Spain',
            code: 'es'
          },
          company: 'Garrison Ind',
          date: '2017-03-14',
          status: 'proposal',
          verified: true,
          activity: 96,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 36435
        },
        {
          id: 1081,
          name: 'Timothy Mulqueen',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'Saronix Nymph Products',
          date: '2018-07-09',
          status: 'renewal',
          verified: true,
          activity: 77,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 39197
        },
        {
          id: 1082,
          name: 'Arlette Honeywell',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Smc Inc',
          date: '2018-09-11',
          status: 'proposal',
          verified: true,
          activity: 46,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 72707
        },
        {
          id: 1083,
          name: 'Dominque Dickerson',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'E A I Electronic Assocs Inc',
          date: '2017-11-12',
          status: 'qualified',
          verified: true,
          activity: 83,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 97965
        },
        {
          id: 1084,
          name: 'Lettie Isenhower',
          country: {
            name: 'Canada',
            code: 'ca'
          },
          company: 'Conte, Christopher A Esq',
          date: '2016-03-01',
          status: 'qualified',
          verified: true,
          activity: 83,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 5823
        },
        {
          id: 1085,
          name: 'Myra Munns',
          country: {
            name: 'Lithuania',
            code: 'lt'
          },
          company: 'Anker Law Office',
          date: '2016-05-21',
          status: 'unqualified',
          verified: true,
          activity: 49,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 96471
        },
        {
          id: 1086,
          name: 'Stephaine Barfield',
          country: {
            name: 'Belgium',
            code: 'be'
          },
          company: 'Beutelschies & Company',
          date: '2016-01-22',
          status: 'new',
          verified: true,
          activity: 34,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 33710
        },
        {
          id: 1087,
          name: 'Lai Gato',
          country: {
            name: 'Nigeria',
            code: 'ng'
          },
          company: 'Fligg, Kenneth I Jr',
          date: '2016-07-26',
          status: 'unqualified',
          verified: false,
          activity: 64,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 30611
        },
        {
          id: 1088,
          name: 'Stephen Emigh',
          country: {
            name: 'Cuba',
            code: 'cu'
          },
          company: 'Sharp, J Daniel Esq',
          date: '2020-07-24',
          status: 'renewal',
          verified: false,
          activity: 51,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 32960
        },
        {
          id: 1089,
          name: 'Tyra Shields',
          country: {
            name: 'Honduras',
            code: 'hn'
          },
          company: 'Assink, Anne H Esq',
          date: '2019-11-10',
          status: 'negotiation',
          verified: false,
          activity: 11,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 57423
        },
        {
          id: 1090,
          name: 'Tammara Wardrip',
          country: {
            name: 'Saudi Arabia',
            code: 'sa'
          },
          company: 'Jewel My Shop Inc',
          date: '2016-06-05',
          status: 'renewal',
          verified: true,
          activity: 64,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 23027
        },
        {
          id: 1091,
          name: 'Cory Gibes',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'Chinese Translation Resources',
          date: '2016-02-28',
          status: 'new',
          verified: false,
          activity: 44,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 84182
        },
        {
          id: 1092,
          name: 'Danica Bruschke',
          country: {
            name: 'Taiwan',
            code: 'tw'
          },
          company: 'Stevens, Charles T',
          date: '2018-12-13',
          status: 'unqualified',
          verified: true,
          activity: 62,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 25237
        },
        {
          id: 1093,
          name: 'Wilda Giguere',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Mclaughlin, Luther W Cpa',
          date: '2017-06-16',
          status: 'new',
          verified: true,
          activity: 79,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 87736
        },
        {
          id: 1094,
          name: 'Elvera Benimadho',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'Tree Musketeers',
          date: '2019-02-17',
          status: 'proposal',
          verified: true,
          activity: 50,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 38674
        },
        {
          id: 1095,
          name: 'Carma Vanheusen',
          country: {
            name: 'Turkey',
            code: 'tr'
          },
          company: 'Springfield Div Oh Edison Co',
          date: '2019-11-26',
          status: 'renewal',
          verified: false,
          activity: 84,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 67762
        },
        {
          id: 1096,
          name: 'Malinda Hochard',
          country: {
            name: 'Serbia',
            code: 'rs'
          },
          company: 'Logan Memorial Hospital',
          date: '2016-07-06',
          status: 'new',
          verified: false,
          activity: 88,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 81299
        },
        {
          id: 1097,
          name: 'Natalie Fern',
          country: {
            name: 'Canada',
            code: 'ca'
          },
          company: 'Kelly, Charles G Esq',
          date: '2019-10-02',
          status: 'proposal',
          verified: true,
          activity: 44,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 64794
        },
        {
          id: 1098,
          name: 'Lisha Centini',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'Industrial Paper Shredders Inc',
          date: '2018-07-05',
          status: 'new',
          verified: true,
          activity: 7,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 7815
        },
        {
          id: 1099,
          name: 'Arlene Klusman',
          country: {
            name: 'Jamaica',
            code: 'jm'
          },
          company: 'Beck Horizon Builders',
          date: '2018-05-14',
          status: 'proposal',
          verified: true,
          activity: 99,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 37976
        },
        {
          id: 1100,
          name: 'Alease Buemi',
          country: {
            name: 'Costa Rica',
            code: 'cr'
          },
          company: 'Porto Cayo At Hawks Cay',
          date: '2018-03-14',
          status: 'unqualified',
          verified: true,
          activity: 0,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 59594
        },
        {
          id: 1101,
          name: 'Louisa Cronauer',
          country: {
            name: 'Costa Rica',
            code: 'cr'
          },
          company: 'Pacific Grove Museum Ntrl Hist',
          date: '2018-09-23',
          status: 'qualified',
          verified: false,
          activity: 3,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 92528
        },
        {
          id: 1102,
          name: 'Angella Cetta',
          country: {
            name: 'Vietnam',
            code: 'vn'
          },
          company: 'Bender & Hatley Pc',
          date: '2018-04-10',
          status: 'qualified',
          verified: false,
          activity: 88,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 58964
        },
        {
          id: 1103,
          name: 'Cyndy Goldammer',
          country: {
            name: 'Burkina Faso',
            code: 'bf'
          },
          company: 'Di Cristina J & Son',
          date: '2017-09-18',
          status: 'unqualified',
          verified: false,
          activity: 92,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 65860
        },
        {
          id: 1104,
          name: 'Rosio Cork',
          country: {
            name: 'Singapore',
            code: 'sg'
          },
          company: 'Green Goddess',
          date: '2017-08-19',
          status: 'negotiation',
          verified: true,
          activity: 19,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 63863
        },
        {
          id: 1105,
          name: 'Celeste Korando',
          country: {
            name: 'Costa Rica',
            code: 'cr'
          },
          company: 'American Arts & Graphics',
          date: '2020-06-18',
          status: 'proposal',
          verified: true,
          activity: 21,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 37510
        },
        {
          id: 1106,
          name: 'Twana Felger',
          country: {
            name: 'Croatia',
            code: 'hr'
          },
          company: 'Opryland Hotel',
          date: '2016-11-18',
          status: 'negotiation',
          verified: false,
          activity: 97,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 63876
        },
        {
          id: 1107,
          name: 'Estrella Samu',
          country: {
            name: 'Vietnam',
            code: 'vn'
          },
          company: 'Marking Devices Pubg Co',
          date: '2017-06-25',
          status: 'unqualified',
          verified: false,
          activity: 27,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 93263
        },
        {
          id: 1108,
          name: 'Donte Kines',
          country: {
            name: 'Slovakia',
            code: 'sk'
          },
          company: 'W Tc Industries Inc',
          date: '2019-02-16',
          status: 'new',
          verified: true,
          activity: 35,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 57198
        },
        {
          id: 1109,
          name: 'Tiffiny Steffensmeier',
          country: {
            name: 'Pakistan',
            code: 'pk'
          },
          company: 'Whitehall Robbins Labs Divsn',
          date: '2018-03-11',
          status: 'new',
          verified: true,
          activity: 81,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 89147
        },
        {
          id: 1110,
          name: 'Edna Miceli',
          country: {
            name: 'France',
            code: 'fr'
          },
          company: 'Sampler',
          date: '2017-10-15',
          status: 'renewal',
          verified: true,
          activity: 54,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 41466
        },
        {
          id: 1111,
          name: 'Sue Kownacki',
          country: {
            name: 'Jamaica',
            code: 'jm'
          },
          company: 'Juno Chefs Incorporated',
          date: '2017-03-17',
          status: 'proposal',
          verified: false,
          activity: 31,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 38918
        },
        {
          id: 1112,
          name: 'Jesusa Shin',
          country: {
            name: 'Ukraine',
            code: 'ua'
          },
          company: 'Carroccio, A Thomas Esq',
          date: '2017-04-06',
          status: 'renewal',
          verified: false,
          activity: 28,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 11397
        },
        {
          id: 1113,
          name: 'Rolland Francescon',
          country: {
            name: 'United Kingdom',
            code: 'gb'
          },
          company: 'Stanley, Richard L Esq',
          date: '2019-02-03',
          status: 'qualified',
          verified: false,
          activity: 45,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 40930
        },
        {
          id: 1114,
          name: 'Pamella Schmierer',
          country: {
            name: 'Belgium',
            code: 'be'
          },
          company: 'K Cs Cstm Mouldings Windows',
          date: '2016-09-22',
          status: 'unqualified',
          verified: true,
          activity: 34,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 40847
        },
        {
          id: 1115,
          name: 'Glory Kulzer',
          country: {
            name: 'Croatia',
            code: 'hr'
          },
          company: 'Comfort Inn',
          date: '2017-09-27',
          status: 'unqualified',
          verified: true,
          activity: 36,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 27832
        },
        {
          id: 1116,
          name: 'Shawna Palaspas',
          country: {
            name: 'Estonia',
            code: 'ee'
          },
          company: 'Windsor, James L Esq',
          date: '2017-06-25',
          status: 'unqualified',
          verified: true,
          activity: 69,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 89060
        },
        {
          id: 1117,
          name: 'Brandon Callaro',
          country: {
            name: 'Romania',
            code: 'ro'
          },
          company: 'Jackson Shields Yeiser',
          date: '2016-07-13',
          status: 'proposal',
          verified: true,
          activity: 55,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 52474
        },
        {
          id: 1118,
          name: 'Scarlet Cartan',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Box, J Calvin Esq',
          date: '2018-09-13',
          status: 'renewal',
          verified: false,
          activity: 1,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 19094
        },
        {
          id: 1119,
          name: 'Oretha Menter',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Custom Engineering Inc',
          date: '2017-09-11',
          status: 'renewal',
          verified: false,
          activity: 8,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 93756
        },
        {
          id: 1120,
          name: 'Ty Smith',
          country: {
            name: 'United States',
            code: 'us'
          },
          company: 'Bresler Eitel Framg Gllry Ltd',
          date: '2019-07-06',
          status: 'unqualified',
          verified: false,
          activity: 50,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 77388
        },
        {
          id: 1121,
          name: 'Xuan Rochin',
          country: {
            name: 'Colombia',
            code: 'co'
          },
          company: 'Carol, Drake Sparks Esq',
          date: '2018-05-22',
          status: 'proposal',
          verified: true,
          activity: 77,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 48759
        },
        {
          id: 1122,
          name: 'Lindsey Dilello',
          country: {
            name: 'Austria',
            code: 'at'
          },
          company: 'Biltmore Investors Bank',
          date: '2017-07-18',
          status: 'renewal',
          verified: true,
          activity: 65,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 37568
        },
        {
          id: 1123,
          name: 'Devora Perez',
          country: {
            name: 'Uruguay',
            code: 'uy'
          },
          company: 'Desco Equipment Corp',
          date: '2017-10-09',
          status: 'unqualified',
          verified: true,
          activity: 30,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 4477
        },
        {
          id: 1124,
          name: 'Herman Demesa',
          country: {
            name: 'Paraguay',
            code: 'py'
          },
          company: 'Merlin Electric Co',
          date: '2019-05-23',
          status: 'proposal',
          verified: true,
          activity: 10,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 13764
        },
        {
          id: 1125,
          name: 'Rory Papasergi',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Bailey Cntl Co Div Babcock',
          date: '2019-03-02',
          status: 'qualified',
          verified: false,
          activity: 22,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 68222
        },
        {
          id: 1126,
          name: 'Talia Riopelle',
          country: {
            name: 'Guatemala',
            code: 'gt'
          },
          company: 'Ford Brothers Wholesale Inc',
          date: '2017-02-18',
          status: 'new',
          verified: false,
          activity: 69,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 29164
        },
        {
          id: 1127,
          name: 'Van Shire',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'Cambridge Inn',
          date: '2020-05-12',
          status: 'new',
          verified: false,
          activity: 4,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 61651
        },
        {
          id: 1128,
          name: 'Lucina Lary',
          country: {
            name: 'Switzerland',
            code: 'ch'
          },
          company: 'Matricciani, Albert J Jr',
          date: '2019-11-20',
          status: 'negotiation',
          verified: true,
          activity: 11,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 79938
        },
        {
          id: 1129,
          name: 'Bok Isaacs',
          country: {
            name: 'Chile',
            code: 'cl'
          },
          company: 'Nelson Hawaiian Ltd',
          date: '2016-11-10',
          status: 'proposal',
          verified: true,
          activity: 41,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 44037
        },
        {
          id: 1130,
          name: 'Rolande Spickerman',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Neland Travel Agency',
          date: '2016-07-11',
          status: 'renewal',
          verified: true,
          activity: 84,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 89918
        },
        {
          id: 1131,
          name: 'Howard Paulas',
          country: {
            name: 'Indonesia',
            code: 'id'
          },
          company: 'Asendorf, J Alan Esq',
          date: '2017-07-17',
          status: 'negotiation',
          verified: false,
          activity: 22,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 32372
        },
        {
          id: 1132,
          name: 'Kimbery Madarang',
          country: {
            name: 'Senegal',
            code: 'sn'
          },
          company: 'Silberman, Arthur L Esq',
          date: '2018-08-19',
          status: 'negotiation',
          verified: true,
          activity: 63,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 46478
        },
        {
          id: 1133,
          name: 'Thurman Manno',
          country: {
            name: 'Colombia',
            code: 'co'
          },
          company: 'Honey Bee Breeding Genetics &',
          date: '2016-05-02',
          status: 'qualified',
          verified: true,
          activity: 47,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 30674
        },
        {
          id: 1134,
          name: 'Becky Mirafuentes',
          country: {
            name: 'Serbia',
            code: 'rs'
          },
          company: 'Wells Kravitz Schnitzer',
          date: '2018-04-13',
          status: 'unqualified',
          verified: true,
          activity: 62,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 47714
        },
        {
          id: 1135,
          name: 'Beatriz Corrington',
          country: {
            name: 'South Africa',
            code: 'za'
          },
          company: 'Prohab Rehabilitation Servs',
          date: '2020-01-04',
          status: 'renewal',
          verified: true,
          activity: 55,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 14307
        },
        {
          id: 1136,
          name: 'Marti Maybury',
          country: {
            name: 'Thailand',
            code: 'th'
          },
          company: 'Eldridge, Kristin K Esq',
          date: '2016-02-05',
          status: 'unqualified',
          verified: false,
          activity: 3,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 82069
        },
        {
          id: 1137,
          name: 'Nieves Gotter',
          country: {
            name: 'Latvia',
            code: 'lv'
          },
          company: 'Vlahos, John J Esq',
          date: '2017-03-12',
          status: 'proposal',
          verified: false,
          activity: 3,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 11182
        },
        {
          id: 1138,
          name: 'Leatha Hagele',
          country: {
            name: 'Ukraine',
            code: 'ua'
          },
          company: 'Ninas Indian Grs & Videos',
          date: '2019-03-27',
          status: 'unqualified',
          verified: false,
          activity: 67,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 17126
        },
        {
          id: 1139,
          name: 'Valentin Klimek',
          country: {
            name: 'Ivory Coast',
            code: 'ci'
          },
          company: 'Schmid, Gayanne K Esq',
          date: '2019-08-06',
          status: 'unqualified',
          verified: true,
          activity: 14,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 19724
        },
        {
          id: 1140,
          name: 'Melissa Wiklund',
          country: {
            name: 'Japan',
            code: 'jp'
          },
          company: 'Moapa Valley Federal Credit Un',
          date: '2018-03-20',
          status: 'qualified',
          verified: true,
          activity: 8,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 91888
        },
        {
          id: 1141,
          name: 'Sheridan Zane',
          country: {
            name: 'Croatia',
            code: 'hr'
          },
          company: 'Kentucky Tennessee Clay Co',
          date: '2016-02-15',
          status: 'qualified',
          verified: true,
          activity: 17,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 15016
        },
        {
          id: 1142,
          name: 'Bulah Padilla',
          country: {
            name: 'Philippines',
            code: 'ph'
          },
          company: 'Admiral Party Rentals & Sales',
          date: '2016-02-10',
          status: 'proposal',
          verified: false,
          activity: 58,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 23118
        },
        {
          id: 1143,
          name: 'Audra Kohnert',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'Nelson, Karolyn King Esq',
          date: '2019-07-16',
          status: 'unqualified',
          verified: false,
          activity: 82,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 90560
        },
        {
          id: 1144,
          name: 'Daren Weirather',
          country: {
            name: 'Israel',
            code: 'il'
          },
          company: 'Panasystems',
          date: '2015-07-23',
          status: 'negotiation',
          verified: false,
          activity: 96,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 34155
        },
        {
          id: 1145,
          name: 'Fernanda Jillson',
          country: {
            name: 'Mexico',
            code: 'mx'
          },
          company: 'Shank, Edward L Esq',
          date: '2017-07-02',
          status: 'unqualified',
          verified: true,
          activity: 92,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 6350
        },
        {
          id: 1146,
          name: 'Gearldine Gellinger',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Megibow & Edwards',
          date: '2019-08-17',
          status: 'proposal',
          verified: true,
          activity: 18,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 77641
        },
        {
          id: 1147,
          name: 'Chau Kitzman',
          country: {
            name: 'Paraguay',
            code: 'py'
          },
          company: 'Benoff, Edward Esq',
          date: '2019-07-04',
          status: 'new',
          verified: true,
          activity: 9,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 43289
        },
        {
          id: 1148,
          name: 'Theola Frey',
          country: {
            name: 'Vietnam',
            code: 'vn'
          },
          company: 'Woodbridge Free Public Library',
          date: '2020-03-14',
          status: 'unqualified',
          verified: true,
          activity: 44,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 85657
        },
        {
          id: 1149,
          name: 'Cheryl Haroldson',
          country: {
            name: 'France',
            code: 'fr'
          },
          company: 'New York Life John Thune',
          date: '2018-04-03',
          status: 'new',
          verified: false,
          activity: 55,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 82733
        },
        {
          id: 1150,
          name: 'Laticia Merced',
          country: {
            name: 'Burkina Faso',
            code: 'bf'
          },
          company: 'Alinabal Inc',
          date: '2017-03-04',
          status: 'unqualified',
          verified: false,
          activity: 21,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 38004
        },
        {
          id: 1151,
          name: 'Carissa Batman',
          country: {
            name: 'Greece',
            code: 'gr'
          },
          company: 'Poletto, Kim David Esq',
          date: '2016-05-05',
          status: 'negotiation',
          verified: true,
          activity: 91,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 29038
        },
        {
          id: 1152,
          name: 'Lezlie Craghead',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Chang, Carolyn Esq',
          date: '2019-05-28',
          status: 'renewal',
          verified: false,
          activity: 30,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 75123
        },
        {
          id: 1153,
          name: 'Ozell Shealy',
          country: {
            name: 'Pakistan',
            code: 'pk'
          },
          company: 'Silver Bros Inc',
          date: '2016-08-19',
          status: 'proposal',
          verified: true,
          activity: 14,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 33214
        },
        {
          id: 1154,
          name: 'Arminda Parvis',
          country: {
            name: 'Indonesia',
            code: 'id'
          },
          company: 'Newtec Inc',
          date: '2020-02-09',
          status: 'proposal',
          verified: true,
          activity: 77,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 80651
        },
        {
          id: 1155,
          name: 'Reita Leto',
          country: {
            name: 'Belgium',
            code: 'be'
          },
          company: 'Creative Business Systems',
          date: '2020-04-03',
          status: 'unqualified',
          verified: true,
          activity: 58,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 5085
        },
        {
          id: 1156,
          name: 'Yolando Luczki',
          country: {
            name: 'France',
            code: 'fr'
          },
          company: 'Dal Tile Corporation',
          date: '2015-01-27',
          status: 'renewal',
          verified: true,
          activity: 78,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 93021
        },
        {
          id: 1157,
          name: 'Lizette Stem',
          country: {
            name: 'Slovakia',
            code: 'sk'
          },
          company: 'Edward S Katz',
          date: '2018-08-06',
          status: 'new',
          verified: false,
          activity: 67,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 37287
        },
        {
          id: 1158,
          name: 'Gregoria Pawlowicz',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Oh My Goodknits Inc',
          date: '2020-02-20',
          status: 'renewal',
          verified: false,
          activity: 29,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 73070
        },
        {
          id: 1159,
          name: 'Carin Deleo',
          country: {
            name: 'China',
            code: 'cn'
          },
          company: 'Redeker, Debbie',
          date: '2015-05-28',
          status: 'qualified',
          verified: true,
          activity: 13,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 64422
        },
        {
          id: 1160,
          name: 'Chantell Maynerich',
          country: {
            name: 'Estonia',
            code: 'ee'
          },
          company: 'Desert Sands Motel',
          date: '2016-09-05',
          status: 'unqualified',
          verified: true,
          activity: 75,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 36826
        },
        {
          id: 1161,
          name: 'Dierdre Yum',
          country: {
            name: 'Czech Republic',
            code: 'cz'
          },
          company: 'Cummins Southern Plains Inc',
          date: '2016-12-20',
          status: 'negotiation',
          verified: true,
          activity: 1,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 93101
        },
        {
          id: 1162,
          name: 'Larae Gudroe',
          country: {
            name: 'Slovenia',
            code: 'si'
          },
          company: 'Lehigh Furn Divsn Lehigh',
          date: '2015-11-28',
          status: 'unqualified',
          verified: false,
          activity: 13,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 60177
        },
        {
          id: 1163,
          name: 'Latrice Tolfree',
          country: {
            name: 'Jamaica',
            code: 'jm'
          },
          company: 'United Van Lines Agent',
          date: '2018-11-11',
          status: 'renewal',
          verified: false,
          activity: 73,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 47198
        },
        {
          id: 1164,
          name: 'Kerry Theodorov',
          country: {
            name: 'Romania',
            code: 'ro'
          },
          company: 'Capitol Reporters',
          date: '2016-11-05',
          status: 'unqualified',
          verified: true,
          activity: 76,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 71305
        },
        {
          id: 1165,
          name: 'Dorthy Hidvegi',
          country: {
            name: 'Poland',
            code: 'pl'
          },
          company: 'Kwik Kopy Printing',
          date: '2020-08-13',
          status: 'qualified',
          verified: true,
          activity: 60,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 17526
        },
        {
          id: 1166,
          name: 'Fannie Lungren',
          country: {
            name: 'Belarus',
            code: 'by'
          },
          company: 'Centro Inc',
          date: '2015-07-06',
          status: 'negotiation',
          verified: true,
          activity: 24,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 16596
        },
        {
          id: 1167,
          name: 'Evangelina Radde',
          country: {
            name: 'Ivory Coast',
            code: 'ci'
          },
          company: 'Campbell, Jan Esq',
          date: '2020-02-25',
          status: 'unqualified',
          verified: true,
          activity: 93,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 56870
        },
        {
          id: 1168,
          name: 'Novella Degroot',
          country: {
            name: 'Slovenia',
            code: 'si'
          },
          company: 'Evans, C Kelly Esq',
          date: '2017-12-19',
          status: 'unqualified',
          verified: false,
          activity: 30,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 82928
        },
        {
          id: 1169,
          name: 'Clay Hoa',
          country: {
            name: 'Paraguay',
            code: 'py'
          },
          company: 'Scat Enterprises',
          date: '2016-02-22',
          status: 'negotiation',
          verified: false,
          activity: 93,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 64181
        },
        {
          id: 1170,
          name: 'Jennifer Fallick',
          country: {
            name: 'Australia',
            code: 'au'
          },
          company: 'Nagle, Daniel J Esq',
          date: '2016-12-24',
          status: 'unqualified',
          verified: true,
          activity: 88,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 30561
        },
        {
          id: 1171,
          name: 'Irma Wolfgramm',
          country: {
            name: 'Belgium',
            code: 'be'
          },
          company: 'Serendiquity Bed & Breakfast',
          date: '2020-10-18',
          status: 'negotiation',
          verified: true,
          activity: 70,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 24617
        },
        {
          id: 1172,
          name: 'Eun Coody',
          country: {
            name: 'Taiwan',
            code: 'tw'
          },
          company: 'Ray Carolyne Realty',
          date: '2018-02-12',
          status: 'qualified',
          verified: true,
          activity: 61,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 77860
        },
        {
          id: 1173,
          name: 'Sylvia Cousey',
          country: {
            name: 'Ireland',
            code: 'ie'
          },
          company: 'Berg, Charles E',
          date: '2018-06-10',
          status: 'unqualified',
          verified: false,
          activity: 91,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 25664
        },
        {
          id: 1174,
          name: 'Nana Wrinkles',
          country: {
            name: 'Austria',
            code: 'at'
          },
          company: 'Ray, Milbern D',
          date: '2017-04-11',
          status: 'renewal',
          verified: true,
          activity: 98,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 98113
        },
        {
          id: 1175,
          name: 'Layla Springe',
          country: {
            name: 'South Africa',
            code: 'za'
          },
          company: 'Chadds Ford Winery',
          date: '2019-07-27',
          status: 'unqualified',
          verified: true,
          activity: 97,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 14763
        },
        {
          id: 1176,
          name: 'Joesph Degonia',
          country: {
            name: 'Serbia',
            code: 'rs'
          },
          company: 'A R Packaging',
          date: '2020-04-23',
          status: 'renewal',
          verified: true,
          activity: 56,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 31317
        },
        {
          id: 1177,
          name: 'Annabelle Boord',
          country: {
            name: 'Guatemala',
            code: 'gt'
          },
          company: 'Corn Popper',
          date: '2020-09-16',
          status: 'proposal',
          verified: true,
          activity: 76,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 30883
        },
        {
          id: 1178,
          name: 'Stephaine Vinning',
          country: {
            name: 'Australia',
            code: 'au'
          },
          company: 'Birite Foodservice Distr',
          date: '2016-05-14',
          status: 'negotiation',
          verified: true,
          activity: 43,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 93785
        },
        {
          id: 1179,
          name: 'Nelida Sawchuk',
          country: {
            name: 'South Africa',
            code: 'za'
          },
          company: 'Anchorage Museum Of Hist & Art',
          date: '2018-06-22',
          status: 'qualified',
          verified: true,
          activity: 58,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 68380
        },
        {
          id: 1180,
          name: 'Marguerita Hiatt',
          country: {
            name: 'United Kingdom',
            code: 'gb'
          },
          company: 'Haber, George D Md',
          date: '2018-10-25',
          status: 'qualified',
          verified: false,
          activity: 72,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 93454
        },
        {
          id: 1181,
          name: 'Carmela Cookey',
          country: {
            name: 'France',
            code: 'fr'
          },
          company: 'Royal Pontiac Olds Inc',
          date: '2018-07-19',
          status: 'proposal',
          verified: false,
          activity: 24,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 30570
        },
        {
          id: 1182,
          name: 'Junita Brideau',
          country: {
            name: 'Indonesia',
            code: 'id'
          },
          company: 'Leonards Antiques Inc',
          date: '2015-03-15',
          status: 'proposal',
          verified: true,
          activity: 86,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 79506
        },
        {
          id: 1183,
          name: 'Claribel Varriano',
          country: {
            name: 'Ecuador',
            code: 'ec'
          },
          company: 'Meca',
          date: '2017-04-14',
          status: 'unqualified',
          verified: true,
          activity: 15,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 8654
        },
        {
          id: 1184,
          name: 'Benton Skursky',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Nercon Engineering & Mfg Inc',
          date: '2015-02-19',
          status: 'proposal',
          verified: true,
          activity: 9,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 13368
        },
        {
          id: 1185,
          name: 'Hillary Skulski',
          country: {
            name: 'France',
            code: 'fr'
          },
          company: 'Replica I',
          date: '2016-03-25',
          status: 'unqualified',
          verified: true,
          activity: 82,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 92631
        },
        {
          id: 1186,
          name: 'Merilyn Bayless',
          country: {
            name: 'Jamaica',
            code: 'jm'
          },
          company: '20 20 Printing Inc',
          date: '2020-10-13',
          status: 'unqualified',
          verified: true,
          activity: 13,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 4989
        },
        {
          id: 1187,
          name: 'Teri Ennaco',
          country: {
            name: 'Pakistan',
            code: 'pk'
          },
          company: 'Publishers Group West',
          date: '2019-12-21',
          status: 'unqualified',
          verified: true,
          activity: 57,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 77668
        },
        {
          id: 1188,
          name: 'Merlyn Lawler',
          country: {
            name: 'Germany',
            code: 'de'
          },
          company: 'Nischwitz, Jeffrey L Esq',
          date: '2016-02-26',
          status: 'renewal',
          verified: true,
          activity: 45,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 3525
        },
        {
          id: 1189,
          name: 'Georgene Montezuma',
          country: {
            name: 'Senegal',
            code: 'sn'
          },
          company: 'Payne Blades & Wellborn Pa',
          date: '2018-10-11',
          status: 'new',
          verified: true,
          activity: 64,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 45838
        },
        {
          id: 1190,
          name: 'Jettie Mconnell',
          country: {
            name: 'Denmark',
            code: 'dk'
          },
          company: 'Coldwell Bnkr Wright Real Est',
          date: '2015-10-18',
          status: 'negotiation',
          verified: false,
          activity: 74,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 49148
        },
        {
          id: 1191,
          name: 'Lemuel Latzke',
          country: {
            name: 'Colombia',
            code: 'co'
          },
          company: 'Computer Repair Service',
          date: '2016-02-13',
          status: 'proposal',
          verified: false,
          activity: 79,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 96709
        },
        {
          id: 1192,
          name: 'Melodie Knipp',
          country: {
            name: 'Finland',
            code: 'fi'
          },
          company: 'Fleetwood Building Block Inc',
          date: '2018-03-08',
          status: 'negotiation',
          verified: false,
          activity: 19,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 23253
        },
        {
          id: 1193,
          name: 'Candida Corbley',
          country: {
            name: 'Poland',
            code: 'pl'
          },
          company: 'Colts Neck Medical Assocs Inc',
          date: '2017-12-02',
          status: 'negotiation',
          verified: true,
          activity: 11,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 40836
        },
        {
          id: 1194,
          name: 'Karan Karpin',
          country: {
            name: 'Estonia',
            code: 'ee'
          },
          company: 'New England Taxidermy',
          date: '2019-01-07',
          status: 'proposal',
          verified: true,
          activity: 4,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 60719
        },
        {
          id: 1195,
          name: 'Andra Scheyer',
          country: {
            name: 'Romania',
            code: 'ro'
          },
          company: 'Ludcke, George O Esq',
          date: '2016-08-14',
          status: 'qualified',
          verified: true,
          activity: 62,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 17419
        },
        {
          id: 1196,
          name: 'Felicidad Poullion',
          country: {
            name: 'Greece',
            code: 'gr'
          },
          company: 'Mccorkle, Tom S Esq',
          date: '2016-03-05',
          status: 'renewal',
          verified: true,
          activity: 64,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 94052
        },
        {
          id: 1197,
          name: 'Belen Strassner',
          country: {
            name: 'Ivory Coast',
            code: 'ci'
          },
          company: 'Eagle Software Inc',
          date: '2015-12-14',
          status: 'qualified',
          verified: true,
          activity: 91,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 54241
        },
        {
          id: 1198,
          name: 'Gracia Melnyk',
          country: {
            name: 'Costa Rica',
            code: 'cr'
          },
          company: 'Juvenile & Adult Super',
          date: '2019-06-01',
          status: 'unqualified',
          verified: true,
          activity: 40,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 87668
        },
        {
          id: 1199,
          name: 'Jolanda Hanafan',
          country: {
            name: 'Cameroon',
            code: 'cm'
          },
          company: 'Perez, Joseph J Esq',
          date: '2015-12-09',
          status: 'qualified',
          verified: true,
          activity: 27,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 99417
        },
        {
          id: 1200,
          name: 'Barrett Toyama',
          country: {
            name: 'Thailand',
            code: 'th'
          },
          company: 'Case Foundation Co',
          date: '2019-08-16',
          status: 'new',
          verified: true,
          activity: 82,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 89792
        },
        {
          id: 1201,
          name: 'Helga Fredicks',
          country: {
            name: 'Guatemala',
            code: 'gt'
          },
          company: 'Eis Environmental Engrs Inc',
          date: '2018-06-20',
          status: 'new',
          verified: true,
          activity: 33,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 42356
        },
        {
          id: 1202,
          name: 'Ashlyn Pinilla',
          country: {
            name: 'Japan',
            code: 'jp'
          },
          company: 'Art Crafters',
          date: '2018-04-11',
          status: 'unqualified',
          verified: true,
          activity: 5,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 90979
        },
        {
          id: 1203,
          name: 'Fausto Agramonte',
          country: {
            name: 'Belgium',
            code: 'be'
          },
          company: 'Marriott Hotels Resorts Suites',
          date: '2019-06-25',
          status: 'new',
          verified: false,
          activity: 22,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 45082
        },
        {
          id: 1204,
          name: 'Ronny Caiafa',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'Remaco Inc',
          date: '2020-07-21',
          status: 'new',
          verified: true,
          activity: 61,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 9871
        },
        {
          id: 1205,
          name: 'Marge Limmel',
          country: {
            name: 'Cameroon',
            code: 'cm'
          },
          company: 'Bjork, Robert D Jr',
          date: '2015-03-12',
          status: 'unqualified',
          verified: true,
          activity: 90,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 47043
        },
        {
          id: 1206,
          name: 'Norah Waymire',
          country: {
            name: 'United States',
            code: 'us'
          },
          company: 'Carmichael, Jeffery L Esq',
          date: '2019-06-11',
          status: 'renewal',
          verified: true,
          activity: 48,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 92763
        },
        {
          id: 1207,
          name: 'Aliza Baltimore',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'Andrews, J Robert Esq',
          date: '2020-11-25',
          status: 'unqualified',
          verified: true,
          activity: 59,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 18075
        },
        {
          id: 1208,
          name: 'Mozell Pelkowski',
          country: {
            name: 'Latvia',
            code: 'lv'
          },
          company: 'Winship & Byrne',
          date: '2018-04-13',
          status: 'unqualified',
          verified: true,
          activity: 94,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 34393
        },
        {
          id: 1209,
          name: 'Viola Bitsuie',
          country: {
            name: 'Denmark',
            code: 'dk'
          },
          company: 'Burton & Davis',
          date: '2018-07-07',
          status: 'new',
          verified: true,
          activity: 94,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 16410
        },
        {
          id: 1210,
          name: 'Franklyn Emard',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Olympic Graphic Arts',
          date: '2018-02-25',
          status: 'qualified',
          verified: true,
          activity: 95,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 1017
        },
        {
          id: 1211,
          name: 'Willodean Konopacki',
          country: {
            name: 'Hong Kong',
            code: 'hk'
          },
          company: 'Magnuson',
          date: '2017-10-03',
          status: 'renewal',
          verified: false,
          activity: 20,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 77843
        },
        {
          id: 1212,
          name: 'Beckie Silvestrini',
          country: {
            name: 'Poland',
            code: 'pl'
          },
          company: 'A All American Travel Inc',
          date: '2016-11-26',
          status: 'renewal',
          verified: true,
          activity: 81,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 30549
        },
        {
          id: 1213,
          name: 'Rebecka Gesick',
          country: {
            name: 'Philippines',
            code: 'ph'
          },
          company: 'Polykote Inc',
          date: '2019-06-22',
          status: 'renewal',
          verified: true,
          activity: 45,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 51738
        },
        {
          id: 1214,
          name: 'Frederica Blunk',
          country: {
            name: 'Lithuania',
            code: 'lt'
          },
          company: 'Jets Cybernetics',
          date: '2019-06-07',
          status: 'renewal',
          verified: true,
          activity: 56,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 13111
        },
        {
          id: 1215,
          name: 'Glen Bartolet',
          country: {
            name: 'Germany',
            code: 'de'
          },
          company: 'Metlab Testing Services',
          date: '2015-02-13',
          status: 'proposal',
          verified: true,
          activity: 99,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 85802
        },
        {
          id: 1216,
          name: 'Freeman Gochal',
          country: {
            name: 'Slovenia',
            code: 'si'
          },
          company: 'Kellermann, William T Esq',
          date: '2019-08-27',
          status: 'negotiation',
          verified: false,
          activity: 2,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 89221
        },
        {
          id: 1217,
          name: 'Vincent Meinerding',
          country: {
            name: 'United States',
            code: 'us'
          },
          company: 'Arturi, Peter D Esq',
          date: '2018-06-18',
          status: 'new',
          verified: true,
          activity: 58,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 32661
        },
        {
          id: 1218,
          name: 'Rima Bevelacqua',
          country: {
            name: 'Taiwan',
            code: 'tw'
          },
          company: 'Mcauley Mfg Co',
          date: '2019-05-18',
          status: 'new',
          verified: true,
          activity: 31,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 18809
        },
        {
          id: 1219,
          name: 'Glendora Sarbacher',
          country: {
            name: 'Lithuania',
            code: 'lt'
          },
          company: 'Defur Voran Hanley Radcliff',
          date: '2018-04-21',
          status: 'new',
          verified: true,
          activity: 79,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 94374
        },
        {
          id: 1220,
          name: 'Avery Steier',
          country: {
            name: 'Mexico',
            code: 'mx'
          },
          company: 'Dill Dill Carr & Stonbraker Pc',
          date: '2017-07-27',
          status: 'proposal',
          verified: true,
          activity: 63,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 45436
        },
        {
          id: 1221,
          name: 'Cristy Lother',
          country: {
            name: 'Burkina Faso',
            code: 'bf'
          },
          company: 'Kleensteel',
          date: '2018-05-19',
          status: 'renewal',
          verified: true,
          activity: 55,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 72645
        },
        {
          id: 1222,
          name: 'Nicolette Brossart',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Goulds Pumps Inc Slurry Pump',
          date: '2015-05-26',
          status: 'new',
          verified: false,
          activity: 25,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 61023
        },
        {
          id: 1223,
          name: 'Tracey Modzelewski',
          country: {
            name: 'Denmark',
            code: 'dk'
          },
          company: 'Kansas City Insurance Report',
          date: '2019-07-02',
          status: 'renewal',
          verified: true,
          activity: 4,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 13685
        },
        {
          id: 1224,
          name: 'Virgina Tegarden',
          country: {
            name: 'Cameroon',
            code: 'cm'
          },
          company: 'Berhanu International Foods',
          date: '2017-10-09',
          status: 'qualified',
          verified: true,
          activity: 62,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 84436
        },
        {
          id: 1225,
          name: 'Tiera Frankel',
          country: {
            name: 'India',
            code: 'in'
          },
          company: 'Roland Ashcroft',
          date: '2018-03-27',
          status: 'unqualified',
          verified: true,
          activity: 90,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 61860
        },
        {
          id: 1226,
          name: 'Alaine Bergesen',
          country: {
            name: 'Peru',
            code: 'pe'
          },
          company: 'Hispanic Magazine',
          date: '2017-08-10',
          status: 'qualified',
          verified: true,
          activity: 34,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 76679
        },
        {
          id: 1227,
          name: 'Earleen Mai',
          country: {
            name: 'Italy',
            code: 'it'
          },
          company: 'Little Sheet Metal Co',
          date: '2016-06-22',
          status: 'proposal',
          verified: true,
          activity: 38,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 14959
        },
        {
          id: 1228,
          name: 'Leonida Gobern',
          country: {
            name: 'Cuba',
            code: 'cu'
          },
          company: 'Holmes, Armstead J Esq',
          date: '2020-02-24',
          status: 'renewal',
          verified: true,
          activity: 96,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 14174
        },
        {
          id: 1229,
          name: 'Ressie Auffrey',
          country: {
            name: 'Japan',
            code: 'jp'
          },
          company: 'Faw, James C Cpa',
          date: '2016-10-21',
          status: 'negotiation',
          verified: false,
          activity: 28,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 80328
        },
        {
          id: 1230,
          name: 'Justine Mugnolo',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'Evans Rule Company',
          date: '2016-02-21',
          status: 'negotiation',
          verified: true,
          activity: 83,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 56392
        },
        {
          id: 1231,
          name: 'Eladia Saulter',
          country: {
            name: 'Switzerland',
            code: 'ch'
          },
          company: 'Tyee Productions Inc',
          date: '2016-03-10',
          status: 'new',
          verified: true,
          activity: 16,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 17176
        },
        {
          id: 1232,
          name: 'Chaya Malvin',
          country: {
            name: 'Cuba',
            code: 'cu'
          },
          company: 'Dunnells & Duvall',
          date: '2016-07-22',
          status: 'renewal',
          verified: true,
          activity: 12,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 90141
        },
        {
          id: 1233,
          name: 'Gwenn Suffield',
          country: {
            name: 'Austria',
            code: 'at'
          },
          company: 'Deltam Systems Inc',
          date: '2019-06-23',
          status: 'qualified',
          verified: true,
          activity: 67,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 38187
        },
        {
          id: 1234,
          name: 'Salena Karpel',
          country: {
            name: 'Puerto Rico',
            code: 'pr'
          },
          company: 'Hammill Mfg Co',
          date: '2016-08-02',
          status: 'renewal',
          verified: true,
          activity: 0,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 35596
        },
        {
          id: 1235,
          name: 'Yoko Fishburne',
          country: {
            name: 'Croatia',
            code: 'hr'
          },
          company: 'Sams Corner Store',
          date: '2017-08-06',
          status: 'unqualified',
          verified: true,
          activity: 73,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 73926
        },
        {
          id: 1236,
          name: 'Taryn Moyd',
          country: {
            name: 'Serbia',
            code: 'rs'
          },
          company: 'Siskin, Mark J Esq',
          date: '2016-12-18',
          status: 'negotiation',
          verified: true,
          activity: 57,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 38375
        },
        {
          id: 1237,
          name: 'Katina Polidori',
          country: {
            name: 'Nigeria',
            code: 'ng'
          },
          company: 'Cape & Associates Real Estate',
          date: '2016-08-25',
          status: 'unqualified',
          verified: true,
          activity: 37,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 35932
        },
        {
          id: 1238,
          name: 'Rickie Plumer',
          country: {
            name: 'Turkey',
            code: 'tr'
          },
          company: 'Merrill Lynch',
          date: '2018-11-04',
          status: 'unqualified',
          verified: true,
          activity: 71,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 28331
        },
        {
          id: 1239,
          name: 'Alex Loader',
          country: {
            name: 'Italy',
            code: 'it'
          },
          company: 'Sublett, Scott Esq',
          date: '2016-01-07',
          status: 'unqualified',
          verified: false,
          activity: 22,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 33549
        },
        {
          id: 1240,
          name: 'Lashon Vizarro',
          country: {
            name: 'Ecuador',
            code: 'ec'
          },
          company: 'Sentry Signs',
          date: '2019-08-02',
          status: 'negotiation',
          verified: true,
          activity: 46,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 63860
        },
        {
          id: 1241,
          name: 'Lauran Burnard',
          country: {
            name: 'Czech Republic',
            code: 'cz'
          },
          company: 'Professionals Unlimited',
          date: '2016-04-27',
          status: 'renewal',
          verified: true,
          activity: 30,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 34992
        },
        {
          id: 1242,
          name: 'Ceola Setter',
          country: {
            name: 'Taiwan',
            code: 'tw'
          },
          company: 'Southern Steel Shelving Co',
          date: '2017-05-13',
          status: 'qualified',
          verified: true,
          activity: 43,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 97068
        },
        {
          id: 1243,
          name: 'My Rantanen',
          country: {
            name: 'Philippines',
            code: 'ph'
          },
          company: 'Bosco, Paul J',
          date: '2017-09-11',
          status: 'proposal',
          verified: true,
          activity: 60,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 2316
        },
        {
          id: 1244,
          name: 'Lorrine Worlds',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'Longo, Nicholas J Esq',
          date: '2018-05-10',
          status: 'renewal',
          verified: true,
          activity: 51,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 89374
        },
        {
          id: 1245,
          name: 'Peggie Sturiale',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'Henry County Middle School',
          date: '2019-11-03',
          status: 'renewal',
          verified: true,
          activity: 41,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 51152
        },
        {
          id: 1246,
          name: 'Marvel Raymo',
          country: {
            name: 'Belgium',
            code: 'be'
          },
          company: 'Edison Supply & Equipment Co',
          date: '2016-03-08',
          status: 'renewal',
          verified: true,
          activity: 9,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 39848
        },
        {
          id: 1247,
          name: 'Daron Dinos',
          country: {
            name: 'Hungary',
            code: 'hu'
          },
          company: 'Wolf, Warren R Esq',
          date: '2016-03-25',
          status: 'unqualified',
          verified: true,
          activity: 30,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 35060
        },
        {
          id: 1248,
          name: 'An Fritz',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'Linguistic Systems Inc',
          date: '2017-08-04',
          status: 'proposal',
          verified: true,
          activity: 86,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 23037
        },
        {
          id: 1249,
          name: 'Portia Stimmel',
          country: {
            name: 'Australia',
            code: 'au'
          },
          company: 'Peace Christian Center',
          date: '2020-01-13',
          status: 'qualified',
          verified: true,
          activity: 30,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 35637
        },
        {
          id: 1250,
          name: 'Rhea Aredondo',
          country: {
            name: 'Greece',
            code: 'gr'
          },
          company: 'Double B Foods Inc',
          date: '2016-06-19',
          status: 'qualified',
          verified: true,
          activity: 68,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 70379
        },
        {
          id: 1251,
          name: 'Benedict Sama',
          country: {
            name: 'Israel',
            code: 'il'
          },
          company: 'Alexander & Alexander Inc',
          date: '2018-08-18',
          status: 'negotiation',
          verified: true,
          activity: 79,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 99271
        },
        {
          id: 1252,
          name: 'Alyce Arias',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Fairbanks Scales',
          date: '2018-09-14',
          status: 'new',
          verified: true,
          activity: 77,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 69658
        },
        {
          id: 1253,
          name: 'Heike Berganza',
          country: {
            name: 'Bulgaria',
            code: 'bg'
          },
          company: 'Cali Sportswear Cutting Dept',
          date: '2019-01-21',
          status: 'unqualified',
          verified: true,
          activity: 95,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 78159
        },
        {
          id: 1254,
          name: 'Carey Dopico',
          country: {
            name: 'Saudi Arabia',
            code: 'sa'
          },
          company: 'Garofani, John Esq',
          date: '2020-05-25',
          status: 'negotiation',
          verified: true,
          activity: 35,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 84097
        },
        {
          id: 1255,
          name: 'Dottie Hellickson',
          country: {
            name: 'Chile',
            code: 'cl'
          },
          company: 'Thompson Fabricating Co',
          date: '2016-02-12',
          status: 'qualified',
          verified: true,
          activity: 7,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 54606
        },
        {
          id: 1256,
          name: 'Deandrea Hughey',
          country: {
            name: 'South Africa',
            code: 'za'
          },
          company: 'Century 21 Krall Real Estate',
          date: '2018-09-26',
          status: 'unqualified',
          verified: true,
          activity: 96,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 2730
        },
        {
          id: 1257,
          name: 'Kimberlie Duenas',
          country: {
            name: 'Bulgaria',
            code: 'bg'
          },
          company: 'Mid Contntl Rlty & Prop Mgmt',
          date: '2015-07-27',
          status: 'qualified',
          verified: true,
          activity: 96,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 71836
        },
        {
          id: 1258,
          name: 'Martina Staback',
          country: {
            name: 'Luxembourg',
            code: 'lu'
          },
          company: 'Ace Signs Inc',
          date: '2017-02-14',
          status: 'new',
          verified: true,
          activity: 47,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 37044
        },
        {
          id: 1259,
          name: 'Skye Fillingim',
          country: {
            name: 'Belgium',
            code: 'be'
          },
          company: 'Rodeway Inn',
          date: '2017-02-12',
          status: 'new',
          verified: true,
          activity: 86,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 5990
        },
        {
          id: 1260,
          name: 'Jade Farrar',
          country: {
            name: 'Mexico',
            code: 'mx'
          },
          company: 'Bonnet & Daughter',
          date: '2017-02-23',
          status: 'new',
          verified: true,
          activity: 73,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 7878
        },
        {
          id: 1261,
          name: 'Charlene Hamilton',
          country: {
            name: 'India',
            code: 'in'
          },
          company: 'Oshins & Gibbons',
          date: '2017-09-23',
          status: 'unqualified',
          verified: true,
          activity: 32,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 38077
        },
        {
          id: 1262,
          name: 'Geoffrey Acey',
          country: {
            name: 'Hong Kong',
            code: 'hk'
          },
          company: 'Price Business Services',
          date: '2019-10-11',
          status: 'proposal',
          verified: true,
          activity: 98,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 24537
        },
        {
          id: 1263,
          name: 'Stevie Westerbeck',
          country: {
            name: 'Lithuania',
            code: 'lt'
          },
          company: 'Wise, Dennis W Md',
          date: '2017-08-01',
          status: 'renewal',
          verified: true,
          activity: 57,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 19164
        },
        {
          id: 1264,
          name: 'Pamella Fortino',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Super 8 Motel',
          date: '2018-09-03',
          status: 'new',
          verified: true,
          activity: 100,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 21880
        },
        {
          id: 1265,
          name: 'Harrison Haufler',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'John Wagner Associates',
          date: '2016-08-25',
          status: 'qualified',
          verified: true,
          activity: 85,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 14975
        },
        {
          id: 1266,
          name: 'Johnna Engelberg',
          country: {
            name: 'Thailand',
            code: 'th'
          },
          company: 'Thrifty Oil Co',
          date: '2019-06-02',
          status: 'renewal',
          verified: true,
          activity: 1,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 28001
        },
        {
          id: 1267,
          name: 'Buddy Cloney',
          country: {
            name: 'Hungary',
            code: 'hu'
          },
          company: 'Larkfield Photo',
          date: '2020-05-14',
          status: 'qualified',
          verified: true,
          activity: 9,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 44811
        },
        {
          id: 1268,
          name: 'Dalene Riden',
          country: {
            name: 'Bolivia',
            code: 'bo'
          },
          company: 'Silverman Planetarium',
          date: '2019-08-23',
          status: 'renewal',
          verified: true,
          activity: 31,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 70963
        },
        {
          id: 1269,
          name: 'Jerry Zurcher',
          country: {
            name: 'Ukraine',
            code: 'ua'
          },
          company: 'J & F Lumber',
          date: '2015-05-11',
          status: 'renewal',
          verified: true,
          activity: 52,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 27808
        },
        {
          id: 1270,
          name: 'Haydee Denooyer',
          country: {
            name: 'Ivory Coast',
            code: 'ci'
          },
          company: 'Cleaning Station Inc',
          date: '2016-04-17',
          status: 'qualified',
          verified: true,
          activity: 80,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 41216
        },
        {
          id: 1271,
          name: 'Joseph Cryer',
          country: {
            name: 'Philippines',
            code: 'ph'
          },
          company: 'Ames Stationers',
          date: '2019-11-26',
          status: 'qualified',
          verified: true,
          activity: 53,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 8819
        },
        {
          id: 1272,
          name: 'Deonna Kippley',
          country: {
            name: 'South Africa',
            code: 'za'
          },
          company: 'Midas Muffler Shops',
          date: '2017-08-26',
          status: 'qualified',
          verified: true,
          activity: 52,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 77610
        },
        {
          id: 1273,
          name: 'Raymon Calvaresi',
          country: {
            name: 'South Africa',
            code: 'za'
          },
          company: 'Seaboard Securities Inc',
          date: '2017-07-09',
          status: 'qualified',
          verified: true,
          activity: 45,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 80612
        },
        {
          id: 1274,
          name: 'Alecia Bubash',
          country: {
            name: 'Poland',
            code: 'pl'
          },
          company: 'Petersen, James E Esq',
          date: '2016-05-19',
          status: 'new',
          verified: false,
          activity: 28,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 45311
        },
        {
          id: 1275,
          name: 'Ma Layous',
          country: {
            name: 'Latvia',
            code: 'lv'
          },
          company: 'Development Authority',
          date: '2016-02-11',
          status: 'qualified',
          verified: false,
          activity: 2,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 88737
        },
        {
          id: 1276,
          name: 'Detra Coyier',
          country: {
            name: 'Ivory Coast',
            code: 'ci'
          },
          company: 'Schott Fiber Optics Inc',
          date: '2019-06-05',
          status: 'proposal',
          verified: true,
          activity: 95,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 13224
        },
        {
          id: 1277,
          name: 'Terrilyn Rodeigues',
          country: {
            name: 'Thailand',
            code: 'th'
          },
          company: 'Stuart J Agins',
          date: '2016-08-04',
          status: 'renewal',
          verified: true,
          activity: 35,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 64656
        },
        {
          id: 1278,
          name: 'Salome Lacovara',
          country: {
            name: 'Romania',
            code: 'ro'
          },
          company: 'Mitsumi Electronics Corp',
          date: '2015-06-05',
          status: 'unqualified',
          verified: true,
          activity: 78,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 61947
        },
        {
          id: 1279,
          name: 'Garry Keetch',
          country: {
            name: 'Luxembourg',
            code: 'lu'
          },
          company: 'Italian Express Franchise Corp',
          date: '2015-06-20',
          status: 'unqualified',
          verified: true,
          activity: 86,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 6380
        },
        {
          id: 1280,
          name: 'Matthew Neither',
          country: {
            name: 'United Kingdom',
            code: 'gb'
          },
          company: 'American Council On Sci & Hlth',
          date: '2018-06-24',
          status: 'qualified',
          verified: true,
          activity: 4,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 80112
        },
        {
          id: 1281,
          name: 'Theodora Restrepo',
          country: {
            name: 'Paraguay',
            code: 'py'
          },
          company: 'Kleri, Patricia S Esq',
          date: '2017-08-15',
          status: 'renewal',
          verified: true,
          activity: 66,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 42038
        },
        {
          id: 1282,
          name: 'Noah Kalafatis',
          country: {
            name: 'Saudi Arabia',
            code: 'sa'
          },
          company: 'Twiggs Abrams Blanchard',
          date: '2020-06-24',
          status: 'negotiation',
          verified: true,
          activity: 35,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 36052
        },
        {
          id: 1283,
          name: 'Carmen Sweigard',
          country: {
            name: 'Bolivia',
            code: 'bo'
          },
          company: 'Maui Research & Technology Pk',
          date: '2019-08-08',
          status: 'new',
          verified: true,
          activity: 96,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 51660
        },
        {
          id: 1284,
          name: 'Lavonda Hengel',
          country: {
            name: 'Hungary',
            code: 'hu'
          },
          company: 'Bradley Nameplate Corp',
          date: '2019-10-25',
          status: 'negotiation',
          verified: true,
          activity: 48,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 56142
        },
        {
          id: 1285,
          name: 'Junita Stoltzman',
          country: {
            name: 'Turkey',
            code: 'tr'
          },
          company: 'Geonex Martel Inc',
          date: '2015-03-19',
          status: 'proposal',
          verified: true,
          activity: 64,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 21631
        },
        {
          id: 1286,
          name: 'Herminia Nicolozakes',
          country: {
            name: 'Venezuela',
            code: 've'
          },
          company: 'Sea Island Div Of Fstr Ind Inc',
          date: '2019-09-09',
          status: 'negotiation',
          verified: true,
          activity: 0,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 59171
        },
        {
          id: 1287,
          name: 'Casie Good',
          country: {
            name: 'Japan',
            code: 'jp'
          },
          company: 'Papay, Debbie J Esq',
          date: '2016-11-27',
          status: 'new',
          verified: true,
          activity: 87,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 97858
        },
        {
          id: 1288,
          name: 'Reena Maisto',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Lane Promotions',
          date: '2016-08-22',
          status: 'new',
          verified: true,
          activity: 16,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 90388
        },
        {
          id: 1289,
          name: 'Mirta Mallett',
          country: {
            name: 'Singapore',
            code: 'sg'
          },
          company: 'Stephen Kennerly Archts Inc Pc',
          date: '2017-11-27',
          status: 'renewal',
          verified: true,
          activity: 14,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 68632
        },
        {
          id: 1290,
          name: 'Cathrine Pontoriero',
          country: {
            name: 'Finland',
            code: 'fi'
          },
          company: 'Business Systems Of Wis Inc',
          date: '2020-02-07',
          status: 'negotiation',
          verified: true,
          activity: 58,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 95552
        },
        {
          id: 1291,
          name: 'Filiberto Tawil',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Flash, Elena Salerno Esq',
          date: '2018-01-28',
          status: 'proposal',
          verified: true,
          activity: 0,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 39725
        },
        {
          id: 1292,
          name: 'Raul Upthegrove',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Neeley, Gregory W Esq',
          date: '2019-11-16',
          status: 'renewal',
          verified: true,
          activity: 59,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 62251
        },
        {
          id: 1293,
          name: 'Sarah Candlish',
          country: {
            name: 'Hong Kong',
            code: 'hk'
          },
          company: 'Alabama Educational Tv Comm',
          date: '2019-06-09',
          status: 'proposal',
          verified: true,
          activity: 30,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 73523
        },
        {
          id: 1294,
          name: 'Lucy Treston',
          country: {
            name: 'Jamaica',
            code: 'jm'
          },
          company: 'Franz Inc',
          date: '2017-02-07',
          status: 'proposal',
          verified: true,
          activity: 59,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 41331
        },
        {
          id: 1295,
          name: 'Judy Aquas',
          country: {
            name: 'Romania',
            code: 'ro'
          },
          company: 'Plantation Restaurant',
          date: '2020-09-14',
          status: 'renewal',
          verified: true,
          activity: 86,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 24208
        },
        {
          id: 1296,
          name: 'Yvonne Tjepkema',
          country: {
            name: 'United Kingdom',
            code: 'gb'
          },
          company: 'Radio Communications Co',
          date: '2017-01-06',
          status: 'renewal',
          verified: true,
          activity: 33,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 56409
        },
        {
          id: 1297,
          name: 'Kayleigh Lace',
          country: {
            name: 'Denmark',
            code: 'dk'
          },
          company: 'Dentalaw Divsn Hlth Care',
          date: '2019-11-04',
          status: 'negotiation',
          verified: true,
          activity: 70,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 70025
        },
        {
          id: 1298,
          name: 'Felix Hirpara',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'American Speedy Printing Ctrs',
          date: '2015-03-13',
          status: 'new',
          verified: true,
          activity: 8,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 88115
        },
        {
          id: 1299,
          name: 'Tresa Sweely',
          country: {
            name: 'Germany',
            code: 'de'
          },
          company: 'Grayson, Grant S Esq',
          date: '2019-12-22',
          status: 'proposal',
          verified: true,
          activity: 22,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 69703
        },
        {
          id: 1300,
          name: 'Kristeen Turinetti',
          country: {
            name: 'Burkina Faso',
            code: 'bf'
          },
          company: 'Jeanerette Middle School',
          date: '2017-09-28',
          status: 'new',
          verified: true,
          activity: 67,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 10718
        },
        {
          id: 1301,
          name: 'Jenelle Regusters',
          country: {
            name: 'Hungary',
            code: 'hu'
          },
          company: 'Haavisto, Brian F Esq',
          date: '2019-07-27',
          status: 'qualified',
          verified: true,
          activity: 13,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 29825
        },
        {
          id: 1302,
          name: 'Renea Monterrubio',
          country: {
            name: 'Greece',
            code: 'gr'
          },
          company: 'Wmmt Radio Station',
          date: '2016-03-28',
          status: 'proposal',
          verified: true,
          activity: 73,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 90351
        },
        {
          id: 1303,
          name: 'Olive Matuszak',
          country: {
            name: 'Germany',
            code: 'de'
          },
          company: 'Colony Paints Sales Ofc & Plnt',
          date: '2018-04-15',
          status: 'unqualified',
          verified: true,
          activity: 70,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 75682
        },
        {
          id: 1304,
          name: 'Ligia Reiber',
          country: {
            name: 'Cameroon',
            code: 'cm'
          },
          company: 'Floral Expressions',
          date: '2017-02-02',
          status: 'negotiation',
          verified: true,
          activity: 51,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 74935
        },
        {
          id: 1305,
          name: 'Christiane Eschberger',
          country: {
            name: 'Romania',
            code: 'ro'
          },
          company: 'Casco Services Inc',
          date: '2018-03-27',
          status: 'unqualified',
          verified: true,
          activity: 81,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 5254
        },
        {
          id: 1306,
          name: 'Goldie Schirpke',
          country: {
            name: 'Paraguay',
            code: 'py'
          },
          company: 'Reuter, Arthur C Jr',
          date: '2015-04-21',
          status: 'renewal',
          verified: true,
          activity: 8,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 37306
        },
        {
          id: 1307,
          name: 'Loreta Timenez',
          country: {
            name: 'Burkina Faso',
            code: 'bf'
          },
          company: 'Kaminski, Katherine Andritsaki',
          date: '2015-07-17',
          status: 'proposal',
          verified: true,
          activity: 29,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 70520
        },
        {
          id: 1308,
          name: 'Fabiola Hauenstein',
          country: {
            name: 'Czech Republic',
            code: 'cz'
          },
          company: 'Sidewinder Products Corp',
          date: '2015-12-12',
          status: 'renewal',
          verified: true,
          activity: 42,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 74510
        },
        {
          id: 1309,
          name: 'Amie Perigo',
          country: {
            name: 'Russia',
            code: 'ru'
          },
          company: 'General Foam Corporation',
          date: '2016-06-13',
          status: 'new',
          verified: true,
          activity: 55,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 74410
        },
        {
          id: 1310,
          name: 'Raina Brachle',
          country: {
            name: 'Greece',
            code: 'gr'
          },
          company: 'Ikg Borden Divsn Harsco Corp',
          date: '2016-11-28',
          status: 'negotiation',
          verified: true,
          activity: 12,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 56116
        },
        {
          id: 1311,
          name: 'Erinn Canlas',
          country: {
            name: 'United Kingdom',
            code: 'gb'
          },
          company: 'Anchor Computer Inc',
          date: '2018-12-08',
          status: 'proposal',
          verified: true,
          activity: 89,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 46518
        },
        {
          id: 1312,
          name: 'Cherry Lietz',
          country: {
            name: 'Bulgaria',
            code: 'bg'
          },
          company: 'Sebring & Co',
          date: '2017-11-04',
          status: 'qualified',
          verified: true,
          activity: 22,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 92213
        },
        {
          id: 1313,
          name: 'Kattie Vonasek',
          country: {
            name: 'Austria',
            code: 'at'
          },
          company: 'H A C Farm Lines Co Optv Assoc',
          date: '2017-02-17',
          status: 'proposal',
          verified: true,
          activity: 58,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 6405
        },
        {
          id: 1314,
          name: 'Lilli Scriven',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Hunter, John J Esq',
          date: '2017-04-15',
          status: 'qualified',
          verified: true,
          activity: 79,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 92900
        },
        {
          id: 1315,
          name: 'Whitley Tomasulo',
          country: {
            name: 'Qatar',
            code: 'qa'
          },
          company: 'Freehold Fence Co',
          date: '2017-11-16',
          status: 'qualified',
          verified: true,
          activity: 6,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 86727
        },
        {
          id: 1316,
          name: 'Barbra Adkin',
          country: {
            name: 'Nigeria',
            code: 'ng'
          },
          company: 'Binswanger',
          date: '2019-07-24',
          status: 'qualified',
          verified: true,
          activity: 64,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 48040
        },
        {
          id: 1317,
          name: 'Hermila Thyberg',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'Chilton Malting Co',
          date: '2016-11-05',
          status: 'qualified',
          verified: true,
          activity: 57,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 24026
        },
        {
          id: 1318,
          name: 'Jesusita Flister',
          country: {
            name: 'Ukraine',
            code: 'ua'
          },
          company: 'Schoen, Edward J Jr',
          date: '2017-03-03',
          status: 'proposal',
          verified: true,
          activity: 29,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 76388
        },
        {
          id: 1319,
          name: 'Caitlin Julia',
          country: {
            name: 'Israel',
            code: 'il'
          },
          company: 'Helderman, Seymour Cpa',
          date: '2018-03-13',
          status: 'renewal',
          verified: true,
          activity: 19,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 89068
        },
        {
          id: 1320,
          name: 'Roosevelt Hoffis',
          country: {
            name: 'Puerto Rico',
            code: 'pr'
          },
          company: 'Denbrook, Myron',
          date: '2019-04-14',
          status: 'new',
          verified: true,
          activity: 49,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 12727
        },
        {
          id: 1321,
          name: 'Helaine Halter',
          country: {
            name: 'Estonia',
            code: 'ee'
          },
          company: 'Lippitt, Mike',
          date: '2019-09-28',
          status: 'renewal',
          verified: true,
          activity: 62,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 83411
        },
        {
          id: 1322,
          name: 'Lorean Martabano',
          country: {
            name: 'Slovakia',
            code: 'sk'
          },
          company: 'Hiram, Hogg P Esq',
          date: '2017-03-23',
          status: 'negotiation',
          verified: true,
          activity: 8,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 44242
        },
        {
          id: 1323,
          name: 'France Buzick',
          country: {
            name: 'Nigeria',
            code: 'ng'
          },
          company: 'In Travel Agency',
          date: '2016-12-14',
          status: 'renewal',
          verified: true,
          activity: 22,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 5644
        },
        {
          id: 1324,
          name: 'Justine Ferrario',
          country: {
            name: 'Ukraine',
            code: 'ua'
          },
          company: 'Newhart Foods Inc',
          date: '2015-09-07',
          status: 'unqualified',
          verified: true,
          activity: 7,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 29321
        },
        {
          id: 1325,
          name: 'Adelina Nabours',
          country: {
            name: 'Mexico',
            code: 'mx'
          },
          company: 'Courtyard By Marriott',
          date: '2018-05-26',
          status: 'renewal',
          verified: true,
          activity: 59,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 13353
        },
        {
          id: 1326,
          name: 'Derick Dhamer',
          country: {
            name: 'Hungary',
            code: 'hu'
          },
          company: 'Studer, Eugene A Esq',
          date: '2020-07-04',
          status: 'new',
          verified: true,
          activity: 72,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 53454
        },
        {
          id: 1327,
          name: 'Jerry Dallen',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Seashore Supply Co Waretown',
          date: '2015-10-23',
          status: 'renewal',
          verified: false,
          activity: 21,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 30074
        },
        {
          id: 1328,
          name: 'Leota Ragel',
          country: {
            name: 'Qatar',
            code: 'qa'
          },
          company: 'Mayar Silk Inc',
          date: '2019-06-15',
          status: 'negotiation',
          verified: true,
          activity: 74,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 36310
        },
        {
          id: 1329,
          name: 'Jutta Amyot',
          country: {
            name: 'Cameroon',
            code: 'cm'
          },
          company: 'National Medical Excess Corp',
          date: '2019-09-01',
          status: 'proposal',
          verified: true,
          activity: 8,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 27563
        },
        {
          id: 1330,
          name: 'Aja Gehrett',
          country: {
            name: 'Sweden',
            code: 'se'
          },
          company: 'Stero Company',
          date: '2018-01-28',
          status: 'qualified',
          verified: true,
          activity: 46,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 45163
        },
        {
          id: 1331,
          name: 'Kirk Herritt',
          country: {
            name: 'Morocco',
            code: 'ma'
          },
          company: 'Hasting, H Duane Esq',
          date: '2018-10-24',
          status: 'renewal',
          verified: false,
          activity: 2,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 52970
        },
        {
          id: 1332,
          name: 'Leonora Mauson',
          country: {
            name: 'Hong Kong',
            code: 'hk'
          },
          company: 'Insty Prints',
          date: '2019-02-02',
          status: 'proposal',
          verified: true,
          activity: 8,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 18922
        },
        {
          id: 1333,
          name: 'Winfred Brucato',
          country: {
            name: 'Austria',
            code: 'at'
          },
          company: 'Glenridge Manor Mobile Home Pk',
          date: '2019-08-16',
          status: 'renewal',
          verified: true,
          activity: 8,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 59632
        },
        {
          id: 1334,
          name: 'Tarra Nachor',
          country: {
            name: 'Panama',
            code: 'pa'
          },
          company: 'Circuit Solution Inc',
          date: '2019-07-10',
          status: 'proposal',
          verified: true,
          activity: 88,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 6733
        },
        {
          id: 1335,
          name: 'Corinne Loder',
          country: {
            name: 'Brazil',
            code: 'br'
          },
          company: 'Local Office',
          date: '2016-05-21',
          status: 'proposal',
          verified: true,
          activity: 85,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 22704
        },
        {
          id: 1336,
          name: 'Dulce Labreche',
          country: {
            name: 'South Africa',
            code: 'za'
          },
          company: 'Lee Kilkelly Paulson & Kabaker',
          date: '2019-11-10',
          status: 'qualified',
          verified: true,
          activity: 24,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 82613
        },
        {
          id: 1337,
          name: 'Kate Keneipp',
          country: {
            name: 'Latvia',
            code: 'lv'
          },
          company: 'Davis, Maxon R Esq',
          date: '2019-06-20',
          status: 'proposal',
          verified: true,
          activity: 44,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 97960
        },
        {
          id: 1338,
          name: 'Kaitlyn Ogg',
          country: {
            name: 'Tunisia',
            code: 'tn'
          },
          company: 'Garrison, Paul E Esq',
          date: '2019-08-01',
          status: 'proposal',
          verified: true,
          activity: 91,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 63123
        },
        {
          id: 1339,
          name: 'Sherita Saras',
          country: {
            name: 'Taiwan',
            code: 'tw'
          },
          company: 'Black History Resource Center',
          date: '2017-06-09',
          status: 'unqualified',
          verified: true,
          activity: 79,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 38090
        },
        {
          id: 1340,
          name: 'Lashawnda Stuer',
          country: {
            name: 'Indonesia',
            code: 'id'
          },
          company: 'Rodriguez, J Christopher Esq',
          date: '2018-01-08',
          status: 'negotiation',
          verified: true,
          activity: 6,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 58826
        },
        {
          id: 1341,
          name: 'Ernest Syrop',
          country: {
            name: 'Jamaica',
            code: 'jm'
          },
          company: 'Grant Family Health Center',
          date: '2016-10-06',
          status: 'unqualified',
          verified: true,
          activity: 90,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 22419
        },
        {
          id: 1342,
          name: 'Nobuko Halsey',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'Goeman Wood Products Inc',
          date: '2019-01-02',
          status: 'unqualified',
          verified: true,
          activity: 72,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 86006
        },
        {
          id: 1343,
          name: 'Lavonna Wolny',
          country: {
            name: 'Czech Republic',
            code: 'cz'
          },
          company: 'Linhares, Kenneth A Esq',
          date: '2019-03-09',
          status: 'negotiation',
          verified: true,
          activity: 77,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 40641
        },
        {
          id: 1344,
          name: 'Lashaunda Lizama',
          country: {
            name: 'Ecuador',
            code: 'ec'
          },
          company: 'Earnhardt Printing',
          date: '2016-02-16',
          status: 'negotiation',
          verified: true,
          activity: 76,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 73493
        },
        {
          id: 1345,
          name: 'Mariann Bilden',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'H P G Industrys Inc',
          date: '2019-12-15',
          status: 'proposal',
          verified: true,
          activity: 20,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 87704
        },
        {
          id: 1346,
          name: 'Helene Rodenberger',
          country: {
            name: 'Bolivia',
            code: 'bo'
          },
          company: 'Bailey Transportation Prod Inc',
          date: '2016-09-22',
          status: 'unqualified',
          verified: true,
          activity: 26,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 19662
        },
        {
          id: 1347,
          name: 'Roselle Estell',
          country: {
            name: 'Hungary',
            code: 'hu'
          },
          company: 'Mcglynn Bliss Pc',
          date: '2016-11-25',
          status: 'negotiation',
          verified: true,
          activity: 80,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 80534
        },
        {
          id: 1348,
          name: 'Samira Heintzman',
          country: {
            name: 'Vietnam',
            code: 'vn'
          },
          company: 'Mutual Fish Co',
          date: '2019-11-26',
          status: 'qualified',
          verified: true,
          activity: 70,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 19473
        },
        {
          id: 1349,
          name: 'Margart Meisel',
          country: {
            name: 'Serbia',
            code: 'rs'
          },
          company: 'Yeates, Arthur L Aia',
          date: '2019-10-27',
          status: 'negotiation',
          verified: true,
          activity: 77,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 89796
        },
        {
          id: 1350,
          name: 'Kristofer Bennick',
          country: {
            name: 'Tunisia',
            code: 'tn'
          },
          company: 'Logan, Ronald J Esq',
          date: '2016-02-13',
          status: 'proposal',
          verified: true,
          activity: 5,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 54429
        },
        {
          id: 1351,
          name: 'Weldon Acuff',
          country: {
            name: 'Estonia',
            code: 'ee'
          },
          company: 'Advantage Martgage Company',
          date: '2019-10-22',
          status: 'qualified',
          verified: true,
          activity: 38,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 16134
        },
        {
          id: 1352,
          name: 'Shalon Shadrick',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Germer And Gertz Llp',
          date: '2015-01-03',
          status: 'proposal',
          verified: true,
          activity: 66,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 9173
        },
        {
          id: 1353,
          name: 'Denise Patak',
          country: {
            name: 'Uruguay',
            code: 'uy'
          },
          company: 'Spence Law Offices',
          date: '2016-11-03',
          status: 'qualified',
          verified: true,
          activity: 77,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 42857
        },
        {
          id: 1354,
          name: 'Louvenia Beech',
          country: {
            name: 'Ecuador',
            code: 'ec'
          },
          company: 'John Ortiz Nts Therapy Center',
          date: '2016-04-11',
          status: 'renewal',
          verified: true,
          activity: 62,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 54300
        },
        {
          id: 1355,
          name: 'Audry Yaw',
          country: {
            name: 'Portugal',
            code: 'pt'
          },
          company: 'Mike Uchrin Htg & Air Cond Inc',
          date: '2017-11-22',
          status: 'unqualified',
          verified: true,
          activity: 12,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 94652
        },
        {
          id: 1356,
          name: 'Kristel Ehmann',
          country: {
            name: 'Israel',
            code: 'il'
          },
          company: 'Mccoy, Joy Reynolds Esq',
          date: '2016-06-23',
          status: 'new',
          verified: true,
          activity: 67,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 88295
        },
        {
          id: 1357,
          name: 'Vincenza Zepp',
          country: {
            name: 'Pakistan',
            code: 'pk'
          },
          company: 'Kbor 1600 Am',
          date: '2016-01-04',
          status: 'qualified',
          verified: true,
          activity: 25,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 92710
        },
        {
          id: 1358,
          name: 'Elouise Gwalthney',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'Quality Inn Northwest',
          date: '2016-04-05',
          status: 'proposal',
          verified: true,
          activity: 14,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 25145
        },
        {
          id: 1359,
          name: 'Venita Maillard',
          country: {
            name: 'Poland',
            code: 'pl'
          },
          company: 'Wallace Church Assoc Inc',
          date: '2016-03-23',
          status: 'negotiation',
          verified: true,
          activity: 69,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 61630
        },
        {
          id: 1360,
          name: 'Kasandra Semidey',
          country: {
            name: 'Indonesia',
            code: 'id'
          },
          company: 'Can Tron',
          date: '2015-09-03',
          status: 'new',
          verified: true,
          activity: 25,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 66134
        },
        {
          id: 1361,
          name: 'Xochitl Discipio',
          country: {
            name: 'Slovakia',
            code: 'sk'
          },
          company: 'Ravaal Enterprises Inc',
          date: '2016-11-14',
          status: 'qualified',
          verified: true,
          activity: 47,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 14822
        },
        {
          id: 1362,
          name: 'Maile Linahan',
          country: {
            name: 'Nigeria',
            code: 'ng'
          },
          company: 'Thompson Steel Company Inc',
          date: '2016-07-24',
          status: 'new',
          verified: true,
          activity: 0,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 17759
        },
        {
          id: 1363,
          name: 'Krissy Rauser',
          country: {
            name: 'Thailand',
            code: 'th'
          },
          company: 'Anderson, Mark A Esq',
          date: '2020-03-06',
          status: 'renewal',
          verified: true,
          activity: 33,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 14922
        },
        {
          id: 1364,
          name: 'Pete Dubaldi',
          country: {
            name: 'Bolivia',
            code: 'bo'
          },
          company: 'Womack & Galich',
          date: '2015-11-22',
          status: 'unqualified',
          verified: true,
          activity: 31,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 96575
        },
        {
          id: 1365,
          name: 'Linn Paa',
          country: {
            name: 'Bolivia',
            code: 'bo'
          },
          company: 'Valerie & Company',
          date: '2015-08-12',
          status: 'unqualified',
          verified: true,
          activity: 26,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 5847
        },
        {
          id: 1366,
          name: 'Paris Wide',
          country: {
            name: 'Uruguay',
            code: 'uy'
          },
          company: 'Gehring Pumps Inc',
          date: '2018-10-21',
          status: 'proposal',
          verified: true,
          activity: 90,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 22929
        },
        {
          id: 1367,
          name: 'Wynell Dorshorst',
          country: {
            name: 'Romania',
            code: 'ro'
          },
          company: 'Haehnel, Craig W Esq',
          date: '2018-05-10',
          status: 'new',
          verified: true,
          activity: 60,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 31837
        },
        {
          id: 1368,
          name: 'Quentin Birkner',
          country: {
            name: 'Senegal',
            code: 'sn'
          },
          company: 'Spoor Behrins Campbell & Young',
          date: '2019-02-03',
          status: 'unqualified',
          verified: true,
          activity: 68,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 27095
        },
        {
          id: 1369,
          name: 'Regenia Kannady',
          country: {
            name: 'Portugal',
            code: 'pt'
          },
          company: 'Ken Jeter Store Equipment Inc',
          date: '2018-07-13',
          status: 'new',
          verified: true,
          activity: 74,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 43696
        },
        {
          id: 1370,
          name: 'Sheron Louissant',
          country: {
            name: 'France',
            code: 'fr'
          },
          company: 'Potter, Brenda J Cpa',
          date: '2017-01-04',
          status: 'qualified',
          verified: true,
          activity: 86,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 79897
        },
        {
          id: 1371,
          name: 'Izetta Funnell',
          country: {
            name: 'Hong Kong',
            code: 'hk'
          },
          company: 'Baird Kurtz & Dobson',
          date: '2019-11-05',
          status: 'new',
          verified: true,
          activity: 67,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 27264
        },
        {
          id: 1372,
          name: 'Rodolfo Butzen',
          country: {
            name: 'Taiwan',
            code: 'tw'
          },
          company: 'Minor, Cynthia A Esq',
          date: '2020-09-25',
          status: 'proposal',
          verified: true,
          activity: 17,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 16490
        },
        {
          id: 1373,
          name: 'Zona Colla',
          country: {
            name: 'Switzerland',
            code: 'ch'
          },
          company: 'Solove, Robert A Esq',
          date: '2020-10-08',
          status: 'negotiation',
          verified: true,
          activity: 64,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 21990
        },
        {
          id: 1374,
          name: 'Serina Zagen',
          country: {
            name: 'Paraguay',
            code: 'py'
          },
          company: 'Mark Ii Imports Inc',
          date: '2018-07-14',
          status: 'negotiation',
          verified: true,
          activity: 46,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 11918
        },
        {
          id: 1375,
          name: 'Paz Sahagun',
          country: {
            name: 'Colombia',
            code: 'co'
          },
          company: 'White Sign Div Ctrl Equip Co',
          date: '2018-03-23',
          status: 'negotiation',
          verified: true,
          activity: 49,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 13268
        },
        {
          id: 1376,
          name: 'Markus Lukasik',
          country: {
            name: 'Burkina Faso',
            code: 'bf'
          },
          company: 'M & M Store Fixtures Co Inc',
          date: '2018-03-21',
          status: 'negotiation',
          verified: true,
          activity: 23,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 15941
        },
        {
          id: 1377,
          name: 'Jaclyn Bachman',
          country: {
            name: 'Slovakia',
            code: 'sk'
          },
          company: 'Judah Caster & Wheel Co',
          date: '2020-10-20',
          status: 'new',
          verified: true,
          activity: 10,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 53132
        },
        {
          id: 1378,
          name: 'Cyril Daufeldt',
          country: {
            name: 'India',
            code: 'in'
          },
          company: 'Galaxy International Inc',
          date: '2019-02-11',
          status: 'qualified',
          verified: true,
          activity: 79,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 47531
        },
        {
          id: 1379,
          name: 'Gayla Schnitzler',
          country: {
            name: 'Brazil',
            code: 'br'
          },
          company: 'Sigma Corp Of America',
          date: '2017-09-15',
          status: 'new',
          verified: true,
          activity: 14,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 88950
        },
        {
          id: 1380,
          name: 'Erick Nievas',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'Soward, Anne Esq',
          date: '2018-10-07',
          status: 'proposal',
          verified: false,
          activity: 28,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 88497
        },
        {
          id: 1381,
          name: 'Jennie Drymon',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'Osborne, Michelle M Esq',
          date: '2016-09-14',
          status: 'negotiation',
          verified: true,
          activity: 80,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 60634
        },
        {
          id: 1382,
          name: 'Mitsue Scipione',
          country: {
            name: 'Ecuador',
            code: 'ec'
          },
          company: 'Students In Free Entrprs Natl',
          date: '2019-07-28',
          status: 'qualified',
          verified: true,
          activity: 24,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 21164
        },
        {
          id: 1383,
          name: 'Ciara Ventura',
          country: {
            name: 'Sweden',
            code: 'se'
          },
          company: 'Johnson, Robert M Esq',
          date: '2018-04-02',
          status: 'renewal',
          verified: false,
          activity: 24,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 83645
        },
        {
          id: 1384,
          name: 'Galen Cantres',
          country: {
            name: 'Saudi Arabia',
            code: 'sa'
          },
          company: 'Del Charro Apartments',
          date: '2020-02-16',
          status: 'unqualified',
          verified: true,
          activity: 61,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 32433
        },
        {
          id: 1385,
          name: 'Truman Feichtner',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Legal Search Inc',
          date: '2019-10-16',
          status: 'renewal',
          verified: true,
          activity: 98,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 96983
        },
        {
          id: 1386,
          name: 'Gail Kitty',
          country: {
            name: 'Pakistan',
            code: 'pk'
          },
          company: 'Service Supply Co Inc',
          date: '2020-01-15',
          status: 'negotiation',
          verified: true,
          activity: 67,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 72211
        },
        {
          id: 1387,
          name: 'Dalene Schoeneck',
          country: {
            name: 'Latvia',
            code: 'lv'
          },
          company: 'Sameshima, Douglas J Esq',
          date: '2019-11-27',
          status: 'proposal',
          verified: true,
          activity: 85,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 40262
        },
        {
          id: 1388,
          name: 'Gertude Witten',
          country: {
            name: 'Morocco',
            code: 'ma'
          },
          company: 'Thompson, John Randolph Jr',
          date: '2017-08-13',
          status: 'proposal',
          verified: true,
          activity: 40,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 91139
        },
        {
          id: 1389,
          name: 'Lizbeth Kohl',
          country: {
            name: 'Serbia',
            code: 'rs'
          },
          company: 'E T Balancing Co Inc',
          date: '2019-04-20',
          status: 'qualified',
          verified: true,
          activity: 57,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 79659
        },
        {
          id: 1390,
          name: 'Glenn Berray',
          country: {
            name: 'Poland',
            code: 'pl'
          },
          company: 'Griswold, John E Esq',
          date: '2017-08-13',
          status: 'negotiation',
          verified: true,
          activity: 68,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 93576
        },
        {
          id: 1391,
          name: 'Lashandra Klang',
          country: {
            name: 'Bulgaria',
            code: 'bg'
          },
          company: 'Acqua Group',
          date: '2016-06-08',
          status: 'negotiation',
          verified: true,
          activity: 32,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 93576
        },
        {
          id: 1392,
          name: 'Lenna Newville',
          country: {
            name: 'Venezuela',
            code: 've'
          },
          company: 'Brooks, Morris J Jr',
          date: '2020-09-12',
          status: 'qualified',
          verified: true,
          activity: 82,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 93576
        },
        {
          id: 1393,
          name: 'Laurel Pagliuca',
          country: {
            name: 'Russia',
            code: 'ru'
          },
          company: 'Printing Images Corp',
          date: '2017-05-26',
          status: 'unqualified',
          verified: true,
          activity: 21,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 2009
        },
        {
          id: 1394,
          name: 'Mireya Frerking',
          country: {
            name: 'Belarus',
            code: 'by'
          },
          company: 'Roberts Supply Co Inc',
          date: '2017-04-21',
          status: 'renewal',
          verified: true,
          activity: 54,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 35341
        },
        {
          id: 1395,
          name: 'Annelle Tagala',
          country: {
            name: 'Colombia',
            code: 'co'
          },
          company: 'Vico Products Mfg Co',
          date: '2017-10-02',
          status: 'proposal',
          verified: true,
          activity: 53,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 13077
        },
        {
          id: 1396,
          name: 'Dean Ketelsen',
          country: {
            name: 'Ivory Coast',
            code: 'ci'
          },
          company: 'J M Custom Design Millwork',
          date: '2019-07-25',
          status: 'negotiation',
          verified: true,
          activity: 98,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 83423
        },
        {
          id: 1397,
          name: 'Levi Munis',
          country: {
            name: 'Pakistan',
            code: 'pk'
          },
          company: 'Farrell & Johnson Office Equip',
          date: '2017-06-24',
          status: 'proposal',
          verified: true,
          activity: 39,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 14350
        },
        {
          id: 1398,
          name: 'Sylvie Ryser',
          country: {
            name: 'India',
            code: 'in'
          },
          company: 'Millers Market & Deli',
          date: '2016-02-23',
          status: 'unqualified',
          verified: true,
          activity: 89,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 98864
        },
        {
          id: 1399,
          name: 'Sharee Maile',
          country: {
            name: 'Ireland',
            code: 'ie'
          },
          company: 'Holiday Inn Naperville',
          date: '2020-07-17',
          status: 'renewal',
          verified: true,
          activity: 0,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 29326
        },
        {
          id: 1400,
          name: 'Cordelia Storment',
          country: {
            name: 'Guatemala',
            code: 'gt'
          },
          company: 'Burrows, Jon H Esq',
          date: '2020-02-17',
          status: 'negotiation',
          verified: true,
          activity: 45,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 81198
        },
        {
          id: 1401,
          name: 'Mollie Mcdoniel',
          country: {
            name: 'Vietnam',
            code: 'vn'
          },
          company: 'Dock Seal Specialty',
          date: '2017-08-13',
          status: 'unqualified',
          verified: true,
          activity: 59,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 82391
        },
        {
          id: 1402,
          name: 'Brett Mccullan',
          country: {
            name: 'Senegal',
            code: 'sn'
          },
          company: 'Five Star Limousines Of Tx Inc',
          date: '2015-05-21',
          status: 'qualified',
          verified: true,
          activity: 9,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 48071
        },
        {
          id: 1403,
          name: 'Teddy Pedrozo',
          country: {
            name: 'Burkina Faso',
            code: 'bf'
          },
          company: 'Barkan, Neal J Esq',
          date: '2019-02-19',
          status: 'proposal',
          verified: true,
          activity: 38,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 14593
        },
        {
          id: 1404,
          name: 'Tasia Andreason',
          country: {
            name: 'Venezuela',
            code: 've'
          },
          company: 'Campbell, Robert A',
          date: '2016-03-06',
          status: 'renewal',
          verified: true,
          activity: 25,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 8750
        },
        {
          id: 1405,
          name: 'Hubert Walthall',
          country: {
            name: 'Mexico',
            code: 'mx'
          },
          company: 'Dee, Deanna',
          date: '2020-12-19',
          status: 'renewal',
          verified: true,
          activity: 32,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 39655
        },
        {
          id: 1406,
          name: 'Arthur Farrow',
          country: {
            name: 'Brazil',
            code: 'br'
          },
          company: 'Young, Timothy L Esq',
          date: '2019-02-05',
          status: 'proposal',
          verified: true,
          activity: 55,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 71713
        },
        {
          id: 1407,
          name: 'Vilma Berlanga',
          country: {
            name: 'Tunisia',
            code: 'tn'
          },
          company: 'Wells, D Fred Esq',
          date: '2016-02-09',
          status: 'unqualified',
          verified: false,
          activity: 26,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 81056
        },
        {
          id: 1408,
          name: 'Billye Miro',
          country: {
            name: 'Hungary',
            code: 'hu'
          },
          company: 'Gray, Francine H Esq',
          date: '2015-09-05',
          status: 'new',
          verified: true,
          activity: 70,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 34220
        },
        {
          id: 1409,
          name: 'Glenna Slayton',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Toledo Iv Care',
          date: '2016-03-10',
          status: 'unqualified',
          verified: true,
          activity: 86,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 21459
        },
        {
          id: 1410,
          name: 'Mitzie Hudnall',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Cangro Transmission Co',
          date: '2020-05-04',
          status: 'new',
          verified: true,
          activity: 19,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 51281
        },
        {
          id: 1411,
          name: 'Bernardine Rodefer',
          country: {
            name: 'Switzerland',
            code: 'ch'
          },
          company: 'Sat Poly Inc',
          date: '2020-10-19',
          status: 'proposal',
          verified: true,
          activity: 69,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 53841
        },
        {
          id: 1412,
          name: 'Staci Schmaltz',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Midwest Contracting & Mfg Inc',
          date: '2018-03-14',
          status: 'unqualified',
          verified: true,
          activity: 81,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 34878
        },
        {
          id: 1413,
          name: 'Nichelle Meteer',
          country: {
            name: 'Denmark',
            code: 'dk'
          },
          company: 'Print Doctor',
          date: '2016-09-23',
          status: 'renewal',
          verified: true,
          activity: 39,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 85399
        },
        {
          id: 1414,
          name: 'Janine Rhoden',
          country: {
            name: 'Nigeria',
            code: 'ng'
          },
          company: 'Nordic Group Inc',
          date: '2018-08-13',
          status: 'qualified',
          verified: true,
          activity: 95,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 29237
        },
        {
          id: 1415,
          name: 'Ettie Hoopengardner',
          country: {
            name: 'Switzerland',
            code: 'ch'
          },
          company: 'Jackson Millwork Co',
          date: '2017-07-24',
          status: 'renewal',
          verified: false,
          activity: 23,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 12179
        },
        {
          id: 1416,
          name: 'Eden Jayson',
          country: {
            name: 'Sweden',
            code: 'se'
          },
          company: 'Harris Corporation',
          date: '2018-03-15',
          status: 'renewal',
          verified: true,
          activity: 9,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 27840
        },
        {
          id: 1417,
          name: 'Lynelle Auber',
          country: {
            name: 'Netherlands',
            code: 'nl'
          },
          company: 'United Cerebral Palsy Of Ne Pa',
          date: '2020-12-11',
          status: 'proposal',
          verified: true,
          activity: 0,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 51958
        },
        {
          id: 1418,
          name: 'Merissa Tomblin',
          country: {
            name: 'Jamaica',
            code: 'jm'
          },
          company: 'One Day Surgery Center Inc',
          date: '2018-03-05',
          status: 'new',
          verified: true,
          activity: 50,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 33138
        },
        {
          id: 1419,
          name: 'Golda Kaniecki',
          country: {
            name: 'Burkina Faso',
            code: 'bf'
          },
          company: 'Calaveras Prospect',
          date: '2019-04-17',
          status: 'negotiation',
          verified: true,
          activity: 91,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 82539
        },
        {
          id: 1420,
          name: 'Catarina Gleich',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'Terk, Robert E Esq',
          date: '2017-01-26',
          status: 'unqualified',
          verified: true,
          activity: 12,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 5619
        },
        {
          id: 1421,
          name: 'Virgie Kiel',
          country: {
            name: 'Latvia',
            code: 'lv'
          },
          company: 'Cullen, Terrence P Esq',
          date: '2016-06-28',
          status: 'renewal',
          verified: true,
          activity: 89,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 44763
        },
        {
          id: 1422,
          name: 'Jolene Ostolaza',
          country: {
            name: 'Puerto Rico',
            code: 'pr'
          },
          company: 'Central Die Casting Mfg Co Inc',
          date: '2016-08-08',
          status: 'negotiation',
          verified: true,
          activity: 85,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 97208
        },
        {
          id: 1423,
          name: 'Keneth Borgman',
          country: {
            name: 'Qatar',
            code: 'qa'
          },
          company: 'Centerline Engineering',
          date: '2019-02-04',
          status: 'unqualified',
          verified: true,
          activity: 41,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 93316
        },
        {
          id: 1424,
          name: 'Rikki Nayar',
          country: {
            name: 'Norway',
            code: 'no'
          },
          company: 'Targan & Kievit Pa',
          date: '2017-03-06',
          status: 'negotiation',
          verified: true,
          activity: 47,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 23786
        },
        {
          id: 1425,
          name: 'Elke Sengbusch',
          country: {
            name: 'Canada',
            code: 'ca'
          },
          company: 'Riley Riper Hollin & Colagreco',
          date: '2019-05-28',
          status: 'renewal',
          verified: true,
          activity: 76,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 98367
        },
        {
          id: 1426,
          name: 'Hoa Sarao',
          country: {
            name: 'Sweden',
            code: 'se'
          },
          company: 'Kaplan, Joel S Esq',
          date: '2020-08-13',
          status: 'negotiation',
          verified: true,
          activity: 0,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 68683
        },
        {
          id: 1427,
          name: 'Trinidad Mcrae',
          country: {
            name: 'Burkina Faso',
            code: 'bf'
          },
          company: 'Water Office',
          date: '2020-12-26',
          status: 'renewal',
          verified: true,
          activity: 76,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 85602
        },
        {
          id: 1428,
          name: 'Mari Lueckenbach',
          country: {
            name: 'Cuba',
            code: 'cu'
          },
          company: 'Westbrooks, Nelson E Jr',
          date: '2017-10-27',
          status: 'new',
          verified: true,
          activity: 34,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 14402
        },
        {
          id: 1429,
          name: 'Selma Husser',
          country: {
            name: 'Morocco',
            code: 'ma'
          },
          company: 'Armon Communications',
          date: '2018-02-16',
          status: 'proposal',
          verified: true,
          activity: 84,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 81608
        },
        {
          id: 1430,
          name: 'Antione Onofrio',
          country: {
            name: 'Taiwan',
            code: 'tw'
          },
          company: 'Jacobs & Gerber Inc',
          date: '2017-11-07',
          status: 'negotiation',
          verified: true,
          activity: 37,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 15573
        },
        {
          id: 1431,
          name: 'Luisa Jurney',
          country: {
            name: 'Canada',
            code: 'ca'
          },
          company: 'Forest Fire Laboratory',
          date: '2018-06-12',
          status: 'unqualified',
          verified: true,
          activity: 96,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 50121
        },
        {
          id: 1432,
          name: 'Clorinda Heimann',
          country: {
            name: 'Israel',
            code: 'il'
          },
          company: 'Haughey, Charles Jr',
          date: '2016-11-03',
          status: 'proposal',
          verified: true,
          activity: 63,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 93911
        },
        {
          id: 1433,
          name: 'Dick Wenzinger',
          country: {
            name: 'Mexico',
            code: 'mx'
          },
          company: 'Wheaton Plastic Products',
          date: '2017-04-05',
          status: 'negotiation',
          verified: true,
          activity: 92,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 66383
        },
        {
          id: 1434,
          name: 'Ahmed Angalich',
          country: {
            name: 'Australia',
            code: 'au'
          },
          company: 'Reese Plastics',
          date: '2018-11-20',
          status: 'unqualified',
          verified: true,
          activity: 83,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 89183
        },
        {
          id: 1435,
          name: 'Iluminada Ohms',
          country: {
            name: 'Croatia',
            code: 'hr'
          },
          company: 'Nazette Marner Good Wendt',
          date: '2015-08-18',
          status: 'proposal',
          verified: true,
          activity: 66,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 9752
        },
        {
          id: 1436,
          name: 'Joanna Leinenbach',
          country: {
            name: 'Thailand',
            code: 'th'
          },
          company: 'Levinson Axelrod Wheaton',
          date: '2016-02-11',
          status: 'proposal',
          verified: true,
          activity: 64,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 2623
        },
        {
          id: 1437,
          name: 'Caprice Suell',
          country: {
            name: 'Algeria',
            code: 'dz'
          },
          company: 'Egnor, W Dan Esq',
          date: '2018-07-09',
          status: 'unqualified',
          verified: true,
          activity: 99,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 87620
        },
        {
          id: 1438,
          name: 'Stephane Myricks',
          country: {
            name: 'Honduras',
            code: 'hn'
          },
          company: 'Portland Central Thriftlodge',
          date: '2018-05-28',
          status: 'renewal',
          verified: true,
          activity: 51,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 14625
        },
        {
          id: 1439,
          name: 'Quentin Swayze',
          country: {
            name: 'Tunisia',
            code: 'tn'
          },
          company: 'Ulbrich Trucking',
          date: '2019-02-11',
          status: 'negotiation',
          verified: true,
          activity: 37,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 39339
        },
        {
          id: 1440,
          name: 'Annmarie Castros',
          country: {
            name: 'Jamaica',
            code: 'jm'
          },
          company: 'Tipiak Inc',
          date: '2015-08-24',
          status: 'qualified',
          verified: true,
          activity: 46,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 77704
        },
        {
          id: 1441,
          name: 'Shonda Greenbush',
          country: {
            name: 'Costa Rica',
            code: 'cr'
          },
          company: 'Saint George Well Drilling',
          date: '2018-06-01',
          status: 'negotiation',
          verified: true,
          activity: 16,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 75326
        },
        {
          id: 1442,
          name: 'Cecil Lapage',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'Hawkes, Douglas D',
          date: '2018-11-09',
          status: 'qualified',
          verified: true,
          activity: 43,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 71364
        },
        {
          id: 1443,
          name: 'Jeanice Claucherty',
          country: {
            name: 'Lithuania',
            code: 'lt'
          },
          company: 'Accurel Systems Intrntl Corp',
          date: '2016-06-02',
          status: 'qualified',
          verified: true,
          activity: 48,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 73360
        },
        {
          id: 1444,
          name: 'Josphine Villanueva',
          country: {
            name: 'Japan',
            code: 'jp'
          },
          company: 'Santa Cruz Community Internet',
          date: '2019-11-08',
          status: 'unqualified',
          verified: true,
          activity: 39,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 59442
        },
        {
          id: 1445,
          name: 'Daniel Perruzza',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'Gersh & Danielson',
          date: '2017-09-24',
          status: 'renewal',
          verified: true,
          activity: 86,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 84338
        },
        {
          id: 1446,
          name: 'Cassi Wildfong',
          country: {
            name: 'Tunisia',
            code: 'tn'
          },
          company: 'Cobb, James O Esq',
          date: '2015-09-08',
          status: 'qualified',
          verified: true,
          activity: 85,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 72545
        },
        {
          id: 1447,
          name: 'Britt Galam',
          country: {
            name: 'Poland',
            code: 'pl'
          },
          company: 'Wheatley Trucking Company',
          date: '2020-10-26',
          status: 'new',
          verified: true,
          activity: 69,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 53307
        },
        {
          id: 1448,
          name: 'Adell Lipkin',
          country: {
            name: 'Saudi Arabia',
            code: 'sa'
          },
          company: 'Systems Graph Inc Ab Dick Dlr',
          date: '2017-05-09',
          status: 'unqualified',
          verified: true,
          activity: 92,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 12178
        },
        {
          id: 1449,
          name: 'Jacqueline Rowling',
          country: {
            name: 'Qatar',
            code: 'qa'
          },
          company: 'John Hancock Mutl Life Ins Co',
          date: '2019-06-28',
          status: 'negotiation',
          verified: true,
          activity: 79,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 35576
        },
        {
          id: 1450,
          name: 'Lonny Weglarz',
          country: {
            name: 'Chile',
            code: 'cl'
          },
          company: 'History Division Of State',
          date: '2016-08-12',
          status: 'new',
          verified: true,
          activity: 49,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 20507
        },
        {
          id: 1451,
          name: 'Lonna Diestel',
          country: {
            name: 'Philippines',
            code: 'ph'
          },
          company: 'Dimmock, Thomas J Esq',
          date: '2017-02-10',
          status: 'proposal',
          verified: true,
          activity: 9,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 56415
        },
        {
          id: 1452,
          name: 'Cristal Samara',
          country: {
            name: 'Australia',
            code: 'au'
          },
          company: 'Intermed Inc',
          date: '2016-08-17',
          status: 'qualified',
          verified: true,
          activity: 99,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 33427
        },
        {
          id: 1453,
          name: 'Kenneth Grenet',
          country: {
            name: 'Croatia',
            code: 'hr'
          },
          company: 'Bank Of New York',
          date: '2020-07-24',
          status: 'negotiation',
          verified: true,
          activity: 78,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 44004
        },
        {
          id: 1454,
          name: 'Elli Mclaird',
          country: {
            name: 'Switzerland',
            code: 'ch'
          },
          company: 'Sportmaster Intrnatl',
          date: '2017-06-10',
          status: 'qualified',
          verified: true,
          activity: 61,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 37227
        },
        {
          id: 1455,
          name: 'Alline Jeanty',
          country: {
            name: 'Denmark',
            code: 'dk'
          },
          company: 'W W John Holden Inc',
          date: '2015-12-08',
          status: 'renewal',
          verified: true,
          activity: 74,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 44560
        },
        {
          id: 1456,
          name: 'Sharika Eanes',
          country: {
            name: 'Chile',
            code: 'cl'
          },
          company: 'Maccani & Delp',
          date: '2018-08-16',
          status: 'qualified',
          verified: true,
          activity: 14,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 50922
        },
        {
          id: 1457,
          name: 'Nu Mcnease',
          country: {
            name: 'Pakistan',
            code: 'pk'
          },
          company: 'Amazonia Film Project',
          date: '2018-07-03',
          status: 'negotiation',
          verified: true,
          activity: 47,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 61262
        },
        {
          id: 1458,
          name: 'Daniela Comnick',
          country: {
            name: 'Tunisia',
            code: 'tn'
          },
          company: 'Water & Sewer Department',
          date: '2017-07-07',
          status: 'negotiation',
          verified: true,
          activity: 31,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 13459
        },
        {
          id: 1459,
          name: 'Cecilia Colaizzo',
          country: {
            name: 'Costa Rica',
            code: 'cr'
          },
          company: 'Switchcraft Inc',
          date: '2019-06-08',
          status: 'proposal',
          verified: true,
          activity: 21,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 52299
        },
        {
          id: 1460,
          name: 'Leslie Threets',
          country: {
            name: 'Sweden',
            code: 'se'
          },
          company: 'C W D C Metal Fabricators',
          date: '2016-11-26',
          status: 'unqualified',
          verified: true,
          activity: 50,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 61040
        },
        {
          id: 1461,
          name: 'Nan Koppinger',
          country: {
            name: 'Norway',
            code: 'no'
          },
          company: 'Shimotani, Grace T',
          date: '2020-11-15',
          status: 'proposal',
          verified: true,
          activity: 94,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 91297
        },
        {
          id: 1462,
          name: 'Izetta Dewar',
          country: {
            name: 'Cuba',
            code: 'cu'
          },
          company: 'Lisatoni, Jean Esq',
          date: '2019-11-22',
          status: 'renewal',
          verified: true,
          activity: 41,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 39947
        },
        {
          id: 1463,
          name: 'Tegan Arceo',
          country: {
            name: 'Iceland',
            code: 'is'
          },
          company: 'Ceramic Tile Sales Inc',
          date: '2016-08-07',
          status: 'new',
          verified: true,
          activity: 79,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 78224
        },
        {
          id: 1464,
          name: 'Ruthann Keener',
          country: {
            name: 'Morocco',
            code: 'ma'
          },
          company: 'Maiden Craft Inc',
          date: '2019-04-25',
          status: 'negotiation',
          verified: true,
          activity: 43,
          representative: {
            name: 'Elwin Sharvill',
            image: 'elwinsharvill.png'
          },
          balance: 22727
        },
        {
          id: 1465,
          name: 'Joni Breland',
          country: {
            name: 'Uruguay',
            code: 'uy'
          },
          company: 'Carriage House Cllsn Rpr Inc',
          date: '2018-07-20',
          status: 'new',
          verified: true,
          activity: 18,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 70650
        },
        {
          id: 1466,
          name: 'Vi Rentfro',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'Video Workshop',
          date: '2018-10-01',
          status: 'negotiation',
          verified: true,
          activity: 99,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 45956
        },
        {
          id: 1467,
          name: 'Colette Kardas',
          country: {
            name: 'Honduras',
            code: 'hn'
          },
          company: 'Fresno Tile Center Inc',
          date: '2017-08-22',
          status: 'new',
          verified: true,
          activity: 14,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 72627
        },
        {
          id: 1468,
          name: 'Malcolm Tromblay',
          country: {
            name: 'Uruguay',
            code: 'uy'
          },
          company: 'Versatile Sash & Woodwork',
          date: '2019-11-25',
          status: 'renewal',
          verified: true,
          activity: 23,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 80176
        },
        {
          id: 1469,
          name: 'Ryan Harnos',
          country: {
            name: 'Paraguay',
            code: 'py'
          },
          company: 'Warner Electric Brk & Cltch Co',
          date: '2019-02-12',
          status: 'qualified',
          verified: true,
          activity: 71,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 97227
        },
        {
          id: 1470,
          name: 'Jess Chaffins',
          country: {
            name: 'Belarus',
            code: 'by'
          },
          company: 'New York Public Library',
          date: '2017-02-12',
          status: 'qualified',
          verified: true,
          activity: 91,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 63121
        },
        {
          id: 1471,
          name: 'Sharen Bourbon',
          country: {
            name: 'Saudi Arabia',
            code: 'sa'
          },
          company: 'Mccaleb, John A Esq',
          date: '2017-06-13',
          status: 'renewal',
          verified: true,
          activity: 35,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 44726
        },
        {
          id: 1472,
          name: 'Nickolas Juvera',
          country: {
            name: 'Finland',
            code: 'fi'
          },
          company: 'United Oil Co Inc',
          date: '2019-01-06',
          status: 'negotiation',
          verified: true,
          activity: 14,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 70748
        },
        {
          id: 1473,
          name: 'Gary Nunlee',
          country: {
            name: 'Turkey',
            code: 'tr'
          },
          company: 'Irving Foot Center',
          date: '2015-07-22',
          status: 'renewal',
          verified: true,
          activity: 72,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 71061
        },
        {
          id: 1474,
          name: 'Diane Devreese',
          country: {
            name: 'Bolivia',
            code: 'bo'
          },
          company: 'Acme Supply Co',
          date: '2018-05-13',
          status: 'renewal',
          verified: true,
          activity: 78,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 7486
        },
        {
          id: 1475,
          name: 'Roslyn Chavous',
          country: {
            name: 'Israel',
            code: 'il'
          },
          company: 'Mcrae, James L',
          date: '2018-03-20',
          status: 'proposal',
          verified: true,
          activity: 45,
          representative: {
            name: 'Bernardo Dominic',
            image: 'bernardodominic.png'
          },
          balance: 58495
        },
        {
          id: 1476,
          name: 'Glory Schieler',
          country: {
            name: 'Italy',
            code: 'it'
          },
          company: 'Mcgraths Seafood',
          date: '2017-05-13',
          status: 'proposal',
          verified: true,
          activity: 34,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 96252
        },
        {
          id: 1477,
          name: 'Rasheeda Sayaphon',
          country: {
            name: 'Latvia',
            code: 'lv'
          },
          company: 'Kummerer, J Michael Esq',
          date: '2017-02-21',
          status: 'proposal',
          verified: true,
          activity: 12,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 71454
        },
        {
          id: 1478,
          name: 'Alpha Palaia',
          country: {
            name: 'Costa Rica',
            code: 'cr'
          },
          company: 'Stoffer, James M Jr',
          date: '2017-07-21',
          status: 'renewal',
          verified: true,
          activity: 4,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 81894
        },
        {
          id: 1479,
          name: 'Refugia Jacobos',
          country: {
            name: 'China',
            code: 'cn'
          },
          company: 'North Central Fl Sfty Cncl',
          date: '2020-11-25',
          status: 'negotiation',
          verified: true,
          activity: 18,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 31601
        },
        {
          id: 1480,
          name: 'Shawnda Yori',
          country: {
            name: 'Luxembourg',
            code: 'lu'
          },
          company: 'Fiorucci Foods Usa Inc',
          date: '2018-05-09',
          status: 'unqualified',
          verified: true,
          activity: 11,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 12090
        },
        {
          id: 1481,
          name: 'Mona Delasancha',
          country: {
            name: 'Hong Kong',
            code: 'hk'
          },
          company: 'Sign All',
          date: '2017-10-06',
          status: 'renewal',
          verified: true,
          activity: 61,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 24073
        },
        {
          id: 1482,
          name: 'Gilma Liukko',
          country: {
            name: 'Morocco',
            code: 'ma'
          },
          company: 'Sammys Steak Den',
          date: '2018-10-06',
          status: 'unqualified',
          verified: true,
          activity: 34,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 35129
        },
        {
          id: 1483,
          name: 'Janey Gabisi',
          country: {
            name: 'Israel',
            code: 'il'
          },
          company: 'Dobscha, Stephen F Esq',
          date: '2019-05-22',
          status: 'unqualified',
          verified: true,
          activity: 38,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 25518
        },
        {
          id: 1484,
          name: 'Lili Paskin',
          country: {
            name: 'Costa Rica',
            code: 'cr'
          },
          company: 'Morgan Custom Homes',
          date: '2018-06-11',
          status: 'unqualified',
          verified: true,
          activity: 69,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 7300
        },
        {
          id: 1485,
          name: 'Loren Asar',
          country: {
            name: 'Italy',
            code: 'it'
          },
          company: 'Olsen Payne & Company',
          date: '2017-12-11',
          status: 'unqualified',
          verified: true,
          activity: 16,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 82949
        },
        {
          id: 1486,
          name: 'Dorothy Chesterfield',
          country: {
            name: 'Spain',
            code: 'es'
          },
          company: 'Cowan & Kelly',
          date: '2015-08-11',
          status: 'renewal',
          verified: true,
          activity: 31,
          representative: {
            name: 'Anna Fali',
            image: 'annafali.png'
          },
          balance: 25044
        },
        {
          id: 1487,
          name: 'Gail Similton',
          country: {
            name: 'Malaysia',
            code: 'my'
          },
          company: 'Johnson, Wes Esq',
          date: '2016-08-10',
          status: 'qualified',
          verified: true,
          activity: 63,
          representative: {
            name: 'Xuxue Feng',
            image: 'xuxuefeng.png'
          },
          balance: 97553
        },
        {
          id: 1488,
          name: 'Catalina Tillotson',
          country: {
            name: 'Pakistan',
            code: 'pk'
          },
          company: 'Icn Pharmaceuticals Inc',
          date: '2018-08-04',
          status: 'negotiation',
          verified: true,
          activity: 13,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 38294
        },
        {
          id: 1489,
          name: 'Lawrence Lorens',
          country: {
            name: 'Egypt',
            code: 'eg'
          },
          company: 'New England Sec Equip Co Inc',
          date: '2020-04-15',
          status: 'negotiation',
          verified: true,
          activity: 82,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 56617
        },
        {
          id: 1490,
          name: 'Carlee Boulter',
          country: {
            name: 'Vietnam',
            code: 'vn'
          },
          company: 'Tippett, Troy M Ii',
          date: '2016-09-22',
          status: 'renewal',
          verified: true,
          activity: 71,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 76817
        },
        {
          id: 1491,
          name: 'Thaddeus Ankeny',
          country: {
            name: 'Qatar',
            code: 'qa'
          },
          company: 'Atc Contracting',
          date: '2015-03-08',
          status: 'unqualified',
          verified: true,
          activity: 78,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 80787
        },
        {
          id: 1492,
          name: 'Jovita Oles',
          country: {
            name: 'Sweden',
            code: 'se'
          },
          company: 'Pagano, Philip G Esq',
          date: '2019-07-18',
          status: 'negotiation',
          verified: true,
          activity: 42,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 4158
        },
        {
          id: 1493,
          name: 'Alesia Hixenbaugh',
          country: {
            name: 'Thailand',
            code: 'th'
          },
          company: 'Kwikprint',
          date: '2017-07-24',
          status: 'negotiation',
          verified: false,
          activity: 23,
          representative: {
            name: 'Ivan Magalhaes',
            image: 'ivanmagalhaes.png'
          },
          balance: 66993
        },
        {
          id: 1494,
          name: 'Lai Harabedian',
          country: {
            name: 'Serbia',
            code: 'rs'
          },
          company: 'Buergi & Madden Scale',
          date: '2018-11-11',
          status: 'new',
          verified: true,
          activity: 28,
          representative: {
            name: 'Asiya Javayant',
            image: 'asiyajavayant.png'
          },
          balance: 53330
        },
        {
          id: 1495,
          name: 'Brittni Gillaspie',
          country: {
            name: 'Senegal',
            code: 'sn'
          },
          company: 'Inner Label',
          date: '2019-11-23',
          status: 'renewal',
          verified: true,
          activity: 14,
          representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
          },
          balance: 72342
        },
        {
          id: 1496,
          name: 'Raylene Kampa',
          country: {
            name: 'Belgium',
            code: 'be'
          },
          company: 'Hermar Inc',
          date: '2020-04-22',
          status: 'unqualified',
          verified: true,
          activity: 65,
          representative: {
            name: 'Stephen Shaw',
            image: 'stephenshaw.png'
          },
          balance: 53660
        },
        {
          id: 1497,
          name: 'Flo Bookamer',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'Simonton Howe & Schneider Pc',
          date: '2020-08-10',
          status: 'unqualified',
          verified: true,
          activity: 30,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 44528
        },
        {
          id: 1498,
          name: 'Jani Biddy',
          country: {
            name: 'Switzerland',
            code: 'ch'
          },
          company: 'Warehouse Office & Paper Prod',
          date: '2019-11-07',
          status: 'negotiation',
          verified: false,
          activity: 20,
          representative: {
            name: 'Onyama Limba',
            image: 'onyamalimba.png'
          },
          balance: 69613
        },
        {
          id: 1499,
          name: 'Chauncey Motley',
          country: {
            name: 'Argentina',
            code: 'ar'
          },
          company: 'Affiliated With Travelodge',
          date: '2019-04-23',
          status: 'renewal',
          verified: true,
          activity: 42,
          representative: {
            name: 'Amy Elsner',
            image: 'amyelsner.png'
          },
          balance: 88090
        }
      ],
      "status": "success"
    }
  }


  getLiquidityProfileDashboardData() {
    return {
      "data": {
        "mmf": [
          {
            "country": "HongKong",
            "fiveD": 0,
            "fiveDThresholdCheck": "HIGH", // HIGH , MEDIUM
            "fiveDThresholdCond": "<=12%",
            "monitoring": "Fail",
            "oneD": 0,
            "oneDThresholdCheck": "HIGH", // HIGH , MEDIUM
            "oneDThresholdCond": "<=12%",
            "tradarCode": "HK-HST-2I",
            "twoD": 0,
            "twoDThresholdCheck": "LOW", // HIGH , MEDIUM
            "twoDThresholdCond": "<=12%"
          },
          {
            "country": "Signapoor",
            "fiveD": 0,
            "fiveDThresholdCheck": "LOW", // HIGH , MEDIUM
            "fiveDThresholdCond": "<=12%",
            "monitoring": "Fail",
            "oneD": 0,
            "oneDThresholdCheck": "HIGH", // HIGH , MEDIUM
            "oneDThresholdCond": "<=12%",
            "tradarCode": "HK-HST",
            "twoD": 0,
            "twoDThresholdCheck": "HIGH", // HIGH , MEDIUM
            "twoDThresholdCond": "<=12%"
          },
          {
            "country": "HongKong",
            "fiveD": 0,
            "fiveDThresholdCheck": "LOW", // HIGH , MEDIUM
            "fiveDThresholdCond": "<=12%",
            "monitoring": "Fail",
            "oneD": 0,
            "oneDThresholdCheck": "MEDIUM", // HIGH , MEDIUM
            "oneDThresholdCond": "<=12%",
            "tradarCode": "HK-HST-2L",
            "twoD": 0,
            "twoDThresholdCheck": "MEDIUM", // HIGH , MEDIUM
            "twoDThresholdCond": "<=12%"
          },
          {
            "country": "HongKong",
            "fiveD": 0,
            "fiveDThresholdCheck": "LOW", // HIGH , MEDIUM
            "fiveDThresholdCond": "<=12%",
            "monitoring": "Fail",
            "oneD": 0,
            "oneDThresholdCheck": "HIGH", // HIGH , MEDIUM
            "oneDThresholdCond": "<=12%",
            "tradarCode": "HK-CUSF",
            "twoD": 0,
            "twoDThresholdCheck": "MEDIUM", // HIGH , MEDIUM
            "twoDThresholdCond": "<=12%"
          },
          {
            "country": "malasia",
            "fiveD": 0,
            "fiveDThresholdCheck": "MEDIUM", // HIGH , MEDIUM
            "fiveDThresholdCond": "<=12%",
            "monitoring": "Fail",
            "oneD": 0,
            "oneDThresholdCheck": "LOW", // HIGH , MEDIUM
            "oneDThresholdCond": "<=12%",
            "tradarCode": "HK-HKD",
            "twoD": 0,
            "twoDThresholdCheck": "MEDIUM", // HIGH , MEDIUM
            "twoDThresholdCond": "<=12%"
          },
          {
            "country": "Signapoor",
            "fiveD": 0,
            "fiveDThresholdCheck": "LOW", // HIGH , MEDIUM
            "fiveDThresholdCond": "<=12%",
            "monitoring": "Fail",
            "oneD": 0,
            "oneDThresholdCheck": "HIGH", // HIGH , MEDIUM
            "oneDThresholdCond": "<=12%",
            "tradarCode": "CO_B5",
            "twoD": 0,
            "twoDThresholdCheck": "LOW", // HIGH , MEDIUM
            "twoDThresholdCond": "<=12%"
          },
          {
            "country": "malasia",
            "fiveD": 0,
            "fiveDThresholdCheck": "HIGH", // HIGH , MEDIUM
            "fiveDThresholdCond": "<=12%",
            "monitoring": "Fail",
            "oneD": 0,
            "oneDThresholdCheck": "MEDIUM", // HIGH , MEDIUM
            "oneDThresholdCond": "<=12%",
            "tradarCode": "CO-USTB",
            "twoD": 0,
            "twoDThresholdCheck": "LOW", // HIGH , MEDIUM
            "twoDThresholdCond": "<=12%"
          },
        ],
        "non_mmf": [
          {
            "highLiquidity": "98.33%",
            "highThresholdCheck": "HIGH",  // LOW , MEDIUM
            "highThresholdCond": ">=75%",
            "illiquid": "0%",
            "illiquidThresholdCheck": "LOW", // LOW , MEDIUM
            "illiquidThresholdCond": "<=15%",
            "lowLiquidity": "0%",
            "lowThresholdCond": ">=16%",
            "lowThresholdCheck": "MEDIUM",
            "mediumLiquidity": "0%",
            "mediumThresholdCond": ">=17%",
            "mediumThresholdCheck": "MEDIUM",
            "monitoring": "Fail", // Pass
            "securityClassification": "EQTY", // BOND
            "tradarCode": "SG-ALC"
          },
          {
            "highLiquidity": "98.33%",
            "highThresholdCheck": "MEDIUM",  // LOW , MEDIUM
            "highThresholdCond": ">=75%",
            "illiquid": "0%",
            "illiquidThresholdCheck": "HIGH", // LOW , MEDIUM
            "illiquidThresholdCond": "<=15%",
            "lowLiquidity": "0%",
            "lowThresholdCond": ">=16%",
            "lowThresholdCheck": "LOW",
            "mediumLiquidity": "0%",
            "mediumThresholdCond": ">=17%",
            "mediumThresholdCheck": "MEDIUM",
            "monitoring": "Fail", // Pass
            "securityClassification": "BOND", // BOND
            "tradarCode": "CO_A50F"
          },
          {
            "highLiquidity": "98.33%",
            "highThresholdCheck": "HIGH",  // LOW , MEDIUM
            "highThresholdCond": ">=75%",
            "illiquid": "0%",
            "illiquidThresholdCheck": "HIGH", // LOW , MEDIUM
            "illiquidThresholdCond": "<=15%",
            "lowLiquidity": "0%",
            "lowThresholdCond": ">=16%",
            "lowThresholdCheck": "HIGH",
            "mediumLiquidity": "0%",
            "mediumThresholdCond": ">=17%",
            "mediumThresholdCheck": "MEDIUM",
            "monitoring": "Fail", // Pass
            "securityClassification": "BOND", // BOND
            "tradarCode": "CO-B5"
          },
          {
            "highLiquidity": "98.33%",
            "highThresholdCheck": "HIGH",  // LOW , MEDIUM
            "highThresholdCond": ">=75%",
            "illiquid": "0%",
            "illiquidThresholdCheck": "MEDIUM", // LOW , MEDIUM
            "illiquidThresholdCond": "<=15%",
            "lowLiquidity": "0%",
            "lowThresholdCond": ">=16%",
            "lowThresholdCheck": "HIGH",
            "mediumLiquidity": "0%",
            "mediumThresholdCond": ">=17%",
            "mediumThresholdCheck": "LOW",
            "monitoring": "Fail", // Pass
            "securityClassification": "EQTY", // BOND
            "tradarCode": "CO-USTB"
          },
          {
            "highLiquidity": "98.33%",
            "highThresholdCheck": "HIGH",  // LOW , MEDIUM
            "highThresholdCond": ">=75%",
            "illiquid": "0%",
            "illiquidThresholdCheck": "MEDIUM", // LOW , MEDIUM
            "illiquidThresholdCond": "<=15%",
            "lowLiquidity": "0%",
            "lowThresholdCond": ">=16%",
            "lowThresholdCheck": "HIGH",
            "mediumLiquidity": "0%",
            "mediumThresholdCond": ">=17%",
            "mediumThresholdCheck": "LOW",
            "monitoring": "Fail", // Pass
            "securityClassification": "BOND", // BOND
            "tradarCode": "CO-USTBV"
          },
          {
            "highLiquidity": "98.33%",
            "highThresholdCheck": "HIGH",  // LOW , MEDIUM
            "highThresholdCond": ">=75%",
            "illiquid": "0%",
            "illiquidThresholdCheck": "MEDIUM", // LOW , MEDIUM
            "illiquidThresholdCond": "<=15%",
            "lowLiquidity": "0%",
            "lowThresholdCond": ">=16%",
            "lowThresholdCheck": "HIGH",
            "mediumLiquidity": "0%",
            "mediumThresholdCond": ">=17%",
            "mediumThresholdCheck": "LOW",
            "monitoring": "Fail", // Pass
            "securityClassification": "BOND", // BOND
            "tradarCode": "CO-USTBVN"
          },
          {
            "highLiquidity": "98.33%",
            "highThresholdCheck": "HIGH",  // LOW , MEDIUM
            "highThresholdCond": ">=75%",
            "illiquid": "0%",
            "illiquidThresholdCheck": "MEDIUM", // LOW , MEDIUM
            "illiquidThresholdCond": "<=15%",
            "lowLiquidity": "0%",
            "lowThresholdCond": ">=16%",
            "lowThresholdCheck": "HIGH",
            "mediumLiquidity": "0%",
            "mediumThresholdCond": ">=17%",
            "mediumThresholdCheck": "LOW",
            "monitoring": "Fail", // Pass
            "securityClassification": "EQTY", // BOND
            "tradarCode": "CO-USTBVNM"
          },
          {
            "highLiquidity": "98.33%",
            "highThresholdCheck": "HIGH",  // LOW , MEDIUM
            "highThresholdCond": ">=75%",
            "illiquid": "0%",
            "illiquidThresholdCheck": "MEDIUM", // LOW , MEDIUM
            "illiquidThresholdCond": "<=15%",
            "lowLiquidity": "0%",
            "lowThresholdCond": ">=16%",
            "lowThresholdCheck": "HIGH",
            "mediumLiquidity": "0%",
            "mediumThresholdCond": ">=17%",
            "mediumThresholdCheck": "LOW",
            "monitoring": "Fail", // Pass
            "securityClassification": "EQTY", // BOND
            "tradarCode": "CO-USTBVNMSA"
          },
        ]
      },
      "status": "success"
    }
  }


  getLiquidityData() {
    return {
      "data": {
        "investorAggr": {
          "SecurityClassification": "EQTY_LQA",
          "above252DaysToTrade": "0%",
          "accumlative1": "0%",
          "accumlative130": "0%",
          "accumlative2": "0%",
          "accumlative22": "0%",
          "accumlative252": "0%",
          "accumlative3": "0%",
          "accumlative5": "0%",
          "accumlative65": "0%",
          "accumlativeAbove252": "0%",
          "cash": "0%",
          "dayToTrade1": "0%",
          "daysToTrade130": "14.41%",
          "daysToTrade2": "0%",
          "daysToTrade22": "0%",
          "daysToTrade252": "58.55%",
          "daysToTrade3": "0.05%",
          "daysToTrade5": "0%",
          "daysToTrade65": "27%"
        },
        "investorSFC": {
          "classificationType": "INVESTOR",
          "tier0": "0%",
          "tier1": "0.05%",
          "tier2": "7.93%",
          "tier3": "22.5%",
          "tier4": "35.79%",
          "tier5": "33.73%",
          "tier6": "0%"
        },
        "liquidityCategory": {
          "highLiquidity": 0,
          "highLiquidityMonitoring": "Fail",
          "highLiquidityThreshold": 70,
          "illiquid": 99.9533,
          "illiquidMonitoring": "Fail",
          "illiquidThreshold": 0,
          "lowLiquidity": 0,
          "lowLiquidityThreshold": 0,
          "mediumLiquidity": 0,
          "mediumLiquidityThreshold": 0,
          "securityClassification": "EQTY_LQA"
        },
        "liquidityCost": {
          "liquidationCost": 0,
          "monitoring": "",
          "pctOfCurrentAum": 0,
          "securityClassification": "",
          "threshold": 0,
          "thresholdCheck": ""
        },
        "portfolioAggr": {
          "above252DaysToTrade": "0%",
          "accumlative1": "0.05%",
          "accumlative130": "41.45%",
          "accumlative2": "0.05%",
          "accumlative22": "0.05%",
          "accumlative252": "85.59%",
          "accumlative3": "0.05%",
          "accumlative5": "0.05%",
          "accumlative65": "27.04%",
          "accumlativeAbove252": "85.59%",
          "cash": "0.05%",
          "dayToTrade1": "0%",
          "daysToTrade130": "14.41%",
          "daysToTrade2": "0%",
          "daysToTrade22": "0%",
          "daysToTrade252": "58.55%",
          "daysToTrade3": "0%",
          "daysToTrade5": "0%",
          "daysToTrade65": "27%",
          "securityClassification": "EQTY_LQA"
        },
        "portfolioSFC": {
          "classificationType": "PORTFOLIO",
          "tier0": "0.05%",
          "tier1": "0%",
          "tier2": "7.93%",
          "tier3": "22.5%",
          "tier4": "35.79%",
          "tier5": "33.73%",
          "tier6": "0%"
        },
        "stockList": {
          "bond": [],
          "eqty": [],
          "eqty_lqa": [
            {
              "above252DaysToTrade": "97.27",
              "bidAsk": 65791.971610512,
              "bidAskBps": 6.3224,
              "dayToTrade1": "0",
              "daysToTrade130": "0.49",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.08",
              "daysToTrade252": "0.94",
              "daysToTrade3": "0.01",
              "daysToTrade5": "0.02",
              "daysToTrade65": "0.24",
              "edv": 2235204.2,
              "investorDtt": "252",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 4835955.4989936,
              "liquidationCostBps": 0.023236,
              "liquidationHorizon": 252,
              "pctOfNav": "0.94%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.48901,
              "securityClassification": "EQTY_LQA",
              "securityName": "ZHONGAN ONLINE P\u0026C INSURAN-H",
              "ticker": "6060 HK EQUITY"
            },
            {
              "above252DaysToTrade": "85.83",
              "bidAsk": 344135.9380725,
              "bidAskBps": 9.9207,
              "dayToTrade1": "0.05",
              "daysToTrade130": "3.14",
              "daysToTrade2": "0.1",
              "daysToTrade22": "1.08",
              "daysToTrade252": "3.14",
              "daysToTrade3": "0.15",
              "daysToTrade5": "0.25",
              "daysToTrade65": "3.14",
              "edv": 23316246.4,
              "investorDtt": "63.7",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "liquidationCost": 17900743.847,
              "liquidationCostBps": 0.025802,
              "liquidationHorizon": 63.7,
              "pctOfNav": "3.14%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "priceVal": 1.43119,
              "securityClassification": "EQTY_LQA",
              "securityName": "XPENG INC - CLASS A SHARES",
              "ticker": "9868 HK EQUITY"
            },
            {
              "above252DaysToTrade": "77.58",
              "bidAsk": 192625.3127544992,
              "bidAskBps": 2.2556,
              "dayToTrade1": "0.03",
              "daysToTrade130": "3.98",
              "daysToTrade2": "0.06",
              "daysToTrade22": "0.67",
              "daysToTrade252": "7.72",
              "daysToTrade3": "0.09",
              "daysToTrade5": "0.15",
              "daysToTrade65": "1.99",
              "edv": 65740104.5,
              "investorDtt": "252",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 22323220.7634448,
              "liquidationCostBps": 0.01307,
              "liquidationHorizon": 252,
              "pctOfNav": "7.72%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.35811,
              "securityClassification": "EQTY_LQA",
              "securityName": "XIAOMI CORP-CLASS B",
              "ticker": "1810 HK EQUITY"
            },
            {
              "above252DaysToTrade": "99.92",
              "bidAsk": 14176.32834816,
              "bidAskBps": 52.8176,
              "dayToTrade1": "0",
              "daysToTrade130": "0.02",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade252": "0.02",
              "daysToTrade3": "0",
              "daysToTrade5": "0",
              "daysToTrade65": "0.01",
              "edv": 31600,
              "investorDtt": "178.2",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 92738.120832,
              "liquidationCostBps": 0.017276,
              "liquidationHorizon": 178.2,
              "pctOfNav": "0.02%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.54462,
              "securityClassification": "EQTY_LQA",
              "securityName": "WEIBO CORP-CLASS A",
              "ticker": "9898 HK EQUITY"
            },
            {
              "above252DaysToTrade": "93.27",
              "bidAsk": 676950.73783929,
              "bidAskBps": 31.6395,
              "dayToTrade1": "0.01",
              "daysToTrade130": "1.63",
              "daysToTrade2": "0.03",
              "daysToTrade22": "0.28",
              "daysToTrade252": "1.93",
              "daysToTrade3": "0.04",
              "daysToTrade5": "0.06",
              "daysToTrade65": "0.81",
              "edv": 1087984.8,
              "investorDtt": "154.3",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 3999720.9479187997,
              "liquidationCostBps": 0.009347,
              "liquidationHorizon": 154.3,
              "pctOfNav": "1.93%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.33159,
              "securityClassification": "EQTY_LQA",
              "securityName": "TRIP.COM GROUP LTD",
              "ticker": "9961 HK EQUITY"
            },
            {
              "above252DaysToTrade": "58.74",
              "bidAsk": 2208218.306763982,
              "bidAskBps": 25.1737,
              "dayToTrade1": "0.29",
              "daysToTrade130": "7.93",
              "daysToTrade2": "0.58",
              "daysToTrade22": "6.37",
              "daysToTrade252": "7.93",
              "daysToTrade3": "0.87",
              "daysToTrade5": "1.45",
              "daysToTrade65": "7.93",
              "edv": 20687675.4,
              "investorDtt": "27.4",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "liquidationCost": 8264908.5697892,
              "liquidationCostBps": 0.004711,
              "liquidationHorizon": 27.4,
              "pctOfNav": "7.93%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "priceVal": 0.3698,
              "securityClassification": "EQTY_LQA",
              "securityName": "TENCENT HOLDINGS LTD",
              "ticker": "700 HK EQUITY"
            },
            {
              "above252DaysToTrade": "89.11",
              "bidAsk": 250316.062987866,
              "bidAskBps": 7.8244,
              "dayToTrade1": "0.02",
              "daysToTrade130": "2.89",
              "daysToTrade2": "0.05",
              "daysToTrade22": "0.5",
              "daysToTrade252": "2.89",
              "daysToTrade3": "0.07",
              "daysToTrade5": "0.11",
              "daysToTrade65": "1.47",
              "edv": 6843712.8,
              "investorDtt": "128",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "liquidationCost": 11582924.4115059,
              "liquidationCostBps": 0.018103,
              "liquidationHorizon": 128,
              "pctOfNav": "2.89%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "priceVal": 0.71798,
              "securityClassification": "EQTY_LQA",
              "securityName": "SUNNY OPTICAL TECH",
              "ticker": "2382 HK EQUITY"
            },
            {
              "above252DaysToTrade": "92.21",
              "bidAsk": 22643.4897307944,
              "bidAskBps": 1.1388,
              "dayToTrade1": "0.02",
              "daysToTrade130": "1.8",
              "daysToTrade2": "0.05",
              "daysToTrade22": "0.54",
              "daysToTrade252": "1.8",
              "daysToTrade3": "0.07",
              "daysToTrade5": "0.12",
              "daysToTrade65": "1.59",
              "edv": 279611465.1,
              "investorDtt": "73.5",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "liquidationCost": 4951821.814679519,
              "liquidationCostBps": 0.012452,
              "liquidationHorizon": 73.5,
              "pctOfNav": "1.8%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "priceVal": 0.54841,
              "securityClassification": "EQTY_LQA",
              "securityName": "SENSETIME GROUP INC-CLASS B",
              "ticker": "20 HK EQUITY"
            },
            {
              "above252DaysToTrade": "86.54",
              "bidAsk": 123033.734818308,
              "bidAskBps": 2.5167,
              "dayToTrade1": "0.02",
              "daysToTrade130": "2.64",
              "daysToTrade2": "0.04",
              "daysToTrade22": "0.45",
              "daysToTrade252": "4.42",
              "daysToTrade3": "0.06",
              "daysToTrade5": "0.1",
              "daysToTrade65": "1.32",
              "edv": 23501649.9,
              "investorDtt": "217.8",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 8478949.007385598,
              "liquidationCostBps": 0.008672,
              "liquidationHorizon": 217.8,
              "pctOfNav": "4.42%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.25574,
              "securityClassification": "EQTY_LQA",
              "securityName": "SEMICONDUCTOR MANUFACTURING",
              "ticker": "981 HK EQUITY"
            },
            {
              "above252DaysToTrade": "98.21",
              "bidAsk": 37425.4121044104,
              "bidAskBps": 5.6049,
              "dayToTrade1": "0",
              "daysToTrade130": "0.33",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.06",
              "daysToTrade252": "0.6",
              "daysToTrade3": "0.01",
              "daysToTrade5": "0.01",
              "daysToTrade65": "0.17",
              "edv": 3206387.8,
              "investorDtt": "235.2",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 1392076.5607820798,
              "liquidationCostBps": 0.010424,
              "liquidationHorizon": 235.2,
              "pctOfNav": "0.6%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.28463,
              "securityClassification": "EQTY_LQA",
              "securityName": "PING AN HEALTHCARE AND TECHN",
              "ticker": "1833 HK EQUITY"
            },
            {
              "above252DaysToTrade": "98.99",
              "bidAsk": 37739.6474947285,
              "bidAskBps": 17.2658,
              "dayToTrade1": "0.01",
              "daysToTrade130": "0.2",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.14",
              "daysToTrade252": "0.2",
              "daysToTrade3": "0.02",
              "daysToTrade5": "0.03",
              "daysToTrade65": "0.2",
              "edv": 2053696.8,
              "investorDtt": "30.1",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "liquidationCost": 481969.68994125,
              "liquidationCostBps": 0.011025,
              "liquidationHorizon": 30.1,
              "pctOfNav": "0.2%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "priceVal": 0.84203,
              "securityClassification": "EQTY_LQA",
              "securityName": "NIO INC-CLASS A",
              "ticker": "9866 HK EQUITY"
            },
            {
              "above252DaysToTrade": "81.64",
              "bidAsk": 944698.7325083,
              "bidAskBps": 15.598,
              "dayToTrade1": "0.03",
              "daysToTrade130": "4.23",
              "daysToTrade2": "0.07",
              "daysToTrade22": "0.72",
              "daysToTrade252": "5.47",
              "daysToTrade3": "0.1",
              "daysToTrade5": "0.16",
              "daysToTrade65": "2.11",
              "edv": 5037725.6,
              "investorDtt": "168.3",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 10899345.037966002,
              "liquidationCostBps": 0.008998,
              "liquidationHorizon": 168.3,
              "pctOfNav": "5.47%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.3073,
              "securityClassification": "EQTY_LQA",
              "securityName": "NETEASE INC",
              "ticker": "9999 HK EQUITY"
            },
            {
              "above252DaysToTrade": "64.02",
              "bidAsk": 1158476.426491895,
              "bidAskBps": 13.181,
              "dayToTrade1": "0.13",
              "daysToTrade130": "7.94",
              "daysToTrade2": "0.25",
              "daysToTrade22": "2.8",
              "daysToTrade252": "7.94",
              "daysToTrade3": "0.38",
              "daysToTrade5": "0.64",
              "daysToTrade65": "7.94",
              "edv": 24641453.2,
              "investorDtt": "62.4",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "liquidationCost": 15227799.533721702,
              "liquidationCostBps": 0.008663,
              "liquidationHorizon": 62.4,
              "pctOfNav": "7.94%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "priceVal": 0.47211,
              "securityClassification": "EQTY_LQA",
              "securityName": "MEITUAN DIANPING-CLASS B",
              "ticker": "3690 HK EQUITY"
            },
            {
              "above252DaysToTrade": "74.08",
              "bidAsk": 1791993.111297545,
              "bidAskBps": 18.4171,
              "dayToTrade1": "0.04",
              "daysToTrade130": "4.75",
              "daysToTrade2": "0.07",
              "daysToTrade22": "0.8",
              "daysToTrade252": "8.79",
              "daysToTrade3": "0.11",
              "daysToTrade5": "0.18",
              "daysToTrade65": "2.38",
              "edv": 6387199,
              "investorDtt": "240.6",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 24451616.642635,
              "liquidationCostBps": 0.012565,
              "liquidationHorizon": 240.6,
              "pctOfNav": "8.79%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.36577,
              "securityClassification": "EQTY_LQA",
              "securityName": "LI AUTO INC-CLASS A",
              "ticker": "2015 HK EQUITY"
            },
            {
              "above252DaysToTrade": "90.57",
              "bidAsk": 48681.7635183945,
              "bidAskBps": 1.4261,
              "dayToTrade1": "0.01",
              "daysToTrade130": "1.86",
              "daysToTrade2": "0.03",
              "daysToTrade22": "0.31",
              "daysToTrade252": "3.09",
              "daysToTrade3": "0.04",
              "daysToTrade5": "0.07",
              "daysToTrade65": "0.93",
              "edv": 41379529.5,
              "investorDtt": "216",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 9937756.8161244,
              "liquidationCostBps": 0.014556,
              "liquidationHorizon": 216,
              "pctOfNav": "3.09%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.44045,
              "securityClassification": "EQTY_LQA",
              "securityName": "LENOVO GROUP LTD",
              "ticker": "992 HK EQUITY"
            },
            {
              "above252DaysToTrade": "72.63",
              "bidAsk": 597669.5685305835,
              "bidAskBps": 7.4794,
              "dayToTrade1": "0.06",
              "daysToTrade130": "7.22",
              "daysToTrade2": "0.12",
              "daysToTrade22": "1.28",
              "daysToTrade252": "7.22",
              "daysToTrade3": "0.17",
              "daysToTrade5": "0.29",
              "daysToTrade65": "3.78",
              "edv": 25739129.6,
              "investorDtt": "124.1",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "liquidationCost": 21217373.5642218,
              "liquidationCostBps": 0.013276,
              "liquidationHorizon": 124.1,
              "pctOfNav": "7.22%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "priceVal": 0.52887,
              "securityClassification": "EQTY_LQA",
              "securityName": "KUAISHOU TECHNOLOGY",
              "ticker": "1024 HK EQUITY"
            },
            {
              "above252DaysToTrade": "94.99",
              "bidAsk": 128740.537022392,
              "bidAskBps": 7.6256,
              "dayToTrade1": "0.01",
              "daysToTrade130": "1.12",
              "daysToTrade2": "0.02",
              "daysToTrade22": "0.19",
              "daysToTrade252": "1.53",
              "daysToTrade3": "0.03",
              "daysToTrade5": "0.04",
              "daysToTrade65": "0.56",
              "edv": 6591264.5,
              "investorDtt": "177.6",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 4530972.68753535,
              "liquidationCostBps": 0.013419,
              "liquidationHorizon": 177.6,
              "pctOfNav": "1.53%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.43529,
              "securityClassification": "EQTY_LQA",
              "securityName": "KINGSOFT CORP LTD",
              "ticker": "3888 HK EQUITY"
            },
            {
              "above252DaysToTrade": "95.47",
              "bidAsk": 61242.1842429448,
              "bidAskBps": 3.6626,
              "dayToTrade1": "0.01",
              "daysToTrade130": "0.86",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.15",
              "daysToTrade252": "1.51",
              "daysToTrade3": "0.02",
              "daysToTrade5": "0.03",
              "daysToTrade65": "0.43",
              "edv": 14921255.7,
              "investorDtt": "228.7",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 6630529.06123992,
              "liquidationCostBps": 0.019827,
              "liquidationHorizon": 228.7,
              "pctOfNav": "1.51%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.58058,
              "securityClassification": "EQTY_LQA",
              "securityName": "KINGDEE INTERNATIONAL SFTWR",
              "ticker": "268 HK EQUITY"
            },
            {
              "above252DaysToTrade": "73.45",
              "bidAsk": 1075640.7741037381,
              "bidAskBps": 12.6261,
              "dayToTrade1": "0.05",
              "daysToTrade130": "6.36",
              "daysToTrade2": "0.1",
              "daysToTrade22": "1.08",
              "daysToTrade252": "7.7",
              "daysToTrade3": "0.15",
              "daysToTrade5": "0.24",
              "daysToTrade65": "3.18",
              "edv": 8748837.6,
              "investorDtt": "157.4",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 23714002.4140088,
              "liquidationCostBps": 0.013918,
              "liquidationHorizon": 157.4,
              "pctOfNav": "7.7%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.50057,
              "securityClassification": "EQTY_LQA",
              "securityName": "JD.COM INC - CL A",
              "ticker": "9618 HK EQUITY"
            },
            {
              "above252DaysToTrade": "91.7",
              "bidAsk": 225948.3715775,
              "bidAskBps": 7.15,
              "dayToTrade1": "0.01",
              "daysToTrade130": "1.47",
              "daysToTrade2": "0.02",
              "daysToTrade22": "0.25",
              "daysToTrade252": "2.86",
              "daysToTrade3": "0.03",
              "daysToTrade5": "0.06",
              "daysToTrade65": "0.74",
              "edv": 4070855,
              "investorDtt": "252",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 12881269.261877,
              "liquidationCostBps": 0.020381,
              "liquidationHorizon": 252,
              "pctOfNav": "2.86%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.51093,
              "securityClassification": "EQTY_LQA",
              "securityName": "JD HEALTH INTERNATIONAL INC",
              "ticker": "6618 HK EQUITY"
            },
            {
              "above252DaysToTrade": "96.53",
              "bidAsk": 59803.141431552,
              "bidAskBps": 6.2327,
              "dayToTrade1": "0.01",
              "daysToTrade130": "0.87",
              "daysToTrade2": "0.02",
              "daysToTrade22": "0.2",
              "daysToTrade252": "0.87",
              "daysToTrade3": "0.03",
              "daysToTrade5": "0.04",
              "daysToTrade65": "0.58",
              "edv": 8211832.8,
              "investorDtt": "97.7",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "liquidationCost": 1840332.8455679999,
              "liquidationCostBps": 0.00959,
              "liquidationHorizon": 97.7,
              "pctOfNav": "0.87%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "priceVal": 0.39872,
              "securityClassification": "EQTY_LQA",
              "securityName": "HUA HONG SEMICONDUCTOR LTD",
              "ticker": "1347 HK EQUITY"
            },
            {
              "above252DaysToTrade": "90.94",
              "bidAsk": 216350.9703811813,
              "bidAskBps": 6.2723,
              "dayToTrade1": "0.01",
              "daysToTrade130": "1.61",
              "daysToTrade2": "0.02",
              "daysToTrade22": "0.27",
              "daysToTrade252": "3.12",
              "daysToTrade3": "0.04",
              "daysToTrade5": "0.06",
              "daysToTrade65": "0.8",
              "edv": 10074785.2,
              "investorDtt": "252",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 8993036.844185,
              "liquidationCostBps": 0.013036,
              "liquidationHorizon": 252,
              "pctOfNav": "3.12%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.32875,
              "securityClassification": "EQTY_LQA",
              "securityName": "HAIER SMART HOME CO LTD-H",
              "ticker": "6690 HK EQUITY"
            },
            {
              "above252DaysToTrade": "99.3",
              "bidAsk": 6983.593354805,
              "bidAskBps": 3.0055,
              "dayToTrade1": "0",
              "daysToTrade130": "0.16",
              "daysToTrade2": "0",
              "daysToTrade22": "0.03",
              "daysToTrade252": "0.21",
              "daysToTrade3": "0",
              "daysToTrade5": "0.01",
              "daysToTrade65": "0.08",
              "edv": 2763095.5,
              "investorDtt": "168.2",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 978469.8591610001,
              "liquidationCostBps": 0.021055,
              "liquidationHorizon": 168.2,
              "pctOfNav": "0.21%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.71843,
              "securityClassification": "EQTY_LQA",
              "securityName": "GDS HOLDINGS LTD-CL A",
              "ticker": "9698 HK EQUITY"
            },
            {
              "above252DaysToTrade": "97.4",
              "bidAsk": 76118.100385872,
              "bidAskBps": 8.8224,
              "dayToTrade1": "0",
              "daysToTrade130": "0.59",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.1",
              "daysToTrade252": "0.78",
              "daysToTrade3": "0.01",
              "daysToTrade5": "0.02",
              "daysToTrade65": "0.3",
              "edv": 3264867.4,
              "investorDtt": "171.6",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 2837518.2325564,
              "liquidationCostBps": 0.016444,
              "liquidationHorizon": 171.6,
              "pctOfNav": "0.78%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.54645,
              "securityClassification": "EQTY_LQA",
              "securityName": "CHINA LITERATURE LTD",
              "ticker": "772 HK EQUITY"
            },
            {
              "above252DaysToTrade": "96.54",
              "bidAsk": 77301.7626144465,
              "bidAskBps": 7.2117,
              "dayToTrade1": "0.01",
              "daysToTrade130": "0.87",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.15",
              "daysToTrade252": "0.97",
              "daysToTrade3": "0.02",
              "daysToTrade5": "0.03",
              "daysToTrade65": "0.43",
              "edv": 6651013,
              "investorDtt": "145.5",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 1942485.8800269,
              "liquidationCostBps": 0.009061,
              "liquidationHorizon": 145.5,
              "pctOfNav": "0.97%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.3096,
              "securityClassification": "EQTY_LQA",
              "securityName": "BYD ELECTRONIC INTL CO LTD",
              "ticker": "285 HK EQUITY"
            },
            {
              "above252DaysToTrade": "93.27",
              "bidAsk": 276737.254400628,
              "bidAskBps": 15.3063,
              "dayToTrade1": "0.02",
              "daysToTrade130": "1.63",
              "daysToTrade2": "0.04",
              "daysToTrade22": "0.41",
              "daysToTrade252": "1.63",
              "daysToTrade3": "0.06",
              "daysToTrade5": "0.09",
              "daysToTrade65": "1.21",
              "edv": 3773715.2,
              "investorDtt": "87.8",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "liquidationCost": 5575497.3123528,
              "liquidationCostBps": 0.015419,
              "liquidationHorizon": 87.8,
              "pctOfNav": "1.63%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "priceVal": 0.72792,
              "securityClassification": "EQTY_LQA",
              "securityName": "BILIBILI INC-CLASS Z",
              "ticker": "9626 HK EQUITY"
            },
            {
              "above252DaysToTrade": "82.28",
              "bidAsk": 721927.0801425922,
              "bidAskBps": 13.3834,
              "dayToTrade1": "0.03",
              "daysToTrade130": "4.54",
              "daysToTrade2": "0.07",
              "daysToTrade22": "0.77",
              "daysToTrade252": "4.88",
              "daysToTrade3": "0.1",
              "daysToTrade5": "0.17",
              "daysToTrade65": "2.27",
              "edv": 6220498.1,
              "investorDtt": "139.5",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 13172631.242495999,
              "liquidationCostBps": 0.01221,
              "liquidationHorizon": 139.5,
              "pctOfNav": "4.88%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.4615,
              "securityClassification": "EQTY_LQA",
              "securityName": "BAIDU INC-CLASS A",
              "ticker": "9888 HK EQUITY"
            },
            {
              "above252DaysToTrade": "95.6",
              "bidAsk": 20671.7911760871,
              "bidAskBps": 1.4142,
              "dayToTrade1": "0.01",
              "daysToTrade130": "1",
              "daysToTrade2": "0.02",
              "daysToTrade22": "0.17",
              "daysToTrade252": "1.32",
              "daysToTrade3": "0.02",
              "daysToTrade5": "0.04",
              "daysToTrade65": "0.5",
              "edv": 38801846.4,
              "investorDtt": "171.1",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 3781496.51905935,
              "liquidationCostBps": 0.012935,
              "liquidationHorizon": 171.1,
              "pctOfNav": "1.32%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.42231,
              "securityClassification": "EQTY_LQA",
              "securityName": "ALIBABA HEALTH INFORMATION T",
              "ticker": "241 HK EQUITY"
            },
            {
              "above252DaysToTrade": "63.28",
              "bidAsk": 522649.76918455,
              "bidAskBps": 6.0635,
              "dayToTrade1": "0.17",
              "daysToTrade130": "7.79",
              "daysToTrade2": "0.34",
              "daysToTrade22": "3.7",
              "daysToTrade252": "7.79",
              "daysToTrade3": "0.5",
              "daysToTrade5": "0.84",
              "daysToTrade65": "7.79",
              "edv": 49155615.2,
              "investorDtt": "46.3",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "liquidationCost": 10493507.528742,
              "liquidationCostBps": 0.006087,
              "liquidationHorizon": 46.3,
              "pctOfNav": "7.79%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "priceVal": 0.38219,
              "securityClassification": "EQTY_LQA",
              "securityName": "ALIBABA GROUP HOLDING LTD",
              "ticker": "9988 HK EQUITY"
            },
            {
              "above252DaysToTrade": "97.94",
              "bidAsk": 22720.557862092,
              "bidAskBps": 2.991,
              "dayToTrade1": "0",
              "daysToTrade130": "0.39",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.07",
              "daysToTrade252": "0.69",
              "daysToTrade3": "0.01",
              "daysToTrade5": "0.01",
              "daysToTrade65": "0.19",
              "edv": 3847888.5,
              "investorDtt": "229",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 1844383.6338736,
              "liquidationCostBps": 0.01214,
              "liquidationHorizon": 229,
              "pctOfNav": "0.69%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "priceVal": 0.35258,
              "securityClassification": "EQTY_LQA",
              "securityName": "AAC ACOUSTIC TECH HOLDINGS INC",
              "ticker": "2018 HK EQUITY"
            }
          ],
          "mmf": []
        }
      },
      "status": "success"
    }
  }


  getLatestLiquiditiData() {
    return {
      "data": {
        // assetClass:"BOND",
        assetClass: "FI",
        // assetClass:"EQUTY",
        // assetClass:"CMDTY",
        //  assetClass:"MM",
        productType: "ETF",
        "investorAggr": {
          "securityClassification": "MMF",
          "above252DaysToTrade": "0%",
          "accumlative1": "0%",
          "accumlative130": "0%",
          "accumlative2": "0%",
          "accumlative22": "0%",
          "accumlative252": "0%",
          "accumlative3": "0%",
          "accumlative5": "0%",
          "accumlative65": "0%",
          "accumlativeAbove252": "0%",
          "cash": "0%",
          "dayToTrade1": "0%",
          "daysToTrade130": "8.35%",
          "daysToTrade2": "0%",
          "daysToTrade22": "17.66%",
          "daysToTrade252": "0%",
          "daysToTrade3": "18.12%",
          "daysToTrade5": "16.55%",
          "daysToTrade65": "39.31%"
        },
        "investorSFC": {
          "classificationType": "INVESTOR",
          "tier0": 51.201438,
          "tier1": 0.51307,
          "tier2": 0,
          "tier3": 0,
          "tier4": 0,
          "tier5": 0,
          "tier6": 0
        },
        "liquidityCategory": {
          "highLiquidity": 10.292355,
          "highLiquidityMonitoring": "Fail",
          "highLiquidityThreshold": 60,
          "illiquid": 47.666051,
          "illiquidMonitoring": "Fail",
          "illiquidThreshold": 0,
          "lowLiquidity": 17.657787,
          "lowLiquidityThreshold": 0,
          "mediumLiquidity": 16.554738,
          "mediumLiquidityThreshold": 0,
          "securityClassification": "MMF"
        },
        "portfolioAggr": {
          "above252DaysToTrade": "0%",
          "accumlative1": "7.83%",
          "accumlative130": "100%",
          "accumlative2": "7.83%",
          "accumlative22": "52.33%",
          "accumlative252": "91.65%",
          "accumlative3": "18.12%",
          "accumlative5": "34.68%",
          "accumlative65": "91.65%",
          "accumlativeAbove252": "91.65%",
          "cash": "7.83%",
          "dayToTrade1": "0%",
          "daysToTrade130": "8.35%",
          "daysToTrade2": "0%",
          "daysToTrade22": "17.66%",
          "daysToTrade252": "0%",
          "daysToTrade3": "10.29%",
          "daysToTrade5": "16.55%",
          "daysToTrade65": "39.31%",
          "fiveDMonitoring": "",
          "fiveDThreshold": "0%",
          "oneDMonitoring": "Fail",
          "oneDThreshold": "0%",
          "securityClassification": "MMF",
          "twoDMonitoring": "Fail",
          "twoDThreshold": "0%"
        },
        "portfolioSFC": {
          "classificationType": "PORTFOLIO",
          "tier0": 51.201438,
          "tier1": 0.51307,
          "tier2": 0,
          "tier3": 0,
          "tier4": 0,
          "tier5": 0,
          "tier6": 0
        },
        "stockList": {
          "bondStockList": [
            {
              "portfolioLiqClassification": "BOND",
              "investorLiqClassification": "5 < Investor DTT <= 22",
              "dayToTrade1": "12.76",
              "daysToTrade2": "12.76",
              "daysToTrade3": "12.76",
              "daysToTrade5": "12.76",
              "daysToTrade22": "12.76",
              "daysToTrade65": "12.76",
              "daysToTrade130": "12.76",
              "daysToTrade252": "12.76",
              "above252DaysToTrade": "12.76",
              "securityName": "XYZ",
              "pctOfNav": "23.87%",
              "investorDtt": "23.87%",
              "securityClassification": "BOND",
              "edv": 671.175,
              "priveVal": 9.832,
              "bidAsk": 9.832,
              "bidAskBps": 9.832,
              "liquidationHorizon": 9.832,
              "liquidationCost": 9.832,
              "liquidationCostBps": 9.832,
              "isin": "CNYDAISDHG",
              "nominalValue": 9.832,
              "cleanPriceUnits": 9.832,
              "cleanPrice": 9.832,
              "marketValue": 9.832,
              "ticker": "XYZ"
            }
          ],
          "equityLqaStockList": [
            {
              portfolioLiqClassification: "EQTY_LQA",
              investorLiqClassification: "5 < Investor DTT <= 22",
              dayToTrade1: "12.76",
              daysToTrade2: "12.76",
              daysToTrade3: "12.76",
              daysToTrade5: "12.76",
              daysToTrade22: "12.76",
              daysToTrade65: "12.76",
              daysToTrade130: "12.76",
              daysToTrade252: "12.76",
              above252DaysToTrade: "12.76",
              securityName: "XYZ",
              pctOfNav: "23.87%",
              investorDtt: "23.87%",
              securityClassification: "BOND",
              edv: 671.175,
              priveVal: 9.832,
              bidAsk: 9.832,
              bidAskBps: 9.832,
              liquidationHorizon: 9.832,
              liquidationCost: 9.832,
              liquidationCostBps: 9.832,
              price: 123.145,
              "ticker": "XYZ"


            }
          ],
          "equityStockList": [
            {
              "portfolioLiqClassification": "EQTY",
              "investorLiqClassification": "5 < Investor DTT <= 22",
              "dayToTrade1": "12.76",
              "daysToTrade2": "12.76",
              "daysToTrade3": "12.76",
              "daysToTrade5": "12.76",
              "daysToTrade22": "12.76",
              daysToTrade65: "12.76",
              daysToTrade130: "12.76",
              daysToTrade252: "12.76",
              above252DaysToTrade: "12.76",
              securityName: "XYZ",
              pctOfNav: "23.87%",
              investorDtt: "23.87%",
              securityClassification: "BOND",
              sharesHeld: 1864,
              tradingVolume: 671.245,
              avgTradVolume: 89.2713,
              daysToTrade: 86.27535,
              ticker: "XYZ"
            }
          ],
          "mmfStockList": [
            {
              portfolioLiqClassification: "BOND",
              investorLiqClassification: "5 < Investor DTT <= 22",
              dayToTrade1: "12.76",
              daysToTrade2: "12.76",
              daysToTrade3: "12.76",
              daysToTrade5: "12.76",
              daysToTrade22: "12.76",
              daysToTrade65: "12.76",
              daysToTrade130: "12.76",
              daysToTrade252: "12.76",
              above252DaysToTrade: "12.76",
              securityName: "XYZ",
              pctOfNav: "23.87%",
              investorDtt: "23.87%",
              securityClassification: "BOND",
              maturityDate: "test date",
              remainingMaturity: 62.354
            }
          ]
        },
        "liquidityCost": {
          securityClassification: "BOND",
          liquidationCost: 167000,
          pctOfCurrentAum: "12.89%",
          threshold: "17.81",
          monitoring: "Pass",
          thresholdCheck: ">="
        }

      },
      "status": "success"
    }
  }

  getsoonDataEquity() {
    return {
      "data": {
        "assetClass": "EQTY",
        "investorAggr": {
          "above252DaysToTrade": "0%",
          "above252DaysToTradeExport": "0%",
          "accumlative1": "0%",
          "accumlative130": "0%",
          "accumlative130Export": "0%",
          "accumlative1Export": "0%",
          "accumlative2": "0%",
          "accumlative22": "0%",
          "accumlative22Export": "0%",
          "accumlative252": "0%",
          "accumlative252Export": "0%",
          "accumlative2Export": "0%",
          "accumlative3": "0%",
          "accumlative3Export": "0%",
          "accumlative5": "0%",
          "accumlative5Export": "0%",
          "accumlative65": "0%",
          "accumlative65Export": "0%",
          "accumlativeAbove252": "0%",
          "accumlativeAbove252Export": "0%",
          "cashExport": "0%",
          "cashExportExport": "0%",
          "dayToTrade1": "99.27%",
          "dayToTrade1Export": "99.265747%",
          "daysToTrade130": "0%",
          "daysToTrade130Export": "0%",
          "daysToTrade2": "0%",
          "daysToTrade22": "0%",
          "daysToTrade22Export": "0%",
          "daysToTrade252": "0%",
          "daysToTrade252Export": "0%",
          "daysToTrade2Export": "0%",
          "daysToTrade3": "0.73%",
          "daysToTrade3Export": "0.734258%",
          "daysToTrade5": "0%",
          "daysToTrade5Export": "0%",
          "daysToTrade65": "0%",
          "daysToTrade65Export": "0%",
          "securityClassification": "EQTY"
        },
        "investorSFC": {
          "classificationType": "INVESTOR",
          "tier0": "99.27%",
          "tier0Export": "99.265747%",
          "tier1": "0.73%",
          "tier1Export": "0.734258%",
          "tier2": "0%",
          "tier2Export": "0%",
          "tier3": "0%",
          "tier3Export": "0%",
          "tier4": "0%",
          "tier4Export": "0%",
          "tier5": "0%",
          "tier5Export": "0%",
          "tier6": "0%",
          "tier6Export": "0%"
        },
        "liquidityCategory": {
          "HighThresholdCond": "70%",
          "HighThresholdCondExport": "70%",
          "highLiquidity": "99.27%",
          "highLiquidityExport": "99.265747%",
          "highLiquidityMonitoring": "Pass",
          "highThresholdCheck": "",
          "illiquid": "0%",
          "illiquidExport": "0%",
          "illiquidMonitoring": "Pass",
          "illiquidThresholdCheck": "",
          "illiquidThresholdCond": "15%",
          "illiquidThresholdCondExport": "15%",
          "lowLiquidity": "0%",
          "lowLiquidityExport": "0%",
          "lowLiquidityMonitoring": "",
          "lowThresholdCheck": "",
          "lowThresholdCond": "0%",
          "lowThresholdCondExport": "0%",
          "mediumLiquidity": "0%",
          "mediumLiquidityExport": "0%",
          "mediumLiquidityMonitoring": "",
          "mediumThresholdCheck": "",
          "mediumThresholdCond": "0%",
          "mediumThresholdCondExport": "0%",
          "securityClassification": "EQTY"
        },
        "liquidityCost": {
          "liquidationCost": 0,
          "monitoring": "",
          "pctOfCurrentAum": "0%",
          "pctOfCurrentAumExport": "0%",
          "securityClassification": "",
          "thresholdCheck": "",
          "thresholdCond": "0%",
          "thresholdCondExport": "0%"
        },
        "portfolioAggr": {
          "above252DaysToTrade": "0%",
          "above252DaysToTradeExport": "0%",
          "accumlative1": "100%",
          "accumlative130": "100%",
          "accumlative130Export": "100.000005%",
          "accumlative1Export": "100.000005%",
          "accumlative2": "100%",
          "accumlative22": "100%",
          "accumlative22Export": "100.000005%",
          "accumlative252": "100%",
          "accumlative252Export": "100.000005%",
          "accumlative2Export": "100.000005%",
          "accumlative3": "100%",
          "accumlative3Export": "100.000005%",
          "accumlative5": "100%",
          "accumlative5Export": "100.000005%",
          "accumlative65": "100%",
          "accumlative65Export": "100.000005%",
          "accumlativeAbove252": "100%",
          "accumlativeAbove252Export": "100.000005%",
          "cash": "0.73%",
          "cashExport": "0.734258%",
          "dayToTrade1": "99.27%",
          "dayToTrade1Export": "99.265747%",
          "daysToTrade130": "0%",
          "daysToTrade130Export": "0%",
          "daysToTrade2": "0%",
          "daysToTrade22": "0%",
          "daysToTrade22Export": "0%",
          "daysToTrade252": "0%",
          "daysToTrade252Export": "0%",
          "daysToTrade2Export": "0%",
          "daysToTrade3": "0%",
          "daysToTrade3Export": "0%",
          "daysToTrade5": "0%",
          "daysToTrade5Export": "0%",
          "daysToTrade65": "0%",
          "daysToTrade65Export": "0%",
          "securityClassification": "EQTY"
        },
        "portfolioSFC": {
          "classificationType": "PORTFOLIO",
          "tier0": "100%",
          "tier0Export": "100.000005%",
          "tier1": "0%",
          "tier1Export": "0%",
          "tier2": "0%",
          "tier2Export": "0%",
          "tier3": "0%",
          "tier3Export": "0%",
          "tier4": "0%",
          "tier4Export": "0%",
          "tier5": "0%",
          "tier5Export": "0%",
          "tier6": "0%",
          "tier6Export": "0%"
        },
        "productType": "ETF",
        "stockList": {
          "eqty": [
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "11.9%",
              "pctOfNavExport": "11.903755%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "VINHOMES JSC",
              "sharesHeld": 178900,
              "ticker": "VHM VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "11.38%",
              "pctOfNavExport": "11.382661%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "HOA PHAT GROUP JSC",
              "sharesHeld": 359800,
              "ticker": "HPG VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "9%",
              "pctOfNavExport": "9.000857%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "BANK FOR FOREIGN TRADE JSC",
              "sharesHeld": 74400,
              "ticker": "VCB VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "8.9%",
              "pctOfNavExport": "8.903227%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "VINGROUP JSC",
              "sharesHeld": 144300,
              "ticker": "VIC VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "8.56%",
              "pctOfNavExport": "8.55517%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "VIETNAM DAIRY PRODUCTS JSC",
              "sharesHeld": 99600,
              "ticker": "VNM VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "8.34%",
              "pctOfNavExport": "8.342537%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "MASAN GROUP CORP",
              "sharesHeld": 91700,
              "ticker": "MSN VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "5.63%",
              "pctOfNavExport": "5.627641%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "SSI SECURITIES CORP",
              "sharesHeld": 180300,
              "ticker": "SSI VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "4.49%",
              "pctOfNavExport": "4.493751%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "VINCOM RETAIL JSC",
              "sharesHeld": 138600,
              "ticker": "VRE VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "2.84%",
              "pctOfNavExport": "2.840678%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "VIETJET AVIATION JSC",
              "sharesHeld": 24900,
              "ticker": "VJC VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "2.19%",
              "pctOfNavExport": "2.192047%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "DUC GIANG CHEMICALS JSC",
              "sharesHeld": 28400,
              "ticker": "DGC VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "2.12%",
              "pctOfNavExport": "2.124547%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "SAIGON - HANOI COMMERICIAL",
              "sharesHeld": 140490,
              "ticker": "SHB VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "2.08%",
              "pctOfNavExport": "2.083009%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "VNDIRECT SECURITIES CORP",
              "sharesHeld": 91100,
              "ticker": "VND VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "2.07%",
              "pctOfNavExport": "2.07298%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "SAIGON THUONG TIN COMMERCIAL",
              "sharesHeld": 57500,
              "ticker": "STB VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "2.04%",
              "pctOfNavExport": "2.03821%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "KHANG DIEN HOUSE TRADING AND",
              "sharesHeld": 54700,
              "ticker": "KDH VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "2.04%",
              "pctOfNavExport": "2.038126%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "KINH BAC CITY DEVELOPMENT SH",
              "sharesHeld": 57400,
              "ticker": "KBC VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "2.01%",
              "pctOfNavExport": "2.006901%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "SAIGON BEER ALCOHOL BEVERAGE",
              "sharesHeld": 10800,
              "ticker": "SAB VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "1.99%",
              "pctOfNavExport": "1.988101%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "VIET CAPITAL SECURITIES JSC",
              "sharesHeld": 44900,
              "ticker": "VCI VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "1.61%",
              "pctOfNavExport": "1.613113%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "KIDO GROUP CORP",
              "sharesHeld": 21300,
              "ticker": "KDC VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "1.51%",
              "pctOfNavExport": "1.514594%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "GELEX GROUP JSC",
              "sharesHeld": 64700,
              "ticker": "GEX VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "1.45%",
              "pctOfNavExport": "1.454997%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "THANH THANH CONG-BIEN HOA JS",
              "sharesHeld": 79385,
              "ticker": "SBT VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "1.22%",
              "pctOfNavExport": "1.219169%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "PETROVIETNAM FERT \u0026 CHEMICAL",
              "sharesHeld": 29000,
              "ticker": "DPM VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "1.15%",
              "pctOfNavExport": "1.151069%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "DEVELOPMENT INVESTMENT CONST",
              "sharesHeld": 45200,
              "ticker": "DIG VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "1.15%",
              "pctOfNavExport": "1.150997%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "PETROVIETNAM POWER CORP",
              "sharesHeld": 71000,
              "ticker": "POW VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "0.89%",
              "pctOfNavExport": "0.894678%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "VIETNAM NATIONAL PETROLEUM G",
              "sharesHeld": 19800,
              "ticker": "PLX VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "0.86%",
              "pctOfNavExport": "0.857874%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "VIETNAM CONSTRUCTION \u0026 IMPOR",
              "sharesHeld": 34010,
              "ticker": "VCG VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "0.63%",
              "pctOfNavExport": "0.625995%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "PETROVIETNAM CA MAU FERTILIZ",
              "sharesHeld": 19600,
              "ticker": "DCM VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "0.58%",
              "pctOfNavExport": "0.5762%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "BAO VIET HOLDINGS",
              "sharesHeld": 10800,
              "ticker": "BVH VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "0.37%",
              "pctOfNavExport": "0.371068%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "VIGLACERA CORP JSC",
              "sharesHeld": 7200,
              "ticker": "VGC VM EQUITY",
              "tradingVolume": 0
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "avgTradVolume": 0,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0",
              "daysToTrade252Export": "0",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "daysToTradeExport": "0",
              "investorDtt": "0",
              "investorDttExport": "0",
              "investorLiqClassification": "Investor DTT \u003c= 1",
              "pctOfNav": "0.24%",
              "pctOfNavExport": "0.241795%",
              "portfolioLiqClassification": "LH \u003c= 1",
              "securityName": "HO CHI MINH CITY SECURITIES",
              "sharesHeld": 7100,
              "ticker": "HCM VM EQUITY",
              "tradingVolume": 0
            }
          ]
        }
      },
      "status": "success"
    }
  }

  getsoonDataFund() {
    return {
      "data": {
        "assetClass": "EQTY",
        "investorAggr": {
          "above252DaysToTrade": "14.63%",
          "above252DaysToTradeExport": "14.632879%",
          "accumlative1": "0%",
          "accumlative130": "0%",
          "accumlative130Export": "0%",
          "accumlative1Export": "0%",
          "accumlative2": "0%",
          "accumlative22": "0%",
          "accumlative22Export": "0%",
          "accumlative252": "0%",
          "accumlative252Export": "0%",
          "accumlative2Export": "0%",
          "accumlative3": "0%",
          "accumlative3Export": "0%",
          "accumlative5": "0%",
          "accumlative5Export": "0%",
          "accumlative65": "0%",
          "accumlative65Export": "0%",
          "accumlativeAbove252": "0%",
          "accumlativeAbove252Export": "0%",
          "cashExport": "0%",
          "cashExportExport": "0%",
          "dayToTrade1": "0%",
          "dayToTrade1Export": "0%",
          "daysToTrade130": "14.41%",
          "daysToTrade130Export": "14.412345%",
          "daysToTrade2": "0%",
          "daysToTrade22": "0%",
          "daysToTrade22Export": "0%",
          "daysToTrade252": "43.91%",
          "daysToTrade252Export": "43.912605%",
          "daysToTrade2Export": "0%",
          "daysToTrade3": "0.05%",
          "daysToTrade3Export": "0.046702%",
          "daysToTrade5": "0%",
          "daysToTrade5Export": "0%",
          "daysToTrade65": "27%",
          "daysToTrade65Export": "26.995471%",
          "securityClassification": "EQTY_LQA"
        },
        "investorSFC": {
          "classificationType": "INVESTOR",
          "tier0": "0%",
          "tier0Export": "0%",
          "tier1": "0.05%",
          "tier1Export": "0.046702%",
          "tier2": "7.93%",
          "tier2Export": "7.928302%",
          "tier3": "22.5%",
          "tier3Export": "22.498421%",
          "tier4": "35.79%",
          "tier4Export": "35.794186%",
          "tier5": "33.73%",
          "tier5Export": "33.732391%",
          "tier6": "0%",
          "tier6Export": "0%"
        },
        "liquidityCategory": {
          "HighThresholdCond": "\u003e=70%",
          "HighThresholdCondExport": "\u003e=70%",
          "highLiquidity": "0%",
          "highLiquidityExport": "0%",
          "highLiquidityMonitoring": "Fail",
          "highThresholdCheck": "",
          "illiquid": "99.95%",
          "illiquidExport": "99.9533%",
          "illiquidMonitoring": "Pass",
          "illiquidThresholdCheck": "HIGH",
          "illiquidThresholdCond": "\u003e=15%",
          "illiquidThresholdCondExport": "\u003e=15%",
          "lowLiquidity": "0%",
          "lowLiquidityExport": "0%",
          "lowLiquidityMonitoring": "Pass",
          "lowThresholdCheck": "HIGH",
          "lowThresholdCond": "\u003c=20%",
          "lowThresholdCondExport": "\u003c=20%",
          "mediumLiquidity": "0%",
          "mediumLiquidityExport": "0%",
          "mediumLiquidityMonitoring": "Pass",
          "mediumThresholdCheck": "HIGH",
          "mediumThresholdCond": "\u003c=50%",
          "mediumThresholdCondExport": "\u003c=50%",
          "securityClassification": "EQTY_LQA"
        },
        "liquidityCost": {
          "liquidationCost": 265255054.14962375,
          "monitoring": "Pass",
          "pctOfCurrentAum": "1.2%",
          "pctOfCurrentAumExport": "1.1992832962%",
          "securityClassification": "EQTY",
          "thresholdCheck": "MEDIUM",
          "thresholdCond": "\u003e=2.2%",
          "thresholdCondExport": "\u003e=2.2%"
        },
        "portfolioAggr": {
          "above252DaysToTrade": "0%",
          "above252DaysToTradeExport": "0%",
          "accumlative1": "0.05%",
          "accumlative130": "41.45%",
          "accumlative130Export": "41.454518%",
          "accumlative1Export": "0.046702%",
          "accumlative2": "0.05%",
          "accumlative22": "0.05%",
          "accumlative22Export": "0.046702%",
          "accumlative252": "100%",
          "accumlative252Export": "100.000002%",
          "accumlative2Export": "0.046702%",
          "accumlative3": "0.05%",
          "accumlative3Export": "0.046702%",
          "accumlative5": "0.05%",
          "accumlative5Export": "0.046702%",
          "accumlative65": "27.04%",
          "accumlative65Export": "27.042173%",
          "accumlativeAbove252": "100%",
          "accumlativeAbove252Export": "100.000002%",
          "cash": "0.05%",
          "cashExport": "0.046702%",
          "dayToTrade1": "0%",
          "dayToTrade1Export": "0%",
          "daysToTrade130": "14.41%",
          "daysToTrade130Export": "14.412345%",
          "daysToTrade2": "0%",
          "daysToTrade22": "0%",
          "daysToTrade22Export": "0%",
          "daysToTrade252": "58.55%",
          "daysToTrade252Export": "58.545484%",
          "daysToTrade2Export": "0%",
          "daysToTrade3": "0%",
          "daysToTrade3Export": "0%",
          "daysToTrade5": "0%",
          "daysToTrade5Export": "0%",
          "daysToTrade65": "27%",
          "daysToTrade65Export": "26.995471%",
          "securityClassification": "EQTY_LQA"
        },
        "portfolioSFC": {
          "classificationType": "PORTFOLIO",
          "tier0": "0.05%",
          "tier0Export": "0.046702%",
          "tier1": "0%",
          "tier1Export": "0%",
          "tier2": "7.93%",
          "tier2Export": "7.928302%",
          "tier3": "22.5%",
          "tier3Export": "22.498421%",
          "tier4": "35.79%",
          "tier4Export": "35.794186%",
          "tier5": "33.73%",
          "tier5Export": "33.732391%",
          "tier6": "0%",
          "tier6Export": "0%"
        },
        "productType": "ETF",
        "stockList": {
          "eqty_lqa": [
            {
              "above252DaysToTrade": "74.08",
              "above252DaysToTradeExport": "74.0777053815",
              "bidAsk": 1791993.111297545,
              "bidAskBps": 18.4171,
              "dayToTrade1": "0.04",
              "dayToTrade1Export": "0.0365514589",
              "daysToTrade130": "4.75",
              "daysToTrade130Export": "4.7516896509",
              "daysToTrade2": "0.07",
              "daysToTrade22": "0.8",
              "daysToTrade22Export": "0.8041320948",
              "daysToTrade252": "8.79",
              "daysToTrade252Export": "8.794281",
              "daysToTrade2Export": "0.0731029177",
              "daysToTrade3": "0.11",
              "daysToTrade3Export": "0.1096543766",
              "daysToTrade5": "0.18",
              "daysToTrade5Export": "0.1827572943",
              "daysToTrade65": "2.38",
              "daysToTrade65Export": "2.3758448254",
              "edv": 6387199,
              "investorDtt": "241.6",
              "investorDttExport": "241.6",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 24451616.642635,
              "liquidationCostBps": 0.012565,
              "liquidationHorizon": 240.6,
              "pctOfNav": "8.79%",
              "pctOfNavExport": "8.794281%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 135.5,
              "priceVal": 0.36577,
              "securityName": "LI AUTO INC-CLASS A",
              "sharesHeld": 14361698,
              "ticker": "2015 HK EQUITY"
            },
            {
              "above252DaysToTrade": "64.02",
              "above252DaysToTradeExport": "64.0241006442",
              "bidAsk": 1158476.426491895,
              "bidAskBps": 13.181,
              "dayToTrade1": "0.13",
              "dayToTrade1Export": "0.1273032532",
              "daysToTrade130": "7.94",
              "daysToTrade130Export": "7.943723",
              "daysToTrade2": "0.25",
              "daysToTrade22": "2.8",
              "daysToTrade22Export": "2.8006715705",
              "daysToTrade252": "7.94",
              "daysToTrade252Export": "7.943723",
              "daysToTrade2Export": "0.2546065064",
              "daysToTrade3": "0.38",
              "daysToTrade3Export": "0.3819097596",
              "daysToTrade5": "0.64",
              "daysToTrade5Export": "0.636516266",
              "daysToTrade65": "7.94",
              "daysToTrade65Export": "7.943723",
              "edv": 24641453.2,
              "investorDtt": "63.4",
              "investorDttExport": "63.4",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "liquidationCost": 15227799.533721702,
              "liquidationCostBps": 0.008663,
              "liquidationHorizon": 62.4,
              "pctOfNav": "7.94%",
              "pctOfNavExport": "7.943723%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "price": 122.3,
              "priceVal": 0.47211,
              "securityName": "MEITUAN DIANPING-CLASS B",
              "sharesHeld": 14372833,
              "ticker": "3690 HK EQUITY"
            },
            {
              "above252DaysToTrade": "58.74",
              "above252DaysToTradeExport": "58.7381071095",
              "bidAsk": 2208218.306763982,
              "bidAskBps": 25.1737,
              "dayToTrade1": "0.29",
              "dayToTrade1Export": "0.2893540876",
              "daysToTrade130": "7.93",
              "daysToTrade130Export": "7.928302",
              "daysToTrade2": "0.58",
              "daysToTrade22": "6.37",
              "daysToTrade22Export": "6.365789927",
              "daysToTrade252": "7.93",
              "daysToTrade252Export": "7.928302",
              "daysToTrade2Export": "0.5787081752",
              "daysToTrade3": "0.87",
              "daysToTrade3Export": "0.8680622628",
              "daysToTrade5": "1.45",
              "daysToTrade5Export": "1.446770438",
              "daysToTrade65": "7.93",
              "daysToTrade65Export": "7.928302",
              "edv": 20687675.4,
              "investorDtt": "28.4",
              "investorDttExport": "28.4",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "liquidationCost": 8264908.5697892,
              "liquidationCostBps": 0.004711,
              "liquidationHorizon": 27.4,
              "pctOfNav": "7.93%",
              "pctOfNavExport": "7.928302%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "price": 331.6,
              "priceVal": 0.3698,
              "securityName": "TENCENT HOLDINGS LTD",
              "sharesHeld": 5290667,
              "ticker": "700 HK EQUITY"
            },
            {
              "above252DaysToTrade": "63.28",
              "above252DaysToTradeExport": "63.2847584406",
              "bidAsk": 522649.76918455,
              "bidAskBps": 6.0635,
              "dayToTrade1": "0.17",
              "dayToTrade1Export": "0.1682641685",
              "daysToTrade130": "7.79",
              "daysToTrade130Export": "7.790631",
              "daysToTrade2": "0.34",
              "daysToTrade22": "3.7",
              "daysToTrade22Export": "3.7018117063",
              "daysToTrade252": "7.79",
              "daysToTrade252Export": "7.790631",
              "daysToTrade2Export": "0.3365283369",
              "daysToTrade3": "0.5",
              "daysToTrade3Export": "0.5047925054",
              "daysToTrade5": "0.84",
              "daysToTrade5Export": "0.8413208423",
              "daysToTrade65": "7.79",
              "daysToTrade65Export": "7.790631",
              "edv": 49155615.2,
              "investorDtt": "47.3",
              "investorDttExport": "47.3",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "liquidationCost": 10493507.528742,
              "liquidationCostBps": 0.006087,
              "liquidationHorizon": 46.3,
              "pctOfNav": "7.79%",
              "pctOfNavExport": "7.790631%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "price": 81.2,
              "priceVal": 0.38219,
              "securityName": "ALIBABA GROUP HOLDING LTD",
              "sharesHeld": 21230555,
              "ticker": "9988 HK EQUITY"
            },
            {
              "above252DaysToTrade": "77.58",
              "above252DaysToTradeExport": "77.5794093333",
              "bidAsk": 192625.3127544992,
              "bidAskBps": 2.2556,
              "dayToTrade1": "0.03",
              "dayToTrade1Export": "0.0306292222",
              "daysToTrade130": "3.98",
              "daysToTrade130Export": "3.9817988889",
              "daysToTrade2": "0.06",
              "daysToTrade22": "0.67",
              "daysToTrade22Export": "0.6738428889",
              "daysToTrade252": "7.72",
              "daysToTrade252Export": "7.718564",
              "daysToTrade2Export": "0.0612584444",
              "daysToTrade3": "0.09",
              "daysToTrade3Export": "0.0918876667",
              "daysToTrade5": "0.15",
              "daysToTrade5Export": "0.1531461111",
              "daysToTrade65": "1.99",
              "daysToTrade65Export": "1.9908994444",
              "edv": 65740104.5,
              "investorDtt": "253",
              "investorDttExport": "253",
              "investorLiqClassification": "Investor DTT \u003e 252",
              "liquidationCost": 22323220.7634448,
              "liquidationCostBps": 0.01307,
              "liquidationHorizon": 252,
              "pctOfNav": "7.72%",
              "pctOfNavExport": "7.718564%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 10.72,
              "priceVal": 0.35811,
              "securityName": "XIAOMI CORP-CLASS B",
              "sharesHeld": 159325937,
              "ticker": "1810 HK EQUITY"
            },
            {
              "above252DaysToTrade": "73.45",
              "above252DaysToTradeExport": "73.4467067954",
              "bidAsk": 1075640.7741037381,
              "bidAskBps": 12.6261,
              "dayToTrade1": "0.05",
              "dayToTrade1Export": "0.0489191105",
              "daysToTrade130": "6.36",
              "daysToTrade130Export": "6.359484371",
              "daysToTrade2": "0.1",
              "daysToTrade22": "1.08",
              "daysToTrade22Export": "1.076220432",
              "daysToTrade252": "7.7",
              "daysToTrade252Export": "7.699868",
              "daysToTrade2Export": "0.0978382211",
              "daysToTrade3": "0.15",
              "daysToTrade3Export": "0.1467573316",
              "daysToTrade5": "0.24",
              "daysToTrade5Export": "0.2445955527",
              "daysToTrade65": "3.18",
              "daysToTrade65Export": "3.1797421855",
              "edv": 8748837.6,
              "investorDtt": "158.4",
              "investorDttExport": "158.4",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 23714002.4140088,
              "liquidationCostBps": 0.013918,
              "liquidationHorizon": 157.4,
              "pctOfNav": "7.7%",
              "pctOfNavExport": "7.699868%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 132.4,
              "priceVal": 0.50057,
              "securityName": "JD.COM INC - CL A",
              "sharesHeld": 12868859,
              "ticker": "9618 HK EQUITY"
            },
            {
              "above252DaysToTrade": "72.63",
              "above252DaysToTradeExport": "72.6294950008",
              "bidAsk": 597669.5685305835,
              "bidAskBps": 7.4794,
              "dayToTrade1": "0.06",
              "dayToTrade1Export": "0.0581979694",
              "daysToTrade130": "7.22",
              "daysToTrade130Export": "7.222368",
              "daysToTrade2": "0.12",
              "daysToTrade22": "1.28",
              "daysToTrade22Export": "1.2803553263",
              "daysToTrade252": "7.22",
              "daysToTrade252Export": "7.222368",
              "daysToTrade2Export": "0.1163959388",
              "daysToTrade3": "0.17",
              "daysToTrade3Export": "0.1745939081",
              "daysToTrade5": "0.29",
              "daysToTrade5Export": "0.2909898469",
              "daysToTrade65": "3.78",
              "daysToTrade65Export": "3.7828680097",
              "edv": 25739129.6,
              "investorDtt": "125.1",
              "investorDttExport": "125.1",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "liquidationCost": 21217373.5642218,
              "liquidationCostBps": 0.013276,
              "liquidationHorizon": 124.1,
              "pctOfNav": "7.22%",
              "pctOfNavExport": "7.222368%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "price": 53.55,
              "priceVal": 0.52887,
              "securityName": "KUAISHOU TECHNOLOGY",
              "sharesHeld": 29844541,
              "ticker": "1024 HK EQUITY"
            },
            {
              "above252DaysToTrade": "81.64",
              "above252DaysToTradeExport": "81.6360377861",
              "bidAsk": 944698.7325083,
              "bidAskBps": 15.598,
              "dayToTrade1": "0.03",
              "dayToTrade1Export": "0.032525615",
              "daysToTrade130": "4.23",
              "daysToTrade130Export": "4.2283299465",
              "daysToTrade2": "0.07",
              "daysToTrade22": "0.72",
              "daysToTrade22Export": "0.7155635294",
              "daysToTrade252": "5.47",
              "daysToTrade252Export": "5.474061",
              "daysToTrade2Export": "0.0650512299",
              "daysToTrade3": "0.1",
              "daysToTrade3Export": "0.0975768449",
              "daysToTrade5": "0.16",
              "daysToTrade5Export": "0.1626280749",
              "daysToTrade65": "2.11",
              "daysToTrade65Export": "2.1141649733",
              "edv": 5037725.6,
              "investorDtt": "169.3",
              "investorDttExport": "169.3",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 10899345.037966002,
              "liquidationCostBps": 0.008998,
              "liquidationHorizon": 168.3,
              "pctOfNav": "5.47%",
              "pctOfNavExport": "5.474061%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 152.6,
              "priceVal": 0.3073,
              "securityName": "NETEASE INC",
              "sharesHeld": 7937795,
              "ticker": "9999 HK EQUITY"
            },
            {
              "above252DaysToTrade": "82.28",
              "above252DaysToTradeExport": "82.2807279785",
              "bidAsk": 721927.0801425922,
              "bidAskBps": 13.3834,
              "dayToTrade1": "0.03",
              "dayToTrade1Export": "0.0349492545",
              "daysToTrade130": "4.54",
              "daysToTrade130Export": "4.5434030824",
              "daysToTrade2": "0.07",
              "daysToTrade22": "0.77",
              "daysToTrade22Export": "0.7688835986",
              "daysToTrade252": "4.88",
              "daysToTrade252Export": "4.875421",
              "daysToTrade2Export": "0.069898509",
              "daysToTrade3": "0.1",
              "daysToTrade3Export": "0.1048477634",
              "daysToTrade5": "0.17",
              "daysToTrade5Export": "0.1747462724",
              "daysToTrade65": "2.27",
              "daysToTrade65Export": "2.2717015412",
              "edv": 6220498.1,
              "investorDtt": "140.5",
              "investorDttExport": "140.5",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 13172631.242495999,
              "liquidationCostBps": 0.01221,
              "liquidationHorizon": 139.5,
              "pctOfNav": "4.88%",
              "pctOfNavExport": "4.875421%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 132.8,
              "priceVal": 0.4615,
              "securityName": "BAIDU INC-CLASS A",
              "sharesHeld": 8123792,
              "ticker": "9888 HK EQUITY"
            },
            {
              "above252DaysToTrade": "86.54",
              "above252DaysToTradeExport": "86.5374755041",
              "bidAsk": 123033.734818308,
              "bidAskBps": 2.5167,
              "dayToTrade1": "0.02",
              "dayToTrade1Export": "0.0202871074",
              "daysToTrade130": "2.64",
              "daysToTrade130Export": "2.6373239669",
              "daysToTrade2": "0.04",
              "daysToTrade22": "0.45",
              "daysToTrade22Export": "0.4463163636",
              "daysToTrade252": "4.42",
              "daysToTrade252Export": "4.418532",
              "daysToTrade2Export": "0.0405742149",
              "daysToTrade3": "0.06",
              "daysToTrade3Export": "0.0608613223",
              "daysToTrade5": "0.1",
              "daysToTrade5Export": "0.1014355372",
              "daysToTrade65": "1.32",
              "daysToTrade65Export": "1.3186619835",
              "edv": 23501649.9,
              "investorDtt": "218.8",
              "investorDttExport": "218.8",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 8478949.007385598,
              "liquidationCostBps": 0.008672,
              "liquidationHorizon": 217.8,
              "pctOfNav": "4.42%",
              "pctOfNavExport": "4.418532%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 20.4,
              "priceVal": 0.25574,
              "securityName": "SEMICONDUCTOR MANUFACTURING",
              "sharesHeld": 47928362,
              "ticker": "981 HK EQUITY"
            },
            {
              "above252DaysToTrade": "85.83",
              "above252DaysToTradeExport": "85.8347460471",
              "bidAsk": 344135.9380725,
              "bidAskBps": 9.9207,
              "dayToTrade1": "0.05",
              "dayToTrade1Export": "0.0492190895",
              "daysToTrade130": "3.14",
              "daysToTrade130Export": "3.135256",
              "daysToTrade2": "0.1",
              "daysToTrade22": "1.08",
              "daysToTrade22Export": "1.0828199686",
              "daysToTrade252": "3.14",
              "daysToTrade252Export": "3.135256",
              "daysToTrade2Export": "0.098438179",
              "daysToTrade3": "0.15",
              "daysToTrade3Export": "0.1476572684",
              "daysToTrade5": "0.25",
              "daysToTrade5Export": "0.2460954474",
              "daysToTrade65": "3.14",
              "daysToTrade65Export": "3.135256",
              "edv": 23316246.4,
              "investorDtt": "64.7",
              "investorDttExport": "64.7",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "liquidationCost": 17900743.847,
              "liquidationCostBps": 0.025802,
              "liquidationHorizon": 63.7,
              "pctOfNav": "3.14%",
              "pctOfNavExport": "3.135256%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "price": 50,
              "priceVal": 1.43119,
              "securityName": "XPENG INC - CLASS A SHARES",
              "sharesHeld": 13875470,
              "ticker": "9868 HK EQUITY"
            },
            {
              "above252DaysToTrade": "90.94",
              "above252DaysToTradeExport": "90.9441781905",
              "bidAsk": 216350.9703811813,
              "bidAskBps": 6.2723,
              "dayToTrade1": "0.01",
              "dayToTrade1Export": "0.0123713413",
              "daysToTrade130": "1.61",
              "daysToTrade130Export": "1.6082743651",
              "daysToTrade2": "0.02",
              "daysToTrade22": "0.27",
              "daysToTrade22Export": "0.2721695079",
              "daysToTrade252": "3.12",
              "daysToTrade252Export": "3.117578",
              "daysToTrade2Export": "0.0247426825",
              "daysToTrade3": "0.04",
              "daysToTrade3Export": "0.0371140238",
              "daysToTrade5": "0.06",
              "daysToTrade5Export": "0.0618567063",
              "daysToTrade65": "0.8",
              "daysToTrade65Export": "0.8041371825",
              "edv": 10074785.2,
              "investorDtt": "253",
              "investorDttExport": "253",
              "investorLiqClassification": "Investor DTT \u003e 252",
              "liquidationCost": 8993036.844185,
              "liquidationCostBps": 0.013036,
              "liquidationHorizon": 252,
              "pctOfNav": "3.12%",
              "pctOfNavExport": "3.117578%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 24.65,
              "priceVal": 0.32875,
              "securityName": "HAIER SMART HOME CO LTD-H",
              "sharesHeld": 27986275,
              "ticker": "6690 HK EQUITY"
            },
            {
              "above252DaysToTrade": "90.57",
              "above252DaysToTradeExport": "90.5726027778",
              "bidAsk": 48681.7635183945,
              "bidAskBps": 1.4261,
              "dayToTrade1": "0.01",
              "dayToTrade1Export": "0.0142839352",
              "daysToTrade130": "1.86",
              "daysToTrade130Export": "1.8569115741",
              "daysToTrade2": "0.03",
              "daysToTrade22": "0.31",
              "daysToTrade22Export": "0.3142465741",
              "daysToTrade252": "3.09",
              "daysToTrade252Export": "3.08533",
              "daysToTrade2Export": "0.0285678704",
              "daysToTrade3": "0.04",
              "daysToTrade3Export": "0.0428518056",
              "daysToTrade5": "0.07",
              "daysToTrade5Export": "0.0714196759",
              "daysToTrade65": "0.93",
              "daysToTrade65Export": "0.928455787",
              "edv": 41379529.5,
              "investorDtt": "217",
              "investorDttExport": "217",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 9937756.8161244,
              "liquidationCostBps": 0.014556,
              "liquidationHorizon": 216,
              "pctOfNav": "3.09%",
              "pctOfNavExport": "3.08533%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 8.17,
              "priceVal": 0.44045,
              "securityName": "LENOVO GROUP LTD",
              "sharesHeld": 83564970,
              "ticker": "992 HK EQUITY"
            },
            {
              "above252DaysToTrade": "89.11",
              "above252DaysToTradeExport": "89.1117028437",
              "bidAsk": 250316.062987866,
              "bidAskBps": 7.8244,
              "dayToTrade1": "0.02",
              "dayToTrade1Export": "0.0225898281",
              "daysToTrade130": "2.89",
              "daysToTrade130Export": "2.891498",
              "daysToTrade2": "0.05",
              "daysToTrade22": "0.5",
              "daysToTrade22Export": "0.4969762187",
              "daysToTrade252": "2.89",
              "daysToTrade252Export": "2.891498",
              "daysToTrade2Export": "0.0451796562",
              "daysToTrade3": "0.07",
              "daysToTrade3Export": "0.0677694844",
              "daysToTrade5": "0.11",
              "daysToTrade5Export": "0.1129491406",
              "daysToTrade65": "1.47",
              "daysToTrade65Export": "1.4683388281",
              "edv": 6843712.8,
              "investorDtt": "129",
              "investorDttExport": "129",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "liquidationCost": 11582924.4115059,
              "liquidationCostBps": 0.018103,
              "liquidationHorizon": 128,
              "pctOfNav": "2.89%",
              "pctOfNavExport": "2.891498%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "price": 78.15,
              "priceVal": 0.71798,
              "securityName": "SUNNY OPTICAL TECH",
              "sharesHeld": 8187262,
              "ticker": "2382 HK EQUITY"
            },
            {
              "above252DaysToTrade": "91.7",
              "above252DaysToTradeExport": "91.7034219524",
              "bidAsk": 225948.3715775,
              "bidAskBps": 7.15,
              "dayToTrade1": "0.01",
              "dayToTrade1Export": "0.011334123",
              "daysToTrade130": "1.47",
              "daysToTrade130Export": "1.4734359921",
              "daysToTrade2": "0.02",
              "daysToTrade22": "0.25",
              "daysToTrade22Export": "0.2493507063",
              "daysToTrade252": "2.86",
              "daysToTrade252Export": "2.856199",
              "daysToTrade2Export": "0.022668246",
              "daysToTrade3": "0.03",
              "daysToTrade3Export": "0.034002369",
              "daysToTrade5": "0.06",
              "daysToTrade5Export": "0.0566706151",
              "daysToTrade65": "0.74",
              "daysToTrade65Export": "0.736717996",
              "edv": 4070855,
              "investorDtt": "253",
              "investorDttExport": "253",
              "investorLiqClassification": "Investor DTT \u003e 252",
              "liquidationCost": 12881269.261877,
              "liquidationCostBps": 0.020381,
              "liquidationHorizon": 252,
              "pctOfNav": "2.86%",
              "pctOfNavExport": "2.856199%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 49.45,
              "priceVal": 0.51093,
              "securityName": "JD HEALTH INTERNATIONAL INC",
              "sharesHeld": 12781060,
              "ticker": "6618 HK EQUITY"
            },
            {
              "above252DaysToTrade": "93.27",
              "above252DaysToTradeExport": "93.274920525",
              "bidAsk": 676950.73783929,
              "bidAskBps": 31.6395,
              "dayToTrade1": "0.01",
              "dayToTrade1Export": "0.0125327609",
              "daysToTrade130": "1.63",
              "daysToTrade130Export": "1.6292589112",
              "daysToTrade2": "0.03",
              "daysToTrade22": "0.28",
              "daysToTrade22Export": "0.2757207388",
              "daysToTrade252": "1.93",
              "daysToTrade252Export": "1.933805",
              "daysToTrade2Export": "0.0250655217",
              "daysToTrade3": "0.04",
              "daysToTrade3Export": "0.0375982826",
              "daysToTrade5": "0.06",
              "daysToTrade5Export": "0.0626638043",
              "daysToTrade65": "0.81",
              "daysToTrade65Export": "0.8146294556",
              "edv": 1087984.8,
              "investorDtt": "155.3",
              "investorDttExport": "155.3",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 3999720.9479187997,
              "liquidationCostBps": 0.009347,
              "liquidationHorizon": 154.3,
              "pctOfNav": "1.93%",
              "pctOfNavExport": "1.933805%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 272.6,
              "priceVal": 0.33159,
              "securityName": "TRIP.COM GROUP LTD",
              "sharesHeld": 1569754,
              "ticker": "9961 HK EQUITY"
            },
            {
              "above252DaysToTrade": "92.21",
              "above252DaysToTradeExport": "92.2124063333",
              "bidAsk": 22643.4897307944,
              "bidAskBps": 1.1388,
              "dayToTrade1": "0.02",
              "dayToTrade1Export": "0.0244508435",
              "daysToTrade130": "1.8",
              "daysToTrade130Export": "1.797137",
              "daysToTrade2": "0.05",
              "daysToTrade22": "0.54",
              "daysToTrade22Export": "0.5379185578",
              "daysToTrade252": "1.8",
              "daysToTrade252Export": "1.797137",
              "daysToTrade2Export": "0.0489016871",
              "daysToTrade3": "0.07",
              "daysToTrade3Export": "0.0733525306",
              "daysToTrade5": "0.12",
              "daysToTrade5Export": "0.1222542177",
              "daysToTrade65": "1.59",
              "daysToTrade65Export": "1.5893048299",
              "edv": 279611465.1,
              "investorDtt": "74.5",
              "investorDttExport": "74.5",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "liquidationCost": 4951821.814679519,
              "liquidationCostBps": 0.012452,
              "liquidationHorizon": 73.5,
              "pctOfNav": "1.8%",
              "pctOfNavExport": "1.797137%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "price": 2.07,
              "priceVal": 0.54841,
              "securityName": "SENSETIME GROUP INC-CLASS B",
              "sharesHeld": 192112468,
              "ticker": "20 HK EQUITY"
            },
            {
              "above252DaysToTrade": "93.27",
              "above252DaysToTradeExport": "93.2736997608",
              "bidAsk": 276737.254400628,
              "bidAskBps": 15.3063,
              "dayToTrade1": "0.02",
              "dayToTrade1Export": "0.0186117882",
              "daysToTrade130": "1.63",
              "daysToTrade130Export": "1.634115",
              "daysToTrade2": "0.04",
              "daysToTrade22": "0.41",
              "daysToTrade22Export": "0.4094593394",
              "daysToTrade252": "1.63",
              "daysToTrade252Export": "1.634115",
              "daysToTrade2Export": "0.0372235763",
              "daysToTrade3": "0.06",
              "daysToTrade3Export": "0.0558353645",
              "daysToTrade5": "0.09",
              "daysToTrade5Export": "0.0930589408",
              "daysToTrade65": "1.21",
              "daysToTrade65Export": "1.2097662301",
              "edv": 3773715.2,
              "investorDtt": "88.8",
              "investorDttExport": "88.8",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "liquidationCost": 5575497.3123528,
              "liquidationCostBps": 0.015419,
              "liquidationHorizon": 87.8,
              "pctOfNav": "1.63%",
              "pctOfNavExport": "1.634115%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "price": 116.7,
              "priceVal": 0.72792,
              "securityName": "BILIBILI INC-CLASS Z",
              "sharesHeld": 3098536,
              "ticker": "9626 HK EQUITY"
            },
            {
              "above252DaysToTrade": "94.99",
              "above252DaysToTradeExport": "94.9892677568",
              "bidAsk": 128740.537022392,
              "bidAskBps": 7.6256,
              "dayToTrade1": "0.01",
              "dayToTrade1Export": "0.0085917905",
              "daysToTrade130": "1.12",
              "daysToTrade130Export": "1.1169327703",
              "daysToTrade2": "0.02",
              "daysToTrade22": "0.19",
              "daysToTrade22Export": "0.1890193919",
              "daysToTrade252": "1.53",
              "daysToTrade252Export": "1.525902",
              "daysToTrade2Export": "0.0171835811",
              "daysToTrade3": "0.03",
              "daysToTrade3Export": "0.0257753716",
              "daysToTrade5": "0.04",
              "daysToTrade5Export": "0.0429589527",
              "daysToTrade65": "0.56",
              "daysToTrade65Export": "0.5584663851",
              "edv": 6591264.5,
              "investorDtt": "178.6",
              "investorDttExport": "178.6",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 4530972.68753535,
              "liquidationCostBps": 0.013419,
              "liquidationHorizon": 177.6,
              "pctOfNav": "1.53%",
              "pctOfNavExport": "1.525902%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 30.85,
              "priceVal": 0.43529,
              "securityName": "KINGSOFT CORP LTD",
              "sharesHeld": 10945009,
              "ticker": "3888 HK EQUITY"
            },
            {
              "above252DaysToTrade": "95.47",
              "above252DaysToTradeExport": "95.4707707084",
              "bidAsk": 61242.1842429448,
              "bidAskBps": 3.6626,
              "dayToTrade1": "0.01",
              "dayToTrade1Export": "0.0066081548",
              "daysToTrade130": "0.86",
              "daysToTrade130Export": "0.8590601224",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.15",
              "daysToTrade22Export": "0.1453794053",
              "daysToTrade252": "1.51",
              "daysToTrade252Export": "1.511285",
              "daysToTrade2Export": "0.0132163096",
              "daysToTrade3": "0.02",
              "daysToTrade3Export": "0.0198244644",
              "daysToTrade5": "0.03",
              "daysToTrade5Export": "0.0330407739",
              "daysToTrade65": "0.43",
              "daysToTrade65Export": "0.4295300612",
              "edv": 14921255.7,
              "investorDtt": "229.7",
              "investorDttExport": "229.7",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 6630529.06123992,
              "liquidationCostBps": 0.019827,
              "liquidationHorizon": 228.7,
              "pctOfNav": "1.51%",
              "pctOfNavExport": "1.511285%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 10.48,
              "priceVal": 0.58058,
              "securityName": "KINGDEE INTERNATIONAL SFTWR",
              "sharesHeld": 31910227,
              "ticker": "268 HK EQUITY"
            },
            {
              "above252DaysToTrade": "95.6",
              "above252DaysToTradeExport": "95.5971928685",
              "bidAsk": 20671.7911760871,
              "bidAskBps": 1.4142,
              "dayToTrade1": "0.01",
              "dayToTrade1Export": "0.0077215137",
              "daysToTrade130": "1",
              "daysToTrade130Export": "1.0037967855",
              "daysToTrade2": "0.02",
              "daysToTrade22": "0.17",
              "daysToTrade22Export": "0.1698733022",
              "daysToTrade252": "1.32",
              "daysToTrade252Export": "1.321151",
              "daysToTrade2Export": "0.0154430275",
              "daysToTrade3": "0.02",
              "daysToTrade3Export": "0.0231645412",
              "daysToTrade5": "0.04",
              "daysToTrade5Export": "0.0386075687",
              "daysToTrade65": "0.5",
              "daysToTrade65Export": "0.5018983928",
              "edv": 38801846.4,
              "investorDtt": "172.1",
              "investorDttExport": "172.1",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 3781496.51905935,
              "liquidationCostBps": 0.012935,
              "liquidationHorizon": 171.1,
              "pctOfNav": "1.32%",
              "pctOfNavExport": "1.321151%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 4.71,
              "priceVal": 0.42231,
              "securityName": "ALIBABA HEALTH INFORMATION T",
              "sharesHeld": 62069231,
              "ticker": "241 HK EQUITY"
            },
            {
              "above252DaysToTrade": "96.54",
              "above252DaysToTradeExport": "96.5442590103",
              "bidAsk": 77301.7626144465,
              "bidAskBps": 7.2117,
              "dayToTrade1": "0.01",
              "dayToTrade1Export": "0.0066584605",
              "daysToTrade130": "0.87",
              "daysToTrade130Export": "0.8655998625",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.15",
              "daysToTrade22Export": "0.1464861306",
              "daysToTrade252": "0.97",
              "daysToTrade252Export": "0.968806",
              "daysToTrade2Export": "0.013316921",
              "daysToTrade3": "0.02",
              "daysToTrade3Export": "0.0199753814",
              "daysToTrade5": "0.03",
              "daysToTrade5Export": "0.0332923024",
              "daysToTrade65": "0.43",
              "daysToTrade65Export": "0.4327999313",
              "edv": 6651013,
              "investorDtt": "146.5",
              "investorDttExport": "146.5",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 1942485.8800269,
              "liquidationCostBps": 0.009061,
              "liquidationHorizon": 145.5,
              "pctOfNav": "0.97%",
              "pctOfNavExport": "0.968806%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 23.7,
              "priceVal": 0.3096,
              "securityName": "BYD ELECTRONIC INTL CO LTD",
              "sharesHeld": 9045517,
              "ticker": "285 HK EQUITY"
            },
            {
              "above252DaysToTrade": "97.27",
              "above252DaysToTradeExport": "97.2679610476",
              "bidAsk": 65791.971610512,
              "bidAskBps": 6.3224,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0.0037322937",
              "daysToTrade130": "0.49",
              "daysToTrade130Export": "0.4851981746",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.08",
              "daysToTrade22Export": "0.0821104603",
              "daysToTrade252": "0.94",
              "daysToTrade252Export": "0.940538",
              "daysToTrade2Export": "0.0074645873",
              "daysToTrade3": "0.01",
              "daysToTrade3Export": "0.011196881",
              "daysToTrade5": "0.02",
              "daysToTrade5Export": "0.0186614683",
              "daysToTrade65": "0.24",
              "daysToTrade65Export": "0.2425990873",
              "edv": 2235204.2,
              "investorDtt": "253",
              "investorDttExport": "253",
              "investorLiqClassification": "Investor DTT \u003e 252",
              "liquidationCost": 4835955.4989936,
              "liquidationCostBps": 0.023236,
              "liquidationHorizon": 252,
              "pctOfNav": "0.94%",
              "pctOfNavExport": "0.940538%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 21.3,
              "priceVal": 0.48901,
              "securityName": "ZHONGAN ONLINE P\u0026C INSURAN-H",
              "sharesHeld": 9771052,
              "ticker": "6060 HK EQUITY"
            },
            {
              "above252DaysToTrade": "96.53",
              "above252DaysToTradeExport": "96.5284290716",
              "bidAsk": 59803.141431552,
              "bidAskBps": 6.2327,
              "dayToTrade1": "0.01",
              "dayToTrade1Export": "0.0088764278",
              "daysToTrade130": "0.87",
              "daysToTrade130Export": "0.867227",
              "daysToTrade2": "0.02",
              "daysToTrade22": "0.2",
              "daysToTrade22Export": "0.1952814125",
              "daysToTrade252": "0.87",
              "daysToTrade252Export": "0.867227",
              "daysToTrade2Export": "0.0177528557",
              "daysToTrade3": "0.03",
              "daysToTrade3Export": "0.0266292835",
              "daysToTrade5": "0.04",
              "daysToTrade5Export": "0.0443821392",
              "daysToTrade65": "0.58",
              "daysToTrade65Export": "0.5769678096",
              "edv": 8211832.8,
              "investorDtt": "98.7",
              "investorDttExport": "98.7",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "liquidationCost": 1840332.8455679999,
              "liquidationCostBps": 0.00959,
              "liquidationHorizon": 97.7,
              "pctOfNav": "0.87%",
              "pctOfNavExport": "0.867227%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "price": 25.6,
              "priceVal": 0.39872,
              "securityName": "HUA HONG SEMICONDUCTOR LTD",
              "sharesHeld": 7496142,
              "ticker": "1347 HK EQUITY"
            },
            {
              "above252DaysToTrade": "97.4",
              "above252DaysToTradeExport": "97.4042821259",
              "bidAsk": 76118.100385872,
              "bidAskBps": 8.8224,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0.004544324",
              "daysToTrade130": "0.59",
              "daysToTrade130Export": "0.5907621212",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.1",
              "daysToTrade22Export": "0.0999751282",
              "daysToTrade252": "0.78",
              "daysToTrade252Export": "0.779806",
              "daysToTrade2Export": "0.009088648",
              "daysToTrade3": "0.01",
              "daysToTrade3Export": "0.013632972",
              "daysToTrade5": "0.02",
              "daysToTrade5Export": "0.02272162",
              "daysToTrade65": "0.3",
              "daysToTrade65Export": "0.2953810606",
              "edv": 3264867.4,
              "investorDtt": "172.6",
              "investorDttExport": "172.6",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 2837518.2325564,
              "liquidationCostBps": 0.016444,
              "liquidationHorizon": 171.6,
              "pctOfNav": "0.78%",
              "pctOfNavExport": "0.779806%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 32.95,
              "priceVal": 0.54645,
              "securityName": "CHINA LITERATURE LTD",
              "sharesHeld": 5236918,
              "ticker": "772 HK EQUITY"
            },
            {
              "above252DaysToTrade": "97.94",
              "above252DaysToTradeExport": "97.9432731441",
              "bidAsk": 22720.557862092,
              "bidAskBps": 2.991,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0.0029981441",
              "daysToTrade130": "0.39",
              "daysToTrade130Export": "0.3897587336",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.07",
              "daysToTrade22Export": "0.0659591703",
              "daysToTrade252": "0.69",
              "daysToTrade252Export": "0.686575",
              "daysToTrade2Export": "0.0059962882",
              "daysToTrade3": "0.01",
              "daysToTrade3Export": "0.0089944323",
              "daysToTrade5": "0.01",
              "daysToTrade5Export": "0.0149907205",
              "daysToTrade65": "0.19",
              "daysToTrade65Export": "0.1948793668",
              "edv": 3847888.5,
              "investorDtt": "230",
              "investorDttExport": "230",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 1844383.6338736,
              "liquidationCostBps": 0.01214,
              "liquidationHorizon": 229,
              "pctOfNav": "0.69%",
              "pctOfNavExport": "0.686575%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 18.44,
              "priceVal": 0.35258,
              "securityName": "AAC ACOUSTIC TECH HOLDINGS INC",
              "sharesHeld": 8238946,
              "ticker": "2018 HK EQUITY"
            },
            {
              "above252DaysToTrade": "98.21",
              "above252DaysToTradeExport": "98.2079477653",
              "bidAsk": 37425.4121044104,
              "bidAskBps": 5.6049,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0.0025659396",
              "daysToTrade130": "0.33",
              "daysToTrade130Export": "0.3335721514",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.06",
              "daysToTrade22Export": "0.0564506718",
              "daysToTrade252": "0.6",
              "daysToTrade252Export": "0.603509",
              "daysToTrade2Export": "0.0051318793",
              "daysToTrade3": "0.01",
              "daysToTrade3Export": "0.0076978189",
              "daysToTrade5": "0.01",
              "daysToTrade5Export": "0.0128296981",
              "daysToTrade65": "0.17",
              "daysToTrade65Export": "0.1667860757",
              "edv": 3206387.8,
              "investorDtt": "236.2",
              "investorDttExport": "236.2",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 1392076.5607820798,
              "liquidationCostBps": 0.010424,
              "liquidationHorizon": 235.2,
              "pctOfNav": "0.6%",
              "pctOfNavExport": "0.603509%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 18.94,
              "priceVal": 0.28463,
              "securityName": "PING AN HEALTHCARE AND TECHN",
              "sharesHeld": 7050968,
              "ticker": "1833 HK EQUITY"
            },
            {
              "above252DaysToTrade": "99.3",
              "above252DaysToTradeExport": "99.2952919049",
              "bidAsk": 6983.593354805,
              "bidAskBps": 3.0055,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0.0012485969",
              "daysToTrade130": "0.16",
              "daysToTrade130Export": "0.1623175981",
              "daysToTrade2": "0",
              "daysToTrade22": "0.03",
              "daysToTrade22Export": "0.027469132",
              "daysToTrade252": "0.21",
              "daysToTrade252Export": "0.210014",
              "daysToTrade2Export": "0.0024971938",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0.0037457907",
              "daysToTrade5": "0.01",
              "daysToTrade5Export": "0.0062429845",
              "daysToTrade65": "0.08",
              "daysToTrade65Export": "0.081158799",
              "edv": 2763095.5,
              "investorDtt": "169.2",
              "investorDttExport": "169.2",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 978469.8591610001,
              "liquidationCostBps": 0.021055,
              "liquidationHorizon": 168.2,
              "pctOfNav": "0.21%",
              "pctOfNavExport": "0.210014%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 10.7,
              "priceVal": 0.71843,
              "securityName": "GDS HOLDINGS LTD-CL A",
              "sharesHeld": 4343186,
              "ticker": "9698 HK EQUITY"
            },
            {
              "above252DaysToTrade": "98.99",
              "above252DaysToTradeExport": "98.9931710764",
              "bidAsk": 37739.6474947285,
              "bidAskBps": 17.2658,
              "dayToTrade1": "0.01",
              "dayToTrade1Export": "0.0065634219",
              "daysToTrade130": "0.2",
              "daysToTrade130Export": "0.197559",
              "daysToTrade2": "0.01",
              "daysToTrade22": "0.14",
              "daysToTrade22Export": "0.1443952824",
              "daysToTrade252": "0.2",
              "daysToTrade252Export": "0.197559",
              "daysToTrade2Export": "0.0131268439",
              "daysToTrade3": "0.02",
              "daysToTrade3Export": "0.0196902658",
              "daysToTrade5": "0.03",
              "daysToTrade5Export": "0.0328171096",
              "daysToTrade65": "0.2",
              "daysToTrade65Export": "0.197559",
              "edv": 2053696.8,
              "investorDtt": "31.1",
              "investorDttExport": "31.1",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "liquidationCost": 481969.68994125,
              "liquidationCostBps": 0.011025,
              "liquidationHorizon": 30.1,
              "pctOfNav": "0.2%",
              "pctOfNavExport": "0.197559%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "price": 75.85,
              "priceVal": 0.84203,
              "securityName": "NIO INC-CLASS A",
              "sharesHeld": 576349,
              "ticker": "9866 HK EQUITY"
            },
            {
              "above252DaysToTrade": "99.92",
              "above252DaysToTradeExport": "99.9204435488",
              "bidAsk": 14176.32834816,
              "bidAskBps": 52.8176,
              "dayToTrade1": "0",
              "dayToTrade1Export": "0.0001361336",
              "daysToTrade130": "0.02",
              "daysToTrade130Export": "0.0176973625",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0.0029949383",
              "daysToTrade252": "0.02",
              "daysToTrade252Export": "0.024259",
              "daysToTrade2Export": "0.0002722671",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0.0004084007",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0.0006806678",
              "daysToTrade65": "0.01",
              "daysToTrade65Export": "0.0088486813",
              "edv": 31600,
              "investorDtt": "179.2",
              "investorDttExport": "179.2",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "liquidationCost": 92738.120832,
              "liquidationCostBps": 0.017276,
              "liquidationHorizon": 178.2,
              "pctOfNav": "0.02%",
              "pctOfNavExport": "0.024259%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "price": 102.6,
              "priceVal": 0.54462,
              "securityName": "WEIBO CORP-CLASS A",
              "sharesHeld": 52320,
              "ticker": "9898 HK EQUITY"
            }
          ]
        }
      },
      "status": "success"
    }
  }

  getsoonDataMmf() {
    return {
      "data": {
        "assetClass": "MM",
        "investorAggr": {
          "above252DaysToTrade": "0%",
          "above252DaysToTradeExport": "0%",
          "accumlative1": "0%",
          "accumlative130": "0%",
          "accumlative130Export": "0%",
          "accumlative1Export": "0%",
          "accumlative2": "0%",
          "accumlative22": "0%",
          "accumlative22Export": "0%",
          "accumlative252": "0%",
          "accumlative252Export": "0%",
          "accumlative2Export": "0%",
          "accumlative3": "0%",
          "accumlative3Export": "0%",
          "accumlative5": "0%",
          "accumlative5Export": "0%",
          "accumlative65": "0%",
          "accumlative65Export": "0%",
          "accumlativeAbove252": "0%",
          "accumlativeAbove252Export": "0%",
          "cashExport": "0%",
          "cashExportExport": "0%",
          "dayToTrade1": "0%",
          "dayToTrade1Export": "0%",
          "daysToTrade130": "22.09%",
          "daysToTrade130Export": "22.091124%",
          "daysToTrade2": "0%",
          "daysToTrade22": "18.26%",
          "daysToTrade22Export": "18.260749%",
          "daysToTrade252": "12.88%",
          "daysToTrade252Export": "12.881312%",
          "daysToTrade2Export": "0%",
          "daysToTrade3": "19.09%",
          "daysToTrade3Export": "19.086104%",
          "daysToTrade5": "5.12%",
          "daysToTrade5Export": "5.115373%",
          "daysToTrade65": "22.57%",
          "daysToTrade65Export": "22.565342%",
          "securityClassification": "MMF"
        },
        "investorSFC": {
          "classificationType": "INVESTOR",
          "tier0": "0%",
          "tier0Export": "0%",
          "tier1": "24.2%",
          "tier1Export": "24.201477%",
          "tier2": "24.83%",
          "tier2Export": "24.831963%",
          "tier3": "32.94%",
          "tier3Export": "32.938485%",
          "tier4": "18.03%",
          "tier4Export": "18.028079%",
          "tier5": "0%",
          "tier5Export": "0%",
          "tier6": "0%",
          "tier6Export": "0%"
        },
        "liquidityCategory": {
          "HighThresholdCond": "60%",
          "HighThresholdCondExport": "60%",
          "highLiquidity": "3.2%",
          "highLiquidityExport": "3.19738%",
          "highLiquidityMonitoring": "Fail",
          "highThresholdCheck": "",
          "illiquid": "57.54%",
          "illiquidExport": "57.537778%",
          "illiquidMonitoring": "Fail",
          "illiquidThresholdCheck": "",
          "illiquidThresholdCond": "0%",
          "illiquidThresholdCondExport": "0%",
          "lowLiquidity": "18.26%",
          "lowLiquidityExport": "18.260749%",
          "lowLiquidityMonitoring": "",
          "lowThresholdCheck": "",
          "lowThresholdCond": "0%",
          "lowThresholdCondExport": "0%",
          "mediumLiquidity": "5.12%",
          "mediumLiquidityExport": "5.115373%",
          "mediumLiquidityMonitoring": "",
          "mediumThresholdCheck": "",
          "mediumThresholdCond": "0%",
          "mediumThresholdCondExport": "0%",
          "securityClassification": "MMF"
        },
        "liquidityCost": {
          "liquidationCost": 0,
          "monitoring": "",
          "pctOfCurrentAum": "0%",
          "pctOfCurrentAumExport": "0%",
          "securityClassification": "",
          "thresholdCheck": "",
          "thresholdCond": "0%",
          "thresholdCondExport": "0%"
        },
        "portfolioAggr": {
          "above252DaysToTrade": "0%",
          "above252DaysToTradeExport": "0%",
          "accumlative1": "15.89%",
          "accumlative130": "87.12%",
          "accumlative130Export": "87.118692%",
          "accumlative1Export": "15.888724%",
          "accumlative2": "15.89%",
          "accumlative22": "42.46%",
          "accumlative22Export": "42.462226%",
          "accumlative252": "77.91%",
          "accumlative252Export": "77.90888%",
          "accumlative2Export": "15.888724%",
          "accumlative3": "19.09%",
          "accumlative3Export": "19.086104%",
          "accumlative5": "24.2%",
          "accumlative5Export": "24.201477%",
          "accumlative65": "65.03%",
          "accumlative65Export": "65.027568%",
          "accumlativeAbove252": "77.91%",
          "accumlativeAbove252Export": "77.90888%",
          "cash": "15.89%",
          "cashExport": "15.888724%",
          "dayToTrade1": "0%",
          "dayToTrade1Export": "0%",
          "daysToTrade130": "22.09%",
          "daysToTrade130Export": "22.091124%",
          "daysToTrade2": "0%",
          "daysToTrade22": "18.26%",
          "daysToTrade22Export": "18.260749%",
          "daysToTrade252": "12.88%",
          "daysToTrade252Export": "12.881312%",
          "daysToTrade2Export": "0%",
          "daysToTrade3": "3.2%",
          "daysToTrade3Export": "3.19738%",
          "daysToTrade5": "5.12%",
          "daysToTrade5Export": "5.115373%",
          "daysToTrade65": "22.57%",
          "daysToTrade65Export": "22.565342%",
          "fiveDMonitoring": "Fail",
          "fiveDThreshold": "0%",
          "fiveDThresholdExport": "0%",
          "oneDMonitoring": "Pass",
          "oneDThreshold": "1%",
          "oneDThresholdExport": "1%",
          "securityClassification": "MMF",
          "twoDMonitoring": "Pass",
          "twoDThreshold": "2%",
          "twoDThresholdExport": "2%"
        },
        "portfolioSFC": {
          "classificationType": "PORTFOLIO",
          "tier0": "100%",
          "tier0Export": "100.000004%",
          "tier1": "0%",
          "tier1Export": "0%",
          "tier2": "0%",
          "tier2Export": "0%",
          "tier3": "0%",
          "tier3Export": "0%",
          "tier4": "0%",
          "tier4Export": "0%",
          "tier5": "0%",
          "tier5Export": "0%",
          "tier6": "0%",
          "tier6Export": "0%"
        },
        "productType": "ETF",
        "stockList": {
          "mmf": [
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "5.32",
              "daysToTrade130Export": "5.322115",
              "daysToTrade2": "0",
              "daysToTrade22": "5.32",
              "daysToTrade22Export": "5.322115",
              "daysToTrade252": "5.32",
              "daysToTrade252Export": "5.322115",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "5.32",
              "daysToTrade65Export": "5.322115",
              "investorDtt": "14",
              "investorDttExport": "14",
              "investorLiqClassification": "5 \u003c Investor DTT \u003c= 22",
              "maturityDate": "7/13/2023",
              "pctOfNav": "5.32%",
              "pctOfNavExport": "5.322115%",
              "portfolioLiqClassification": "5 \u003c LH \u003c= 22",
              "remainingMaturity": 13,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 247000000 to 13 Jul 23 at 3.65%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "4.32",
              "daysToTrade130Export": "4.321549",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "4.32",
              "daysToTrade252Export": "4.321549",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "89",
              "investorDttExport": "89",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "9/26/2023",
              "pctOfNav": "4.32%",
              "pctOfNavExport": "4.321549%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 88,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 202000000 to 26 Sep 23 at 5.35%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "4.3",
              "daysToTrade252Export": "4.297099",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "148",
              "investorDttExport": "148",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "maturityDate": "11/24/2023",
              "pctOfNav": "4.3%",
              "pctOfNavExport": "4.297099%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "remainingMaturity": 147,
              "securityName": "NOTE"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "4.28",
              "daysToTrade252Export": "4.282698",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "173",
              "investorDttExport": "173",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "maturityDate": "12/19/2023",
              "pctOfNav": "4.28%",
              "pctOfNavExport": "4.282698%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "remainingMaturity": 172,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 200000000 to 19 Dec 23 at 5.03%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "3.21",
              "daysToTrade130Export": "3.212182",
              "daysToTrade2": "0",
              "daysToTrade22": "3.21",
              "daysToTrade22Export": "3.212182",
              "daysToTrade252": "3.21",
              "daysToTrade252Export": "3.212182",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "3.21",
              "daysToTrade65Export": "3.212182",
              "investorDtt": "20",
              "investorDttExport": "20",
              "investorLiqClassification": "5 \u003c Investor DTT \u003c= 22",
              "maturityDate": "7/19/2023",
              "pctOfNav": "3.21%",
              "pctOfNavExport": "3.212182%",
              "portfolioLiqClassification": "5 \u003c LH \u003c= 22",
              "remainingMaturity": 19,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 150000000 to 19 Jul 23 at 5.18%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.93",
              "daysToTrade130Export": "2.933793",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.93",
              "daysToTrade252Export": "2.933793",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.93",
              "daysToTrade65Export": "2.933793",
              "investorDtt": "53",
              "investorDttExport": "53",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "8/21/2023",
              "pctOfNav": "2.93%",
              "pctOfNavExport": "2.933793%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 52,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 137000000 to 21 Aug 23 at 5.18%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.89",
              "daysToTrade130Export": "2.894311",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.89",
              "daysToTrade252Export": "2.894311",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "74",
              "investorDttExport": "74",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "9/11/2023",
              "pctOfNav": "2.89%",
              "pctOfNavExport": "2.894311%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 73,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 135000000 to 11 Sep 23 at 4.75%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.8",
              "daysToTrade130Export": "2.79999",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.8",
              "daysToTrade252Export": "2.79999",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.8",
              "daysToTrade65Export": "2.79999",
              "investorDtt": "25",
              "investorDttExport": "25",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "7/24/2023",
              "pctOfNav": "2.8%",
              "pctOfNavExport": "2.79999%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 24,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 130000000 to 24 Jul 23 at 3.8%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.39",
              "daysToTrade130Export": "2.389083",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.39",
              "daysToTrade252Export": "2.389083",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "130",
              "investorDttExport": "130",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "11/6/2023",
              "pctOfNav": "2.39%",
              "pctOfNavExport": "2.389083%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 129,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 111000000 to 06 Nov 23 at 4.2%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.31",
              "daysToTrade130Export": "2.308804",
              "daysToTrade2": "0",
              "daysToTrade22": "2.31",
              "daysToTrade22Export": "2.308804",
              "daysToTrade252": "2.31",
              "daysToTrade252Export": "2.308804",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "2.31",
              "daysToTrade5Export": "2.308804",
              "daysToTrade65": "2.31",
              "daysToTrade65Export": "2.308804",
              "investorDtt": "5",
              "investorDttExport": "5",
              "investorLiqClassification": "3 \u003c Investor DTT \u003c= 5",
              "maturityDate": "7/4/2023",
              "pctOfNav": "2.31%",
              "pctOfNavExport": "2.308804%",
              "portfolioLiqClassification": "3 \u003c LH \u003c= 5",
              "remainingMaturity": 4,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 107000000 to 04 Jul 23 at 3.87%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.19",
              "daysToTrade130Export": "2.185941",
              "daysToTrade2": "0",
              "daysToTrade22": "2.19",
              "daysToTrade22Export": "2.185941",
              "daysToTrade252": "2.19",
              "daysToTrade252Export": "2.185941",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.19",
              "daysToTrade65Export": "2.185941",
              "investorDtt": "18",
              "investorDttExport": "18",
              "investorLiqClassification": "5 \u003c Investor DTT \u003c= 22",
              "maturityDate": "7/17/2023",
              "pctOfNav": "2.19%",
              "pctOfNavExport": "2.185941%",
              "portfolioLiqClassification": "5 \u003c LH \u003c= 22",
              "remainingMaturity": 17,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 17 Jul 23 at 4.95%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.18",
              "daysToTrade130Export": "2.18392",
              "daysToTrade2": "0",
              "daysToTrade22": "2.18",
              "daysToTrade22Export": "2.18392",
              "daysToTrade252": "2.18",
              "daysToTrade252Export": "2.18392",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.18",
              "daysToTrade65Export": "2.18392",
              "investorDtt": "19",
              "investorDttExport": "19",
              "investorLiqClassification": "5 \u003c Investor DTT \u003c= 22",
              "maturityDate": "7/18/2023",
              "pctOfNav": "2.18%",
              "pctOfNavExport": "2.18392%",
              "portfolioLiqClassification": "5 \u003c LH \u003c= 22",
              "remainingMaturity": 18,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 18 Jul 23 at 4.8%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.18",
              "daysToTrade130Export": "2.182585",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.18",
              "daysToTrade252Export": "2.182585",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.18",
              "daysToTrade65Export": "2.182585",
              "investorDtt": "32",
              "investorDttExport": "32",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "7/31/2023",
              "pctOfNav": "2.18%",
              "pctOfNavExport": "2.182585%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 31,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 31 Jul 23 at 4.9%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.17",
              "daysToTrade130Export": "2.171192",
              "daysToTrade2": "0",
              "daysToTrade22": "2.17",
              "daysToTrade22Export": "2.171192",
              "daysToTrade252": "2.17",
              "daysToTrade252Export": "2.171192",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.17",
              "daysToTrade65Export": "2.171192",
              "investorDtt": "18",
              "investorDttExport": "18",
              "investorLiqClassification": "5 \u003c Investor DTT \u003c= 22",
              "maturityDate": "7/17/2023",
              "pctOfNav": "2.17%",
              "pctOfNavExport": "2.171192%",
              "portfolioLiqClassification": "5 \u003c LH \u003c= 22",
              "remainingMaturity": 17,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 101000000 to 17 Jul 23 at 4.41%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.16",
              "daysToTrade130Export": "2.164358",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.16",
              "daysToTrade252Export": "2.164358",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "85",
              "investorDttExport": "85",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "9/22/2023",
              "pctOfNav": "2.16%",
              "pctOfNavExport": "2.164358%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 84,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 22 Sep 23 at 4.4%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.16",
              "daysToTrade130Export": "2.162747",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.16",
              "daysToTrade252Export": "2.162747",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "89",
              "investorDttExport": "89",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "9/26/2023",
              "pctOfNav": "2.16%",
              "pctOfNavExport": "2.162747%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 88,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 26 Sep 23 at 4.3%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.15",
              "daysToTrade252Export": "2.151286",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "133",
              "investorDttExport": "133",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "maturityDate": "11/9/2023",
              "pctOfNav": "2.15%",
              "pctOfNavExport": "2.151286%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "remainingMaturity": 132,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 09 Nov 23 at 4.34%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.15",
              "daysToTrade130Export": "2.151203",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.15",
              "daysToTrade252Export": "2.151203",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.15",
              "daysToTrade65Export": "2.151203",
              "investorDtt": "34",
              "investorDttExport": "34",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "8/2/2023",
              "pctOfNav": "2.15%",
              "pctOfNavExport": "2.151203%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 33,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 02 Aug 23 at 3.81%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0",
              "daysToTrade130Export": "0",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.15",
              "daysToTrade252Export": "2.150229",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "139",
              "investorDttExport": "139",
              "investorLiqClassification": "130 \u003c Investor DTT \u003c= 252",
              "maturityDate": "11/15/2023",
              "pctOfNav": "2.15%",
              "pctOfNavExport": "2.150229%",
              "portfolioLiqClassification": "130 \u003c LH \u003c= 252",
              "remainingMaturity": 138,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 15 Nov 23 at 4.51%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.15",
              "daysToTrade130Export": "2.147188",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.15",
              "daysToTrade252Export": "2.147188",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.15",
              "daysToTrade65Export": "2.147188",
              "investorDtt": "29",
              "investorDttExport": "29",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "7/28/2023",
              "pctOfNav": "2.15%",
              "pctOfNavExport": "2.147188%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 28,
              "securityName": "NOTE"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.15",
              "daysToTrade130Export": "2.14616",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.15",
              "daysToTrade252Export": "2.14616",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.15",
              "daysToTrade65Export": "2.14616",
              "investorDtt": "64",
              "investorDttExport": "64",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "9/1/2023",
              "pctOfNav": "2.15%",
              "pctOfNavExport": "2.14616%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 63,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 01 Sep 23 at 4.75%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.15",
              "daysToTrade130Export": "2.145047",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.15",
              "daysToTrade252Export": "2.145047",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "68",
              "investorDttExport": "68",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "9/5/2023",
              "pctOfNav": "2.15%",
              "pctOfNavExport": "2.145047%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 67,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 05 Sep 23 at 4.75%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.14",
              "daysToTrade130Export": "2.144895",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "2.14",
              "daysToTrade252Export": "2.144895",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.14",
              "daysToTrade65Export": "2.144895",
              "investorDtt": "39",
              "investorDttExport": "39",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "8/7/2023",
              "pctOfNav": "2.14%",
              "pctOfNavExport": "2.144895%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 38,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 100000000 to 07 Aug 23 at 4.65%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.12",
              "daysToTrade130Export": "2.124524",
              "daysToTrade2": "0",
              "daysToTrade22": "2.12",
              "daysToTrade22Export": "2.124524",
              "daysToTrade252": "2.12",
              "daysToTrade252Export": "2.124524",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "2.12",
              "daysToTrade5Export": "2.124524",
              "daysToTrade65": "2.12",
              "daysToTrade65Export": "2.124524",
              "investorDtt": "4",
              "investorDttExport": "4",
              "investorLiqClassification": "3 \u003c Investor DTT \u003c= 5",
              "maturityDate": "7/3/2023",
              "pctOfNav": "2.12%",
              "pctOfNavExport": "2.124524%",
              "portfolioLiqClassification": "2 \u003c LH \u003c= 3",
              "remainingMaturity": 3,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 99000000 to 03 Jul 23 at 4.65%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "2.02",
              "daysToTrade130Export": "2.016201",
              "daysToTrade2": "0",
              "daysToTrade22": "2.02",
              "daysToTrade22Export": "2.016201",
              "daysToTrade252": "2.02",
              "daysToTrade252Export": "2.016201",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "2.02",
              "daysToTrade65Export": "2.016201",
              "investorDtt": "6",
              "investorDttExport": "6",
              "investorLiqClassification": "5 \u003c Investor DTT \u003c= 22",
              "maturityDate": "7/5/2023",
              "pctOfNav": "2.02%",
              "pctOfNavExport": "2.016201%",
              "portfolioLiqClassification": "3 \u003c LH \u003c= 5",
              "remainingMaturity": 5,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 94000000 to 05 Jul 23 at 4.65%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "1.96",
              "daysToTrade130Export": "1.963293",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "1.96",
              "daysToTrade252Export": "1.963293",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "1.96",
              "daysToTrade65Export": "1.963293",
              "investorDtt": "36",
              "investorDttExport": "36",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "8/4/2023",
              "pctOfNav": "1.96%",
              "pctOfNavExport": "1.963293%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 35,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 90000000 to 04 Aug 23 at 4.9%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "1.88",
              "daysToTrade130Export": "1.875252",
              "daysToTrade2": "0",
              "daysToTrade22": "1.88",
              "daysToTrade22Export": "1.875252",
              "daysToTrade252": "1.88",
              "daysToTrade252Export": "1.875252",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "1.88",
              "daysToTrade65Export": "1.875252",
              "investorDtt": "12",
              "investorDttExport": "12",
              "investorLiqClassification": "5 \u003c Investor DTT \u003c= 22",
              "maturityDate": "7/11/2023",
              "pctOfNav": "1.88%",
              "pctOfNavExport": "1.875252%",
              "portfolioLiqClassification": "5 \u003c LH \u003c= 22",
              "remainingMaturity": 11,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 87000000 to 11 Jul 23 at 3.72%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "1.61",
              "daysToTrade130Export": "1.613086",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "1.61",
              "daysToTrade252Export": "1.613086",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "1.61",
              "daysToTrade65Export": "1.613086",
              "investorDtt": "40",
              "investorDttExport": "40",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "8/8/2023",
              "pctOfNav": "1.61%",
              "pctOfNavExport": "1.613086%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 39,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 75000000 to 08 Aug 23 at 4.1%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "1.51",
              "daysToTrade130Export": "1.508632",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "1.51",
              "daysToTrade252Export": "1.508632",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "117",
              "investorDttExport": "117",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "10/24/2023",
              "pctOfNav": "1.51%",
              "pctOfNavExport": "1.508632%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 116,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 70000000 to 24 Oct 23 at 4.12%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "1.5",
              "daysToTrade130Export": "1.501338",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "1.5",
              "daysToTrade252Export": "1.501338",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "69",
              "investorDttExport": "69",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "9/6/2023",
              "pctOfNav": "1.5%",
              "pctOfNavExport": "1.501338%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 68,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 70000000 to 06 Sep 23 at 4.75%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "1.26",
              "daysToTrade130Export": "1.256561",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "1.26",
              "daysToTrade252Export": "1.256561",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "76",
              "investorDttExport": "76",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "9/13/2023",
              "pctOfNav": "1.26%",
              "pctOfNavExport": "1.256561%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 75,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 58000000 to 13 Sep 23 at 4.45%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "1.09",
              "daysToTrade130Export": "1.094848",
              "daysToTrade2": "0",
              "daysToTrade22": "1.09",
              "daysToTrade22Export": "1.094848",
              "daysToTrade252": "1.09",
              "daysToTrade252Export": "1.094848",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "1.09",
              "daysToTrade65Export": "1.094848",
              "investorDtt": "11",
              "investorDttExport": "11",
              "investorLiqClassification": "5 \u003c Investor DTT \u003c= 22",
              "maturityDate": "7/10/2023",
              "pctOfNav": "1.09%",
              "pctOfNavExport": "1.094848%",
              "portfolioLiqClassification": "5 \u003c LH \u003c= 22",
              "remainingMaturity": 10,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 50000000 to 10 Jul 23 at 5.15%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "1.07",
              "daysToTrade130Export": "1.072856",
              "daysToTrade2": "0",
              "daysToTrade22": "1.07",
              "daysToTrade22Export": "1.072856",
              "daysToTrade252": "1.07",
              "daysToTrade252Export": "1.072856",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "1.07",
              "daysToTrade5Export": "1.072856",
              "daysToTrade65": "1.07",
              "daysToTrade65Export": "1.072856",
              "investorDtt": "4",
              "investorDttExport": "4",
              "investorLiqClassification": "3 \u003c Investor DTT \u003c= 5",
              "maturityDate": "7/3/2023",
              "pctOfNav": "1.07%",
              "pctOfNavExport": "1.072856%",
              "portfolioLiqClassification": "2 \u003c LH \u003c= 3",
              "remainingMaturity": 3,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 50000000 to 03 Jul 23 at 4.65%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0.97",
              "daysToTrade130Export": "0.968744",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0.97",
              "daysToTrade252Export": "0.968744",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0.97",
              "daysToTrade65Export": "0.968744",
              "investorDtt": "26",
              "investorDttExport": "26",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "7/25/2023",
              "pctOfNav": "0.97%",
              "pctOfNavExport": "0.968744%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 25,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 45000000 to 25 Jul 23 at 3.81%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0.86",
              "daysToTrade130Export": "0.859113",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0.86",
              "daysToTrade252Export": "0.859113",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0.86",
              "daysToTrade65Export": "0.859113",
              "investorDtt": "57",
              "investorDttExport": "57",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "8/25/2023",
              "pctOfNav": "0.86%",
              "pctOfNavExport": "0.859113%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 56,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 40000000 to 25 Aug 23 at 4.6%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0.79",
              "daysToTrade130Export": "0.790368",
              "daysToTrade2": "0",
              "daysToTrade22": "0.79",
              "daysToTrade22Export": "0.790368",
              "daysToTrade252": "0.79",
              "daysToTrade252Export": "0.790368",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0.79",
              "daysToTrade5Export": "0.790368",
              "daysToTrade65": "0.79",
              "daysToTrade65Export": "0.790368",
              "investorDtt": "5",
              "investorDttExport": "5",
              "investorLiqClassification": "3 \u003c Investor DTT \u003c= 5",
              "maturityDate": "7/4/2023",
              "pctOfNav": "0.79%",
              "pctOfNavExport": "0.790368%",
              "portfolioLiqClassification": "3 \u003c LH \u003c= 5",
              "remainingMaturity": 4,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 36000000 to 04 Jul 23 at 5.53%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0.75",
              "daysToTrade130Export": "0.753782",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0.75",
              "daysToTrade252Export": "0.753782",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "119",
              "investorDttExport": "119",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "10/26/2023",
              "pctOfNav": "0.75%",
              "pctOfNavExport": "0.753782%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 118,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 35000000 to 26 Oct 23 at 4.1%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0.66",
              "daysToTrade130Export": "0.655292",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0.66",
              "daysToTrade252Export": "0.655292",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0.66",
              "daysToTrade65Export": "0.655292",
              "investorDtt": "26",
              "investorDttExport": "26",
              "investorLiqClassification": "22 \u003c Investor DTT \u003c= 65",
              "maturityDate": "7/25/2023",
              "pctOfNav": "0.66%",
              "pctOfNavExport": "0.655292%",
              "portfolioLiqClassification": "22 \u003c LH \u003c= 65",
              "remainingMaturity": 25,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 30000000 to 25 Jul 23 at 4.9%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0.5",
              "daysToTrade130Export": "0.49527",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0.5",
              "daysToTrade252Export": "0.49527",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "120",
              "investorDttExport": "120",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "10/27/2023",
              "pctOfNav": "0.5%",
              "pctOfNavExport": "0.49527%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 119,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 23000000 to 27 Oct 23 at 4.08%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0.28",
              "daysToTrade130Export": "0.281799",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0.28",
              "daysToTrade252Export": "0.281799",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "67",
              "investorDttExport": "67",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "9/4/2023",
              "pctOfNav": "0.28%",
              "pctOfNavExport": "0.281799%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 66,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 13000000 to 04 Sep 23 at 4.25%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0.22",
              "daysToTrade130Export": "0.216647",
              "daysToTrade2": "0",
              "daysToTrade22": "0",
              "daysToTrade22Export": "0",
              "daysToTrade252": "0.22",
              "daysToTrade252Export": "0.216647",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0",
              "daysToTrade65Export": "0",
              "investorDtt": "74",
              "investorDttExport": "74",
              "investorLiqClassification": "65 \u003c Investor DTT \u003c= 130",
              "maturityDate": "9/11/2023",
              "pctOfNav": "0.22%",
              "pctOfNavExport": "0.216647%",
              "portfolioLiqClassification": "65 \u003c LH \u003c= 130",
              "remainingMaturity": 73,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 10000000 to 11 Sep 23 at 4.33%"
            },
            {
              "above252DaysToTrade": "0",
              "above252DaysToTradeExport": "0",
              "dayToTrade1": "0",
              "dayToTrade1Export": "0",
              "daysToTrade130": "0.22",
              "daysToTrade130Export": "0.215299",
              "daysToTrade2": "0",
              "daysToTrade22": "0.22",
              "daysToTrade22Export": "0.215299",
              "daysToTrade252": "0.22",
              "daysToTrade252Export": "0.215299",
              "daysToTrade2Export": "0",
              "daysToTrade3": "0",
              "daysToTrade3Export": "0",
              "daysToTrade5": "0",
              "daysToTrade5Export": "0",
              "daysToTrade65": "0.22",
              "daysToTrade65Export": "0.215299",
              "investorDtt": "22",
              "investorDttExport": "22",
              "investorLiqClassification": "5 \u003c Investor DTT \u003c= 22",
              "maturityDate": "7/21/2023",
              "pctOfNav": "0.22%",
              "pctOfNavExport": "0.215299%",
              "portfolioLiqClassification": "5 \u003c LH \u003c= 22",
              "remainingMaturity": 21,
              "securityName": "HKD - Hong Kong Dollar RepDeposit of 10000000 to 21 Jul 23 at 3.65%"
            }
          ]
        }
      },
      "status": "success"
    }
  }

  getOverrideData() {
    return {
      "status": "success",
      "data": []
      //   "data": [ 
      //     {
      //       date:"2022-01-23T05:30:00+05:30",
      //       description:"Dragon Boat Festival",
      //       calCode:"HKD",
      //     },
      //     {
      //       date:"2022-08-15T05:30:00+05:30",
      //       description:"Independance Day",
      //       calCode:"CNY",
      //       action:false
      //     },
      //     {
      //     date:"2022-10-19T05:30:00+05:30",
      //     description:"Vinayaka Chavithi",
      //     calCode:"HK",
      //     action:true
      //   },
      //   {
      //     date:"2022-12-01T05:30:00+05:30",
      //     description:"International AIDS Day",
      //     calCode:"GK",
      //     action:true
      //   },
      // ]
    }
  }

  getSettlementConfigData() {
    return {
      "status": "success",
      "data": []
      //   "data": [ 
      //     {
      //       ccy:"HKD",
      //       crrd:"IN_CSH",
      //       calCodes:["HKD","CNY"],
      //       cond:"AND"
      //     },
      //     {
      //       ccy:"AED",
      //       crrd:"PRT_ICSH",
      //       calCodes:["SGD","USD"],
      //       cond:"OR"
      //     },
      //     {
      //       ccy:"ARS",
      //       crrd:"PRT_IKIND",
      //       calCodes:["CN","HK"],
      //       cond:"AND"
      //     },
      //     {
      //       ccy:"AOA",
      //       crrd:"TRUS_IKIND",
      //       calCodes:["SG","US"],
      //       cond:"OR"
      //     },
      // ]
    }
  }

  getDealingOverrideData() {
    return {
      "dealingCalendarOverride": [
        {
          "activeInd": true,
          "holidayCalcDesc": "My BirthDay",
          "holidayDate": "2023-09-17T00:00:00Z",
          "holidayYear": "2023",
          "holidayCode": "-"
        },
        {
          "holidayCalcDesc": "The day following the first day of January",
          "holidayCode": "HK",
          "holidayDate": "2023-01-02T00:00:00Z",
          "holidayYear": "2023"
        },
        {
          "holidayCalcDesc": "The second day of Lunar New Year",
          "holidayCode": "HK",
          "holidayDate": "2023-01-23T00:00:00Z",
          "holidayYear": "2023"
        },
        {
          "holidayCalcDesc": "The third day of Lunar New Year",
          "holidayCode": "HK",
          "holidayDate": "2023-01-24T00:00:00Z",
          "holidayYear": "2023"
        },
        {
          "holidayCalcDesc": "Good Friday",
          "holidayCode": "HK",
          "holidayDate": "2023-04-07T00:00:00Z",
          "holidayYear": "2023"
        },
        {
          "holidayCalcDesc": "Labour Day",
          "holidayCode": "HK",
          "holidayDate": "2023-05-01T00:00:00Z",
          "holidayYear": "2023"
        },
        {
          "holidayCalcDesc": "Christmas Day",
          "holidayCode": "HK",
          "holidayDate": "2023-12-25T00:00:00Z",
          "holidayYear": "2023"
        }

      ],
      "status": "success"
    }
  }

  getRetreatFromPeakData() {
    return {

      "data": [

        {

          "acctholdingID": 17057,

          "assetClass": "EQTY",

          "ccy": "CNY",

          "cps": 12.36,

          "inceptionDate": "2023-08-31T00:00:00Z",

          "instrumentID": "1000401",

          "lossPct": -13.0145631068,

          "marketPrice": 10.7514,
          "maxMarketPrice": 10.75146,

          "matchedRulesDetails": [

            {

              "key": "-10.0% to -20.0%",

              "value": 1

            }

          ],

          "quantity": 13168717,

          "securityDesc": "ZIJIN MINING GROUP CO LTD-A",

          "ticker": "601899 CG EQUITY",

          "unrealized": -21183198.16619999,

          "versionDate": "2023-09-30T11:53:02.9896998Z"

        },

        {

          "acctholdingID": 17057,

          "assetClass": "EQTY",

          "ccy": "CNY",

          "cps": 1847,

          "inceptionDate": "2023-08-31T00:00:00Z",

          "instrumentID": "1000433",

          "lossPct": -21.7836762317,

          "marketPrice": 1444.6555,
          "maxMarketPrice": 10.756,

          "matchedRulesDetails": [

            {

              "key": "-20.0% to -30.0%",

              "value": 1

            }

          ],

          "quantity": 803186,

          "securityDesc": "KWEICHOW MOUTAI CO LTD-A",

          "ticker": "600519 CG EQUITY",

          "unrealized": -323157469.57699996,

          "versionDate": "2023-09-30T11:53:02.9898553Z"

        },

        {

          "acctholdingID": 17057,

          "assetClass": "EQTY",

          "ccy": "CNY",

          "cps": 28.17,

          "inceptionDate": "2023-08-31T00:00:00Z",

          "instrumentID": "1000436",

          "lossPct": -16.3631522897,

          "marketPrice": 23.5605,
          "maxMarketPrice": 10.146,

          "matchedRulesDetails": [

            {

              "key": "-10.0% to -20.0%",

              "value": 1

            }

          ],

          "quantity": 4519781,

          "securityDesc": "CHINA SHENHUA ENERGY CO-A",

          "ticker": "601088 CG EQUITY",

          "unrealized": -20833930.519500002,

          "versionDate": "2023-09-30T11:53:02.9899431Z"

        },

        {

          "acctholdingID": 17057,

          "assetClass": "EQTY",

          "ccy": "CNY",

          "cps": 21.91,

          "inceptionDate": "2023-08-31T00:00:00Z",

          "instrumentID": "1000523",

          "lossPct": -40.8032861707,

          "marketPrice": 12.97,
          "maxMarketPrice": 10.46,

          "matchedRulesDetails": [

            {

              "key": "-40.0% to -50.0%",

              "value": 1

            }

          ],

          "quantity": 7986315,

          "securityDesc": "FOXCONN INDUSTRIAL INTERNE-A",

          "ticker": "601138 CG EQUITY",

          "unrealized": -71397656.1,

          "versionDate": "2023-09-30T11:53:02.9898941Z"

        },

        {

          "acctholdingID": 17057,

          "assetClass": "EQTY",

          "ccy": "CNY",

          "cps": 7.75,

          "inceptionDate": "2023-08-31T00:00:00Z",

          "instrumentID": "1000540",

          "lossPct": -26.3329032258,

          "marketPrice": 5.7092,
          "maxMarketPrice": 10.146,

          "matchedRulesDetails": [

            {

              "key": "-20.0% to -30.0%",

              "value": 1

            }

          ],

          "quantity": 15925300,

          "securityDesc": "PETROCHINA CO LTD-A",

          "ticker": "601857 CG EQUITY",

          "unrealized": -32500352.24,

          "versionDate": "2023-09-30T11:53:02.9898253Z"

        },

        {

          "acctholdingID": 17057,

          "assetClass": "EQTY",

          "ccy": "CNY",

          "cps": 15.87,

          "inceptionDate": "2023-08-31T00:00:00Z",

          "instrumentID": "1001598",

          "lossPct": -11.2161310649,

          "marketPrice": 14.09,
          "maxMarketPrice": 10.5146,

          "matchedRulesDetails": [

            {

              "key": "-10.0% to -20.0%",

              "value": 1

            }

          ],

          "quantity": 10141902,

          "securityDesc": "EAST MONEY INFORMATION CO-A",

          "ticker": "300059 C2 EQUITY",

          "unrealized": -18052585.559999995,

          "versionDate": "2023-09-30T11:53:02.9899111Z"

        },

        {

          "acctholdingID": 17057,

          "assetClass": "EQTY",

          "ccy": "CNY",

          "cps": 81.5,

          "inceptionDate": "2023-08-31T00:00:00Z",

          "instrumentID": "1001773",

          "lossPct": -21.963190184,

          "marketPrice": 63.6,
          "maxMarketPrice": 10.75146,

          "matchedRulesDetails": [

            {

              "key": "-20.0% to -30.0%",

              "value": 1

            }

          ],

          "quantity": 1640319,

          "securityDesc": "WUXI APPTEC CO LTD-A",

          "ticker": "603259 C1 EQUITY",

          "unrealized": -29361710.099999998,

          "versionDate": "2023-09-30T11:53:02.9897495Z"

        }

      ],

      "status": "success"

    }
  }


  getDataForStopLoss() {
    return {
      "assetClass": "FI",
      "bondHoldings": [
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 12.36,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1000401",
          "lossPct": -13.0145631068,
          "marketPrice": 10.7514,
          "matchedRulesDetails": [
            {
              "key": "-10.0% to -20.0%",
              "value": 1
            }
          ],
          "quantity": 13168717,
          "securityDesc": "ZIJIN MINING GROUP CO LTD-A",
          "ticker": "601899 CG EQUITY",
          "unrealized": -21183198.16619999,
          "versionDate": "2023-09-30T11:53:02.9896998Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 1847,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1000433",
          "lossPct": -21.7836762317,
          "marketPrice": 1444.6555,
          "matchedRulesDetails": [
            {
              "key": "-20.0% to -30.0%",
              "value": 1
            }
          ],
          "quantity": 803186,
          "securityDesc": "KWEICHOW MOUTAI CO LTD-A",
          "ticker": "600519 CG EQUITY",
          "unrealized": -323157469.57699996,
          "versionDate": "2023-09-30T11:53:02.9898553Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 28.17,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1000436",
          "lossPct": -16.3631522897,
          "marketPrice": 23.5605,
          "matchedRulesDetails": [
            {
              "key": "-10.0% to -20.0%",
              "value": 1
            }
          ],
          "quantity": 4519781,
          "securityDesc": "CHINA SHENHUA ENERGY CO-A",
          "ticker": "601088 CG EQUITY",
          "unrealized": -20833930.519500002,
          "versionDate": "2023-09-30T11:53:02.9899431Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 21.91,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1000523",
          "lossPct": -40.8032861707,
          "marketPrice": 12.97,
          "matchedRulesDetails": [
            {
              "key": "-40.0% to -50.0%",
              "value": 1
            }
          ],
          "quantity": 7986315,
          "securityDesc": "FOXCONN INDUSTRIAL INTERNE-A",
          "ticker": "601138 CG EQUITY",
          "unrealized": -71397656.1,
          "versionDate": "2023-09-30T11:53:02.9898941Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 7.75,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1000540",
          "lossPct": -26.3329032258,
          "marketPrice": 5.7092,
          "matchedRulesDetails": [
            {
              "key": "-20.0% to -30.0%",
              "value": 1
            }
          ],
          "quantity": 15925300,
          "securityDesc": "PETROCHINA CO LTD-A",
          "ticker": "601857 CG EQUITY",
          "unrealized": -32500352.24,
          "versionDate": "2023-09-30T11:53:02.9898253Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 15.87,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1001598",
          "lossPct": -11.2161310649,
          "marketPrice": 14.09,
          "matchedRulesDetails": [
            {
              "key": "-10.0% to -20.0%",
              "value": 1
            }
          ],
          "quantity": 10141902,
          "securityDesc": "EAST MONEY INFORMATION CO-A",
          "ticker": "300059 C2 EQUITY",
          "unrealized": -18052585.559999995,
          "versionDate": "2023-09-30T11:53:02.9899111Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 81.5,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1001773",
          "lossPct": -21.963190184,
          "marketPrice": 63.6,
          "matchedRulesDetails": [
            {
              "key": "-20.0% to -30.0%",
              "value": 1
            }
          ],
          "quantity": 1640319,
          "securityDesc": "WUXI APPTEC CO LTD-A",
          "ticker": "603259 C1 EQUITY",
          "unrealized": -29361710.099999998,
          "versionDate": "2023-09-30T11:53:02.9897495Z"
        }
      ],
      "equityHoldings": [
        // {
        //   "acctholdingID": 17057,
        //   "assetClass": "EQTY",
        //   "ccy": "CNY",
        //   "cps": 12.36,
        //   "inceptionDate": "2023-08-31T00:00:00Z",
        //   "instrumentID": "1000401",
        //   "lossPct": -13.0145631068,
        //   "marketPrice": 10.7514,
        //   "matchedRulesDetails": [
        //     {
        //       "key": "-10.0% to -20.0%",
        //       "value": 1
        //     }
        //   ],
        //   "quantity": 13168717,
        //   "securityDesc": "ZIJIN MINING GROUP CO LTD-A",
        //   "ticker": "601899 CG EQUITY",
        //   "unrealized": -21183198.16619999,
        //   "versionDate": "2023-09-30T11:53:02.9896998Z"
        // },
        // {
        //   "acctholdingID": 17057,
        //   "assetClass": "EQTY",
        //   "ccy": "CNY",
        //   "cps": 1847,
        //   "inceptionDate": "2023-08-31T00:00:00Z",
        //   "instrumentID": "1000433",
        //   "lossPct": -21.7836762317,
        //   "marketPrice": 1444.6555,
        //   "matchedRulesDetails": [
        //     {
        //       "key": "-20.0% to -30.0%",
        //       "value": 1
        //     }
        //   ],
        //   "quantity": 803186,
        //   "securityDesc": "KWEICHOW MOUTAI CO LTD-A",
        //   "ticker": "600519 CG EQUITY",
        //   "unrealized": -323157469.57699996,
        //   "versionDate": "2023-09-30T11:53:02.9898553Z"
        // },
        // {
        //   "acctholdingID": 17057,
        //   "assetClass": "EQTY",
        //   "ccy": "CNY",
        //   "cps": 28.17,
        //   "inceptionDate": "2023-08-31T00:00:00Z",
        //   "instrumentID": "1000436",
        //   "lossPct": -16.3631522897,
        //   "marketPrice": 23.5605,
        //   "matchedRulesDetails": [
        //     {
        //       "key": "-10.0% to -20.0%",
        //       "value": 1
        //     }
        //   ],
        //   "quantity": 4519781,
        //   "securityDesc": "CHINA SHENHUA ENERGY CO-A",
        //   "ticker": "601088 CG EQUITY",
        //   "unrealized": -20833930.519500002,
        //   "versionDate": "2023-09-30T11:53:02.9899431Z"
        // },
        // {
        //   "acctholdingID": 17057,
        //   "assetClass": "EQTY",
        //   "ccy": "CNY",
        //   "cps": 21.91,
        //   "inceptionDate": "2023-08-31T00:00:00Z",
        //   "instrumentID": "1000523",
        //   "lossPct": -40.8032861707,
        //   "marketPrice": 12.97,
        //   "matchedRulesDetails": [
        //     {
        //       "key": "-40.0% to -50.0%",
        //       "value": 1
        //     }
        //   ],
        //   "quantity": 7986315,
        //   "securityDesc": "FOXCONN INDUSTRIAL INTERNE-A",
        //   "ticker": "601138 CG EQUITY",
        //   "unrealized": -71397656.1,
        //   "versionDate": "2023-09-30T11:53:02.9898941Z"
        // },
      ],
      "investmentStrategyInd": "A",
      "productType": "ETF",
      "status": "success"
    }
  }

  getDataForRpeak() {
    return {
      "assetClass": "FI",
      "bondHoldings": [
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 12.36,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1000401",
          "retreatValue": -13.0145631068,
          "marketPrice": 10.7514,
          maxMarketPrice: 11,
          "matchedRulesDetails": [
            {
              "key": "-10.0% to -20.0%",
              "value": 1
            }
          ],
          "quantity": 13168717,
          "securityDesc": "ZIJIN MINING GROUP CO LTD-A",
          "ticker": "601899 CG EQUITY",
          "unrealized": -21183198.16619999,
          "versionDate": "2023-09-30T11:53:02.9896998Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 1847,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1000433",
          "retreatValue": -21.7836762317,
          "marketPrice": 1444.6555,
          maxMarketPrice: 15,
          "matchedRulesDetails": [
            {
              "key": "-20.0% to -30.0%",
              "value": 1
            }
          ],
          "quantity": 803186,
          "securityDesc": "KWEICHOW MOUTAI CO LTD-A",
          "ticker": "600519 CG EQUITY",
          "unrealized": -323157469.57699996,
          "versionDate": "2023-09-30T11:53:02.9898553Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 28.17,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1000436",
          "retreatValue": -16.3631522897,
          "marketPrice": 23.5605,
          maxMarketPrice: 24,
          "matchedRulesDetails": [
            {
              "key": "-10.0% to -20.0%",
              "value": 1
            }
          ],
          "quantity": 4519781,
          "securityDesc": "CHINA SHENHUA ENERGY CO-A",
          "ticker": "601088 CG EQUITY",
          "unrealized": -20833930.519500002,
          "versionDate": "2023-09-30T11:53:02.9899431Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 21.91,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1000523",
          "retreatValue": -40.8032861707,
          "marketPrice": 12.97,
          maxMarketPrice: 13,
          "matchedRulesDetails": [
            {
              "key": "-40.0% to -50.0%",
              "value": 1
            }
          ],
          "quantity": 7986315,
          "securityDesc": "FOXCONN INDUSTRIAL INTERNE-A",
          "ticker": "601138 CG EQUITY",
          "unrealized": -71397656.1,
          "versionDate": "2023-09-30T11:53:02.9898941Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 7.75,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1000540",
          "retreatValue": -26.3329032258,
          "marketPrice": 5.7092,
          maxMarketPrice: 6,
          "matchedRulesDetails": [
            {
              "key": "-20.0% to -30.0%",
              "value": 1
            }
          ],
          "quantity": 15925300,
          "securityDesc": "PETROCHINA CO LTD-A",
          "ticker": "601857 CG EQUITY",
          "unrealized": -32500352.24,
          "versionDate": "2023-09-30T11:53:02.9898253Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 15.87,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1001598",
          "retreatValue": -11.2161310649,
          "marketPrice": 14.09,
          maxMarketPrice: 15,
          "matchedRulesDetails": [
            {
              "key": "-10.0% to -20.0%",
              "value": 1
            }
          ],
          "quantity": 10141902,
          "securityDesc": "EAST MONEY INFORMATION CO-A",
          "ticker": "300059 C2 EQUITY",
          "unrealized": -18052585.559999995,
          "versionDate": "2023-09-30T11:53:02.9899111Z"
        },
        {
          "acctholdingID": 17057,
          "assetClass": "EQTY",
          "ccy": "CNY",
          "cps": 81.5,
          "inceptionDate": "2023-08-31T00:00:00Z",
          "instrumentID": "1001773",
          "retreatValue": -21.963190184,
          "marketPrice": 63.6,
          maxMarketPrice: 64,
          "matchedRulesDetails": [
            {
              "key": "-20.0% to -30.0%",
              "value": 1
            }
          ],
          "quantity": 1640319,
          "securityDesc": "WUXI APPTEC CO LTD-A",
          "ticker": "603259 C1 EQUITY",
          "unrealized": -29361710.099999998,
          "versionDate": "2023-09-30T11:53:02.9897495Z"
        }
      ],
      "equityHoldings": [
        // {
        //   "acctholdingID": 17057,
        //   "assetClass": "EQTY",
        //   "ccy": "CNY",
        //   "cps": 12.36,
        //   "inceptionDate": "2023-08-31T00:00:00Z",
        //   "instrumentID": "1000401",
        //   "retreatValue": -13.0145631068,
        //   "marketPrice": 10.7514,
        //   maxMarketPrice:11,
        //   "matchedRulesDetails": [
        //     {
        //       "key": "-10.0% to -20.0%",
        //       "value": 1
        //     }
        //   ],
        //   "quantity": 13168717,
        //   "securityDesc": "ZIJIN MINING GROUP CO LTD-A",
        //   "ticker": "601899 CG EQUITY",
        //   "unrealized": -21183198.16619999,
        //   "versionDate": "2023-09-30T11:53:02.9896998Z"
        // },
        // {
        //   "acctholdingID": 17057,
        //   "assetClass": "EQTY",
        //   "ccy": "CNY",
        //   "cps": 1847,
        //   "inceptionDate": "2023-08-31T00:00:00Z",
        //   "instrumentID": "1000433",
        //   "retreatValue": -21.7836762317,
        //   "marketPrice": 1444.6555,
        //   maxMarketPrice:1445,
        //   "matchedRulesDetails": [
        //     {
        //       "key": "-20.0% to -30.0%",
        //       "value": 1
        //     }
        //   ],
        //   "quantity": 803186,
        //   "securityDesc": "KWEICHOW MOUTAI CO LTD-A",
        //   "ticker": "600519 CG EQUITY",
        //   "unrealized": -323157469.57699996,
        //   "versionDate": "2023-09-30T11:53:02.9898553Z"
        // },
        // {
        //   "acctholdingID": 17057,
        //   "assetClass": "EQTY",
        //   "ccy": "CNY",
        //   "cps": 28.17,
        //   "inceptionDate": "2023-08-31T00:00:00Z",
        //   "instrumentID": "1000436",
        //   "retreatValue": -16.3631522897,
        //   "marketPrice": 23.5605,
        //   maxMarketPrice:24,
        //   "matchedRulesDetails": [
        //     {
        //       "key": "-10.0% to -20.0%",
        //       "value": 1
        //     }
        //   ],
        //   "quantity": 4519781,
        //   "securityDesc": "CHINA SHENHUA ENERGY CO-A",
        //   "ticker": "601088 CG EQUITY",
        //   "unrealized": -20833930.519500002,
        //   "versionDate": "2023-09-30T11:53:02.9899431Z"
        // },
        // {
        //   "acctholdingID": 17057,
        //   "assetClass": "EQTY",
        //   "ccy": "CNY",
        //   "cps": 21.91,
        //   "inceptionDate": "2023-08-31T00:00:00Z",
        //   "instrumentID": "1000523",
        //   "retreatValue": -40.8032861707,
        //   "marketPrice": 12.97,
        //   maxMarketPrice:13,
        //   "matchedRulesDetails": [
        //     {
        //       "key": "-40.0% to -50.0%",
        //       "value": 1
        //     }
        //   ],
        //   "quantity": 7986315,
        //   "securityDesc": "FOXCONN INDUSTRIAL INTERNE-A",
        //   "ticker": "601138 CG EQUITY",
        //   "unrealized": -71397656.1,
        //   "versionDate": "2023-09-30T11:53:02.9898941Z"
        // },
      ],
      "investmentStrategyInd": "A",
      "productType": "ETF",
      "status": "success"
    }
  }

  getSupendedstockData() {
    return {

      "data": [

        {

          "as_of_date": "2023-09-29T00:00:00Z",

          "gt_60_count": 0,

          "lt_60_count": 3,

          "version_date": "2023-11-07T06:02:32.5831208Z"

        }

      ],

      "status": "success"

    }
  }

  getFirmLevelDataInInSide() {
    return {

      "data": [

        {

          "HK-HSI": 0.23,

          "_id": 45,

          "actionDate": "2022-05-18T00:00:00Z",

          "actionDetails": "Testing 12",

          "fromDate": "2023-09-29T00:00:00Z",

          "fundID": "1000131",

          "indstryLvlAdjstmnt": "123",

          "instrumentName": "LONGFOR PROPERTES",

          "instrumentTicker": "960 HK EQUITY",

          "noOfDaysSuspended": 1,

          "pmCommentsChn": "testybb",

          "pmCommentsEng": "321",

          "sector": "Real Estate",

          "ticker": "960 HK EQUITY",

          "toDate": "2023-09-29T00:00:00Z",

          "trgtdFairValPrc": ""



        }

      ],

      "headers": [

        "HK-HSI",

        "HK-HSI",

        "HK-HSI"

      ],

      "status": "success"

    }
  }

  getOldDashBoardSummaries() {
    return {
      "filterDetails": {
        "assetClassFilters": [
          {
            "type": "Equity",
            "value": 30
          },
          {
            "type": "Fixed Income",
            "value": 12
          },
          {
            "type": "Derivatives",
            "value": 19
          },
          {
            "type": "Commodities",
            "value": 0
          },
          {
            "type": "Others",
            "value": 0
          }
        ],
        "breachFilters": [
          {
            "type": "TD",
            "value": 6
          },
          {
            "type": "LQ",
            "value": 0
          },
          {
            "type": "FV",
            "value": 0
          }
        ],
        "domicileFilters": [
          {
            "type": "Cayman Islands",
            "value": 14
          },
          {
            "type": "China",
            "value": 4
          },
          {
            "type": "Ireland",
            "value": 1
          },
          {
            "type": "Central African Republic",
            "value": 1
          },
          {
            "type": "Hong Kong",
            "value": 52
          },
          {
            "type": "United States",
            "value": 3
          },
          {
            "type": "Luxembourg",
            "value": 1
          },
          {
            "type": "Singapore",
            "value": 4
          }
        ],
        "productTypeFilters": [
          {
            "type": "ETF",
            "value": 44
          },
          {
            "type": "Active Fund",
            "value": 17
          },
          {
            "type": "Segregated Account",
            "value": 0
          }
        ]
      },
      "firmReports": {
        "_id": "eb-ZhaM-p",
        "asOfDate": "2023-08-29T00:00:00Z",
        "aumByAssetClass": [
          {
            "type": "Bonds",
            "value": 0
          },
          {
            "type": "Equities",
            "value": 0
          },
          {
            "type": "Derivatives",
            "value": 0
          },
          {
            "type": "Commodities",
            "value": 0
          },
          {
            "type": "Others",
            "value": 0
          }
        ],
        "aumByFundType": [],
        "aumTotal": 0,
        "companyID": "CSOP",
        "createdAt": "2023-08-30T17:15:01.015Z",
        "firmLevelLiquidity": [
          {
            "type": "1",
            "value": null
          },
          {
            "type": "3",
            "value": null
          },
          {
            "type": "5",
            "value": null
          },
          {
            "type": "7",
            "value": null
          },
          {
            "type": "30",
            "value": null
          }
        ],
        "updatedAt": "2023-08-30T17:15:01.015Z"
      },
      "noOfFunds": 102,
      "noOfSummaries": 80,
      "status": "success",
      "summaries": [
        {
          "_id": "e0vDWap-pp",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.14082170732133192
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8750477971830986
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "MXA50CNC Index",
          "benchmarkIndexName": "MSCI China A 50 Connect Index",
          "betaYTD": 0.7748852022509427,
          "ccy": "CNY",
          "classID": "CLS00196",
          "className": "HK-MSCIA50",
          "companyID": "CSOP",
          "dtdDiff": -0.0009508892689722437,
          "fundDTDDiff": 0.008465631260366813,
          "fundID": "1000139",
          "fundName": "CSOP MSCI China A 50 Connect ETF",
          "fundNote": null,
          "fundReturn": 4.6816,
          "fundReturn1M": -0.042676318426272286,
          "fundReturn1Y": -0.09882579403272373,
          "fundReturn1YRolling": -0.09882579403272373,
          "fundReturn3M": 0.019134902148594923,
          "fundReturn3MRolling": 0.019134902148594923,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.0786247072484304,
          "fundReturn6MRolling": -0.0786247072484304,
          "fundReturnInception": -0.18255312461804396,
          "fundReturnMTD": -0.04758417251551206,
          "fundReturnQTD": 0.011975271280965094,
          "fundReturnRebase": 81.74468753819573,
          "fundReturnYTD": -0.04857131244157209,
          "fundType": "EE",
          "inceptionDate": "2021-12-10T00:00:00Z",
          "indexDTDDiff": 0.009416520529339056,
          "indexMTDVolatility": 0.1664617852035309,
          "indexQTDVolatility": 0.19282617095203813,
          "indexReturn": 359.32427,
          "indexReturn1M": -0.059711951627711035,
          "indexReturn1Y": -0.8725610658324998,
          "indexReturn1YRolling": -0.8725610658324998,
          "indexReturn3M": -0.0037456135744508012,
          "indexReturn3MRolling": -0.0037456135744508012,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": -0.11115101137776007,
          "indexReturn6MRolling": -0.11115101137776007,
          "indexReturnChange": 0.1079121126760576,
          "indexReturnChangePct": 0.009416520529339056,
          "indexReturnInception": -0.8843221665995976,
          "indexReturnMTD": -0.06527615270030451,
          "indexReturnQTD": 0.01255700038935581,
          "indexReturnRebase": 11.567783340040254,
          "indexYTD": -0.0742233366632935,
          "indexYTDVolatility": 0.17961918805944568,
          "location": "Hong Kong",
          "navChange": 0.6862111714480505,
          "navChangePct": 0.008465631260366813,
          "navRebase": 81.74468753819573,
          "netAssetValue": 4.6816,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3003 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.017691980184792455,
          "td1Y": 0.7737352717997761,
          "tdAnnualYears": [],
          "tdCalYear": 0.7342260898617666,
          "tdCheck": "Pass",
          "tdInception": 0.7017690419815537,
          "tdYTD": 0.025652024221721414,
          "tdYTDChange": -0.0006494404553368804,
          "te1M": 0.05301239738395343,
          "te1Y": 6.015276789858129,
          "teAnnualYears": [],
          "teCalYear": 6.066793270165714,
          "teInception": 7.0688022719185915,
          "teYTD": 0.060649328534017555,
          "theoriticalDTD": -0.9416520529339056,
          "tradarCode": "HK-MSCIA50",
          "volatilityMTD": 0.1457764341983515,
          "volatilityQTD": 0.16273986593733283,
          "volatilityYTD": 0.14620938919270143
        },
        {
          "_id": "A0XDWaM-MH",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.07983757817827741
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.06540498136889583
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": "VND",
          "benchmarkIndexCode": "FIVNM3NV Index",
          "benchmarkIndexName": "FTSE Vietnam 30 Index",
          "betaYTD": 0.977851834484444,
          "ccy": "VND",
          "classID": "CLS00225",
          "className": "VND",
          "companyID": "CSOP",
          "dtdDiff": 0.005722446651550084,
          "fundDTDDiff": -0.0006700896304125958,
          "fundID": "1000158",
          "fundName": "CSOP FTSE Vietnam 30 ETF",
          "fundNote": null,
          "fundReturn": 22117.8799,
          "fundReturn1M": 0.009824825259589831,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": 0.16226336122734986,
          "fundReturn3MRolling": 0.16226336122734986,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.23715184017043556,
          "fundReturn6MRolling": 0.23715184017043556,
          "fundReturnInception": 0.11721613140755571,
          "fundReturnMTD": -0.008132300963173966,
          "fundReturnQTD": 0.10082611630423943,
          "fundReturnRebase": 111.72161314075547,
          "fundReturnYTD": 0.2141510073794466,
          "fundType": "EE",
          "inceptionDate": "2022-09-19T00:00:00Z",
          "indexDTDDiff": -0.00639253628196268,
          "indexMTDVolatility": 0.23906211258646606,
          "indexQTDVolatility": 0.18199494157012497,
          "indexReturn": 0.11502,
          "indexReturn1M": -0.0072501294665976435,
          "indexReturn1Y": "-",
          "indexReturn1YRolling": "-",
          "indexReturn3M": 0.1364489674933307,
          "indexReturn3MRolling": 0.1364489674933307,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.2333261848595325,
          "indexReturn6MRolling": 0.2333261848595325,
          "indexReturnChange": -0.7256324769562639,
          "indexReturnChangePct": -0.00639253628196268,
          "indexReturnInception": 0.12786820945283384,
          "indexReturnMTD": -0.025254237288135584,
          "indexReturnQTD": 0.07949319568277802,
          "indexReturnRebase": 112.78682094528321,
          "indexYTD": 0.20679886685552407,
          "indexYTDVolatility": 0.18337256356921433,
          "location": "Hong Kong",
          "navChange": -0.07491369334768194,
          "navChangePct": -0.0006700896304125958,
          "navRebase": 111.72161314075547,
          "netAssetValue": 22117.8799,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3004 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.017121936324961617,
          "td1Y": "-",
          "tdAnnualYears": [],
          "tdCalYear": -0.014432596809381582,
          "tdCheck": "Fail",
          "tdInception": -0.010652078045278124,
          "tdYTD": 0.0073521405239225235,
          "tdYTDChange": 0.006950002532167376,
          "te1M": 0.048029690895986236,
          "te1Y": 0.02376534601793474,
          "teAnnualYears": [],
          "teCalYear": 0.014223685260405487,
          "teInception": 0.028735323519430322,
          "teYTD": 0.023884656464712405,
          "theoriticalDTD": 0.639253628196268,
          "tradarCode": "HK-VN30",
          "volatilityMTD": 0.24311939600484775,
          "volatilityQTD": 0.18380370175940275,
          "volatilityYTD": 0.18082966312743257
        },
        {
          "_id": "e0vYWtpzpY",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.25220347752175765
            },
            {
              "key": 2021,
              "value": -0.3554576311558387
            },
            {
              "key": 2020,
              "value": 0.11346125794003359
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.9031039677771479
            },
            {
              "key": 2021,
              "value": -0.3479932822513726
            },
            {
              "key": 2020,
              "value": 0.11802710388176418
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 14069918397,
          "aumLastQuarter": 14069918397,
          "aumLastYear": 14069918397,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSTECHN Index",
          "benchmarkIndexName": "Hang Seng TECH Index",
          "betaYTD": 0.9992583998772803,
          "ccy": "HKD",
          "classID": "CLS00178",
          "className": "CO-CHST",
          "companyID": "CSOP",
          "dtdDiff": 0.0001385232652462154,
          "fundDTDDiff": 0.0258641944869078,
          "fundID": "1000123",
          "fundName": "CSOP Hang Seng TECH Index ETF",
          "fundNote": null,
          "fundReturn": 4.1607,
          "fundReturn1M": -0.05264236435255798,
          "fundReturn1Y": -0.008837962742388794,
          "fundReturn1YRolling": -0.008837962742388794,
          "fundReturn3M": 0.16052103090483105,
          "fundReturn3MRolling": 0.16052103090483105,
          "fundReturn3Y": -0.45113119187388684,
          "fundReturn3YRolling": -0.45113119187388684,
          "fundReturn6M": 0.07756655961877135,
          "fundReturn6MRolling": 0.07756655961877135,
          "fundReturnInception": -0.44824888275935226,
          "fundReturnMTD": -0.06990208789734864,
          "fundReturnQTD": 0.0826416174442508,
          "fundReturnRebase": 114.1669410602567,
          "fundReturnYTD": 0.028094885100074185,
          "fundType": "EE",
          "inceptionDate": "2020-08-27T08:00:00Z",
          "indexDTDDiff": 0.025725671221661583,
          "indexMTDVolatility": 0.3226006228331632,
          "indexQTDVolatility": 0.34585201786000475,
          "indexReturn": 588.97286,
          "indexReturn1M": -0.057930958544556876,
          "indexReturn1Y": -0.8723462156169604,
          "indexReturn1YRolling": -0.8723462156169604,
          "indexReturn3M": 0.16093550719884142,
          "indexReturn3MRolling": 0.16093550719884142,
          "indexReturn3Y": -0.9277235476242777,
          "indexReturn3YRolling": -0.9277235476242777,
          "indexReturn6M": 0.08362723860289889,
          "indexReturn6MRolling": 0.08362723860289889,
          "indexReturnChange": 0.36784985892835387,
          "indexReturnChangePct": 0.025725671221661583,
          "indexReturnInception": -0.9273397780615235,
          "indexReturnMTD": -0.07517011922564343,
          "indexReturnQTD": 0.08301772209400404,
          "indexReturnRebase": 14.666791012254425,
          "indexYTD": 0.028694277744045316,
          "indexYTDVolatility": 0.3445013468678971,
          "location": "Hong Kong",
          "navChange": 2.878388760838561,
          "navChangePct": 0.0258641944869078,
          "navRebase": 114.1669410602567,
          "netAssetValue": 4.1607,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3033 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.005268031328294787,
          "td1Y": 0.8635082528745716,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.6509004902553902
            },
            {
              "key": 2021,
              "value": -0.007464348904466123
            }
          ],
          "tdCalYear": 0.6509004902553902,
          "tdCheck": "Pass",
          "tdInception": 0.4790908953021712,
          "tdYTD": -0.0005993926439711306,
          "tdYTDChange": 0.00012030965253195802,
          "te1M": 0.006572744764780753,
          "te1Y": 0.9516374638402264,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.9516604190867041
            },
            {
              "key": 2021,
              "value": 0.0012584142361962013
            }
          ],
          "teCalYear": 0.9516604190867041,
          "teInception": 0.6009358705068063,
          "teYTD": 0.008933929712479605,
          "theoriticalDTD": -2.5725671221661583,
          "tradarCode": "CO-CHST",
          "volatilityMTD": 0.32070674462114696,
          "volatilityQTD": 0.3446038238506958,
          "volatilityYTD": 0.3443603501468197
        },
        {
          "_id": "e0XYiaM-Mt",
          "annualFundReturns": [],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00201",
          "className": "USD",
          "companyID": "CSOP",
          "fundDTDDiff": null,
          "fundID": "1000141",
          "fundName": "CSOP Metaverse Concept ETF",
          "fundNote": null,
          "fundReturn": 0.8607,
          "fundReturn1M": "-",
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": "-",
          "fundReturn3MRolling": "-",
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": "-",
          "fundReturn6MRolling": "-",
          "fundReturnInception": "-",
          "fundReturnMTD": "-",
          "fundReturnQTD": "-",
          "fundReturnRebase": 100,
          "fundReturnYTD": "-",
          "fundType": "EE",
          "inceptionDate": "2022-02-18T08:00:00Z",
          "location": "Hong Kong",
          "navChange": 0,
          "navChangePct": 0,
          "navRebase": 100,
          "netAssetValue": 0.8607,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3034 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "tradarCode": "HK-METAV",
          "volatilityMTD": 0,
          "volatilityQTD": 0,
          "volatilityYTD": 0
        },
        {
          "_id": "e0XYWtMzp-",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.1509125636672325
            },
            {
              "key": 2021,
              "value": -0.17094799071011335
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8868831589064856
            },
            {
              "key": 2021,
              "value": -0.1705042525342858
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 841862686,
          "aumLastQuarter": 841862686,
          "aumLastYear": 841862686,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSINH Index",
          "benchmarkIndexName": "Hang Seng Index",
          "betaYTD": 0.9981269998708683,
          "ccy": "HKD",
          "classID": "CLS00187",
          "className": "HK-HSI",
          "companyID": "CSOP",
          "dtdDiff": 0.00010005812036961714,
          "fundDTDDiff": 0.020039588324112234,
          "fundID": "1000131",
          "fundName": "CSOP Hang Seng Index ETF",
          "fundNote": null,
          "fundReturn": 19.1185,
          "fundReturn1M": -0.06939218559099691,
          "fundReturn1Y": -0.08071759659954225,
          "fundReturn1YRolling": -0.08071759659954225,
          "fundReturn3M": 0.013985828542333989,
          "fundReturn3MRolling": 0.013985828542333989,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.04627809759455648,
          "fundReturn6MRolling": -0.04627809759455648,
          "fundReturnInception": -0.32723977760574274,
          "fundReturnMTD": -0.07685584880879948,
          "fundReturnQTD": -0.012229271720261226,
          "fundReturnRebase": 67.27602223942588,
          "fundReturnYTD": -0.04429003474218296,
          "fundType": "EE",
          "inceptionDate": "2021-05-05T08:00:00Z",
          "indexDTDDiff": 0.019939530203742617,
          "indexMTDVolatility": 0.2048218998266824,
          "indexQTDVolatility": 0.23785105936036996,
          "indexReturn": 7508.40958,
          "indexReturn1M": -0.0757320648414268,
          "indexReturn1Y": -0.8781444960605232,
          "indexReturn1YRolling": -0.8781444960605232,
          "indexReturn3M": 0.01186441451375253,
          "indexReturn3MRolling": 0.01186441451375253,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": -0.039777648677175925,
          "indexReturn6MRolling": -0.039777648677175925,
          "indexReturnChange": 0.1753790780211748,
          "indexReturnChangePct": 0.019939530203742617,
          "indexReturnInception": -0.9102907377371869,
          "indexReturnMTD": -0.0831830686659717,
          "indexReturnQTD": -0.013540234776283855,
          "indexReturnRebase": 8.970926226281279,
          "indexYTD": -0.04391643488348529,
          "indexYTDVolatility": 0.2239463472149207,
          "location": "Hong Kong",
          "navChange": 1.3216975156591104,
          "navChangePct": 0.020039588324112234,
          "navRebase": 67.27602223942588,
          "netAssetValue": 19.1185,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3037 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.006327219857172217,
          "td1Y": 0.797426899460981,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.7359705952392531
            }
          ],
          "tdCalYear": 0.7359705952392531,
          "tdCheck": "Pass",
          "tdInception": 0.5830509601314442,
          "tdYTD": -0.00037359985869767076,
          "tdYTDChange": 0.00008461135755610805,
          "te1M": 0.006868538733889254,
          "te1Y": 0.911197593466112,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.9112649724347102
            }
          ],
          "teCalYear": 0.9112649724347102,
          "teInception": 0.6724608799093301,
          "teYTD": 0.011217856413859414,
          "theoriticalDTD": -1.9939530203742617,
          "tradarCode": "HK-HSI",
          "volatilityMTD": 0.2015523809110328,
          "volatilityQTD": 0.23465901181125082,
          "volatilityYTD": 0.2238045089028434
        },
        {
          "_id": "ejvYiaMzMn",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.013007322853665793
            },
            {
              "key": 2021,
              "value": 0.0007368973641650456
            },
            {
              "key": 2020,
              "value": 0.010352509359840445
            },
            {
              "key": 2019,
              "value": 0.016889670879750573
            },
            {
              "key": 2018,
              "value": 0.006135264396227802
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.969555240760107
            },
            {
              "key": 2021,
              "value": 215.3171160969226
            },
            {
              "key": 2020,
              "value": 0.013966157080139041
            },
            {
              "key": 2019,
              "value": 0.021400401869560604
            },
            {
              "key": 2018,
              "value": 0.008654261743179292
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 3598068254,
          "aumLastQuarter": 3598068254,
          "aumLastYear": 3598068254,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSCIUT Index",
          "benchmarkIndexName": "HSCIUT Index",
          "betaYTD": 0.0008439686060280839,
          "ccy": "HKD",
          "classID": "CLS00077",
          "className": "CO-MMF",
          "companyID": "CSOP",
          "dtdDiff": -0.019583584699079726,
          "fundDTDDiff": 0.00012987778583894993,
          "fundID": "1000029",
          "fundName": "CSOP Hong Kong Dollar Money Market ETF",
          "fundNote": "Static Note",
          "fundReturn": 1077.3064,
          "fundReturn1M": 0.004098296100145671,
          "fundReturn1Y": 0.04097371095598756,
          "fundReturn1YRolling": 0.04097371095598756,
          "fundReturn3M": 0.011093240300418694,
          "fundReturn3MRolling": 0.011093240300418694,
          "fundReturn3Y": 0.04332074828499066,
          "fundReturn3YRolling": 0.04332074828499066,
          "fundReturn6M": 0.0206468245689726,
          "fundReturn6MRolling": 0.0206468245689726,
          "fundReturnInception": 0.07722991667591583,
          "fundReturnMTD": 0.0037206294901859227,
          "fundReturnQTD": 0.007481296758104827,
          "fundReturnRebase": 107.72299166759157,
          "fundReturnYTD": 0.027951861261196642,
          "fundType": "BE",
          "inceptionDate": "2018-07-18T16:00:00Z",
          "indexDTDDiff": 0.019713462484918676,
          "indexMTDVolatility": 0.15793655522415143,
          "indexQTDVolatility": 0.15904618926023176,
          "indexReturn": 608.46183,
          "indexReturn1M": -0.09951986376604982,
          "indexReturn1Y": -0.9688474082592058,
          "indexReturn1YRolling": -0.9688474082592058,
          "indexReturn3M": -0.12172439483772513,
          "indexReturn3MRolling": -0.12172439483772513,
          "indexReturn3Y": 4.705041531266859,
          "indexReturn3YRolling": 4.705041531266859,
          "indexReturn6M": -0.13553128359039268,
          "indexReturn6MRolling": -0.13553128359039268,
          "indexReturnChange": 11.500933088118359,
          "indexReturnChangePct": 0.019713462484918676,
          "indexReturnInception": 4.949059588118777,
          "indexReturnMTD": -0.10481119062975564,
          "indexReturnQTD": -0.10491764674280579,
          "indexReturnRebase": 594.9059588118778,
          "indexYTD": -0.13526491787173212,
          "indexYTDVolatility": 0.16101726134900907,
          "location": "Hong Kong",
          "navChange": 0.01398900678050552,
          "navChangePct": 0.00012987778583894993,
          "navRebase": 107.72299166759157,
          "netAssetValue": 1077.3064,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3053 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.10853182011994156,
          "td1Y": 1.0098211192151934,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.9825625636137728
            },
            {
              "key": 2021,
              "value": -215.31637919955844
            },
            {
              "key": 2020,
              "value": -0.003613647720298596
            },
            {
              "key": 2019,
              "value": -0.00451073098981003
            }
          ],
          "tdCalYear": 0.9825625636137728,
          "tdCheck": "Pass",
          "tdInception": -4.871829671442861,
          "tdYTD": 0.16321677913292876,
          "tdYTDChange": -0.016583874670190513,
          "te1M": 0.16162555528040337,
          "te1Y": 6.860269284158579,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 6.878887548793812
            },
            {
              "key": 2021,
              "value": 272.9155731696146
            },
            {
              "key": 2020,
              "value": 0.0009005996020628416
            },
            {
              "key": 2019,
              "value": 0.0015750169859618751
            }
          ],
          "teCalYear": 6.878887548793812,
          "teInception": 132.95561020254752,
          "teYTD": 0.16166138702818378,
          "theoriticalDTD": -1.9713462484918676,
          "tradarCode": "CO-MMF",
          "volatilityMTD": 0.0019320092486464937,
          "volatilityQTD": 0.0018595231899514164,
          "volatilityYTD": 0.0018520549406080599
        },
        {
          "_id": "A0vYWtp-Mx",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.04604604604604612
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00244",
          "className": "USD",
          "companyID": "CSOP",
          "fundDTDDiff": 0.07338028169014099,
          "fundID": "1000173",
          "fundName": "CSOP Bitcoin Futures ETF",
          "fundNote": null,
          "fundReturn": 1.5242,
          "fundReturn1M": -0.057856348127086066,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": 0.027781523937963515,
          "fundReturn3MRolling": 0.027781523937963515,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.14257871064467764,
          "fundReturn6MRolling": 0.14257871064467764,
          "fundReturnInception": 0.5257257257257257,
          "fundReturnMTD": -0.04992831764632555,
          "fundReturnQTD": -0.10076696165191745,
          "fundReturnRebase": 152.57257257257254,
          "fundReturnYTD": 0.599370409233998,
          "fundType": "EM",
          "inceptionDate": "2022-12-15T00:00:00Z",
          "location": "Hong Kong",
          "navChange": 10.430430430430448,
          "navChangePct": 0.07338028169014099,
          "navRebase": 152.57257257257254,
          "netAssetValue": 1.5242,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3066 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "tradarCode": "HK-BTCFUT",
          "volatilityMTD": 0.42560415382818007,
          "volatilityQTD": 0.3935544756454212,
          "volatilityYTD": 0.5667323000716046
        },
        {
          "_id": "AjvYiap-pi",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.07782423626021773
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "HKD",
          "classID": "CLS00200",
          "className": "Test2",
          "companyID": "CSOP",
          "fundDTDDiff": 0.0258762290341239,
          "fundID": "1000140",
          "fundName": "CSOP Metaverse Dummy ETF",
          "fundNote": null,
          "fundReturn": 8.8687,
          "fundReturn1M": -0.052094355554130334,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": 0.16289468163222498,
          "fundReturn3MRolling": 0.16289468163222498,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.0815751603697652,
          "fundReturn6MRolling": 0.0815751603697652,
          "fundReturnInception": 0.11358471139237336,
          "fundReturnMTD": -0.06944998216271792,
          "fundReturnQTD": 0.08402087687775794,
          "fundReturnRebase": 111.35847113923751,
          "fundReturnYTD": 0.033178392106152366,
          "fundType": "EM",
          "inceptionDate": "2022-02-03T00:00:00Z",
          "location": "Hong Kong",
          "navChange": 2.8088547356261415,
          "navChangePct": 0.0258762290341239,
          "navRebase": 111.35847113923751,
          "netAssetValue": 8.8687,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3067 HK Equity",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "tradarCode": "TEST2",
          "volatilityMTD": 0.32083691747774595,
          "volatilityQTD": 0.3447612540443014,
          "volatilityYTD": 0.3444634277062989
        },
        {
          "_id": "U0XYiap-p",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.056056056056056125
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00245",
          "className": "USD",
          "companyID": "CSOP",
          "fundDTDDiff": 0.05586592178770955,
          "fundID": "1000174",
          "fundName": "CSOP Ether Futures ETF",
          "fundNote": null,
          "fundReturn": 1.323,
          "fundReturn1M": -0.07997218358831715,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": -0.057020669992872475,
          "fundReturn3MRolling": -0.057020669992872475,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.028771384136858424,
          "fundReturn6MRolling": 0.028771384136858424,
          "fundReturnInception": 0.32432432432432434,
          "fundReturnMTD": -0.07027406886858756,
          "fundReturnQTD": -0.118,
          "fundReturnRebase": 132.43243243243225,
          "fundReturnYTD": 0.40296924708377513,
          "fundType": "EM",
          "inceptionDate": "2022-12-15T16:00:00Z",
          "location": "Hong Kong",
          "navChange": 7.00700700700699,
          "navChangePct": 0.05586592178770933,
          "navRebase": 132.43243243243225,
          "netAssetValue": 1.323,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3068 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "tradarCode": "HK-DCRFUT",
          "volatilityMTD": 0.35810295894455607,
          "volatilityQTD": 0.3768603049498239,
          "volatilityYTD": 0.5421268019801653
        },
        {
          "_id": "U0vDWtpzpH",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.31225835797134416
            },
            {
              "key": 2021,
              "value": -0.04877577668065114
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8992075317794316
            },
            {
              "key": 2021,
              "value": -0.03412188778960734
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 77999144,
          "aumLastQuarter": 77999144,
          "aumLastYear": 77999144,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "STAR50NR Index",
          "benchmarkIndexName": "SSE Science and Technology Innovation Board 50 Index",
          "betaYTD": 0.9029239075815012,
          "ccy": "CNY",
          "classID": "CLS00186",
          "className": "CO-STAR50",
          "companyID": "CSOP",
          "dtdDiff": -0.0013263843160613575,
          "fundDTDDiff": 0.041074805974042894,
          "fundID": "1000130",
          "fundName": "CSOP STAR 50 Index ETF",
          "fundNote": null,
          "fundReturn": 7.9814,
          "fundReturn1M": -0.04930139482805862,
          "fundReturn1Y": -0.14676672760121023,
          "fundReturn1YRolling": -0.14676672760121023,
          "fundReturn3M": -0.11074715333021379,
          "fundReturn3MRolling": -0.11074715333021379,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.0811613556824462,
          "fundReturn6MRolling": -0.0811613556824462,
          "fundReturnInception": -0.38333758276738594,
          "fundReturnMTD": -0.05535500822572814,
          "fundReturnQTD": -0.08988904979645829,
          "fundReturnRebase": 61.66624172326129,
          "fundReturnYTD": -0.057374338624338606,
          "fundType": "EE",
          "inceptionDate": "2021-02-09T00:00:00Z",
          "indexDTDDiff": 0.04240119029010425,
          "indexMTDVolatility": 0.24066209598050264,
          "indexQTDVolatility": 0.20467767009148427,
          "indexReturn": 127.83905,
          "indexReturn1M": -0.0650574623873712,
          "indexReturn1Y": -0.8800129054662862,
          "indexReturn1YRolling": -0.8800129054662862,
          "indexReturn3M": -0.1312340991519072,
          "indexReturn3MRolling": -0.1312340991519072,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": -0.11420758128647057,
          "indexReturn6MRolling": -0.11420758128647057,
          "indexReturnChange": 0.36255656187468155,
          "indexReturnChangePct": 0.04240119029010425,
          "indexReturnInception": -0.9108682117035147,
          "indexReturnMTD": -0.07180765423126056,
          "indexReturnQTD": -0.09035637531930085,
          "indexReturnRebase": 8.913178829648537,
          "indexYTD": -0.08444968779459383,
          "indexYTDVolatility": 0.22316541169861315,
          "location": "Hong Kong",
          "navChange": 2.432994151233487,
          "navChangePct": 0.041074805974042894,
          "navRebase": 61.66624172326129,
          "netAssetValue": 7.9814,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3109 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.016452646005532423,
          "td1Y": 0.733246177865076,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.5869491738080874
            }
          ],
          "tdCalYear": 0.5869491738080874,
          "tdCheck": "Pass",
          "tdInception": 0.5275306289361288,
          "tdYTD": 0.027075349170255225,
          "tdYTDChange": -0.000050774952747523194,
          "te1M": 0.03903229310750021,
          "te1Y": 0.8535021771494071,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.8526302524882675
            }
          ],
          "teCalYear": 0.8526302524882675,
          "teInception": 0.6084875323159878,
          "teYTD": 0.054433303194814785,
          "theoriticalDTD": -4.240119029010425,
          "tradarCode": "CO-STAR50",
          "volatilityMTD": 0.22667446974631322,
          "volatilityQTD": 0.18654123183172072,
          "volatilityYTD": 0.20753550543867405
        },
        {
          "_id": "UjvDWapzMf",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.18701120562825635
            },
            {
              "key": 2021,
              "value": 0.474730831973899
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8820436862201303
            },
            {
              "key": 2021,
              "value": 0.484871861052546
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 115533963,
          "aumLastQuarter": 115533963,
          "aumLastYear": 115533963,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "SH931151 Index",
          "benchmarkIndexName": "CSI Photovoltaic Industry Index",
          "betaYTD": 0.9272917462105519,
          "ccy": "CNY",
          "classID": "CLS00189",
          "className": "HK-PTVT",
          "companyID": "CSOP",
          "dtdDiff": -0.002822633575860989,
          "fundDTDDiff": 0.022060675855615886,
          "fundID": "1000133",
          "fundName": "CSOP Huatai-PineBridge CSI Photovoltaic Industry ETF",
          "fundNote": null,
          "fundReturn": 5.68,
          "fundReturn1M": -0.09194097616345065,
          "fundReturn1Y": -0.4009828942650441,
          "fundReturn1YRolling": -0.4009828942650441,
          "fundReturn3M": -0.12503658517799654,
          "fundReturn3MRolling": -0.12503658517799654,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.27003547010743845,
          "fundReturn6MRolling": -0.27003547010743845,
          "fundReturnInception": -0.0734094616639478,
          "fundReturnMTD": -0.09875602944909878,
          "fundReturnQTD": -0.14705749853588224,
          "fundReturnRebase": 92.65905383360518,
          "fundReturnYTD": -0.22715831008912168,
          "fundType": "EE",
          "inceptionDate": "2021-05-31T00:00:00Z",
          "indexDTDDiff": 0.024883309431476874,
          "indexMTDVolatility": 0.2783157591630099,
          "indexQTDVolatility": 0.2563144365684619,
          "indexReturn": 443.40984,
          "indexReturn1M": -0.10902481172187084,
          "indexReturn1Y": -0.9175946803844753,
          "indexReturn1YRolling": -0.9175946803844753,
          "indexReturn3M": -0.15664211099092906,
          "indexReturn3MRolling": -0.15664211099092906,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": -0.30993442514930347,
          "indexReturn6MRolling": -0.30993442514930347,
          "indexReturnChange": 0.3122089431908144,
          "indexReturnChangePct": 0.024883309431476874,
          "indexReturnInception": -0.8714086903059849,
          "indexReturnMTD": -0.11426783966110732,
          "indexReturnQTD": -0.15258402719789443,
          "indexReturnRebase": 12.859130969401528,
          "indexYTD": -0.26582185842558026,
          "indexYTDVolatility": 0.2806393687517155,
          "location": "Hong Kong",
          "navChange": 2,
          "navChangePct": 0.022060675855615886,
          "navRebase": 92.65905383360518,
          "netAssetValue": 5.68,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3134 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.015511810212008537,
          "td1Y": 0.5166117861194313,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.695032480591874
            }
          ],
          "tdCalYear": 0.695032480591874,
          "tdCheck": "Pass",
          "tdInception": 0.7979992286420371,
          "tdYTD": 0.038663548336458575,
          "tdYTDChange": -0.0011438242342327598,
          "te1M": 0.04339127591609044,
          "te1Y": 0.834137448238654,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.8332096642763027
            }
          ],
          "teCalYear": 0.8332096642763027,
          "teInception": 0.6433163953529996,
          "teYTD": 0.059549073208820476,
          "theoriticalDTD": -2.4883309431476874,
          "tradarCode": "HK-PTVT",
          "volatilityMTD": 0.25702023323162987,
          "volatilityQTD": 0.2278854558354722,
          "volatilityYTD": 0.2661209101275873
        },
        {
          "_id": "U0XDWaMzp-",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.4630511910795744
            },
            {
              "key": 2021,
              "value": 0.0031523286556842667
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.44933422741641926
            },
            {
              "key": 2021,
              "value": 0.006417005063418024
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 5571116,
          "aumLastQuarter": 5571116,
          "aumLastYear": 5571116,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "SGDRIVEN Index",
          "benchmarkIndexName": "Solactive Global Smart Driving Index",
          "betaYTD": 0.9977653438789109,
          "ccy": "USD",
          "classID": "CLS00191",
          "className": "HK-SDRIVE",
          "companyID": "CSOP",
          "dtdDiff": -0.00008099817357787309,
          "fundDTDDiff": 0.03307805116506435,
          "fundID": "1000135",
          "fundName": "CSOP Global Smart Driving Index ETF",
          "fundNote": null,
          "fundReturn": 0.634,
          "fundReturn1M": -0.07969226302801558,
          "fundReturn1Y": -0.07754983267859739,
          "fundReturn1YRolling": -0.07754983267859739,
          "fundReturn3M": 0.10645724258289713,
          "fundReturn3MRolling": 0.10645724258289713,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.033583306162373816,
          "fundReturn6MRolling": 0.033583306162373816,
          "fundReturnInception": -0.3552979459019727,
          "fundReturnMTD": -0.08894956171863766,
          "fundReturnQTD": -0.008755472170106193,
          "fundReturnRebase": 64.47020540980272,
          "fundReturnYTD": 0.19690390787238066,
          "fundType": "EE",
          "inceptionDate": "2021-08-11T08:00:00Z",
          "indexDTDDiff": 0.03315904933864222,
          "indexMTDVolatility": 0.2218183469820557,
          "indexQTDVolatility": 0.22070763907972,
          "indexReturn": 1756.67,
          "indexReturn1M": -0.07731620331220079,
          "indexReturn1Y": -0.048823937103376513,
          "indexReturn1YRolling": -0.048823937103376513,
          "indexReturn3M": 0.11525397903665091,
          "indexReturn3MRolling": 0.11525397903665091,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.050074720545161044,
          "indexReturn6MRolling": 0.050074720545161044,
          "indexReturnChange": 2.1742232300210986,
          "indexReturnChangePct": 0.03315904933864222,
          "indexReturnInception": -0.3225624828873438,
          "indexReturnMTD": -0.08697459992411682,
          "indexReturnQTD": -0.0035282972845724903,
          "indexReturnRebase": 67.74375171126567,
          "indexYTD": 0.22237144248834473,
          "indexYTDVolatility": 0.25140107718758564,
          "location": "Hong Kong",
          "navChange": 2.0642668293675044,
          "navChangePct": 0.03307805116506435,
          "navRebase": 64.47020540980272,
          "netAssetValue": 0.634,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3162 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": -0.0019749617945208353,
          "td1Y": -0.028725895575220872,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.013716963663155113
            }
          ],
          "tdCalYear": -0.013716963663155113,
          "tdCheck": "Pass",
          "tdInception": -0.03273546301462893,
          "tdYTD": -0.02546753461596407,
          "tdYTDChange": -0.0009082068516130803,
          "te1M": 0.003218247988444422,
          "te1Y": 0.006050113491967549,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.01030261963269034
            }
          ],
          "teCalYear": 0.01030261963269034,
          "teInception": 0.009888343215677928,
          "teYTD": 0.005144399924026889,
          "theoriticalDTD": -3.315904933864222,
          "tradarCode": "HK-SDRIVE",
          "volatilityMTD": 0.2212763721457376,
          "volatilityQTD": 0.22000177399788112,
          "volatilityYTD": 0.2508908697668223
        },
        {
          "_id": "UjvDiaM-M9",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.19020111988711286
            },
            {
              "key": 2021,
              "value": -0.2929160060874496
            },
            {
              "key": 2020,
              "value": 0.450910411891404
            },
            {
              "key": 2019,
              "value": 0.310848119180245
            },
            {
              "key": 2018,
              "value": -0.2381107620943156
            },
            {
              "key": 2017,
              "value": 0.6122820422821991
            },
            {
              "key": 2016,
              "value": -0.04590396186809509
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8948766429666352
            },
            {
              "key": 2021,
              "value": -0.2846222719968269
            },
            {
              "key": 2020,
              "value": 0.46696872071979567
            },
            {
              "key": 2019,
              "value": 0.32570304264064864
            },
            {
              "key": 2018,
              "value": -0.23296799103250876
            },
            {
              "key": 2017,
              "value": 0.6388581092902506
            },
            {
              "key": 2016,
              "value": -0.04420289855072279
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 467863436.66,
          "aumLastQuarter": 467863436.66,
          "aumLastYear": 467863436.66,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "SPNCSCHN Index",
          "benchmarkIndexName": "S&P New China Sectors (A-shares Capped) Index",
          "betaYTD": 1.0010004459955852,
          "ccy": "HKD",
          "classID": "CLS00066",
          "className": "HK-SPNC",
          "companyID": "CSOP",
          "dtdDiff": -0.00007211702103315787,
          "fundDTDDiff": 0.026302007048192078,
          "fundID": "1000022",
          "fundName": "ICBC CSOP S&P NEW CHINA SECTORS ETF",
          "fundNote": null,
          "fundReturn": 60.15938857972001,
          "fundReturn1M": -0.06857587558669198,
          "fundReturn1Y": -0.08949010626920595,
          "fundReturn1YRolling": -0.08949010626920595,
          "fundReturn3M": 0.04592192133512096,
          "fundReturn3MRolling": 0.04592192133512096,
          "fundReturn3Y": -0.3975415175058753,
          "fundReturn3YRolling": -0.3975415175058753,
          "fundReturn6M": -0.04674900648055025,
          "fundReturn6MRolling": -0.04674900648055025,
          "fundReturnInception": 0.20381616363181587,
          "fundReturnMTD": -0.07838979673176572,
          "fundReturnQTD": 0.026735840377104525,
          "fundReturnRebase": 120.38161636318169,
          "fundReturnYTD": -0.05682111123075961,
          "fundType": "EE",
          "inceptionDate": "2016-12-08T08:00:00Z",
          "indexDTDDiff": 0.026374124069225235,
          "indexMTDVolatility": 0.25072752551445415,
          "indexQTDVolatility": 0.27452184067730423,
          "indexReturn": 243.70842,
          "indexReturn1M": -0.07321412497608493,
          "indexReturn1Y": -0.8822573628879526,
          "indexReturn1YRolling": -0.8822573628879526,
          "indexReturn3M": 0.04757426161569045,
          "indexReturn3MRolling": 0.04757426161569045,
          "indexReturn3Y": -0.9204661495132513,
          "indexReturn3YRolling": -0.9204661495132513,
          "indexReturn6M": -0.04082489200502237,
          "indexReturn6MRolling": -0.04082489200502237,
          "indexReturnChange": 0.4267658459306496,
          "indexReturnChangePct": 0.026374124069225235,
          "indexReturnInception": -0.8339200118456862,
          "indexReturnMTD": -0.08323657242837279,
          "indexReturnQTD": 0.02767114529422776,
          "indexReturnRebase": 16.607998815431404,
          "indexYTD": -0.054862465716194464,
          "indexYTDVolatility": 0.2636454477449179,
          "location": "Hong Kong",
          "navChange": 3.0851329338854896,
          "navChangePct": 0.026302007048192078,
          "navRebase": 115.67638307196324,
          "netAssetValue": 57.808,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3167 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.004846775696607075,
          "td1Y": 0.7927672566187467,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.7046755230795223
            },
            {
              "key": 2021,
              "value": -0.008293734090622729
            },
            {
              "key": 2020,
              "value": -0.016058308828391654
            },
            {
              "key": 2019,
              "value": -0.014854923460403624
            },
            {
              "key": 2018,
              "value": -0.005142771061806828
            },
            {
              "key": 2017,
              "value": -0.026576067008051485
            }
          ],
          "tdCalYear": 0.7046755230795223,
          "tdCheck": "Pass",
          "tdInception": 1.037736175477502,
          "tdYTD": -0.0019586455145651493,
          "tdYTDChange": -0.00011490314832296455,
          "te1M": 0.005755111840219461,
          "te1Y": 0.919267922401964,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.9193042053775182
            },
            {
              "key": 2021,
              "value": 0.003820792140920424
            },
            {
              "key": 2020,
              "value": 0.004706809314468596
            },
            {
              "key": 2019,
              "value": 0.005949625272883293
            },
            {
              "key": 2018,
              "value": 0.12067865345709299
            },
            {
              "key": 2017,
              "value": 0.007754724941489623
            }
          ],
          "teCalYear": 0.9193042053775182,
          "teInception": 0.3858161752823634,
          "teYTD": 0.00899129612220524,
          "theoriticalDTD": -2.6374124069225235,
          "tradarCode": "HK-SPNC",
          "volatilityMTD": 0.24983461821601227,
          "volatilityQTD": 0.2734758098759256,
          "volatilityYTD": 0.2640602307722119
        },
        {
          "_id": "IjXDWtpzpn",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.24669024106257287
            },
            {
              "key": 2021,
              "value": -0.27102686219958816
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.9011653684750778
            },
            {
              "key": 2021,
              "value": -0.2656915940165492
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 49958668,
          "aumLastQuarter": 49958668,
          "aumLastYear": 49958668,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "SOLCHCDN Index",
          "benchmarkIndexName": "Solactive China Healthcare Disruption Index",
          "betaYTD": 0.9965236176162302,
          "ccy": "HKD",
          "classID": "CLS00190",
          "className": "HK-HEALTH",
          "companyID": "CSOP",
          "dtdDiff": 0.00008415519038962316,
          "fundDTDDiff": 0.03017467425067588,
          "fundID": "1000134",
          "fundName": "CSOP China Healthcare Disruption Index ETF",
          "fundNote": null,
          "fundReturn": 2.5537,
          "fundReturn1M": -0.09221143933738574,
          "fundReturn1Y": -0.2037354619438122,
          "fundReturn1YRolling": -0.2037354619438122,
          "fundReturn3M": -0.04555987442069076,
          "fundReturn3MRolling": -0.04555987442069076,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.20291528809538673,
          "fundReturn6MRolling": -0.20291528809538673,
          "fundReturnInception": -0.5986073780669905,
          "fundReturnMTD": -0.08133678681919554,
          "fundReturnQTD": -0.018072057522974472,
          "fundReturnRebase": 40.1392621933009,
          "fundReturnYTD": -0.26905572888341867,
          "fundType": "EE",
          "inceptionDate": "2021-07-20T08:00:00Z",
          "indexDTDDiff": 0.030090519060286258,
          "indexMTDVolatility": 0.3618188987834913,
          "indexQTDVolatility": 0.3383565753642615,
          "indexReturn": 97.0562,
          "indexReturn1M": -0.09589757476626415,
          "indexReturn1Y": -0.895342527793653,
          "indexReturn1YRolling": -0.895342527793653,
          "indexReturn3M": -0.0407119370312341,
          "indexReturn3MRolling": -0.0407119370312341,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": -0.1906088267621584,
          "indexReturn6MRolling": -0.1906088267621584,
          "indexReturnChange": 0.15713264350361023,
          "indexReturnChangePct": 0.030090519060286258,
          "indexReturnInception": -0.9462086891942072,
          "indexReturnMTD": -0.08517525291803851,
          "indexReturnQTD": -0.014306420725846536,
          "indexReturnRebase": 5.379131080579275,
          "indexYTD": -0.2588186545527468,
          "indexYTDVolatility": 0.3243959963008232,
          "location": "Hong Kong",
          "navChange": 1.1757124219990303,
          "navChangePct": 0.03017467425067588,
          "navRebase": 40.1392621933009,
          "netAssetValue": 2.5537,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3174 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.003838466098842974,
          "td1Y": 0.6916070658498408,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.6544751274125049
            }
          ],
          "tdCalYear": 0.6544751274125049,
          "tdCheck": "Pass",
          "tdInception": 0.34760131112721676,
          "tdYTD": -0.010237074330671891,
          "tdYTDChange": -0.00024107384854221703,
          "te1M": 0.006572777129286009,
          "te1Y": 0.9255070209715378,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.9255518055217311
            }
          ],
          "teCalYear": 0.9255518055217311,
          "teInception": 0.7468162220086372,
          "teYTD": 0.009320414332654972,
          "theoriticalDTD": -3.009051906028626,
          "tradarCode": "HK-HEALTH",
          "volatilityMTD": 0.36103076670999645,
          "volatilityQTD": 0.3366036751591799,
          "volatilityYTD": 0.3233994768829017
        },
        {
          "_id": "IjXDWapzpZ",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.38251373900167995
            },
            {
              "key": 2021,
              "value": 0.03245626929295886
            },
            {
              "key": 2020,
              "value": -0.02796233586237451
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.9109995528258668
            },
            {
              "key": 2021,
              "value": 0.05906288055979725
            },
            {
              "key": 2020,
              "value": -0.023703032704900928
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 11043205,
          "aumLastQuarter": 11043205,
          "aumLastYear": 11043205,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "SH931079 Index",
          "benchmarkIndexName": "CSI 5G Communication Index",
          "betaYTD": 0.8994365782143632,
          "ccy": "CNY",
          "classID": "CLS00181",
          "className": "CO-CS5G",
          "companyID": "CSOP",
          "dtdDiff": 0.0008039892682007999,
          "fundDTDDiff": 0.035015129994441896,
          "fundID": "1000125",
          "fundName": "CSOP Yinhua CSI 5G Communications Theme ETF",
          "fundNote": null,
          "fundReturn": 5.028,
          "fundReturn1M": -0.06681514476614703,
          "fundReturn1Y": -0.08451986453515914,
          "fundReturn1YRolling": -0.08451986453515914,
          "fundReturn3M": 0.0034726380074241447,
          "fundReturn3MRolling": 0.0034726380074241447,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.04730363056926801,
          "fundReturn6MRolling": 0.04730363056926801,
          "fundReturnInception": -0.2815808650177891,
          "fundReturnMTD": -0.07085042687659404,
          "fundReturnQTD": -0.11848241523195069,
          "fundReturnRebase": 71.84191349822093,
          "fundReturnYTD": 0.1592999930829353,
          "fundType": "EE",
          "inceptionDate": "2020-10-22T00:00:00Z",
          "indexDTDDiff": 0.034211140726241096,
          "indexMTDVolatility": 0.320731776757076,
          "indexQTDVolatility": 0.3220696643612933,
          "indexReturn": 110.74064,
          "indexReturn1M": -0.0837076314450792,
          "indexReturn1Y": -0.8701553108914606,
          "indexReturn1YRolling": -0.8701553108914606,
          "indexReturn3M": -0.02146854155297373,
          "indexReturn3MRolling": -0.02146854155297373,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.011779109266761179,
          "indexReturn6MRolling": 0.011779109266761179,
          "indexReturnChange": 0.34566042008718867,
          "indexReturnChangePct": 0.034211140726241096,
          "indexReturnInception": -0.8955060106814622,
          "indexReturnMTD": -0.0875644670621245,
          "indexReturnQTD": -0.12210870901338011,
          "indexReturnRebase": 10.449398931853777,
          "indexYTD": 0.1355216899385403,
          "indexYTDVolatility": 0.316154684289415,
          "location": "Hong Kong",
          "navChange": 2.4304513695400374,
          "navChangePct": 0.035015129994441896,
          "navRebase": 71.84191349822093,
          "netAssetValue": 5.028,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3193 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.016714040185530465,
          "td1Y": 0.7856354463563014,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.5284858138241868
            },
            {
              "key": 2021,
              "value": -0.026606611266838387
            }
          ],
          "tdCalYear": 0.5284858138241868,
          "tdCheck": "Pass",
          "tdInception": 0.6139251456636731,
          "tdYTD": 0.023778303144394997,
          "tdYTDChange": 0.001657316672042164,
          "te1M": 0.04733886752271531,
          "te1Y": 0.8640202802041157,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.862676804099381
            },
            {
              "key": 2021,
              "value": 0.026153524805725022
            }
          ],
          "teCalYear": 0.862676804099381,
          "teInception": 0.6151201348096405,
          "teYTD": 0.06437357319054114,
          "theoriticalDTD": -3.4211140726241096,
          "tradarCode": "CO-CS5G",
          "volatilityMTD": 0.30317881707940336,
          "volatilityQTD": 0.3014054833265091,
          "volatilityYTD": 0.2897310592113236
        },
        {
          "_id": "I0XYiapzMP",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.41647123741205694
            },
            {
              "key": 2021,
              "value": 0.08832949654689215
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.40504488975077213
            },
            {
              "key": 2021,
              "value": 0.10358111000142678
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 2675794,
          "aumLastQuarter": 2675794,
          "aumLastYear": 2675794,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "SOCLOUDN Index",
          "benchmarkIndexName": "Solactive Global Cloud Computing Technology Index",
          "betaYTD": 0.9971022983835074,
          "ccy": "USD",
          "classID": "CLS00188",
          "className": "HK-CLOUD",
          "companyID": "CSOP",
          "dtdDiff": -0.000051294969327653206,
          "fundDTDDiff": 0.020685319082049736,
          "fundID": "1000132",
          "fundName": "CSOP Global Cloud Computing Technology Index ETF",
          "fundNote": null,
          "fundReturn": 1.6234,
          "fundReturn1M": -0.029995219885277313,
          "fundReturn1Y": 0.12830136224631628,
          "fundReturn1YRolling": 0.12830136224631628,
          "fundReturn3M": 0.08414585281153997,
          "fundReturn3MRolling": 0.08414585281153997,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.21095032075190212,
          "fundReturn6MRolling": 0.21095032075190212,
          "fundReturnInception": -0.1875688119307377,
          "fundReturnMTD": -0.042128864762803975,
          "fundReturnQTD": 0.02081368295290198,
          "fundReturnRebase": 81.24311880692626,
          "fundReturnYTD": 0.2792750197005516,
          "fundType": "EE",
          "inceptionDate": "2021-05-12T08:00:00Z",
          "indexDTDDiff": 0.02073661405137739,
          "indexMTDVolatility": 0.1974980106145228,
          "indexQTDVolatility": 0.19783226392999742,
          "indexReturn": 2704.36,
          "indexReturn1M": -0.027761200468798264,
          "indexReturn1Y": 0.16146211363119045,
          "indexReturn1YRolling": 0.16146211363119045,
          "indexReturn3M": 0.09256476139687475,
          "indexReturn3MRolling": 0.09256476139687475,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.2297967740321869,
          "indexReturn6MRolling": 0.2297967740321869,
          "indexReturnChange": 1.7418874145939327,
          "indexReturnChangePct": 0.02073661405137739,
          "indexReturnInception": -0.14257541890585124,
          "indexReturnMTD": -0.04031285043080812,
          "indexReturnQTD": 0.026003293092851543,
          "indexReturnRebase": 85.74245810941466,
          "indexYTD": 0.30589263553351476,
          "indexYTDVolatility": 0.2326321830865785,
          "location": "Hong Kong",
          "navChange": 1.6464818336502844,
          "navChangePct": 0.020685319082049736,
          "navRebase": 81.24311880692626,
          "netAssetValue": 1.6234,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "3194 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": -0.0018160143319958522,
          "td1Y": -0.033160751384874176,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.011426347661284808
            }
          ],
          "tdCalYear": -0.011426347661284808,
          "tdCheck": "Pass",
          "tdInception": -0.044993393024886474,
          "tdYTD": -0.026617615832963182,
          "tdYTDChange": -0.0006037304054102854,
          "te1M": 0.0021807508427717755,
          "te1Y": 0.003742038059150498,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.006855591842622064
            }
          ],
          "teCalYear": 0.006855591842622064,
          "teInception": 0.005754529772678911,
          "teYTD": 0.003223824254013146,
          "theoriticalDTD": -2.073661405137739,
          "tradarCode": "HK-CLOUD",
          "volatilityMTD": 0.1960502571220528,
          "volatilityQTD": 0.19647247087678746,
          "volatilityYTD": 0.23198099994427743
        },
        {
          "_id": "I0vDiaMzMh",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.33315444245676806
            },
            {
              "key": 2021,
              "value": -0.2766329138494388
            },
            {
              "key": 2020,
              "value": -0.08826074132337025
            },
            {
              "key": 2019,
              "value": 0.18831199177493207
            },
            {
              "key": 2018,
              "value": -0.265493864240968
            },
            {
              "key": 2017,
              "value": 0.6184722222222223
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8904597925494148
            },
            {
              "key": 2021,
              "value": -0.15126511459495084
            },
            {
              "key": 2020,
              "value": -0.03400597734992328
            },
            {
              "key": 2019,
              "value": 0.0906940032577952
            },
            {
              "key": 2018,
              "value": -0.1361485871089252
            },
            {
              "key": 2017,
              "value": 0.25554193574648765
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 1958323324,
          "aumLastQuarter": 1958323324,
          "aumLastYear": 1958323324,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSI Index",
          "benchmarkIndexName": "Hong Kong Hang Seng Index",
          "betaYTD": 2.061446902293119,
          "ccy": "HKD",
          "classID": "CLS00074",
          "className": "HK-HSI-L",
          "companyID": "CSOP",
          "dtdDiff": -0.0067935349799796985,
          "fundDTDDiff": 0.03184004894190928,
          "fundID": "1000027",
          "fundName": "CSOP Hang Seng Index Daily (2x) Leveraged Product",
          "fundNote": null,
          "fundReturn": 3.7106,
          "fundReturn1M": -0.1512808783165599,
          "fundReturn1Y": -0.2375375005137057,
          "fundReturn1YRolling": -0.2375375005137057,
          "fundReturn3M": -0.012928282613322017,
          "fundReturn3MRolling": -0.012928282613322017,
          "fundReturn3Y": -0.5440795212994705,
          "fundReturn3YRolling": -0.5440795212994705,
          "fundReturn6M": -0.15077585023115303,
          "fundReturn6MRolling": -0.15077585023115303,
          "fundReturnInception": -0.48463888888888895,
          "fundReturnMTD": -0.17114903502501788,
          "fundReturnQTD": -0.0540940144794535,
          "fundReturnRebase": 51.5361111111112,
          "fundReturnYTD": -0.17048198157918282,
          "fundType": "LE",
          "inceptionDate": "2017-03-13T16:00:00Z",
          "indexDTDDiff": 0.03863358392188898,
          "indexMTDVolatility": 0.19999174659714306,
          "indexQTDVolatility": 0.23753674598781374,
          "indexReturn": 2355.55371,
          "indexReturn1M": -0.07775734634581843,
          "indexReturn1Y": -0.8823588958219507,
          "indexReturn1YRolling": -0.8823588958219507,
          "indexReturn3M": -0.006003114795768538,
          "indexReturn3MRolling": -0.006003114795768538,
          "indexReturn3Y": -0.9073421386779829,
          "indexReturn3YRolling": -0.9073421386779829,
          "indexReturn6M": -0.06552593768108983,
          "indexReturn6MRolling": -0.06552593768108983,
          "indexReturnChange": 0.18732718497570566,
          "indexReturnChangePct": 0.01931679196094449,
          "indexReturnInception": -0.9011503847934109,
          "indexReturnMTD": -0.0851921316029407,
          "indexReturnQTD": -0.02416594313808096,
          "indexReturnRebase": 9.884961520658962,
          "indexYTD": -0.06957421957690735,
          "indexYTDVolatility": 0.22359841289246316,
          "location": "Hong Kong",
          "navChange": 1.5902777777777786,
          "navChangePct": 0.03184004894190928,
          "navRebase": 51.5361111111112,
          "netAssetValue": 3.7106,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7200 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 40
            },
            {
              "key": -15,
              "value": 30
            },
            {
              "key": -10,
              "value": 20
            },
            {
              "key": -5,
              "value": 10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -10
            },
            {
              "key": 10,
              "value": -20
            },
            {
              "key": 15,
              "value": -30
            },
            {
              "key": 20,
              "value": -40
            }
          ],
          "td1M": -0.00004078742024861084,
          "td1Y": 0.00945292095449074,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.016312663318821766
            },
            {
              "key": 2021,
              "value": 0.0002010080529764678
            },
            {
              "key": 2020,
              "value": 0.00023980405332925034
            },
            {
              "key": 2019,
              "value": 0.00010743503502067077
            },
            {
              "key": 2018,
              "value": 0.0001233800055634146
            }
          ],
          "tdCalYear": 0.016312663318821766,
          "tdCheck": "Pass",
          "tdInception": 0.0013930246411362163,
          "tdYTD": 0.00007675478850921257,
          "tdYTDChange": -0.0000416381198090237,
          "te1M": 0.0048465685610276695,
          "te1Y": 0.1315585632044968,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.17372458068835991
            },
            {
              "key": 2021,
              "value": 0.004363205159679441
            },
            {
              "key": 2020,
              "value": 0.007996648394934762
            },
            {
              "key": 2019,
              "value": 0.004209207212047125
            },
            {
              "key": 2018,
              "value": 0.005431672235054265
            }
          ],
          "teCalYear": 0.17372458068835991,
          "teInception": 0.048281754271210085,
          "teYTD": 0.005147478113979069,
          "theoriticalDTD": -7.726716784377796,
          "tradarCode": "HK-HSI-L",
          "volatilityMTD": 0.3953922784926876,
          "volatilityQTD": 0.4788098086285832,
          "volatilityYTD": 0.46806648537815265
        },
        {
          "_id": "IjXDWtMzMs",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.028761329305135863
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": 0.019861858229620832
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "SOULOGCN Index",
          "benchmarkIndexName": "Solactive US Large Oil & Gas Companies Index NTR",
          "betaYTD": 1.9997726903051114,
          "ccy": "USD",
          "classID": "CLS00230",
          "className": "USD",
          "companyID": "CSOP",
          "dtdDiff": -0.00021031817236716321,
          "fundDTDDiff": 0.005647002282830549,
          "fundID": "1000161",
          "fundName": "CSOP US Large Oil & Gas Companies Daily (2x) Leveraged Product",
          "fundNote": null,
          "fundReturn": 0.837,
          "fundReturn1M": 0.03409933283914013,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": 0.28295524218270995,
          "fundReturn3MRolling": 0.28295524218270995,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.09469003400470832,
          "fundReturn6MRolling": 0.09469003400470832,
          "fundReturnInception": 0.011480362537764188,
          "fundReturnMTD": 0.004560729716754475,
          "fundReturnQTD": 0.1981105067277411,
          "fundReturnRebase": 101.1480362537765,
          "fundReturnYTD": -0.01679783859978856,
          "fundType": "LE",
          "inceptionDate": "2022-11-23T00:00:00Z",
          "indexDTDDiff": 0.005857320455197712,
          "indexMTDVolatility": 0.15687323856382496,
          "indexQTDVolatility": 0.18337329063992372,
          "indexReturn": 1678.02,
          "indexReturn1M": 0.02292095926652915,
          "indexReturn1Y": "-",
          "indexReturn1YRolling": "-",
          "indexReturn3M": 0.15528720042410504,
          "indexReturn3MRolling": 0.15528720042410504,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.09107578269774685,
          "indexReturn6MRolling": 0.09107578269774685,
          "indexReturnChange": 0.3119330298882943,
          "indexReturnChangePct": 0.002928660227598856,
          "indexReturnInception": 0.0682242098227075,
          "indexReturnMTD": 0.007928785093884061,
          "indexReturnQTD": 0.10810864354062244,
          "indexReturnRebase": 106.8224209822709,
          "indexYTD": 0.04742049249399205,
          "indexYTDVolatility": 0.2443541984854828,
          "location": "Hong Kong",
          "navChange": 0.5679758308156977,
          "navChangePct": 0.005647002282830549,
          "navRebase": 101.1480362537765,
          "netAssetValue": 0.837,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7204 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 40
            },
            {
              "key": -15,
              "value": 30
            },
            {
              "key": -10,
              "value": 20
            },
            {
              "key": -5,
              "value": 10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -10
            },
            {
              "key": 10,
              "value": -20
            },
            {
              "key": 15,
              "value": -30
            },
            {
              "key": 20,
              "value": -40
            }
          ],
          "td1M": -0.000473244010561887,
          "td1Y": -0.0004755233138689871,
          "tdAnnualYears": [],
          "tdCalYear": -0.0004296271790035777,
          "tdCheck": "Pass",
          "tdInception": -0.0004755233138689871,
          "tdYTD": -0.0004796159628378771,
          "tdYTDChange": 0.0000017262678876328293,
          "te1M": 0.0004046404868914505,
          "te1Y": 0.0004900255656508873,
          "teAnnualYears": [],
          "teCalYear": 0.0004927986650487607,
          "teInception": 0.0004900255656508872,
          "teYTD": 0.0004911530288285642,
          "theoriticalDTD": -1.1714640910395424,
          "tradarCode": "HK-OLG-L",
          "volatilityMTD": 0.3108511878932131,
          "volatilityQTD": 0.3644193820455618,
          "volatilityYTD": 0.48871386956973123
        },
        {
          "_id": "IjvYWap-M2",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.938347760888828
            },
            {
              "key": 2021,
              "value": -0.6406060531827591
            },
            {
              "key": 2020,
              "value": 0.05387961636267424
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.9038012181091002
            },
            {
              "key": 2021,
              "value": -0.3501347037111763
            },
            {
              "key": 2020,
              "value": 0.04000730704525046
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 2701409585,
          "aumLastQuarter": 2701409585,
          "aumLastYear": 2701409585,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSTECH Index",
          "benchmarkIndexName": "HSTECH Index",
          "betaYTD": 2.002252217832924,
          "ccy": "HKD",
          "classID": "CLS00182",
          "className": "HK-HST-L",
          "companyID": "CSOP",
          "dtdDiff": -0.0005881914202712224,
          "fundDTDDiff": 0.05086545396265718,
          "fundID": "1000126",
          "fundName": "CSOP Hang Seng TECH Index Daily (2x) Leveraged Product",
          "fundNote": null,
          "fundReturn": 5.2455,
          "fundReturn1M": -0.11865517415192295,
          "fundReturn1Y": -0.255461087533533,
          "fundReturn1YRolling": -0.255461087533533,
          "fundReturn3M": 0.28386812541302575,
          "fundReturn3MRolling": 0.28386812541302575,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.04916294977698654,
          "fundReturn6MRolling": 0.04916294977698654,
          "fundReturnInception": -0.32289044650118115,
          "fundReturnMTD": -0.15012718523679147,
          "fundReturnQTD": 0.13455465674611755,
          "fundReturnRebase": 67.71095534988204,
          "fundReturnYTD": -0.07771428571428574,
          "fundType": "LE",
          "inceptionDate": "2020-12-09T00:00:00Z",
          "indexDTDDiff": 0.051453645382928404,
          "indexMTDVolatility": 0.31524105651051915,
          "indexQTDVolatility": 0.3426426290352929,
          "indexReturn": 539.62406,
          "indexReturn1M": -0.05792888930229223,
          "indexReturn1Y": -0.8730048315318051,
          "indexReturn1YRolling": -0.8730048315318051,
          "indexReturn3M": 0.15820782670618017,
          "indexReturn3MRolling": 0.15820782670618017,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.0791353935167931,
          "indexReturn6MRolling": 0.0791353935167931,
          "indexReturnChange": 0.1670574454717917,
          "indexReturnChangePct": 0.025726822691464202,
          "indexReturnInception": -0.9333943002578449,
          "indexReturnMTD": -0.07516906932890932,
          "indexReturnQTD": 0.08116307604028261,
          "indexReturnRebase": 6.660569974215527,
          "indexYTD": 0.02442952346388605,
          "indexYTDVolatility": 0.3422181450456557,
          "location": "Hong Kong",
          "navChange": 3.2774400082613653,
          "navChangePct": 0.05086545396265718,
          "navRebase": 13.542191069976406,
          "netAssetValue": 5.2455,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7226 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 40
            },
            {
              "key": -15,
              "value": 30
            },
            {
              "key": -10,
              "value": 20
            },
            {
              "key": -5,
              "value": 10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -10
            },
            {
              "key": 10,
              "value": -20
            },
            {
              "key": 15,
              "value": -30
            },
            {
              "key": 20,
              "value": -40
            }
          ],
          "td1M": 0.0001733515114057235,
          "td1Y": 0.007423805599979004,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.058106418226497654
            },
            {
              "key": 2021,
              "value": -0.00013765745718243635
            }
          ],
          "tdCalYear": 0.058106418226497654,
          "tdCheck": "Pass",
          "tdInception": 0.016801191718795898,
          "tdYTD": -0.00033719569956321756,
          "tdYTDChange": -0.0000015211861861091068,
          "te1M": 0.0010849831563543675,
          "te1Y": 0.1216472979225721,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.4544284238422227
            },
            {
              "key": 2021,
              "value": 0.0025783945978145725
            }
          ],
          "teCalYear": 0.4544284238422227,
          "teInception": 0.24661492047378092,
          "teYTD": 0.0018957052888128646,
          "theoriticalDTD": -10.29072907658568,
          "tradarCode": "HK-HST-L",
          "volatilityMTD": 0.6289927684972629,
          "volatilityQTD": 0.6837131985125743,
          "volatilityYTD": 0.6858543602328416
        },
        {
          "_id": "I0vDiapzp8",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.4471607076580477
            },
            {
              "key": 2021,
              "value": -0.13904392654765463
            },
            {
              "key": 2020,
              "value": 0.34120000000000017
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.887477304729646
            },
            {
              "key": 2021,
              "value": -0.05560619347608742
            },
            {
              "key": 2020,
              "value": 0.15662765586748906
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 240335070,
          "aumLastQuarter": 240335070,
          "aumLastYear": 240335070,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "SHSZ300 Index",
          "benchmarkIndexName": "CSI 300 Index",
          "betaYTD": 1.5861043499637157,
          "ccy": "CNY",
          "classID": "CLS00176",
          "className": "HK-CS3-L",
          "companyID": "CSOP",
          "dtdDiff": -0.0027987442001009555,
          "fundDTDDiff": 0.01951142961761798,
          "fundID": "1000121",
          "fundName": "CSOP CSI 300 Index Daily (2x) Leveraged Product",
          "fundNote": null,
          "fundReturn": 3.898,
          "fundReturn1M": -0.11164794092846231,
          "fundReturn1Y": -0.2623152476296814,
          "fundReturn1YRolling": -0.2623152476296814,
          "fundReturn3M": -0.06325098529270401,
          "fundReturn3MRolling": -0.06325098529270401,
          "fundReturn3Y": -0.518230132245705,
          "fundReturn3YRolling": -0.518230132245705,
          "fundReturn6M": -0.1968185939174153,
          "fundReturn6MRolling": -0.1968185939174153,
          "fundReturnInception": -0.44314285714285717,
          "fundReturnMTD": -0.12056673585416477,
          "fundReturnQTD": -0.053883495145631066,
          "fundReturnRebase": 55.68571428571423,
          "fundReturnYTD": -0.1276909994181623,
          "fundType": "LE",
          "inceptionDate": "2020-07-24T00:00:00Z",
          "indexDTDDiff": 0.022310173817718937,
          "indexMTDVolatility": 0.16079419842074555,
          "indexQTDVolatility": 0.17815982113963114,
          "indexReturn": 520.56904,
          "indexReturn1M": -0.06811636714474822,
          "indexReturn1Y": -0.8727065670298715,
          "indexReturn1YRolling": -0.8727065670298715,
          "indexReturn3M": -0.03986552743852567,
          "indexReturn3MRolling": -0.03986552743852567,
          "indexReturn3Y": -0.8925392184993818,
          "indexReturn3YRolling": -0.8925392184993818,
          "indexReturn6M": -0.11280428737006742,
          "indexReturn6MRolling": -0.11280428737006742,
          "indexReturnChange": 0.12746233012768649,
          "indexReturnChangePct": 0.011155086908859468,
          "indexReturnInception": -0.8844615155839746,
          "indexReturnMTD": -0.07382038914418065,
          "indexReturnQTD": -0.01726718560411289,
          "indexReturnRebase": 11.55384844160251,
          "indexYTD": -0.05997406161422092,
          "indexYTDVolatility": 0.17112986088410972,
          "location": "Hong Kong",
          "navChange": 1.065714285714293,
          "navChangePct": 0.01951142961761798,
          "navRebase": 55.68571428571423,
          "netAssetValue": 3.898,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7233 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 40
            },
            {
              "key": -15,
              "value": 30
            },
            {
              "key": -10,
              "value": 20
            },
            {
              "key": -5,
              "value": 10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -10
            },
            {
              "key": 10,
              "value": -20
            },
            {
              "key": 15,
              "value": -30
            },
            {
              "key": 20,
              "value": -40
            }
          ],
          "td1M": 0.0011214196661499967,
          "td1Y": 0.007074036615578299,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.010475564857813842
            },
            {
              "key": 2021,
              "value": -0.000017850065398839478
            }
          ],
          "tdCalYear": 0.010475564857813842,
          "tdCheck": "Pass",
          "tdInception": 0.002627326131249538,
          "tdYTD": -0.00014834415090291754,
          "tdYTDChange": -0.000016565000307487747,
          "te1M": 0.0048808409769170134,
          "te1Y": 0.11330821557295474,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.138483463607329
            },
            {
              "key": 2021,
              "value": 0.00020701689162361053
            }
          ],
          "teCalYear": 0.138483463607329,
          "teInception": 0.06969992655841305,
          "teYTD": 0.0069247807287741575,
          "theoriticalDTD": -4.462034763543787,
          "tradarCode": "HK-CS3-L",
          "volatilityMTD": 0.2883955336364219,
          "volatilityQTD": 0.3059260359209499,
          "volatilityYTD": 0.28379563557232473
        },
        {
          "_id": "I0vYitp-MN",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.3368834080717489
            },
            {
              "key": 2021,
              "value": -0.2864
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8919441243089345
            },
            {
              "key": 2021,
              "value": -0.13137604024779914
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 8306951,
          "aumLastQuarter": 8306951,
          "aumLastYear": 8306951,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "XIN9I Index",
          "benchmarkIndexName": "FTSE China A50 Index",
          "betaYTD": 1.1883120136625958,
          "ccy": "USD",
          "classID": "CLS00184",
          "className": "HK-A50-L",
          "companyID": "CSOP",
          "dtdDiff": -0.003775069572194978,
          "fundDTDDiff": 0.01053811659192827,
          "fundID": "1000128",
          "fundName": "CSOP FTSE China A50 Index Daily (2x) Leveraged Product",
          "fundNote": null,
          "fundReturn": 0.4507,
          "fundReturn1M": -0.09023011707710948,
          "fundReturn1Y": -0.11592781482934489,
          "fundReturn1YRolling": -0.11592781482934489,
          "fundReturn3M": 0.025716886663632144,
          "fundReturn3MRolling": 0.025716886663632144,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.0998601957259837,
          "fundReturn6MRolling": -0.0998601957259837,
          "fundReturnInception": -0.5493,
          "fundReturnMTD": -0.09479815223940558,
          "fundReturnQTD": 0.02037582069277799,
          "fundReturnRebase": 45.070000000000036,
          "fundReturnYTD": -0.04754860524091298,
          "fundType": "LE",
          "inceptionDate": "2021-01-19T00:00:00Z",
          "indexDTDDiff": 0.014313186164123248,
          "indexMTDVolatility": 0.17537347199655556,
          "indexQTDVolatility": 0.22594453293809327,
          "indexReturn": 239.50228000054938,
          "indexReturn1M": -0.0775888060896095,
          "indexReturn1Y": -0.8859141711752132,
          "indexReturn1YRolling": -0.8859141711752132,
          "indexReturn3M": -0.04691859834174228,
          "indexReturn3MRolling": -0.04691859834174228,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": -0.13822503980414014,
          "indexReturn6MRolling": -0.13822503980414014,
          "indexReturnChange": 0.0600361307601851,
          "indexReturnChangePct": 0.007156593082061624,
          "indexReturnInception": -0.9155103773193164,
          "indexReturnMTD": -0.08136197614807905,
          "indexReturnQTD": 0.010489154307308013,
          "indexReturnRebase": 8.448962268068371,
          "indexYTD": -0.09983281122860854,
          "indexYTDVolatility": 0.22132983108638135,
          "location": "Hong Kong",
          "navChange": 0.46999999999999886,
          "navChangePct": 0.01053811659192827,
          "navRebase": 45.070000000000036,
          "netAssetValue": 0.4507,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7248 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 40
            },
            {
              "key": -15,
              "value": 30
            },
            {
              "key": -10,
              "value": 20
            },
            {
              "key": -5,
              "value": 10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -10
            },
            {
              "key": 10,
              "value": -20
            },
            {
              "key": 15,
              "value": -30
            },
            {
              "key": 20,
              "value": -40
            }
          ],
          "td1M": 0.003444985130558274,
          "td1Y": 0.011440467139621095,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.03141092578754569
            }
          ],
          "tdCalYear": 0.03141092578754569,
          "tdCheck": "Pass",
          "tdInception": 0.004925110165601719,
          "tdYTD": 0.0008885862828390175,
          "tdYTDChange": -0.000028966806553006193,
          "te1M": 0.010289088237592189,
          "te1Y": 0.13097737433312326,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.22428131990025138
            }
          ],
          "teCalYear": 0.22428131990025138,
          "teInception": 0.08662548645777059,
          "teYTD": 0.01447000725806475,
          "theoriticalDTD": -2.8626372328246497,
          "tradarCode": "HK-A50-L",
          "volatilityMTD": 0.27416186469012627,
          "volatilityQTD": 0.32980650540267,
          "volatilityYTD": 0.29792763731192545
        },
        {
          "_id": "IjvYitMzpu",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.1104986218992734
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.052637858258990256
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "SH399975 Index",
          "benchmarkIndexName": "CSI All Share Investment Banking & Brokerage Index",
          "betaYTD": 1.817849175457122,
          "ccy": "CNY",
          "classID": "CLS00229",
          "className": "HK-IBB-L",
          "companyID": "CSOP",
          "dtdDiff": -0.0019319296788377072,
          "fundDTDDiff": -0.013436704256093113,
          "fundID": "1000160",
          "fundName": "CSOP CSI Brokerage Index Daily (2x) Leveraged Product",
          "fundNote": null,
          "fundReturn": 8.6786,
          "fundReturn1M": -0.015417777525668086,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": 0.20250516135289787,
          "fundReturn3MRolling": 0.20250516135289787,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.08018022503236089,
          "fundReturn6MRolling": 0.08018022503236089,
          "fundReturnInception": 0.08727136056126272,
          "fundReturnMTD": -0.028880906822428942,
          "fundReturnQTD": 0.2715152225510591,
          "fundReturnRebase": 108.72713605612626,
          "fundReturnYTD": 0.22233802816901416,
          "fundType": "LE",
          "inceptionDate": "2022-10-18T00:00:00Z",
          "indexDTDDiff": -0.011504774577255406,
          "indexMTDVolatility": 0.29196262542542817,
          "indexQTDVolatility": 0.3219365381881871,
          "indexReturn": 100.66425,
          "indexReturn1M": -0.018189069334031527,
          "indexReturn1Y": "-",
          "indexReturn1YRolling": "-",
          "indexReturn3M": 0.08668629082107304,
          "indexReturn3MRolling": 0.08668629082107304,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.027989839812530892,
          "indexReturn6MRolling": 0.027989839812530892,
          "indexReturnChange": -0.6084548489644561,
          "indexReturnChangePct": -0.005752387288627814,
          "indexReturnInception": 0.05165864304988599,
          "indexReturnMTD": -0.026133276731614585,
          "indexReturnQTD": 0.1417326496232134,
          "indexReturnRebase": 105.16586430498865,
          "indexYTD": 0.11009148108579248,
          "indexYTDVolatility": 0.2661264066228935,
          "location": "Hong Kong",
          "navChange": -1.4808318717113451,
          "navChangePct": -0.013436704256093113,
          "navRebase": 108.72713605612626,
          "netAssetValue": 8.6786,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7252 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 40
            },
            {
              "key": -15,
              "value": 30
            },
            {
              "key": -10,
              "value": 20
            },
            {
              "key": -5,
              "value": 10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -10
            },
            {
              "key": 10,
              "value": -20
            },
            {
              "key": 15,
              "value": -30
            },
            {
              "key": 20,
              "value": -40
            }
          ],
          "td1M": 0.0013286707611008437,
          "td1Y": -0.056651149516233464,
          "tdAnnualYears": [],
          "tdCalYear": -0.708538876540515,
          "tdCheck": "Pass",
          "tdInception": -0.05665114951623346,
          "tdYTD": 0.00003473979022580472,
          "tdYTDChange": -0.000012291684181646953,
          "te1M": 0.004940530950538136,
          "te1Y": 0.8893445386403626,
          "teAnnualYears": [],
          "teCalYear": 3.176211054785509,
          "teInception": 0.8893445386403623,
          "teYTD": 0.006893115084754599,
          "theoriticalDTD": 2.300954915451081,
          "tradarCode": "HK-IBB-L",
          "volatilityMTD": 0.5693897836551923,
          "volatilityQTD": 0.609696556559446,
          "volatilityYTD": 0.49342050366625184
        },
        {
          "_id": "I0vYiap-Mv",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.6031433506044905
            },
            {
              "key": 2021,
              "value": 0.5192065491183879
            },
            {
              "key": 2020,
              "value": 0.9111423127068499
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.33343579380489496
            },
            {
              "key": 2021,
              "value": 0.27473177181128894
            },
            {
              "key": 2020,
              "value": 0.4171625564933952
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 3864838,
          "aumLastQuarter": 3864838,
          "aumLastYear": 3864838,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "NDX Index",
          "benchmarkIndexName": "NASDAQ 100 Index",
          "betaYTD": 1.9723819076715694,
          "ccy": "USD",
          "classID": "CLS00174",
          "className": "HK-NDX-L",
          "companyID": "CSOP",
          "dtdDiff": -0.00045387977843724947,
          "fundDTDDiff": 0.04260752015220537,
          "fundID": "1000119",
          "fundName": "CSOP NASDAQ-100 Index Daily (2x) Leveraged Product",
          "fundNote": null,
          "fundReturn": 2.0824,
          "fundReturn1M": -0.05534385773906747,
          "fundReturn1Y": 0.29221222463543284,
          "fundReturn1YRolling": 0.29221222463543284,
          "fundReturn3M": 0.17716223855285462,
          "fundReturn3MRolling": 0.17716223855285462,
          "fundReturn3Y": 0.22386129885395234,
          "fundReturn3YRolling": 0.22386129885395234,
          "fundReturn6M": 0.5298266235674405,
          "fundReturn6MRolling": 0.5298266235674405,
          "fundReturnInception": 1.088456523919366,
          "fundReturnMTD": -0.0564139743531652,
          "fundReturnQTD": 0.006136154998308774,
          "fundReturnRebase": 208.84565239193637,
          "fundReturnYTD": 0.8125163199582206,
          "fundType": "LE",
          "inceptionDate": "2020-05-14T08:00:00Z",
          "indexDTDDiff": 0.04306139993064262,
          "indexMTDVolatility": 0.1804312586827164,
          "indexQTDVolatility": 0.1666369413881288,
          "indexReturn": 15376.55,
          "indexReturn1M": -0.023768755241754036,
          "indexReturn1Y": 0.21986058101556738,
          "indexReturn1YRolling": 0.21986058101556738,
          "indexReturn3M": 0.10316869856433919,
          "indexReturn3MRolling": 0.10316869856433919,
          "indexReturn3Y": 0.2818224636019957,
          "indexReturn3YRolling": 0.2818224636019957,
          "indexReturn6M": 0.2768972572935662,
          "indexReturn6MRolling": 0.2768972572935662,
          "indexReturnChange": 3.5636113813010297,
          "indexReturnChangePct": 0.02153069996532131,
          "indexReturnInception": 0.6907664101065862,
          "indexReturnMTD": -0.024144824522434494,
          "indexReturnQTD": 0.013000676583300397,
          "indexReturnRebase": 169.07664101065816,
          "indexYTD": 0.4041165002442688,
          "indexYTDVolatility": 0.2092846289696671,
          "location": "Hong Kong",
          "navChange": 8.534750777254004,
          "navChangePct": 0.04260752015220537,
          "navRebase": 208.84565239193637,
          "netAssetValue": 2.0824,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7266 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 40
            },
            {
              "key": -15,
              "value": 30
            },
            {
              "key": -10,
              "value": 20
            },
            {
              "key": -5,
              "value": 10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -10
            },
            {
              "key": 10,
              "value": -20
            },
            {
              "key": 15,
              "value": -30
            },
            {
              "key": 20,
              "value": -40
            }
          ],
          "td1M": -0.00032332387491292486,
          "td1Y": -0.00037456475969943663,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.00026010351876995866
            },
            {
              "key": 2021,
              "value": -0.00018753664646239756
            }
          ],
          "tdCalYear": -0.00026010351876995866,
          "tdCheck": "Pass",
          "tdInception": -0.00023426210468013771,
          "tdYTD": -0.00038627227528081724,
          "tdYTDChange": -4.278955895977094e-7,
          "te1M": 0.0008301053066203893,
          "te1Y": 0.0009009764486663376,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.002138107614479006
            },
            {
              "key": 2021,
              "value": 0.0014398442331176268
            }
          ],
          "teCalYear": 0.002138107614479006,
          "teInception": 0.002887760012947042,
          "teYTD": 0.0008148539879371468,
          "theoriticalDTD": -8.612279986128524,
          "tradarCode": "HK-NDX-L",
          "volatilityMTD": 0.3554963083754754,
          "volatilityQTD": 0.32920417587051254,
          "volatilityYTD": 0.4129592047084861
        },
        {
          "_id": "I0XYWapzMK",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.39838152433510343
            },
            {
              "key": 2021,
              "value": -0.436498810985967
            },
            {
              "key": 2020,
              "value": -0.10942772696595215
            },
            {
              "key": 2019,
              "value": 0.22081427394380215
            },
            {
              "key": 2018,
              "value": -0.27184144651095443
            },
            {
              "key": 2017,
              "value": 0.3426944444444444
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8939446695090681
            },
            {
              "key": 2021,
              "value": -0.2460292036057513
            },
            {
              "key": 2020,
              "value": -0.03847221451174154
            },
            {
              "key": 2019,
              "value": 0.10304550729647644
            },
            {
              "key": 2018,
              "value": -0.13532405865423203
            },
            {
              "key": 2017,
              "value": 0.1414008194012697
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 243131459,
          "aumLastQuarter": 243131459,
          "aumLastYear": 243131459,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSCEI Index",
          "benchmarkIndexName": "Hong Kong Stock Exchange Hang Seng China Enterprises Index",
          "betaYTD": 2.0372797239181852,
          "ccy": "HKD",
          "classID": "CLS00072",
          "className": "HK-HCE-L",
          "companyID": "CSOP",
          "dtdDiff": -0.009153504784488398,
          "fundDTDDiff": 0.03552054342185951,
          "fundID": "1000025",
          "fundName": "CSOP Hang Seng China Enterprises Index Daily (2x) Leveraged Product",
          "fundNote": null,
          "fundReturn": 2.1952,
          "fundReturn1M": -0.13625811528624843,
          "fundReturn1Y": -0.24834788563602128,
          "fundReturn1YRolling": -0.24834788563602128,
          "fundReturn3M": 0.04230568349081221,
          "fundReturn3MRolling": 0.04230568349081221,
          "fundReturn3Y": -0.6864671856030851,
          "fundReturn3YRolling": -0.6864671856030851,
          "fundReturn6M": -0.09524790833779828,
          "fundReturn6MRolling": -0.09524790833779828,
          "fundReturnInception": -0.6951111111111111,
          "fundReturnMTD": -0.16315949984751466,
          "fundReturnQTD": -0.016839842350412138,
          "fundReturnRebase": 30.48888888888887,
          "fundReturnYTD": -0.15393509596855004,
          "fundType": "LE",
          "inceptionDate": "2017-03-13T16:00:00Z",
          "indexDTDDiff": 0.044674048206347905,
          "indexMTDVolatility": 0.23392371309545043,
          "indexQTDVolatility": 0.28039825462118323,
          "indexReturn": 813.91615,
          "indexReturn1M": -0.06782669452006473,
          "indexReturn1Y": -0.8815649946305874,
          "indexReturn1YRolling": -0.8815649946305874,
          "indexReturn3M": 0.019270134913172754,
          "indexReturn3MRolling": 0.019270134913172754,
          "indexReturn3Y": -0.9200697497650456,
          "indexReturn3YRolling": -0.9200697497650456,
          "indexReturn6M": -0.029294057466631385,
          "indexReturn6MRolling": -0.029294057466631385,
          "indexReturnChange": 0.17334772110723495,
          "indexReturnChangePct": 0.022337024103173952,
          "indexReturnInception": -0.9206609651700848,
          "indexReturnMTD": -0.08007659614704754,
          "indexReturnQTD": -0.007257112659177056,
          "indexReturnRebase": 7.933903482991518,
          "indexYTD": -0.05212064900974478,
          "indexYTDVolatility": 0.26006306080680897,
          "location": "Hong Kong",
          "navChange": 1.0458333333333307,
          "navChangePct": 0.03552054342185951,
          "navRebase": 30.48888888888887,
          "netAssetValue": 2.1952,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7288 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 40
            },
            {
              "key": -15,
              "value": 30
            },
            {
              "key": -10,
              "value": 20
            },
            {
              "key": -5,
              "value": 10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -10
            },
            {
              "key": 10,
              "value": -20
            },
            {
              "key": 15,
              "value": -30
            },
            {
              "key": 20,
              "value": -40
            }
          ],
          "td1M": -0.00006501356006635838,
          "td1Y": 0.01473028591882737,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.02208565298952899
            },
            {
              "key": 2021,
              "value": 0.0001797231780253105
            },
            {
              "key": 2020,
              "value": 0.00014742356660131785
            },
            {
              "key": 2019,
              "value": 0.00010924061072246028
            },
            {
              "key": 2018,
              "value": 0.00010018403239315591
            }
          ],
          "tdCalYear": 0.02208565298952899,
          "tdCheck": "Pass",
          "tdInception": 0.0025742044224982795,
          "tdYTD": 0.000031845832341355376,
          "tdYTDChange": -0.00005467470605255805,
          "te1M": 0.004454485906111635,
          "te1Y": 0.16344036866760017,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.20059656131229736
            },
            {
              "key": 2021,
              "value": 0.004941747510271707
            },
            {
              "key": 2020,
              "value": 0.00781460733727144
            },
            {
              "key": 2019,
              "value": 0.004653521987878086
            },
            {
              "key": 2018,
              "value": 0.006902175478381721
            }
          ],
          "teCalYear": 0.20059656131229736,
          "teInception": 0.06724958524491598,
          "teYTD": 0.005161245062471661,
          "theoriticalDTD": -8.934809641269581,
          "tradarCode": "HK-HCE-L",
          "volatilityMTD": 0.4657117877185659,
          "volatilityQTD": 0.5679074716019081,
          "volatilityYTD": 0.536210350809844
        },
        {
          "_id": "U0XYWtp-pe",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.07153032153032157
            },
            {
              "key": 2021,
              "value": -0.13241525423728817
            },
            {
              "key": 2020,
              "value": 0.12794981579209397
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.01958610495195867
            },
            {
              "key": 2021,
              "value": -0.05025972202723572
            },
            {
              "key": 2020,
              "value": 0.07826218589161371
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 216302458,
          "aumLastQuarter": 216302458,
          "aumLastYear": 216302458,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "SOLGOLD1 Index",
          "benchmarkIndexName": "Solactive Gold 1-Day Rolling Futures Index",
          "betaYTD": 2.0003132200585503,
          "ccy": "USD",
          "classID": "CLS00175",
          "className": "HK-GLD-L",
          "companyID": "CSOP",
          "dtdDiff": -0.0000038137758269218835,
          "fundDTDDiff": 0.018805427930334462,
          "fundID": "1000120",
          "fundName": "CSOP Gold Futures Daily (2x) Leveraged Product",
          "fundNote": null,
          "fundReturn": 0.9535,
          "fundReturn1M": -0.03482133819212474,
          "fundReturn1Y": 0.1083343019876788,
          "fundReturn1YRolling": 0.1083343019876788,
          "fundReturn3M": -0.03667407557082236,
          "fundReturn3MRolling": -0.03667407557082236,
          "fundReturn3Y": -0.24114604058893752,
          "fundReturn3YRolling": -0.24114604058893752,
          "fundReturn6M": 0.05324201922014815,
          "fundReturn6MRolling": 0.05324201922014815,
          "fundReturnInception": -0.050582495270337535,
          "fundReturnMTD": -0.04372680774245308,
          "fundReturnQTD": -0.002719380817906014,
          "fundReturnRebase": 94.94175047296643,
          "fundReturnYTD": 0.04493150684931502,
          "fundType": "LE",
          "inceptionDate": "2020-06-04T08:00:00Z",
          "indexDTDDiff": 0.018809241706161384,
          "indexMTDVolatility": 0.08997723754760238,
          "indexQTDVolatility": 0.09447592356557073,
          "indexReturn": 136.31,
          "indexReturn1M": -0.017443955885532936,
          "indexReturn1Y": 0.06326053042121704,
          "indexReturn1YRolling": 0.06326053042121704,
          "indexReturn3M": -0.017939481268011592,
          "indexReturn3MRolling": -0.017939481268011592,
          "indexReturn3Y": -0.08449190677681495,
          "indexReturn3YRolling": -0.08449190677681495,
          "indexReturn6M": 0.030543585091101555,
          "indexReturn6MRolling": 0.030543585091101555,
          "indexReturnChange": 0.9612473508931458,
          "indexReturnChangePct": 0.009404620853080692,
          "indexReturnInception": 0.03171359370269444,
          "indexReturnMTD": -0.021955944607878308,
          "indexReturnQTD": -0.0011724188466328833,
          "indexReturnRebase": 103.17135937026941,
          "indexYTD": 0.027591405955522008,
          "indexYTDVolatility": 0.13206470438248963,
          "location": "Hong Kong",
          "navChange": 1.7524644030668242,
          "navChangePct": 0.018805427930334462,
          "navRebase": 94.94175047296643,
          "netAssetValue": 0.9535,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7299 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 40
            },
            {
              "key": -15,
              "value": 30
            },
            {
              "key": -10,
              "value": 20
            },
            {
              "key": -5,
              "value": 10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -10
            },
            {
              "key": 10,
              "value": -20
            },
            {
              "key": 15,
              "value": -30
            },
            {
              "key": 20,
              "value": -40
            }
          ],
          "td1M": 0.000014447875118416328,
          "td1Y": -0.000002762604216931982,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.0000265527010324949
            },
            {
              "key": 2021,
              "value": -0.00008158920738694912
            }
          ],
          "tdCalYear": -0.0000265527010324949,
          "tdCheck": "Pass",
          "tdInception": -0.00005012971150291213,
          "tdYTD": -0.0000017050684518940418,
          "tdYTDChange": -1.3346249209037033e-8,
          "te1M": 0.0001283662371693001,
          "te1Y": 0.0001343011762985662,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.00018498751417297864
            },
            {
              "key": 2021,
              "value": 0.00012253180696596283
            }
          ],
          "teCalYear": 0.00018498751417297864,
          "teInception": 0.00015573137756576656,
          "teYTD": 0.00013713819299793965,
          "theoriticalDTD": -3.7618483412322767,
          "tradarCode": "HK-GLD-L",
          "volatilityMTD": 0.17946958107342026,
          "volatilityQTD": 0.1887175354209531,
          "volatilityYTD": 0.26417956041466906
        },
        {
          "_id": "IjXDWtM-pI",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.01338981768666292
            },
            {
              "key": 2021,
              "value": 0.08590557164274126
            },
            {
              "key": 2020,
              "value": -0.06309166474255357
            },
            {
              "key": 2019,
              "value": -0.12330893019803657
            },
            {
              "key": 2018,
              "value": 0.08092042885274608
            },
            {
              "key": 2017,
              "value": -0.2382777777777778
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8904597925494148
            },
            {
              "key": 2021,
              "value": -0.15126511459495084
            },
            {
              "key": 2020,
              "value": -0.03400597734992328
            },
            {
              "key": 2019,
              "value": 0.0906940032577952
            },
            {
              "key": 2018,
              "value": -0.1361485871089252
            },
            {
              "key": 2017,
              "value": 0.25554193574648765
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 427842302,
          "aumLastQuarter": 427842302,
          "aumLastYear": 427842302,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSI Index",
          "benchmarkIndexName": "Hong Kong Hang Seng Index",
          "betaYTD": -1.033601704161906,
          "ccy": "HKD",
          "classID": "CLS00073",
          "className": "HK-HSI-I",
          "companyID": "CSOP",
          "dtdDiff": 0.0033888755381629165,
          "fundDTDDiff": -0.015927916422781574,
          "fundID": "1000026",
          "fundName": "CSOP Hang Seng Index Daily (-1x) Inverse Product",
          "fundNote": null,
          "fundReturn": 5.5481,
          "fundReturn1M": 0.07880920899121091,
          "fundReturn1Y": 0.005564215028817765,
          "fundReturn1YRolling": 0.005564215028817765,
          "fundReturn3M": -0.016137326878402636,
          "fundReturn3MRolling": -0.016137326878402636,
          "fundReturn3Y": 0.04738441788903347,
          "fundReturn3YRolling": 0.04738441788903347,
          "fundReturn6M": 0.035518309754003496,
          "fundReturn6MRolling": 0.035518309754003496,
          "fundReturnInception": -0.22943055555555558,
          "fundReturnMTD": 0.09188774305282221,
          "fundReturnQTD": 0.01227922930958969,
          "fundReturnRebase": 77.05694444444435,
          "fundReturnYTD": 0.0354023589131085,
          "fundType": "LE",
          "inceptionDate": "2017-03-13T16:00:00Z",
          "indexDTDDiff": -0.01931679196094449,
          "indexMTDVolatility": 0.19999174659714306,
          "indexQTDVolatility": 0.24068615749009256,
          "indexReturn": 2355.55371,
          "indexReturn1M": -0.07775734634581843,
          "indexReturn1Y": -0.8823588958219507,
          "indexReturn1YRolling": -0.8823588958219507,
          "indexReturn3M": -0.006003114795768538,
          "indexReturn3MRolling": -0.006003114795768538,
          "indexReturn3Y": -0.9073421386779829,
          "indexReturn3YRolling": -0.9073421386779829,
          "indexReturn6M": -0.06552593768108983,
          "indexReturn6MRolling": -0.06552593768108983,
          "indexReturnChange": 0.18732718497570566,
          "indexReturnChangePct": 0.01931679196094449,
          "indexReturnInception": -0.9011503847934109,
          "indexReturnMTD": -0.0851921316029407,
          "indexReturnQTD": -0.02416594313808096,
          "indexReturnRebase": 9.884961520658969,
          "indexYTD": -0.06957421957690735,
          "indexYTDVolatility": 0.22480744483385726,
          "location": "Hong Kong",
          "navChange": -1.24722222222222,
          "navChangePct": -0.015927916422781574,
          "navRebase": 77.05694444444435,
          "netAssetValue": 5.5481,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7300 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": -20
            },
            {
              "key": -15,
              "value": -15
            },
            {
              "key": -10,
              "value": -10
            },
            {
              "key": -5,
              "value": -5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": 5
            },
            {
              "key": 10,
              "value": 10
            },
            {
              "key": 15,
              "value": 15
            },
            {
              "key": 20,
              "value": 20
            }
          ],
          "td1M": -0.000005678822453524299,
          "td1Y": -0.007273087337024267,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.011348820570643105
            },
            {
              "key": 2021,
              "value": -0.000194579426848179
            },
            {
              "key": 2020,
              "value": -0.00015885028814792602
            },
            {
              "key": 2019,
              "value": -0.00007955925971704931
            },
            {
              "key": 2018,
              "value": -0.00011642802517921503
            }
          ],
          "tdCalYear": -0.011348820570643105,
          "tdCheck": "Pass",
          "tdInception": -0.0013762590107961176,
          "tdYTD": -0.00007101468312882301,
          "tdYTDChange": 0.000020352295419363178,
          "te1M": 0.0024498358746451285,
          "te1Y": 0.08073646591490315,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.09948002079556258
            },
            {
              "key": 2021,
              "value": 0.0021832139681024656
            },
            {
              "key": 2020,
              "value": 0.003993489622951944
            },
            {
              "key": 2019,
              "value": 0.002105264337532107
            },
            {
              "key": 2018,
              "value": 0.0027209705925633344
            }
          ],
          "teCalYear": 0.09948002079556258,
          "teInception": 0.0333220190374455,
          "teYTD": 0.002585627526604654,
          "theoriticalDTD": -1.931679196094449,
          "tradarCode": "HK-HSI-I",
          "volatilityMTD": 0.19697224887197623,
          "volatilityQTD": 0.24411118210364546,
          "volatilityYTD": 0.2359120748231073
        },
        {
          "_id": "U0vDiapzMG",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.18431588874850968
            },
            {
              "key": 2021,
              "value": -0.02422211418252529
            },
            {
              "key": 2020,
              "value": -0.1772571428571429
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.887477304729646
            },
            {
              "key": 2021,
              "value": -0.05560619347608742
            },
            {
              "key": 2020,
              "value": 0.15662765586748906
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 144798099,
          "aumLastQuarter": 144798099,
          "aumLastYear": 144798099,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "SHSZ300 Index",
          "benchmarkIndexName": "CSI 300 INDEX",
          "betaYTD": -0.8038946800942355,
          "ccy": "CNY",
          "classID": "CLS00177",
          "className": "HK-CS3-I",
          "companyID": "CSOP",
          "dtdDiff": 0.0011400871361286757,
          "fundDTDDiff": -0.010014999772730793,
          "fundID": "1000122",
          "fundName": "CSOP CSI 300 Index Daily (-1x) Inverse Product",
          "fundNote": null,
          "fundReturn": 6.534,
          "fundReturn1M": 0.04839227263975343,
          "fundReturn1Y": 0.01642710472279263,
          "fundReturn1YRolling": 0.01642710472279263,
          "fundReturn3M": -0.0021532963760479973,
          "fundReturn3MRolling": -0.0021532963760479973,
          "fundReturn3Y": 0.017123287671232834,
          "fundReturn3YRolling": 0.017123287671232834,
          "fundReturn6M": 0.04495514081466201,
          "fundReturn6MRolling": 0.04495514081466201,
          "fundReturnInception": -0.06657142857142861,
          "fundReturnMTD": 0.0546875,
          "fundReturnQTD": 0.0027778204085391334,
          "fundReturnRebase": 93.3428571428571,
          "fundReturnYTD": -0.018255578093306357,
          "fundType": "LE",
          "inceptionDate": "2020-07-24T00:00:00Z",
          "indexDTDDiff": -0.011155086908859468,
          "indexMTDVolatility": 0.16079419842074555,
          "indexQTDVolatility": 0.17898482436706195,
          "indexReturn": 520.56904,
          "indexReturn1M": -0.06811636714474822,
          "indexReturn1Y": -0.8727065670298715,
          "indexReturn1YRolling": -0.8727065670298715,
          "indexReturn3M": -0.03986552743852567,
          "indexReturn3MRolling": -0.03986552743852567,
          "indexReturn3Y": -0.8925392184993818,
          "indexReturn3YRolling": -0.8925392184993818,
          "indexReturn6M": -0.11280428737006742,
          "indexReturn6MRolling": -0.11280428737006742,
          "indexReturnChange": 0.12746233012768649,
          "indexReturnChangePct": 0.011155086908859468,
          "indexReturnInception": -0.8844615155839746,
          "indexReturnMTD": -0.07382038914418065,
          "indexReturnQTD": -0.01726718560411289,
          "indexReturnRebase": 11.55384844160251,
          "indexYTD": -0.05997406161422092,
          "indexYTDVolatility": 0.17128263931435864,
          "location": "Hong Kong",
          "navChange": -0.9442857142857264,
          "navChangePct": -0.010014999772730793,
          "navRebase": 93.3428571428571,
          "netAssetValue": 6.534,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7333 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": -20
            },
            {
              "key": -15,
              "value": -15
            },
            {
              "key": -10,
              "value": -10
            },
            {
              "key": -5,
              "value": -5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": 5
            },
            {
              "key": 10,
              "value": 10
            },
            {
              "key": 15,
              "value": 15
            },
            {
              "key": 20,
              "value": 20
            }
          ],
          "td1M": -0.0009651318427876069,
          "td1Y": -0.007559781355885145,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.011203907231577406
            },
            {
              "key": 2021,
              "value": -0.0002469606511575068
            }
          ],
          "tdCalYear": -0.011203907231577406,
          "tdCheck": "Pass",
          "tdInception": -0.0030802615571155793,
          "tdYTD": -0.0004232814850182034,
          "tdYTDChange": 0.000009591218534643435,
          "te1M": 0.002488627138971892,
          "te1Y": 0.0793251560330511,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.0974154538416059
            },
            {
              "key": 2021,
              "value": 0.0003070130630759916
            }
          ],
          "teCalYear": 0.0974154538416059,
          "teInception": 0.04911973696246129,
          "teYTD": 0.0033403425177538945,
          "theoriticalDTD": -1.1155086908859468,
          "tradarCode": "HK-CS3-I",
          "volatilityMTD": 0.14419310764362872,
          "volatilityQTD": 0.15352184192690876,
          "volatilityYTD": 0.14352174939652135
        },
        {
          "_id": "IjXDWaM-pV",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.020069330414157927
            }
          ],
          "annualIndexReturns": [],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "SOLWTI1 Index",
          "benchmarkIndexName": "Solactive WTI 1-Day Rolling Futures Index",
          "betaYTD": -0.9989237212283327,
          "ccy": "USD",
          "classID": "CLS00195",
          "className": "HK-OIL-I",
          "companyID": "CSOP",
          "dtdDiff": 0.000572135892223935,
          "fundDTDDiff": -0.012725137039937495,
          "fundID": "1000138",
          "fundName": "CSOP WTI Crude Oil Futures Daily (-1x) Inverse Product",
          "fundNote": null,
          "fundReturn": 0.5043,
          "fundReturn1M": -0.01772497078301516,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": -0.12675324675324684,
          "fundReturn3MRolling": -0.12675324675324684,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.10774946921443751,
          "fundReturn6MRolling": -0.10774946921443751,
          "fundReturnInception": -0.07991242474001103,
          "fundReturnMTD": -0.0025712025316457776,
          "fundReturnQTD": -0.14059304703476483,
          "fundReturnRebase": 92.00875752599899,
          "fundReturnYTD": -0.09801466642818824,
          "fundType": "LE",
          "inceptionDate": "2021-11-30T08:00:00Z",
          "indexDTDDiff": -0.01329727293216143,
          "indexMTDVolatility": 0.20968296059561917,
          "indexQTDVolatility": 0.22444579640697007,
          "indexReturn": 44.96,
          "indexReturn1M": 0.012840729894120217,
          "indexReturn1Y": "-",
          "indexReturn1YRolling": "-",
          "indexReturn3M": "-",
          "indexReturn3MRolling": "-",
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.05688763516690187,
          "indexReturn6MRolling": 0.05688763516690187,
          "indexReturnChange": 1.3015662916390909,
          "indexReturnChangePct": 0.01329727293216143,
          "indexReturnInception": "-",
          "indexReturnMTD": -0.0024406478810738985,
          "indexReturnQTD": 0.15578406169665815,
          "indexReturnRebase": 99.18376351202296,
          "indexYTD": 0.03142922688690053,
          "indexYTDVolatility": 0.3235704571369798,
          "location": "Hong Kong",
          "navChange": -1.1859149790184347,
          "navChangePct": -0.012725137039937384,
          "navRebase": 92.00875752599899,
          "netAssetValue": 0.5043,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7345 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": -20
            },
            {
              "key": -15,
              "value": -15
            },
            {
              "key": -10,
              "value": -10
            },
            {
              "key": -5,
              "value": -5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": 5
            },
            {
              "key": 10,
              "value": 10
            },
            {
              "key": 15,
              "value": 15
            },
            {
              "key": 20,
              "value": 20
            }
          ],
          "td1M": -0.00007394182231138895,
          "td1Y": -0.000029153777075481304,
          "tdAnnualYears": [],
          "tdCalYear": -0.00013434047257380937,
          "tdCheck": "Pass",
          "tdInception": -0.000029153777075481304,
          "tdYTD": -0.00001681706587506011,
          "tdYTDChange": 0.0000036580929074471753,
          "te1M": 0.00067544116223021,
          "te1Y": 0.0005828456234536193,
          "teAnnualYears": [],
          "teCalYear": 0.0003187271776372508,
          "teInception": 0.0005828456234536195,
          "teYTD": 0.000605790799916255,
          "theoriticalDTD": -1.329727293216143,
          "tradarCode": "HK-OIL-I",
          "volatilityMTD": 0.20199978279809533,
          "volatilityQTD": 0.21490303782082967,
          "volatilityYTD": 0.31899147807361194
        },
        {
          "_id": "U0XYWtM-M6",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.10136342266102494
            },
            {
              "key": 2021,
              "value": 0.06349999999999989
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8919441243089345
            },
            {
              "key": 2021,
              "value": -0.13137604024779914
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 24300941,
          "aumLastQuarter": 24300941,
          "aumLastYear": 24300941,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "XIN9I Index",
          "benchmarkIndexName": "FTSE China A50 Index",
          "betaYTD": -0.5947892202894776,
          "ccy": "USD",
          "classID": "CLS00185",
          "className": "HK-A50-I",
          "companyID": "CSOP",
          "dtdDiff": 0.0019464506715023422,
          "fundDTDDiff": -0.005210142410559282,
          "fundID": "1000129",
          "fundName": "CSOP FTSE China A50 Index Daily (-1x) Inverse Product",
          "fundNote": null,
          "fundReturn": 1.1456,
          "fundReturn1M": 0.041549231748340576,
          "fundReturn1Y": -0.005296518190501032,
          "fundReturn1YRolling": -0.005296518190501032,
          "fundReturn3M": -0.03005672678012028,
          "fundReturn3MRolling": -0.03005672678012028,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.018039633875410965,
          "fundReturn6MRolling": 0.018039633875410965,
          "fundReturnInception": 0.14559999999999995,
          "fundReturnMTD": 0.04468356739011492,
          "fundReturnQTD": -0.022859092459911468,
          "fundReturnRebase": 114.56000000000002,
          "fundReturnYTD": -0.02194143259626058,
          "fundType": "LE",
          "inceptionDate": "2021-01-19T00:00:00Z",
          "indexDTDDiff": -0.007156593082061624,
          "indexMTDVolatility": 0.17537347199655584,
          "indexQTDVolatility": 0.22946242958997132,
          "indexReturn": 239.50228000054938,
          "indexReturn1M": -0.0775888060896095,
          "indexReturn1Y": -0.87716892702633,
          "indexReturn1YRolling": -0.87716892702633,
          "indexReturn3M": -0.04691859834174228,
          "indexReturn3MRolling": -0.04691859834174228,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": -0.13822503980414014,
          "indexReturn6MRolling": -0.13822503980414014,
          "indexReturnChange": 0.0600361307601851,
          "indexReturnChangePct": 0.007156593082061624,
          "indexReturnInception": -0.9155103773193164,
          "indexReturnMTD": -0.08136197614807905,
          "indexReturnQTD": 0.010489154307308013,
          "indexReturnRebase": 8.448962268068362,
          "indexYTD": -0.09983281122860854,
          "indexYTDVolatility": 0.22227822581303414,
          "location": "Hong Kong",
          "navChange": -0.6000000000000085,
          "navChangePct": -0.005210142410559282,
          "navRebase": 114.56000000000002,
          "netAssetValue": 1.1456,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7348 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": -20
            },
            {
              "key": -15,
              "value": -15
            },
            {
              "key": -10,
              "value": -10
            },
            {
              "key": -5,
              "value": -5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": 5
            },
            {
              "key": 10,
              "value": 10
            },
            {
              "key": 15,
              "value": 15
            },
            {
              "key": 20,
              "value": 20
            }
          ],
          "td1M": -0.0019041950293098933,
          "td1Y": -0.007719408857270765,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.011865693415720208
            }
          ],
          "tdCalYear": -0.011865693415720208,
          "tdCheck": "Pass",
          "tdInception": -0.003950604782601627,
          "tdYTD": -0.0005953637490870876,
          "tdYTDChange": 0.000015593953500548643,
          "te1M": 0.005042823810277792,
          "te1Y": 0.0793244452660481,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.0973891149740581
            }
          ],
          "teCalYear": 0.0973891149740581,
          "teInception": 0.0546022280652966,
          "teYTD": 0.007293287503759439,
          "theoriticalDTD": -0.7156593082061624,
          "tradarCode": "HK-A50-I",
          "volatilityMTD": 0.13806733479027863,
          "volatilityQTD": 0.1701146448009001,
          "volatilityYTD": 0.150146086518578
        },
        {
          "_id": "I0vDiapzM1",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.007461902259590092
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": 0.005991202790838646
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "SOLGOLD1 Index",
          "benchmarkIndexName": "Solactive Gold 1-Day Rolling Futures Index",
          "betaYTD": -0.9240250531547303,
          "ccy": "USD",
          "classID": "CLS00192",
          "className": "HK-GLD-I",
          "companyID": "CSOP",
          "dtdDiff": 0.0007014006616098412,
          "fundDTDDiff": -0.00870322019147085,
          "fundID": "1000136",
          "fundName": "CSOP Gold Futures Daily (-1x) Inverse Product",
          "fundNote": null,
          "fundReturn": 0.9112,
          "fundReturn1M": 0.011994669035984007,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": 0.01571731133652876,
          "fundReturn3MRolling": 0.01571731133652876,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.03372216330858957,
          "fundReturn6MRolling": -0.03372216330858957,
          "fundReturnInception": -0.042354177614293276,
          "fundReturnMTD": 0.016397099832682782,
          "fundReturnQTD": -0.0012057437246519864,
          "fundReturnRebase": 95.7645822385706,
          "fundReturnYTD": -0.03515459551037692,
          "fundType": "LE",
          "inceptionDate": "2021-10-19T08:00:00Z",
          "indexDTDDiff": -0.009404620853080692,
          "indexMTDVolatility": 0.0911579203752378,
          "indexQTDVolatility": 0.09227534195902229,
          "indexReturn": 136.31,
          "indexReturn1M": -0.017443955885532936,
          "indexReturn1Y": "-",
          "indexReturn1YRolling": "-",
          "indexReturn3M": -0.017939481268011592,
          "indexReturn3MRolling": -0.017939481268011592,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.030543585091101555,
          "indexReturn6MRolling": 0.030543585091101555,
          "indexReturnChange": 0.9631427271348514,
          "indexReturnChangePct": 0.009404620853080692,
          "indexReturnInception": 0.03374791445472458,
          "indexReturnMTD": -0.021955944607878308,
          "indexReturnQTD": -0.0011724188466328833,
          "indexReturnRebase": 103.3747914454724,
          "indexYTD": 0.027591405955522008,
          "indexYTDVolatility": 0.13251770709326843,
          "location": "Hong Kong",
          "navChange": -0.8407777193904309,
          "navChangePct": -0.00870322019147085,
          "navRebase": 95.7645822385706,
          "netAssetValue": 0.9112,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7374 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": -20
            },
            {
              "key": -15,
              "value": -15
            },
            {
              "key": -10,
              "value": -10
            },
            {
              "key": -5,
              "value": -5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": 5
            },
            {
              "key": 10,
              "value": 10
            },
            {
              "key": 15,
              "value": 15
            },
            {
              "key": 20,
              "value": 20
            }
          ],
          "td1M": -0.00026579970579774235,
          "td1Y": 0.00001990516418399,
          "tdAnnualYears": [],
          "tdCalYear": 0.000009083821942430021,
          "tdCheck": "Pass",
          "tdInception": 0.00001990516418399,
          "tdYTD": 0.000021161836186235674,
          "tdYTDChange": 0.0000044171352300234125,
          "te1M": 0.0006794986954603303,
          "te1Y": 0.0007957357415953908,
          "teAnnualYears": [],
          "teCalYear": 0.0005366285302439727,
          "teInception": 0.000795735741595391,
          "teYTD": 0.00082182785689561,
          "theoriticalDTD": -0.9404620853080692,
          "tradarCode": "HK-GLD-I",
          "volatilityMTD": 0.0817195077033408,
          "volatilityQTD": 0.07914977075012827,
          "volatilityYTD": 0.12271611520044005
        },
        {
          "_id": "I0XDitp-MR",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.08102539448155077
            },
            {
              "key": 2021,
              "value": 0.13767507276385427
            },
            {
              "key": 2020,
              "value": -0.18155366192904765
            },
            {
              "key": 2019,
              "value": -0.13126388888888885
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8904597925494148
            },
            {
              "key": 2021,
              "value": -0.15126511459495084
            },
            {
              "key": 2020,
              "value": -0.03400597734992328
            },
            {
              "key": 2019,
              "value": 0.03304225396500815
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 2810025611,
          "aumLastQuarter": 2810025611,
          "aumLastYear": 2810025611,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSI Index",
          "benchmarkIndexName": "Hong Kong Hang Seng Index",
          "betaYTD": -2.0634977177505087,
          "ccy": "HKD",
          "classID": "CLS00092",
          "className": "HK-HSI-2I",
          "companyID": "CSOP",
          "dtdDiff": 0.006644393215073374,
          "fundDTDDiff": -0.03198919070681561,
          "fundID": "1000043",
          "fundName": "CSOP Hang Seng Index Daily (-2x) Inverse Product",
          "fundNote": null,
          "fundReturn": 5.5165,
          "fundReturn1M": 0.1596594492327097,
          "fundReturn1Y": -0.07242063492063489,
          "fundReturn1YRolling": -0.07242063492063489,
          "fundReturn3M": -0.04728597827401004,
          "fundReturn3MRolling": -0.04728597827401004,
          "fundReturn3Y": -0.09678105966337025,
          "fundReturn3YRolling": -0.09678105966337025,
          "fundReturn6M": 0.03873239436619702,
          "fundReturn6MRolling": 0.03873239436619702,
          "fundReturnInception": -0.23381944444444447,
          "fundReturnMTD": 0.18805590851334175,
          "fundReturnQTD": 0.013745704467353903,
          "fundReturnRebase": 76.61805555555556,
          "fundReturnYTD": 0.030697657038227355,
          "fundType": "LE",
          "inceptionDate": "2019-05-27T16:00:00Z",
          "indexDTDDiff": -0.03863358392188898,
          "indexMTDVolatility": 0.19876355212873711,
          "indexQTDVolatility": 0.24109816073480003,
          "indexReturn": 2355.55371,
          "indexReturn1M": -0.07775734634581843,
          "indexReturn1Y": -0.8823588958219507,
          "indexReturn1YRolling": -0.8823588958219507,
          "indexReturn3M": -0.006003114795768538,
          "indexReturn3MRolling": -0.006003114795768538,
          "indexReturn3Y": -0.9073421386779829,
          "indexReturn3YRolling": -0.9073421386779829,
          "indexReturn6M": -0.06552593768108983,
          "indexReturn6MRolling": -0.06552593768108983,
          "indexReturnChange": 0.16358583543223482,
          "indexReturnChangePct": 0.01931679196094449,
          "indexReturnInception": -0.9136783223010478,
          "indexReturnMTD": -0.0851921316029407,
          "indexReturnQTD": -0.02416594313808096,
          "indexReturnRebase": 8.63216776989527,
          "indexYTD": -0.06957421957690735,
          "indexYTDVolatility": 0.2267937029245067,
          "location": "Hong Kong",
          "navChange": -2.5319444444444485,
          "navChangePct": -0.031989190706815496,
          "navRebase": 76.61805555555556,
          "netAssetValue": 5.5165,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7500 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": -40
            },
            {
              "key": -15,
              "value": -30
            },
            {
              "key": -10,
              "value": -20
            },
            {
              "key": -5,
              "value": -10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": 10
            },
            {
              "key": 10,
              "value": 20
            },
            {
              "key": 15,
              "value": 30
            },
            {
              "key": 20,
              "value": 40
            }
          ],
          "td1M": -0.00014905749716595277,
          "td1Y": -0.014702077885470328,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.022692505023282104
            },
            {
              "key": 2021,
              "value": -0.0003744108505828777
            },
            {
              "key": 2020,
              "value": -0.0003279195407398445
            }
          ],
          "tdCalYear": -0.022692505023282104,
          "tdCheck": "Fail",
          "tdInception": -0.0041901031531418505,
          "tdYTD": -0.00014852722293842317,
          "tdYTDChange": 0.00004067617028749579,
          "te1M": 0.004825138106059232,
          "te1Y": 0.16247302596960142,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.19901183793261687
            },
            {
              "key": 2021,
              "value": 0.0043731172103128125
            },
            {
              "key": 2020,
              "value": 0.008023439097522822
            }
          ],
          "teCalYear": 0.19901183793261687,
          "teInception": 0.08355716690076338,
          "teYTD": 0.005169825324963052,
          "theoriticalDTD": -7.726716784377796,
          "tradarCode": "HK-HSI-2I",
          "volatilityMTD": 0.3899761508657398,
          "volatilityQTD": 0.4870267996340069,
          "volatilityYTD": 0.47506934115428767
        },
        {
          "_id": "U0vYitp-pm",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.04939203354297694
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": 0.019861858229620832
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "SOULOGCN Index",
          "benchmarkIndexName": "Solactive US Large Oil & Gas Companies Index NTR",
          "betaYTD": -1.992445621465116,
          "ccy": "USD",
          "classID": "CLS00231",
          "className": "USD",
          "companyID": "CSOP",
          "dtdDiff": 0.00032056253455792216,
          "fundDTDDiff": -0.00553675792063979,
          "fundID": "1000162",
          "fundName": "CSOP US Large Oil & Gas Companies Daily (-2x) Inverse Product",
          "fundNote": null,
          "fundReturn": 0.9699,
          "fundReturn1M": -0.04264139769025754,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": -0.25558369790467417,
          "fundReturn3MRolling": -0.25558369790467417,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.1943013789666057,
          "fundReturn6MRolling": -0.1943013789666057,
          "fundReturnInception": -0.18666666666666665,
          "fundReturnMTD": -0.013627580595952371,
          "fundReturnQTD": -0.18680305189905266,
          "fundReturnRebase": 81.3333333333333,
          "fundReturnYTD": -0.1444071983062808,
          "fundType": "LE",
          "inceptionDate": "2022-11-23T00:00:00Z",
          "indexDTDDiff": -0.005857320455197712,
          "indexMTDVolatility": 0.15510842292751195,
          "indexQTDVolatility": 0.18205743990060916,
          "indexReturn": 1678.02,
          "indexReturn1M": 0.02292095926652915,
          "indexReturn1Y": "-",
          "indexReturn1YRolling": "-",
          "indexReturn3M": 0.15528720042410504,
          "indexReturn3MRolling": 0.15528720042410504,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.09107578269774685,
          "indexReturn6MRolling": 0.09107578269774685,
          "indexReturnChange": 0.3119330298882943,
          "indexReturnChangePct": 0.002928660227598856,
          "indexReturnInception": 0.0682242098227075,
          "indexReturnMTD": 0.007928785093884061,
          "indexReturnQTD": 0.10810864354062244,
          "indexReturnRebase": 106.8224209822709,
          "indexYTD": 0.04742049249399205,
          "indexYTDVolatility": 0.24605035914199186,
          "location": "Hong Kong",
          "navChange": -0.4528301886792434,
          "navChangePct": -0.00553675792063979,
          "navRebase": 81.3333333333333,
          "netAssetValue": 0.9699,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7505 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": -40
            },
            {
              "key": -15,
              "value": -30
            },
            {
              "key": -10,
              "value": -20
            },
            {
              "key": -5,
              "value": -10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": 10
            },
            {
              "key": 10,
              "value": 20
            },
            {
              "key": 15,
              "value": 30
            },
            {
              "key": 20,
              "value": 40
            }
          ],
          "td1M": 0.00037493603315513215,
          "td1Y": 0.0002968311935433704,
          "tdAnnualYears": [],
          "tdCalYear": 0.0002944390396465435,
          "tdCheck": "Fail",
          "tdInception": 0.0002968311935433704,
          "tdYTD": 0.00029704450662971165,
          "tdYTDChange": 1.5075658928338787e-7,
          "te1M": 0.0002886994908995349,
          "te1Y": 0.00045670080070829984,
          "teAnnualYears": [],
          "teCalYear": 0.0003633779284928506,
          "teInception": 0.00045670080070829973,
          "teYTD": 0.00046506962967164434,
          "theoriticalDTD": -1.1714640910395424,
          "tradarCode": "HK-OLG-I",
          "volatilityMTD": 0.3086441642278348,
          "volatilityQTD": 0.36276032342862236,
          "volatilityYTD": 0.49029288579126845
        },
        {
          "_id": "UjXDiap-Mk",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.321282080228795
            },
            {
              "key": 2021,
              "value": 0.4991749291285277
            },
            {
              "key": 2020,
              "value": -0.08475648323845664
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.9038012181091002
            },
            {
              "key": 2021,
              "value": -0.3501347037111763
            },
            {
              "key": 2020,
              "value": 0.04000730704525046
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 451477411,
          "aumLastQuarter": 451477411,
          "aumLastYear": 451477411,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSTECH Index",
          "benchmarkIndexName": "HSTECH Index",
          "betaYTD": -2.000930713490625,
          "ccy": "HKD",
          "classID": "CLS00183",
          "className": "HK-HST-2I",
          "companyID": "CSOP",
          "dtdDiff": -0.00008353390596504973,
          "fundDTDDiff": -0.05153717928889345,
          "fundID": "1000127",
          "fundName": "CSOP Hang Seng TECH Index Daily (-2x) Inverse Product",
          "fundNote": null,
          "fundReturn": 5.5192,
          "fundReturn1M": 0.09047082765297443,
          "fundReturn1Y": -0.39830804116518415,
          "fundReturn1YRolling": -0.39830804116518415,
          "fundReturn3M": -0.31742910498522126,
          "fundReturn3MRolling": -0.31742910498522126,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.26112159792227263,
          "fundReturn6MRolling": -0.26112159792227263,
          "fundReturnInception": -0.28756018536446837,
          "fundReturnMTD": 0.13288722853975932,
          "fundReturnQTD": -0.19487680704876664,
          "fundReturnRebase": 71.2439814635534,
          "fundReturnYTD": -0.23498509945249157,
          "fundType": "LE",
          "inceptionDate": "2020-12-09T00:00:00Z",
          "indexDTDDiff": -0.051453645382928404,
          "indexMTDVolatility": 0.3103542868388286,
          "indexQTDVolatility": 0.34768152164943217,
          "indexReturn": 539.62406,
          "indexReturn1M": -0.05792888930229223,
          "indexReturn1Y": -0.8730048315318051,
          "indexReturn1YRolling": -0.8730048315318051,
          "indexReturn3M": 0.15820782670618017,
          "indexReturn3MRolling": 0.15820782670618017,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.0791353935167931,
          "indexReturn6MRolling": 0.0791353935167931,
          "indexReturnChange": 0.1670574454717917,
          "indexReturnChangePct": 0.025726822691464202,
          "indexReturnInception": -0.9333943002578449,
          "indexReturnMTD": -0.07516906932890932,
          "indexReturnQTD": 0.08116307604028261,
          "indexReturnRebase": 6.660569974215527,
          "indexYTD": 0.02442952346388605,
          "indexYTDVolatility": 0.3468752213438563,
          "location": "Hong Kong",
          "navChange": -3.8712259097187314,
          "navChangePct": -0.05153717928889334,
          "navRebase": 71.2439814635534,
          "netAssetValue": 5.5192,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7552 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": -40
            },
            {
              "key": -15,
              "value": -30
            },
            {
              "key": -10,
              "value": -20
            },
            {
              "key": -5,
              "value": -10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": 10
            },
            {
              "key": 10,
              "value": 20
            },
            {
              "key": 15,
              "value": 30
            },
            {
              "key": 20,
              "value": 40
            }
          ],
          "td1M": -0.000432124369479178,
          "td1Y": -0.014955356643811764,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.023202738688168266
            },
            {
              "key": 2021,
              "value": -0.00032479446255218654
            }
          ],
          "tdCalYear": -0.023202738688168266,
          "tdCheck": "Fail",
          "tdInception": -0.006837576427246749,
          "tdYTD": 0.00005931890932461874,
          "tdYTDChange": -8.40310678174517e-7,
          "te1M": 0.0010074890863685597,
          "te1Y": 0.16970658147209539,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.20908274728266227
            },
            {
              "key": 2021,
              "value": 0.0027528693047195294
            }
          ],
          "teCalYear": 0.20908274728266227,
          "teInception": 0.1130569654582647,
          "teYTD": 0.0015678389397572967,
          "theoriticalDTD": -10.29072907658568,
          "tradarCode": "HK-HST-2I",
          "volatilityMTD": 0.6176247107636447,
          "volatilityQTD": 0.6949151236814383,
          "volatilityYTD": 0.6945137079151147
        },
        {
          "_id": "IjXDiaMzMo",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 15.846704871060172
            },
            {
              "key": 2021,
              "value": -0.4540477121626906
            },
            {
              "key": 2020,
              "value": -0.6856405212687484
            },
            {
              "key": 2019,
              "value": -0.91866
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.33343579380489496
            },
            {
              "key": 2021,
              "value": 0.27473177181128894
            },
            {
              "key": 2020,
              "value": 0.4758012443042672
            },
            {
              "key": 2019,
              "value": 0.10705596816451579
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 250200667,
          "aumLastQuarter": 250200667,
          "aumLastYear": 250200667,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "NDX Index",
          "benchmarkIndexName": "NASDAQ 100 Index",
          "betaYTD": -1.966247205716874,
          "ccy": "USD",
          "classID": "CLS00162",
          "className": "HK-NDX-2I",
          "companyID": "CSOP",
          "dtdDiff": 0.0002496899086251725,
          "fundDTDDiff": -0.042811710022017446,
          "fundID": "1000113",
          "fundName": "CSOP NASDAQ-100 Index Daily (-2x) Inverse Product",
          "fundNote": null,
          "fundReturn": 1.1738,
          "fundReturn1M": 0.04822289694588333,
          "fundReturn1Y": -0.39983638408835265,
          "fundReturn1YRolling": -0.39983638408835265,
          "fundReturn3M": -0.17529684535937617,
          "fundReturn3MRolling": -0.17529684535937617,
          "fundReturn3Y": 2.709860935524652,
          "fundReturn3YRolling": 2.709860935524652,
          "fundReturn6M": -0.3852197140313204,
          "fundReturn6MRolling": -0.3852197140313204,
          "fundReturnInception": -0.88262,
          "fundReturnMTD": 0.049535050071530584,
          "fundReturnQTD": -0.02281052281052287,
          "fundReturnRebase": 117.3799999999999,
          "fundReturnYTD": -0.5008929330725402,
          "fundType": "LE",
          "inceptionDate": "2019-09-18T16:00:00Z",
          "indexDTDDiff": -0.04306139993064262,
          "indexMTDVolatility": 0.17892985775061762,
          "indexQTDVolatility": 0.16959565458395057,
          "indexReturn": 15376.55,
          "indexReturn1M": -0.023768755241754036,
          "indexReturn1Y": 0.23166900560062542,
          "indexReturn1YRolling": 0.23166900560062542,
          "indexReturn3M": 0.10316869856433919,
          "indexReturn3MRolling": 0.10316869856433919,
          "indexReturn3Y": 0.2818224636019957,
          "indexReturn3YRolling": 0.2818224636019957,
          "indexReturn6M": 0.2768972572935662,
          "indexReturn6MRolling": 0.2768972572935662,
          "indexReturnChange": 4.108356459661309,
          "indexReturnChangePct": 0.02153069996532131,
          "indexReturnInception": 0.9492223925392682,
          "indexReturnMTD": -0.024144824522434494,
          "indexReturnQTD": 0.013000676583300397,
          "indexReturnRebase": 194.9222392539268,
          "indexYTD": 0.4041165002442688,
          "indexYTDVolatility": 0.2093940363152594,
          "location": "Hong Kong",
          "navChange": -5.25,
          "navChangePct": -0.042811710022017446,
          "navRebase": 117.3799999999999,
          "netAssetValue": 1.1738,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7568 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": -40
            },
            {
              "key": -15,
              "value": -30
            },
            {
              "key": -10,
              "value": -20
            },
            {
              "key": -5,
              "value": -10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": 10
            },
            {
              "key": 10,
              "value": 20
            },
            {
              "key": 15,
              "value": 30
            },
            {
              "key": 20,
              "value": 40
            }
          ],
          "td1M": 0.0003840401930487332,
          "td1Y": 0.0002672542687875948,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.069602456499886
            },
            {
              "key": 2021,
              "value": -0.00009198657296481944
            },
            {
              "key": 2020,
              "value": -0.00003378078294457764
            }
          ],
          "tdCalYear": 0.069602456499886,
          "tdCheck": "Fail",
          "tdInception": 0.011730946845013359,
          "tdYTD": 0.0004401798453596819,
          "tdYTDChange": -0.0000011686499186166046,
          "te1M": 0.0007091787958351105,
          "te1Y": 0.0020734001662320554,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.8188845679429441
            },
            {
              "key": 2021,
              "value": 0.0008441325233685831
            },
            {
              "key": 2020,
              "value": 0.006931758390809333
            }
          ],
          "teCalYear": 0.8188845679429441,
          "teInception": 0.3353400768076752,
          "teYTD": 0.001001930192427008,
          "theoriticalDTD": -8.612279986128524,
          "tradarCode": "HK-NDX-2I",
          "volatilityMTD": 0.355550022597343,
          "volatilityQTD": 0.3379505952898773,
          "volatilityYTD": 0.4119687727226926
        },
        {
          "_id": "UjvYitMzpM",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.12703195888369911
            },
            {
              "key": 2021,
              "value": 0.3798336798336799
            },
            {
              "key": 2020,
              "value": -0.16860319960053016
            },
            {
              "key": 2019,
              "value": -0.18422949175910253
            },
            {
              "key": 2018,
              "value": 0.07396688652577721
            },
            {
              "key": 2017,
              "value": -0.17455555555555557
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8939446695090681
            },
            {
              "key": 2021,
              "value": -0.2460292036057513
            },
            {
              "key": 2020,
              "value": -0.03847221451174154
            },
            {
              "key": 2019,
              "value": 0.10304550729647644
            },
            {
              "key": 2018,
              "value": -0.13532405865423203
            },
            {
              "key": 2017,
              "value": 0.1414008194012697
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 35291107,
          "aumLastQuarter": 35291107,
          "aumLastYear": 35291107,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSCEI Index",
          "benchmarkIndexName": "Hong Kong Stock Exchange Hang Seng China Enterprises Index",
          "betaYTD": -2.0380224020797333,
          "ccy": "HKD",
          "classID": "CLS00071",
          "className": "HK-HCE-2I",
          "companyID": "CSOP",
          "dtdDiff": 0.009029262942094718,
          "fundDTDDiff": -0.03564478526425319,
          "fundID": "1000024",
          "fundName": "CSOP Hang Seng China Enterprises Index Daily (-2x) Inverse Product",
          "fundNote": null,
          "fundReturn": 5.0051,
          "fundReturn1M": 0.13232432921587245,
          "fundReturn1Y": -0.1446320538674506,
          "fundReturn1YRolling": -0.1446320538674506,
          "fundReturn3M": -0.11751533958671279,
          "fundReturn3MRolling": -0.11751533958671279,
          "fundReturn3Y": 0.005120893244437319,
          "fundReturn3YRolling": 0.005120893244437319,
          "fundReturn6M": -0.058501533078760715,
          "fundReturn6MRolling": -0.058501533078760715,
          "fundReturnInception": -0.30484722222222227,
          "fundReturnMTD": 0.17001729861143566,
          "fundReturnQTD": -0.03969685341519569,
          "fundReturnRebase": 69.51527777777774,
          "fundReturnYTD": -0.04015725381148727,
          "fundType": "LE",
          "inceptionDate": "2017-03-13T16:00:00Z",
          "indexDTDDiff": -0.044674048206347905,
          "indexMTDVolatility": 0.2323937389678246,
          "indexQTDVolatility": 0.28267346268271065,
          "indexReturn": 813.91615,
          "indexReturn1M": -0.06782669452006473,
          "indexReturn1Y": -0.8815649946305874,
          "indexReturn1YRolling": -0.8815649946305874,
          "indexReturn3M": 0.019270134913172754,
          "indexReturn3MRolling": 0.019270134913172754,
          "indexReturn3Y": -0.9200697497650456,
          "indexReturn3YRolling": -0.9200697497650456,
          "indexReturn6M": -0.029294057466631385,
          "indexReturn6MRolling": -0.029294057466631385,
          "indexReturnChange": 0.17334772110723495,
          "indexReturnChangePct": 0.022337024103173952,
          "indexReturnInception": -0.9206609651700848,
          "indexReturnMTD": -0.08007659614704754,
          "indexReturnQTD": -0.007257112659177056,
          "indexReturnRebase": 7.933903482991518,
          "indexYTD": -0.05212064900974478,
          "indexYTDVolatility": 0.2624531228197459,
          "location": "Hong Kong",
          "navChange": -2.569444444444457,
          "navChangePct": -0.0356447852642533,
          "navRebase": 69.51527777777774,
          "netAssetValue": 5.0051,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "7588 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": -40
            },
            {
              "key": -15,
              "value": -30
            },
            {
              "key": -10,
              "value": -20
            },
            {
              "key": -5,
              "value": -10
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": 10
            },
            {
              "key": 10,
              "value": 20
            },
            {
              "key": 15,
              "value": 30
            },
            {
              "key": 20,
              "value": 40
            }
          ],
          "td1M": -0.00011458834456904635,
          "td1Y": -0.014772019372752111,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.022811288786805682
            },
            {
              "key": 2021,
              "value": -0.00044462037310416845
            },
            {
              "key": 2020,
              "value": -0.0003026848698733162
            },
            {
              "key": 2019,
              "value": 0.00001016044795475512
            },
            {
              "key": 2018,
              "value": -0.00018771817533592036
            }
          ],
          "tdCalYear": -0.022811288786805682,
          "tdCheck": "Fail",
          "tdInception": -0.0027181312781708387,
          "tdYTD": -0.00018692321879868094,
          "tdYTDChange": 0.00005485825095769879,
          "te1M": 0.004431175096494877,
          "te1Y": 0.16347652660822995,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.20065769939114406
            },
            {
              "key": 2021,
              "value": 0.004923871245298001
            },
            {
              "key": 2020,
              "value": 0.0078022098547299665
            },
            {
              "key": 2019,
              "value": 0.004274587890699524
            },
            {
              "key": 2018,
              "value": 0.005131953591103839
            }
          ],
          "teCalYear": 0.20065769939114406,
          "teInception": 0.06732873199329634,
          "teYTD": 0.005191149631570957,
          "theoriticalDTD": -8.934809641269581,
          "tradarCode": "HK-HCE-2I",
          "volatilityMTD": 0.45999934239985857,
          "volatilityQTD": 0.5720532137535419,
          "volatilityYTD": 0.5413055678379765
        },
        {
          "_id": "IjvYWtMzpT",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.18592005712167203
            },
            {
              "key": 2021,
              "value": -0.11000313585204946
            },
            {
              "key": 2020,
              "value": 0.2448065088551239
            },
            {
              "key": 2019,
              "value": 0.3974231641999255
            },
            {
              "key": 2018,
              "value": -0.20405627877680343
            },
            {
              "key": 2017,
              "value": 0.3330557549781006
            },
            {
              "key": 2016,
              "value": -0.04819367921764395
            },
            {
              "key": 2015,
              "value": -0.04029360094598755
            },
            {
              "key": 2014,
              "value": 0.6568999197237118
            },
            {
              "key": 2013,
              "value": -0.12426021990082836
            },
            {
              "key": 2012,
              "value": 0.15928448553167085
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8790372833733321
            },
            {
              "key": 2021,
              "value": -0.09736867232688828
            },
            {
              "key": 2020,
              "value": 0.26155966052097046
            },
            {
              "key": 2019,
              "value": 0.421195294759529
            },
            {
              "key": 2018,
              "value": -0.19362521317471648
            },
            {
              "key": 2017,
              "value": 0.3537966557521519
            },
            {
              "key": 2016,
              "value": -0.042581899037919535
            },
            {
              "key": 2015,
              "value": -0.05044444597926612
            },
            {
              "key": 2014,
              "value": 0.6808296242637004
            },
            {
              "key": 2013,
              "value": -0.12016456786126117
            },
            {
              "key": 2012,
              "value": 0.1832394483648787
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 10041711348,
          "aumLastQuarter": 10041711348,
          "aumLastYear": 10041711348,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "XINA50NC Index",
          "benchmarkIndexName": "FTSE China A50 Net Total Return Index",
          "betaYTD": 0.7842703924838627,
          "ccy": "CNY",
          "classID": "CLS00069",
          "className": "CO-A50F",
          "companyID": "CSOP",
          "dtdDiff": -0.0008567961186443096,
          "fundDTDDiff": 0.005166489925768847,
          "fundID": "1000023",
          "fundName": "CSOP FTSE China A50 ETF",
          "fundNote": null,
          "fundReturn": 14.680697881686564,
          "fundReturn1M": -0.04225944128297976,
          "fundReturn1Y": -0.06374505140220144,
          "fundReturn1YRolling": -0.06374505140220144,
          "fundReturn3M": 0.030304611344446286,
          "fundReturn3MRolling": 0.030304611344446286,
          "fundReturn3Y": -0.19222330420567413,
          "fundReturn3YRolling": -0.19222330420567413,
          "fundReturn6M": -0.033312392405786206,
          "fundReturn6MRolling": -0.033312392405786206,
          "fundReturnInception": 1.0532157426730486,
          "fundReturnMTD": -0.0447303921568627,
          "fundReturnQTD": 0.029141224191993276,
          "fundReturnRebase": 205.3215742673054,
          "fundReturnYTD": -0.0007590196839104557,
          "fundType": "EE",
          "inceptionDate": "2012-08-27T08:00:00Z",
          "indexDTDDiff": 0.006023286044413156,
          "indexMTDVolatility": 0.15485917623006948,
          "indexQTDVolatility": 0.19563774917085064,
          "indexReturn": 2713.98263,
          "indexReturn1M": -0.05951240195042162,
          "indexReturn1Y": -0.8669179927142774,
          "indexReturn1YRolling": -0.8669179927142774,
          "indexReturn3M": 0.004968612914891368,
          "indexReturn3MRolling": 0.004968612914891368,
          "indexReturn3Y": -0.8818832547334043,
          "indexReturn3YRolling": -0.8818832547334043,
          "indexReturn6M": -0.07251765347843031,
          "indexReturn6MRolling": -0.07251765347843031,
          "indexReturnChange": 0.19638157139387147,
          "indexReturnChangePct": 0.006023286044413156,
          "indexReturnInception": -0.6719989183388101,
          "indexReturnMTD": -0.06261219345767577,
          "indexReturnQTD": 0.028189386605108613,
          "indexReturnRebase": 32.800108166118946,
          "indexYTD": -0.03519040047408928,
          "indexYTDVolatility": 0.18277209888349155,
          "location": "Hong Kong",
          "navChange": 1.0553394443873287,
          "navChangePct": 0.005166489925768847,
          "navRebase": 165.70957049551757,
          "netAssetValue": 11.8484,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "82822 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.01788180130081307,
          "td1Y": 0.803172941312076,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.6931172262516601
            },
            {
              "key": 2021,
              "value": -0.012634463525161177
            },
            {
              "key": 2020,
              "value": -0.016753151665846566
            },
            {
              "key": 2019,
              "value": -0.023772130559603477
            },
            {
              "key": 2018,
              "value": -0.010431065602086953
            },
            {
              "key": 2017,
              "value": -0.02074090077405133
            },
            {
              "key": 2016,
              "value": -0.005611780179724413
            },
            {
              "key": 2015,
              "value": 0.010150845033278566
            },
            {
              "key": 2014,
              "value": -0.02392970453998866
            },
            {
              "key": 2013,
              "value": -0.004095652039567188
            }
          ],
          "tdCalYear": 0.6931172262516601,
          "tdCheck": "Pass",
          "tdInception": 1.7252146610118588,
          "tdYTD": 0.03443138079017882,
          "tdYTDChange": -0.0006404973063288688,
          "te1M": 0.05103092035673591,
          "te1Y": 1.2343341151028606,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 1.2322777743019881
            },
            {
              "key": 2021,
              "value": 0.0011394684100140997
            },
            {
              "key": 2020,
              "value": 0.0017145355643395138
            },
            {
              "key": 2019,
              "value": 0.0023337266385435288
            },
            {
              "key": 2018,
              "value": 0.01918881545776182
            },
            {
              "key": 2017,
              "value": 0.004710604147792125
            },
            {
              "key": 2016,
              "value": 0.026948308035637154
            },
            {
              "key": 2015,
              "value": 0.01087277008922613
            },
            {
              "key": 2014,
              "value": 0.006302220060996948
            },
            {
              "key": 2013,
              "value": 0.01873632799767789
            }
          ],
          "teCalYear": 1.2322777743019881,
          "teInception": 0.3909653688972005,
          "teYTD": 0.059727642044055826,
          "theoriticalDTD": -0.6023286044413156,
          "tradarCode": "CO-A50F",
          "volatilityMTD": 0.1351575492468469,
          "volatilityQTD": 0.16806452693056764,
          "volatilityYTD": 0.15008526380811862
        },
        {
          "_id": "IjXDiaM-p0",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.2095354388633941
            },
            {
              "key": 2021,
              "value": 0.14034046487208318
            },
            {
              "key": 2020,
              "value": 0.2429846214958573
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8831358247460035
            },
            {
              "key": 2021,
              "value": 0.16302759922686194
            },
            {
              "key": 2020,
              "value": 0.2564556466059651
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 43097938,
          "aumLastQuarter": 43097938,
          "aumLastYear": 43097938,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "CSIN0905 Index",
          "benchmarkIndexName": "CSI 500 Index",
          "betaYTD": 0.7856342331689287,
          "ccy": "CNY",
          "classID": "CLS00171",
          "className": "HK-CSI5",
          "companyID": "CSOP",
          "dtdDiff": -0.001270249478096508,
          "fundDTDDiff": 0.02226435786817116,
          "fundID": "1000118",
          "fundName": "CSOP CSI 500 ETF",
          "fundNote": null,
          "fundReturn": 15.4733,
          "fundReturn1M": -0.045676857511147806,
          "fundReturn1Y": -0.10169521044992746,
          "fundReturn1YRolling": -0.10169521044992746,
          "fundReturn3M": -0.03795744786679767,
          "fundReturn3MRolling": -0.03795744786679767,
          "fundReturn3Y": -0.1639172416774285,
          "fundReturn3YRolling": -0.1639172416774285,
          "fundReturn6M": -0.09238457793445687,
          "fundReturn6MRolling": -0.09238457793445687,
          "fundReturnInception": 0.09758398592668249,
          "fundReturnMTD": -0.051026353394295065,
          "fundReturnQTD": -0.03771214637088993,
          "fundReturnRebase": 109.75839859266857,
          "fundReturnYTD": -0.02038581096908565,
          "fundType": "EE",
          "inceptionDate": "2020-03-18T08:00:00Z",
          "indexDTDDiff": 0.023534607346267666,
          "indexMTDVolatility": 0.1750221269734523,
          "indexQTDVolatility": 0.1653375555033398,
          "indexReturn": 924.6762,
          "indexReturn1M": -0.06192897121140417,
          "indexReturn1Y": -0.8725656757762434,
          "indexReturn1YRolling": -0.8725656757762434,
          "indexReturn3M": -0.06005077177947493,
          "indexReturn3MRolling": -0.06005077177947493,
          "indexReturn3Y": -0.8763758900710452,
          "indexReturn3YRolling": -0.8763758900710452,
          "indexReturn6M": -0.12317465787214232,
          "indexReturn6MRolling": -0.12317465787214232,
          "indexReturnChange": 0.3752492512314749,
          "indexReturnChangePct": 0.023534607346267666,
          "indexReturnInception": -0.8368017832780619,
          "indexReturnMTD": -0.06956745576125645,
          "indexReturnQTD": -0.038196411775949235,
          "indexReturnRebase": 16.319821672193786,
          "indexYTD": -0.044354482404952544,
          "indexYTDVolatility": 0.15468240391364171,
          "location": "Hong Kong",
          "navChange": 2.3904778118261305,
          "navChangePct": 0.02226435786817116,
          "navRebase": 109.75839859266857,
          "netAssetValue": 15.4733,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "83005 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.01854110236696138,
          "td1Y": 0.770870465326316,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.6736003858826094
            },
            {
              "key": 2021,
              "value": -0.02268713435477876
            }
          ],
          "tdCalYear": 0.6736003858826094,
          "tdCheck": "Pass",
          "tdInception": 0.9343857692047444,
          "tdYTD": 0.023968671435866895,
          "tdYTDChange": -0.0006381413771288091,
          "te1M": 0.039109237632089865,
          "te1Y": 0.8650706576944174,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.8641440017725457
            },
            {
              "key": 2021,
              "value": 0.0025097275340690813
            }
          ],
          "teCalYear": 0.8641440017725457,
          "teInception": 0.5105349327806501,
          "teYTD": 0.054964536846927495,
          "theoriticalDTD": -2.3534607346267666,
          "tradarCode": "HK-CSI5",
          "volatilityMTD": 0.1603233425975378,
          "volatilityQTD": 0.144461404870436,
          "volatilityYTD": 0.12906315157386264
        },
        {
          "_id": "UjvDWap-p4",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.029701887675948724
            },
            {
              "key": 2021,
              "value": 0.008071230162332466
            },
            {
              "key": 2020,
              "value": 0.015025960273443273
            },
            {
              "key": 2019,
              "value": 0.008281695238353093
            },
            {
              "key": 2018,
              "value": 0.024127109172356054
            },
            {
              "key": 2017,
              "value": 0.016077039081312083
            },
            {
              "key": 2016,
              "value": 0.0008165029901874288
            },
            {
              "key": 2015,
              "value": 0.023723917472471934
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": 4.066746495824651
            },
            {
              "key": 2021,
              "value": 0.009293739487284736
            },
            {
              "key": 2020,
              "value": 0.02196194615382696
            },
            {
              "key": 2019,
              "value": 0.02782492441361306
            },
            {
              "key": 2018,
              "value": 0.04070384830886087
            },
            {
              "key": 2017,
              "value": 0.03455255939943158
            },
            {
              "key": 2016,
              "value": 0.022088702768329194
            },
            {
              "key": 2015,
              "value": 0.03206304097585777
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 172065513,
          "aumLastQuarter": 172065513,
          "aumLastYear": 172065513,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "HSCIUT Index",
          "benchmarkIndexName": "HSCIUT Index",
          "betaYTD": 0.00040256473800153895,
          "ccy": "CNY",
          "classID": "CLS00040",
          "className": "CO-USTB",
          "companyID": "CSOP",
          "dtdDiff": -0.018403768924840858,
          "fundDTDDiff": 0.00005867239803336588,
          "fundID": "1000008",
          "fundName": "CSOP RMB Money Market ETF",
          "fundNote": null,
          "fundReturn": 171.83740597610603,
          "fundReturn1M": 0.0015246259563701514,
          "fundReturn1Y": 0.04151176163363579,
          "fundReturn1YRolling": 0.04151176163363579,
          "fundReturn3M": 0.004433325806955768,
          "fundReturn3MRolling": 0.004433325806955768,
          "fundReturn3Y": 0.056187639136841216,
          "fundReturn3YRolling": 0.056187639136841216,
          "fundReturn6M": 0.008697477656726793,
          "fundReturn6MRolling": 0.008697477656726793,
          "fundReturnInception": 0.1455979811471222,
          "fundReturnMTD": 0.0013821721438200818,
          "fundReturnQTD": 0.0028607066855932395,
          "fundReturnRebase": 114.55979811471182,
          "fundReturnYTD": 0.011469216575238361,
          "fundType": "BE",
          "inceptionDate": "2015-01-20T16:00:00Z",
          "indexDTDDiff": 0.018462441322874223,
          "indexMTDVolatility": 0.15437884094644203,
          "indexQTDVolatility": 0.15543441803787073,
          "indexReturn": 564.5649240048173,
          "indexReturn1M": -0.08871092737521691,
          "indexReturn1Y": 3.550910643243385,
          "indexReturn1YRolling": 3.550910643243385,
          "indexReturn3M": -0.09784583431881055,
          "indexReturn3MRolling": -0.09784583431881055,
          "indexReturn3Y": 3.6305516335989285,
          "indexReturn3YRolling": 3.6305516335989285,
          "indexReturn6M": -0.09221655662187789,
          "indexReturn6MRolling": -0.09221655662187789,
          "indexReturnChange": 9.933182195377526,
          "indexReturnChangePct": 0.018462441322874223,
          "indexReturnInception": 4.479542391977759,
          "indexReturnMTD": -0.09315677022685698,
          "indexReturnQTD": -0.10276522845970604,
          "indexReturnRebase": 547.9542391977734,
          "indexYTD": -0.1018081036827777,
          "indexYTDVolatility": 0.15522458301920836,
          "location": "Hong Kong",
          "navChange": 0.006721103730328082,
          "navChangePct": 0.00005867239803336588,
          "navRebase": 107.95177269030232,
          "netAssetValue": 161.9255,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "83122 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.09453894237067706,
          "td1Y": -3.5093988816097488,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -4.037044608148702
            },
            {
              "key": 2021,
              "value": -0.00122250932495227
            },
            {
              "key": 2020,
              "value": -0.006935985880383688
            },
            {
              "key": 2019,
              "value": -0.01954322917525997
            },
            {
              "key": 2018,
              "value": -0.016576739136504814
            },
            {
              "key": 2017,
              "value": -0.018475520318119498
            },
            {
              "key": 2016,
              "value": -0.021272199778141765
            }
          ],
          "tdCalYear": -4.037044608148702,
          "tdCheck": "Pass",
          "tdInception": -4.333944410830637,
          "tdYTD": 0.11327732025801607,
          "tdYTDChange": -0.016222864068450082,
          "te1M": 0.1585009496684902,
          "te1Y": 7.794010834002959,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 7.645920470904032
            },
            {
              "key": 2021,
              "value": 0.00017778473541639738
            },
            {
              "key": 2020,
              "value": 0.0006051705293882219
            },
            {
              "key": 2019,
              "value": 0.003114276788319838
            },
            {
              "key": 2018,
              "value": 0.003855191249868295
            },
            {
              "key": 2017,
              "value": 0.004780057793071029
            },
            {
              "key": 2016,
              "value": 0.003593348510129073
            }
          ],
          "teCalYear": 7.645920470904032,
          "teInception": 3.003962727642176,
          "teYTD": 0.1556336972956289,
          "theoriticalDTD": -1.8462441322874223,
          "tradarCode": "CO-USTB",
          "volatilityMTD": 0.0006983081776344741,
          "volatilityQTD": 0.0006941710188021196,
          "volatilityYTD": 0.0007303480604156176
        },
        {
          "_id": "UjXDWap-pa",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.2968041210692226
            },
            {
              "key": 2021,
              "value": 0.11414947079875137
            },
            {
              "key": 2020,
              "value": 0.6370974024962213
            },
            {
              "key": 2019,
              "value": 0.42745612669320954
            },
            {
              "key": 2018,
              "value": -0.2908898772909373
            },
            {
              "key": 2017,
              "value": -0.13176489921121814
            },
            {
              "key": 2016,
              "value": -0.2820865735324479
            },
            {
              "key": 2015,
              "value": -0.15138242311992134
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8984751986276635
            },
            {
              "key": 2021,
              "value": 0.12019849912010416
            },
            {
              "key": 2020,
              "value": 0.6496451849709699
            },
            {
              "key": 2019,
              "value": 0.43788633619345396
            },
            {
              "key": 2018,
              "value": -0.28649188371893997
            },
            {
              "key": 2017,
              "value": -0.10672966168210951
            },
            {
              "key": 2016,
              "value": -0.27707300897183185
            },
            {
              "key": 2015,
              "value": -0.13712031131769542
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 593024896,
          "aumLastQuarter": 593024896,
          "aumLastYear": 593024896,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "SZ399006 Index",
          "benchmarkIndexName": "CHINEXT Price Index",
          "betaYTD": 0.8526974315045108,
          "ccy": "CNY",
          "classID": "CLS00044",
          "className": "CO-CNXT",
          "companyID": "CSOP",
          "dtdDiff": -0.0012824999239857693,
          "fundDTDDiff": 0.028131362799008297,
          "fundID": "1000010",
          "fundName": "CSOP SZSE ChiNext ETF",
          "fundNote": null,
          "fundReturn": 7.4228,
          "fundReturn1M": -0.04694160546453707,
          "fundReturn1Y": -0.195369155889909,
          "fundReturn1YRolling": -0.195369155889909,
          "fundReturn3M": -0.03838530398621609,
          "fundReturn3MRolling": -0.03838530398621609,
          "fundReturn3Y": -0.24332810047095754,
          "fundReturn3YRolling": -0.24332810047095754,
          "fundReturn6M": -0.12656500047067687,
          "fundReturn6MRolling": -0.12656500047067687,
          "fundReturnInception": -0.38072116267040435,
          "fundReturnMTD": -0.05398654159869498,
          "fundReturnQTD": -0.044869072894550555,
          "fundReturnRebase": 61.92788373295944,
          "fundReturnYTD": -0.09823359331340975,
          "fundType": "EE",
          "inceptionDate": "2015-05-15T08:00:00Z",
          "indexDTDDiff": 0.029413862722994066,
          "indexMTDVolatility": 0.20883401130345636,
          "indexQTDVolatility": 0.20592200764941807,
          "indexReturn": 290.93274,
          "indexReturn1M": -0.06311954070522574,
          "indexReturn1Y": -0.8894006333372616,
          "indexReturn1YRolling": -0.8894006333372616,
          "indexReturn3M": -0.06650626969914564,
          "indexReturn3MRolling": -0.06650626969914564,
          "indexReturn3Y": -0.8945070272387086,
          "indexReturn3YRolling": -0.8945070272387086,
          "indexReturn6M": -0.16931183045795006,
          "indexReturn6MRolling": -0.16931183045795006,
          "indexReturnChange": 0.26429384422669777,
          "indexReturnChangePct": 0.029413862722994066,
          "indexReturnInception": -0.9075035640026198,
          "indexReturnMTD": -0.07092128529530983,
          "indexReturnQTD": -0.04723839444103062,
          "indexReturnRebase": 9.249643599738,
          "indexYTD": -0.13758510868094975,
          "indexYTDVolatility": 0.19650677308280282,
          "location": "Hong Kong",
          "navChange": 1.6944486159082928,
          "navChangePct": 0.028131362799008297,
          "navRebase": 61.92788373295944,
          "netAssetValue": 7.4228,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "83147 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.01693474369661485,
          "td1Y": 0.6940314774473526,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.6016710775584408
            },
            {
              "key": 2021,
              "value": -0.0060490283213527896
            },
            {
              "key": 2020,
              "value": -0.012547782474748637
            },
            {
              "key": 2019,
              "value": -0.010430209500244425
            },
            {
              "key": 2018,
              "value": -0.004397993571997327
            },
            {
              "key": 2017,
              "value": -0.025035237529108634
            },
            {
              "key": 2016,
              "value": -0.0050135645606160795
            }
          ],
          "tdCalYear": 0.6016710775584408,
          "tdCheck": "Pass",
          "tdInception": 0.5267824013322154,
          "tdYTD": 0.03935151536754,
          "tdYTDChange": 0.00003167713552487683,
          "te1M": 0.03710750237263738,
          "te1Y": 0.8547436285089274,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.8537405518133447
            },
            {
              "key": 2021,
              "value": 0.001787948230530536
            },
            {
              "key": 2020,
              "value": 0.0029699106206290318
            },
            {
              "key": 2019,
              "value": 0.003936972418247289
            },
            {
              "key": 2018,
              "value": 0.02712617050050665
            },
            {
              "key": 2017,
              "value": 0.009541776481802622
            },
            {
              "key": 2016,
              "value": 0.006041482186292913
            }
          ],
          "teCalYear": 0.8537405518133447,
          "teInception": 0.3129695104728053,
          "teYTD": 0.05417493023144653,
          "theoriticalDTD": -2.9413862722994066,
          "tradarCode": "CO-CNXT",
          "volatilityMTD": 0.19195448428704923,
          "volatilityQTD": 0.18534498378762618,
          "volatilityYTD": 0.17363800237876592
        },
        {
          "_id": "U0vYitpzMr",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.011212702636680172
            },
            {
              "key": 2021,
              "value": 0.042475357529560887
            },
            {
              "key": 2020,
              "value": 0.02206145254828029
            },
            {
              "key": 2019,
              "value": 0.026447124586975068
            },
            {
              "key": 2018,
              "value": 0.06444074696557145
            },
            {
              "key": 2017,
              "value": -0.02200603254596234
            },
            {
              "key": 2016,
              "value": 0.0050252998082525036
            },
            {
              "key": 2015,
              "value": 0.06870195519496392
            },
            {
              "key": 2014,
              "value": 0.07429449792279197
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.811664744079673
            },
            {
              "key": 2021,
              "value": 0.05696922472724064
            },
            {
              "key": 2020,
              "value": 0.03027663532519509
            },
            {
              "key": 2019,
              "value": 0.04242509942427075
            },
            {
              "key": 2018,
              "value": 0.07723754926063031
            },
            {
              "key": 2017,
              "value": -0.010411167219464557
            },
            {
              "key": 2016,
              "value": 0.023134829931783063
            },
            {
              "key": 2015,
              "value": 0.07774201241632461
            },
            {
              "key": 2014,
              "value": 0.07845436435350495
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 5296322846,
          "aumLastQuarter": 5296322846,
          "aumLastYear": 5296322846,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "CFIICNGL Index",
          "benchmarkIndexName": "FTSE Chinese Government and Policy Bank Bond Index",
          "betaYTD": 0.02296799396235303,
          "ccy": "CNY",
          "classID": "CLS00002",
          "className": "CO-B5",
          "companyID": "CSOP",
          "dtdDiff": -0.0011391916964524906,
          "fundDTDDiff": -0.00015791673166298814,
          "fundID": "1000002",
          "fundName": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
          "fundNote": null,
          "fundReturn": 136.8441857196008,
          "fundReturn1M": 0.005019493366061001,
          "fundReturn1Y": -0.0033125073789916915,
          "fundReturn1YRolling": -0.0033125073789916915,
          "fundReturn3M": 0.005001011429638824,
          "fundReturn3MRolling": 0.005001011429638824,
          "fundReturn3Y": 0.07379283415059334,
          "fundReturn3YRolling": 0.07379283415059334,
          "fundReturn6M": 0.012774308200545814,
          "fundReturn6MRolling": 0.012774308200545814,
          "fundReturnInception": 0.33543654303490045,
          "fundReturnMTD": 0.005488578372986996,
          "fundReturnQTD": -0.0007583150009717876,
          "fundReturnRebase": 133.54365430349014,
          "fundReturnYTD": 0.005279285123026778,
          "fundType": "BE",
          "inceptionDate": "2014-02-18T16:00:00Z",
          "indexDTDDiff": 0.0009812749647895025,
          "indexMTDVolatility": 0.0346325645373246,
          "indexQTDVolatility": 0.045643217362219664,
          "indexReturn": 23.41089,
          "indexReturn1M": -0.01316849391462993,
          "indexReturn1Y": -0.8881562924145522,
          "indexReturn1YRolling": -0.8881562924145522,
          "indexReturn3M": -0.014989182543629687,
          "indexReturn3MRolling": -0.014989182543629687,
          "indexReturn3Y": -0.8001696842209868,
          "indexReturn3YRolling": -0.8001696842209868,
          "indexReturn6M": -0.017129261691036013,
          "indexReturn6MRolling": -0.017129261691036013,
          "indexReturnChange": 0.026263685037726248,
          "indexReturnChangePct": 0.0009812749647895025,
          "indexReturnInception": -0.7320887836980732,
          "indexReturnMTD": -0.013365627066385333,
          "indexReturnQTD": 0.004458752038553282,
          "indexReturnRebase": 26.791121630192723,
          "indexYTD": -0.011486754383853715,
          "indexYTDVolatility": 0.05316475964654482,
          "location": "Hong Kong",
          "navChange": -0.021092108218738304,
          "navChangePct": -0.00015791673166298814,
          "navRebase": 101.33139458288377,
          "netAssetValue": 103.8358,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "83199 HK EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.01885420543937233,
          "td1Y": 0.8848437850355605,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.8228774467163532
            },
            {
              "key": 2021,
              "value": -0.014493867197679755
            },
            {
              "key": 2020,
              "value": -0.008215182776914798
            },
            {
              "key": 2019,
              "value": -0.015977974837295683
            },
            {
              "key": 2018,
              "value": -0.012796802295058862
            },
            {
              "key": 2017,
              "value": -0.011594865326497783
            },
            {
              "key": 2016,
              "value": -0.01810953012353056
            },
            {
              "key": 2015,
              "value": -0.009040057221360698
            }
          ],
          "tdCalYear": 0.8228774467163532,
          "tdCheck": "Pass",
          "tdInception": 1.0675253267329736,
          "tdYTD": 0.016766039506880492,
          "tdYTDChange": -0.0011278278858546376,
          "te1M": 0.03759339573540103,
          "te1Y": 6.383547404917155,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 6.416997636145247
            },
            {
              "key": 2021,
              "value": 0.009594663190527751
            },
            {
              "key": 2020,
              "value": 0.006688053868467534
            },
            {
              "key": 2019,
              "value": 0.013005102228532997
            },
            {
              "key": 2018,
              "value": 0.0048538856994681115
            },
            {
              "key": 2017,
              "value": 0.005366729916143604
            },
            {
              "key": 2016,
              "value": 0.00810932717817771
            },
            {
              "key": 2015,
              "value": 0.0064404129200646
            }
          ],
          "teCalYear": 6.416997636145247,
          "teInception": 2.177863069946305,
          "teYTD": 0.05596491286580404,
          "theoriticalDTD": -0.09812749647895025,
          "tradarCode": "CO-B5",
          "volatilityMTD": 0.0081103249609172,
          "volatilityQTD": 0.02108073375468065,
          "volatilityYTD": 0.02022040105754133
        },
        {
          "_id": "I0XYitp-ME",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.24869813499154447
            },
            {
              "key": 2021,
              "value": -0.07601564501849212
            },
            {
              "key": 2020,
              "value": 0.3369937310517328
            },
            {
              "key": 2019,
              "value": 0.3850158974293203
            },
            {
              "key": 2018,
              "value": -0.2401922542886037
            },
            {
              "key": 2017,
              "value": 0.4386376021738154
            },
            {
              "key": 2016,
              "value": -0.07858352846188199
            },
            {
              "key": 2015,
              "value": -0.014547919510000895
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.22822340260217278
            },
            {
              "key": 2021,
              "value": -0.0736837972853166
            },
            {
              "key": 2020,
              "value": 0.3457086882828151
            },
            {
              "key": 2019,
              "value": 0.39774657247643197
            },
            {
              "key": 2018,
              "value": -0.23471987417059503
            },
            {
              "key": 2017,
              "value": 0.4459909434476186
            },
            {
              "key": 2016,
              "value": -0.10548628580826325
            },
            {
              "key": 2015,
              "value": 0.004706127144990413
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 7024661,
          "aumLastQuarter": 7024661,
          "aumLastYear": 7024661,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "A50CNHN Index",
          "benchmarkIndexName": "FTSE China A50 Index",
          "betaYTD": 0.9747079704838175,
          "ccy": "USD",
          "classID": "CLS00042",
          "className": "CO-USA50",
          "companyID": "CSOP",
          "dtdDiff": 0.0007002076557398595,
          "fundDTDDiff": 0.005617112442674044,
          "fundID": "1000009",
          "fundName": "CSOP FTSE China A50 ETF (US)",
          "fundNote": null,
          "fundReturn": 20.763466470829325,
          "fundReturn1M": -0.05836478302844894,
          "fundReturn1Y": -0.10487360102978505,
          "fundReturn1YRolling": -0.10487360102978505,
          "fundReturn3M": -0.004757424364903673,
          "fundReturn3MRolling": -0.004757424364903673,
          "fundReturn3Y": -0.22012301755113806,
          "fundReturn3YRolling": -0.22012301755113806,
          "fundReturn6M": -0.07788643939812512,
          "fundReturn6MRolling": -0.07788643939812512,
          "fundReturnInception": 0.2220057600554004,
          "fundReturnMTD": -0.06315139494414623,
          "fundReturnQTD": 0.025605309516981478,
          "fundReturnRebase": 122.20057600554011,
          "fundReturnYTD": -0.042225141239335295,
          "fundType": "EE",
          "inceptionDate": "2015-03-12T00:00:00Z",
          "indexDTDDiff": 0.004916904786934184,
          "indexMTDVolatility": 0.15516333733149099,
          "indexQTDVolatility": 0.19763767566288198,
          "indexReturn": 2103.07,
          "indexReturn1M": -0.05967279669845704,
          "indexReturn1Y": -0.07932109304545021,
          "indexReturn1YRolling": -0.07932109304545021,
          "indexReturn3M": -0.0019315280427497639,
          "indexReturn3MRolling": -0.0019315280427497639,
          "indexReturn3Y": -0.18921846808641873,
          "indexReturn3YRolling": -0.18921846808641873,
          "indexReturn6M": -0.07250780602254481,
          "indexReturn6MRolling": -0.07250780602254481,
          "indexReturnChange": 0.500260096357195,
          "indexReturnChangePct": 0.004916904786934184,
          "indexReturnInception": 0.2870370370370372,
          "indexReturnMTD": -0.06314954807268247,
          "indexReturnQTD": 0.026353418868071854,
          "indexReturnRebase": 102.24314877025445,
          "indexYTD": -0.03761548916152224,
          "indexYTDVolatility": 0.18572130273109183,
          "location": "United States",
          "navChange": 0.682580245989783,
          "navChangePct": 0.005617112442674044,
          "navRebase": 88.40053439112934,
          "netAssetValue": 15.0204,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "AFTY US Equity",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": -0.0000018468714637620565,
          "td1Y": -0.025552507984334838,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": -0.020474732389371697
            },
            {
              "key": 2021,
              "value": -0.0023318477331755183
            },
            {
              "key": 2020,
              "value": -0.008714957231082288
            },
            {
              "key": 2019,
              "value": -0.012730675047111673
            },
            {
              "key": 2018,
              "value": -0.005472380118008657
            },
            {
              "key": 2017,
              "value": -0.00735334127380316
            },
            {
              "key": 2016,
              "value": 0.026902757346381256
            }
          ],
          "tdCalYear": -0.020474732389371697,
          "tdCheck": "Pass",
          "tdInception": -0.0650312769816368,
          "tdYTD": -0.004609652077813053,
          "tdYTDChange": 0.000641077923422162,
          "te1M": 0.014523915997843424,
          "te1Y": 0.03438908541287423,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 0.03211671448126657
            },
            {
              "key": 2021,
              "value": 0.014462263150178184
            },
            {
              "key": 2020,
              "value": 0.0421638408763412
            },
            {
              "key": 2019,
              "value": 0.00901942769846458
            },
            {
              "key": 2018,
              "value": 0.012738178285818904
            },
            {
              "key": 2017,
              "value": 0.020097975742747416
            },
            {
              "key": 2016,
              "value": 0.040757283089278874
            }
          ],
          "teCalYear": 0.03211671448126657,
          "teInception": 0.03317228410255997,
          "teYTD": 0.020292630021689444,
          "theoriticalDTD": -0.4916904786934184,
          "tradarCode": "CO-AFTY",
          "volatilityMTD": 0.14999305393480522,
          "volatilityQTD": 0.19400126916866245,
          "volatilityYTD": 0.18208563477836334
        },
        {
          "_id": "I0XYWtpzMU",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.059714795008912636
            },
            {
              "key": 2021,
              "value": -0.030250648228176247
            },
            {
              "key": 2020,
              "value": 0.0008650519031141446
            },
            {
              "key": 2019,
              "value": 0.08037383177570101
            },
            {
              "key": 2018,
              "value": -0.03690369036903696
            },
            {
              "key": 2017,
              "value": 0.06112702960840477
            },
            {
              "key": 2016,
              "value": 0.04700000000000015
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.11989381574194913
            },
            {
              "key": 2021,
              "value": -0.022849283675563048
            },
            {
              "key": 2020,
              "value": 0.0716354464685558
            },
            {
              "key": 2019,
              "value": 0.1184755527872936
            },
            {
              "key": 2018,
              "value": -0.006825817167489534
            },
            {
              "key": 2017,
              "value": 0.058028916009617015
            },
            {
              "key": 2016,
              "value": 0.05206657902543799
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 26996577,
          "aumLastQuarter": 26996577,
          "aumLastYear": 26996577,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "SBABBI Index",
          "benchmarkIndexName": "Citi Asia Broad Bond Index",
          "betaYTD": 0.5903922968800124,
          "ccy": "USD",
          "classID": "CLS00054",
          "className": "CO-USDB",
          "companyID": "CSOP",
          "dtdDiff": -0.00015474209911969794,
          "fundDTDDiff": 0.002803738317757043,
          "fundID": "1000016",
          "fundName": "CSOP Select USD Bond Fund",
          "fundNote": null,
          "fundReturn": 10.73,
          "fundReturn1M": -0.0009310986964617962,
          "fundReturn1Y": 0.011310084825636224,
          "fundReturn1YRolling": 0.011310084825636224,
          "fundReturn3M": 0.00374181478016844,
          "fundReturn3MRolling": 0.00374181478016844,
          "fundReturn3Y": -0.08603066439523,
          "fundReturn3YRolling": -0.08603066439523,
          "fundReturn6M": 0.01417769376181477,
          "fundReturn6MRolling": 0.01417769376181477,
          "fundReturnInception": 0.07299999999999995,
          "fundReturnMTD": -0.0018604651162790198,
          "fundReturnQTD": 0.0018674136321195078,
          "fundReturnRebase": 107.29999999999995,
          "fundReturnYTD": 0.017061611374407537,
          "fundType": "BM",
          "inceptionDate": "2016-01-13T00:00:00Z",
          "indexDTDDiff": 0.002958480416876741,
          "indexMTDVolatility": 0.040925028251420636,
          "indexQTDVolatility": 0.04055618021437612,
          "indexReturn": 202.8644,
          "indexReturn1M": -0.010058284567667553,
          "indexReturn1Y": 0.01322720800175392,
          "indexReturn1YRolling": 0.01322720800175392,
          "indexReturn3M": 0.0005612807064443448,
          "indexReturn3MRolling": 0.0005612807064443448,
          "indexReturn3Y": -0.10782755928886345,
          "indexReturn3YRolling": -0.10782755928886345,
          "indexReturn6M": 0.006840170691590819,
          "indexReturn6MRolling": 0.006840170691590819,
          "indexReturnChange": 0.3197127714139185,
          "indexReturnChangePct": 0.002958480416876741,
          "indexReturnInception": 0.16407367601398049,
          "indexReturnMTD": -0.011639752793496938,
          "indexReturnQTD": -0.009795906015142242,
          "indexReturnRebase": 108.38626260899285,
          "indexYTD": 0.021513454721237446,
          "indexYTDVolatility": 0.04034620498259664,
          "location": "Hong Kong",
          "navChange": 0.29999999999999716,
          "navChangePct": 0.002803738317757043,
          "navRebase": 107.29999999999995,
          "netAssetValue": 10.73,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "CEHKBUI HK",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "tdCheck": "Pass",
          "theoriticalDTD": -0.2958480416876741,
          "tradarCode": "CO-USDB",
          "volatilityMTD": 0.024698508962729335,
          "volatilityQTD": 0.026377322422860117,
          "volatilityYTD": 0.02801088051231606
        },
        {
          "_id": "IjvDWapzM_",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.2695108227973786
            },
            {
              "key": 2021,
              "value": -0.10370809848709595
            },
            {
              "key": 2020,
              "value": 0.5557504153590549
            },
            {
              "key": 2019,
              "value": 0.24150575832235144
            },
            {
              "key": 2018,
              "value": -0.3297361649832943
            },
            {
              "key": 2017,
              "value": 0.4013023355935852
            },
            {
              "key": 2016,
              "value": 0.005682740704659794
            },
            {
              "key": 2015,
              "value": 0.11900436046511631
            },
            {
              "key": 2014,
              "value": 0.014749262536873253
            },
            {
              "key": 2013,
              "value": 0.30593900481540937
            },
            {
              "key": 2012,
              "value": 0.34936105696339603
            },
            {
              "key": 2011,
              "value": -0.0766
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8972320708174206
            },
            {
              "key": 2021,
              "value": -0.23125849826422762
            },
            {
              "key": 2020,
              "value": 0.29652683790002876
            },
            {
              "key": 2019,
              "value": 0.23676817624584778
            },
            {
              "key": 2018,
              "value": -0.18837828041751437
            },
            {
              "key": 2017,
              "value": 0.5441732381191211
            },
            {
              "key": 2016,
              "value": 0.010825267460633059
            },
            {
              "key": 2015,
              "value": -0.07634350941133605
            },
            {
              "key": 2014,
              "value": 0.08262043081752535
            },
            {
              "key": 2013,
              "value": 0.039393891154738014
            },
            {
              "key": 2012,
              "value": 0.2313115891294062
            },
            {
              "key": 2011,
              "value": -0.1903901163360081
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 107410972,
          "aumLastQuarter": 107410972,
          "aumLastYear": 107410972,
          "benchmarkIndexCcy": "HKD",
          "benchmarkIndexCode": "MXCN Index",
          "benchmarkIndexName": "MSCI China Index",
          "betaYTD": 0.981173578304651,
          "ccy": "USD",
          "classID": "CLS00005",
          "className": "CO-CNBO",
          "companyID": "CSOP",
          "dtdDiff": -0.003412203804008085,
          "fundDTDDiff": 0.01923767506284313,
          "fundID": "1000003",
          "fundName": "China New Balance Opportunity Fund",
          "fundNote": null,
          "fundReturn": 198.68,
          "fundReturn1M": -0.05583804590600194,
          "fundReturn1Y": -0.13489506226595838,
          "fundReturn1YRolling": -0.13489506226595838,
          "fundReturn3M": 0.060361850883279056,
          "fundReturn3MRolling": 0.060361850883279056,
          "fundReturn3Y": -0.35756321541744807,
          "fundReturn3YRolling": -0.35756321541744807,
          "fundReturn6M": -0.06963240458908926,
          "fundReturn6MRolling": -0.06963240458908926,
          "fundReturnInception": 0.9868000000000001,
          "fundReturnMTD": -0.07547696603071197,
          "fundReturnQTD": 0.044145469833928974,
          "fundReturnRebase": 198.6799999999978,
          "fundReturnYTD": -0.09981423587513027,
          "fundType": "EM",
          "inceptionDate": "2011-01-21T00:00:00Z",
          "indexDTDDiff": 0.022649878866851214,
          "indexMTDVolatility": 0.23540944382244214,
          "indexQTDVolatility": 0.2583036878782942,
          "indexReturn": 1.377631646021308,
          "indexReturn1M": -0.07699354498122934,
          "indexReturn1Y": -0.8809492528601963,
          "indexReturn1YRolling": -0.8809492528601963,
          "indexReturn3M": 0.031410351117769686,
          "indexReturn3MRolling": 0.031410351117769686,
          "indexReturn3Y": -0.9192789726283593,
          "indexReturn3YRolling": -0.9192789726283593,
          "indexReturn6M": -0.03877563669757189,
          "indexReturn6MRolling": -0.03877563669757189,
          "indexReturnChange": 0.35278014730385365,
          "indexReturnChangePct": 0.022649878866851214,
          "indexReturnInception": -0.8407184314544516,
          "indexReturnMTD": -0.08753067137205439,
          "indexReturnQTD": 0.01642065961605632,
          "indexReturnRebase": 15.928145886683527,
          "indexYTD": -0.04209735391388747,
          "indexYTDVolatility": 0.24888933828121443,
          "location": "Luxembourg",
          "navChange": 3.7499999999999716,
          "navChangePct": 0.01923767506284313,
          "navRebase": 198.6799999999978,
          "netAssetValue": 198.68,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "CHNEBAO LX",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "tdCheck": "Pass",
          "theoriticalDTD": -2.2649878866851214,
          "tradarCode": "CNBO",
          "volatilityMTD": 0.24421968034671593,
          "volatilityQTD": 0.25545431386218037,
          "volatilityYTD": 0.2542491441685807
        },
        {
          "_id": "UjvDiap-pjp",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.013198680131986995
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00205",
          "className": "USD",
          "companyID": "CSOP",
          "fundDTDDiff": 0.00014365889631640094,
          "fundID": "1000142",
          "fundName": "CSOP USD Money Market Fund",
          "fundNote": null,
          "fundReturn": 1.04429,
          "fundReturn1M": 0.004617649039432736,
          "fundReturn1Y": 0.041270316083358294,
          "fundReturn1YRolling": 0.041270316083358294,
          "fundReturn3M": 0.01297882453366439,
          "fundReturn3MRolling": 0.01297882453366439,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.023813725490196003,
          "fundReturn6MRolling": 0.023813725490196003,
          "fundReturnInception": 0.04418558144185569,
          "fundReturnMTD": 0.004192589885857689,
          "fundReturnQTD": 0.008449697742240803,
          "fundReturnRebase": 104.41855814418558,
          "fundReturnYTD": 0.030583242869831162,
          "fundType": "BM",
          "inceptionDate": "2022-07-12T00:00:00Z",
          "location": "Singapore",
          "navChange": 0.014998500149971505,
          "navChangePct": 0.00014365889631640094,
          "navRebase": 104.41855814418558,
          "netAssetValue": 1.04429,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "CSOPUMM SP EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "tradarCode": "SG-USDMF",
          "volatilityMTD": 0.0021841813408557463,
          "volatilityQTD": 0.0019001371346082548,
          "volatilityYTD": 0.0019889664876310467
        },
        {
          "_id": "UjvYiap-p-p",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.0012449460257993872
            },
            {
              "key": 2021,
              "value": 0.0368436125371836
            },
            {
              "key": 2020,
              "value": 0.008254092421289494
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": -0.8519370996481095
            },
            {
              "key": 2021,
              "value": 0.05172540656779412
            },
            {
              "key": 2020,
              "value": 0.010635995838321755
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "aumLastMonth": 9325999299,
          "aumLastQuarter": 9325999299,
          "aumLastYear": 9325999299,
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "CFIICNL Index",
          "benchmarkIndexName": "FTSE Chinese Government Bond Index",
          "betaYTD": -0.008692464632443461,
          "ccy": "CNY",
          "classID": "CLS00180",
          "className": "SG-WGBI",
          "companyID": "CSOP",
          "dtdDiff": -0.0011601452919792754,
          "fundDTDDiff": -0.0002508605514656148,
          "fundID": "1000124",
          "fundName": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
          "fundNote": null,
          "fundReturn": 71.80657051681949,
          "fundReturn1M": 0.005259846446880712,
          "fundReturn1Y": 0.0011218586886243553,
          "fundReturn1YRolling": 0.0011218586886243553,
          "fundReturn3M": -0.0009557315405651634,
          "fundReturn3MRolling": -0.0009557315405651634,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.015191492995500155,
          "fundReturn6MRolling": 0.015191492995500155,
          "fundReturnInception": 0.061467388982141324,
          "fundReturnMTD": 0.005801856421978169,
          "fundReturnQTD": 0.00854679962327376,
          "fundReturnRebase": 106.14673889821412,
          "fundReturnYTD": 0.016633499194849355,
          "fundType": "BE",
          "inceptionDate": "2020-09-18T00:00:00Z",
          "indexDTDDiff": 0.0009092847405136606,
          "indexMTDVolatility": 0.03419205370184083,
          "indexQTDVolatility": 0.045832422387867604,
          "indexReturn": 23.23716,
          "indexReturn1M": -0.012904327524729253,
          "indexReturn1Y": -0.8579096344027899,
          "indexReturn1YRolling": -0.8579096344027899,
          "indexReturn3M": -0.014119249298365055,
          "indexReturn3MRolling": -0.014119249298365055,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": -0.01667831083279836,
          "indexReturn6MRolling": -0.01667831083279836,
          "indexReturnChange": 0.01414247116068168,
          "indexReturnChangePct": 0.0009092847405136606,
          "indexReturnInception": -0.8443245546394473,
          "indexReturnMTD": -0.013055256123075787,
          "indexReturnQTD": 0.005257916508404348,
          "indexReturnRebase": 15.567544536055278,
          "indexYTD": -0.010816788882333284,
          "indexYTDVolatility": 0.052780023975436956,
          "location": "Singapore",
          "navChange": -0.026634711054583704,
          "navChangePct": -0.0002508605514656148,
          "navRebase": 103.6838977528173,
          "netAssetValue": 70.1409,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "CYC SP EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.018857112545053956,
          "td1Y": 0.8590314930914142,
          "tdAnnualYears": [
            {
              "key": 2022,
              "value": 0.8506921536223101
            },
            {
              "key": 2021,
              "value": -0.014881794030610518
            }
          ],
          "tdCalYear": 0.8506921536223101,
          "tdCheck": "Pass",
          "tdInception": 0.9057919436215887,
          "tdYTD": 0.02745028807718264,
          "tdYTDChange": -0.001153729321076824,
          "te1M": 0.03790573504424867,
          "te1Y": 1.2094958375174096,
          "teAnnualYears": [
            {
              "key": 2022,
              "value": 1.2075200712017966
            },
            {
              "key": 2021,
              "value": 0.016707942360356737
            }
          ],
          "teCalYear": 1.2075200712017966,
          "teInception": 0.7753921625757083,
          "teYTD": 0.057908276126507945,
          "theoriticalDTD": -0.09092847405136606,
          "tradarCode": "SG-WGBI",
          "volatilityMTD": 0.009394586797209767,
          "volatilityQTD": 0.01126395799385096,
          "volatilityYTD": 0.022176888623919623
        },
        {
          "_id": "U0vYWtM-M6p",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": 0.09051767297486735
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": 0.09461408992546283
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "GPCSP7UR Index",
          "benchmarkIndexName": "FTSE Asia Pacific Low Carbon Select Index",
          "betaYTD": 0.9112334392134395,
          "ccy": "USD",
          "classID": "CLS00226",
          "className": "USD",
          "companyID": "CSOP",
          "dtdDiff": -0.003292768133055901,
          "fundDTDDiff": 0.016161616161616044,
          "fundID": "1000159",
          "fundName": "CSOP CGS-CIMB FTSE Asia Pacific Low Carbon Index ETF",
          "fundNote": null,
          "fundReturn": 1.1569,
          "fundReturn1M": -0.05094339622641508,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": 0.016786781508173698,
          "fundReturn3MRolling": 0.016786781508173698,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": 0.022267385349474367,
          "fundReturn6MRolling": 0.022267385349474367,
          "fundReturnInception": 0.1584059277060179,
          "fundReturnMTD": -0.05094339622641508,
          "fundReturnQTD": -0.016910265125764767,
          "fundReturnRebase": 115.84059277060176,
          "fundReturnYTD": 0.062253236617390684,
          "fundType": "EE",
          "inceptionDate": "2022-09-27T00:00:00Z",
          "indexDTDDiff": 0.019454384294671945,
          "indexMTDVolatility": 0.1636245100689797,
          "indexQTDVolatility": 0.151950794328633,
          "indexReturn": 1552.68,
          "indexReturn1M": -0.049540588022845,
          "indexReturn1Y": "-",
          "indexReturn1YRolling": "-",
          "indexReturn3M": 0.020875385454951934,
          "indexReturn3MRolling": 0.020875385454951934,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": 0.046111141055354965,
          "indexReturn6MRolling": 0.046111141055354965,
          "indexReturnChange": 2.2263464775186463,
          "indexReturnChangePct": 0.019454384294671945,
          "indexReturnInception": 0.16665664823274828,
          "indexReturnMTD": -0.053318944230030585,
          "indexReturnQTD": -0.01649437519002739,
          "indexReturnRebase": 116.6656648232747,
          "indexYTD": 0.06581548599670528,
          "indexYTDVolatility": 0.15333506382351106,
          "location": "Singapore",
          "navChange": 1.8423951136477257,
          "navChangePct": 0.016161616161616044,
          "navRebase": 115.84059277060176,
          "netAssetValue": 1.1569,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "LCU SP EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.002375548003615502,
          "td1Y": "-",
          "tdAnnualYears": [],
          "tdCalYear": -0.004096416950595483,
          "tdCheck": "Pass",
          "tdInception": -0.00825072052673037,
          "tdYTD": -0.003562249379314597,
          "tdYTDChange": -0.0034444157121606978,
          "te1M": 0.05572756810713057,
          "te1Y": 0.0961413191700963,
          "teAnnualYears": [],
          "teCalYear": 0.07498006168485315,
          "teInception": 0.1041574357511788,
          "teYTD": 0.077214723031613,
          "theoriticalDTD": -1.9454384294671945,
          "tradarCode": "SG-ALC",
          "volatilityMTD": 0.1609103044455945,
          "volatilityQTD": 0.1633239135538412,
          "volatilityYTD": 0.15908186932802232
        },
        {
          "_id": "I0vDitp-pgp",
          "annualFundReturns": [
            {
              "key": 2022,
              "value": -0.005815333491573704
            }
          ],
          "annualIndexReturns": [
            {
              "key": 2022,
              "value": 0.002678919945068081
            }
          ],
          "asOfDate": "2023-08-29T00:00:00Z",
          "aum": 0,
          "benchmarkIndexCcy": "SGD",
          "benchmarkIndexCode": "SREITLSN Index",
          "benchmarkIndexName": "iEdge S-REIT Leaders Index",
          "betaYTD": 0.8355835337410739,
          "ccy": "SGD",
          "classID": "CLS00193",
          "className": "SG-REIT",
          "companyID": "CSOP",
          "dtdDiff": -0.003051908305431228,
          "fundDTDDiff": 0.0001247504990020687,
          "fundID": "1000137",
          "fundName": "CSOP iEdge S-REIT Leaders Index ETF",
          "fundNote": null,
          "fundReturn": 0.8017,
          "fundReturn1M": -0.042059983271597545,
          "fundReturn1Y": "-",
          "fundReturn1YRolling": "-",
          "fundReturn3M": -0.04865313872077848,
          "fundReturn3MRolling": -0.04865313872077848,
          "fundReturn3Y": "-",
          "fundReturn3YRolling": "-",
          "fundReturn6M": -0.07616962433740504,
          "fundReturn6MRolling": -0.07616962433740504,
          "fundReturnInception": -0.04854023261333973,
          "fundReturnMTD": -0.03188020770438349,
          "fundReturnQTD": -0.04740969581749055,
          "fundReturnRebase": 95.14597673866612,
          "fundReturnYTD": -0.04297481198519759,
          "fundType": "EE",
          "inceptionDate": "2021-11-17T00:00:00Z",
          "indexDTDDiff": 0.0031766588044332966,
          "indexMTDVolatility": 0.0958446097029649,
          "indexQTDVolatility": 0.1266528818845788,
          "indexReturn": 1586.9294,
          "indexReturn1M": -0.05788983676393056,
          "indexReturn1Y": "-",
          "indexReturn1YRolling": "-",
          "indexReturn3M": -0.014321875703577014,
          "indexReturn3MRolling": -0.014321875703577014,
          "indexReturn3Y": "-",
          "indexReturn3YRolling": "-",
          "indexReturn6M": -0.0478573524381285,
          "indexReturn6MRolling": -0.0478573524381285,
          "indexReturnChange": 0.31748493919774035,
          "indexReturnChangePct": 0.0031766588044332966,
          "indexReturnInception": 0.0026052532951625906,
          "indexReturnMTD": -0.04962122007677594,
          "indexReturnQTD": -0.014619781113162533,
          "indexReturnRebase": 100.26052532951644,
          "indexYTD": -0.00007346983011213837,
          "indexYTDVolatility": 0.13294000517161703,
          "location": "Singapore",
          "navChange": 0.011868027533836312,
          "navChangePct": 0.0001247504990020687,
          "navRebase": 95.14597673866612,
          "netAssetValue": 0.8017,
          "noOfHoldings": 0,
          "noOfLongPositions": 0,
          "noOfShortPositions": 0,
          "preferredBBGNav": "SRT SP EQUITY",
          "securitiesPct": 0,
          "stressedTDBYMpf": [
            {
              "key": -20,
              "value": 20
            },
            {
              "key": -15,
              "value": 15
            },
            {
              "key": -10,
              "value": 10
            },
            {
              "key": -5,
              "value": 5
            },
            {
              "key": 0,
              "value": 0
            },
            {
              "key": 5,
              "value": -5
            },
            {
              "key": 10,
              "value": -10
            },
            {
              "key": 15,
              "value": -15
            },
            {
              "key": 20,
              "value": -20
            }
          ],
          "td1M": 0.01774101237239245,
          "td1Y": "-",
          "tdAnnualYears": [],
          "tdCalYear": -0.008494253436641785,
          "tdCheck": "Pass",
          "tdInception": -0.05114548590850232,
          "tdYTD": -0.04290134215508545,
          "tdYTDChange": -0.0030469924706691964,
          "te1M": 0.046968488375255656,
          "te1Y": 0.4392685772921968,
          "teAnnualYears": [],
          "teCalYear": 0.4470415540153154,
          "teInception": 0.46826187675603115,
          "teYTD": 0.07242143213368392,
          "theoriticalDTD": -0.31766588044332966,
          "tradarCode": "SG-REIT",
          "volatilityMTD": 0.08417208812723376,
          "volatilityQTD": 0.1537556372448664,
          "volatilityYTD": 0.1306748951256068
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "HKD",
          "classID": "CLS00242",
          "className": "CJQ2 HKD",
          "fundID": "1000172",
          "fundName": "CSOP Changjiang Absolute Return Fund SP",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2021-07-01T16:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": null,
          "tradarCode": "CO-CCJA"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00241",
          "className": "CO-CQGF",
          "fundID": "1000171",
          "fundName": "CSOP Global Quant Allocation Growth Fund I SP",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2019-06-01T08:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": "CSOPGQD KY EQUITY",
          "tradarCode": "CO-CQGF"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00240",
          "className": "CO-CGQA",
          "fundID": "1000170",
          "fundName": "CSOP Global Quant Allocation Fund I SP",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2018-12-01T08:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": "CSOPGQA KY EQUITY",
          "tradarCode": "CO-CGQA"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00239",
          "className": "CO-CQBP",
          "fundID": "1000169",
          "fundName": "CSOP Global Quant Allocation Fund - Bond Plus SP",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2019-03-01T16:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": "CSOPGQB KY EQUITY",
          "tradarCode": "CO-CQBP"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00238",
          "className": "CO-CQB2",
          "fundID": "1000168",
          "fundName": "CSOP Global Quant Allocation Fund - Bocom Bond Plus II Greater Bay SP",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2019-08-01T16:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": "CSOPGQE KY EQUITY",
          "tradarCode": "CO-CQB2"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00237",
          "className": "CO-MFOF",
          "fundID": "1000167",
          "fundName": "Multi Access Fund of Funds SP",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2020-07-28T00:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": null,
          "tradarCode": "CO-MFOF"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00235",
          "className": "CO-CSTF",
          "fundID": "1000166",
          "fundName": "CSOP Finetree Fund SP",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2020-06-01T08:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": null,
          "tradarCode": "CO-CSTF"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00234",
          "className": "CO-CQHT",
          "fundID": "1000165",
          "fundName": "CSOP Global Quant Allocation Dividend Fund - HT International SP I",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2019-10-01T08:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": "CSOPGQC KY EQUITY",
          "tradarCode": "CO-CQHT"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00233",
          "className": "SG-CSGT",
          "fundID": "1000164",
          "fundName": "CSOP Global Quant Allocation Fund - Balanced I SP",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2019-10-01T08:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": "CSOPGQF KY EQUITY",
          "tradarCode": "SG-CSGT"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00232",
          "className": "SG-CSB2",
          "fundID": "1000163",
          "fundName": "CSOP Global Quant Allocation Fund - Balanced II SP",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2019-12-02T08:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": "CSOPGQG KY EQUITY",
          "tradarCode": "SG-CSB2"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00224",
          "className": "USD",
          "fundID": "1000157",
          "fundName": "GAMA",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2018-11-01T00:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": null,
          "tradarCode": null
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00223",
          "className": "USD",
          "fundID": "1000156",
          "fundName": "Finetree",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2020-05-01T00:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": null,
          "tradarCode": null
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "CNY",
          "classID": "CLS00215",
          "className": "CNY",
          "fundID": "1000148",
          "fundName": "社保1303港股通",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2018-01-01T00:00:00Z",
          "location": "China",
          "preferredBBGNav": null,
          "tradarCode": null
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "CNY",
          "classID": "CLS00214",
          "className": "CNY",
          "fundID": "1000147",
          "fundName": "平安鑫福26号港股通",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2017-07-01T00:00:00Z",
          "location": "China",
          "preferredBBGNav": null,
          "tradarCode": null
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "HKD",
          "classID": "CLS00213",
          "className": "HKD",
          "fundID": "1000146",
          "fundName": "HKU",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2017-11-01T00:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": null,
          "tradarCode": null
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "CNY",
          "classID": "CLS00208",
          "className": "A",
          "fundID": "1000145",
          "fundName": "Fotic-HK Connect",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2016-03-01T00:00:00Z",
          "location": "China",
          "preferredBBGNav": null,
          "tradarCode": null
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00207",
          "className": "USD",
          "fundID": "1000144",
          "fundName": "Rock Creek",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2016-02-01T00:00:00Z",
          "location": "United States",
          "preferredBBGNav": null,
          "tradarCode": null
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00206",
          "className": "A",
          "fundID": "1000143",
          "fundName": "CG Partner",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2015-10-01T00:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": null,
          "tradarCode": null
        },
        {
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "SBWMUD3L Index",
          "benchmarkIndexName": "FTSE 3-Month US Dollar Eurodeposit Index",
          "ccy": "USD",
          "classID": "CLS00172",
          "className": "CO-CUSF",
          "fundID": "1000042",
          "fundName": "CSOP US Dollar Money Market ETF",
          "fundNote": null,
          "fundType": "BE",
          "inceptionDate": "2019-08-01T16:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": "3096UA HK EQUITY",
          "tradarCode": "CO-CUSF"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "HKD",
          "classID": "CLS00089",
          "className": "CO-HKU",
          "fundID": "1000040",
          "fundName": "HKU HK and CN Equity Fund",
          "fundNote": null,
          "fundType": "EM",
          "inceptionDate": "2010-10-11T00:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": null,
          "tradarCode": "CO-HKU"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00088",
          "className": "CO-ABS",
          "fundID": "1000039",
          "fundName": "ABS Direct Eq Fund (S4)",
          "fundNote": null,
          "fundType": "EM",
          "inceptionDate": "2010-10-11T00:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": null,
          "tradarCode": "CO-ABS"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00087",
          "className": "CO-RC",
          "fundID": "1000038",
          "fundName": "Rock Creek Advisory account",
          "fundNote": null,
          "fundType": "EM",
          "inceptionDate": "2010-10-11T00:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": null,
          "tradarCode": "CO-RC"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "CNY",
          "classID": "CLS00085",
          "className": "CO-CABF",
          "fundID": "1000036",
          "fundName": "China Access Bond Fund SP7",
          "fundNote": null,
          "fundType": "BM",
          "inceptionDate": "2010-10-11T00:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": null,
          "tradarCode": "CO-CABF"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "USD",
          "classID": "CLS00084",
          "className": "CO-MFOF",
          "fundID": "1000035",
          "fundName": "Multi Access Fund of Funds SP",
          "fundNote": null,
          "fundType": "BM",
          "inceptionDate": "2010-10-11T00:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": null,
          "tradarCode": "CO-MFOF"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "CNY",
          "classID": "CLS00083",
          "className": "CO-ICBC",
          "fundID": "1000034",
          "fundName": "ICBC Advisory Account",
          "fundNote": null,
          "fundType": "EM",
          "inceptionDate": "2017-06-15T16:00:00Z",
          "location": "China",
          "preferredBBGNav": null,
          "tradarCode": "CO-ICBC"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "CNY",
          "classID": "CLS00082",
          "className": "CO-CSBF",
          "fundID": "1000033",
          "fundName": "CSOP Dragon Dynamic China Select Balanced Fund",
          "fundNote": null,
          "fundType": "EM",
          "inceptionDate": "2014-11-26T00:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": "CODDCSB KY Equity",
          "tradarCode": "CO-CSBF"
        },
        {
          "benchmarkIndexCcy": "USD",
          "benchmarkIndexCode": "M1CXCNA Index",
          "benchmarkIndexName": "MSCI China A International with CNH 100% Hedged to USD USD Net Total Return Index",
          "ccy": "USD",
          "classID": "CLS00059",
          "className": "US-MSCIH",
          "fundID": "1000018",
          "fundName": "CSOP MSCI China A International Hedged ETF",
          "fundNote": null,
          "fundType": "EE",
          "inceptionDate": "2015-10-20T00:00:00Z",
          "location": "United States",
          "preferredBBGNav": "CNHX US Equity",
          "tradarCode": "US-MSCIH"
        },
        {
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "MBCNA Index",
          "benchmarkIndexName": "MSCI China A Inclusion Net Total Return Index",
          "ccy": "CNY",
          "classID": "CLS00048",
          "className": "HK-MSCIA",
          "fundID": "1000012",
          "fundName": "CSOP MSCI China A Inclusion Index ETF",
          "fundNote": null,
          "fundType": "EE",
          "inceptionDate": "2015-11-25T16:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": "83149 HK EQUITY",
          "tradarCode": "HK-MSCIA"
        },
        {
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "CSIR0300 Index",
          "benchmarkIndexName": "CSIR0300 Index",
          "ccy": "CNY",
          "classID": "CLS00035",
          "className": "CO-HERM",
          "fundID": "1000007",
          "fundName": "CSOP China Intelligence A-Share Fund",
          "fundNote": null,
          "fundType": "EM",
          "inceptionDate": "2014-09-03T00:00:00Z",
          "location": "Ireland",
          "preferredBBGNav": "CSHCAAR ID",
          "tradarCode": "CO-HERM"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "CNY",
          "classID": "CLS00034",
          "className": "CO-DDCS",
          "fundID": "1000006",
          "fundName": "CSOP Dragon Dynamic China Select Equity SP",
          "fundNote": null,
          "fundType": "BM",
          "inceptionDate": "2014-08-13T00:00:00Z",
          "location": "Cayman Islands",
          "preferredBBGNav": "CODDCSC KY",
          "tradarCode": "RQFII-DDCS"
        },
        {
          "benchmarkIndexCcy": "CNY",
          "benchmarkIndexCode": "CTAGTRID Index",
          "benchmarkIndexName": "CTAGTRID Index",
          "ccy": "CNY",
          "classID": "CLS00029",
          "className": "CO-SZRMB",
          "fundID": "1000005",
          "fundName": "CSOP Shen Zhou RMB Fund",
          "fundNote": null,
          "fundType": "BM",
          "inceptionDate": "2012-02-22T00:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": "CSOPSZI HK",
          "tradarCode": "RQFII-SZRMB"
        },
        {
          "benchmarkIndexCcy": null,
          "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          "ccy": "HKD",
          "classID": "CLS00254",
          "className": "HKD",
          "fundID": "1000183",
          "fundName": "My Choice HK Tracking Fund",
          "fundNote": null,
          "fundType": "BM",
          "inceptionDate": "2010-01-04T18:55:28.47Z",
          "location": "Central African Republic",
          "preferredBBGNav": null,
          "tradarCode": "MYCHOICE"
        },
        {
          "benchmarkIndexCcy": null,
          // "benchmarkIndexCode": null,
          "benchmarkIndexName": null,
          // "ccy": "HKD",
          "classID": "CLS00247",
          "className": "HKD Share Class A",
          "fundID": "1000175",
          // "fundName": "CSOP Asset Management Limited - SMA 1",
          "fundNote": null,
          "fundType": "OT",
          "inceptionDate": "2019-04-01T00:00:00Z",
          "location": "Hong Kong",
          "preferredBBGNav": null,
          "tradarCode": "CO-CSM1"
        }
      ]
    }
  }

  getNewDashBoardSummaries() {
    return {
      "filterDetails": {
        "asstClassFilters": [
          {
            "type": "Equity",
            "value": 97
          },
          {
            "type": "Fixed Income",
            "value": 8
          },
          {
            "type": "Commodities",
            "value": 5
          },
          {
            "type": "Money Market",
            "value": 4
          },
          {
            "type": "Currency",
            "value": 5
          }
        ],
        "breachFilters": [
          {
            "type": "TD",
            "value": 24
          },
          {
            "type": "LQ",
            "value": 36
          },
          {
            "type": "FV",
            "value": 0
          }
        ],
        "domicileFilters": [
          {
            "type": "Hong Kong",
            "value": 87
          },
          {
            "type": "Cayman Islands",
            "value": 21
          },
          {
            "type": "Taiwan",
            "value": 1
          },
          {
            "type": "Singapore",
            "value": 7
          },
          {
            "type": "American Samoa",
            "value": 1
          },
          {
            "type": "United States",
            "value": 5
          },
          {
            "type": "Albania",
            "value": 3
          },
          {
            "type": "China",
            "value": 8
          },
          {
            "type": "Ireland",
            "value": 4
          },
          {
            "type": "South Korea",
            "value": 1
          },
          {
            "type": "Afghanistan",
            "value": 1
          },
          {
            "type": "Luxembourg",
            "value": 2
          }
        ],
        "productTypeFilters": [
          {
            "type": "Advisory Account",
            "value": 30
          },
          {
            "type": "ETF",
            "value": 37
          },
          {
            "type": "Mutual Fund",
            "value": 10
          },
          {
            "type": "Private Fund",
            "value": 26
          },
          {
            "type": "L&I",
            "value": 16
          }
        ],
      },
      "status": "success",
      "summaries": [
        {
          "aum": 0,
          "benchmarkIndexCode": "cft",
          "ccy": "HKD",
          "fundName": "CSOP Asset Management Limited - SMA 7",
          "fundReturnYTD": 23,
          "indexReturnYTD": 24,
          "location": "Hong Kong",
          "navChange": 24,
          "navChangePct": 26,
          "netAssetValue": 27,
          "primaryTicker": "",
          "tdYTD": 28,
          "tdYTDChg": 29,
          "tdYTDChgPct": 30,
          "tradarCode": "CO-CSM7"
        },
        {
          "aum": 0,
          "benchmarkIndexCode": "",
          "ccy": "USD",
          "fundName": null,
          "fundReturnYTD": 0,
          "indexReturnYTD": 0,
          "location": "Hong Kong",
          "navChange": 0,
          "navChangePct": 0,
          "netAssetValue": 0,
          "primaryTicker": "",
          "tdYTD": 0,
          "tdYTDChg": 0,
          "tdYTDChgPct": 0,
          "tradarCode": "GQAB"
        },
        {
          "aum": 0,
          "benchmarkIndexCode": "",
          "ccy": "USD",
          "fundName": "",
          "fundReturnYTD": 0,
          "indexReturnYTD": 0,
          "location": "Cayman Islands",
          "navChange": 0,
          "navChangePct": 0,
          "netAssetValue": 0,
          "primaryTicker": "",
          "tdYTD": 0,
          "tdYTDChg": 0,
          "tdYTDChgPct": 0,
          "tradarCode": "CO-CMB"
        }
      ]
    }
  }

  getSuspendedStockData() {
    return {
      "data": [
        {
          "HK-CNBO": 0,
          "_id": 29376,
          "actionDate": "",
          "actionDetails": "",
          "fromDate": "2023-11-20T00:00:00Z",
          "fundID": "1000003",
          "indstryLvlAdjstmnt": "",
          "instrumentName": "CHINA ANIMAL HEALTHCARE LTD",
          "instrumentTicker": "940 HK EQUITY",
          "noOfDaysSuspended": 1,
          "pmCommentsChn": "",
          "pmCommentsEng": "",
          "sector": "",
          "ticker": "940 HK EQUITY",
          "toDate": "2023-11-20T00:00:00Z",
          "trgtdFairValPrc": ""
        },
        {
          "HK-CNBO": 0,
          "_id": 29375,
          "actionDate": "",
          "actionDetails": "",
          "fromDate": "2023-11-20T00:00:00Z",
          "fundID": "1000003",
          "indstryLvlAdjstmnt": "",
          "instrumentName": "SOUND GLOBAL LTD",
          "instrumentTicker": "967 HK EQUITY",
          "noOfDaysSuspended": 1,
          "pmCommentsChn": "",
          "pmCommentsEng": "",
          "sector": "",
          "ticker": "967 HK EQUITY",
          "toDate": "2023-11-20T00:00:00Z",
          "trgtdFairValPrc": ""
        },
        {
          "HK-CSI5": 0,
          "_id": 29374,
          "actionDate": "",
          "actionDetails": "",
          "fromDate": "2023-11-20T00:00:00Z",
          "fundID": "1000118",
          "indstryLvlAdjstmnt": "",
          "instrumentName": "ZHONGTIAN URBAN DEVELOPMEN-A",
          "instrumentTicker": "000540 C2 EQUITY",
          "noOfDaysSuspended": 1,
          "pmCommentsChn": "",
          "pmCommentsEng": "",
          "sector": "Real Estate",
          "ticker": "000540 C2 EQUITY",
          "toDate": "2023-11-20T00:00:00Z",
          "trgtdFairValPrc": ""
        },
        {
          "HK-CLOUD": 0,
          "_id": 29375,
          "actionDate": "",
          "actionDetails": "",
          "fromDate": "2023-11-20T00:00:00Z",
          "fundID": "1000118",
          "indstryLvlAdjstmnt": "",
          "instrumentName": "ZHONGTIAN URBAN DEVELOPMEN-A",
          "instrumentTicker": "000540 C2 EQUITY",
          "noOfDaysSuspended": 1,
          "pmCommentsChn": "",
          "pmCommentsEng": "",
          "sector": "Real Estate",
          "ticker": "000540 C2 EQUITY",
          "toDate": "2023-11-20T00:00:00Z",
          "trgtdFairValPrc": ""
        },
        {
          "CO-MMF": 0,
          "_id": 29376,
          "actionDate": "",
          "actionDetails": "",
          "fromDate": "2023-11-20T00:00:00Z",
          "fundID": "1000118",
          "indstryLvlAdjstmnt": "",
          "instrumentName": "ZHONGTIAN URBAN DEVELOPMEN-A",
          "instrumentTicker": "000540 C2 EQUITY",
          "noOfDaysSuspended": 1,
          "pmCommentsChn": "",
          "pmCommentsEng": "",
          "sector": "Real Estate",
          "ticker": "000540 C2 EQUITY",
          "toDate": "2023-11-20T00:00:00Z",
          "trgtdFairValPrc": ""
        }
      ],
      "headers": [
        "HK-CSI5",
        "HK-CNBO",
        "HK-CLOUD",
        "CO-MMF"
      ],
      "status": "success"
    }
  }

  getUploadShareholdings() {
    return {
      "data": [
        {
          "S_No": "01",
          "job_status": "DONE",
          "error_msg": "",
          "verison_date": "2024-04-24T09:47:19.3401021Z",
          "file_name": "CSOP iEdge S-REIT Leaders Index ETF (1).xlsx"
        },
        {
          "S_No": "02",
          "job_status": "FAIL",
          "error_msg": "Issuer Info not found",
          "verison_date": "2024-04-24T09:36:34.5846106Z",
          "file_name": "NAV2_RECON_RESULTS_20230609.xlsx"
        },
        {
          "S_No": "03",
          "job_status": "FAIL",
          "error_msg": "Issuer Info not found",
          "verison_date": "2024-04-24T09:36:20.5069766Z",
          "file_name": "NAV2_RECON_RESULTS_20230609.xlsx"
        },
        {
          "S_No": "04",
          "job_status": "FAIL",
          "error_msg": "Issuer Info not found",
          "verison_date": "2024-04-24T09:35:46.9225349Z",
          "file_name": "CYX_SH2310.xlsx"
        }
      ],
      "status": "success"
    }
  }

  getSalesPersonsData() {
    return {
      data: [
        {
          salespersonId: "01",
          name: "Nicole",
          username: 'inprogress',
          modifiedDate: "30/02/2024"
        },
        {
          salespersonId: "02",
          name: "Lin",
          username: 'failed',
          modifiedDate: "30/02/2024"
        },
        {
          salespersonId: "03",
          name: "Tyler",
          username: 'completed',
          modifiedDate: "30/02/2024"
        },
        {
          salespersonId: "04",
          name: "Raphel",
          username: 'inprogress',
          modifiedDate: "30/02/2024"
        },
        {
          salespersonId: "05",
          name: "Jim",
          username: 'inprogress',
          modifiedDate: "30/02/2024"
        }
      ],
      status: "success"
    }
  }

  getSalesTeamData() {
    return {
      data: [
        {
          sNo: "01",
          salesteamname: "Nicole",
          modifiedDate: "30/02/2024"
        },
        {
          sNo: "02",
          salesteamname: "Lin",
          modifiedDate: "30/02/2024"
        },
        {
          sNo: "03",
          salesteamname: "Tyler",
          modifiedDate: "30/02/2024"
        },
        {
          sNo: "04",
          salesteamname: "Raphel",
          modifiedDate: "30/02/2024"
        },
        {
          sNo: "05",
          salesteamname: "Jim",
          modifiedDate: "30/02/2024"
        }
      ],
      status: "success"
    }
  }


  getPdData() {
    return {
      participants: [
        {
          sNo: "01",
          pdId: "B0122",
          participantDealer: "China Merchant Securities1",
          nationalityDesc: "DEP AGENT",
          nationality: "DEP_AGENT",
          modifiedDate: "30/02/2024"
        },
        {
          sNo: "02",
          pdId: "B0123",
          participantDealer: "China Merchant Securities2",
          nationalityDesc: "DEP AGENT",
          nationality: "DEP_AGENT",
          modifiedDate: "30/02/2024"
        },
        {
          sNo: "03",
          pdId: "B0124",
          participantDealer: "China Merchant Securities3",
          nationalityDesc: "DEP AGENT",
          nationality: "DEP_AGENT",
          modifiedDate: "30/02/2024"
        },
        {
          sNo: "04",
          pdId: "B0125",
          participantDealer: "China Merchant Securities4",
          nationalityDesc: "DEP AGENT",
          nationality: "DEP_AGENT",
          modifiedDate: "30/02/2024"
        },
      ],
      status: "success"
    }
  }

  getCcassFubdData() {
    return {
      data: [
        {
          "assetClass": "EQTY",
          "assetClassDesc": "Equity",
          "ccassTickerId": 25,
          "currencyId": 1,
          "currencyName": "USD",
          "fundId": "5",
          "isActive": true,
          "issuerId": 6,
          "issuerName": "CSOP ASSET MANAGEMENT PTE. LTD.D",
          "listedAt": "CDP",
          "listedAtDesc": "CDP",
          "longName": "CSOP IEDGE SRETFD",
          "modifiedDate": "2024-05-06T12:21:36.247Z",
          "productType": "ETF",
          "seqNo": 1,
          "terminatedDate": "2024-03-05T00:00:00Z",
          "tickerName": "SRT"
        },
        {
          "assetClass": "EQTY",
          "assetClassDesc": "Equity",
          "ccassTickerId": 25,
          "currencyId": 1,
          "currencyName": "USD",
          "fundId": "5",
          "isActive": false,
          "issuerId": 6,
          "issuerName": "CSOP ASSET MANAGEMENT PTE. LTD.D",
          "listedAt": "CDP",
          "listedAtDesc": "CDP",
          "longName": "CSOP IEDGE SRETFD",
          "modifiedDate": "2024-05-06T12:21:36.247Z",
          "productType": "ETF",
          "seqNo": 1,
          "terminatedDate": "2024-03-05T00:00:00Z",
          "tickerName": "SRT"
        }
      ],
      status: "success"
    }
  }

  getCcassCurrencies() {
    return {
      data: [
        {
          "currencyName": "USD",
          "id": 1
        },
        {
          "currencyName": "CNY",
          "id": 2
        }
      ],
      status: "success"
    }
  }

  getPdccData() {
    return {
      "data": {
        "pdcc": {
          "T0FundCount": 34,
          "T1FundCount": 16,
          "exceptionT0Count": 34,
          "exceptionT1Count": 16,
          "metCount": 0,
          "noExceptionT0Count": 0,
          "noExceptionT1Count": 0,
          "nonReconciledT0Count": 34,
          "nonReconciledT1Count": 16,
          "reconciledT0Count": 0,
          "reconciledT1Count": 0,
          "signOffT0Count": 31,
          "signOffT1Count": 12,
          "unMetCount": 50,
          "unSignOffT0Count": 3,
          "unsignOffT1Count": 4
        },
        // "nav1": {
        //   "T0FundCount": 34,
        //   "T1FundCount": 16,
        //   "exceptionT0Count": 34,
        //   "exceptionT1Count": 16,
        //   "metCount": 0,
        //   "noExceptionT0Count": 0,
        //   "noExceptionT1Count": 0,
        //   "nonReconciledT0Count": 34,
        //   "nonReconciledT1Count": 16,
        //   "reconciledT0Count": 0,
        //   "reconciledT1Count": 0,
        //   "signOffT0Count": 31,
        //   "signOffT1Count": 12,
        //   "unMetCount": 50,
        //   "unSignOffT0Count": 3,
        //   "unsignOffT1Count": 4
        // },
        // "nav2": {
        //   "T0FundCount": 32,
        //   "T1FundCount": 12,
        //   "exceptionT0Count": 32,
        //   "exceptionT1Count": 12,
        //   "metCount": 0,
        //   "noExceptionT0Count": 0,
        //   "noExceptionT1Count": 0,
        //   "nonReconciledT0Count": 32,
        //   "nonReconciledT1Count": 12,
        //   "reconciledT0Count": 0,
        //   "reconciledT1Count": 0,
        //   "signOffT0Count": 0,
        //   "signOffT1Count": 0,
        //   "unMetCount": 44,
        //   "unSignOffT0Count": 32,
        //   "unsignOffT1Count": 12
        // }
      },
      "status": "success"
    }
  }

  getPdccTableData() {
    return {
      // "data": {
        "data": [
          {
            "bbg": "82822 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00069",
            "custodian_fund_code": "CA50",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CA50",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000023",
            "fund_name": "CSOP FTSE China A50 ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-A50F",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3199UP HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00203",
            "custodian_fund_code": "CTBF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CTB2",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000002",
            "fund_name": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_share_class": "RMB Share Class P unlisted",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Class P",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-B5",
            "type": "",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "83199 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00002",
            "custodian_fund_code": "CTBF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CTBA",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000002",
            "fund_name": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-B5",
            "type": "",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3199UA HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00202",
            "custodian_fund_code": "CTBF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CTBB",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000002",
            "fund_name": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_share_class": "RMB Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Class A",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-B5",
            "type": "",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00240",
            "custodian_fund_code": "CGQA",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CGQA",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000170",
            "fund_name": "CSOP Global Quant Allocation Fund I SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "CO-CGQA",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CGQA",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3033 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00178",
            "custodian_fund_code": "CHST",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHSA",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000123",
            "fund_name": "CSOP Hang Seng TECH Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CHST",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3033UA HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00199",
            "custodian_fund_code": "CHST",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHSB",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000123",
            "fund_name": "CSOP Hang Seng TECH Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "HKD Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted class",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CHST",
            "type": "EE",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00248",
            "custodian_fund_code": "CM11",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000177",
            "fund_name": "CSOP Asset Management Limited - SMA 11",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CM11",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "CNY",
            "class_id": "CLS00252",
            "custodian_fund_code": "CM12",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000181",
            "fund_name": "CSOP Asset Management Limited - SMA 12",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CM12",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "83147 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00044",
            "custodian_fund_code": "CCNT",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CCNT",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000010",
            "fund_name": "CSOP SZSE ChiNext ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CNXT",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00238",
            "custodian_fund_code": "CQB2",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CQB2",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000168",
            "fund_name": "CSOP Global Quant Allocation Fund - Bocom Bond Plus II Greater Bay SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "CO-CQB2",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CQB2",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00239",
            "custodian_fund_code": "CQBP",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CQBP",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000169",
            "fund_name": "CSOP Global Quant Allocation Fund - Bond Plus SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "CO-CQBP",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CQBP",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00241",
            "custodian_fund_code": "CQGF",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CQGF",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000171",
            "fund_name": "CSOP Global Quant Allocation Growth Fund I SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "CO-CQGF",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CQGF",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00234",
            "custodian_fund_code": "CQHT",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CQHT",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000165",
            "fund_name": "CSOP Global Quant Allocation Dividend Fund - HT International SP I",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "CO-CQHT",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CQHT",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3193 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00181",
            "custodian_fund_code": "CS5G",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CS5G",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000125",
            "fund_name": "CSOP Yinhua CSI 5G Communications Theme ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CS5G",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "HKD",
            "class_id": "CLS00247",
            "custodian_fund_code": "CSM1",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000176",
            "fund_name": "CSOP Asset Management Limited - SMA 1",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSM1",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "HKD",
            "class_id": "CLS00251",
            "custodian_fund_code": "CSM4",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000180",
            "fund_name": "CSOP Asset Management Limited - SMA 4",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSM4",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "HKD",
            "class_id": "CLS00249",
            "custodian_fund_code": "CSM5",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000178",
            "fund_name": "CSOP Asset Management Limited - SMA 5",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSM5",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "HKD",
            "class_id": "CLS00250",
            "custodian_fund_code": "CSM7",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000179",
            "fund_name": "CSOP Asset Management Limited - SMA 7",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSM7",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00235",
            "custodian_fund_code": "CSFT",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000166",
            "fund_name": "CSOP Finetree Fund SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "CO-CSTF",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD A",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSTF",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00236",
            "custodian_fund_code": "CSFT",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000166",
            "fund_name": "CSOP Finetree Fund SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "CO-CSTF",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD N1",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSTF",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "9096 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00091",
            "custodian_fund_code": "CUSF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CUSA",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000042",
            "fund_name": "CSOP US Dollar Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CUSF",
            "type": "ME",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "9096UA HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00172",
            "custodian_fund_code": "CUSF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CUSB",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000042",
            "fund_name": "CSOP US Dollar Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "USD Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Share Class A",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CUSF",
            "type": "ME",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00237",
            "custodian_fund_code": "CSFF",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSFF",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000167",
            "fund_name": "Multi Access Fund of Funds SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "USD Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-MFOF",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3053 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00077",
            "custodian_fund_code": "CMMF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CMM1",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000029",
            "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-MMF",
            "type": "ME",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3053UP HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00197",
            "custodian_fund_code": "CMMF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CMMP",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000029",
            "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "HKD Share Class P unlisted",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted share class P",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-MMF",
            "type": "ME",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3053UA HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00173",
            "custodian_fund_code": "CMMF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CMM2",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000029",
            "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "HKD Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted share class A",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-MMF",
            "type": "ME",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3109 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00186",
            "custodian_fund_code": "CS50",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000130",
            "fund_name": "CSOP STAR 50 Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-STAR50",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "83122 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00040",
            "custodian_fund_code": "CSTB",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CST1",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000008",
            "fund_name": "CSOP RMB Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-USTB",
            "type": "ME",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3122UA HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00198",
            "custodian_fund_code": "CSTB",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSTA",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000008",
            "fund_name": "CSOP RMB Money Market ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_share_class": "RMB Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Share Class A",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-USTB",
            "type": "ME",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7348 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00185",
            "custodian_fund_code": "CA5I",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000129",
            "fund_name": "CSOP FTSE China A50 Index Daily (-1x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-A50-I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7248 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00184",
            "custodian_fund_code": "CA5L",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000128",
            "fund_name": "CSOP FTSE China A50 Index Daily (2x) Leveraged Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-A50-L",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3066 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00244",
            "custodian_fund_code": "CBTC",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CBTC",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000173",
            "fund_name": "CSOP Bitcoin Futures ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-BTCFUT",
            "type": "",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3194 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00188",
            "custodian_fund_code": "CGCT",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000132",
            "fund_name": "CSOP Global Cloud Computing Technology Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CLOUD",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CHSNBAU LX EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00007",
            "custodian_fund_code": "CSBE",
            "custodian_recon_party": "CITILUX",
            "entity": "LUX",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CLASS AA DISTRIBUTING",
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000003",
            "fund_name": "China New Balance Opportunity Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "A - Acc (USD)",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CNBO",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CHNEBAO LX EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00005",
            "custodian_fund_code": "CSBE",
            "custodian_recon_party": "CITILUX",
            "entity": "LUX",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "Institutional",
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000003",
            "fund_name": "China New Balance Opportunity Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "I - Acc (USD)",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CNBO",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CHNEBHA LX Equity",
            "ccy": "HKD",
            "class_id": "CLS00006",
            "custodian_fund_code": "CSBE",
            "custodian_recon_party": "CITILUX",
            "entity": "LUX",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CLASS A CAPITALISATION HKD",
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000003",
            "fund_name": "China New Balance Opportunity Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "I - Acc (HKD)",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CNBO",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CNBOADH LX EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00011",
            "custodian_fund_code": "CSBE",
            "custodian_recon_party": "CITILUX",
            "entity": "LUX",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "Class A Dist HKD",
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000003",
            "fund_name": "China New Balance Opportunity Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "HKD Share Class A Dist",
            "recon_status": "NOT RECONCILED",
            "share_class": "A - Dis (HKD)",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CNBO",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CHNEBUA LX EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00013",
            "custodian_fund_code": "CSBE",
            "custodian_recon_party": "CITILUX",
            "entity": "LUX",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "USD Share Class A Dist",
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000003",
            "fund_name": "China New Balance Opportunity Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class A Dist",
            "recon_status": "NOT RECONCILED",
            "share_class": "R - Acc (USD)",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CNBO",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7333 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00177",
            "custodian_fund_code": "CS3I",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000122",
            "fund_name": "CSOP CSI 300 Index Daily (-1x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CS3-I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7233 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00176",
            "custodian_fund_code": "CS3L",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000121",
            "fund_name": "CSOP CSI 300 Index Daily (2x) Leveraged Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "CNY",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CS3-L",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "83005 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00171",
            "custodian_fund_code": "CSI5",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSI5",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000118",
            "fund_name": "CSOP CSI 500 ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CSI5",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3068 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00245",
            "custodian_fund_code": "CEHR",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CEHR",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000174",
            "fund_name": "CSOP Ether Futures ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-DCRFUT",
            "type": "",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7299 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00175",
            "custodian_fund_code": "CSGD",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000120",
            "fund_name": "CSOP Gold Futures Daily (2x) Leveraged Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-GLD-L",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7588 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00071",
            "custodian_fund_code": "CHS4",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHS4",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000024",
            "fund_name": "CSOP Hang Seng China Enterprises Index Daily (-2x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HCE-2I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7288 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00072",
            "custodian_fund_code": "CHS3",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHS3",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000025",
            "fund_name": "CSOP Hang Seng China Enterprises Index Daily (2x) Leveraged Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HCE-L",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3174 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00190",
            "custodian_fund_code": "CSCH",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000134",
            "fund_name": "CSOP China Healthcare Disruption Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HEALTH",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3037UA HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00204",
            "custodian_fund_code": "CSOPHSIETF",
            "custodian_recon_party": "BOC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "2",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000131",
            "fund_name": "CSOP Hang Seng Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "HKD Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Share Class A",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HSI",
            "type": "EE",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3037 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00187",
            "custodian_fund_code": "CSOPHSIETF",
            "custodian_recon_party": "BOC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "1",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000131",
            "fund_name": "CSOP Hang Seng Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HSI",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7500 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00092",
            "custodian_fund_code": "CHS5",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHS5",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000043",
            "fund_name": "CSOP Hang Seng Index Daily (-2x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HSI-2I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7300 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00073",
            "custodian_fund_code": "CHS2",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHS2",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000026",
            "fund_name": "CSOP Hang Seng Index Daily (-1x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HSI-I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7200 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00074",
            "custodian_fund_code": "CHS1",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHS1",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000027",
            "fund_name": "CSOP Hang Seng Index Daily (2x) Leveraged Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HSI-L",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7552 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00183",
            "custodian_fund_code": "CHS7",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHS7",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000127",
            "fund_name": "CSOP Hang Seng TECH Index Daily (-2x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HST-2I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7226 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00182",
            "custodian_fund_code": "CHS6",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHS6",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000126",
            "fund_name": "CSOP Hang Seng TECH Index Daily (2x) Leveraged Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HST-L",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7252 HK Equity",
            "ccy": "CNY",
            "class_id": "CLS00229",
            "custodian_fund_code": "CSBL",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000160",
            "fund_name": "CSOP CSI Brokerage Index Daily (2x) Leveraged Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-IBB-L",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3034 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00201",
            "custodian_fund_code": "CSME",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000141",
            "fund_name": "CSOP NASDAQ 100 ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-METAV",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3003 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00196",
            "custodian_fund_code": "CM50",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000139",
            "fund_name": "CSOP MSCI China A 50 Connect ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-MSCIA50",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7568 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00162",
            "custodian_fund_code": "CND1",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CND1",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000113",
            "fund_name": "CSOP NASDAQ-100 Index Daily (-2x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-NDX-2I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7266 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00174",
            "custodian_fund_code": "CSND",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000119",
            "fund_name": "CSOP NASDAQ-100 Index Daily (2x) Leveraged Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-NDX-L",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3153 HK EQUITY",
            "ccy": "JPY",
            "class_id": "CLS00408",
            "custodian_fund_code": "CNIK",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CNI1",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1014616",
            "fund_name": "CSOP Nikkei 225 Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "JPY Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "JPY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-NIK225",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3153UA HK EQUITY",
            "ccy": "JPY",
            "class_id": "CLS00410",
            "custodian_fund_code": "CNIK",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CNI2",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1014616",
            "fund_name": "CSOP Nikkei 225 Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class unlisted A",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-NIK225",
            "type": "EE",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00195",
            "custodian_fund_code": "CSWC",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000138",
            "fund_name": "CSOP WTI Crude Oil Futures Daily (-1x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-OIL-I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7505 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00231",
            "custodian_fund_code": "COG2",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "COG2",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000162",
            "fund_name": "CSOP US Large Oil \u0026 Gas Companies Daily (-2x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-OLG-I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7204 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00230",
            "custodian_fund_code": "COG1",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "COG1",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000161",
            "fund_name": "CSOP US Large Oil \u0026 Gas Companies Daily (2x) Leveraged Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-OLG-L",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3134 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00189",
            "custodian_fund_code": "CHPC",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000133",
            "fund_name": "CSOP Huatai-PineBridge CSI Photovoltaic Industry ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-PTVT",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "2830 HK EQUITY",
            "ccy": "SAR",
            "class_id": "CLS00402",
            "custodian_fund_code": "CSAU",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSAU",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1021889",
            "fund_name": "CSOP Saudi Arabia ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "SAR Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "SAR",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-SAU",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3162 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00191",
            "custodian_fund_code": "CSGS",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000135",
            "fund_name": "CSOP Global Smart Driving Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-SDRIVE",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3167 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00066",
            "custodian_fund_code": "CSPC",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSPC",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000022",
            "fund_name": "ICBC CSOP S\u0026P NEW CHINA SECTORS ETF",
            "match_status": "Unmatched",
            "nav_frequency": "WEEKLY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-SPNC",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBRM",
            "ccy": "USD",
            "class_id": "CLS00404",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C07",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "RMB Share Class M",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBFA HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00052",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C01",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBFA HK",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBRA HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00055",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C05",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBRA HK",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBUA HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00056",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C03",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBUA HK",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBRI HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00057",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C06",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "RMB Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBRI HK",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBUI HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00054",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C04",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBUI HK",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBFI HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00053",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C02",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "HKD Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBFI HK",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3004 HK EQUITY",
            "ccy": "VND",
            "class_id": "CLS00225",
            "custodian_fund_code": "CSV1",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000158",
            "fund_name": "CSOP FTSE Vietnam 30 ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "VND Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "VND",
            "share_class_ccy": "VND",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-VN30",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "LCU SP EQUITY",
            "ccy": "USD",
            "class_id": "CLS00226",
            "custodian_fund_code": "CALC",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CALC",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000159",
            "fund_name": "CSOP CGS-CIMB FTSE Asia Pacific Low Carbon Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-ALC",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "SQU SP EQUITY",
            "ccy": "USD",
            "class_id": "CLS00258",
            "custodian_fund_code": "C3IE",
            "custodian_recon_party": "DBS",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C01",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1021685",
            "fund_name": "CSOP iEdge Southeast Asia+ TECH Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-ATECH",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00232",
            "custodian_fund_code": "CSB2",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSB2",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000163",
            "fund_name": "CSOP Global Quant Allocation Fund - Balanced II SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "SG-CSB2",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-CSB2",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00233",
            "custodian_fund_code": "CSGT",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSGT",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000164",
            "fund_name": "CSOP Global Quant Allocation Fund - Balanced I SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "SG-CSGT",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-CSGT",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "SCY SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00246",
            "custodian_fund_code": "CCSI",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CCSI",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000175",
            "fund_name": "CSOP CSI STAR and CHINEXT 50 Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "CNY",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-CSI50",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "SHD SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00403",
            "custodian_fund_code": "C3HP",
            "custodian_recon_party": "DBS",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C01",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1022065",
            "fund_name": "CSOP Huatai-PineBridge SSE Dividend Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-DIV",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "SRT SP EQUITY",
            "ccy": "SGD",
            "class_id": "CLS00193",
            "custodian_fund_code": "CISR",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CISR",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000137",
            "fund_name": "CSOP iEdge S-REIT Leaders Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "SGD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "SGD",
            "share_class_ccy": "SGD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-REIT",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CSOPUMM SP EQUITY",
            "ccy": "USD",
            "class_id": "CLS00205",
            "custodian_fund_code": "CUMF",
            "custodian_recon_party": "CITI",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "A",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000142",
            "fund_name": "CSOP USD Money Market Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-USDMF",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CYX SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00228",
            "custodian_fund_code": "CCGB",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CCBG",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000124",
            "fund_name": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_share_class": "RMB Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-WGBI",
            "type": "",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CYC SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00180",
            "custodian_fund_code": "CCGB",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CCGA",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000124",
            "fund_name": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "SGD",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-WGBI",
            "type": "",
            "version_date": "0001-01-01T00:00:00Z"
          }
        ],
      // },
      "status": "success"
    }
  }

  getPDCCTableData() {
    return {
      // "data": {
        "data": [
          {
            "bbg": "82822 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00069",
            "custodian_fund_code": "CA50",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CA50",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000023",
            "fund_name": "CSOP FTSE China A50 ETF",
            "gav_non_trading_diff": -1729475,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -7297257428,
            "gav_trading_diff_status": "Matched",
            "id": 36079,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -9.9303,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 219270,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -7298767633,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 1729475,
            "pma_gav_trading": 7297257428,
            "pma_nav": 7298767633,
            "pma_nav/share": 9.9303,
            "pma_nav_accrual": -219270,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": 0.22000598907470703,
            "pma_units": 735000000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-09-30T13:33:25.4022446Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47021",
            "total_nav_diff_diff": -0.22000598907470703,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-A50F",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -735000000,
            "units_diff_in_baskets": -1470,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 19,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 77,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:36.9033333Z"
          },
          {
            "bbg": "3199UP HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00203",
            "custodian_fund_code": "CTBF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_share_class": "CTB2",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000002",
            "fund_name": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
            "id": 36091,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -7.4284,
            "nav/share_diff_status": "Unmatched",
            "nav_diff": -32332994,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_nav": 32332994,
            "pma_nav/share": 7.4284,
            "pma_share_class": "RMB Share Class P unlisted",
            "pma_total_nav_diff": -0.989995002746582,
            "pma_units": 4352620,
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Class P",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-09-30T13:33:25.4004739Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47005",
            "total_nav_diff_diff": 0.989995002746582,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-B5",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "",
            "units_diff": -4352620,
            "units_diff_in_baskets": "NA",
            "units_diff_status": "Unmatched",
            "version_date": "2024-02-02T09:47:40.56Z"
          },
          {
            "bbg": "83199 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00002",
            "custodian_fund_code": "CTBF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CTBA",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000002",
            "fund_name": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
            "gav_non_trading_diff": -87849467,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -4998967030,
            "gav_trading_diff_status": "Matched",
            "id": 36090,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -103.7697,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 38845,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -5053583793,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_gav_non_trading": 87849467,
            "pma_gav_trading": 4998967030,
            "pma_nav": 5053583793,
            "pma_nav/share": 103.7697,
            "pma_nav_accrual": -38845,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -0.989995002746582,
            "pma_units": 48700000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-09-30T13:33:25.3941589Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47005",
            "total_nav_diff_diff": 0.989995002746582,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-B5",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "",
            "units_diff": -48700000,
            "units_diff_in_baskets": -1623.33,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 11,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 135,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 1,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:40.56Z"
          },
          {
            "bbg": "3199UA HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00202",
            "custodian_fund_code": "CTBF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_share_class": "CTBB",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000002",
            "fund_name": "ICBC CSOP FTSE Chinese Government and Policy Bank Bond Index ETF",
            "id": 36092,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -7.4168,
            "nav/share_diff_status": "Unmatched",
            "nav_diff": -860864,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_nav": 860864,
            "pma_nav/share": 7.4168,
            "pma_share_class": "RMB Share Class unlisted A",
            "pma_total_nav_diff": -0.989995002746582,
            "pma_units": 116070,
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Class A",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-09-30T13:33:25.3983619Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47005",
            "total_nav_diff_diff": 0.989995002746582,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-B5",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "",
            "units_diff": -116070,
            "units_diff_in_baskets": "NA",
            "units_diff_status": "Unmatched",
            "version_date": "2024-02-02T09:47:40.56Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00240",
            "custodian_fund_code": "CGQA",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CGQA",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000170",
            "fund_name": "CSOP Global Quant Allocation Fund I SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "CO-CGQA",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CGQA",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3033 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00178",
            "custodian_fund_code": "CHST",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CHSA",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000123",
            "fund_name": "CSOP Hang Seng TECH Index ETF",
            "gav_non_trading_diff": -14184345,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -25478840519,
            "gav_trading_diff_status": "Matched",
            "id": 36085,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -2.9469,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 717426,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -25492204922,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 14184345,
            "pma_gav_trading": 25478840519,
            "pma_nav": 25492204922,
            "pma_nav/share": 2.9469,
            "pma_nav_accrual": -717426,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": 0.19001007080078125,
            "pma_units": 8650400200,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0716934Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47011",
            "total_nav_diff_diff": -0.19001007080078125,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-CHST",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -8650400200,
            "units_diff_in_baskets": -17300.8,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 21,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 30,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:37.9633333Z"
          },
          {
            "bbg": "3033UA HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00199",
            "custodian_fund_code": "CHST",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_share_class": "CHSB",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000123",
            "fund_name": "CSOP Hang Seng TECH Index ETF",
            "id": 36086,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -2.93,
            "nav/share_diff_status": "Unmatched",
            "nav_diff": -102516,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_nav": 102516,
            "pma_nav/share": 2.93,
            "pma_share_class": "HKD Share Class unlisted A",
            "pma_total_nav_diff": 0.19001007080078125,
            "pma_units": 34988,
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted class",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0740203Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47011",
            "total_nav_diff_diff": -0.19001007080078125,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-CHST",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -34988,
            "units_diff_in_baskets": "NA",
            "units_diff_status": "Unmatched",
            "version_date": "2024-02-02T09:47:37.9633333Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00248",
            "custodian_fund_code": "CM11",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000177",
            "fund_name": "CSOP Asset Management Limited - SMA 11",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CM11",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "CNY",
            "class_id": "CLS00252",
            "custodian_fund_code": "CM12",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000181",
            "fund_name": "CSOP Asset Management Limited - SMA 12",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CM12",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "83147 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00044",
            "custodian_fund_code": "CCNT",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CCNT",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000010",
            "fund_name": "CSOP SZSE ChiNext ETF",
            "gav_non_trading_diff": -81337,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -194575299,
            "gav_trading_diff_status": "Matched",
            "id": 36078,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -5.4986,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 5265,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -194651371,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 81337,
            "pma_gav_trading": 194575299,
            "pma_nav": 194651371,
            "pma_nav/share": 5.4986,
            "pma_nav_accrual": -5265,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": 0.0899999737739563,
            "pma_units": 35400000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0504178Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47003",
            "total_nav_diff_diff": -0.0899999737739563,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-CNXT",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -35400000,
            "units_diff_in_baskets": -118,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 29,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 149,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:35.3733333Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00238",
            "custodian_fund_code": "CQB2",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CQB2",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000168",
            "fund_name": "CSOP Global Quant Allocation Fund - Bocom Bond Plus II Greater Bay SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "CO-CQB2",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CQB2",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00239",
            "custodian_fund_code": "CQBP",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CQBP",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000169",
            "fund_name": "CSOP Global Quant Allocation Fund - Bond Plus SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "CO-CQBP",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CQBP",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00241",
            "custodian_fund_code": "CQGF",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CQGF",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000171",
            "fund_name": "CSOP Global Quant Allocation Growth Fund I SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "CO-CQGF",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CQGF",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00234",
            "custodian_fund_code": "CQHT",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CQHT",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000165",
            "fund_name": "CSOP Global Quant Allocation Dividend Fund - HT International SP I",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "CO-CQHT",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CQHT",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3193 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00181",
            "custodian_fund_code": "CS5G",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CS5G",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000125",
            "fund_name": "CSOP Yinhua CSI 5G Communications Theme ETF",
            "gav_non_trading_diff": -220773,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -4380030,
            "gav_trading_diff_status": "Matched",
            "id": 36065,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -3.8339,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 124,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -4600679,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 220773,
            "pma_gav_trading": 4380030,
            "pma_nav": 4600679,
            "pma_nav/share": 3.8339,
            "pma_nav_accrual": -124,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -0.07000000681728125,
            "pma_units": 1200000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0800176Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "46996",
            "total_nav_diff_diff": 0.07000000681728125,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-CS5G",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -1200000,
            "units_diff_in_baskets": -6,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 16,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 1,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:23.55Z"
          },
          {
            "bbg": "",
            "ccy": "HKD",
            "class_id": "CLS00247",
            "custodian_fund_code": "CSM1",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000176",
            "fund_name": "CSOP Asset Management Limited - SMA 1",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSM1",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "HKD",
            "class_id": "CLS00251",
            "custodian_fund_code": "CSM4",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000180",
            "fund_name": "CSOP Asset Management Limited - SMA 4",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSM4",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "HKD",
            "class_id": "CLS00249",
            "custodian_fund_code": "CSM5",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000178",
            "fund_name": "CSOP Asset Management Limited - SMA 5",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSM5",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "HKD",
            "class_id": "CLS00250",
            "custodian_fund_code": "CSM7",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000179",
            "fund_name": "CSOP Asset Management Limited - SMA 7",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSM7",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00236",
            "custodian_fund_code": "CSFT",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000166",
            "fund_name": "CSOP Finetree Fund SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "CO-CSTF",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD N1",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSTF",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00235",
            "custodian_fund_code": "CSFT",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": null,
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000166",
            "fund_name": "CSOP Finetree Fund SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "OT",
            "ops_fund_type_value": "",
            "pma_share_class": "CO-CSTF",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD A",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-CSTF",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "9096 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00091",
            "custodian_fund_code": "CUSF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CUSA",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000042",
            "fund_name": "CSOP US Dollar Money Market ETF",
            "gav_non_trading_diff": -351638622,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -157630613,
            "gav_trading_diff_status": "Matched",
            "id": 36093,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -110.7461,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 4870,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -104439134,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_gav_non_trading": 351638622,
            "pma_gav_trading": 157630613,
            "pma_nav": 104439134,
            "pma_nav/share": 110.7461,
            "pma_nav_accrual": -4870,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": -0.07000011205673218,
            "pma_units": 943050,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0630903Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47019",
            "total_nav_diff_diff": 0.07000011205673218,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-CUSF",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "ME",
            "units_diff": -943050,
            "units_diff_in_baskets": -943.05,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 26,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 9,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:40.85Z"
          },
          {
            "bbg": "9096UA HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00172",
            "custodian_fund_code": "CUSF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_share_class": "CUSB",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000042",
            "fund_name": "CSOP US Dollar Money Market ETF",
            "id": 36094,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -1.1075,
            "nav/share_diff_status": "Unmatched",
            "nav_diff": -404825231,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_nav": 404825231,
            "pma_nav/share": 1.1075,
            "pma_share_class": "USD Share Class unlisted A",
            "pma_total_nav_diff": -0.07000011205673218,
            "pma_units": 365528373,
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Share Class A",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0645321Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47019",
            "total_nav_diff_diff": 0.07000011205673218,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-CUSF",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "ME",
            "units_diff": -365528373,
            "units_diff_in_baskets": "NA",
            "units_diff_status": "Unmatched",
            "version_date": "2024-02-02T09:47:40.85Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00237",
            "custodian_fund_code": "CSFF",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSFF",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000167",
            "fund_name": "Multi Access Fund of Funds SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "USD Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "CO-MFOF",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3053UA HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00173",
            "custodian_fund_code": "CMMF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_share_class": "CMM2",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000029",
            "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
            "id": 36077,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -10.9954,
            "nav/share_diff_status": "Unmatched",
            "nav_diff": -1818287573,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_nav": 1818287573,
            "pma_nav/share": 10.9954,
            "pma_share_class": "HKD Share Class unlisted A",
            "pma_total_nav_diff": 0.2799968719482422,
            "pma_units": 165367639,
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted share class A",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0601709Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47014",
            "total_nav_diff_diff": -0.2799968719482422,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-MMF",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "ME",
            "units_diff": -165367639,
            "units_diff_in_baskets": "NA",
            "units_diff_status": "Unmatched",
            "version_date": "2024-02-02T09:47:34.4366667Z"
          },
          {
            "bbg": "3053 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00077",
            "custodian_fund_code": "CMMF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CMM1",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000029",
            "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
            "gav_non_trading_diff": -4326226435,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -994844593,
            "gav_trading_diff_status": "Matched",
            "id": 36075,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -1099.5507,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 45086,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -2964399609,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_gav_non_trading": 4326226435,
            "pma_gav_trading": 994844593,
            "pma_nav": 2964399609,
            "pma_nav/share": 1099.5507,
            "pma_nav_accrual": -45086,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": 0.2799968719482422,
            "pma_units": 2696010,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0585238Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47014",
            "total_nav_diff_diff": -0.2799968719482422,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-MMF",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "ME",
            "units_diff": -2696010,
            "units_diff_in_baskets": -2696.01,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 24,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 7,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:34.4366667Z"
          },
          {
            "bbg": "3053UP HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00197",
            "custodian_fund_code": "CMMF",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_share_class": "CMMP",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000029",
            "fund_name": "CSOP Hong Kong Dollar Money Market ETF",
            "id": 36076,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -10.954,
            "nav/share_diff_status": "Unmatched",
            "nav_diff": -538338760,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_nav": 538338760,
            "pma_nav/share": 10.954,
            "pma_share_class": "HKD Share Class P unlisted",
            "pma_total_nav_diff": 0.2799968719482422,
            "pma_units": 49145338,
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted share class P",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0615584Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47014",
            "total_nav_diff_diff": -0.2799968719482422,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-MMF",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "ME",
            "units_diff": -49145338,
            "units_diff_in_baskets": "NA",
            "units_diff_status": "Unmatched",
            "version_date": "2024-02-02T09:47:34.4366667Z"
          },
          {
            "bbg": "3109 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00186",
            "custodian_fund_code": "CS50",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000130",
            "fund_name": "CSOP STAR 50 Index ETF",
            "gav_non_trading_diff": -18163,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -40103237,
            "gav_trading_diff_status": "Matched",
            "id": 36088,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -5.9,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 1085,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -40120315,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 18163,
            "pma_gav_trading": 40103237,
            "pma_nav": 40120315,
            "pma_nav/share": 5.9,
            "pma_nav_accrual": -1085,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -0.10000000149011612,
            "pma_units": 6800000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0902805Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47013",
            "total_nav_diff_diff": 0.10000000149011612,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-STAR50",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -6800000,
            "units_diff_in_baskets": -22.67,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 12,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 50,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:38.5666667Z"
          },
          {
            "bbg": "83122 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00040",
            "custodian_fund_code": "CSTB",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CST1",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000008",
            "fund_name": "CSOP RMB Money Market ETF",
            "gav_non_trading_diff": -421296106,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -69800443,
            "gav_trading_diff_status": "Matched",
            "id": 36080,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -163.6011,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 6575,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -33538217,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_gav_non_trading": 421296106,
            "pma_gav_trading": 69800443,
            "pma_nav": 33538217,
            "pma_nav/share": 163.6011,
            "pma_nav_accrual": -6575,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": 0.28000032901763916,
            "pma_units": 205000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.046484Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47015",
            "total_nav_diff_diff": -0.28000032901763916,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-USTB",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "ME",
            "units_diff": -205000,
            "units_diff_in_baskets": -20.5,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 20,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 6,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:36.99Z"
          },
          {
            "bbg": "3122UA HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00198",
            "custodian_fund_code": "CSTB",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_share_class": "CSTA",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000008",
            "fund_name": "CSOP RMB Money Market ETF",
            "id": 36081,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -10.9068,
            "nav/share_diff_status": "Unmatched",
            "nav_diff": -457551758,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "ME",
            "ops_fund_type_value": "MMF ETF",
            "pma_nav": 457551758,
            "pma_nav/share": 10.9068,
            "pma_share_class": "RMB Share Class unlisted A",
            "pma_total_nav_diff": 0.28000032901763916,
            "pma_units": 41951187,
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Share Class A",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0485622Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47015",
            "total_nav_diff_diff": -0.28000032901763916,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "CO-USTB",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "ME",
            "units_diff": -41951187,
            "units_diff_in_baskets": "NA",
            "units_diff_status": "Unmatched",
            "version_date": "2024-02-02T09:47:36.99Z"
          },
          {
            "bbg": "7348 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00185",
            "custodian_fund_code": "CA5I",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000129",
            "fund_name": "CSOP FTSE China A50 Index Daily (-1x) Inverse Product",
            "gav_non_trading_diff": -2024139,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -1110610,
            "gav_trading_diff_status": "Matched",
            "id": 36103,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -1.3061,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 129,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -3134620,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 2024139,
            "pma_gav_trading": 1110610,
            "pma_nav": 3134620,
            "pma_nav/share": 1.3061,
            "pma_nav_accrual": -129,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": -0.33000000193715096,
            "pma_units": 2400000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0881395Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47002",
            "total_nav_diff_diff": 0.33000000193715096,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-A50-I",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -2400000,
            "units_diff_in_baskets": -6,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 21,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 3,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:56.9766667Z"
          },
          {
            "bbg": "7248 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00184",
            "custodian_fund_code": "CA5L",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000128",
            "fund_name": "CSOP FTSE China A50 Index Daily (2x) Leveraged Product",
            "gav_non_trading_diff": -1771512,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -1267695,
            "gav_trading_diff_status": "Matched",
            "id": 36089,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.3303,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 123,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -3039085,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 1771512,
            "pma_gav_trading": 1267695,
            "pma_nav": 3039085,
            "pma_nav/share": 0.3303,
            "pma_nav_accrual": -123,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 0.23000000044703484,
            "pma_units": 9200000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0861625Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47022",
            "total_nav_diff_diff": -0.23000000044703484,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-A50-L",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -9200000,
            "units_diff_in_baskets": -23,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 21,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 3,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:39.9766667Z"
          },
          {
            "bbg": "3066 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00244",
            "custodian_fund_code": "CBTC",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CBTC",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000173",
            "fund_name": "CSOP Bitcoin Futures ETF",
            "gav_non_trading_diff": -26305724,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -4171293,
            "gav_trading_diff_status": "Matched",
            "id": 36060,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -2.194,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 1635,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -30475382,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 26305724,
            "pma_gav_trading": 4171293,
            "pma_nav": 30475382,
            "pma_nav/share": 2.194,
            "pma_nav_accrual": -1635,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 0.08000000193715096,
            "pma_units": 13890300,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:36.0076016Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "46988",
            "total_nav_diff_diff": -0.08000000193715096,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-BTCFUT",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "",
            "units_diff": -13890300,
            "units_diff_in_baskets": -27.78,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 20,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:08.3266667Z"
          },
          {
            "bbg": "3194 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00188",
            "custodian_fund_code": "CGCT",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000132",
            "fund_name": "CSOP Global Cloud Computing Technology Index ETF",
            "gav_non_trading_diff": -3787,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -1355610,
            "gav_trading_diff_status": "Matched",
            "id": 36149,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -1.7356,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 37,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -1359361,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 3787,
            "pma_gav_trading": 1355610,
            "pma_nav": 1359361,
            "pma_nav/share": 1.7356,
            "pma_nav_accrual": -37,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": -0.030000000493600965,
            "pma_units": 783200,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:36.0050909Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "46992",
            "total_nav_diff_diff": 0.030000000493600965,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-CLOUD",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -783200,
            "units_diff_in_baskets": -2.61,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 15,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 50,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 1,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:48:42.1133333Z"
          },
          {
            "bbg": "CHNEBHA LX Equity",
            "ccy": "HKD",
            "class_id": "CLS00006",
            "custodian_fund_code": "CSBE",
            "custodian_recon_party": "CITILUX",
            "entity": "LUX",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CLASS A CAPITALISATION HKD",
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000003",
            "fund_name": "China New Balance Opportunity Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "I - Acc (HKD)",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CNBO",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CNBOADH LX EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00011",
            "custodian_fund_code": "CSBE",
            "custodian_recon_party": "CITILUX",
            "entity": "LUX",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "Class A Dist HKD",
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000003",
            "fund_name": "China New Balance Opportunity Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "HKD Share Class A Dist",
            "recon_status": "NOT RECONCILED",
            "share_class": "A - Dis (HKD)",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CNBO",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CHNEBUA LX EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00013",
            "custodian_fund_code": "CSBE",
            "custodian_recon_party": "CITILUX",
            "entity": "LUX",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "USD Share Class A Dist",
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000003",
            "fund_name": "China New Balance Opportunity Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class A Dist",
            "recon_status": "NOT RECONCILED",
            "share_class": "R - Acc (USD)",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CNBO",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CHNEBAO LX EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00005",
            "custodian_fund_code": "CSBE",
            "custodian_recon_party": "CITILUX",
            "entity": "LUX",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "Institutional",
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000003",
            "fund_name": "China New Balance Opportunity Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "I - Acc (USD)",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CNBO",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CHSNBAU LX EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00007",
            "custodian_fund_code": "CSBE",
            "custodian_recon_party": "CITILUX",
            "entity": "LUX",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CLASS AA DISTRIBUTING",
            "file_status": "PMA, FA Files Pending",
            "fund_class": null,
            "fund_id": "1000003",
            "fund_name": "China New Balance Opportunity Fund",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "A - Acc (USD)",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-CNBO",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7333 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00177",
            "custodian_fund_code": "CS3I",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000122",
            "fund_name": "CSOP CSI 300 Index Daily (-1x) Inverse Product",
            "gav_non_trading_diff": -19786340,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -4147937,
            "gav_trading_diff_status": "Matched",
            "id": 36068,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -7.4791,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 1005,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -23933272,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 19786340,
            "pma_gav_trading": 4147937,
            "pma_nav": 23933272,
            "pma_nav/share": 7.4791,
            "pma_nav_accrual": -1005,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": 0.09000005573034286,
            "pma_units": 3200000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0701606Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47017",
            "total_nav_diff_diff": -0.09000005573034286,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-CS3-I",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -3200000,
            "units_diff_in_baskets": -4,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 26,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 5,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:29.9866667Z"
          },
          {
            "bbg": "7233 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00176",
            "custodian_fund_code": "CS3L",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000121",
            "fund_name": "CSOP CSI 300 Index Daily (2x) Leveraged Product",
            "gav_non_trading_diff": -154379309,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -10470483,
            "gav_trading_diff_status": "Matched",
            "id": 36087,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -2.6588,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 6943,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -164842849,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 154379309,
            "pma_gav_trading": 10470483,
            "pma_nav": 164842849,
            "pma_nav/share": 2.6588,
            "pma_nav_accrual": -6943,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -0.39000004529953003,
            "pma_units": 62000000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "CNY",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0689299Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47009",
            "total_nav_diff_diff": 0.39000004529953003,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-CS3-L",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -62000000,
            "units_diff_in_baskets": -77.5,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 27,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 4,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:38.21Z"
          },
          {
            "bbg": "83005 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00171",
            "custodian_fund_code": "CSI5",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CSI5",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000118",
            "fund_name": "CSOP CSI 500 ETF",
            "gav_non_trading_diff": -410481,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -14892751,
            "gav_trading_diff_status": "Matched",
            "id": 36100,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -12.7525,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 209,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -15303023,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 410481,
            "pma_gav_trading": 14892751,
            "pma_nav": 15303023,
            "pma_nav/share": 12.7525,
            "pma_nav_accrual": -209,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -0.06999998539686203,
            "pma_units": 1200000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0675664Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47027",
            "total_nav_diff_diff": 0.06999998539686203,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-CSI5",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -1200000,
            "units_diff_in_baskets": -3,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 12,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:48.8Z"
          },
          {
            "bbg": "3068 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00245",
            "custodian_fund_code": "CEHR",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CEHR",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000174",
            "fund_name": "CSOP Ether Futures ETF",
            "gav_non_trading_diff": -11054340,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -998273,
            "gav_trading_diff_status": "Matched",
            "id": 36070,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -1.6648,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 648,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -12051965,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 11054340,
            "pma_gav_trading": 998273,
            "pma_nav": 12051965,
            "pma_nav/share": 1.6648,
            "pma_nav_accrual": -648,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 0.4299999997019768,
            "pma_units": 7239500,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.7817019Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "47008",
            "total_nav_diff_diff": -0.4299999997019768,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-DCRFUT",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "",
            "units_diff": -7239500,
            "units_diff_in_baskets": -14.48,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 20,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:32.34Z"
          },
          {
            "bbg": "7374 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00192",
            "custodian_fund_code": "CSGI",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000136",
            "fund_name": "CSOP Gold Futures Daily (-1x) Inverse Product",
            "gav_non_trading_diff": -1123255,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -266759,
            "gav_trading_diff_status": "Matched",
            "id": 36073,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.8687,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 58,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -1389957,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 1123255,
            "pma_gav_trading": 266759,
            "pma_nav": 1389957,
            "pma_nav/share": 0.8687,
            "pma_nav_accrual": -58,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 0.4100000001490116,
            "pma_units": 1600000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.7738673Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "46994",
            "total_nav_diff_diff": -0.4100000001490116,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-GLD-I",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -1600000,
            "units_diff_in_baskets": -2,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 17,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 1,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:33.9766667Z"
          },
          {
            "bbg": "7299 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00175",
            "custodian_fund_code": "CSGD",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000120",
            "fund_name": "CSOP Gold Futures Daily (2x) Leveraged Product",
            "gav_non_trading_diff": -123897573,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -36167183,
            "gav_trading_diff_status": "Matched",
            "id": 36067,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -1.0092,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 6255,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -160058501,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 123897573,
            "pma_gav_trading": 36167183,
            "pma_nav": 160058501,
            "pma_nav/share": 1.0092,
            "pma_nav_accrual": -6255,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": -0.1400001347064972,
            "pma_units": 158600000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.7705772Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "46989",
            "total_nav_diff_diff": 0.1400001347064972,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-GLD-L",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -158600000,
            "units_diff_in_baskets": -198.25,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 22,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 4,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:28.9566667Z"
          },
          {
            "bbg": "7588 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00071",
            "custodian_fund_code": "CHS4",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CHS4",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000024",
            "fund_name": "CSOP Hang Seng China Enterprises Index Daily (-2x) Inverse Product",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-HCE-2I",
            "type": "LE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "7288 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00072",
            "custodian_fund_code": "CHS3",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CHS3",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000025",
            "fund_name": "CSOP Hang Seng China Enterprises Index Daily (2x) Leveraged Product",
            "gav_non_trading_diff": -234975078,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -22439850,
            "gav_trading_diff_status": "Matched",
            "id": 36126,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -1.3634,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 6598,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -257408330,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 234975078,
            "pma_gav_trading": 22439850,
            "pma_nav": 257408330,
            "pma_nav/share": 1.3634,
            "pma_nav_accrual": -6598,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": 0.49000006914138794,
            "pma_units": 188800000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0524871Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47026",
            "total_nav_diff_diff": -0.49000006914138794,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HCE-L",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -188800000,
            "units_diff_in_baskets": -236,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 20,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:48:22.05Z"
          },
          {
            "bbg": "3174 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00190",
            "custodian_fund_code": "CSCH",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000134",
            "fund_name": "CSOP China Healthcare Disruption Index ETF",
            "gav_non_trading_diff": -1813287,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -220258988,
            "gav_trading_diff_status": "Matched",
            "id": 36111,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -1.7327,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 6250,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -222066026,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 1813287,
            "pma_gav_trading": 220258988,
            "pma_nav": 222066026,
            "pma_nav/share": 1.7327,
            "pma_nav_accrual": -6250,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": 0.38999998569488525,
            "pma_units": 128163100,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0976054Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47051",
            "total_nav_diff_diff": -0.38999998569488525,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HEALTH",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -128163100,
            "units_diff_in_baskets": -427.21,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 12,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 21,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:48:09.1833333Z"
          },
          {
            "bbg": "3037 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00187",
            "custodian_fund_code": "CSOPHSIETF",
            "custodian_recon_party": "BOC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "1",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000131",
            "fund_name": "CSOP Hang Seng Index ETF",
            "gav_non_trading_diff": -559238,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -1200410627,
            "gav_trading_diff_status": "Matched",
            "id": 36119,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -15.6575,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 3282,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -1200930512,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 559238,
            "pma_gav_trading": 1200410627,
            "pma_nav": 1200930512,
            "pma_nav/share": 15.6575,
            "pma_nav_accrual": -3282,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": -0.3599998950958252,
            "pma_units": 76700000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0923266Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47090",
            "total_nav_diff_diff": 0.3599998950958252,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HSI",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -76700000,
            "units_diff_in_baskets": -76.7,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 6,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 82,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:48:17.32Z"
          },
          {
            "bbg": "3037UA HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00204",
            "custodian_fund_code": "CSOPHSIETF",
            "custodian_recon_party": "BOC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_share_class": "2",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000131",
            "fund_name": "CSOP Hang Seng Index ETF",
            "id": 36120,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -16.2693,
            "nav/share_diff_status": "Unmatched",
            "nav_diff": -36071,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_nav": 36071,
            "pma_nav/share": 16.2693,
            "pma_share_class": "HKD Share Class unlisted A",
            "pma_total_nav_diff": -0.3599998950958252,
            "pma_units": 2217,
            "recon_status": "NOT RECONCILED",
            "share_class": "Unlisted Share Class A",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0940504Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47090",
            "total_nav_diff_diff": 0.3599998950958252,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HSI",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -2217,
            "units_diff_in_baskets": "NA",
            "units_diff_status": "Unmatched",
            "version_date": "2024-02-02T09:48:17.32Z"
          },
          {
            "bbg": "7500 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00092",
            "custodian_fund_code": "CHS5",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CHS5",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000043",
            "fund_name": "CSOP Hang Seng Index Daily (-2x) Inverse Product",
            "gav_non_trading_diff": -959968854,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -416212841,
            "gav_trading_diff_status": "Matched",
            "id": 36101,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -7.4466,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 56391,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -1376125303,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 959968854,
            "pma_gav_trading": 416212841,
            "pma_nav": 1376125303,
            "pma_nav/share": 7.4466,
            "pma_nav_accrual": -56391,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": -0.419999361038208,
            "pma_units": 184800000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0659745Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47000",
            "total_nav_diff_diff": 0.419999361038208,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HSI-2I",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -184800000,
            "units_diff_in_baskets": -231,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 28,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 4,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:52.5833333Z"
          },
          {
            "bbg": "7300 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00073",
            "custodian_fund_code": "CHS2",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CHS2",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000026",
            "fund_name": "CSOP Hang Seng Index Daily (-1x) Inverse Product",
            "gav_non_trading_diff": -86121514,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -23560743,
            "gav_trading_diff_status": "Matched",
            "id": 36102,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -6.5285,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 3044,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -109679213,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 86121514,
            "pma_gav_trading": 23560743,
            "pma_nav": 109679213,
            "pma_nav/share": 6.5285,
            "pma_nav_accrual": -3044,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": 0.2900005728006363,
            "pma_units": 16800000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.054305Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47024",
            "total_nav_diff_diff": -0.2900005728006363,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HSI-I",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -16800000,
            "units_diff_in_baskets": -21,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 22,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:55.68Z"
          },
          {
            "bbg": "7200 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00074",
            "custodian_fund_code": "CHS1",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CHS1",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000027",
            "fund_name": "CSOP Hang Seng Index Daily (2x) Leveraged Product",
            "gav_non_trading_diff": -5571412799,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -96500973,
            "gav_trading_diff_status": "Matched",
            "id": 36062,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -2.4763,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 161644,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -5667752128,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 5571412799,
            "pma_gav_trading": 96500973,
            "pma_nav": 5667752128,
            "pma_nav/share": 2.4763,
            "pma_nav_accrual": -161644,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": -0.41999244689941406,
            "pma_units": 2288800000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.056846Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "46999",
            "total_nav_diff_diff": 0.41999244689941406,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HSI-L",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -2288800000,
            "units_diff_in_baskets": -2861,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 32,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 7,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:18.1966667Z"
          },
          {
            "bbg": "7552 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00183",
            "custodian_fund_code": "CHS7",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CHS7",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000127",
            "fund_name": "CSOP Hang Seng TECH Index Daily (-2x) Inverse Product",
            "gav_non_trading_diff": -501814944,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -417130551,
            "gav_trading_diff_status": "Matched",
            "id": 36104,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -9.9234,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 39801,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -918905694,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 501814944,
            "pma_gav_trading": 417130551,
            "pma_nav": 918905694,
            "pma_nav/share": 9.9234,
            "pma_nav_accrual": -39801,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": -0.48000097274780273,
            "pma_units": 92600000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0841294Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47041",
            "total_nav_diff_diff": 0.48000097274780273,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HST-2I",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -92600000,
            "units_diff_in_baskets": -231.5,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 32,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 4,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:59.9966667Z"
          },
          {
            "bbg": "7226 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00182",
            "custodian_fund_code": "CHS6",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CHS6",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000126",
            "fund_name": "CSOP Hang Seng TECH Index Daily (2x) Leveraged Product",
            "gav_non_trading_diff": -7262097293,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -1041649541,
            "gav_trading_diff_status": "Matched",
            "id": 36106,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -2.3792,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 358889,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -8303387945,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 7262097293,
            "pma_gav_trading": 1041649541,
            "pma_nav": 8303387945,
            "pma_nav/share": 2.3792,
            "pma_nav_accrual": -358889,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": 0.38000011444091797,
            "pma_units": 3490040000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0818777Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47018",
            "total_nav_diff_diff": -0.38000011444091797,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-HST-L",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -3490040000,
            "units_diff_in_baskets": -8725.1,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 36,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 6,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:48:03.8866667Z"
          },
          {
            "bbg": "7252 HK Equity",
            "ccy": "CNY",
            "class_id": "CLS00229",
            "custodian_fund_code": "CSBL",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000160",
            "fund_name": "CSOP CSI Brokerage Index Daily (2x) Leveraged Product",
            "gav_non_trading_diff": -8122265,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -1260817,
            "gav_trading_diff_status": "Matched",
            "id": 36095,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -5.8642,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 395,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -9382686,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 8122265,
            "pma_gav_trading": 1260817,
            "pma_nav": 9382686,
            "pma_nav/share": 5.8642,
            "pma_nav_accrual": -395,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -0.5000000018626451,
            "pma_units": 1600000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.1082451Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47001",
            "total_nav_diff_diff": 0.5000000018626451,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-IBB-L",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -1600000,
            "units_diff_in_baskets": -4,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 18,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:41.95Z"
          },
          {
            "bbg": "3034 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00201",
            "custodian_fund_code": "CSME",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000141",
            "fund_name": "CSOP NASDAQ 100 ETF",
            "gav_non_trading_diff": -34752,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -5906126,
            "gav_trading_diff_status": "Matched",
            "id": 36064,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.9744,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 161,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -5940718,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 34752,
            "pma_gav_trading": 5906126,
            "pma_nav": 5940718,
            "pma_nav/share": 0.9744,
            "pma_nav_accrual": -161,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 0.3899999987334013,
            "pma_units": 6096600,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.7771186Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "46991",
            "total_nav_diff_diff": -0.3899999987334013,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-METAV",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -6096600,
            "units_diff_in_baskets": -20.32,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 12,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 101,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:20.61Z"
          },
          {
            "bbg": "3003 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00196",
            "custodian_fund_code": "CM50",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000139",
            "fund_name": "CSOP MSCI China A 50 Connect ETF",
            "gav_non_trading_diff": -616377,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -26251739,
            "gav_trading_diff_status": "Matched",
            "id": 36105,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -3.8532,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 727,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -26867389,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 616377,
            "pma_gav_trading": 26251739,
            "pma_nav": 26867389,
            "pma_nav/share": 3.8532,
            "pma_nav_accrual": -727,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -0.4299999997019768,
            "pma_units": 6972700,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.1014842Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47025",
            "total_nav_diff_diff": 0.4299999997019768,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-MSCIA50",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -6972700,
            "units_diff_in_baskets": -13.95,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 13,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 50,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:48:00.1033333Z"
          },
          {
            "bbg": "7568 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00162",
            "custodian_fund_code": "CND1",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CND1",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000113",
            "fund_name": "CSOP NASDAQ-100 Index Daily (-2x) Inverse Product",
            "gav_non_trading_diff": -187623417,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -21079829,
            "gav_trading_diff_status": "Matched",
            "id": 36098,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.963,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 8787,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -208694458,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 187623417,
            "pma_gav_trading": 21079829,
            "pma_nav": 208694458,
            "pma_nav/share": 0.963,
            "pma_nav_accrual": -8787,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": -0.2199999988079071,
            "pma_units": 216720000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.7669228Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "46997",
            "total_nav_diff_diff": 0.2199999988079071,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-NDX-2I",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -216720000,
            "units_diff_in_baskets": -270.9,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 20,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 4,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:47.2633333Z"
          },
          {
            "bbg": "7266 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00174",
            "custodian_fund_code": "CSND",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000119",
            "fund_name": "CSOP NASDAQ-100 Index Daily (2x) Leveraged Product",
            "gav_non_trading_diff": -9125756,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -2687682,
            "gav_trading_diff_status": "Matched",
            "id": 36072,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -2.4508,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 500,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -11812938,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 9125756,
            "pma_gav_trading": 2687682,
            "pma_nav": 11812938,
            "pma_nav/share": 2.4508,
            "pma_nav_accrual": -500,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": -0.20999999903142452,
            "pma_units": 4820000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.7688463Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "47020",
            "total_nav_diff_diff": 0.20999999903142452,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-NDX-L",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -4820000,
            "units_diff_in_baskets": -6.03,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 20,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 3,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:33.09Z"
          },
          {
            "bbg": "3153 HK EQUITY",
            "ccy": "JPY",
            "class_id": "CLS00408",
            "custodian_fund_code": "CNIK",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CNI1",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1014616",
            "fund_name": "CSOP Nikkei 225 Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "JPY Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "JPY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-NIK225",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00195",
            "custodian_fund_code": "CSWC",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000138",
            "fund_name": "CSOP WTI Crude Oil Futures Daily (-1x) Inverse Product",
            "gav_non_trading_diff": -1671373,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -330299,
            "gav_trading_diff_status": "Matched",
            "id": 36066,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.5004,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 84,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -2001589,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 1671373,
            "pma_gav_trading": 330299,
            "pma_nav": 2001589,
            "pma_nav/share": 0.5004,
            "pma_nav_accrual": -84,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": -0.039999999571591616,
            "pma_units": 4000000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.7756071Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "47012",
            "total_nav_diff_diff": 0.039999999571591616,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-OIL-I",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -4000000,
            "units_diff_in_baskets": -5,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 16,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 1,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:26.98Z"
          },
          {
            "bbg": "7505 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00231",
            "custodian_fund_code": "COG2",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "COG2",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000162",
            "fund_name": "CSOP US Large Oil \u0026 Gas Companies Daily (-2x) Inverse Product",
            "gav_non_trading_diff": -1798856,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -1145606,
            "gav_trading_diff_status": "Matched",
            "id": 36082,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.9814,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 116,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -2944346,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 1798856,
            "pma_gav_trading": 1145606,
            "pma_nav": 2944346,
            "pma_nav/share": 0.9814,
            "pma_nav_accrual": -116,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": -0.3300000000745058,
            "pma_units": 3000000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.7800664Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "47006",
            "total_nav_diff_diff": 0.3300000000745058,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-OLG-I",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -3000000,
            "units_diff_in_baskets": -7.5,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 22,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 1,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:37.1766667Z"
          },
          {
            "bbg": "7204 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00230",
            "custodian_fund_code": "COG1",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "COG1",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000161",
            "fund_name": "CSOP US Large Oil \u0026 Gas Companies Daily (2x) Leveraged Product",
            "gav_non_trading_diff": -974292,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -1012969,
            "gav_trading_diff_status": "Matched",
            "id": 36063,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.7643,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 78,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -1987183,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "LE",
            "ops_fund_type_value": "L\u0026I ETF",
            "pma_gav_non_trading": 974292,
            "pma_gav_trading": 1012969,
            "pma_nav": 1987183,
            "pma_nav/share": 0.7643,
            "pma_nav_accrual": -78,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 0.13999999966472387,
            "pma_units": 2600000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.7785222Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "46990",
            "total_nav_diff_diff": -0.13999999966472387,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-OLG-L",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "LE",
            "units_diff": -2600000,
            "units_diff_in_baskets": -6.5,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 19,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 1,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:19.5Z"
          },
          {
            "bbg": "3134 HK EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00189",
            "custodian_fund_code": "CHPC",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000133",
            "fund_name": "CSOP Huatai-PineBridge CSI Photovoltaic Industry ETF",
            "gav_non_trading_diff": -35220,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -36461435,
            "gav_trading_diff_status": "Matched",
            "id": 36071,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -4.0105,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 987,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -36495668,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 35220,
            "pma_gav_trading": 36461435,
            "pma_nav": 36495668,
            "pma_nav/share": 4.0105,
            "pma_nav_accrual": -987,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -0.17000000923871994,
            "pma_units": 9100000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "RMB",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0960254Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47007",
            "total_nav_diff_diff": 0.17000000923871994,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-PTVT",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -9100000,
            "units_diff_in_baskets": -30.33,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 12,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 1,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:32.9066667Z"
          },
          {
            "bbg": "2830 HK EQUITY",
            "ccy": "SAR",
            "class_id": "CLS00402",
            "custodian_fund_code": "CSAU",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSAU",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1021889",
            "fund_name": "CSOP Saudi Arabia ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "SAR Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "SAR",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-SAU",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3162 HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00191",
            "custodian_fund_code": "CSGS",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000135",
            "fund_name": "CSOP Global Smart Driving Index ETF",
            "gav_non_trading_diff": -1291,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -1383665,
            "gav_trading_diff_status": "Matched",
            "id": 36059,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.5379,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 37,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -1384919,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 1291,
            "pma_gav_trading": 1383665,
            "pma_nav": 1384919,
            "pma_nav/share": 0.5379,
            "pma_nav_accrual": -37,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 0.15000000083819032,
            "pma_units": 2574700,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.7723259Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "46993",
            "total_nav_diff_diff": -0.15000000083819032,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-SDRIVE",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -2574700,
            "units_diff_in_baskets": -8.58,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 18,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 50,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 1,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:08.1Z"
          },
          {
            "bbg": "3167 HK EQUITY",
            "ccy": "HKD",
            "class_id": "CLS00066",
            "custodian_fund_code": "CSPC",
            "custodian_recon_party": "HSBC",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CSPC",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000022",
            "fund_name": "ICBC CSOP S\u0026P NEW CHINA SECTORS ETF",
            "gav_non_trading_diff": -120434,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -450609942,
            "gav_trading_diff_status": "Matched",
            "id": 36099,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -45.527,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 12808,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -450717568,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "WEEKLY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 120434,
            "pma_gav_trading": 450609942,
            "pma_nav": 450717568,
            "pma_nav/share": 45.527,
            "pma_nav_accrual": -12808,
            "pma_share_class": "HKD Share Class A",
            "pma_total_nav_diff": 0.3599998354911804,
            "pma_units": 9900000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "HKD",
            "share_class_ccy": "HKD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:56.764799Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "46987",
            "total_nav_diff_diff": -0.3599998354911804,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-SPNC",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -9900000,
            "units_diff_in_baskets": -99,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 18,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 193,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:48.6433333Z"
          },
          {
            "bbg": "CEHKBUA HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00056",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C03",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBUA HK",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBUI HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00054",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C04",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "USD Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBUI HK",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBRA HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00055",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C05",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBRA HK",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBFI HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00053",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C02",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "HKD Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBFI HK",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBFA HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00052",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C01",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "HKD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBFA HK",
            "share_class_ccy": "HKD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBRM",
            "ccy": "USD",
            "class_id": "CLS00404",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C07",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "RMB Share Class M",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "CEHKBRI HK EQUITY",
            "ccy": "USD",
            "class_id": "CLS00057",
            "custodian_fund_code": "CSOP12",
            "custodian_recon_party": "CCBASIA",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C06",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000016",
            "fund_name": "CSOP Select USD Bond Fund(Tim)",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_share_class": "RMB Share Class I",
            "recon_status": "NOT RECONCILED",
            "share_class": "CEHKBRI HK",
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "HK-USDB",
            "type": "PBMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "3004 HK EQUITY",
            "ccy": "VND",
            "class_id": "CLS00225",
            "custodian_fund_code": "CSV1",
            "custodian_recon_party": "CITI",
            "entity": "HK",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000158",
            "fund_name": "CSOP FTSE Vietnam 30 ETF",
            "gav_non_trading_diff": -413487785,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -74536119300,
            "gav_trading_diff_status": "Matched",
            "id": 36097,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -19650.6287,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 2109238,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -74947497847,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 413487785,
            "pma_gav_trading": 74536119300,
            "pma_nav": 74947497847,
            "pma_nav/share": 19650.6287,
            "pma_nav_accrual": -2109238,
            "pma_share_class": "VND Share Class A",
            "pma_total_nav_diff": -0.2299957275390625,
            "pma_units": 3814000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "VND",
            "share_class_ccy": "VND",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.1046663Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47023",
            "total_nav_diff_diff": 0.2299957275390625,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "HK-VN30",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -3814000,
            "units_diff_in_baskets": -12.71,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 15,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 31,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:47.0666667Z"
          },
          {
            "bbg": "LCU SP EQUITY",
            "ccy": "USD",
            "class_id": "CLS00226",
            "custodian_fund_code": "CALC",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CALC",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000159",
            "fund_name": "CSOP CGS-CIMB FTSE Asia Pacific Low Carbon Index ETF",
            "gav_non_trading_diff": -160811,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -21467519,
            "gav_trading_diff_status": "Matched",
            "id": 36069,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -1.219,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 411,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -21627919,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 160811,
            "pma_gav_trading": 21467519,
            "pma_nav": 21627919,
            "pma_nav/share": 1.219,
            "pma_nav_accrual": -411,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 0.09999999776482582,
            "pma_units": 17742000,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.1063316Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "46998",
            "total_nav_diff_diff": -0.09999999776482582,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "SG-ALC",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -17742000,
            "units_diff_in_baskets": -88.71,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 30,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 186,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:32.5166667Z"
          },
          {
            "bbg": "SQU SP EQUITY",
            "ccy": "USD",
            "class_id": "CLS00258",
            "custodian_fund_code": "C3IE",
            "custodian_recon_party": "DBS",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C01",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1021685",
            "fund_name": "CSOP iEdge Southeast Asia+ TECH Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "USD Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-ATECH",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00232",
            "custodian_fund_code": "CSB2",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSB2",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000163",
            "fund_name": "CSOP Global Quant Allocation Fund - Balanced II SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "SG-CSB2",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-CSB2",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "",
            "ccy": "USD",
            "class_id": "CLS00233",
            "custodian_fund_code": "CSGT",
            "custodian_recon_party": "HSBC",
            "entity": "CYM",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "CSGT",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "Private",
            "fund_id": "1000164",
            "fund_name": "CSOP Global Quant Allocation Fund - Balanced I SP",
            "match_status": "Unmatched",
            "nav_frequency": "MONTHLY",
            "ops_fund_type": "PVMF",
            "ops_fund_type_value": "PRIVATE MF",
            "pma_share_class": "SG-CSGT",
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T1",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-CSGT",
            "type": "PVMF",
            "units_diff_in_baskets": "NA",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "SCY SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00246",
            "custodian_fund_code": "CCSI",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CCSI",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000175",
            "fund_name": "CSOP CSI STAR and CHINEXT 50 Index ETF",
            "gav_non_trading_diff": -668893,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -24541146,
            "gav_trading_diff_status": "Matched",
            "id": 36096,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -3.4467,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 644,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -25209396,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 668893,
            "pma_gav_trading": 24541146,
            "pma_nav": 25209396,
            "pma_nav/share": 3.4467,
            "pma_nav_accrual": -644,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": 0.3200000002980232,
            "pma_units": 7314096,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "CNY",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.1099094Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47016",
            "total_nav_diff_diff": -0.3200000002980232,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "SG-CSI50",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -7314096,
            "units_diff_in_baskets": -36.57,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 24,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 2,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 2,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:42.1766667Z"
          },
          {
            "bbg": "SHD SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00403",
            "custodian_fund_code": "C3HP",
            "custodian_recon_party": "DBS",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_share_class": "C01",
            "file_status": "PMA, FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1022065",
            "fund_name": "CSOP Huatai-PineBridge SSE Dividend Index ETF",
            "match_status": "Unmatched",
            "nav_frequency": "DAILY",
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_share_class": "RMB Share Class A",
            "recon_status": "NOT RECONCILED",
            "share_class": null,
            "share_class_ccy": "CNY",
            "sign_off_date": "0001-01-01T00:00:00Z",
            "sign_off_status": "PENDING",
            "sign_off_user": "",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "",
            "tradar_code": "SG-DIV",
            "type": "EE",
            "version_date": "0001-01-01T00:00:00Z"
          },
          {
            "bbg": "SRT SP EQUITY",
            "ccy": "SGD",
            "class_id": "CLS00193",
            "custodian_fund_code": "CISR",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CISR",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000137",
            "fund_name": "CSOP iEdge S-REIT Leaders Index ETF",
            "gav_non_trading_diff": -354470,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -67801243,
            "gav_trading_diff_status": "Matched",
            "id": 36061,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -0.7786,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 1071,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -68154642,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "EE",
            "ops_fund_type_value": "EQUITY ETF",
            "pma_gav_non_trading": 354470,
            "pma_gav_trading": 67801243,
            "pma_nav": 68154642,
            "pma_nav/share": 0.7786,
            "pma_nav_accrual": -1071,
            "pma_share_class": "SGD Share Class A",
            "pma_total_nav_diff": 0.14999999105930328,
            "pma_units": 87537250,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "SGD",
            "share_class_ccy": "SGD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0998488Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "46995",
            "total_nav_diff_diff": -0.14999999105930328,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "SG-REIT",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "EE",
            "units_diff": -87537250,
            "units_diff_in_baskets": -1750.75,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 24,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 21,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 3,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:16.0533333Z"
          },
          {
            "bbg": "CSOPUMM SP EQUITY",
            "ccy": "USD",
            "class_id": "CLS00205",
            "custodian_fund_code": "CUMF",
            "custodian_recon_party": "CITI",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "A",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "Public",
            "fund_id": "1000142",
            "fund_name": "CSOP USD Money Market Fund",
            "gav_non_trading_diff": 0,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": 0,
            "gav_trading_diff_status": "Matched",
            "id": 36074,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -1.0681,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 0,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -379259257,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "PBMF",
            "ops_fund_type_value": "PUBLIC MF",
            "pma_gav_non_trading": 0,
            "pma_gav_trading": 0,
            "pma_nav": 379259257,
            "pma_nav/share": 1.0681,
            "pma_nav_accrual": 0,
            "pma_share_class": "USD Share Class A",
            "pma_total_nav_diff": 379259257,
            "pma_units": 355086075,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "USD",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.1031829Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47010",
            "total_nav_diff_diff": -379259257,
            "total_nav_diff_diff_status": "Unmatched",
            "tradar_code": "SG-USDMF",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "PBMF",
            "units_diff": -355086075,
            "units_diff_in_baskets": "NA",
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 0,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 0,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 0,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:34.08Z"
          },
          {
            "bbg": "CYX SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00228",
            "custodian_fund_code": "CCGB",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_share_class": "CCBG",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000124",
            "fund_name": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
            "id": 36084,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -73.6454,
            "nav/share_diff_status": "Unmatched",
            "nav_diff": -2607749070,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_nav": 2607749070,
            "pma_nav/share": 73.6454,
            "pma_share_class": "RMB Share Class I",
            "pma_total_nav_diff": -0.39999961853027344,
            "pma_units": 35409546,
            "recon_status": "NOT RECONCILED",
            "share_class": "USD",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.0778116Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47004",
            "total_nav_diff_diff": 0.39999961853027344,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "SG-WGBI",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "",
            "units_diff": -35409546,
            "units_diff_in_baskets": -708.19,
            "units_diff_status": "Unmatched",
            "version_date": "2024-02-02T09:47:37.6433333Z"
          },
          {
            "bbg": "CYC SP EQUITY",
            "ccy": "CNY",
            "class_id": "CLS00180",
            "custodian_fund_code": "CCGB",
            "custodian_recon_party": "HSBC",
            "entity": "SG",
            "exception_status": "EXCEPTIONS",
            "fa_gav_non_trading": 0,
            "fa_gav_trading": 0,
            "fa_nav": 0,
            "fa_nav/share": 0,
            "fa_nav_accrual": 0,
            "fa_share_class": "CCGA",
            "fa_total_nav_diff": 0,
            "fa_units": 0,
            "fa_unmatched_gav_non_trading": 0,
            "fa_unmatched_gav_trading": 0,
            "fa_unmatched_nav_accrual": 0,
            "file_status": "FA Files Pending",
            "fund_class": "ETF",
            "fund_id": "1000124",
            "fund_name": "ICBC CSOP FTSE Chinese Government Bond Index ETF",
            "gav_non_trading_diff": -60925447,
            "gav_non_trading_diff_status": "Matched",
            "gav_trading_diff": -4277675290,
            "gav_trading_diff_status": "Matched",
            "id": 36083,
            "is_holiday": false,
            "match_status": "Unmatched",
            "nav/share_diff": -70.3655,
            "nav/share_diff_status": "Unmatched",
            "nav_accrual_diff": 29635,
            "nav_accrual_diff_status": "Matched",
            "nav_diff": -1730822031,
            "nav_diff_status": "Unmatched",
            "nav_frequency": "DAILY",
            "nav_share_diff_bps": -10000,
            "normalized_nav_diff": 0,
            "ops_fund_type": "BE",
            "ops_fund_type_value": "BOND ETF",
            "pma_gav_non_trading": 60925447,
            "pma_gav_trading": 4277675290,
            "pma_nav": 1730822031,
            "pma_nav/share": 70.3655,
            "pma_nav_accrual": -29635,
            "pma_share_class": "RMB Share Class A",
            "pma_total_nav_diff": -0.39999961853027344,
            "pma_units": 24597600,
            "pma_unmatched_gav_non_trading": 0,
            "pma_unmatched_gav_trading": 0,
            "pma_unmatched_nav_accrual": 0,
            "recon_status": "NOT RECONCILED",
            "share_class": "SGD",
            "share_class_ccy": "CNY",
            "sign_off_comments": "",
            "sign_off_date": "2024-02-02T09:49:19.075974Z",
            "sign_off_status": "SIGNED-OFF",
            "sign_off_user": "Ops User",
            "sla_met": "Unmet",
            "sla_type": "T0",
            "source1_id": "",
            "source2_id": "47004",
            "total_nav_diff_diff": 0.39999961853027344,
            "total_nav_diff_diff_status": "Matched",
            "tradar_code": "SG-WGBI",
            "trade_date": "2024-01-31T00:00:00Z",
            "type": "",
            "units_diff": -24597600,
            "units_diff_in_baskets": -491.95,
            "units_diff_status": "Unmatched",
            "unmatched_gav_non_trading_diff": 17,
            "unmatched_gav_non_trading_diff_status": "NA",
            "unmatched_gav_trading_diff": 75,
            "unmatched_gav_trading_diff_status": "NA",
            "unmatched_nav_accrual_diff": 1,
            "unmatched_nav_accrual_diff_status": "NA",
            "version_date": "2024-02-02T09:47:37.6433333Z"
          }
        ],
      // },
      "status": "success"
    }
  }
}



// Required Changes

// settlement config is mandatory for listed share class onlu
// unit decimals should be a text box which allow numbers
